const defaultColors = {
    '--app-menu-background': '#EDEDED',
    '--app-table-background': '#F8F8F9',
    '--app-base-primary': '#2B3037',
    '--app-base-secondary': '#A4AFBE',
    '--app-border': '#B6C3D3',
    '--app-btn-secondary': '#E0ECFB',
    '--brand-main': '#2D28F3',
    '--brand-iris': '#6E6DF8',
    '--brand-purple': '#8672D9',
    '--brand-pink': '#D1CFF7',
    '--system-red': '#FF3B30',
    '--system-orange': '#FF9500',
    '--system-yellow': '#F2C200',
    '--system-green': '#24A645',
    '--system-blue': '#007AFF',
    '--system-iris': '#5856D6',
    '--system-purple': '#AF52DE',
    '--system-pink': '#FF2D55'
}

function hexToRGBA(hex, opacity) {
    const [ hex1, hex2, hex3, hex4, hex5, hex6 ] = hex.substring(1).split('');
    const r = hexPairToDecimal(hex1, hex2);
    const g = hexPairToDecimal(hex3, hex4);
    const b = hexPairToDecimal(hex5, hex6);
    const a = opacity/100;

    return `rgba( ${r}, ${g}, ${b}, ${a})`;
}

function hexPairToDecimal(firstHex, secondHex) {
    return parseInt(firstHex, 16) * 16 + parseInt(secondHex, 16);
}

export {
    defaultColors,
    hexToRGBA
}