<template>
    <div class="individual-panel"
        :style="{
            'borderBottomColor': colorBorder,
        }"
    >
        <div class="individual-panel__logo">
            <admin-logo
                :logo-url="logo.link"
            />
        </div>
        <div class="individual-panel__language language" :style="{ 'color': colorBasePrimary }">
            <div class="language__text">UA</div>
            <div class="language__btn">
                <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.175 2.01367L5 5.83034L8.825 2.01367L10 3.18867L5 8.18867L0 3.18867L1.175 2.01367Z" fill="#2B3037"/>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
    import AdminLogo from "../../logo/AdminLogo";

    export default {
        name: "IndividualTemplatePanel",

        components: {
            AdminLogo
        },

        props: {
            logo: {
                type: Object,
                required: true
            },
            colorBasePrimary: {
                type: String,
                required: true
            },
            colorBorder: {
                type: String,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>
    .individual-panel {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;
        padding: 5px 15px;
        border-bottom: 1px solid;
    }

    .logo {
        display: flex;
        align-items: flex-end;
        transform: scale(0.7);
        transform-origin: left center;

        &__image {
            margin-right: 10px;
        }
    }

    .language {
        font-size: 12px;
        font-weight: 500;
        display: flex;
        align-items: center;

        &__text {
            margin-right: 2px;
            padding-top: 5px;
        }

        &__btn {
            transform: scale(0.7);
            transform-origin: center center;
            padding-top: 10px;
        }
    }
</style>
