<template>
    <div class="users">
        <div class="users__panel">
            <div class="users__settings">
                <div class="users__title">{{ $t('users.title') }}</div>
                <div class="users__settings-btn">
                    <app-text-btn
                        :text="$t('users.inviteBtn')"
                        @action="openModalInvite"
                    >
                        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.37401 17.126H5.34276L14.0986 8.37017L11.1298 5.40142L2.37401 14.1573V17.126ZM3.95734 14.8143L11.1298 7.64184L11.8582 8.37017L4.68567 15.5427H3.95734V14.8143Z" fill="var(--brand-main)"/>
                            <path d="M14.5419 3.10559C14.2332 2.79684 13.7344 2.79684 13.4257 3.10559L11.9769 4.55434L14.9457 7.52309L16.3944 6.07434C16.7032 5.76559 16.7032 5.26684 16.3944 4.95809L14.5419 3.10559Z" fill="var(--brand-main)"/>
                        </svg>
                    </app-text-btn>
                </div>
            </div>
            <div class="users__actions">
                <app-add-btn
                    @open-modal="openModalUser"
                />
            </div>
        </div>
        <div class="users__control">
            <div class="users__filters">
                <users-filters
                    :qty="users.length"
                    @update="updateQueryParams"
                />
            </div>
        </div>
        <div class="users__content" ref="users">
            <div v-if="showTable" class="users__table">
                <users-table
                    :users="users"
                    :max-height="maxHeightScroll"
                    :search-sub-string="searchSubString"
                />
            </div>
            <div v-if="showEmpty" class="users__empty">
                <app-empty-content
                    :content-type="'users'"
                    @open-modal="openModalUser"
                />
            </div>
            <div v-if="showNoResults" class="users__empty">
                <app-no-results
                    @clear="clearQueryParams"
                />
            </div>
            <div v-if="isLoaded" class="users__loader">
                <app-loader
                    :size="'large'"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import UsersFilters from "./UsersFilters";
    import UsersTable from "./UsersTable";
    import { maxHeightMixin } from "../../mixins/calcMaxHeight";
    import { usersToastsMixin } from "../../mixins/toasts/users";
    import { djinAPI } from "../../config/api";
    import { mapUsersToView } from "../../api/mappers/users";

    export default {
        name: "UsersControl",

        components: {
            UsersTable,
            UsersFilters
        },

        mixins: [
            maxHeightMixin,
            usersToastsMixin
        ],

        data() {
            return {
                users: [],
                isLoaded: false,
                params: null
            }
        },

        created() {
            this.getUsers();
        },

        mounted() {
            this.subscribeToResize('users');
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromResize();
            this.unsubscribeFromEvents();
        },

        computed: {
            showTable() {
                return this.users.length && !this.isLoaded;
            },

            showEmpty() {
                return !this.users.length && !this.isLoaded && !this.isExistQueryParams;
            },

            showNoResults() {
                return !this.users.length && !this.isLoaded && this.isExistQueryParams;
            },

            isExistQueryParams() {
                return this.searchSubString || this.filterSubString;
            },

            searchSubString() {
                return this.params?.params?.match ? this.params?.params?.match : '';
            },

            filterSubString() {
                return this.params?.params?.filter ? this.params?.params?.filter : '';
            }
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('updateUsers', this.updateUsers);
                this.emitter.on('deleteUser', this.deleteUser);
            },

            unsubscribeFromEvents() {
                this.emitter.off('updateUsers', this.updateUsers);
                this.emitter.off('deleteUser', this.deleteUser);
            },

            getUsers() {
                this.isLoaded = true;

                djinAPI.get('/users', this.params)
                    .then((result) => {
                        const users = result.data;
                        this.users = mapUsersToView(users);

                        this.emitter.emit('updateShowQty');
                        this.$nextTick(() => {
                            this.calcMaxHeight();
                        });
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.isLoaded = false;
                        }, 500)
                    })
            },

            updateUsers() {
                this.getUsers();
            },

            deleteUser(id) {
                //TODO: Name in toast?
                const user = this.users.find((user) => user.id === id);

                djinAPI.delete(`/users/${id}`)
                    .then(() => {
                        this.closeModalConfirm();
                        this.closeModalUser();
                        this.showDeleteUserToast();
                        this.updateUsers();
                    })
            },


            openModalInvite() {
                this.openModal({
                    name: 'modalInvite'
                })
            },
            openModalUser() {
                this.openModal({
                    name: 'modalUser'
                })
            },

            closeModalUser() {
                this.closeModal('modalUser');
            },

            closeModalConfirm() {
                this.closeModal('modalConfirm');
            },

            updateQueryParams(params) {
                this.params = { ...params };
                this.getUsers();
            },

            clearQueryParams() {
                this.emitter.emit('clearQueryParams');
            },
        }
    }
</script>

<style lang="scss" scoped>
    .users {
        &__table {
            height: 100%;
        }
    }
</style>