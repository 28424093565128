<template>
    <div class="table__row premise-row" @click="handlerClick">
        <div class="table__column premise__group">
            <app-overflow-match-wrapper
                :text="buildingGroup ? buildingGroup.name : ''"
                :sub-text="subString"
                :is-match="true"
                :scroll-position="scrollPosition"
            />
        </div>
        <div class="table__column premise__building">
            <app-overflow-match-wrapper
                :text="building.name"
                :sub-text="subString"
                :is-match="true"
                :scroll-position="scrollPosition"
            />
        </div>
        <div class="table__column premise__entrance">
            {{ entrance }}
        </div>
        <div class="table__column premise__floor">
            {{ floor }}
        </div>
        <div class="table__column premise__type">
            <app-item-type
                :type="type"
            />
        </div>
        <div class="table__column premise__name">
            <app-overflow-match-wrapper
                :text="name"
                :sub-text="subString"
                :is-match="true"
                :scroll-position="scrollPosition"
            />
        </div>
        <div class="table__column premise__area">
            {{ formattedArea }}
        </div>
        <div class="table__column premise__registered">
            {{ registered }}
        </div>
        <div class="table__column premise__file">
            <app-circle-btn v-if="document"
                @action="getAndDownloadDocument"
            >
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.8749 7.5V11.875H3.12488V7.5H1.87488V11.875C1.87488 12.5625 2.43738 13.125 3.12488 13.125H11.8749C12.5624 13.125 13.1249 12.5625 13.1249 11.875V7.5H11.8749ZM8.12488 7.91875L9.74363 6.30625L10.6249 7.1875L7.49988 10.3125L4.37488 7.1875L5.25613 6.30625L6.87488 7.91875V1.875H8.12488V7.91875Z" fill="#2B3037"/>
                </svg>
            </app-circle-btn>
        </div>
    </div>
</template>

<script>
    import AppItemType from "./PremiseType";
    import { getAndDownloadFile } from "../../api/requests/common";

    export default {
        name: "PremiseRow",

        components: {
            AppItemType
        },

        props: {
            id: {
                type: String,
                required: true
            },
            buildingGroup: {
                type: Object,
            },
            building: {
                type: Object,
                required: true
            },
            entrance: {
                type: String,
                required: true
            },
            floor: {
                type: String,
                required: true
            },
            type: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            area: {
                type: String,
                required: true
            },
            registered: {
                type: Number,
                required: true
            },
            document: {
                type: String,
                required: true
            },
            subString: {
                type: String,
                required: true
            },
            scrollPosition: {
                type: Number,
                required: true
            }
        },

        computed: {
            formattedArea() {
                return this.area ? `${this.area} ${this.$t('common.units.area')}` : '';
            }
        },

        methods: {
            handlerClick() {
                this.$emit('update', this.id);
            },

            getAndDownloadDocument() {
                getAndDownloadFile(this.document, this.name);
            }
        }
    }
</script>

<style scoped lang="scss">
    .premise-row {
        &:hover {
            cursor: pointer;
        }
    }
</style>