<template>
    <div class="finance-statements-details">
        <div class="finance-statements-details__type">
            <app-switch-toggle
                :f-element-text="$t('finance.statements.type.enterprise')"
                :s-element-text="$t('finance.statements.type.user')"
                @update="updateQueryType"
            />
        </div>
        <div v-if="isUserType" class="finance-statements-details__user">
            <div class="finance-statements-details__search-icon">
                <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.4115 12.349H12.5885L12.2969 12.0677C13.3177 10.8802 13.9323 9.33854 13.9323 7.66146C13.9323 3.92188 10.901 0.890625 7.16146 0.890625C3.42188 0.890625 0.390625 3.92188 0.390625 7.66146C0.390625 11.401 3.42188 14.4323 7.16146 14.4323C8.83854 14.4323 10.3802 13.8177 11.5677 12.7969L11.849 13.0885V13.9115L17.0573 19.1094L18.6094 17.5573L13.4115 12.349ZM7.16146 12.349C4.56771 12.349 2.47396 10.2552 2.47396 7.66146C2.47396 5.06771 4.56771 2.97396 7.16146 2.97396C9.75521 2.97396 11.849 5.06771 11.849 7.66146C11.849 10.2552 9.75521 12.349 7.16146 12.349Z" fill="#A4AFBE"/>
                </svg>
            </div>
            <app-base-select
                :is-searchable="true"
                :place-holder="$t('finance.statements.user.ph')"
                :selected-option="selectedUser"
                :options="users"
                :type="'financeUser'"
                @select="selectUser"
            />
        </div>
        <div class="finance-statements-details__period">
            <app-base-select-period
                :is-not-clear="true"
                :selected-period="selectedPeriod"
                @update="updatePeriod"
            />
        </div>
        <div class="finance-statements-details__action-btn">
            <app-base-btn
                :is-disabled="isDisabledActionBtn"
                :size="'padding'"
                :variation="'save'"
                :text="$t('common.buttons.statements')"
                @action="updateQueryParams"
            />
        </div>
    </div>
</template>

<script>
    import { getStartAndEndDate, convertToISOStringWithoutTime } from "../../../helpers/date";
    import AppFilterActiveItem from "../../filters/AppFilterActiveItem";
    import AppSwitchToggle from "../../common/local/AppSwitchToggle";

    export default {
        name: "FinanceStatementsDetails",

        components: {
            AppSwitchToggle,
            AppFilterActiveItem
        },

        props: {
            users: {
                type: Array,
                required: true
            }
        },

        data() {
            return {
                selectedUser: null,
                selectedPeriod: null,
                isUserType: false,
                params: null
            }
        },

        computed: {
            isDisabledActionBtn() {
                return this.isUserType ? !this.selectedUser || !this.selectedPeriod : !this.selectedPeriod;
            }
        },

        methods: {
            updatePeriod(period) {
                this.selectedPeriod = period;
                this.$emit('selectPeriod', this.selectedPeriod);
            },

            selectUser(user) {
                this.selectedUser = user;
                this.$emit('selectUser', this.selectedUser);
            },

            updateQueryParams() {
                let parameters = null;
                const timeFilter = this.selectedPeriod;

                if (timeFilter) {
                    parameters = {};

                    if (timeFilter) {
                        parameters.start_date = convertToISOStringWithoutTime(timeFilter.startDate);

                        if (this.selectedPeriod.id === '21' || this.selectedPeriod.id === '31' || this.selectedPeriod.id === '51') {
                            const { startDate, endDate } = getStartAndEndDate('11');
                            parameters.end_date = convertToISOStringWithoutTime(startDate);
                        } else {
                            parameters.end_date = convertToISOStringWithoutTime(timeFilter.endDate);
                        }
                    }
                }

                this.$emit('update-query-details', parameters);
            },

            updateQueryType(isUserType) {
                this.isUserType = isUserType;
                this.$emit('update-query-type', isUserType);
            }
        }
    }
</script>

<style scoped lang="scss">
    .finance-statements-details {
        display: flex;
        width: 100%;

        &__type, &__period, &__user {
            padding-right: 30px;
        }

        &__period {
            width: 100%;
            max-width: 282px;
        }

        &__user {
            width: 100%;
            position: relative;
            max-width: 574px;
        }

        &__search-icon {
            position: absolute;
            left: 16px;
            top: 50%;
            transform: translate(0, -50%);
            display: flex;
            align-items: center;
            z-index: 100;
        }
    }
</style>

<style lang="scss">
    .finance-statements-details__user {
        .multiselect__tags {
            padding-left: 40px !important;
        }
    }
</style>