<template>
    <div class="finance-invoice-position">
        <div class="table__row">
            <div class="table__column invoice__control"></div>
            <div class="table__column invoice__number invoice__number--is-position">
                <app-match-string
                    :string="name"
                    :sub-string="subString"
                />
            </div>
            <div class="table__column invoice__user-name"></div>
            <div class="table__column invoice__user-document"></div>
            <div class="table__column invoice__building"></div>
            <div class="table__column invoice__due-date"></div>
            <div class="table__column invoice__total invoice__total--is-row">
                {{ formattedPrice }}
            </div>
            <div class="table__column invoice__state">
                <app-invoice-state
                    :state="state.code"
                    :sub-price="state.price"
                />
            </div>
            <div class="table__column invoice__payment-date"></div>
        </div>
    </div>
</template>

<script>
    import AppInvoiceState from "./AppInvoiceState";
    import { formatCurrencyValue } from "../../../helpers/finance";

    export default {
        name: "FinanceInvoicePremiseRow",

        components: {
            AppInvoiceState
        },

        props: {
            id: {
                type: String,
                required: true
            },
            index: {
                type: Number,
                required: true
            },
            length: {
                type: Number,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            price: {
                type: Number,
                required: true
            },
            state: {
                type: Object,
                required: true
            },
            subString: {
                type: String,
                required: true
            }
        },

        computed: {
            formattedPrice() {
                const { balance, currency } = formatCurrencyValue(this.price);

                return `${balance} ${currency}`;
            }
        }
    }
</script>

<style lang="scss">
    .finance-invoice-position {
        .table__row {
            min-height: 50px !important;
            padding: 0 30px !important;
            background: var(--app-table-background) !important;
        }
    }
</style>