function setDefaultLocale(language) {
    const langCode = language.substring(0, 2);

    return langCode === 'ru'
        ? 'ru'
        : langCode === 'ka'
        ? 'ka'
        : langCode === 'uk'
        ? 'uk'
        : 'en'
}

export {
    setDefaultLocale
}

