<template>
    <div class="filter-active">
        <div class="filter-active__name">
            {{ name }}
        </div>
        <button type="button" class="filter-active__clear-btn" @click="handlerClick">
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.875 4.00625L10.9938 3.125L7.5 6.61875L4.00625 3.125L3.125 4.00625L6.61875 7.5L3.125 10.9938L4.00625 11.875L7.5 8.38125L10.9938 11.875L11.875 10.9938L8.38125 7.5L11.875 4.00625Z" fill="#B6C3D3"/>
            </svg>
        </button>
    </div>
</template>

<script>
    export default {
        name: "AppFilterActiveItem",

        props: {
            id: {
                type: [String, Number],
                required: true
            },
            filterKey: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            }
        },

        methods: {
            handlerClick() {
                this.$emit('remove', this.filterKey, this.id);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .filter-active {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        border-radius: 100px;
        background: var(--app-btn-secondary);

        &__name {
            font-size: 12px;
            color: var(--app-base-primary);
            margin-right: 15px;
        }

        &__clear-btn {
            width: 15px;
            height: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
</style>