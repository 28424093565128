<template>
    <div class="finance-review">
        <div class="finance-review__filters">
            <finance-review-filters
                :qty="invoices.length"
                @update="updateQueryParams"
            />
        </div>
        <div class="finance-review__content" ref="financeReview">
            <div v-if="showTable">
                <finance-review-table
                    :invoices="invoices"
                    :max-height="maxHeightScroll"
                    :search-sub-string="searchSubString"
                />
            </div>
            <div v-if="showEmpty" class="finance-review__empty">
                <app-empty-content
                    :is-small="true"
                    :show-btn="false"
                    :content-type="'reviewInvoices'"
                />
            </div>
            <div v-if="showNoResults" class="finance-review__empty">
                <app-no-results
                    :is-small="true"
                    @clear="clearQueryParams"
                />
            </div>
            <div v-if="isLoaded" class="finance-review__loader">
                <app-loader
                    :size="'large'"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import FinanceReviewFilters from "./FinanceReviewFilters";
    import FinanceReviewTable from "./FinanceReviewTable";
    import { mapReviewInvoicesToView } from "../../../api/mappers/finance/review";
    import { maxHeightMixin } from "../../../mixins/calcMaxHeight";
    import { reviewInvoicesToastsMixin } from "../../../mixins/toasts/finance/reviewInvoices";
    import { djinAPI } from "../../../config/api";

    export default {
        name: "FinanceReviewControl",

        components: {
            FinanceReviewFilters,
            FinanceReviewTable
        },

        data() {
            return {
                isLoaded: false,
                params: null,
                invoices: []
            }
        },

        mixins: [
            maxHeightMixin,
            reviewInvoicesToastsMixin
        ],

        created() {
            this.getReviewInvoices();
        },

        mounted() {
            this.subscribeToResize('financeReview');
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromResize();
            this.unsubscribeFromEvents();
        },

        computed: {
            showTable() {
                return this.invoices.length && !this.isLoaded;
            },

            showEmpty() {
                return !this.invoices.length && !this.isLoaded && !this.isExistQueryParams;
            },

            showNoResults() {
                return !this.invoices.length && !this.isLoaded && this.isExistQueryParams;
            },

            isExistQueryParams() {
                return this.searchSubString || this.filterSubString;
            },

            searchSubString() {
                return this.params?.params?.match ? this.params?.params?.match : '';
            },

            filterSubString() {
                return this.params?.params?.filter ? this.params?.params?.filter : '';
            },
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('updateReviewInvoices', this.getReviewInvoices);
                this.emitter.on('deleteReviewInvoice', this.deleteReviewInvoice);
            },

            unsubscribeFromEvents() {
                this.emitter.off('updateReviewInvoices', this.getReviewInvoices);
                this.emitter.off('deleteReviewInvoice', this.deleteReviewInvoice);
            },

            getReviewInvoices() {
                this.isLoaded = true;

                djinAPI.get('/finance/review', this.params)
                    .then((result) => {
                        this.invoices = mapReviewInvoicesToView(result.data);

                        this.emitter.emit('updateShowQty');
                        this.$nextTick(() => {
                            this.calcMaxHeight();
                        });
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.isLoaded = false;
                        }, 500)
                    })
            },

            deleteReviewInvoice(id) {
                djinAPI.delete(`/finance/review/${id}`)
                    .then(() => {
                        this.closeModalConfirm();
                        this.showDeleteReviewInvoiceToast();
                        this.getReviewInvoices();
                        this.emitter.emit('updateReviewInvoices');
                    })
            },

            clearQueryParams() {
                this.emitter.emit('clearQueryParams');
            },

            updateQueryParams(params) {
                this.params = { ...params };
                this.getReviewInvoices();
            },

            closeModalConfirm() {
                this.closeModal('modalConfirm');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .finance-review {
        display: flex;
        flex-direction: column;
        height: 100%;

        &__content {
            flex-grow: 1;
        }

        &__filters {
            display: flex;
            justify-content: space-between;
            padding: 15px 30px 15px 30px;
        }
    }
</style>

<style lang="scss">
    .review-invoice {
        &__date {
            width: 8%;
        }

        &__amount {
            width: 10%;

            &--is-row {
                font-weight: 700;
            }
        }

        &__payment {
            width: 20%;
        }

        &__user-name {
            width: 18%;
        }

        &__invoice-number {
            width: 15%;
            display: flex;
            align-items: center;

            button {
                margin-left: 30px;
            }
        }

        &__invoice {
            display: flex;
            align-items: center;

            button {
                margin-left: 30px;
            }
        }

        &__should-be {
            width: 12%;

            &--is-row {
                font-weight: 700;
            }
        }

        &__state {
            width: 13%;
        }

        &__actions {
            width: 4%;
            display: flex;
            justify-content: flex-end;
            padding-right: unset;
        }

        &__bank-name {
            font-weight: 700;
            white-space: nowrap;
            margin-bottom: 4px;
        }

        &__account-number {
            margin-bottom: 2px;
        }
    }
</style>