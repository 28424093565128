<template>
    <div class="upload"
         @drop.prevent="dropFile"
         @dragover.prevent="overUploadArea = true"
         @dragleave.prevent="overUploadArea = false"
         @dragenter.prevent
    >
        <div class="upload__area"
             :class="{
                'upload__area--is-enter': overUploadArea,
                'upload__area--is-error': isError
            }"
        >
            <svg width="30" height="36" viewBox="0 0 30 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.75008 27.3753H21.2501V14.8753H29.5834L15.0001 0.291992L0.416748 14.8753H8.75008V27.3753ZM15.0001 6.18783L19.5209 10.7087H17.0834V23.2087H12.9167V10.7087H10.4792L15.0001 6.18783ZM0.416748 31.542H29.5834V35.7087H0.416748V31.542Z" fill="var(--brand-purple)"/>
            </svg>
            <div class="upload__help">{{ helpText }}</div>
            <label class="upload__uploadBtn">
                <input type="file"
                    hidden
                    :accept="acceptTypesUpload"
                    @change="uploadFile"
                />
                {{ btnText }}
            </label>
        </div>
        <div v-if="isError" class="upload__errors">
            {{ errorMessage }}
        </div>
    </div>
</template>

<script>
    import { validateFile } from "../../../../validations/upload";

    export default {
        name: "AppUploadArea",

        props: {
            helpText: {
                type: String,
                required: true
            },
            btnText: {
                type: String,
                required: true
            },
            acceptTypes: {
                type: Array,
                required: true
            },
            checkType: {
                type: Boolean,
                default: true
            }
        },

        data() {
            return {
                overUploadArea: false,
                errorMessage: ''
            }
        },

        computed: {
            isError() {
                return !!this.errorMessage;
            },

            acceptTypesUpload() {
                return this.acceptTypes.join(',')
            }
        },

        methods: {
            uploadFile(event) {
                this.clearErrors();

                const file = event.target.files[0];

                if (file) {
                    this.validateFile(file);
                }
            },

            dropFile(event) {
                this.clearErrors();

                const file = event.dataTransfer.files[0];

                if (file) {
                    this.validateFile(file);
                }
                this.overUploadArea = false;
            },

            validateFile(file) {
                const { isValid, errorMsg } = validateFile(file, this.acceptTypes, this.checkType);

                if (isValid) {
                    this.convertFile(file);
                } else {
                    this.errorMessage = errorMsg;
                }

                // if (this.errorMessage) {
                //     this.clearErrors();
                // }
            },

            clearErrors() {
                this.errorMessage = '';
                // setTimeout(() => {
                //     this.errorMessage = '';
                // },5000)
            },

            async convertFile(file) {
                if (file) {
                    const url = URL.createObjectURL(file);

                    await this.toBase64(file)
                        .then(convertedFile => {
                            this.$emit('uploaded', url, convertedFile, file);
                        })
                }
            },

            toBase64(file)  {
                return new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
    .upload {
        font-size: 16px;
        line-height: 18.75px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        &__area {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 13px;
            padding: 8px;
            border: 1px solid var(--app-border);

            &--is-enter {
                border: 1px solid var(--brand-main);
            }

            &--is-error {
                border: 1px solid var(--system-red);
            }
        }

        &__help {
            text-align: center;
            max-width: 380px;
            margin: 10px 0 30px 0;
            color: var(--app-base-primary);
        }

        &__uploadBtn {
            font-weight: 500;
            text-align: center;
            color: var(--brand-main);
        }

        &__uploadBtn:hover {
            cursor: pointer;
        }

        &__errors {
            color: var(--system-red);
            font-size: 14px;
            margin-top: 10px;
        }
    }
</style>