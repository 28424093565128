const modals = {
    namespaced: true,

    state: () => ({
        active: [],
        selectedEl: null
    }),

    getters: {
        showModalAdmin(state) {
            return state.active.includes('modalAdmin');
        },

        showModalAdminTransfer(state) {
            return state.active.includes('modalAdminTransfer');
        },

        showModalChangeLogin(state) {
            return state.active.includes('modalChangeLogin');
        },

        showModalChangePassword(state) {
            return state.active.includes('modalChangePassword');
        },

        showModalRecoveryPassword(state) {
            return state.active.includes('modalRecoveryPassword');
        },

        showModalLogout(state) {
            return state.active.includes('modalLogout');
        },

        showModalEditAvatar(state) {
            return state.active.includes('modalEditAvatar');
        },

        showModalUploadIcon(state) {
            return state.active.includes('modalUploadIcon');
        },

        showModalBuilding(state) {
            return state.active.includes('modalBuilding');
        },

        showModalGroupBuildings(state) {
            return state.active.includes('modalGroupBuildings');
        },

        showModalPremisesAutocomplete(state) {
            return state.active.includes('modalPremisesAutocomplete');
        },

        showModalPremise(state) {
            return state.active.includes('modalPremise');
        },

        showModalInvite(state) {
            return state.active.includes('modalInvite');
        },

        showModalUser(state) {
            return state.active.includes('modalUser');
        },

        showModalRestructuringDebt(state) {
            return state.active.includes('modalRestructuringDebt');
        },

        showModalSignContract(state) {
            return state.active.includes('modalSignContract');
        },

        showModalTerminateContract(state) {
            return state.active.includes('modalTerminateContract');
        },

        showModalContract(state) {
            return state.active.includes('modalContract');
        },

        showModalInvoice(state) {
            return state.active.includes('modalInvoice');
        },

        showModalReviewInvoice(state) {
            return state.active.includes('modalReviewInvoice');
        },

        showModalDeterminePayer(state) {
            return state.active.includes('modalDeterminePayer');
        },

        showModalTemplateContract(state) {
            return state.active.includes('modalTemplateContract');
        },

        showModalTemplateInvoice(state) {
            return state.active.includes('modalTemplateInvoice');
        },

        showModalOperation(state) {
            return state.active.includes('modalOperation');
        },

        showModalConfirm(state) {
            return state.active.includes('modalConfirm');
        },

        selectedElement(state) {
            return state.selectedEl;
        }
    },

    mutations: {
        open(state, name) {
            state.active.push(name);
        },

        close(state, name) {
            state.active = state.active.filter((modalName) => modalName !== name);
        },

        closeOne(state) {
            if (state.active.length) {
                const lastModalName = state.active[state.active.length - 1];
                state.active = state.active.filter((modalName) => modalName !== lastModalName);
            }
        },

        updateSelectedEl(state, selectedEl) {
            state.selectedEl = selectedEl;
        }
    },

    actions: {
        openModal({ commit }, { name, selectedEl }) {
            if (selectedEl) {
                commit('updateSelectedEl', selectedEl);
            }

            commit('open', name);
        },

        closeModal({ commit, state }, name) {
            //We need this condition, because we don't need clear selectedEl when close modalConfirm
            if (name !== 'modalConfirm') {
                commit('updateSelectedEl', null);
            } else {
                //Check condition if only modalConfirm has been opened
                if (state.active.length === 1) {
                    commit('updateSelectedEl', null);
                }
            }

            commit('close', name);
        },

        closeOneModal({ commit }) {
            commit('closeOne');
        },

        clearSelectedEl({ commit }) {
            commit('updateSelectedEl', null);
        }
    }
}

export {
    modals
}