<template>
    <div class="documents">
<!--        <div class="documents__panel">-->
<!--            <div class="documents__title">Документи</div>-->
<!--        </div>-->
    </div>
</template>

<script>
    export default {
        name: "DocumentsControl"
    }
</script>