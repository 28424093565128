const storage = {
    get location() {
        return sessionStorage.location ? JSON.parse(sessionStorage.location) : null;
    },

    set location(position) {
        sessionStorage.setItem('location', JSON.stringify({
            lat: position.lat,
            lng: position.lng
        }));
    }
}

const localStorageData = {
    get language() {
        return localStorage.language ? JSON.parse(localStorage.language) : null;
    },

    set language(language) {
        localStorage.setItem('language', language);
    }
}

export {
    storage,
    localStorageData
}