<template>
    <div class="admin-filters filters">
        <div class="filters__control">
            <div class="filters__sorting filters__element">
                <app-base-select
                    :selected-option="selectedSort"
                    :options="elements"
                    :place-holder="$t('sort.ph')"
                    :type="'sort'"
                    :is-clear="true"
                    @select="selectSort"
                />
            </div>
            <div class="filters__searching filters__element">
                <app-base-search
                    v-model="search.currentValue"
                    :qty="qty"
                    :show-qty="search.showQty"
                    @update="updateBySearch"
                    @clear="clearSearch"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { adminsElements } from "../../constants/sorting";

    export default {
        name: "AdminsFilters",

        props: {
            qty: {
                type: Number,
                required: true
            }
        },

        data() {
            return {
                selectedSort: null,
                search: {
                    currentValue: '',
                    updatedValue: '',
                    showQty: false
                }
            }
        },

        mounted() {
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromEvents();
        },

        computed: {
            elements() {
                return adminsElements;
            }
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('updateShowQty', this.checkShowQty);
                this.emitter.on('clearSearch', this.clearSearch);
            },

            unsubscribeFromEvents() {
                this.emitter.off('updateShowQty', this.checkShowQty);
                this.emitter.off('clearSearch', this.clearSearch);
            },

            selectSort(element) {
                this.selectedSort = element;
                this.updateQueryParams();
            },

            updateBySearch() {
                this.search.updatedValue = this.search.currentValue;
                this.updateQueryParams();
            },

            clearSearch() {
                this.search.updatedValue = '';
                this.search.currentValue = '';
                this.checkShowQty();
                this.updateQueryParams();
            },

            checkShowQty() {
                this.search.showQty = !!this.search.updatedValue;
            },

            updateQueryParams() {
                let parameters = null;
                const match = this.search.updatedValue;
                const sortBy = this.selectedSort;

                if (match || sortBy) {
                    parameters = {
                        params: {}
                    };

                    if (sortBy) {
                        parameters.params.sortBy =  sortBy.id;
                    }

                    if (match) {
                        parameters.params.match =  match;
                    }
                }

                this.$emit('update', parameters);
            }
        }
    }
</script>