<template>
    <div class="type-qty"
         :style="{
            'backgroundColor': premiseType.background
         }"
    >
        <span>{{ qty }}</span>
    </div>
</template>

<script>
    import { premiseTypes } from "../../constants/premises";

    export default {
        name: "PremisesTypeQty",

        props: {
            type: {
                type: String,
                required: true,
                validator(type) {
                    return [
                        'living',
                        'apts',
                        'commercial',
                        'technical',
                        'parking'
                    ].includes(type);
                }
            },
            qty: {
                type: Number,
                required: true,
            }
        },

        computed: {
            premiseType() {
                return premiseTypes.find((type) => type.id === this.type);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .type-qty {
        height: 24px;
        width: 68px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: var(--app-base-primary);
    }
</style>