import { validateNumber } from "./common";
import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

const premiseErrors = {
    building: t('validation.required'),
    type: t('validation.required'),
    floor: t('validation.required'),
    name: t('validation.required'),
    area: t('validation.number'),
}

const premiseAutoFillErrors = {
    building: t('validation.required'),
    type: t('validation.required'),
    floor: t('validation.required'),
    floorRange: t('validation.premise.floorsRange'),
    qty: t('validation.number'),
    numbering: t('validation.number')
}

function validatePremise(premise, building, type, floor) {
    let isValid = false;

    const errors = {
        building: '',
        type: '',
        floor: '',
        name: '',
        area: ''
    }

    const isValidBuilding = !!building;

    if (!isValidBuilding) {
        errors.building = premiseErrors.building;
    }

    const isValidType = !!type;

    if (!isValidType) {
        errors.type = premiseErrors.type;
    }

    const isValidFloor = !!floor;

    if (!isValidFloor) {
        errors.floor = premiseErrors.floor;
    }

    const isValidName = !!premise.name;

    if (!isValidName) {
        errors.name = premiseErrors.name;
    }

    const isValidArea = premise.area ? validateNumber(premise.area) : true;

    if (!isValidArea) {
        errors.area = premiseErrors.area;
    }

    isValid = isValidBuilding && isValidType && isValidFloor && isValidName && isValidArea;

    return { isValid, errors };
}

function validateAutoFillPremises(building, type, minFloor, maxFloor, qty, numbering) {
    let isValid = false;

    const errors = {
        building: '',
        type: '',
        minFloor: '',
        maxFloor: '',
        floors: '',
        qty: '',
        numbering: ''
    }

    const isValidBuilding = !!building;

    if (!isValidBuilding) {
        errors.building = premiseAutoFillErrors.building;
    }

    const isValidType = !!type;

    if (!isValidType) {
        errors.type = premiseAutoFillErrors.type;
    }

    let isValidFloor = false;
    const isValidMinFloor = !!minFloor;
    const isValidMaxFloor = !!maxFloor;

    if (isValidMinFloor && isValidMaxFloor) {
        if (minFloor.id <= maxFloor.id) {
            isValidFloor = true;
        } else {
            errors.floors = premiseAutoFillErrors.floorRange;
            errors.minFloor = 'isNotShowError';
            errors.maxFloor = 'isNotShowError';
        }
    } else {
        errors.floors = premiseAutoFillErrors.floor;
        errors.minFloor = 'isNotShowError';
        errors.maxFloor = 'isNotShowError';
    }

    const isValidQty = validateNumber(qty);

    if (!isValidQty) {
        errors.qty = premiseAutoFillErrors.qty;
    }

    const isValidNumbering = validateNumber(numbering);

    if (!isValidNumbering) {
        errors.numbering = premiseAutoFillErrors.numbering;
    }

    isValid = isValidBuilding && isValidType && isValidFloor && isValidQty && isValidNumbering;

    return { isValid, errors };
}

export {
    validatePremise,
    validateAutoFillPremises
}