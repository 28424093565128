<template>
    <app-modal-wrapper>
        <div class="modal-upload-icons">
            <div class="upload-icons">
                <h4>{{ title }}</h4>
                <div class="upload-icons__upload">
                    <div class="app__label">
                        {{ label }}
                    </div>
                    <div v-if="!image.isUploaded" class="upload-icons__area">
                        <app-upload-area
                            :help-text="upload.helpText"
                            :btn-text="upload.btnText"
                            :accept-types="acceptTypes"
                            @uploaded="showUploadedFile"
                        />
                    </div>
                    <div v-else class="upload-icons__preview">
                        <div class="upload-icons__image"
                            :style="{
                                backgroundImage: `url(${image.url})`,
                                height: previewHeight
                            }"
                        />
                        <button type="button" class="upload-icons__clear-btn" @click="clearUploadedFile">
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="15" cy="15" r="15" fill="#E0ECFB"/>
                                <path d="M19.875 12.0062L18.9938 11.125L15.5 14.6188L12.0062 11.125L11.125 12.0062L14.6188 15.5L11.125 18.9938L12.0062 19.875L15.5 16.3812L18.9938 19.875L19.875 18.9938L16.3812 15.5L19.875 12.0062Z" fill="#2B3037"/>
                            </svg>
                        </button>
                    </div>
                </div>
                <div class="upload-icons__control">
                    <app-base-btn
                        :size="'full'"
                        :variation="'cancel'"
                        :text="$t('common.buttons.cancel')"
                        @action="closeModalUploadIcon"
                    />
                    <app-base-btn
                        :size="'full'"
                        :variation="'save'"
                        :text="btnText"
                        @action="updateUploadIcon"
                    />
                </div>
            </div>
        </div>
    </app-modal-wrapper>
</template>

<script>
    import AppUploadArea from "../../common/local/upload/AppUploadArea";
    import { uploadTypes } from "../../../constants/uploadFileTypes";
    import { logoTypes, iconTypes } from "../../../constants/uploadFileTypes";

    export default {
        name: "ModalUploadIcons",

        components: {
            AppUploadArea
        },

        props: {
            selectedIcon: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                image: {
                    isUploaded: false,
                    url: '',
                    base64: '',
                    file: ''
                }
            }
        },

        computed: {
            isLogo() {
                return this.selectedIcon.type === 'logo';
            },

            title() {
                return this.isLogo
                    ? this.$t('personalization.view.upload.title.logo')
                    : this.$t('personalization.view.upload.title.icon');
            },

            label() {
                return this.isLogo
                    ? this.$t('personalization.view.upload.label.logo')
                    : this.$t('personalization.view.upload.label.icon');
            },

            btnText() {
                return this.isLogo
                    ? this.$t('common.buttons.applyLogo')
                    : this.$t('common.buttons.applyIcon');
            },

            upload() {
                return uploadTypes['logo'];
            },

            acceptTypes() {
                return this.isLogo ? logoTypes : iconTypes;
            },

            previewHeight() {
                return this.isLogo ? '50px' : '150px';
            }
        },

        methods: {
            closeModalUploadIcon() {
                this.closeModal('modalUploadIcon');
            },

            showUploadedFile(url, convertedFile, file) {
                this.image.url = url;
                this.image.base64 = convertedFile;
                this.image.file = file;
                this.image.isUploaded = true;
            },

            updateUploadIcon() {
                if (this.image.url) {
                    const id = this.selectedIcon.id;
                    const url = this.image.url;
                    const file = this.image.file;

                    this.emitter.emit('updateUploadIcon', { id, url, file });

                    this.closeModalUploadIcon();
                }
            },

            clearUploadedFile() {
                this.image.url = '';
                this.image.file = '';
                this.image.isUploaded = false;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-upload-icons {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 512px;
    }

    .upload-icons {
        h4 {
            margin-bottom: 30px;
        }

        &__control {
            display: flex;
            margin-top: 45px;

            button:first-child {
                margin-right: 30px;
            }
        }

        &__area, &__preview {
            height: 230px;
        }

        &__preview {
            border: 1px solid var(--app-border);
            border-radius: 13px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
        }

        &__image {
            width: 150px;
            background-repeat: no-repeat;
            background-size: contain;
            background-color: transparent;
        }

        &__clear-btn {
            position: absolute;
            right: 15px;
            top: 15px;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            /*background: var(--grey40);*/
        }
    }
</style>

<style lang="scss" scoped>
    .upload-icons {
        .upload, .upload__area {
            height: 100%;
        }
    }
</style>

