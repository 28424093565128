function validatePhoneNumber(phoneNumber) {
    return !phoneNumber || /^\d+$/.test(phoneNumber);
}

function validateNumber(value) {
    return value && Number(value);
}

function validatePositiveNumber(value) {
    return value && Number(value) && Number(value) > 0;
}

export {
    validatePhoneNumber,
    validateNumber,
    validatePositiveNumber
}