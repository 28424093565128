import './styles/app.scss';

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from '../src/localization/i18n'
import axios from 'axios'
import VueAxios from 'vue-axios'
import mitt from 'mitt'
const emitter = mitt()

import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
const options = {
    position: "bottom-right",
    timeout: 5000,
    closeOnClick: false,
    draggable: false,
    transition: "my-custom-fade",
    pauseOnHover: true,
    hideProgressBar: true,
    closeButton: false,
    icon: false
}

import "./config/amplify"

import AppBaseInput from './components/common/global/AppBaseInput'
import AppTextBtn from './components/common/global/buttons/AppTextBtn'
import AppBaseBtn from './components/common/global/buttons/AppBaseBtn'
import AppAddBtn from './components/common/global/buttons/AppAddBtn'
import AppCircleBtn from './components/common/global/buttons/AppCircleBtn'
import AppCollapseBtn from './components/common/global/buttons/AppCollapseBtn'
import AppActionBtn from './components/common/global/buttons/AppActionBtn'
import AppDatePicker from "./components/common/global/AppDatePicker"
import AppBaseSelect from './components/common/global/AppBaseSelect'
import AppBaseSelectPeriod from "./components/common/global/AppBaseSelectPeriod"
import AppBaseMultipleSelect from './components/common/global/AppBaseMultipleSelect'
import AppBaseCheckbox from "./components/common/global/AppBaseCheckbox"
import AppBaseRadioBtn from "./components/common/global/AppBaseRadioBtn"
import AppBaseSearch from "./components/common/global/AppBaseSearch"
import AppBaseFilters from "./components/common/global/AppBaseFilters"
import AppBaseState from "./components/common/global/AppBaseState";
import AppMatchString from "./components/common/global/AppMatchString"
import AppModalWrapper from './components/common/global/AppModalWrapper'
import AppLoader from './components/common/global/AppLoader'
import AppEmptyContent from "./components/common/global/AppEmptyContent"
import AppNoResults from "./components/common/global/AppNoResults"
import AppOverflowMatchWrapper from "./components/common/global/AppOverflowMatchWrapper";

import { modalActionsMixin } from "./mixins/modalActions"

const app = createApp(App)
app.config.globalProperties.emitter = emitter

app.component('app-base-input', AppBaseInput)
app.component('app-text-btn', AppTextBtn)
app.component('app-base-btn', AppBaseBtn)
app.component('app-add-btn', AppAddBtn)
app.component('app-circle-btn', AppCircleBtn)
app.component('app-collapse-btn', AppCollapseBtn)
app.component('app-action-btn', AppActionBtn)
app.component('app-date-picker', AppDatePicker)
app.component('app-base-select', AppBaseSelect)
app.component('app-base-select-period', AppBaseSelectPeriod)
app.component('app-base-multiple-select', AppBaseMultipleSelect)
app.component('app-base-checkbox', AppBaseCheckbox)
app.component('app-base-radio-btn', AppBaseRadioBtn)
app.component('app-base-search', AppBaseSearch)
app.component('app-base-state', AppBaseState)
app.component('app-overflow-match-wrapper', AppOverflowMatchWrapper)
app.component('app-base-filters', AppBaseFilters)
app.component('app-match-string', AppMatchString)
app.component('app-modal-wrapper', AppModalWrapper)
app.component('app-loader', AppLoader)
app.component('app-empty-content', AppEmptyContent)
app.component('app-no-results', AppNoResults)

app
    .use(store)
    .use(router)
    .use(i18n)
    .use(VueAxios, axios)
    .use(Toast, options)
    .mixin(modalActionsMixin)
    .mount('#app')
