<template>
    <div v-html="match()" :class="{'can-overflow': canOverflow}"/>
</template>

<script>
    export default {
        name: "AppMatchString",

        props: {
            string: {
                type: String,
                required: true
            },
            subString: {
                type: String,
                required: true
            },
            canOverflow: {
                type: Boolean,
                default: true
            }
        },

        methods: {
            match() {
                //We have error with RegExp if string starts with '+'
                if(!this.subString || this.subString.startsWith('+')) {
                    return this.string;
                }

                return this.string.replace(new RegExp(this.subString, "gi"), match => {
                    return '<span class="app__highlight">' + match + '</span>';
                });
            }
        }
    }
</script>