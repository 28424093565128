<template>
    <div class="finance-operations-table">
        <div class="table__head">
            <div class="table__column operation__date">
                <app-overflow-match-wrapper
                    :text="$t('finance.operations.table.date')"
                />
            </div>
            <div class="table__column operation__amount">
                <app-overflow-match-wrapper
                    :text="$t('finance.operations.table.amount')"
                />
            </div>
            <div class="table__column operation__premise">
                <app-overflow-match-wrapper
                    :text="$t('finance.operations.table.premise')"
                />
            </div>
            <div class="table__column operation__type">
                <app-overflow-match-wrapper
                    :text="$t('finance.operations.table.type')"
                />
            </div>
            <div class="table__column operation__basis">
                <app-overflow-match-wrapper
                    :text="$t('finance.operations.table.basis')"
                />
            </div>
            <div class="table__column operation__actions"></div>
        </div>
        <div class="table__body" :style="{ 'maxHeight': maxHeight }">
            <finance-operation-row v-for="operation in operations" :key="operation.id"
                :id="operation.id"
                :date="operation.date"
                :amount="operation.amount"
                :premise="operation.premise"
                :type="operation.type"
                :basis="operation.basis"
                @delete="deleteOperation"
            />
        </div>
    </div>
</template>

<script>
    import FinanceOperationRow from "./FinanceOperationRow";

    export default {
        name: "FinanceOperationsTable",

        components: {
            FinanceOperationRow
        },

        props: {
            operations: {
                type: Array,
                required: true
            },
            maxHeight: {
                type: String,
                required: true
            }
        },

        methods: {
            deleteOperation(id) {
                const selectedElement = {
                    id: id,
                    type: 'operation'
                };

                this.openModal({
                    name: 'modalConfirm',
                    selectedEl: selectedElement
                })
            }
        }
    }
</script>