<template>
    <div class="cameras">
<!--        <div class="cameras__panel">-->
<!--            <div class="cameras__title">Відеокамери</div>-->
<!--        </div>-->
    </div>
</template>

<script>
    export default {
        name: "CamerasControl"
    }
</script>