<template>
    <label class="radio-btn"
        :class="{
            'radio-btn--is-checked': isChecked,
            'radio-btn--is-filter': isFilter
        }"
    >
        <input type="radio"
            v-model="radioValue"
            :name="name"
            :value="id"
        />
        <div class="radio-btn__label can-overflow">
            {{ label }}
        </div>
    </label>
</template>

<script>
    export default {
        name: "AppBaseRadioBtn",

        props: {
            modelValue: {
                type: [ String, Array ],
                required: true
            },
            id: {
                type: String,
                required: true
            },
            label: {
                type: String,
                required: true
            },
            name: {
                type: String,
                default: 'variants'
            },
            isFilter: {
                type: Boolean,
                default: false
            }
        },

        emits: ['update:modelValue'],

        computed: {
            isChecked() {
                return this.modelValue === this.id;
            },

            radioValue: {
                get() {
                    return this.modelValue;
                },
                set(val) {
                    this.$emit('update:modelValue', val);
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .radio-btn {
        display: flex;
        align-items: center;
        color: var(--app-base-primary);
        font-size: 16px;
        opacity: 0.3;
        transition: 0.3s;

        &:hover {
            cursor: pointer;
            opacity: 1;
        }

        input {
            appearance: none;
            border: 1px solid var(--app-border);
            border-radius: 50%;
            background-clip: content-box;
            padding: 3px;
            margin-right: 10px;
            width: 25px;
            height: 25px;

            &:hover {
                cursor: pointer;
            }

            &:hover:not(:checked) {
                border: 1px solid var(--app-base-primary);
            }
        }

        &--is-checked {
            opacity: 1;

            input {
                border: 1px solid var(--brand-main);
                background-color: var(--brand-main);
            }
        }

        &--is-filter {
            opacity: 1;
        }
    }
</style>