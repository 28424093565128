import { validateNumber } from "./common";
import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

const ranges = {
    entrances: {
        min: 1,
        max: 500
    },
    minFloor: {
        min: -10,
        max: 10
    },
    maxFloor: {
        min: 1,
        max: 500
    }
}

const groupBuildingsErrors = {
    name: t('validation.required')
}

const buildingErrors = {
    name: t('validation.required'),
    location: t('validation.building.location'),
    floors: t('validation.number'),
    entrances: t('validation.number'),
    floorsRange: t('validation.building.floorsRange'),
    minFloorRange: `
        ${t('validation.building.minFloorRange')} 
        ${t('validation.from')} 
        ${ranges.minFloor.min} 
        ${t('validation.to')} 
        ${ranges.minFloor.max}
    `,
    maxFloorRange: `
        ${t('validation.building.maxFloorRange')} 
        ${t('validation.from')} 
        ${ranges.maxFloor.min} 
        ${t('validation.to')} 
        ${ranges.maxFloor.max}
    `,
    entrancesRange: `
        ${t('validation.building.entrancesRange')} 
        ${t('validation.from')} 
        ${ranges.entrances.min} 
        ${t('validation.to')} 
        ${ranges.entrances.max}
    `,
}

function validateGroupBuildings(group) {
    const errors = {
        name: ''
    };

    let isValid = false;

    const isValidName = !!group.name;

    if (!isValidName) {
        errors.name = groupBuildingsErrors.name;
    } else {
        isValid = true;
    }

    return { isValid, errors };
}

function validateBuilding(building) {
    let isValid = false;

    const errors = {
        location: '',
        name: '',
        floors: '',
        minFloor: '',
        maxFloor: '',
        entrances: ''
    }

    const isValidName = !!building.name;

    if (!isValidName) {
        errors.name = buildingErrors.name;
    }

    const isValidLocation = validateBuildingLocation(building.location);

    if (!isValidLocation) {
        errors.location = buildingErrors.location;
    }

    const { isValidFloors, errorFloors } = validateFloors(building.minFloor, building.maxFloor);
    Object.assign(errors, errorFloors);

    let isValidEntrances = false;

    const isNumberEntrances = validateNumber(building.entrances);

    if (isNumberEntrances) {
        const isValidEntrancesRange = validateRange(building.entrances, 'entrances');

        if (!isValidEntrancesRange) {
            errors.entrances = buildingErrors.entrancesRange;
        } else {
            isValidEntrances = true;
        }
    } else {
        errors.entrances = buildingErrors.entrances;
    }

    isValid = isValidName && isValidLocation && isValidFloors && isValidEntrances;

    return { isValid, errors };
}

function validateRange(value, type) {
    return value <= ranges[type].max && value >= ranges[type].min;
}

function validateFloors(minFloor, maxFloor) {
    const errorFloors = {
        floors: '',
        minFloor: '',
        maxFloor: '',
    }

    let isValidFloors = false;
    let isValidMinFloorRange = false;
    let isValidMaxFloorRange = false;

    const isNumberMinFloor = validateNumber(minFloor);

    if (isNumberMinFloor) {
        isValidMinFloorRange = validateRange(minFloor, 'minFloor');

        if (!isValidMinFloorRange) {
            errorFloors.minFloor = 'isNotShowError';
            errorFloors.floors = buildingErrors.minFloorRange;
        }
    } else {
        errorFloors.minFloor = 'isNotShowError';
        errorFloors.floors = buildingErrors.floors;
    }

    const isNumberMaxFloor = validateNumber(maxFloor);

    if (isNumberMaxFloor) {
        isValidMaxFloorRange = validateRange(maxFloor, 'maxFloor');

        if (!isValidMaxFloorRange) {
            errorFloors.maxFloor = 'isNotShowError';
            errorFloors.floors = buildingErrors.maxFloorRange;
        }
    } else {
        errorFloors.maxFloor = 'isNotShowError';
        errorFloors.floors = buildingErrors.floors;
    }

    if (isValidMinFloorRange && isValidMaxFloorRange) {
        if (minFloor < maxFloor) {
            isValidFloors = true;
        } else {
            errorFloors.minFloor = 'isNotShowError';
            errorFloors.maxFloor = 'isNotShowError';
            errorFloors.floors = buildingErrors.floorsRange;
        }
    }

    return { isValidFloors, errorFloors }
}

function validateBuildingLocation(location) {
    return !!location && !!location.street && !!location.build_no;
}

export {
    validateGroupBuildings,
    validateBuilding
}