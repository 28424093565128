const allCountries = [
    {
        name: '+380',
        country: 'Ukraine',
        iso2: 'ua'
    },
    {
        name: '+93',
        country: 'Afghanistan',
        iso2: 'af'
    },
    {
        name: '+355',
        country: 'Albania',
        iso2: 'al'
    },
    {
        name: '+213',
        country: 'Algeria',
        iso2: 'dz'
    },
    {
        name: '+1684',
        country: 'American Samoa',
        iso2: 'as'
    },
    {
        name: '+376',
        country: 'Andorra',
        iso2: 'ad'
    },
    {
        name: '+244',
        country: 'Angola',
        iso2: 'ao'
    },
    {
        name: '+1264',
        country: 'Anguilla',
        iso2: 'ai'
    },
    {
        name: '+1268',
        country: 'Antigua and Barbuda',
        iso2: 'ag'
    },
    {
        name: '+54',
        country: 'Argentina',
        iso2: 'ar'
    },
    {
        name: '+374',
        country: 'Armenia',
        iso2: 'am'
    },
    {
        name: '+297',
        country: 'Aruba',
        iso2: 'aw'
    },
    {
        name: '+247',
        country: 'Ascension Island',
        iso2: 'ac'
    },
    {
        name: '+61',
        country: 'Australia',
        iso2: 'au'
    },
    {
        name: '+43',
        country: 'Austria',
        iso2: 'at'
    },
    {
        name: '+994',
        country: 'Azerbaijan',
        iso2: 'az'
    },
    {
        name: '+1242',
        country: 'Bahamas',
        iso2: 'bs'
    },
    {
        name: '+973',
        country: 'Bahrain',
        iso2: 'bh'
    },
    {
        name: '+880',
        country: 'Bangladesh',
        iso2: 'bd'
    },
    {
        name: '+1246',
        country: 'Barbados',
        iso2: 'bb'
    },
    {
        name: '+375',
        country: 'Belarus',
        iso2: 'by'
    },
    {
        name: '+32',
        country: 'Belgium',
        iso2: 'be'
    },
    {
        name: '+501',
        country: 'Belize',
        iso2: 'bz'
    },
    {
        name: '+229',
        country: 'Benin',
        iso2: 'bj'
    },
    {
        name: '+1441',
        country: 'Bermuda',
        iso2: 'bm'
    },
    {
        name: '+975',
        country: 'Bhutan',
        iso2: 'bt'
    },
    {
        name: '+591',
        country: 'Bolivia',
        iso2: 'bo'
    },
    {
        name: '+387',
        country: 'Bosnia and Herzegovina',
        iso2: 'ba'
    },
    {
        name: '+267',
        country: 'Botswana',
        iso2: 'bw'
    },
    {
        name: '+55',
        country: 'Brazil',
        iso2: 'br'
    },
    {
        name: '+246',
        country: 'British Indian Ocean Territory',
        iso2: 'io'
    },
    {
        name: '+1284',
        country: 'British Virgin Islands',
        iso2: 'vg'
    },
    {
        name: '+673',
        country: 'Brunei',
        iso2: 'bn'
    },
    {
        name: '+359',
        country: 'Bulgaria',
        iso2: 'bg'
    },
    {
        name: '+226',
        country: 'Burkina Faso',
        iso2: 'bf'
    },
    {
        name: '+257',
        country: 'Burundi',
        iso2: 'bi'
    },
    {
        name: '+855',
        country: 'Cambodia',
        iso2: 'kh'
    },
    {
        name: '+237',
        country: 'Cameroon',
        iso2: 'cm'
    },
    {
        name: '+1',
        country: 'Canada',
        iso2: 'ca'
    },
    {
        name: '+238',
        country: 'Cape Verde',
        iso2: 'cv'
    },
    {
        name: '+599',
        country: 'Caribbean Netherlands',
        iso2: 'bq'
    },
    {
        name: '+1345',
        country: 'Cayman Islands',
        iso2: 'ky'
    },
    {
        name: '+236',
        country: 'Central African Republic',
        iso2: 'cf'
    },
    {
        name: '+235',
        country: 'Chad',
        iso2: 'td'
    },
    {
        name: '+56',
        country: 'Chile',
        iso2: 'cl'
    },
    {
        name: '+86',
        country: 'China',
        iso2: 'cn'
    },
    {
        name: '+61',
        country: 'Christmas Island',
        iso2: 'cx'
    },
    {
        name: '+61',
        country: 'Cocos (Keeling) Islands',
        iso2: 'cc'
    },
    {
        name: '+57',
        country: 'Colombia',
        iso2: 'co'
    },
    {
        name: '+269',
        country: 'Comoros',
        iso2: 'km'
    },
    {
        name: '+243',
        country: 'Congo (DRC)',
        iso2: 'cd'
    },
    {
        name: '+242',
        country: 'Congo (Republic)',
        iso2: 'cg'
    },
    {
        name: '+682',
        country: 'Cook Islands',
        iso2: 'ck'
    },
    {
        name: '+506',
        country: 'Costa Rica',
        iso2: 'cr'
    },
    {
        name: '+225',
        country: 'Côte d’Ivoire',
        iso2: 'ci'
    },
    {
        name: '+385',
        country: 'Croatia',
        iso2: 'hr'
    },
    {
        name: '+53',
        country: 'Cuba',
        iso2: 'cu'
    },
    {
        name: '+599',
        country: 'Curaçao',
        iso2: 'cw'
    },
    {
        name: '+357',
        country: 'Cyprus',
        iso2: 'cy'
    },
    {
        name: '+420',
        country: 'Czech Republic',
        iso2: 'cz'
    },
    {
        name: '+45',
        country: 'Denmark',
        iso2: 'dk'
    },
    {
        name: '+253',
        country: 'Djibouti',
        iso2: 'dj'
    },
    {
        name: '+1767',
        country: 'Dominica',
        iso2: 'dm'
    },
    {
        name: '+1',
        country: 'Dominican Republic',
        iso2: 'do'
    },
    {
        name: '+593',
        country: 'Ecuador',
        iso2: 'ec'
    },
    {
        name: '+20',
        country: 'Egypt',
        iso2: 'eg'
    },
    {
        name: '+503',
        country: 'El Salvador',
        iso2: 'sv'
    },
    {
        name: '+240',
        country: 'Equatorial Guinea',
        iso2: 'gq'
    },
    {
        name: '+291',
        country: 'Eritrea',
        iso2: 'er'
    },
    {
        name: '+372',
        country: 'Estonia',
        iso2: 'ee'
    },
    {
        name: '+268',
        country: 'Eswatini',
        iso2: 'sz'
    },
    {
        name: '+251',
        country: 'Ethiopia',
        iso2: 'et'
    },
    {
        name: '+500',
        country: 'Falkland Islands',
        iso2: 'fk'
    },
    {
        name: '+298',
        country: 'Faroe Islands',
        iso2: 'fo'
    },
    {
        name: '+679',
        country: 'Fiji',
        iso2: 'fj'
    },
    {
        name: '+358',
        country: 'Finland',
        iso2: 'fi'
    },
    {
        name: '+33',
        country: 'France',
        iso2: 'fr'
    },
    {
        name: '+594',
        country: 'French Guiana',
        iso2: 'gf'
    },
    {
        name: '+689',
        country: 'French Polynesia',
        iso2: 'pf'
    },
    {
        name: '+241',
        country: 'Gabon',
        iso2: 'ga'
    },
    {
        name: '+220',
        country: 'Gambia',
        iso2: 'gm'
    },
    {
        name: '+995',
        country: 'Georgia',
        iso2: 'ge'
    },
    {
        name: '+49',
        country: 'Germany',
        iso2: 'de'
    },
    {
        name: '+233',
        country: 'Ghana',
        iso2: 'gh'
    },
    {
        name: '+350',
        country: 'Gibraltar',
        iso2: 'gi'
    },
    {
        name: '+30',
        country: 'Greece',
        iso2: 'gr'
    },
    {
        name: '+299',
        country: 'Greenland',
        iso2: 'gl'
    },
    {
        name: '+1473',
        country: 'Grenada',
        iso2: 'gd'
    },
    {
        name: '+590',
        country: 'Guadeloupe',
        iso2: 'gp'
    },
    {
        name: '+1671',
        country: 'Guam',
        iso2: 'gu'
    },
    {
        name: '+502',
        country: 'Guatemala',
        iso2: 'gt'
    },
    {
        name: '+44',
        country: 'Guernsey',
        iso2: 'gg'
    },
    {
        name: '+224',
        country: 'Guinea',
        iso2: 'gn'
    },
    {
        name: '+245',
        country: 'Guinea-Bissau',
        iso2: 'gw'
    },
    {
        name: '+592',
        country: 'Guyana',
        iso2: 'gy'
    },
    {
        name: '+509',
        country: 'Haiti',
        iso2: 'ht'
    },
    {
        name: '+504',
        country: 'Honduras',
        iso2: 'hn'
    },
    {
        name: '+852',
        country: 'Hong Kong',
        iso2: 'hk'
    },
    {
        name: '+36',
        country: 'Hungary',
        iso2: 'hu'
    },
    {
        name: '+354',
        country: 'Iceland',
        iso2: 'is'
    },
    {
        name: '+91',
        country: 'India',
        iso2: 'in'
    },
    {
        name: '+62',
        country: 'Indonesia',
        iso2: 'id'
    },
    {
        name: '+98',
        country: 'Iran',
        iso2: 'ir'
    },
    {
        name: '+964',
        country: 'Iraq',
        iso2: 'iq'
    },
    {
        name: '+353',
        country: 'Ireland',
        iso2: 'ie'
    },
    {
        name: '+44',
        country: 'Isle of Man',
        iso2: 'im'
    },
    {
        name: '+972',
        country: 'Israel',
        iso2: 'il'
    },
    {
        name: '+39',
        country: 'Italy',
        iso2: 'it'
    },
    {
        name: '+1876',
        country: 'Jamaica',
        iso2: 'jm'
    },
    {
        name: '+81',
        country: 'Japan',
        iso2: 'jp'
    },
    {
        name: '+44',
        country: 'Jersey',
        iso2: 'je'
    },
    {
        name: '+962',
        country: 'Jordan',
        iso2: 'jo'
    },
    {
        name: '+7',
        country: 'Kazakhstan',
        iso2: 'kz'
    },
    {
        name: '+254',
        country: 'Kenya',
        iso2: 'ke'
    },
    {
        name: '+686',
        country: 'Kiribati',
        iso2: 'ki'
    },
    {
        name: '+383',
        country: 'Kosovo',
        iso2: 'xk'
    },
    {
        name: '+965',
        country: 'Kuwait',
        iso2: 'kw'
    },
    {
        name: '+996',
        country: 'Kyrgyzstan',
        iso2: 'kg'
    },
    {
        name: '+856',
        country: 'Laos',
        iso2: 'la'
    },
    {
        name: '+371',
        country: 'Latvia',
        iso2: 'lv'
    },
    {
        name: '+961',
        country: 'Lebanon',
        iso2: 'lb'
    },
    {
        name: '+266',
        country: 'Lesotho',
        iso2: 'ls'
    },
    {
        name: '+231',
        country: 'Liberia',
        iso2: 'lr'
    },
    {
        name: '+218',
        country: 'Libya',
        iso2: 'ly'
    },
    {
        name: '+423',
        country: 'Liechtenstein',
        iso2: 'li'
    },
    {
        name: '+370',
        country: 'Lithuania',
        iso2: 'lt'
    },
    {
        name: '+352',
        country: 'Luxembourg',
        iso2: 'lu'
    },
    {
        name: '+853',
        country: 'Macau',
        iso2: 'mo'
    },
    {
        name: '+389',
        country: 'North Macedonia',
        iso2: 'mk'
    },
    {
        name: '+261',
        country: 'Madagascar',
        iso2: 'mg'
    },
    {
        name: '+265',
        country: 'Malawi',
        iso2: 'mw'
    },
    {
        name: '+60',
        country: 'Malaysia',
        iso2: 'my'
    },
    {
        name: '+960',
        country: 'Maldives',
        iso2: 'mv'
    },
    {
        name: '+223',
        country: 'Mali',
        iso2: 'ml'
    },
    {
        name: '+356',
        country: 'Malta',
        iso2: 'mt'
    },
    {
        name: '+692',
        country: 'Marshall Islands',
        iso2: 'mh'
    },
    {
        name: '+596',
        country: 'Martinique',
        iso2: 'mq'
    },
    {
        name: '+222',
        country: 'Mauritania',
        iso2: 'mr'
    },
    {
        name: '+230',
        country: 'Mauritius',
        iso2: 'mu'
    },
    {
        name: '+262',
        country: 'Mayotte',
        iso2: 'yt'
    },
    {
        name: '+52',
        country: 'Mexico',
        iso2: 'mx'
    },
    {
        name: '+691',
        country: 'Micronesia',
        iso2: 'fm'
    },
    {
        name: '+373',
        country: 'Moldova',
        iso2: 'md'
    },
    {
        name: '+377',
        country: 'Monaco',
        iso2: 'mc'
    },
    {
        name: '+976',
        country: 'Mongolia',
        iso2: 'mn'
    },
    {
        name: '+382',
        country: 'Montenegro',
        iso2: 'me'
    },
    {
        name: '+1664',
        country: 'Montserrat',
        iso2: 'ms'
    },
    {
        name: '+212',
        country: 'Morocco',
        iso2: 'ma'
    },
    {
        name: '+258',
        country: 'Mozambique',
        iso2: 'mz'
    },
    {
        name: '+95',
        country: 'Myanmar',
        iso2: 'mm'
    },
    {
        name: '+264',
        country: 'Namibia',
        iso2: 'na'
    },
    {
        name: '+674',
        country: 'Nauru',
        iso2: 'nr'
    },
    {
        name: '+977',
        country: 'Nepal',
        iso2: 'np'
    },
    {
        name: '+31',
        country: 'Netherlands',
        iso2: 'nl'
    },
    {
        name: '+687',
        country: 'New Caledonia',
        iso2: 'nc'
    },
    {
        name: '+64',
        country: 'New Zealand',
        iso2: 'nz'
    },
    {
        name: '+505',
        country: 'Nicaragua',
        iso2: 'ni'
    },
    {
        name: '+227',
        country: 'Niger',
        iso2: 'ne'
    },
    {
        name: '+234',
        country: 'Nigeria',
        iso2: 'ng'
    },
    {
        name: '+683',
        country: 'Niue',
        iso2: 'nu'
    },
    {
        name: '+672',
        country: 'Norfolk Island',
        iso2: 'nf'
    },
    {
        name: '+850',
        country: 'North Korea',
        iso2: 'kp'
    },
    {
        name: '+1670',
        country: 'Northern Mariana Islands',
        iso2: 'mp'
    },
    {
        name: '+47',
        country: 'Norway',
        iso2: 'no'
    },
    {
        name: '+968',
        country: 'Oman',
        iso2: 'om'
    },
    {
        name: '+92',
        country: 'Pakistan',
        iso2: 'pk'
    },
    {
        name: '+680',
        country: 'Palau',
        iso2: 'pw'
    },
    {
        name: '+970',
        country: 'Palestine',
        iso2: 'ps'
    },
    {
        name: '+507',
        country: 'Panama',
        iso2: 'pa'
    },
    {
        name: '+675',
        country: 'Papua New Guinea',
        iso2: 'pg'
    },
    {
        name: '+595',
        country: 'Paraguay',
        iso2: 'py'
    },
    {
        name: '+51',
        country: 'Peru',
        iso2: 'pe'
    },
    {
        name: '+63',
        country: 'Philippines',
        iso2: 'ph'
    },
    {
        name: '+48',
        country: 'Poland',
        iso2: 'pl'
    },
    {
        name: '+351',
        country: 'Portugal',
        iso2: 'pt'
    },
    {
        name: '+1',
        country: 'Puerto Rico',
        iso2: 'pr'
    },
    {
        name: '+974',
        country: 'Qatar',
        iso2: 'qa'
    },
    {
        name: '+262',
        country: 'Réunion',
        iso2: 're'
    },
    {
        name: '+40',
        country: 'Romania',
        iso2: 'ro'
    },
    {
        name: '+7',
        country: 'Russia',
        iso2: 'ru'
    },
    {
        name: '+250',
        country: 'Rwanda',
        iso2: 'rw'
    },
    {
        name: '+590',
        country: 'Saint Barthélemy',
        iso2: 'bl'
    },
    {
        name: '+290',
        country: 'Saint Helena',
        iso2: 'sh'
    },
    {
        name: '+1869',
        country: 'Saint Kitts and Nevis',
        iso2: 'kn'
    },
    {
        name: '+1758',
        country: 'Saint Lucia',
        iso2: 'lc'
    },
    {
        name: '+590',
        country: 'Saint Martin',
        iso2: 'mf'
    },
    {
        name: '+508',
        country: 'Saint Pierre and Miquelon',
        iso2: 'pm'
    },
    {
        name: '+1784',
        country: 'Saint Vincent and the Grenadines',
        iso2: 'vc'
    },
    {
        name: '+685',
        country: 'Samoa',
        iso2: 'ws'
    },
    {
        name: '+378',
        country: 'San Marino',
        iso2: 'sm'
    },
    {
        name: '+239',
        country: 'São Tomé and Príncipe',
        iso2: 'st'
    },
    {
        name: '+966',
        country: 'Saudi Arabia',
        iso2: 'sa'
    },
    {
        name: '+221',
        country: 'Senegal',
        iso2: 'sn'
    },
    {
        name: '+381',
        country: 'Serbia',
        iso2: 'rs'
    },
    {
        name: '+248',
        country: 'Seychelles',
        iso2: 'sc'
    },
    {
        name: '+232',
        country: 'Sierra Leone',
        iso2: 'sl'
    },
    {
        name: '+65',
        country: 'Singapore',
        iso2: 'sg'
    },
    {
        name: '+1721',
        country: 'Sint Maarten',
        iso2: 'sx'
    },
    {
        name: '+421',
        country: 'Slovakia',
        iso2: 'sk'
    },
    {
        name: '+386',
        country: 'Slovenia',
        iso2: 'si'
    },
    {
        name: '+677',
        country: 'Solomon Islands',
        iso2: 'sb'
    },
    {
        name: '+252',
        country: 'Somalia',
        iso2: 'so'
    },
    {
        name: '+27',
        country: 'South Africa',
        iso2: 'za'
    },
    {
        name: '+82',
        country: 'South Korea',
        iso2: 'kr'
    },
    {
        name: '+211',
        country: 'South Sudan',
        iso2: 'ss'
    },
    {
        name: '+34',
        country: 'Spain',
        iso2: 'es'
    },
    {
        name: '+94',
        country: 'Sri Lanka',
        iso2: 'lk'
    },
    {
        name: '+249',
        country: 'Sudan',
        iso2: 'sd'
    },
    {
        name: '+597',
        country: 'Suriname',
        iso2: 'sr'
    },
    {
        name: '+47',
        country: 'Svalbard and Jan Mayen',
        iso2: 'sj'
    },
    {
        name: '+46',
        country: 'Sweden',
        iso2: 'se'
    },
    {
        name: '+41',
        country: 'Switzerland',
        iso2: 'ch'
    },
    {
        name: '+963',
        country: 'Syria',
        iso2: 'sy'
    },
    {
        name: '+886',
        country: 'Taiwan',
        iso2: 'tw'
    },
    {
        name: '+992',
        country: 'Tajikistan',
        iso2: 'tj'
    },
    {
        name: '+255',
        country: 'Tanzania',
        iso2: 'tz'
    },
    {
        name: '+66',
        country: 'Thailand',
        iso2: 'th'
    },
    {
        name: '+670',
        country: 'Timor-Leste',
        iso2: 'tl'
    },
    {
        name: '+228',
        country: 'Togo',
        iso2: 'tg'
    },
    {
        name: '+690',
        country: 'Tokelau',
        iso2: 'tk'
    },
    {
        name: '+676',
        country: 'Tonga',
        iso2: 'to'
    },
    {
        name: '+1868',
        country: 'Trinidad and Tobago',
        iso2: 'tt'
    },
    {
        name: '+216',
        country: 'Tunisia',
        iso2: 'tn'
    },
    {
        name: '+90',
        country: 'Turkey',
        iso2: 'tr'
    },
    {
        name: '+993',
        country: 'Turkmenistan',
        iso2: 'tm'
    },
    {
        name: '+1649',
        country: 'Turks and Caicos Islands',
        iso2: 'tc'
    },
    {
        name: '+688',
        country: 'Tuvalu',
        iso2: 'tv'
    },
    {
        name: '+1340',
        country: 'U.S. Virgin Islands',
        iso2: 'vi'
    },
    {
        name: '+256',
        country: 'Uganda',
        iso2: 'ug'
    },
    {
        name: '+971',
        country: 'United Arab Emirates',
        iso2: 'ae'
    },
    {
        name: '+44',
        country: 'United Kingdom',
        iso2: 'gb'
    },
    {
        name: '+1',
        country: 'United States',
        iso2: 'us'
    },
    {
        name: '+598',
        country: 'Uruguay',
        iso2: 'uy'
    },
    {
        name: '+998',
        country: 'Uzbekistan',
        iso2: 'uz'
    },
    {
        name: '+678',
        country: 'Vanuatu',
        iso2: 'vu'
    },
    {
        name: '+39',
        country: 'Vatican City',
        iso2: 'va'
    },
    {
        name: '+58',
        country: 'Venezuela',
        iso2: 've'
    },
    {
        name: '+84',
        country: 'Vietnam',
        iso2: 'vn'
    },
    {
        name: '+681',
        country: 'Wallis and Futuna',
        iso2: 'wf'
    },
    {
        name: '+212',
        country: 'Western Sahara',
        iso2: 'eh'
    },
    {
        name: '+967',
        country: 'Yemen',
        iso2: 'ye'
    },
    {
        name: '+260',
        country: 'Zambia',
        iso2: 'zm'
    },
    {
        name: '+263"',
        country: 'Zimbabwe',
        iso2: 'zw'
    },
    {
        name: '+358',
        country: 'Åland Islands',
        iso2: 'ax'
    }
];

function getUniqueCodes() {
    const uniqueCountries = [];

    function codeCheck(item) {
        let codeIsExist = false;

        if (!uniqueCountries.includes(item.name)) {
            uniqueCountries.push(item.name);
            codeIsExist = true;
        }

        return codeIsExist;
    }

    return allCountries.filter((item) => codeCheck(item));
}

export {
    getUniqueCodes
}