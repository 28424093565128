<template>
    <div class="select"
         :class="{
            'select--is-disabled': isDisabled,
            'select--is-error': isError
         }"
    >
        <div v-if="showLabel" class="app__label">
            {{ label }}
            <span class="select__optional">
                {{ optionalText }}
            </span>
        </div>
        <div class="select__wrapper">
            <VueMultiselect :class="{
                'is-language': isLanguageSelect,
                'is-code': isPhoneCodeSelect,
                'is-admin': isAdminSelect,
                'is-text': isTextSelect,
                'is-sort': isSortSelect,
                'is-tariff': isTariffSelect,
                'is-user': isUser,
                'is-finance-user': isFinanceUserSelect
            }"
                            v-model="selectedValue"
                            :options="options"
                            :searchable="isSearchable"
                            :openDirection="openDirection"
                            :placeholder="placeHolder"
                            :show-labels="false"
                            :allow-empty="false"
                            :label="labelType"
                            :track-by="labelType"
                            @select="selectOption"
                            @open="focusSelect"
                            @close="closeSelect"
            >
                <template v-slot:singleLabel="{ option }">
                    <div class="select__option option">
                        <div v-if="isAdminSelect" class="admin-select">
                            <div class="admin-select__image">
                                <img v-if="!!option.avatar" :src="option.avatar" alt="Avatar">
                                <div v-else class="admin-select__image-ph">
                                    {{ option.fullName ? `${option.surname.charAt(0).toUpperCase()}${option.name.charAt(0).toUpperCase()}` : option.username.charAt(0).toUpperCase() }}
                                </div>
                            </div>
                            <div class="admin-select__info can-overflow">
                                {{ `${ option.fullName ? `${option.fullName}, `: '' } ${option.username}` }}
                            </div>
                        </div>
                        <div v-else class="option__text can-overflow">{{ option.name }}</div>
                    </div>
                </template>
                <template v-slot:option="{ option }">
                    <div class="select__option option">
                        <div v-if="isAdminSelect" class="admin-select">
                            <div class="admin-select__image admin-select__image--is-list">
                                <img v-if="!!option.avatar" :src="option.avatar" alt="Avatar">
                                <div v-else class="admin-select__image-ph">
                                    {{ option.fullName ? `${option.surname.charAt(0).toUpperCase()}${option.name.charAt(0).toUpperCase()}` : option.username.charAt(0).toUpperCase() }}
                                </div>
                            </div>
                            <div class="admin-select__details">
                                <div v-if="option.fullName" class="admin-select__name">
                                    {{ option.fullName }}
                                </div>
                                <div class="admin-select__login">
                                    {{ option.username }}
                                </div>
                            </div>
                        </div>
                        <div v-else class="option__text can-overflow">
                            {{ option.name }}
                        </div>
                    </div>
                </template>
                <template v-slot:noResult>
                    {{ noResultText }}
                </template>
                <template v-slot:noOptions>
                    {{ noDataText }}
                </template>
                <!--            <template #caret="{toggle}"-->
                <!--            >-->
                <!--                <div @mousedown.prevent.stop="toggle" class="select__caret">-->
                <!--                    <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">-->
                <!--                        <path d="M8.875 1.50625L7.99375 0.625L4.5 4.11875L1.00625 0.625L0.125 1.50625L3.61875 5L0.125 8.49375L1.00625 9.375L4.5 5.88125L7.99375 9.375L8.875 8.49375L5.38125 5L8.875 1.50625Z" fill="#A4AFBE"/>-->
                <!--                    </svg>-->
                <!--                </div>-->
                <!--            </template>-->
            </VueMultiselect>
            <div v-if="selectedValue && isClear" class="select__clear">
                <button type="button" @click="clearSelectedOption">
                    <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.875 1.50625L7.99375 0.625L4.5 4.11875L1.00625 0.625L0.125 1.50625L3.61875 5L0.125 8.49375L1.00625 9.375L4.5 5.88125L7.99375 9.375L8.875 8.49375L5.38125 5L8.875 1.50625Z" fill="#A4AFBE"/>
                    </svg>
                </button>
            </div>
        </div>
        <div v-if="showError" class="select__error">
            {{ errorMessage }}
        </div>
    </div>
</template>

<script>
    import cloneDeep from "lodash.clonedeep";
    import VueMultiselect from 'vue-multiselect';
    import "vue-multiselect/dist/vue-multiselect.css";
    import { filtersText } from "../../../constants/filters";

    export default {
        name: "AppBaseSelect",

        components: {
            VueMultiselect
        },

        props: {
            label: {
                type: String,
                default: ''
            },
            name: {
                type: String,
                default: 'select'
            },
            isSearchable: {
                type: Boolean,
                default: false
            },
            placeHolder: {
                type: String,
                default: ''
            },
            openDirection: {
                type: String,
                default: 'bottom',
                validator(direction) {
                    return ['top', 'bottom'].includes(direction);
                }
            },
            selectedOption: {
                type: Object
            },
            options: {
                type: Array,
                required: true
            },
            type: {
                type: String,
                default: 'text',
                validator(type) {
                    return ['text', 'image', 'icon', 'notice', 'language', 'code', 'admin', 'sort', 'tariff', 'financeUser'].includes(type);
                }
            },
            errorMessage: {
                type: String,
                default: ''
            },
            isUser: {
                type: Boolean,
                default: false
            },
            isDisabled: {
                type: Boolean,
                default: false
            },
            isBalancer: {
                type: Boolean,
                default: false
            },
            required: {
                type: Boolean,
                default: true
            },
            isClear: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                selectedValue: null,
            }
        },

        created() {
            this.initSelect();
        },

        //Need to watch because we get some data from backend
        watch: {
            selectedOption(option) {
                if (option && !this.isFirstInit) {
                    this.initSelect();
                    this.isFirstInit = true;
                }
            },
        },

        computed: {
            isError() {
                return !!this.errorMessage;
            },

            showError() {
                return !!this.errorMessage && this.errorMessage !== 'isNotShowError';
            },

            showLabel() {
                return !!this.label;
            },

            isTextSelect() {
                return this.type === 'text';
            },
            //
            // isImageSelect() {
            //     return this.type === 'image';
            // },
            //
            // isIconSelect() {
            //     return this.type === 'icon';
            // },
            //
            // isNoticeSelect() {
            //     return this.type === 'notice';
            // },

            isLanguageSelect() {
                return this.type === 'language';
            },

            isPhoneCodeSelect() {
                return this.type === 'code';
            },

            isAdminSelect() {
                return this.type === 'admin';
            },

            isSortSelect() {
                return this.type === 'sort';
            },

            isTariffSelect() {
                return this.type === 'tariff';
            },

            isFinanceUserSelect() {
                return this.type === 'financeUser';
            },

            labelType() {
                //TOD0: NEED TO THINK ABOUT THIS
                return this.isAdminSelect ? 'username' : 'name';
            },

            // isTopOpenDirection() {
            //     return this.openDirection === 'top';
            // },

            optionalText() {
                return this.required ? '' : ` ${this.$t('common.optional')}`;
            },

            noResultText() {
                return filtersText.noResult;
            },

            noDataText() {
                return filtersText.noData;
            }
        },

        methods: {
            initSelect() {
                if (this.selectedOption) {
                    this.selectedValue = cloneDeep(this.selectedOption);
                }
            },

            selectOption(option) {
                this.$emit('select', option);
            },

            clearSelectedOption() {
                this.selectedValue = null;
                this.$emit('select', null);
            },

            focusSelect() {
                this.$emit('focus', this.name);
            },

            closeSelect() {
                if (this.isBalancer) {
                    this.selectedValue = null;
                }

                this.$emit('close', this.name);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .admin-select {
        display: flex;
        align-items: center;
        font-size: 16px;

        &__info {
            max-width: 400px;
        }

        &__image {
            margin-right: 15px;
            width: 24px;
            height: 24px;

            img {
                max-width: 100%;
                object-fit: cover;
                border-radius: 50%;
                border: 1px solid transparent;
                transition: border 0.2s;
            }

            &--is-list {
                margin-right: 10px;
                width: 50px;
                height: 50px;

                .admin-select__image-ph {
                    font-size: 16px;
                }
            }
        }

        &__image-ph {
            width: 100%;
            height: 100%;
            background: var(--brand-pink);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--system-white);
            font-size: 12px;
        }

        &__details {
            display: flex;
            flex-direction: column;
        }

        &__name {
            margin-bottom: 5px;
            font-weight: 600;
            font-size: 14px;
        }

        &__login {
            font-weight: 400;
            font-size: 12px;
        }
    }
</style>

<style lang="scss">
    .option {
        &__text {
            max-width: 380px;
        }
    }

    .is-user {
        .option {
            &__text {
                max-width: 440px;
            }
        }
    }

    .is-finance-user {
        .option {
            &__text {
                max-width: 480px;
            }
        }
    }

    .select {
        position: relative;
        width: 100%;

        &__wrapper {
            position: relative;
        }

        /*&__caret {*/
        /*    width: 15px;*/
        /*    height: 15px;*/
        /*    display: flex;*/
        /*    align-items: center;*/
        /*    justify-content: center;*/
        /*    position: absolute;*/
        /*    bottom: 16px;*/
        /*    right: 10px;*/
        /*}*/

        &__clear {
            position: absolute;
            right: 32px;
            top: 11px;
            background: transparent;
            height: 24px;
            border-right: 1px solid var(--app-border);

            button {
                padding-right: 10px;
            }
        }

        &__optional {
            font-size: 12px;
            font-style: italic;
        }

        /*.is-notice {*/
        /*    .multiselect__tags {*/
        /*        padding: 8px 40px 0 10px;*/
        /*        border: none;*/
        /*        background: var(--white);*/
        /*        box-shadow: none;*/
        /*    }*/

        /*    .multiselect__option {*/
        /*        padding: 8px 15px;*/
        /*        min-height: unset;*/
        /*    }*/

        /*    .multiselect__content-wrapper {*/
        /*        border-top: 1px solid var(--green);*/
        /*        border-bottom: 1px solid var(--green);*/
        /*        border-radius: 13px;*/
        /*    }*/

        /*    .multiselect__single {*/
        /*        font-weight: bold;*/
        /*        font-size: 18px;*/
        /*    }*/

        /*    .select__option {*/
        /*        display: flex;*/
        /*        align-items: center;*/

        /*        img {*/
        /*            margin-right: 15px;*/
        /*        }*/

        /*        div {*/
        /*            padding-top: 2px;*/
        /*        }*/
        /*    }*/
        /*}*/

        /*.is-image {*/
        /*    .multiselect__tags {*/
        /*        padding: 8px 40px 0 10px;*/
        /*        background: transparent;*/
        /*        box-shadow: none;*/
        /*    }*/

        /*    .multiselect__option {*/
        /*        padding: 8px 15px;*/
        /*        min-height: unset;*/
        /*    }*/

        /*    .multiselect__content-wrapper {*/
        /*        max-height: 200px !important;*/
        /*    }*/

        /*    .select__option {*/
        /*        display: flex;*/
        /*        align-items: center;*/

        /*        img {*/
        /*            margin-right: 15px;*/
        /*        }*/
        /*    }*/
        /*}*/

        /*.is-icon {*/
        /*    .multiselect__tags {*/
        /*        padding: 10px 40px 0 10px;*/
        /*        border: none;*/
        /*        background: transparent;*/
        /*        box-shadow: none;*/
        /*    }*/

        /*    .multiselect__option {*/
        /*        padding: 8px 13px;*/
        /*        min-height: unset;*/
        /*    }*/

        /*    .multiselect__content-wrapper {*/
        /*        border-top: 1px solid var(--green);*/
        /*        border-bottom: 1px solid var(--green);*/
        /*        border-radius: 13px;*/
        /*    }*/
        /*}*/
        .is-sort {
            .multiselect__select {
                height: 44px;
            }

            .multiselect__tags {
                border: 1px solid var(--app-border);
                padding: 12px 40px 0 8px;
            }

            .multiselect__content-wrapper {
                background: var(--system-white);
                box-shadow: 0px 16px 32px rgba(35, 23, 5, 0.26);
                border-radius: 13px;
                border: none;
                margin-top: 10px;
                padding: 5px 0px;
            }

            .multiselect--active {
                .multiselect__tags {
                    border-color: var(--brand-main) !important;
                }
            }

            .option__text {
                max-width: 254px;
            }

            .multiselect__single {
                .option__text {
                    max-width: 220px;
                }
            }
        }

        .is-text, .is-finance-user {
            .multiselect__select {
                height: 44px;
            }

            .multiselect__tags {
                border: 1px solid var(--app-border);
                padding: 12px 40px 0 8px;
            }

            .multiselect__content-wrapper {
                background: var(--system-white);
                box-shadow: 0px 16px 32px rgba(35, 23, 5, 0.26);
                border-radius: 13px;
                border: none;
                margin-top: 10px;
                padding: 5px 0px;
                max-height: 160px !important;
            }

            .multiselect--active {
                .multiselect__tags {
                    border-color: var(--brand-main) !important;
                }
            }
        }

        .is-language {
            .multiselect__single {
                font-weight: 500;
                background: transparent;
                color: var(--app-base-primary);
            }

            .multiselect__tags {
                padding: 12px 35px 0 10px;
                border: none;
                background: transparent;
                box-shadow: none;

            }

            .multiselect__option {
                padding: 8px 13px;
                min-height: unset;
            }

            .multiselect__content-wrapper {
                border-radius: 13px;
            }

            .multiselect__select {
                height: 40px;
            }

            .multiselect__select:before {
                border-color: var(--app-base-primary) transparent transparent transparent;
            }
        }

        .is-code {
            .multiselect__tags {
                padding: 10px 35px 0 6px;
                border: none;
                background: var(--system-white);
                box-shadow: none;
                width: 100px;
                min-height: 40px !important;
            }

            .multiselect__content-wrapper {
                border-radius: 13px;
                max-height: 200px !important;
                border: none;
            }
        }

        .is-tariff {
            .multiselect__tags {
                padding: 10px 35px 0 6px;
                border: none;
                background: var(--system-white);
                box-shadow: none;
                width: 200px;
                min-height: 40px !important;
            }

            .multiselect__content-wrapper {
                border-radius: 13px;
                max-height: 200px !important;
                border: none;
            }
        }

        .is-admin {
            .multiselect__tags {
                background: transparent;
                box-shadow: none;
                padding: 10px 48px 0 10px;
            }

            .multiselect__content-wrapper {
                background: var(--system-white);
                box-shadow: 0px 16px 32px rgba(35, 23, 5, 0.26);
                border-radius: 13px;
                border: none;
                margin-top: 10px;
                padding: 5px 0px;
                max-height: 210px !important;
            }

            .multiselect__option {
                padding: 15px 10px;
                min-height: unset;
            }

            .multiselect__select {
                height: 44px;
            }
        }

        /*.is-text.is-top-direction.multiselect--active {*/
        /*    .multiselect__tags {*/
        /*        border-bottom: 1px solid var(--green);*/
        /*    }*/
        /*}*/

        /*.is-text.is-top-direction {*/
        /*    .multiselect__content-wrapper {*/
        /*        border-top: 1px solid var(--green);*/
        /*        border-radius: 13px 13px 0px 0px;*/
        /*    }*/
        /*}*/

        /*.is-balancer {*/
        /*    .multiselect__placeholder {*/
        /*        font-size: 14px;*/
        /*        color: var(--grey40);*/
        /*    }*/
        /*}*/

        &--is-disabled {
            pointer-events: none;

            .app__label, .multiselect__single, .multiselect__placeholder {
                opacity: 0.5 !important;
            }
        }

        &--is-error {
            .multiselect__tags {
                border: 1px solid var(--system-red) !important;
            }
        }

        &__error {
            margin-top: 10px;
            font-size: 14px;
            color: var(--system-red);
        }
    }
</style>