import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

const premiseTypes = [
    {
        id: 'living',
        name: t('premises.types.living'),
        background: 'var(--system-green-15)'
    },
    {
        id: 'apts',
        name: t('premises.types.apartments'),
        background: 'var(--system-iris-15)'
    },
    {
        id: 'commercial',
        name: t('premises.types.commercial'),
        background: 'var(--system-blue-15)'
    },
    {
        id: 'technical',
        name: t('premises.types.technical'),
        background: 'var(--system-red-15)'
    },
    {
        id: 'parking',
        name: t('premises.types.parking'),
        background: 'var(--system-orange-15)'
    }
]

export {
    premiseTypes
}