<template>
    <app-modal-wrapper>
        <div class="modal-contract" ref="modalContract">
            <form @submit.prevent="doActionContract" class="contract-form">
                <h4>{{ $t('finance.contract.title') }}</h4>
                <div class="contract-form__info">
                    <div class="contract-form__details">
                        <app-base-input
                            v-model="contract.number"
                            :required="false"
                            :label="$t('finance.contract.number.label')"
                            :place-holder="$t('finance.contract.number.ph')"
                        />
                    </div>
                    <div class="contract-form__details">
                        <div class="contract-form__label">
                            <div>{{ $t('finance.contract.template.label') }}</div>
                            <router-link :to="{ path: '/finance/templates'}" target="_blank">
                                <app-text-btn
                                    :text="$t('finance.contract.template.btn')"
                                />
                            </router-link>
                        </div>
                        <div class="contract-form__template">
                            <app-base-select ref="templates"
                                :place-holder="$t('finance.contract.template.ph')"
                                :selected-option="selectedTemplate"
                                :options="templates"
                                :type="'text'"
                                :is-disabled="isLoadedTemplates"
                                :error-message="errors.selectedTemplate"
                                @select="selectTemplate"
                            />
                        </div>
                    </div>
                </div>
                <div class="contract-form__info contract-form__info--is-period">
                    <div class="contract-form__details">
                        <app-date-picker
                            :label="$t('finance.contract.date.label')"
                            :init-date="contract.startDate"
                            @update="selectStartDate"
                        />
                    </div>
                    <div class="contract-form__details">
                        <app-base-select
                            :label="$t('finance.contract.period.label')"
                            :place-holder="$t('finance.contract.period.ph')"
                            :selected-option="selectedPeriod"
                            :options="periods"
                            :type="'text'"
                            :error-message="errors.selectedPeriod"
                            @select="selectPeriod"
                        />
                        <div class="contract-form__auto-extension">
                            <app-base-checkbox
                                :label="$t('finance.contract.autorenewal')"
                                :value="'auto'"
                                v-model="contract.isAutomaticExtension"
                            />
                        </div>
                    </div>
                </div>
                <div class="contract-form__info contract-form__info--is-selects">
                    <div class="contract-form__info--is-select-row">
                        <div class="contract-form__details">
                            <div class="contract-form__building">
                                <app-base-select
                                    :label="$t('finance.contract.building.ph')"
                                    :place-holder="$t('finance.contract.building.ph')"
                                    :selected-option="selectedBuilding"
                                    :options="buildings"
                                    :type="'text'"
                                    :error-message="errors.selectedBuilding"
                                    @select="selectBuilding"
                                />
                            </div>
                        </div>
                        <div class="contract-form__details">
                            <div class="contract-form__floor">
                                <app-base-select ref="floor"
                                    :label="$t('finance.contract.floor.ph')"
                                    :place-holder="$t('finance.contract.floor.ph')"
                                    :is-clear="true"
                                    :selected-option="selectedFloor"
                                    :options="floors"
                                    :type="'text'"
                                    :required="false"
                                    @select="selectFloor"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="contract-form__info--is-select-row">
                        <div class="contract-form__details">
                            <div class="contract-form__entrance">
                                <app-base-select ref="entrance"
                                    :label="$t('finance.contract.entrance.ph')"
                                    :place-holder="$t('finance.contract.entrance.ph')"
                                    :is-clear="true"
                                    :selected-option="selectedEntrance"
                                    :options="entrances"
                                    :type="'text'"
                                    :required="false"
                                    @select="selectEntrance"
                                />
                            </div>
                        </div>
                        <div class="contract-form__details">
                            <div class="contract-form__user">
                                <div class="contract-form__label">
                                    <div>{{ $t('finance.contract.user.label') }}</div>
                                    <app-text-btn
                                        :text="$t('finance.contract.user.btn')"
                                        @action="openModalUser"
                                    />
                                </div>
                                <app-base-select ref="user"
                                    :place-holder="$t('finance.contract.user.ph')"
                                    :is-searchable="true"
                                    :is-disabled="isLoadedUsers"
                                    :selected-option="selectedUser"
                                    :options="users"
                                    :type="'text'"
                                    :is-user="true"
                                    :error-message="errors.selectedUser"
                                    @select="selectUser"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="showPremises" class="contract-form__info contract-form__info--is-premises">
                    <h5>{{ $t('finance.contract.premises.title') }}</h5>
                    <div class="contract-form__info contract-form__info--is-checkboxes">
                        <div class="contract-form__details">
                            <div class="contract-form__premises">
                                <div class="contract-form__premise">
                                    <app-base-checkbox
                                        v-model="isSelectAll"
                                        :value="'selectAll'"
                                        :label="$t('finance.contract.premises.all')"
                                        @input="selectAllPremises"
                                    />
                                </div>
                                <div v-for="premise in premises" :key="premise.id" class="contract-form__premise">
                                    <app-base-checkbox
                                        v-model="selectedPremises"
                                        :value="premise.id"
                                        :label="premise.name"
                                        @input="selectPremise"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="contract-form__details"></div>
                    </div>
                    <div v-if="errors.selectedPremises" class="contract-form__error">
                        {{ errors.selectedPremises }}
                    </div>
                </div>
                <div v-if="selectedPremises.length" class="contract-form__tariff">
                    <div v-for="premise in filteredPremises" :key="premise.id">
                        <contract-tariff-row v-if="selectedPremises.includes(premise.id)"
                            :is-update="isUpdate"
                            :id="premise.id"
                            :name="premise.name"
                            :area="premise.area"
                            :total-price="premise.total"
                            :price="premise.price"
                            :type="premise.type"
                            @update="updateTariffValue"
                        />
                    </div>
                    <div class="contract-form__amount">
                        <div class="contract-form__details"></div>
                        <div class="contract-form__details">
                            <div class="contract-form__total">
                                <div>{{ $t('finance.contract.premises.total') }}</div>
                                <div class="contract-form__total-value">
                                    {{ formattedTotal }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="contract-form__control">
                    <app-base-btn
                        :size="'fixed'"
                        :variation="'cancel'"
                        :text="$t('common.buttons.cancel')"
                        @action="closeModalContract"
                    />
                    <app-base-btn
                        :size="'fixed'"
                        :variation="'cancel'"
                        :text="$t('common.buttons.preview')"
                        @action="doActionContractDocument('preview')"
                    >
                        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.7501 12.1243V15.2494H2.25008V12.1243H0.166748V15.2494C0.166748 16.3952 1.10425 17.3327 2.25008 17.3327H14.7501C15.8959 17.3327 16.8334 16.3952 16.8334 15.2494V12.1243H14.7501ZM13.7084 7.95768L12.2397 6.48893L9.54175 9.17643V0.666016H7.45842V9.17643L4.7605 6.48893L3.29175 7.95768L8.50008 13.166L13.7084 7.95768Z" fill="#2B3037"/>
                        </svg>
                    </app-base-btn>
                    <app-base-btn
                        :type="'submit'"
                        :size="'fixed'"
                        :variation="'save'"
                        :text="saveBtnText"
                    />
                </div>
            </form>
        </div>
    </app-modal-wrapper>
</template>

<script>
    import ContractTariffRow from "../../finance/contracts/ContractTariffRow";

    import cloneDeep from "lodash.clonedeep";
    import { formatCurrencyValue, getPeriodsContracts } from "../../../helpers/finance";
    import { clearDependentSelects } from "../../../helpers/selects";
    import { formatTimestampToDate, formatTimestampToExpirationDate } from "../../../helpers/date";
    import { mapUsersToView, mapUsersToSelect, mapUserPremisesToView } from "../../../api/mappers/users";
    import { mapContractTemplatesToView } from "../../../api/mappers/finance/templates";
    import { validateContract } from "../../../validations/finance/contracts";
    import { mapContractToSend } from "../../../api/mappers/finance/contracts";
    import { contractTariffs } from "../../../constants/finance";
    import { contractsToastsMixin } from "../../../mixins/toasts/finance/contracts";

    import Docxtemplater from "docxtemplater";
    import PizZip from "pizzip";
    import PizZipUtils from "pizzip/utils/index.js";
    import { saveAs } from "file-saver";

    import { djinAPI } from "../../../config/api";
    import { mapGetters } from "vuex";
    import i18n from "../../../localization/i18n";

    export default {
        name: "ModalContract",

        components: {
            ContractTariffRow
        },

        props: {
            selectedContract: {
                type: Object
            }
        },

        data() {
            return {
                isLoadedUsers: false,
                isLoadedPremises: false,
                isChangedBuilding: false,
                isLoadedTemplates: false,

                isSelectAll: false,
                contract: {
                    number: '',
                    startDate: Date.now(),
                    isAutomaticExtension: true
                },
                templates: [],
                selectedTemplate: null,
                selectedPeriod: null,
                selectedBuilding: null,
                selectedEntrance: null,
                selectedFloor: null,
                selectedUser: null,
                total: 0,
                users: [],
                premises: [],
                selectedPremises: [],

                errors: {
                    selectedTemplate: '',
                    selectedPeriod: '',
                    selectedBuilding: '',
                    selectedUser: '',
                    selectedPremises: ''
                }
            }
        },

        mixins: [
            contractsToastsMixin
        ],

        created() {
            this.checkUpdateContract();
            this.getContractTemplates();
        },

        mounted() {
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromEvents();
        },

        computed: {
            ...mapGetters({
                buildings: 'buildings/mappedBuildings',
            }),

            isUpdate() {
                return !!this.selectedContract;
            },

            saveBtnText() {
                return this.isUpdate ? this.$t('common.buttons.save') : this.$t('common.buttons.createContract');
            },

            periods() {
                return getPeriodsContracts();
            },

            entrances() {
                return this.selectedBuilding?.entrances || [];
            },

            floors() {
                return this.selectedBuilding?.floors || [];
            },

            showPremises() {
                return this.selectedUser?.info && !this.isLoadedPremises;
            },

            filteredPremises() {
                return this.premises.filter((premise) => this.selectedPremises.includes(premise.id));
            },

            formattedTotal() {
                const { balance, currency } = formatCurrencyValue(this.total);

                return `${balance} ${currency}`;
            },

            tariffs() {
                return contractTariffs;
            },

            langCode() {
                return i18n.global.locale;
            }
        },

        methods: {
            subscribeToEvents() {
                window.addEventListener("focus", this.getContractTemplates);
                this.emitter.on('updateContractUser', this.updateContractUser);
            },

            unsubscribeFromEvents() {
                window.removeEventListener("focus", this.getContractTemplates);
                this.emitter.off('updateContractUser', this.updateContractUser);
            },

            checkUpdateContract() {
                if (this.isUpdate) {
                    this.contract = cloneDeep(this.selectedContract);

                    this.selectedTemplate = {
                        id: this.contract.templateId
                    }

                    this.selectedPeriod = this.periods.find((period) => period.id === this.contract.period);

                    this.selectedUser = {
                        id: this.contract.userId,
                        name: this.contract.userName,
                        info: this.contract.userName,
                        premises: this.contract.premises
                    }

                    this.getUserPremises(this.contract.userId);
                }
            },

            // <----------GET SOME DATA FOR CONTRACTS---------->
            getContractTemplates() {
                this.isLoadedTemplates = true;

                djinAPI.get('/finance/templates')
                    .then((result) => {
                        const { contracts, ...rest } = result.data;
                        this.templates = mapContractTemplatesToView(contracts);

                        if (this.selectedTemplate) {
                            const template = this.templates.find((template) => template.id === this.selectedTemplate.id);

                            //Need to do this, because we don't have templates right now
                            if (this.isUpdate) {
                                this.selectedTemplate = template;
                            }

                            if (!template) {
                                //Clear selected template if it has been deleted
                                this.$refs.templates.clearSelectedOption();
                            }
                        }
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.isLoadedTemplates = false;
                        }, 500)
                    })
            },

            getBuildingUsers(params) {
                this.isLoadedUsers = true;

                djinAPI.get(`/users`, params)
                    .then((result) => {
                        const users = mapUsersToView(result.data);
                        this.users = mapUsersToSelect(users);
                    })
                    .finally(() => {
                        this.isLoadedUsers = false;
                    })
            },

            getUserPremises(userId) {
                this.isLoadedPremises = true;

                djinAPI.get(`/users/${userId}`)
                    .then((result) => {
                        const user = result.data;
                        const premises = mapUserPremisesToView(user.premises);

                        if (this.isUpdate) {
                            if (this.contract.userId === this.selectedUser.id) {
                                this.selectedPremises = this.contract.premises.map((premise) => premise.id);
                            }

                            this.selectedBuilding = this.buildings.find((building) => building.id === premises[0].building.id);
                        }

                        this.premises = premises.map((premise) => {
                            const updatedPremise = this.isUpdate ? this.contract.premises.find((contractPremise) => contractPremise.id === premise.id) : null;
                            const isExistArea = premise.premise.area !== 0;

                            return {
                                id: premise.premise.id,
                                name: premise.premise.name,
                                area: premise.premise.area,
                                price: updatedPremise ? updatedPremise.price : 0,
                                total: updatedPremise ? updatedPremise.total : 0,
                                type: updatedPremise ? updatedPremise.type : isExistArea ? 'tariff' : 'fixed',
                                isValid: false
                            }
                        })

                        //Need to reset here for computed and scroll correct work
                        this.isLoadedPremises = false;
                        this.scrollModalToBottom();
                    })
                    .finally(() => {
                        this.isLoadedPremises = false;
                    })
            },
            // <----------GET SOME DATA FOR CONTRACTS---------->

            // <----------SELECTS LOGIC---------->
            selectTemplate(template) {
                this.selectedTemplate = template;
            },

            selectStartDate(date) {
                this.contract.startDate = date;
            },

            selectBuilding(building) {
                this.selectedBuilding = building;
                //Need to set isChangedBuilding not to make 2 extra requests
                this.isChangedBuilding = true;

                this.selectedPremises = [];
                this.clearDependentFields();
                this.setParamsBuildingUsers();

                this.isChangedBuilding = false;
            },

            selectEntrance(entrance) {
                this.selectedEntrance = entrance;
                this.clearSelectedUser();

                if (!this.isChangedBuilding) {
                    this.setParamsBuildingUsers();
                }
            },

            selectFloor(floor) {
                this.selectedFloor = floor;
                this.clearSelectedUser();

                if (!this.isChangedBuilding) {
                    this.setParamsBuildingUsers();
                }
            },

            setParamsBuildingUsers() {
                const filterParams = [];
                const buildingId = this.selectedBuilding.id;

                filterParams.push(`building:${buildingId}`);

                if (this.selectedEntrance) {
                    const entranceId = this.selectedEntrance.id;
                    filterParams.push(`entrance:${entranceId}`);
                }

                if (this.selectedFloor) {
                    const floorId = this.selectedFloor.id;
                    filterParams.push(`floor:${floorId}`);
                }

                const filterString = `${filterParams.join(';')}`;

                this.getBuildingUsers({
                    params: {
                        filter: filterString
                    }
                });
            },

            updateContractUser(id) {
                djinAPI.get(`/users/${id}`)
                    .then((result) => {
                        const users = mapUsersToView([result.data]);
                        const user = mapUsersToSelect(users)[0];
                        this.selectUser(user);
                    })
            },

            selectUser(user) {
                this.selectedUser = user;

                if (this.selectedUser) {
                    this.errors.selectedUser = '';

                    if (this.selectedUser.info) {
                        this.getUserPremises(this.selectedUser.id);
                    } else {
                        this.errors.selectedUser = this.$t('validation.contract.selectedUserInfo');
                    }
                } else {
                    this.premises = [];
                }
            },

            selectPeriod(period) {
                this.selectedPeriod = period;
            },

            selectAllPremises() {
                if (!this.isSelectAll) {
                    this.selectedPremises = this.premises.map((premise) => premise.id);
                } else {
                    this.selectedPremises = [];
                }

                this.scrollModalToBottom();
            },

            selectPremise() {
                setTimeout(() => {
                    this.scrollModalToBottom();
                },100)
            },

            clearSelectedUser() {
                this.$refs.user.clearSelectedOption();
            },

            clearDependentFields() {
                const dependentSelects = {
                    entrance: this.$refs.entrance,
                    floor: this.$refs.floor,
                    user: this.$refs.user
                };

                clearDependentSelects(dependentSelects);
            },
            // <----------SELECTS LOGIC---------->

            // <----------CRU CONTRACTS---------->
            doActionContract() {
                const isValid = this.validateContract();

                if (isValid) {
                    if (!this.isUpdate) {
                        this.createContract();
                    } else {
                        this.changeContract();
                    }
                }
            },

            createContract() {
                const contract = { ...this.contract };
                contract.templateId = this.selectedTemplate.id;
                contract.period = this.selectedPeriod.id;
                contract.userId = this.selectedUser.id;
                contract.premises = this.filteredPremises;

                const mappedContract = mapContractToSend(contract);

                djinAPI.post('/finance/contracts', mappedContract)
                    .then((result) => {
                        // this.showCreateInvoiceTemplateToast();
                        const id = result.data._id;
                        this.doActionContractDocument('send', id);
                    })
            },

            changeContract() {
                const contract = { ...this.contract };
                contract.templateId = this.selectedTemplate.id;
                contract.period = this.selectedPeriod.id;
                contract.userId = this.selectedUser.id;
                contract.premises = this.filteredPremises;

                const mappedContract = mapContractToSend(contract);
                const id = this.contract.id;

                djinAPI.patch(`/finance/contracts/${id}`, mappedContract)
                    .then((result) => {
                        // this.showCreateInvoiceTemplateToast();
                        const id = result.data._id;
                        this.doActionContractDocument('send', id);
                    })
            },

            validateContract() {
                this.clearContractsErrors();
                const { isValid, errors } = validateContract(
                    this.contract,
                    this.selectedTemplate,
                    this.selectedPeriod,
                    this.selectedBuilding,
                    this.selectedUser,
                    this.selectedPremises
                );
                this.errors = errors;

                this.emitter.emit('validateTariff');
                const isValidAllTariffs = this.filteredPremises.every(premise => premise.isValid);

                return isValid && isValidAllTariffs;
            },

            clearContractsErrors() {
                for (let key in this.errors) {
                    this.errors[key] = '';
                }
            },

            doActionContractDocument(action, id) {
                const isValid = this.validateContract();

                if (isValid) {
                    const link = this.selectedTemplate.link;
                    const contract = this.contract;
                    const user = this.selectedUser;

                    const premiseName = this.isUpdate ? user.premises[0].name : user.premises[0].premise.name;
                    const buildingAddress = this.selectedBuilding.location.address[this.langCode];
                    const address = buildingAddress
                        ? buildingAddress
                        : this.selectedBuilding.location.address.en
                            ? this.selectedBuilding.location.address.en
                            : `${this.$t('buildings.building.address.unknown')}, 0`;
                    const userAddress = address ? `${address}, ${premiseName}` : premiseName;

                    console.log(userAddress);

                    const premises = this.filteredPremises;
                    const period = this.selectedPeriod;
                    const total = this.total;
                    const vm = this;

                    this.loadFile(link, function(
                        error,
                        content
                    ) {
                        if (error) {
                            throw error;
                        }
                        const zip = new PizZip(content);

                        const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true });
                        doc.setData({
                            'номер_договора': contract.number,
                            'дата_заключения': formatTimestampToDate(contract.startDate),
                            'имя_и_фамилия_клиента': user.info,
                            'адрес_клиента': userAddress,
                            'телефон_клиента': user.phone,
                            'почта_клиента': user.email,
                            'личный_номер': user.documentId,
                            'помещения': premises.map((premise) => {
                                const isFixed = premise.type === 'fixed';

                                return {
                                    'имя': premise.name,
                                    'площадь_помещения': premise.area !== 0 ? premise.area.toFixed(2) : '',
                                    'тариф_за_кв.м': !isFixed ? premise.price.toFixed(2) : '',
                                    'за_месяц': !isFixed ? premise.total.toFixed(2) : premise.price.toFixed(2)
                                }
                            }),
                            'итого': total.toFixed(2),
                            'срок_действия': period.name,
                            'дата_окончания': formatTimestampToExpirationDate(contract.startDate, period.id)
                        });
                        try {
                            // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
                            doc.render();
                        } catch (error) {
                            // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
                            function replaceErrors(key, value) {
                                if (value instanceof Error) {
                                    return Object.getOwnPropertyNames(value).reduce(function(
                                        error,
                                        key
                                        ) {
                                            error[key] = value[key];
                                            return error;
                                        },
                                        {});
                                }
                                return value;
                            }
                            console.log(JSON.stringify({ error: error }, replaceErrors));

                            if (error.properties && error.properties.errors instanceof Array) {
                                const errorMessages = error.properties.errors
                                    .map(function(error) {
                                        return error.properties.explanation;
                                    })
                                    .join("\n");
                                console.log("errorMessages", errorMessages);
                                // errorMessages is a humanly readable message looking like this :
                                // 'The tag beginning with "foobar" is unopened'
                            }
                            throw error;
                        }

                        const out = doc.getZip().generate({
                            type: "blob",
                            mimeType:
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        });

                        if (action === 'preview') {
                            // Output the document using Data-URI
                            saveAs(out, "contract.docx");
                        } else {
                            const file = new File([ out ], 'contract.docx');
                            vm.uploadContract(id, file);
                        }
                    });
                }
            },

            loadFile(url, callback) {
                PizZipUtils.getBinaryContent(url, callback);
            },

            uploadContract(id, file) {
                const data = new FormData();
                data.append('document', file);

                djinAPI.post(`finance/contracts/${id}/document`, data, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                    .then(() => {
                        if (!this.isUpdate) {
                            this.showCreateToast();
                        }

                        this.updateContracts();
                    })
            },

            updateContracts() {
                this.closeModalContract()
                this.emitter.emit('updateContracts');
            },
            // <----------CRU CONTRACTS---------->
            scrollModalToBottom() {
                this.$nextTick(() => {
                    //Scroll modal to bottom
                    const modal = this.$refs.modalContract;
                    modal.scrollTop = modal.scrollHeight;
                })
            },

            updateTariffValue(id, price, total, type, isValid) {
                const premise = this.filteredPremises.find((premise) => premise.id === id);
                premise.price = parseFloat(price);
                premise.total = parseFloat(total);
                premise.type = type;
                premise.isValid = isValid;
                this.total = this.getTotal(this.filteredPremises);
            },

            getTotal(premises) {
                let sum = 0;

                premises.forEach((premise) => {
                    if (premise.total) {
                        sum += premise.total;
                    }
                });

                return sum;
            },

            openModalUser() {
                this.clearSelectedEl();

                this.openModal({
                    name: 'modalUser'
                });
            },

            closeModalContract() {
                this.closeModal('modalContract');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-contract {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        max-width: 1118px;
        width: 100%;
        max-height: 80vh;
        overflow-y: auto;
        scroll-behavior: smooth;
    }

    .contract-form {
        &__info {
            display: flex;
            padding: 30px 0;
            border-bottom: 1px solid var(--app-border);

            &--is-period {
                align-items: flex-start;
            }

            &--is-premises {
                flex-direction: column;
                border-top: 1px solid var(--app-border);
                border-bottom: unset;
            }

            h5 {
                margin-bottom: 30px;
            }

            &--is-selects {
                flex-direction: column;
                border-bottom: unset;
            }

            &--is-select-row {
                display: flex;
                border-bottom: unset;
            }

            &--is-checkboxes {
                padding: 0;
                border-bottom: unset;
            }
        }

        &__details {
            flex-basis: 50%;

            &:first-child {
                margin-right: 30px;
            }
        }

        &__control {
            display: flex;
            justify-content: flex-end;
            margin-top: 45px;

            button:not(:last-child) {
                margin-right: 30px;
            }
        }

        &__label {
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 16px;
            height: 16px;
            color: var(--app-base-secondary);
            margin-bottom: 10px;

            button {
                margin: 0 0 2px 10px;
            }
        }

        &__auto-extension {
            margin-top: 15px;
        }

        &__building, &__floor {
            margin-bottom: 15px;
        }

        &__premise {
            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }

        &__amount {
            display: flex;
        }

        &__tariff {
            display: flex;
            flex-direction: column;
            border-top: 1px solid var(--app-border);
        }

        &__total {
            display: flex;
            justify-content: space-between;
            padding-top: 30px;
            font-size: 16px;
        }

        &__total-value {
            font-weight: 500;
        }

        &__error {
            margin-top: 10px;
            font-size: 14px;
            color: var(--system-red);
        }
    }
</style>