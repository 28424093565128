<template>
    <div class="upload-image"
        :class="{
            'is-fixed': isFixedHeight
        }"
    >
        <div v-if="showLabel" class="app__label">
            {{ label }}
            <span class="upload-image__optional">
                {{ optionalText }}
            </span>
        </div>
        <div v-if="!image.isUploaded && !image.isLoaded" class="upload-image__upload">
            <app-upload-area
                :help-text="upload.helpText"
                :btn-text="upload.btnText"
                :accept-types="acceptTypes"
                @uploaded="showUploadedFile"
            />
        </div>
        <div v-else class="upload-image__preview preview">
            <div v-if="isBuilding" class="preview__building"
                :class="{
                    'preview__building--is-uk': isLangUk,
                    'preview__building--is-en': isLangEn,
                    'preview__building--is-ru': isLangRu,
                }"
            >
                <div class="preview__logo">
                    <img v-if="image.url && !image.isLoaded" :src="image.url" alt="Logo">
                    <app-loader v-if="image.isLoaded" class="loader"
                                :size="'small'"
                    />
                </div>
                <div class="preview__template"></div>
                <app-circle-btn class="preview__delete-btn"
                    @action="deleteImage"
                >
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.875 4.00625L10.9938 3.125L7.5 6.61875L4.00625 3.125L3.125 4.00625L6.61875 7.5L3.125 10.9938L4.00625 11.875L7.5 8.38125L10.9938 11.875L11.875 10.9938L8.38125 7.5L11.875 4.00625Z" fill="#2B3037"/>
                    </svg>
                </app-circle-btn>
            </div>
            <div v-else class="preview__type">
                <div class="preview__image">
                    <transition name="opacity">
                        <img v-if="image.url && !image.isLoaded" :src="image.url" alt="Logo">
                    </transition>
                    <app-loader v-if="image.isLoaded" class="loader"
                        :size="'small'"
                    />
                </div>
                <app-circle-btn class="preview__delete-btn"
                    @action="deleteImage"
                >
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.875 4.00625L10.9938 3.125L7.5 6.61875L4.00625 3.125L3.125 4.00625L6.61875 7.5L3.125 10.9938L4.00625 11.875L7.5 8.38125L10.9938 11.875L11.875 10.9938L8.38125 7.5L11.875 4.00625Z" fill="#2B3037"/>
                    </svg>
                </app-circle-btn>
            </div>
        </div>
    </div>
</template>

<script>
    import i18n from "../../../../localization/i18n";
    import AppUploadArea from "./AppUploadArea";
    import { uploadTypes } from "../../../../constants/uploadFileTypes";

    export default {
        name: "AppUploadPreview",

        components: {
            AppUploadArea
        },

        props: {
            label: {
                type: String,
                default: '',
            },
            type: {
                type: String,
                required: true,
                validator(type) {
                    return ['image', 'logo'].includes(type);
                }
            },
            acceptTypes: {
                type: Array,
                default() {
                    return [];
                }
            },
            url: {
                type: String,
                required: true
            },
            isDisabled: {
                type: Boolean,
                default: false
            },
            height: {
                type: String,
                default: 'fixed',
                validator(height) {
                    return ['full', 'fixed'].includes(height);
                }
            },
            previewType: {
                type: String,
                default: 'image',
                validator(previewType) {
                    return ['image', 'building'].includes(previewType);
                }
            },
            required: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                image: {
                    isLoaded: false,
                    isUploaded: false,
                    url: '',
                    base64: '',
                    file: '',
                    isChanged: false
                }
            }
        },

        created() {
            this.initImage();
        },

        computed: {
            types() {
                return uploadTypes;
            },

            isFixedHeight() {
                return this.height === 'fixed';
            },

            isBuilding() {
                return this.previewType === 'building';
            },

            optionalText() {
                return this.required ? '' : ` ${this.$t('common.optional')}`;
            },

            upload() {
                return this.types[this.type];
            },

            isLangRu() {
                return this.langCode === 'ru';
            },

            isLangUk() {
                return this.langCode === 'uk';
            },

            isLangEn() {
                return this.langCode === 'en';
            },

            langCode() {
                return i18n.global.locale;
            },

            showLabel() {
                return this.label;
            }
        },

        methods: {
            initImage() {
                if (this.url) {
                    this.image.url = this.url;
                    //Imitate step with upload
                    this.image.isUploaded = true;
                    //TODO: ANOTHER LOGIC FOR GET IMAGE
                    // this.getImage(this.url);
                }
            },

            // async getImage(imageUrl) {
            //     this.image.isLoaded = true;
            //
            //     await getImageFromUrl(imageUrl)
            //         .then((image) => {
            //             this.image.url = image;
            //             //Imitate step with upload
            //             this.image.isUploaded = true;
            //         })
            //         .finally(() => {
            //             this.image.isLoaded = false;
            //         })
            // },

            showUploadedFile(url, convertedFile, file) {
                this.image.url = url;
                this.image.base64 = convertedFile;
                this.image.file = file;
                this.image.isUploaded = true;

                this.$emit('update', {
                    base64: this.image.base64,
                    file: this.image.file,
                    isChanged: this.image.isChanged
                })
            },

            deleteImage() {
                this.image.isUploaded = false;
                this.image.url = '';
                this.image.base64 = '';
                this.image.file = '';

                if (this.url && !this.image.isChanged) {
                    this.image.isChanged = true;
                }

                this.$emit('update', {
                    base64: '',
                    file: '',
                    isChanged: this.image.isChanged
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .upload-image {
        &__upload {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__optional {
            font-size: 12px;
            font-style: italic;
        }
    }

    .preview {
        position: relative;
        border: 1px solid var(--grey08);
        border-radius: 13px;

        &__delete-btn {
            position: absolute;
            right: 15px;
            top: 15px;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background: var(--grey40);
        }

        &__delete-btn.is-specific {
            background: none;
        }

        &__type {
            padding: 8px 37px;
            width: 100%;
            height: 100%;
        }

        &__image {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }

        &__building {
            height: 100%;
            width: 100%;
            position: relative;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            border-radius: 13px;

            &--is-ru {
                background-image: url("../../../../assets/mobile_bckg_min_ru.png");
            }

            &--is-uk {
                background-image: url("../../../../assets/mobile_bckg_min_uk.png");
            }

            &--is-en {
                background-image: url("../../../../assets/mobile_bckg_min_en.png");
            }
        }

        &__template {
            position: absolute;
            top: 0px;
            height: 100%;
            width: 100%;
            background-image: url("../../../../assets/template.png");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            border-radius: 13px;
        }

        &__logo {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            height: 52px;
            line-height: 52px;
            max-width: 300px;
            top: 20%;
            left: 50%;
            transform: translate(-50%, 0%);

            img {
                max-width: 100%;
                max-height: 100%;
                object-fit: cover;
            }
        }
    }
</style>

<style lang="scss">
    .upload-image.is-fixed {
        .preview, .upload__area {
            height: 230px;
        }
    }
</style>