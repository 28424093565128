<template>
    <div class="action-btn" @click.stop="handlerClick">
        <slot/>
        <span>{{ text }}</span>
    </div>
</template>

<script>
    export default {
        name: "AppActionBtn",

        props: {
            id: {
                type: String,
                required: true
            },
            text: {
                type: String,
                required: true
            }
        },

        methods: {
            handlerClick() {
                this.$emit('action', this.id);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .action-btn {
        padding: 10px 15px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        font-size: 16px;
        color: var(--app-base-primary);

        &:hover {
            cursor: pointer;
            background: var(--app-btn-secondary);
        }
    }
</style>

<style lang="scss">
    .action-btn {
        svg {
            margin-right: 15px;
        }
    }
</style>