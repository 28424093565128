function mapContractsToView(contracts) {
    return contracts.map((contract) => {
        return {
            id: contract._id,
            number: contract.contract_no ? contract.contract_no : '',
            name: contract.contract_no ? `№${contract.contract_no}` : '',
            link: contract.link,
            templateId: contract.template_id,
            startDate: contract.start_date,
            endDate: contract.end_date,
            period: contract.validity_period,
            isAutomaticExtension: contract.automatic_extension,
            state: contract.status,
            tariff: contract.tariff,
            userId: contract.user,
            userName: contract.user_name,
            userDocument: contract.user_document ? contract.user_document : '',
            premises: mapContractPremisesToView(contract.premises)
        }
    })
}

function mapContractPremisesToView(premises) {
    return premises.map((premise) => {
        return {
            id: premise.id,
            name: premise.name,
            type: premise.pay_type,
            price: premise.price,
            total: premise.totalPrice
        }
    })
}

function mapContractsQtyTypesToView(qty) {
    return {
        unsigned: qty.unsigned,
        expired: qty.expired_soon,
        overdue: qty.overdue
    }
}

function mapContractToSend(contract) {
    const contractToSend = {
        contract_no: contract.number,
        template_id: contract.templateId,
        start_date: contract.startDate,
        validity_period: contract.period,
        automatic_extension: contract.isAutomaticExtension,
        user: contract.userId,
        premises: mapContractPremisesToSend(contract.premises)
    }

    return contractToSend;
}

function mapContractPremisesToSend(premises) {
    return premises.map((premise) => {
        return {
            id: premise.id,
            pay_type: premise.type,
            price: premise.price
        }
    })
}

export {
    mapContractsToView,
    mapContractsQtyTypesToView,
    mapContractToSend
}