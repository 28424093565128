import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

const financeMenuItems = [
    {
        id: '1',
        name: t('finance.menu.main'),
        route: '/finance/main'
    },
    {
        id: '2',
        name: t('finance.menu.contracts'),
        route: '/finance/contracts'
    },
    {
        id: '3',
        name: t('finance.menu.invoices'),
        route: '/finance/invoices'
    },
    {
        id: '4',
        name: t('finance.menu.statements'),
        route: '/finance/statements'
    },
    {
        id: '5',
        name: t('finance.menu.review'),
        route: '/finance/review'
    },
    {
        id: '6',
        name: t('finance.menu.templates'),
        route: '/finance/templates'
    },
    {
        id: '7',
        name: t('finance.menu.operations'),
        route: '/finance/operations'
    }
]

const financePremisesTypes = {
    flat: t('finance.data.premises.flat'),
    parking: t('finance.data.premises.parking'),
    pantry: t('finance.data.premises.pantry')
}

const contractStates = [
    {
        id: 0,
        name: t('finance.contract.state.sent'),
        background: 'var(--system-blue-15)'
    },
    {
        id: 1,
        name: t('finance.contract.state.actual'),
        background: 'var(--system-green-15)'
    },
    {
        id: 2,
        name: t('finance.contract.state.expires'),
        background: 'var(--system-orange-15)'
    },
    {
        id: 3,
        name: t('finance.contract.state.overdue'),
        background: 'var(--system-red-15)'
    },
    {
        id: 4,
        name: t('finance.contract.state.terminate'),
        background: 'var(--app-base-primary-15)'
    }
]

const invoiceStates = [
    {
        id: 0,
        name: t('finance.invoice.state.sent'),
        background: 'var(--system-iris-15)'
    },
    {
        id: 1,
        name: t('finance.invoice.state.paid'),
        background: 'var(--system-green-15)'
    },
    {
        id: 2,
        name: t('finance.invoice.state.overdue'),
        background: 'var(--system-red-15)'
    },
    {
        id: 3,
        name: t('finance.invoice.state.overpayment'),
        background: 'var(--system-blue-15)'
    },
    {
        id: 4,
        name: t('finance.invoice.state.underpayment'),
        background: 'var(--system-orange-15)'
    },
    {
        id: 5,
        name: t('finance.invoice.state.unknown'),
        background: 'var(--app-base-primary-15)'
    }
]

const reviewInvoiceStates = [
    {
        id: 0,
        name: t('finance.invoice.state.unknown'),
        background: 'var(--app-base-primary-15)'
    },
    {
        id: 1,
        name: t('finance.invoice.state.underpayment'),
        background: 'var(--system-orange-15)'
    },
    {
        id: 2,
        name: t('finance.invoice.state.overpayment'),
        background: 'var(--system-blue-15)'
    },
    {
        id: 3,
        name: t('finance.invoice.state.unknownPayment'),
        background: 'var(--app-base-primary-15)'
    },
    {
        id: 4,
        name: t('finance.invoice.state.unknownInvoice'),
        background: 'var(--system-red-15)'
    }
]

const financePanelBtnTypes = {
    send: {
        text: t('finance.actions.send.btn'),
        actions: [
            {
                id: '1',
                name: t('finance.actions.send.email'),
            }
            // {
            //     id: '2',
            //     name: t('finance.actions.send.sms'),
            // }
        ]
    },
    delete: {
        text: t('finance.actions.delete.btn'),
        actions: []
    }
}

const contractTariffs = [
    {
        id: 'tariff',
        name: t('finance.contract.premises.tariff.tariff'),
    },
    {
        id: 'fixed',
        name: t('finance.contract.premises.tariff.fixed'),
    },
]

const financeInvoiceGroups = [
    {
        id: '1',
        name: t('finance.invoice.from.group'),
    },
]

const financeInvoiceTypes = [
    {
        id: '1',
        name: t('finance.invoice.type.now')
    },
    {
        id: '2',
        name: t('finance.invoice.type.current')
    }
]

const financeContractStates = [
    {
        id: '0',
        name: t('finance.cards.contracts.unsigned')
    },
    {
        id: '2',
        name: t('finance.cards.contracts.expired')
    },
    {
        id: '3',
        name: t('finance.cards.contracts.overdue')
    }
]

const financeInvoiceStates = [
    {
        id: '3',
        name: t('finance.cards.invoices.overpayment')
    },
    {
        id: '4',
        name: t('finance.cards.invoices.underpayment')
    },
    {
        id: '2',
        name: t('finance.cards.invoices.overdue')
    }
]

const financeBalanceFilters = [
    {
        id: 'debt',
        name: t('filters.balance.debt')
    },
    {
        id: 'overpay',
        name: t('filters.balance.overpay')
    },
    {
        id: 'zero',
        name: t('filters.balance.zero')
    },
]

const financeInvoiceReviewStates = [
    {
        id: '1',
        name: t('finance.invoice.state.underpayment')
    },
    {
        id: '2',
        name: t('finance.invoice.state.overpayment')
    },
    {
        id: '3',
        name: t('finance.invoice.state.unknownPayment')
    },
    {
        id: '4',
        name: t('finance.invoice.state.unknownInvoice')
    }
]

const operationTypes = [
    {
        id: 'service',
        name: t('finance.operations.operation.type.service'),
        background: 'var(--system-blue-15)'
    },
    {
        id: 'periodic',
        name: t('finance.operations.operation.type.periodic'),
        background: 'var(--system-iris-15)'
    }
]

const operationAmountTypes = [
    {
        id: 1,
        name: t('finance.operations.operation.sumType.positive')
    },
    {
        id: 2,
        name: t('finance.operations.operation.sumType.negative')
    }
]

const operationServices = [
    {
        id: 'service',
        name: t('finance.services')
    }
]

const statementViewTypes = [
    {
        id: 'weeks',
        name: t('filters.view.week')
    }
]

const statementOperationTypes = [
    {
        id: 'debit',
        name: t('filters.operation.debit')
    },
    {
        id: 'credit',
        name: t('filters.operation.credit')
    }
]

export {
    financeMenuItems,
    financePremisesTypes,
    contractStates,
    invoiceStates,
    financePanelBtnTypes,
    contractTariffs,
    financeInvoiceGroups,
    financeInvoiceTypes,
    financeContractStates,
    financeInvoiceStates,
    financeBalanceFilters,
    reviewInvoiceStates,
    operationTypes,
    operationAmountTypes,
    operationServices,
    statementViewTypes,
    statementOperationTypes
}