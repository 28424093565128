<template>
    <div class="table__row operation-row">
        <div class="table__column operation__date">
            <app-overflow-match-wrapper
                :text="formattedDate"
            />
        </div>
        <div class="table__column operation__amount">
            <finance-total-item
                :is-operation-amount="true"
                :balance="amount"
            />
        </div>
        <div class="table__column operation__premise">
            <app-overflow-match-wrapper
                :text="premise"
            />
        </div>
        <div class="table__column operation__type">
            <operation-type
                :type="type"
            />
        </div>
        <div class="table__column operation__basis">
            <app-overflow-match-wrapper
                :text="basis"
            />
        </div>
        <div class="table__column operation__actions">
            <app-circle-btn
                :is-delete="true"
                @action="deleteOperation"
            >
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 5.625V11.875H5V5.625H10ZM9.0625 1.875H5.9375L5.3125 2.5H3.125V3.75H11.875V2.5H9.6875L9.0625 1.875ZM11.25 4.375H3.75V11.875C3.75 12.5625 4.3125 13.125 5 13.125H10C10.6875 13.125 11.25 12.5625 11.25 11.875V4.375Z" fill="#FF3B30"/>
                </svg>
            </app-circle-btn>
        </div>
    </div>
</template>

<script>
    import { formatTimestampToDate } from "../../../helpers/date";
    import FinanceTotalItem from "../main/FinanceTotalItem";
    import OperationType from "./OperationType";

    export default {
        name: "FinanceOperationRow",

        components: {
            FinanceTotalItem,
            OperationType
        },

        props: {
            id: {
                type: String,
                required: true
            },
            date: {
                type: Number,
                required: true
            },
            amount: {
                type: Number,
                required: true
            },
            premise: {
                type: String,
                required: true
            },
            type: {
                type: String,
                required: true
            },
            basis: {
                type: String,
                required: true
            }
        },

        computed: {
            formattedDate() {
                return formatTimestampToDate(this.date);
            }
        },

        methods: {
            deleteOperation() {
                this.$emit('delete', this.id);
            }
        }
    }
</script>

<style lang="scss">
    .operation-row.table__row {
        min-height: 60px;
    }
</style>