function createEntrancesList(entranceQty) {
    return [...Array(entranceQty).keys()].map(entrance => {
        const numberEntrance = entrance + 1;

        return {
            id: numberEntrance,
            name: numberEntrance
        }
    });
}

function createFloorsList(minFloor, maxFloor, isZeroFloor) {
    let floorsList = [];

    floorsList = [...Array(maxFloor - minFloor + 1).keys()].map(floor => {
        const numberFloors = floor + minFloor;

        return {
            id: numberFloors,
            name: numberFloors
        }
    });

    if (!isZeroFloor) {
        floorsList = floorsList.filter((floor) => floor.id !== 0);
    } else {
        if (minFloor >= 1) {
            floorsList.unshift({
                id: 0,
                name: 0
            })
        }
    }

    return floorsList;
}

export {
    createEntrancesList,
    createFloorsList
}