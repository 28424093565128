<template>
    <div class="advanced-template">
        <div class="advanced-template__types">
            <advanced-color-type
                :current-color="colorSystemRed"
                :text="$t('templates.types.medicine')"
            />
            <advanced-color-type
                :current-color="colorSystemOrange"
                :text="$t('templates.types.best')"
            />
            <advanced-color-type
                :current-color="colorSystemYellow"
                :text="$t('templates.types.auto')"
            />
            <advanced-color-type
                :current-color="colorSystemGreen"
                :text="$t('templates.types.beauty')"
            />
            <advanced-color-type
                :current-color="colorSystemBlue"
                :text="$t('templates.types.restaurant')"
            />
            <advanced-color-type
                :current-color="colorSystemIris"
                :text="$t('templates.types.sport')"
            />
            <advanced-color-type
                :current-color="colorSystemPurple"
                :text="$t('templates.types.shopping')"
            />
            <advanced-color-type
                :current-color="colorSystemPink"
                :text="$t('templates.types.entertainment')"
            />
        </div>
        <div class="advanced-template__toasts">
            <advanced-color-toast
                :index="0"
                :current-color="colorSystemGreen"
                :title="$t('templates.toasts.success.title')"
                :details="$t('templates.toasts.success.details')"
            >
                <svg class="color-toast__icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z"/>
                </svg>
            </advanced-color-toast>
            <advanced-color-toast
                :index="1"
                :current-color="colorSystemRed"
                :title="$t('templates.toasts.error.title')"
                :details="$t('templates.toasts.error.details')"
            >
                <svg class="color-toast__icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM15.59 7L12 10.59L8.41 7L7 8.41L10.59 12L7 15.59L8.41 17L12 13.41L15.59 17L17 15.59L13.41 12L17 8.41L15.59 7Z"/>
                </svg>
            </advanced-color-toast>
            <advanced-color-toast
                :index="2"
                :current-color="colorSystemOrange"
                :additional-color="colorBrandMain"
                :title="$t('templates.toasts.warning.title')"
                :details="$t('templates.toasts.warning.details')"
                :additional-details="$t('templates.toasts.warning.additionalDetails')"
            >
                <svg class="color-toast__icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 15H13V17H11V15ZM11 7H13V13H11V7ZM11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"/>
                </svg>
            </advanced-color-toast>
            <advanced-color-toast
                :index="3"
                :current-color="colorBrandMain"
                :title="$t('templates.toasts.info.title')"
                :details="$t('templates.toasts.info.details')"
            >
                <svg class="color-toast__icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"/>
                </svg>
            </advanced-color-toast>
        </div>
    </div>
</template>

<script>
    import AdvancedColorType from "./advanced/AdvancedColorType";
    import AdvancedColorToast from "./advanced/AdvancedColorToast";

    export default {
        name: "AdvancedSettingsTemplate",

        components: {
            AdvancedColorToast,
            AdvancedColorType
        },

        props: {
            colorBrandMain: {
                type: String,
                required: true
            },
            colorSystemRed: {
                type: String,
                required: true
            },
            colorSystemOrange: {
                type: String,
                required: true
            },
            colorSystemYellow: {
                type: String,
                required: true
            },
            colorSystemGreen: {
                type: String,
                required: true
            },
            colorSystemBlue: {
                type: String,
                required: true
            },
            colorSystemIris: {
                type: String,
                required: true
            },
            colorSystemPurple: {
                type: String,
                required: true
            },
            colorSystemPink: {
                type: String,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>
    .advanced-template {
        height: 348px;
        border: 1px solid var(--app-border);
        border-radius: 13px;
        display: flex;
        width: 100%;
        padding: 30px 15px;
        pointer-events: none;

        &__types {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-right: 15px;
        }

        &__toasts {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &__toasts {
            width: 100%;
        }
    }
</style>

<style lang="scss">
    /*.color-type:not(:last-child) {*/
    /*    margin-bottom: 10px;*/
    /*}*/
</style>