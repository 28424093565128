<template>
    <div class="contract-template">
        <div class="contract-template__control">
            <div class="contract-template__icon">
                <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.6667 33.3327H33.3333V37.4994H16.6667V33.3327ZM16.6667 24.9994H33.3333V29.166H16.6667V24.9994ZM29.1667 4.16602H12.5C10.2083 4.16602 8.33334 6.04102 8.33334 8.33268V41.666C8.33334 43.9577 10.1875 45.8327 12.4792 45.8327H37.5C39.7917 45.8327 41.6667 43.9577 41.6667 41.666V16.666L29.1667 4.16602ZM37.5 41.666H12.5V8.33268H27.0833V18.7493H37.5V41.666Z" :fill="iconColor"/>
                </svg>
            </div>
            <div class="contract-template__actions" ref="contractControlPanel">
                <app-circle-btn
                    :is-transparent="true"
                    @action="toggleControlPanel"
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 8C13.1 8 14 7.1 14 6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6C10 7.1 10.9 8 12 8ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM12 16C10.9 16 10 16.9 10 18C10 19.1 10.9 20 12 20C13.1 20 14 19.1 14 18C14 16.9 13.1 16 12 16Z" fill="#2D28F3"/>
                    </svg>
                </app-circle-btn>
                <div v-if="showPanel" class="contract-template__actions-panel">
                    <app-action-btn
                        :id="'download'"
                        :text="$t('finance.templates.actions.download')"
                        @action="getAndDownloadContractTemplate"
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 12V19H5V12H3V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V12H19ZM13 12.67L15.59 10.09L17 11.5L12 16.5L7 11.5L8.41 10.09L11 12.67V3H13V12.67Z" fill="#B6C3D3"/>
                        </svg>
                    </app-action-btn>
                    <app-action-btn
                        :id="'delete'"
                        :text="$t('finance.templates.actions.delete')"
                        @action="deleteContractTemplate"
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7Z" fill="#FF3B30"/>
                        </svg>
                    </app-action-btn>
                </div>
            </div>
        </div>
        <div class="contract-template__info">
            <h3 class="contract-template__name">{{ name }}</h3>
            <div>{{ formattedSize }}</div>
        </div>
    </div>
</template>

<script>
    import { getAndDownloadFile } from "../../../api/requests/common";
    import { getContractTemplateIconColor } from "../../../helpers/finance";

    export default {
        name: "FinanceContractCard",

        props: {
            index: {
                type: Number,
                required: true
            },
            id: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            size: {
                type: Number,
                required: true
            },
            link: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                showPanel: false,
                iconColor: ''
            }
        },

        created() {
            this.initIconColor();
        },

        mounted() {
            document.addEventListener("mousedown", this.handlerClickOutside);
        },

        unmounted() {
            document.removeEventListener("mousedown", this.handlerClickOutside);
        },

        computed: {
            formattedSize() {
                return `${this.size} ${this.$t('common.units.size')}`
            }
        },

        methods: {
            initIconColor() {
                this.iconColor = getContractTemplateIconColor(this.index);
            },

            handlerClickOutside(event) {
                if (!this.$refs.contractControlPanel.contains(event.target)) {
                    this.showPanel = false;
                }
            },

            toggleControlPanel() {
                this.showPanel = !this.showPanel;
            },

            closeControlPanel() {
                this.showPanel = false;
            },

            deleteContractTemplate() {
                this.$emit('delete', this.id);
                this.closeControlPanel();
            },

            getAndDownloadContractTemplate() {
                getAndDownloadFile(this.link, this.name);
                this.closeControlPanel();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .contract-template {
        padding: 30px;
        background: var(--system-white);
        border: 1px solid var(--app-border);
        border-radius: 15px;
        height: 100%;
        display: flex;
        flex-direction: column;

        &__control {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 30px;
        }

        &__info {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            h3 {
                margin-bottom: 15px;
            }
        }

        @media screen and (max-width: 1820px) {
            &__info {
                h3 {
                    font-size: 26px;
                }
            }
        }

        &__name {
            flex-grow: 1;
        }

        &__icon {
            width: 106px;
            height: 106px;
            background: var(--app-table-background);
            border-radius: 13px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__actions {
            position: relative;

            button {
                width: 24px;
                height: 24px;
            }
        }

        &__actions-panel {
            position: absolute;
            top: -10px;
            right: 30px;
            background: var(--system-white);
            box-shadow: 0px 16px 32px rgba(35, 23, 5, 0.26);
            border-radius: 13px;
            padding: 7px 0;
        }
    }
</style>