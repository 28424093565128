<template>
    <div class="finance-main">
        <div class="finance-main__control-x">
            <div class="finance-main__head">
                <div class="finance-main__info">
                    <div class="finance-main__card">
                        <finance-card
                            :type="'balance'"
                            :total="formattedBalance"
                            :details="$t('finance.cards.balance.details')"
                        >
                            <svg width="26" height="40" viewBox="0 0 26 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.54625 33.952H25.9383V40H0.54625V33.952ZM18.5943 36.736C17.1223 36.736 15.5543 36.56 13.8903 36.208C12.2583 35.824 10.6583 35.232 9.09025 34.432C7.52225 33.632 6.09825 32.592 4.81825 31.312C3.57025 30.032 2.56225 28.496 1.79425 26.704C1.05825 24.88 0.69025 22.768 0.69025 20.368C0.69025 18.16 1.02625 16.16 1.69825 14.368C2.40225 12.544 3.41025 10.992 4.72225 9.712C6.06625 8.432 7.68225 7.456 9.57025 6.784C11.4903 6.08 13.6663 5.728 16.0983 5.728C17.9223 5.728 19.5703 5.872 21.0423 6.16C22.5143 6.416 24.0023 6.864 25.5063 7.504V13.6C23.9703 12.96 22.4343 12.496 20.8983 12.208C19.3623 11.92 17.8903 11.776 16.4823 11.776C15.1383 11.776 13.9383 12 12.8823 12.448C11.8263 12.864 10.9143 13.472 10.1463 14.272C9.41025 15.072 8.85025 16.048 8.46625 17.2C8.08225 18.32 7.89025 19.6 7.89025 21.04C7.89025 22.832 8.17825 24.512 8.75425 26.08C9.33025 27.648 10.1783 29.024 11.2983 30.208C12.4183 31.392 13.8263 32.32 15.5223 32.992C17.2183 33.632 19.1863 33.952 21.4263 33.952L18.5943 36.736ZM11.4903 0.879997H14.7063V20.176H11.4903V0.879997ZM18.3543 0.879997H21.5703V20.176H18.3543V0.879997Z" fill="#2D28F3"/>
                            </svg>
                        </finance-card>
                    </div>
                    <div class="finance-main__card">
                        <finance-card
                            :type="'clients'"
                            :total="qtyDebtClients"
                            :details="$t('finance.cards.clients.debt')"
                            @action="updateStateFilter('debt')"
                        >
                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.9167 31.2503H27.0833V35.417H22.9167V31.2503ZM22.9167 14.5837H27.0833V27.0837H22.9167V14.5837ZM24.9792 4.16699C13.4792 4.16699 4.16667 13.5003 4.16667 25.0003C4.16667 36.5003 13.4792 45.8337 24.9792 45.8337C36.5 45.8337 45.8333 36.5003 45.8333 25.0003C45.8333 13.5003 36.5 4.16699 24.9792 4.16699ZM25 41.667C15.7917 41.667 8.33334 34.2087 8.33334 25.0003C8.33334 15.792 15.7917 8.33366 25 8.33366C34.2083 8.33366 41.6667 15.792 41.6667 25.0003C41.6667 34.2087 34.2083 41.667 25 41.667Z" fill="var(--system-red)"/>
                            </svg>
                        </finance-card>
                    </div>
                </div>
                <div class="finance-main__filters">
                    <finance-main-filters
                        :qty="qtyResults"
                        @update="updateQueryParams"
                    />
                </div>
            </div>
            <div class="finance-main__content-x" ref="financeMain">
                <div v-if="showTable">
                    <finance-main-table
                        :building-groups="buildingGroups"
                        :free-buildings="freeBuildings"
                        :max-height="maxHeightScroll"
                        :search-sub-string="searchSubString"
                    />
                </div>
                <div v-if="showEmpty" class="finance-main__empty">
                    <app-empty-content
                        :is-small="true"
                        :content-type="'balance'"
                        :show-btn="false"
                    />
                </div>
                <div v-if="showNoResults" class="finance-main__empty">
                    <app-no-results
                        :is-small="true"
                        @clear="clearQueryParams"
                    />
                </div>
                <div v-if="isLoaded" class="finance-main__loader">
                    <app-loader
                        :size="'large'"
                    />
                </div>
            </div>
        </div>
        <div class="finance-main__panel-x">
            <div class="finance-main__panel-wrapper">
                <div class="finance-main__card">
                    <finance-card
                        :type="'payments'"
                        :total="qtyCheckPayments"
                        :details="$t('finance.cards.invoices.check')"
                        @action="goToCheck"
                    >
                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22.9167 31.2503H27.0834V35.417H22.9167V31.2503ZM22.9167 14.5837H27.0834V27.0837H22.9167V14.5837ZM24.9792 4.16699C13.4792 4.16699 4.16669 13.5003 4.16669 25.0003C4.16669 36.5003 13.4792 45.8337 24.9792 45.8337C36.5 45.8337 45.8334 36.5003 45.8334 25.0003C45.8334 13.5003 36.5 4.16699 24.9792 4.16699ZM25 41.667C15.7917 41.667 8.33335 34.2087 8.33335 25.0003C8.33335 15.792 15.7917 8.33366 25 8.33366C34.2084 8.33366 41.6667 15.792 41.6667 25.0003C41.6667 34.2087 34.2084 41.667 25 41.667Z" fill="var(--system-orange)"/>
                        </svg>
                    </finance-card>
                </div>
                <div class="finance-main__card">
                    <finance-card
                        :type="'payments'"
                        :total="qtyUnsignedContracts"
                        :details="$t('finance.cards.contracts.unsigned')"
                        @action="goToContracts"
                    >
                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.24738 35.9375V43.75H14.0599L37.1015 20.7083L29.289 12.8958L6.24738 35.9375ZM12.3307 39.5833H10.414V37.6667L29.289 18.7917L31.2057 20.7083L12.3307 39.5833ZM43.1432 11.7292L38.2682 6.85417C37.8515 6.4375 37.3307 6.25 36.789 6.25C36.2474 6.25 35.7265 6.45833 35.3307 6.85417L31.5182 10.6667L39.3307 18.4792L43.1432 14.6667C43.9557 13.8542 43.9557 12.5417 43.1432 11.7292Z" fill="var(--brand-main)"/>
                        </svg>
                    </finance-card>
                </div>
                <div class="finance-main__card">
                    <finance-card
                        :type="'payments'"
                        :total="qtyExpiredContracts"
                        :details="$t('finance.cards.contracts.expired')"
                        @action="goToContracts"
                    >
                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M24.9792 4.16699C13.4792 4.16699 4.16669 13.5003 4.16669 25.0003C4.16669 36.5003 13.4792 45.8337 24.9792 45.8337C36.5 45.8337 45.8334 36.5003 45.8334 25.0003C45.8334 13.5003 36.5 4.16699 24.9792 4.16699ZM25 41.667C15.7917 41.667 8.33335 34.2087 8.33335 25.0003C8.33335 15.792 15.7917 8.33366 25 8.33366C34.2084 8.33366 41.6667 15.792 41.6667 25.0003C41.6667 34.2087 34.2084 41.667 25 41.667ZM26.0417 14.5837H22.9167V27.0837L33.8542 33.6462L35.4167 31.0837L26.0417 25.5212V14.5837Z" fill="var(--system-orange)"/>
                        </svg>
                    </finance-card>
                </div>
                <div class="finance-main__card">
                    <finance-card
                        :type="'payments'"
                        :total="qtyOverdueContracts"
                        :details="$t('finance.cards.contracts.overdue')"
                        @action="goToContracts"
                    >
                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22.9167 31.2503H27.0834V35.417H22.9167V31.2503ZM22.9167 14.5837H27.0834V27.0837H22.9167V14.5837ZM24.9792 4.16699C13.4792 4.16699 4.16669 13.5003 4.16669 25.0003C4.16669 36.5003 13.4792 45.8337 24.9792 45.8337C36.5 45.8337 45.8334 36.5003 45.8334 25.0003C45.8334 13.5003 36.5 4.16699 24.9792 4.16699ZM25 41.667C15.7917 41.667 8.33335 34.2087 8.33335 25.0003C8.33335 15.792 15.7917 8.33366 25 8.33366C34.2084 8.33366 41.6667 15.792 41.6667 25.0003C41.6667 34.2087 34.2084 41.667 25 41.667Z" fill="var(--system-red)"/>
                        </svg>
                    </finance-card>
                </div>
                <div class="finance-main__operation">
                    <app-base-btn
                        :size="'full'"
                        :variation="'cancel'"
                        :text="$t('common.buttons.contract')"
                        @action="openModalContract"
                    >
                        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.7916 9.04134H8.54165V15.2913H6.45831V9.04134H0.208313V6.95801H6.45831V0.708008H8.54165V6.95801H14.7916V9.04134Z" fill="#2B3037"/>
                        </svg>
                    </app-base-btn>
                    <app-base-btn
                        :type="'submit'"
                        :size="'full'"
                        :variation="'save'"
                        :text="$t('common.buttons.invoice')"
                        @action="openModalInvoice"
                    >
                        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.7917 9.04134H8.54171V15.2913H6.45837V9.04134H0.208374V6.95801H6.45837V0.708008H8.54171V6.95801H14.7917V9.04134Z" fill="white"/>
                        </svg>
                    </app-base-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FinanceCard from "../FinanceCard";
    import FinanceMainFilters from "./FinanceMainFilters";
    import FinanceMainTable from "./FinanceMainTable";
    import { maxHeightMixin } from "../../../mixins/calcMaxHeight";
    import { djinAPI } from "../../../config/api";
    import { mapGroupsBalanceToView, mapBuildingsBalanceToView, mapAdditionalInfoBalanceToView } from "../../../api/mappers/finance/balance";
    import { formatCurrencyValue } from "../../../helpers/finance";

    export default {
        name: "FinanceMainControl",

        components: {
            FinanceMainTable,
            FinanceCard,
            FinanceMainFilters
        },

        data() {
            return {
                isLoaded: false,
                buildingGroups: [],
                freeBuildings: [],
                info: {
                    balance: 0,
                    contracts: {
                        signature: 0,
                        expires: 0,
                        overdue: 0
                    },
                    invoices: {
                        review: 0
                    },
                    clients: {
                        debt: 0
                    }
                },
                params: null,
                qtyResults: 0
            }
        },

        mixins: [
            maxHeightMixin
        ],

        created() {
            this.getMainBalance();
        },

        mounted() {
            this.subscribeToResize('financeMain');
        },

        unmounted() {
            this.unsubscribeFromResize();
        },

        computed: {
            isExistBuildings() {
                return this.buildingGroups.length || this.freeBuildings.length;
            },

            showTable() {
                return this.isExistBuildings && !this.isLoaded;
            },

            showEmpty() {
                return !this.isExistBuildings && !this.isLoaded && !this.isExistQueryParams;
            },

            showNoResults() {
                return !this.isExistBuildings && !this.isLoaded && this.isExistQueryParams;
            },

            isExistQueryParams() {
                return this.searchSubString || this.filterSubString;
            },

            searchSubString() {
                return this.params?.params?.match ? this.params?.params?.match : '';
            },

            filterSubString() {
                return this.params?.params?.filter ? this.params?.params?.filter : '';
            },

            qtyUnsignedContracts() {
                return `${this.$t('finance.cards.contracts.qty')} ${this.info.contracts.signature}`;
            },

            qtyExpiredContracts() {
                return `${this.$t('finance.cards.contracts.qty')} ${this.info.contracts.expires}`;
            },

            qtyOverdueContracts() {
                return `${this.$t('finance.cards.contracts.qty')} ${this.info.contracts.overdue}`;
            },

            qtyCheckPayments() {
                return `${this.$t('finance.cards.invoices.qty')} ${this.info.invoices.review}`;
            },

            qtyDebtClients() {
                return `${this.$t('finance.cards.clients.qty')} ${this.info.clients.debt}`;
            },

            formattedBalance() {
                const { balance, ...currency } = formatCurrencyValue(this.info.balance);

                return `${balance}`;
            }
        },

        methods: {
            getMainBalance() {
                this.isLoaded = true;

                djinAPI.get('/finance/home', this.params)
                    .then((result) => {
                        const { building_groups, free_buildings, ...info } =  result.data;
                        this.buildingGroups = mapGroupsBalanceToView(building_groups);
                        this.freeBuildings = mapBuildingsBalanceToView(free_buildings);
                        this.info = mapAdditionalInfoBalanceToView(info);
                        this.calculateQty(this.buildingGroups, this.freeBuildings);

                        this.emitter.emit('updateShowQty');

                        this.$nextTick(() => {
                            this.calcMaxHeight();
                        });
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.isLoaded = false;
                        }, 500)
                    })
            },

            calculateQty(groups, freeBuildings) {
                this.qtyResults = 0;
                this.qtyResults += groups.length;

                groups.forEach((group) => {
                    group.buildings.forEach((building) => {
                        this.calculateQtyWithoutGroups(building);
                    });
                })

                freeBuildings.forEach((building) => {
                    this.calculateQtyWithoutGroups(building);
                });
            },

            calculateQtyWithoutGroups(building) {
                this.qtyResults = this.qtyResults + 1 + building.users.length;

                building.users.forEach((user) => {
                    this.qtyResults += user.premises.length;
                })
            },

            updateQueryParams(params) {
                this.params = { ...params };
                this.getMainBalance();
            },

            updateStateFilter(id) {
                this.emitter.emit('updateStateFilter', id);
            },

            goToCheck() {
                this.$router.push({ path: '/finance/review' });
            },

            goToContracts() {
                this.$router.push({ path: '/finance/contracts' });
            },

            openModalContract() {
                this.openModal({
                    name: 'modalContract'
                });
            },

            openModalInvoice() {
                this.openModal({
                    name: 'modalInvoice'
                })
            },

            clearQueryParams() {
                this.emitter.emit('clearQueryParams');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .finance-main {
        display: flex;
        flex-direction: row;
        height: 100%;

        //TODO: REMOVE X FROM STYLES
        &__control-x {
            width: 66.66%;
            display: flex;
            flex-direction: column;
        }

        &__panel-x {
            height: 100%;
            width: 33.33%;
            background: var(--app-table-background);
            padding: 15px 30px 0 30px;

            .finance-main__card:not(:last-child) {
                margin-bottom: 30px;
            }
        }

        //TODO: WHY? SCROLL?
        &__panel-wrapper {
            overflow-y: auto;
            max-height: calc(100vh - 150px);
        }

        &__content-x {
            flex-grow: 1;
        }

        &__head {
            display: flex;
            flex-direction: column;
            padding: 15px 15px 30px 30px;
        }

        &__info {
            display: flex;

            .finance-main__card:not(:last-child) {
                margin-right: 30px;
            }
        }

        &__card {
            width: 100%;
        }

        &__filters {
            margin-top: 30px;
        }

        &__operation {
            display: flex;
            justify-content: flex-end;
            margin-top: 45px;

            button:first-child {
                margin-right: 30px;
            }
        }
    }
</style>