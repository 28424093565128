<template>
    <div class="invite-form">
        <div class="invite-form__panel">
            <h5>{{ $t('users.user.invite.title') }}</h5>
            <app-text-btn
                :text="$t('users.user.invite.editBtn')"
                @action="openModalInvite"
            >
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.0417 15.0417H3.95833V3.95833H9.5V2.375H3.95833C3.07958 2.375 2.375 3.0875 2.375 3.95833V15.0417C2.375 15.9125 3.07958 16.625 3.95833 16.625H15.0417C15.9125 16.625 16.625 15.9125 16.625 15.0417V9.5H15.0417V15.0417ZM11.0833 2.375V3.95833H13.9254L6.14333 11.7404L7.25958 12.8567L15.0417 5.07458V7.91667H16.625V2.375H11.0833Z" fill="var(--brand-main)"/>
                </svg>
            </app-text-btn>
        </div>
        <div class="invite-form__type">
            <div v-for="variant in inviteSendVariants" :key="variant.id" class="invite-form__variant">
                <app-base-radio-btn
                    v-model="activeVariant"
                    :id="variant.id"
                    :label="variant.label"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { variants } from "../../constants/invite";

    export default {
        name: "InviteForm",

        data() {
            return {
                activeVariant: 'sms'
            }
        },

        computed: {
            inviteSendVariants() {
                return variants;
            }
        },

        watch: {
            activeVariant(variant) {
                this.$emit('update', variant);
            }
        },

        methods: {
            openModalInvite() {
                this.openModal({
                    name: 'modalInvite'
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
    .invite-form {
        padding-top: 30px;

        &__panel {
            display: flex;
            align-items: center;
            margin-bottom: 30px;

            h5 {
                margin-right: 30px;
            }
        }

        &__variant:not(:last-child) {
            margin-bottom: 15px;
        }
    }
</style>