<template>
  <router-view/>
</template>

<script>
    import i18n from "./localization/i18n";

    export default {
        name: 'App',

        created() {
            this.connectGoogleMaps();
        },

        mounted() {
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromEvents();
        },

        methods: {
            subscribeToEvents() {
                document.addEventListener('keydown', this.handlerKeyEsc);
            },

            unsubscribeFromEvents() {
                document.removeEventListener('keydown', this.handlerKeyEsc);
            },

            handlerKeyEsc(event) {
                if (event.keyCode === 27) {
                    this.closeOneModal();
                }
            },

            connectGoogleMaps() {
                const langCode = i18n.global.locale;
                const script = document.createElement('script');

                script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}&libraries=places&v=weekly&language=${langCode}`;
                document.body.append(script);
            }
        }
    }
</script>
