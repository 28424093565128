<template>
    <app-modal-wrapper>
        <div class="modal-template-invoice">
            <form @submit.prevent="doActionTemplateInvoice" class="template-invoice-form">
                <h4>{{ title }}</h4>
                <div class="template-invoice-form__info">
                    <div class="template-invoice-form__name">
                        <app-base-input
                            v-model="invoiceTemplate.name"
                            :label="$t('finance.templates.invoice.name.label')"
                            :place-holder="$t('finance.templates.invoice.name.ph')"
                            :error-message="errors.name"
                        />
                    </div>
                    <div class="template-invoice-form__cost">
                        <div class="template-invoice-form__currency">
                            {{ $t('finance.data.currency') }}
                        </div>
                        <app-base-input
                            v-model="invoiceTemplate.price"
                            :label="$t('finance.templates.invoice.price.label')"
                            :place-holder="$t('finance.templates.invoice.price.ph')"
                            :error-message="errors.price"
                        />
                    </div>
                </div>
                <div class="template-invoice-form__control">
                    <app-base-btn
                        :size="'fixed'"
                        :variation="'cancel'"
                        :text="$t('common.buttons.cancel')"
                        @action="closeModalTemplateInvoice"
                    />
                    <app-base-btn
                        :type="'submit'"
                        :size="'fixed'"
                        :variation="'save'"
                        :text="saveBtnText"
                    />
                </div>
            </form>
        </div>
    </app-modal-wrapper>
</template>

<script>
    import { validateInvoiceTemplate } from "../../../validations/finance/templates";
    import { mapInvoiceTemplateToSend } from "../../../api/mappers/finance/templates";
    import { templatesToastsMixin } from "../../../mixins/toasts/finance/templates";
    import { djinAPI } from "../../../config/api";

    export default {
        name: "ModalTemplateInvoice",

        props: {
            selectedInvoiceTemplate: {
                type: Object
            }
        },



        data() {
            return {
                invoiceTemplate: {
                    name: '',
                    price: ''
                },

                errors: {
                    name: '',
                    price: ''
                }
            }
        },

        mixins: [
            templatesToastsMixin
        ],

        created() {
            this.checkUpdateTemplate();
        },

        computed: {
            isUpdate() {
                return !!this.selectedInvoiceTemplate;
            },
            title() {
                return !this.isUpdate
                    ? this.$t('finance.templates.invoice.title.create')
                    : this.$t('finance.templates.invoice.title.edit');
            },

            saveBtnText() {
                return this.isUpdate
                    ? this.$t('common.buttons.save')
                    : this.$t('common.buttons.addTemplate');
            }
        },

        methods: {
            checkUpdateTemplate() {
                if (this.isUpdate) {
                    this.invoiceTemplate = { ...this.selectedInvoiceTemplate };
                }
            },

            closeModalTemplateInvoice() {
                this.closeModal('modalTemplateInvoice');
            },

            doActionTemplateInvoice() {
                this.clearInvoiceTemplateErrors();
                const { isValid, errors } = validateInvoiceTemplate(this.invoiceTemplate);
                this.errors = errors;

                if (isValid) {
                    if (!this.isUpdate) {
                        this.createInvoiceTemplate();
                    } else {
                        this.changeInvoiceTemplate();
                    }
                }
            },

            createInvoiceTemplate() {
                const invoiceTemplate = mapInvoiceTemplateToSend(this.invoiceTemplate);

                djinAPI.post('/finance/templates/invoices', invoiceTemplate)
                    .then(() => {
                        this.showCreateInvoiceTemplateToast();
                        this.updateTemplates();
                    })
            },


            changeInvoiceTemplate() {
                const id = this.invoiceTemplate.id;
                const invoiceTemplate = mapInvoiceTemplateToSend(this.invoiceTemplate);

                djinAPI.patch(`/finance/templates/invoices/${id}`, invoiceTemplate)
                    .then((result) => {
                        // this.showCreateBuildingToast();
                        this.updateTemplates();
                    })
            },

            updateTemplates() {
                this.closeModalTemplateInvoice();
                this.emitter.emit('updateTemplates');
            },

            clearInvoiceTemplateErrors() {
                for (let key in this.errors) {
                    this.errors[key] = '';
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-template-invoice {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        max-width: 574px;
        width: 100%;
    }

    .template-invoice-form {
        h4 {
            margin-bottom: 30px;
        }

        &__control {
            display: flex;
            justify-content: flex-end;
            margin-top: 45px;

            button:first-child {
                margin-right: 30px;
            }
        }

        &__name {
            margin-bottom: 15px;
        }

        &__cost {
            position: relative;
        }

        &__currency {
            height: 48px;
            display: flex;
            align-items: center;
            position: absolute;
            left: 15px;
            top: 26px;
            z-index: 10;
            font-weight: 400;
            font-size: 16px;
        }
    }
</style>

<style lang="scss">
    .template-invoice-form__cost {
        .field__entry {
            input {
                padding-left: 50px !important;
            }
        }
    }
</style>