<template>
    <div class="news">
<!--        <div class="news__panel">-->
<!--            <div class="news__title">Новини</div>-->
<!--        </div>-->
    </div>
</template>

<script>
    export default {
        name: "NewsControl"
    }
</script>