<template>
    <div class="individual-template"
        :style="{
            'backgroundColor': colorMenuBackground
        }"
    >
        <div class="individual-template__navigation">
            <individual-template-panel
                :logo="icons[0]"
                :color-base-primary="colorBasePrimary"
                :color-border="colorBorder"
            />
            <individual-template-menu
                :items="icons.filter((icon) => icon.id !== 'logo')"
                :color-base-secondary="colorBaseSecondary"
                :color-brand-main="colorBrandMain"
            />
        </div>
        <div class="individual-template__manage">
            <individual-template-content
                :color-table-background="colorTableBackground"
                :color-base-primary="colorBasePrimary"
                :color-border="colorBorder"
                :color-btn-secondary="colorBtnSecondary"
                :color-brand-iris="colorBrandIris"
                :color-brand-purple="colorBrandPurple"
                :color-brand-pink="colorBrandPink"
            />
        </div>
    </div>
</template>

<script>
    import IndividualTemplatePanel from "./individual/IndividualTemplatePanel";
    import IndividualTemplateMenu from "./individual/IndividualTemplateMenu";
    import IndividualTemplateContent from "./individual/IndividualTemplateContent";

    export default {
        name: "IndividualSettingsTemplate",

        components: {
            IndividualTemplateContent,
            IndividualTemplateMenu,
            IndividualTemplatePanel
        },

        props: {
            icons: {
                type: Array,
                required: true
            },
            colorMenuBackground: {
                type: String,
                required: true
            },
            colorTableBackground: {
                type: String,
                required: true
            },
            colorBasePrimary: {
                type: String,
                required: true
            },
            colorBaseSecondary: {
                type: String,
                required: true
            },
            colorBorder: {
                type: String,
                required: true
            },
            colorBtnSecondary: {
                type: String,
                required: true
            },
            colorBrandMain: {
                type: String,
                required: true
            },
            colorBrandIris: {
                type: String,
                required: true
            },
            colorBrandPurple: {
                type: String,
                required: true
            },
            colorBrandPink: {
                type: String,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>
    .individual-template {
        height: 438px;
        border: 1px solid var(--app-border);
        border-radius: 13px;
        display: flex;
        width: 100%;
        pointer-events: none;

        &__navigation {
            width: 160px;
            display: flex;
            flex-direction: column;
        }

        &__manage {
            display: flex;
            flex-direction: column;

            width: calc(100% - 160px);
        }
    }
</style>
