<template>
    <router-link :to="'/profile'">
        <div class="profile-panel"
            :class="{
                'profile-panel--is-adaptive': !isNotAdaptive
            }"
        >
            <div class="profile-panel__image">
                <app-loader v-if="avatar.isLoaded || isLoaded"
                    :size="'small'"
                />
                <div v-else>
                    <img v-if="avatar.url" :src="avatar.url" alt="Avatar">
                    <div v-else class="profile-panel__avatar-ph"
                    >
                        <div class="profile-panel__avatar-abbr">
                            {{ profile.abbrNoAvatar }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="isNotAdaptive" class="profile-panel__info">
                <div v-if="profile.fullName" class="profile-panel__fullName can-overflow">
                    {{ profile.fullName }}
                </div>
                <div class="profile-panel__username can-overflow">
                    {{ profile.username }}
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
    import { djinAPI } from "../../../config/api";
    import { mapProfileToView } from "../../../api/mappers/profile";
    import { getImageFromUrl } from "../../../api/requests/common";

    export default {
        name: "AdminProfilePanel",

        props: {
            isNotAdaptive: {
                type: Boolean,
                required: true
            }
        },

        data() {
            return {
                isLoaded: false,

                profile: {
                    id: '',
                    username: '',
                    name: '',
                    surname: '',
                    fullName: '',
                    abbrNoAvatar: '',
                    avatar: ''
                },

                avatar: {
                    url: '',
                    isLoaded: false
                }
            }
        },

        created() {
            this.getProfile();
        },

        mounted() {
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromEvents();
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('updateMe', this.getProfile);
            },

            unsubscribeFromEvents() {
                this.emitter.off('updateMe', this.getProfile);
            },

            getProfile() {
                this.isLoaded = true;

                djinAPI.get('/admins/me')
                    .then((result) => {
                        const profile = result.data;
                        this.profile = mapProfileToView(profile);

                        if (this.profile.avatar) {
                            this.getProfileAvatar(this.profile.avatar);
                        } else {
                            this.avatar.url = '';
                        }
                    })
                    .finally(() => {
                        this.isLoaded = false;
                    })
            },

            async getProfileAvatar(url) {
                this.avatar.isLoaded = true;

                await getImageFromUrl(url)
                    .then((image) => {
                        this.avatar.url = image;
                    })
                    .finally(() => {
                        this.avatar.isLoaded = false;
                    })
            },
        }
    }
</script>

<style lang="scss" scoped>
    .profile-panel {
        display: flex;
        align-items: center;

        &__image {
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                max-width: 100%;
                object-fit: cover;
                border-radius: 50%;
                border: 1px solid transparent;
                transition: border 0.2s;
            }
        }

        &__info {
            display: flex;
            flex-direction: column;
            width: 140px;
            margin-left: 10px;
        }

        &__fullName {
            margin-bottom: 5px;
            font-size: 14px;
            font-weight: 600;
            color: var(--app-base-primary);
            transition: color 0.2s;
        }

        &__username {
            font-size: 12px;
            color: var(--app-base-primary);
        }

        &__avatar-ph {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            background: var(--brand-pink);
        }

        &__avatar-abbr {
            font-size: 16px;
            font-weight: 500;
            color: var(--system-white);
        }
    }

    .router-link-active {
        .profile-panel__name {
            color: var(--brand-main);
        }

        .profile-panel__image {
            img {
                border: 1px solid var(--brand-main);
            }
        }

        .profile-panel__avatar-ph {
            border: 1px solid var(--brand-main);
        }

        .profile-panel__fullName {
            color: var(--brand-main);
        }
    }
</style>

