<template>
    <div class="invoice-position">
        <div class="invoice-position__details">
            <div class="invoice-position__variant">
                <app-base-checkbox
                    :value="'isTemplate'"
                    :label="$t('finance.invoice.position.variant')"
                    v-model="isTemplate"
                />
            </div>
            <div class="invoice-position__name">
                <app-base-input v-if="!isTemplate"
                    v-model="position.name"
                    :label="$t('finance.invoice.position.name.label')"
                    :place-holder="$t('finance.invoice.position.name.ph')"
                    :error-message="errors.name"
                />
                <app-base-select v-else
                    :label="$t('finance.invoice.position.template.label')"
                    :place-holder="$t('finance.invoice.position.template.ph')"
                    :selected-option="selectedTemplate"
                    :options="templates"
                    :type="'text'"
                    :error-message="errors.name"
                    @select="selectTemplate"
                />
                <div v-if="errors.positive" class="invoice-position__error">
                    {{ errors.positive }}
                </div>
            </div>
        </div>
        <div class="invoice-position__details total">
            <div class="total__row">
                <div class="total__qty">
                    <div class="app__label">
                        {{ $t('finance.invoice.total.qty') }}
                    </div>
                    <div class="total__qty-panel">
                        {{ position.qty }}
                        <button type="button" class="total__qty-dec-btn" @click="decQty">
                            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.0418 10.7923H3.9585V9.20898H15.0418V10.7923Z" fill="#A4AFBE"/>
                            </svg>
                        </button>
                        <button type="button" class="total__qty-inc-btn" @click="incQty">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.3753 10.7923H10.6253V15.5423H9.04199V10.7923H4.29199V9.20898H9.04199V4.45898H10.6253V9.20898H15.3753V10.7923Z" fill="#A4AFBE"/>
                            </svg>
                        </button>
                    </div>
                </div>
                <div class="total__entry">
                    <div class="total__currency">
                        {{ $t('finance.data.currency') }}
                    </div>
                    <app-base-input
                        v-model="position.price"
                        :label="$t('finance.invoice.total.value')"
                        :error-message="errors.price"
                        @input="handlerOnChange"
                    />
                </div>
                <div class="total__value">
                    <div class="app__label">
                        {{ $t('finance.invoice.total.amount') }}
                    </div>
                    <div class="total__value-panel">
                        {{ formattedTotal }}
                    </div>
                </div>
            </div>
            <div v-if="errors.name || errors.positive" class="total__error"></div>
        </div>
        <div v-if="index || length > 1" class="invoice-position__delete-btn">
            <app-circle-btn
                @action="deleteInvoicePosition"
            >
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.875 4.00625L10.9938 3.125L7.5 6.61875L4.00625 3.125L3.125 4.00625L6.61875 7.5L3.125 10.9938L4.00625 11.875L7.5 8.38125L10.9938 11.875L11.875 10.9938L8.38125 7.5L11.875 4.00625Z" fill="#2B3037"/>
                </svg>
            </app-circle-btn>
        </div>
    </div>
</template>

<script>
    import { formatCurrencyValue } from "../../../helpers/finance";
    import { validateInvoicePosition } from "../../../validations/finance/invoices";

    export default {
        name: "InvoicePositionRow",

        props: {
            index: {
                type: Number,
                required: true
            },
            length: {
                type: Number,
                required: true
            },
            id: {
                type: Number,
                required: true
            },
            price: {
                type: Number,
                required: true
            },
            templates: {
                type: Array,
                required: true
            }
        },

        data() {
            return {
                position: {
                    name: '',
                    price: 0,
                    qty: 1,
                    total: 0,
                    isValid: false
                },
                isTemplate: false,
                selectedTemplate: null,
                errors: {
                    name: '',
                    price: '',
                    positive: ''
                }
            }
        },

        mounted() {
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromEvents();
        },

        watch: {
            isTemplate(newState) {
                this.position.price = newState
                    ? this.selectedTemplate ? this.selectedTemplate.price : 0
                    : 0;

                this.updateInvoicePosition();
            }
        },

        computed: {
            formattedTotal() {
                const { balance, currency } = formatCurrencyValue(this.position.total);

                return `${balance} ${currency}`;
            }
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('validatePosition', this.validateAndUpdatePosition);
            },

            unsubscribeFromEvents() {
                this.emitter.off('validatePosition', this.validateAndUpdatePosition);
            },

            validateAndUpdatePosition() {
                this.clearPositionErrors();
                const { isValid, errors } = validateInvoicePosition(this.position, this.isTemplate, this.selectedTemplate);
                this.errors = errors;
                this.position.isValid = isValid;
                this.updateInvoicePosition();
            },

            clearPositionErrors() {
                for (let key in this.errors) {
                    this.errors[key] = '';
                }
            },

            selectTemplate(template) {
                this.selectedTemplate = template;
                this.position.price = this.selectedTemplate.price;
                this.updateInvoicePosition();
            },

            incQty() {
                if (this.position.qty < 50) {
                    this.position.qty += 1;
                    this.updateInvoicePosition();
                }
            },

            decQty() {
                if (this.position.qty > 1) {
                    this.position.qty -= 1;
                    this.updateInvoicePosition();
                }
            },

            handlerOnChange() {
                this.updateInvoicePosition();
            },

            updateInvoicePosition() {
                this.getTotal();
                let positionName = ''

                if (!this.isTemplate) {
                    positionName = this.position.name;
                } else {
                    if (this.selectedTemplate) {
                        positionName = this.selectedTemplate.name;
                    }
                }

                this.$emit('update', this.id, positionName, this.position.price, this.position.qty, this.position.total, this.position.isValid);
            },

            getTotal() {
                this.position.total = this.position.price * this.position.qty;
            },

            deleteInvoicePosition() {
                this.$emit('delete', this.id);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .invoice-position {
        display: flex;
        padding: 30px 0;
        position: relative;
        border-bottom: 1px solid var(--app-border);

        &__variant {
            margin-bottom: 15px;
        }

        &__details {
            flex-basis: 50%;

            &:first-child {
                margin-right: 30px;
            }
        }

        &__delete-btn {
            position: absolute;
            top: 15px;
            right: 15px;
        }

        &__error {
            margin-top: 10px;
            font-size: 14px;
            color: var(--system-red);
        }
    }

    .total {
        display: flex;
        flex-direction: column;
        align-self: flex-end;

        &__row {
            display: flex;
            width: 100%;
        }

        &__qty, &__entry, &__value {
            flex-basis: 33%;
        }

        &__qty {
            margin-right: 30px;
        }

        &__value {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        &__qty-panel {
            height: 48px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 13px;
            border: 1px solid var(--app-border);
        }

        &__qty-dec-btn, &__qty-inc-btn {
            position: absolute;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 14px;
        }

        &__qty-dec-btn {
            left: 14px;
        }

        &__qty-inc-btn {
            right: 14px;
        }

        &__value-panel {
            height: 48px;
            display: flex;
            align-items: center;
            font-weight: 700;
            font-size: 16px;
        }

        &__entry {
            position: relative;
        }

        &__currency {
            height: 48px;
            display: flex;
            align-items: center;
            position: absolute;
            left: 15px;
            top: 26px;
            z-index: 10;
            font-size: 16px;
        }

        &__error {
            height: 27px;
        }
    }
</style>

<style lang="scss">
    .total__entry {
        .field__entry {
            input {
                padding-left: 50px !important;
            }
        }
    }
</style>