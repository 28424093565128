import { showToast } from "../../../helpers/toasts";

const templatesToastsMixin = {
    methods: {
        showDeleteInvoiceTemplateToast(name) {
            const deleteToastTitle = this.$t('toasts.invoiceTemplates.delete.title');

            const deleteToastDetails = `
                    ${this.$t(`toasts.invoiceTemplates.delete.details1`)}
                    ${name}
                    ${this.$t(`toasts.invoiceTemplates.delete.details2`)}`
            ;

            showToast('success',
                deleteToastTitle,
                deleteToastDetails
            );
        },

        showCreateInvoiceTemplateToast() {
            showToast('success',
                this.$t('toasts.success'),
                this.$t('toasts.invoiceTemplates.create.details')
            );
        },

        showDeleteContractTemplateToast(name) {
            const deleteToastTitle = this.$t('toasts.contractTemplates.delete.title');

            const deleteToastDetails = `
                    ${this.$t(`toasts.contractTemplates.delete.details1`)}
                    ${name}
                    ${this.$t(`toasts.contractTemplates.delete.details2`)}`
            ;

            showToast('success',
                deleteToastTitle,
                deleteToastDetails
            );
        },

        showCreateContractTemplateToast() {
            showToast('success',
                this.$t('toasts.success'),
                this.$t('toasts.contractTemplates.create.details')
            );
        }
    }
}

export {
    templatesToastsMixin
}