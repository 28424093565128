<template>
    <div class="premises__table premise">
        <div class="table__head">
            <div class="table__column premise__group">
                <app-overflow-match-wrapper
                    :text="$t('premises.table.group')"
                />
            </div>
            <div class="table__column premise__building">
                <app-overflow-match-wrapper
                    :text="$t('premises.table.building')"
                />
            </div>
            <div class="table__column premise__entrance">
                <app-overflow-match-wrapper
                    :text="$t('premises.table.entrance')"
                />
            </div>
            <div class="table__column premise__floor">
                <app-overflow-match-wrapper
                    :text="$t('premises.table.floor')"
                />
            </div>
            <div class="table__column premise__type">
                <app-overflow-match-wrapper
                    :text="$t('premises.table.type')"
                />
            </div>
            <div class="table__column premise__name">
                <app-overflow-match-wrapper
                    :text="$t('premises.table.name')"
                />
            </div>
            <div class="table__column premise__area">
                <app-overflow-match-wrapper
                    :text="$t('premises.table.area')"
                />
            </div>
            <div class="table__column premise__registered">
                <app-overflow-match-wrapper
                    :text="$t('premises.table.registered')"
                />
            </div>
            <div class="table__column premise__file">
                <app-overflow-match-wrapper
                    :text="$t('premises.table.file')"
                />
            </div>
        </div>
        <div class="table__body" :style="{ 'maxHeight': maxHeight }" @scroll="handlerScrollBodyTable">
            <premise-row v-for="premise in premises" :key="premise.id"
                :id="premise.id"
                :building-group="premise.buildingGroup"
                :building="premise.building"
                :entrance="premise.entrance"
                :floor="premise.floor"
                :type="premise.type"
                :name="premise.name"
                :area="premise.area"
                :registered="premise.registered"
                :document="premise.document"
                :sub-string="searchSubString"
                :scroll-position="scrollPosition"
                @update="updatePremise"
            />
        </div>
    </div>
</template>

<script>
    import PremiseRow from "./PremiseRow";
    import { scrollPositionMixin } from "../../mixins/scrollPosition";

    export default {
        name: "PremisesTable",

        components: {
            PremiseRow
        },

        props: {
            premises: {
                type: Array,
                required: true
            },
            maxHeight: {
                type: String,
                required: true
            },
            searchSubString: {
                type: String,
                required: true
            }
        },

        mixins: [ scrollPositionMixin ],

        methods: {
            updatePremise(id) {
                const premise = this.premises.find((premise) => premise.id === id);

                this.openModal({
                    name: 'modalPremise',
                    selectedEl: premise
                })
            }
        }
    }
</script>

<style lang="scss">
    .premise {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__group {
            width: 11%;
        }

        &__building {
            width: 11%;
        }

        &__entrance {
            width: 10%;
        }

        &__floor {
            width: 10%;
        }

        &__type {
            width: 14%;
        }

        &__name {
            width: 17%;
        }

        &__area {
            width: 10%;
        }

        &__registered {
            width: 10%;
        }

        &__file {
            width: 7%;
        }
    }
</style>