<template>
    <div class="finance-card"
         :class="{
            'finance-card--is-balance': isBalance
         }"
    >
        <div class="finance-card__icon">
            <slot/>
        </div>
        <div class="finance-card__info">
            <div class="finance-card__total">
                {{ total }}
            </div>
            <div class="finance-card__details">
                <div>{{ details }}</div>
                <app-text-btn
                    v-if="!isBalance"
                    :text="$t('common.buttons.view')"
                    @action="actionCard"
                >
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.0417 15.0417H3.95833V3.95833H9.5V2.375H3.95833C3.07958 2.375 2.375 3.0875 2.375 3.95833V15.0417C2.375 15.9125 3.07958 16.625 3.95833 16.625H15.0417C15.9125 16.625 16.625 15.9125 16.625 15.0417V9.5H15.0417V15.0417ZM11.0833 2.375V3.95833H13.9254L6.14333 11.7404L7.25958 12.8567L15.0417 5.07458V7.91667H16.625V2.375H11.0833Z" fill="#2D28F3"/>
                    </svg>
                </app-text-btn>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FinanceCard",

        props: {
            type: {
                type: String,
                required: true,
                validator(type) {
                    return [
                        'balance',
                        'clients',
                        'payments',
                        'contracts',
                        'invoices'
                    ].includes(type);
                }
            },
            total: {
                type: String,
                required: true
            },
            details: {
                type: String,
                required: true
            }
        },

        computed: {
            isBalance() {
                return this.type === 'balance';
            }
        },

        methods: {
            actionCard() {
                this.$emit('action');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .finance-card {
        padding: 15px;
        display: flex;
        align-items: center;
        border-radius: 13px;
        color: var(--app-base-primary);
        background: var(--app-btn-secondary);
        width: 100%;

        &__icon {
            width: 106px;
            min-width: 106px;
            height: 106px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--system-white);
            border-radius: 13px;
            margin-right: 30px;
        }

        &__info {
            width: 100%;
        }

        &__total {
            font-size: 32px;
            margin-bottom: 15px;
            font-weight: 500;
        }

        &__details {
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &--is-balance {
            color: var(--system-white);
            background: linear-gradient(90deg, var(--brand-purple) 0%, var(--brand-iris) 100%);
        }

        @media screen and (max-width: 1770px) {
            &__total {
                margin-bottom: 10px;
            }

            &__details {
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;

                button {
                    margin-top: 10px;
                }
            }
        }
    }
</style>