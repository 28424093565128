<template>
    <div class="finance-total">
        <div class="finance-total__balance balance"
            :class="{
                'balance--is-debt': isDebtBalance,
                'balance--is-overpay': isOverpayBalance,
                'balance--is-zero': isZeroBalance,
                'balance--is-operation-amount': isOperationAmount,
                'balance--is-amount': isAmount
            }"
        >
            <div v-html="formattedTotal"/>
            <div v-if="isRestructured" class="balance__progress">
                1
            </div>
            <div v-else>
                <div v-if="showRestructured && isDebtBalance" class="balance__control">
                    <app-circle-btn
                        @action="openModal"
                    >
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.49375 1.25C4.04375 1.25 1.25 4.05 1.25 7.5C1.25 10.95 4.04375 13.75 7.49375 13.75C10.95 13.75 13.75 10.95 13.75 7.5C13.75 4.05 10.95 1.25 7.49375 1.25ZM7.5 12.5C4.7375 12.5 2.5 10.2625 2.5 7.5C2.5 4.7375 4.7375 2.5 7.5 2.5C10.2625 2.5 12.5 4.7375 12.5 7.5C12.5 10.2625 10.2625 12.5 7.5 12.5ZM7.8125 4.375H6.875V8.125L10.1562 10.0937L10.625 9.325L7.8125 7.65625V4.375Z" fill="#2B3037"/>
                        </svg>
                    </app-circle-btn>
                </div>
            </div>
        </div>
        <div v-if="isRestructured" class="finance-total__progress progress">
            <div v-for="part in parts" :key="part.id"
                 class="progress__item"
                 :class="{
                    'progress__item--is-active': part.isActive
                 }">
            </div>
        </div>
    </div>
</template>

<script>
    import { formatCurrencyValue } from "../../../helpers/finance";

    export default {
        name: "FinanceTotalItem",

        props: {
            balance: {
                type: Number,
                required: true
            },
            isAmount: {
                type: Boolean,
                default: false
            },
            isOperationAmount: {
                type: Boolean,
                default: false
            },
            isRestructured: {
                type: Boolean,
                default: false
            },
            showRestructured: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                parts: [
                    {
                        id: '1',
                        isActive: true
                    },
                    {
                        id: '2',
                        isActive: true
                    },
                    {
                        id: '3',
                        isActive: true
                    },
                    {
                        id: '4',
                        isActive: true
                    },
                    {
                        id: '5',
                        isActive: true
                    },
                    {
                        id: '6',
                        isActive: false
                    }
                ]
            }
        },

        computed: {
            formattedTotal() {
                const { balance, currency } = formatCurrencyValue(this.balance);

                return this.isZeroBalance
                    ? `${balance} ${currency}`
                    : this.isOverpayBalance
                    ? `+ ${balance} ${currency}`
                    : `- ${balance.substring(1)} ${currency}`;
            },

            isDebtBalance() {
                return this.balance < 0;
            },

            isOverpayBalance() {
                return this.balance > 0;
            },

            isZeroBalance() {
                return this.balance === 0;
            }
        },

        methods: {
            openModal() {
                this.$emit('openModal');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .finance-total {
        display: flex;
        flex-direction: column;
    }

    .balance {
        display: flex;
        font-weight: 700;
        align-items: center;

        &--is-zero {
            padding-left: 10px;
            color: var(--system-green);
        }

        &--is-debt {
            color: var(--system-red);
        }

        &--is-overpay {
            color: var(--system-blue);
        }

        &--is-amount {
            padding: 8px 12px;
            background-color: var(--brand-main-15);
            color: var(--brand-main);
            border-radius: 100px;
        }

        &__control, &__progress {
            margin-left: 10px;
        }

        &__progress {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            background: var(--system-red-15);
            border-radius: 100px;
            font-weight: 400;
            font-size: 12px;
            color: var(--system-red);
        }
    }

    .balance--is-zero.balance--is-operation-amount {
        color: var(--system-blue);
    }

    .balance--is-overpay.balance--is-operation-amount {
        color: var(--system-green);
    }

    .progress {
        display: flex;
        margin-top: 5px;

        &__item {
            height: 4px;
            border-radius: 100px 0 0 100px;
            background: var(--app-border);
            width: 100%;

            &:not(:last-child) {
                margin-right: 2px;
            }

            &--is-active {
                background: var(--brand-main);
            }
        }
    }
</style>