<template>
    <div class="finance-invoices-table invoice">
        <div v-if="isNoneSelected" class="table__head">
            <div class="table__column invoice__control">
                <app-base-checkbox
                    :value="'selectAll'"
                    v-model="isSelectAll"
                />
            </div>
            <div class="table__column invoice__number">
                <app-overflow-match-wrapper
                    :text="$t('finance.table.invoice')"
                />
            </div>
            <div class="table__column invoice__user-name">
                <app-overflow-match-wrapper
                    :text="$t('finance.table.userName')"
                />
            </div>
            <div class="table__column invoice__user-document">
                <app-overflow-match-wrapper
                    :text="$t('finance.table.userDocument')"
                />
            </div>
            <div class="table__column invoice__building">
                <app-overflow-match-wrapper
                    :text="$t('finance.table.building')"
                />
            </div>
            <div class="table__column invoice__due-date">
                <app-overflow-match-wrapper
                    :text="$t('finance.table.dueDate')"
                />
            </div>
            <div class="table__column invoice__total">
                <app-overflow-match-wrapper
                    :text="$t('finance.table.total')"
                />
            </div>
            <div class="table__column invoice__state">
                <app-overflow-match-wrapper
                    :text="$t('finance.table.state')"
                />
            </div>
            <div class="table__column invoice__payment-date">
                <app-overflow-match-wrapper
                    :text="$t('finance.table.paymentDate')"
                />
            </div>
        </div>
        <div v-else class="table__head">
            <div class="table__column invoice__control">
                <app-base-checkbox
                    :value="'selectAll'"
                    v-model="isSelectAll"
                />
            </div>
            <div class="table__column invoice__panel">
                <div class="invoice__selected">
                    {{ selectedQty }}
                </div>
                <div class="invoice__send">
                    <finance-panel-btn
                        :type="'send'"
                        :variation="'invoice'"
                    />
                </div>
                <div class="invoice__delete">
                    <finance-panel-btn
                        :type="'delete'"
                        :variation="'invoice'"
                        @delete="openModalConfirm"
                    />
                </div>
            </div>
        </div>
        <div class="table__body" :style="{ 'maxHeight': maxHeight }">
            <finance-invoice-row v-for="invoice in invoices" :key="invoice.id"
                :id="invoice.id"
                :name="invoice.name"
                :user-name="invoice.userName"
                :user-document="invoice.userDocument"
                :buildings-name="invoice.buildingsName"
                :due-date="invoice.dueDate"
                :total="invoice.total"
                :state="invoice.state"
                :pay-date="invoice.payDate"
                :positions="invoice.positions"
                :link="invoice.link"
                :sub-string="searchSubString"
                @select-invoice="updateSelectedInvoices"
            />
        </div>
    </div>
</template>

<script>
    import FinancePanelBtn from "../FinancePanelBtn";
    import FinanceInvoiceRow from "./FinanceInvoiceRow";
    import { getAndDownloadFile } from "../../../api/requests/common";

    export default {
        name: "FinanceInvoicesTable",

        components: {
            FinanceInvoiceRow,
            FinancePanelBtn
        },

        props: {
            invoices: {
                type: Array,
                required: true
            },
            maxHeight: {
                type: String,
                required: true
            },
            searchSubString: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                isSelectAll: false,
                selectedInvoices: []
            }
        },

        watch: {
            isSelectAll(newState, oldState) {
                this.emitter.emit('selectAll', newState);
            }
        },

        computed: {
            isNoneSelected() {
                return this.selectedInvoices.length === 0;
            },

            selectedQty() {
                return `${this.$t('finance.actions.selected')} ${this.selectedInvoices.length}`;
            }
        },

        methods: {
            updateSelectedInvoices(id) {
                if (this.selectedInvoices.includes(id)) {
                    this.selectedInvoices = this.selectedInvoices.filter((invoiceId) => invoiceId !== id);
                } else {
                    this.selectedInvoices.push(id);
                }
            },

            openModalConfirm() {
                const selectedElement = {
                    id: this.selectedInvoices,
                    type: 'invoices'
                };

                this.openModal({
                    name: 'modalConfirm',
                    selectedEl: selectedElement
                })
            },
        }
    }
</script>