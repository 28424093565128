<template>
    <div class="finance">
        <div class="finance__panel">
            <div class="finance__title">{{ $t('finance.title') }}</div>
        </div>
        <div class="finance__control">
            <div class="finance__menu">
                <app-menu-item v-for="item in items" :key="item.id"
                    :id="item.id"
                    :name="item.name"
                    :route="item.route"
                    :qty="qtyReviewInvoices"
                />
            </div>
        </div>
        <div class="finance__content">
            <router-view/>
        </div>
    </div>
</template>

<script>
    import AppMenuItem from "../common/local/AppMenuItem";
    import { financeMenuItems } from "../../constants/finance";
    import { djinAPI } from "../../config/api";

    export default {
        name: "FinanceControl",

        components: {
            AppMenuItem
        },

        data() {
            return {
                qtyReviewInvoices: 0
            }
        },

        created() {
            this.getAdditionalFinanceInfo();
        },

        mounted() {
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromEvents();
        },

        computed: {
            items() {
                return financeMenuItems;
            }
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('updateReviewInvoices', this.getAdditionalFinanceInfo);
            },

            unsubscribeFromEvents() {
                this.emitter.off('updateReviewInvoices', this.getAdditionalFinanceInfo);
            },

            getAdditionalFinanceInfo() {
                djinAPI.get('/finance/home')
                    .then((result) => {
                        this.qtyReviewInvoices = result.data.invoices_inspection;
                    })
            }
        }
    }
</script>

<style lang="scss">
    .finance__control {
        padding: 0 30px;
    }
</style>
