<template>
    <app-modal-wrapper>
        <div class="modal-group-buildings" ref="modalGroupBuildings">
            <div class="group-buildings">
                <form @submit.prevent="doActionGroupBuildings">
                    <h4>{{ title }}</h4>
                    <div class="group-buildings__info">
                        <div class="group-buildings__details">
                            <div class="group-buildings__name">
                                <app-base-input
                                    v-model="groupBuildings.name"
                                    :label="$t('buildings.groups.name.label')"
                                    :place-holder="$t('buildings.groups.name.ph')"
                                    :error-message="errors.name"
                                />
                            </div>
                            <div class="group-buildings__select-buildings">
                                <app-base-multiple-select
                                    :label="$t('buildings.groups.buildings.label')"
                                    :place-holder="$t('buildings.groups.buildings.ph')"
                                    :selected-options="selectedBuildings"
                                    :options="availableBuildings"
                                    @select="selectBuildings"
                                />
                            </div>
                        </div>
                        <div class="group-buildings__details">
                            <app-upload-image
                                :label="$t('upload.label.mobile')"
                                :type="'image'"
                                :accept-types="acceptBuildingLogoTypes"
                                :url="groupBuildings.logo"
                                :height="'fixed'"
                                :preview-type="'building'"
                                @update="updateGroupLogo"
                            />
                        </div>
                    </div>
                    <div class="group-buildings__control">
                        <app-base-btn v-if="isUpdate"
                            :variation="'delete'"
                            :text="$t('common.buttons.delete')"
                            @action="openModalConfirm"
                        />
                        <app-base-btn
                            :size="'fixed'"
                            :variation="'cancel'"
                            :text="$t('common.buttons.cancel')"
                            @action="closeModalGroupBuildings"
                        />
                        <app-base-btn
                            :type="'submit'"
                            :size="'fixed'"
                            :variation="'save'"
                            :text="$t('common.buttons.save')"
                        />
                    </div>
                </form>
            </div>
        </div>
    </app-modal-wrapper>
</template>

<script>
    import cloneDeep from "lodash.clonedeep";
    import { djinAPI } from "../../../config/api";
    import { validateGroupBuildings } from "../../../validations/buildings";
    import { mapGroupBuildingToSend } from "../../../api/mappers/buildings";
    import { buildingLogoTypes } from "../../../constants/uploadFileTypes";
    import { buildingsToastsMixin } from "../../../mixins/toasts/buildings";
    import AppUploadImage from "../../common/local/upload/AppUploadImage";
    import { mapGetters } from "vuex";

    export default {
        name: "ModalGroupBuildings",

        components: {
            AppUploadImage
        },

        props: {
            selectedGroupBuildings: {
                type: Object
            }
        },

        mixins: [
            buildingsToastsMixin
        ],

        data() {
            return {
                groupBuildings: {
                    name: '',
                    logo: '',
                    buildings: []
                },

                errors: {
                    name: ''
                },

                logo: {
                    file: '',
                    isChanged: false
                },

                selectedBuildings: [],
                availableBuildings: []
            }
        },

        created() {
            this.checkUpdateGroupBuildings();
        },

        computed: {
            ...mapGetters({
                mappedFreeBuildings: 'buildings/mappedFreeBuildings',
            }),

            isUpdate() {
                return !!this.selectedGroupBuildings;
            },

            title() {
                return !this.isUpdate
                    ? this.$t('buildings.groups.title.create')
                    : this.$t('buildings.groups.title.edit');
            },

            acceptBuildingLogoTypes() {
                return buildingLogoTypes;
            }
        },

        methods: {
            checkUpdateGroupBuildings() {
                if (this.isUpdate) {
                    this.groupBuildings = cloneDeep(this.selectedGroupBuildings);
                    this.selectedBuildings = this.groupBuildings.buildings;
                    this.availableBuildings = this.groupBuildings.buildings.concat(this.mappedFreeBuildings);
                } else {
                    this.availableBuildings = cloneDeep(this.mappedFreeBuildings);
                }
            },

            doActionGroupBuildings() {
                this.clearGroupBuildingsErrors();
                const { isValid, errors } = validateGroupBuildings(this.groupBuildings);
                this.errors = errors;

                if (isValid) {
                    if (!this.isUpdate) {
                        this.createGroupBuildings();
                    } else {
                        this.changeGroupBuildings();
                    }
                }
            },

            createGroupBuildings() {
                const groupToSend = mapGroupBuildingToSend(this.groupBuildings);

                djinAPI.post('/buildings/groups', groupToSend)
                    .then((result) => {
                        const id = result.data._id;
                        this.showCreateGroupBuildingsToast()
                        this.uploadLogo(id);
                    })
            },

            changeGroupBuildings() {
                const id = this.groupBuildings.id
                const groupToSend = mapGroupBuildingToSend(this.groupBuildings);

                djinAPI.patch(`/buildings/groups/${id}`, groupToSend)
                    .then(() => {
                        this.uploadLogo(id);
                    })
            },

            uploadLogo(id) {
                if (!this.isUpdate) {
                    if (this.groupBuildings.logo) {
                        this.sendLogo(id, this.groupBuildings.logo);
                    } else {
                        this.updateGroupBuildings();
                    }
                } else {
                    if (this.groupBuildings.logo) {
                        //Logo have already been uploaded
                        if (this.logo.isChanged) {
                            //Avatar has been changed
                            if (this.logo.file) {
                                this.sendLogo(id, this.logo.file);
                            } else {
                                this.deleteLogo(id);
                            }
                        } else {
                            this.updateGroupBuildings();
                        }
                    } else {
                        if (this.logo.file) {
                            this.sendLogo(id, this.logo.file);
                        } else {
                            this.updateGroupBuildings();
                        }
                    }
                }
            },

            deleteLogo(id) {
                djinAPI.delete(`/buildings/groups/${id}/logo`)
                    .then(() => {
                        this.updateGroupBuildings();
                    })
            },

            sendLogo(id, logo) {
                const data = new FormData();
                data.append('logo', logo);

                djinAPI.post(`/buildings/groups/${id}/logo`, data, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                    .then(() => {
                        this.updateGroupBuildings();
                    })
            },

            clearGroupBuildingsErrors() {
                for (let key in this.errors) {
                    this.errors[key] = '';
                }
            },

            selectBuildings(buildings) {
                this.groupBuildings.buildings = buildings;
            },

            updateGroupLogo(image) {
                if (!this.isUpdate) {
                    this.groupBuildings.logo = image.file;
                } else {
                    this.logo.file = image.file;
                    this.logo.isChanged = image.isChanged;
                }
            },

            updateGroupBuildings() {
                this.closeModalGroupBuildings();
                this.emitter.emit('updateGroupBuildings');
            },

            closeModalGroupBuildings() {
                this.closeModal('modalGroupBuildings');
            },

            openModalConfirm() {
                const selectedElement = {
                    id: this.groupBuildings.id,
                    name: this.groupBuildings.name,
                    type: 'groupBuildings'
                };

                this.openModal({
                    name: 'modalConfirm',
                    selectedEl: selectedElement
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-group-buildings {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        max-width: 1118px;
        width: 100%;
    }

    .group-buildings {
        h4 {
            margin-bottom: 30px;
        }

        &__info {
            display: flex;
            margin-bottom: 45px;
        }

        &__details {
            flex-basis: 50%;

            &:first-child {
                margin-right: 30px;
            }
        }

        &__name {
            margin-bottom: 15px;
        }

        &__control {
            display: flex;
            justify-content: flex-end;
            /*margin-top: 45px;*/

            button:not(:last-child) {
                margin-right: 30px;
            }
        }
    }
</style>
