import i18n from "../../src/localization/i18n"
const { t } = i18n.global;
import { validateLoginData } from "./login";
import { validatePasswordData } from "./password";
import { validatePhoneNumber } from "./common";

const adminErrors = {
    username: t('validation.admin.username'),
    tempPass: t('validation.admin.tempPass'),
    phone: t('validation.admin.phone')
}

function validateAdmin(admin, isUpdate) {
    let isValid = false;

    const errors = {
        username: '',
        tempPass: '',
        phone: ''
    };

    if (!isUpdate) {
        const isValidUsername = validateLoginData(admin.username);

        if (!isValidUsername) {
            errors.username = adminErrors.username;
        }

        const isValidPassword = validatePasswordData(admin.tempPass);

        if (!isValidPassword) {
            errors.tempPass = adminErrors.tempPass;
        }

        const isValidPhoneNumber = validatePhoneNumber(admin.phone.number);

        if (!isValidPhoneNumber) {
            errors.phone = adminErrors.phone;
        }

        isValid = isValidUsername && isValidPassword && isValidPhoneNumber;
    } else {
        const isValidPhoneNumber = validatePhoneNumber(admin.phone.number);

        if (!isValidPhoneNumber) {
            errors.phone = adminErrors.phone;
        }

        isValid = isValidPhoneNumber;
    }

    return { isValid, errors };
}

function validateProfile(profile) {
    const errors = {
        phone: ''
    };

    let isValid = false;

    const isValidPhoneNumber = validatePhoneNumber(profile.phone.number);

    if (!isValidPhoneNumber) {
        errors.phone = adminErrors.phone;
    } else {
        isValid = true;
    }

    return { isValid, errors };
}

export {
    validateAdmin,
    validateProfile
}