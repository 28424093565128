<template>
    <div class="element-table__row"
        :style="{
            'color': colorBasePrimary,
            'backgroundColor': index % 2 === 0 ? 'var(--white)' : colorTableBackground,
            'borderBottomColor': colorBorder
        }"
    >
        <div class="element-table__user element__user">
            <div class="element-user">
                <div class="element-user__image">
                    <img v-if="!hasNoImage" :src="image" alt="Image">
                    <div v-else class="element-user__ph"
                        :style="{
                            'backgroundColor': colorBrandPink,
                        }"
                    >
                        <div class="element-user__abbr">
                            {{ abbrNoImage }}
                        </div>
                    </div>
                </div>
                <div class="element-user__info">
                    <div class="element-user__name">
                        {{ name }}
                    </div>
                    <div class="element-user__email">
                        {{ email }}
                    </div>
                </div>
            </div>
        </div>
        <div class="table__column element-table__created-date">
            <div class="element-table__date">
                {{ createdDate }}
            </div>
        </div>
        <div class="element-table__actions element-actions">
            <div class="element-actions__delete-btn">
                <svg class="element-actions__btn" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 5.625V11.875H5V5.625H10ZM9.0625 1.875H5.9375L5.3125 2.5H3.125V3.75H11.875V2.5H9.6875L9.0625 1.875ZM11.25 4.375H3.75V11.875C3.75 12.5625 4.3125 13.125 5 13.125H10C10.6875 13.125 11.25 12.5625 11.25 11.875V4.375Z" fill="#FF3B30"/>
                </svg>
            </div>
            <div class="element-actions__edit-btn"
                :style="{
                    'backgroundColor': colorBtnSecondary
                }"
            >
                <svg class="element-actions__btn" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.87427 13.1256H4.21802L11.1305 6.21309L8.78677 3.86934L1.87427 10.7818V13.1256ZM3.12427 11.3006L8.78677 5.63809L9.36177 6.21309L3.69927 11.8756H3.12427V11.3006Z" fill="#2B3037"/>
                    <path d="M11.4805 2.05684C11.2368 1.81309 10.843 1.81309 10.5993 2.05684L9.45552 3.20059L11.7993 5.54434L12.943 4.40059C13.1868 4.15684 13.1868 3.76309 12.943 3.51934L11.4805 2.05684Z" fill="#2B3037"/>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "IndividualElementRow",

        props: {
            index: {
                type: Number,
                required: true
            },
            id: {
                type: Number,
                required: true
            },
            image: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            email: {
                type: String,
                required: true
            },
            createdDate: {
                type: String,
                required: true
            },

            colorBasePrimary: {
                type: String,
                required: true
            },
            colorTableBackground: {
                type: String,
                required: true
            },
            colorBorder: {
                type: String,
                required: true
            },
            colorBtnSecondary: {
                type: String,
                required: true
            },
            colorBrandPink: {
                type: String,
                required: true
            }
        },

        computed: {
            hasNoImage() {
                return !this.image;
            },

            abbrNoImage() {
                if (this.name) {
                    const [ name, surname ] = this.name.split(' ');
                    return name.charAt(0).toUpperCase() + surname.charAt(0).toUpperCase();
                } else {
                    return this.email.charAt(0).toUpperCase();
                }
            }
        }
    }
</script>