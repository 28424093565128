import i18n from "../../../src/localization/i18n"
const { t } = i18n.global;
import { financePremisesTypes } from "../../constants/finance";
import { createEntrancesList, createFloorsList } from "../../helpers/buildings";

const finance = {
    namespaced: true,

    state: () => ({
        buildingGroups: [
            {
                id: '1',
                name: t('finance.data.groups.group1'),
                total: {
                    value: -1980,
                    isRestructured: false
                },
                buildings: [
                    {
                        id: '1',
                        name: `${t('finance.data.groups.group1')} - ${t('finance.data.buildings.building')} 1`,
                        groupName: t('finance.data.groups.group1'),
                        total: {
                            value: -1080,
                            isRestructured: false
                        },
                        entrances: createEntrancesList(2),
                        floors: createFloorsList(1, 4, false),
                        users: [
                            {
                                id: '34567238',
                                name: t('finance.data.users.user1'),
                                fullName: `${financePremisesTypes['flat']} 23, ${t('finance.data.users.user1')}`,
                                entrance: 1,
                                floor: 1,
                                premises: [
                                    {
                                        id: '1',
                                        buildingId: '1',
                                        userId: '34567238',
                                        name: `${financePremisesTypes['flat']} 23`,
                                        total: {
                                            value: 0,
                                            isRestructured: false
                                        },
                                        balance: 1100,
                                        area: 83,
                                    }
                                ],
                                total: {
                                    value: 0,
                                    isRestructured: false
                                },
                                turn: {
                                    id: '1',
                                    debit: {
                                        start: 22500,
                                        end: 18500,
                                        turn: 1200
                                    },
                                    credit: 4000
                                },
                                turns: [
                                    {
                                        id: '1',
                                        date: '12.05.2022',
                                        debit: {
                                            start: 11000,
                                            end: 9000
                                        },
                                        credit: 2000
                                    },
                                    {
                                        id: '1',
                                        date: '25.05.2022',
                                        debit: {
                                            start: 11500,
                                            end: 9500
                                        },
                                        credit: 2000
                                    },
                                ],
                            },
                            {
                                id: '34567239',
                                name: t('finance.data.users.user2'),
                                fullName: `${financePremisesTypes['flat']} 30, ${t('finance.data.users.user2')}`,
                                entrance: 1,
                                floor: 2,
                                premises: [
                                    {
                                        id: '2',
                                        buildingId: '1',
                                        userId: '34567239',
                                        name: `${financePremisesTypes['flat']} 30`,
                                        total: {
                                            value: -600,
                                            isRestructured: false
                                        },
                                        balance: 1200,
                                        area: 86
                                    },
                                    {
                                        id: '3',
                                        buildingId: '1',
                                        userId: '34567239',
                                        name: `${financePremisesTypes['parking']} 11`,
                                        total: {
                                            value: -800,
                                            isRestructured: false
                                        },
                                        balance: 200,
                                        area: 15
                                    },
                                ],
                                total: {
                                    value: -1400,
                                    isRestructured: true
                                },
                                turn: {
                                    id: '1',
                                    debit: {
                                        start: 22500,
                                        end: 18500,
                                        turn: 1200
                                    },
                                    credit: 4000
                                },
                                turns: [
                                    {
                                        id: '1',
                                        date: '12.05.2022',
                                        debit: {
                                            start: 11000,
                                            end: 9000
                                        },
                                        credit: 2000
                                    },
                                    {
                                        id: '1',
                                        date: '25.05.2022',
                                        debit: {
                                            start: 11500,
                                            end: 9500
                                        },
                                        credit: 2000
                                    },
                                ],
                            },
                            {
                                id: '34567240',
                                name: t('finance.data.users.user3'),
                                fullName: `${financePremisesTypes['flat']} 47, ${t('finance.data.users.user3')}`,
                                entrance: 1,
                                floor: 3,
                                premises: [
                                    {
                                        id: '4',
                                        buildingId: '1',
                                        userId: '34567240',
                                        name: `${financePremisesTypes['flat']} 47`,
                                        total: {
                                            value: 320,
                                            isRestructured: false
                                        },
                                        balance: 1400,
                                        area: 102
                                    }
                                ],
                                total: {
                                    value: 320,
                                    isRestructured: false
                                },
                                turn: {
                                    id: '1',
                                    debit: {
                                        start: 22500,
                                        end: 18500,
                                        turn: 1200
                                    },
                                    credit: 4000
                                },
                                turns: [
                                    {
                                        id: '1',
                                        date: '12.05.2022',
                                        debit: {
                                            start: 11000,
                                            end: 9000
                                        },
                                        credit: 2000
                                    },
                                    {
                                        id: '1',
                                        date: '25.05.2022',
                                        debit: {
                                            start: 11500,
                                            end: 9500
                                        },
                                        credit: 2000
                                    },
                                ],
                            },
                        ],
                        turn: {
                            id: '1',
                            debit: {
                                start: 67500,
                                end: 57500,
                                turn: 3600
                            },
                            credit: 12000
                        },
                    },
                    {
                        id: '2',
                        name: `${t('finance.data.groups.group1')} - ${t('finance.data.buildings.building')} 2`,
                        groupName: t('finance.data.groups.group1'),
                        total: {
                            value: -2400,
                            isRestructured: false
                        },
                        entrances: createEntrancesList(2),
                        floors: createFloorsList(1, 4, false),
                        users: [
                            {
                                id: '34567241',
                                name: t('finance.data.users.user4'),
                                fullName: `${financePremisesTypes['flat']} 11, ${t('finance.data.users.user4')}`,
                                entrance: 1,
                                floor: 1,
                                premises: [
                                    {
                                        id: '5',
                                        buildingId: '2',
                                        userId: '34567241',
                                        name: `${financePremisesTypes['flat']} 11`,
                                        total: {
                                            value: -350,
                                            isRestructured: false
                                        },
                                        balance: 1200,
                                        area: 44
                                    },
                                    {
                                        id: '6',
                                        buildingId: '2',
                                        userId: '34567241',
                                        name: `${financePremisesTypes['parking']} 3`,
                                        total: {
                                            value: -450,
                                            isRestructured: false
                                        },
                                        balance: 200,
                                        area: 15
                                    },
                                ],
                                total: {
                                    value: -800,
                                    isRestructured: false
                                },
                                turn: {
                                    id: '1',
                                    debit: {
                                        start: 22500,
                                        end: 18500,
                                        turn: 1200
                                    },
                                    credit: 4000
                                },
                                turns: [
                                    {
                                        id: '1',
                                        date: '12.05.2022',
                                        debit: {
                                            start: 11000,
                                            end: 9000
                                        },
                                        credit: 2000
                                    },
                                    {
                                        id: '1',
                                        date: '25.05.2022',
                                        debit: {
                                            start: 11500,
                                            end: 9500
                                        },
                                        credit: 2000
                                    },
                                ],
                            },
                            {
                                id: '34567242',
                                name: t('finance.data.users.user5'),
                                fullName: `${financePremisesTypes['flat']} 25, ${t('finance.data.users.user5')}`,
                                entrance: 1,
                                floor: 2,
                                premises: [
                                    {
                                        id: '7',
                                        buildingId: '2',
                                        userId: '34567242',
                                        name: `${financePremisesTypes['flat']} 25`,
                                        total: {
                                            value: -1450,
                                            isRestructured: false
                                        },
                                        balance: 1500,
                                        area: 42
                                    },
                                    {
                                        id: '8',
                                        buildingId: '2',
                                        userId: '34567242',
                                        name: `${financePremisesTypes['pantry']} 8`,
                                        total: {
                                            value: -150,
                                            isRestructured: false
                                        },
                                        balance: 100,
                                        area: 7
                                    },
                                ],
                                total: {
                                    value: -1600,
                                    isRestructured: false
                                },
                                turn: {
                                    id: '1',
                                    debit: {
                                        start: 22500,
                                        end: 18500,
                                        turn: 1200
                                    },
                                    credit: 4000
                                },
                                turns: [
                                    {
                                        id: '1',
                                        date: '12.05.2022',
                                        debit: {
                                            start: 11000,
                                            end: 9000
                                        },
                                        credit: 2000
                                    },
                                    {
                                        id: '1',
                                        date: '25.05.2022',
                                        debit: {
                                            start: 11500,
                                            end: 9500
                                        },
                                        credit: 2000
                                    },
                                ],
                            }
                        ],
                        turn: {
                            id: '1',
                            debit: {
                                start: 50000,
                                end: 42000,
                                turn: 2400
                            },
                            credit: 8000
                        },
                    },
                    {
                        id: '3',
                        name: `${t('finance.data.groups.group1')} - ${t('finance.data.buildings.building')} 3`,
                        groupName: t('finance.data.groups.group1'),
                        total: {
                            value: 1500,
                            isRestructured: false
                        },
                        entrances: createEntrancesList(2),
                        floors: createFloorsList(1, 4, false),
                        users: [
                            {
                                id: '34567243',
                                name: t('finance.data.users.user6'),
                                fullName: `${financePremisesTypes['flat']} 2, ${t('finance.data.users.user6')}`,
                                entrance: 1,
                                floor: 1,
                                premises: [
                                    {
                                        id: '9',
                                        buildingId: '3',
                                        userId: '34567243',
                                        name: `${financePremisesTypes['flat']} 2`,
                                        total: {
                                            value: 1250,
                                            isRestructured: false
                                        },
                                        balance: 1500,
                                        area: 85
                                    },
                                ],
                                total: {
                                    value: 1250,
                                    isRestructured: false
                                },
                                turn: {
                                    id: '1',
                                    debit: {
                                        start: 22500,
                                        end: 18500,
                                        turn: 1200
                                    },
                                    credit: 4000
                                },
                                turns: [
                                    {
                                        id: '1',
                                        date: '12.05.2022',
                                        debit: {
                                            start: 11000,
                                            end: 9000
                                        },
                                        credit: 2000
                                    },
                                    {
                                        id: '1',
                                        date: '25.05.2022',
                                        debit: {
                                            start: 11500,
                                            end: 9500
                                        },
                                        credit: 2000
                                    },
                                ],
                            },
                            {
                                id: '34567244',
                                name: t('finance.data.users.user7'),
                                fullName: `${financePremisesTypes['flat']} 32, ${t('finance.data.users.user7')}`,
                                entrance: 1,
                                floor: 2,
                                premises: [
                                    {
                                        id: '10',
                                        buildingId: '3',
                                        userId: '34567244',
                                        name: `${financePremisesTypes['flat']} 32`,
                                        total: {
                                            value: 0,
                                            isRestructured: false
                                        },
                                        balance: 1400,
                                        area: 87
                                    },
                                    {
                                        id: '11',
                                        buildingId: '3',
                                        userId: '34567244',
                                        name: `${financePremisesTypes['parking']} 9`,
                                        total: {
                                            value: 0,
                                            isRestructured: false
                                        },
                                        balance: 200,
                                        area: 15
                                    },
                                ],
                                total: {
                                    value: 0,
                                    isRestructured: false
                                },
                                turn: {
                                    id: '1',
                                    debit: {
                                        start: 22500,
                                        end: 18500,
                                        turn: 1200
                                    },
                                    credit: 4000
                                },
                                turns: [
                                    {
                                        id: '1',
                                        date: '12.05.2022',
                                        debit: {
                                            start: 11000,
                                            end: 9000
                                        },
                                        credit: 2000
                                    },
                                    {
                                        id: '1',
                                        date: '25.05.2022',
                                        debit: {
                                            start: 11500,
                                            end: 9500
                                        },
                                        credit: 2000
                                    },
                                ],
                            },
                            {
                                id: '34567245',
                                name: t('finance.data.users.user8'),
                                fullName: `${financePremisesTypes['flat']} 63, ${t('finance.data.users.user8')}`,
                                entrance: 2,
                                floor: 1,
                                premises: [
                                    {
                                        id: '12',
                                        buildingId: '3',
                                        userId: '34567245',
                                        name: `${financePremisesTypes['flat']} 63`,
                                        total: {
                                            value: 250,
                                            isRestructured: false
                                        },
                                        balance: 1200,
                                        area: 110
                                    }
                                ],
                                total: {
                                    value: 250,
                                    isRestructured: false
                                },
                                turn: {
                                    id: '1',
                                    debit: {
                                        start: 22500,
                                        end: 18500,
                                        turn: 1200
                                    },
                                    credit: 4000
                                },
                                turns: [
                                    {
                                        id: '1',
                                        date: '12.05.2022',
                                        debit: {
                                            start: 11000,
                                            end: 9000
                                        },
                                        credit: 2000
                                    },
                                    {
                                        id: '1',
                                        date: '25.05.2022',
                                        debit: {
                                            start: 11500,
                                            end: 9500
                                        },
                                        credit: 2000
                                    },
                                ],
                            }
                        ],
                        turn: {
                            id: '3',
                            debit: {
                                start: 67500,
                                end: 57500,
                                turn: 3600
                            },
                            credit: 12000
                        },
                    },
                ],
                turn: {
                    id: '1',
                    debit: {
                        start: 185000,
                        end: 153000,
                        turn: 9600
                    },
                    credit: 32000
                },
            },
        ],
        financeContracts: [
            {
                id: '11111111',
                userId: '34567238',
                userName: t('finance.data.users.user1'),
                startDate: '12.02.2022',
                endDate: '12.02.2023',
                premises: [
                    {
                        id: '1',
                        name: `${financePremisesTypes['flat']} 23`,
                        tariff: 1200
                    }
                ],
                tariff: 1200,
                state: 2
            },
            {
                id: '11111112',
                userId: '34567239',
                userName: t('finance.data.users.user2'),
                startDate: '13.02.2022',
                endDate: '13.02.2023',
                premises: [
                    {
                        id: '2',
                        name: `${financePremisesTypes['flat']} 30`,
                        tariff: 1200
                    },
                    {
                        id: '3',
                        name: `${financePremisesTypes['parking']} 11`,
                        tariff: 200
                    },
                ],
                tariff: 1400,
                state: 2
            },
            {
                id: '11111113',
                userId: '34567240',
                userName: t('finance.data.users.user3'),
                startDate: '14.02.2021',
                endDate: '14.02.2022',
                premises: [
                    {
                        id: '4',
                        name: `${financePremisesTypes['flat']} 47`,
                        tariff: 1600
                    }
                ],
                tariff: 1600,
                state: 4
            },
            {
                id: '11111114',
                userId: '34567241',
                userName: t('finance.data.users.user4'),
                startDate: '28.06.2022',
                endDate: '28.06.2023',
                premises: [
                    {
                        id: '5',
                        name: `${financePremisesTypes['flat']} 11`,
                        tariff: 2000
                    },
                    {
                        id: '6',
                        name: `${financePremisesTypes['parking']} 3`,
                        tariff: 200
                    },
                ],
                tariff: 2200,
                state: 1
            },
            {
                id: '11111115',
                userId: '34567242',
                userName: t('finance.data.users.user5'),
                startDate: '28.07.2021',
                endDate: '28.07.2022',
                premises: [
                    {
                        id: '7',
                        name: `${financePremisesTypes['flat']} 25`,
                        tariff: 1000
                    },
                    {
                        id: '8',
                        name: `${financePremisesTypes['pantry']} 8`,
                        tariff: 100
                    },
                ],
                tariff: 1100,
                state: 3
            },
            {
                id: '11111116',
                userId: '34567243',
                userName: t('finance.data.users.user6'),
                startDate: '29.06.2022',
                endDate: '29.06.2023',
                premises: [
                    {
                        id: '9',
                        name: `${financePremisesTypes['flat']} 2`,
                        tariff: 1200
                    },
                ],
                tariff: 1200,
                state: 0
            },
            {
                id: '11111117',
                userId: '34567244',
                userName: t('finance.data.users.user7'),
                startDate: '29.06.2022',
                endDate: '29.06.2023',
                premises: [
                    {
                        id: '10',
                        name: `${financePremisesTypes['flat']} 32`,
                        tariff: 1700,
                    },
                    {
                        id: '11',
                        name: `${financePremisesTypes['parking']} 9`,
                        tariff: 200,
                    },
                ],
                tariff: 1900,
                state: 0
            },
            {
                id: '11111118',
                userId: '34567245',
                userName: t('finance.data.users.user8'),
                startDate: '22.02.2022',
                endDate: '22.02.2023',
                premises: [
                    {
                        id: '12',
                        name: `${financePremisesTypes['flat']} 63`,
                        tariff: 1300,
                    }
                ],
                tariff: 1300,
                state: 2
            },
        ],
        financeInvoices: [
            {
                id: '21111111',
                userId: '34567238',
                userName: t('finance.data.users.user1'),
                userBuilding: `${t('finance.data.buildings.building')} 1`,
                purpose: `${financePremisesTypes['flat']} 23`,
                dueDate: '25.06.2022',
                paymentDate: '20.06.2022',
                premises: [
                    {
                        id: '1',
                        name: `${financePremisesTypes['flat']} 23`,
                        total: 1100,
                        state: 1,
                        paymentDate: '20.06.2022',
                    }
                ],
                state: 1,
                total: 1100,
                subTotal: 0
            },
            {
                id: '21111112',
                userId: '34567239',
                userName: t('finance.data.users.user2'),
                userBuilding: `${t('finance.data.buildings.building')} 1`,
                purpose: `${financePremisesTypes['flat']} 30, ${financePremisesTypes['parking']} 11`,
                dueDate: '25.06.2022',
                paymentDate: '19.06.2022',
                premises: [
                    {
                        id: '2',
                        name: `${financePremisesTypes['flat']} 30`,
                        total: 1000,
                        state: 1,
                        paymentDate: '19.06.2022',
                    },
                    {
                        id: '3',
                        name: `${financePremisesTypes['parking']} 11`,
                        total: 200,
                        state: 1,
                        paymentDate: '19.06.2022',
                    },
                ],
                state: 1,
                total: 1200,
                subTotal: 0
            },
            {
                id: '21111113',
                userId: '34567240',
                userName: t('finance.data.users.user3'),
                userBuilding: `${t('finance.data.buildings.building')} 1`,
                purpose: `${financePremisesTypes['flat']} 47`,
                dueDate: '25.06.2022',
                paymentDate: '21.06.2022',
                premises: [
                    {
                        id: '4',
                        name: `${financePremisesTypes['flat']} 47`,
                        total: 1400,
                        state: 1,
                        paymentDate: '21.06.2022',
                    }
                ],
                state: 1,
                total: 1400,
                subTotal: 0
            },
            {
                id: '21111114',
                userId: '34567241',
                userName: t('finance.data.users.user4'),
                userBuilding: `${t('finance.data.buildings.building')} 2`,
                purpose: `${financePremisesTypes['flat']} 11, ${financePremisesTypes['parking']} 3`,
                dueDate: '25.06.2022',
                paymentDate: '21.06.2022',
                premises: [
                    {
                        id: '5',
                        name: `${financePremisesTypes['flat']} 11`,
                        total: 900,
                        state: 2,
                        paymentDate: '21.06.2022',
                    },
                    {
                        id: '6',
                        name: `${financePremisesTypes['parking']} 3`,
                        total: 200,
                        state: 2,
                        paymentDate: '21.06.2022',
                    },
                ],
                state: 2,
                total: 1400,
                subTotal: 1100
            },
            {
                id: '21111115',
                userId: '34567242',
                userName: t('finance.data.users.user5'),
                userBuilding: `${t('finance.data.buildings.building')} 2`,
                purpose: `${financePremisesTypes['flat']} 25, ${financePremisesTypes['pantry']} 8`,
                dueDate: '25.06.2022',
                paymentDate: '21.06.2022',
                premises: [
                    {
                        id: '7',
                        name: `${financePremisesTypes['flat']} 25`,
                        total: 1200,
                        state: 2,
                        paymentDate: '21.06.2022',

                    },
                    {
                        id: '8',
                        name: `${financePremisesTypes['pantry']} 8`,
                        total: 100,
                        state: 2,
                        paymentDate: '21.06.2022',

                    },
                ],
                state: 2,
                total: 1600,
                subTotal: 1300
            },
            {
                id: '21111116',
                userId: '34567243',
                userName: t('finance.data.users.user6'),
                userBuilding: `${t('finance.data.buildings.building')} 3`,
                purpose: `${financePremisesTypes['flat']} 2`,
                dueDate: '25.06.2022',
                paymentDate: '21.06.2022',
                premises: [
                    {
                        id: '9',
                        name: `${financePremisesTypes['flat']} 2`,
                        total: 1500,
                        state: 4,
                        paymentDate: '21.06.2022',
                    },
                ],
                state: 4,
                total: 1500,
                subTotal: 0
            },
            {
                id: '21111117',
                userId: '34567244',
                userName: t('finance.data.users.user7'),
                userBuilding: `${t('finance.data.buildings.building')} 3`,
                purpose: `${financePremisesTypes['flat']} 32, ${financePremisesTypes['parking']} 9`,
                dueDate: '25.06.2022',
                paymentDate: '21.06.2022',
                premises: [
                    {
                        id: '10',
                        name: `${financePremisesTypes['flat']} 32`,
                        total: 1500,
                        state: 3,
                        paymentDate: '21.06.2022',
                    },
                    {
                        id: '11',
                        name: `${financePremisesTypes['parking']} 9`,
                        total: 300,
                        state: 3,
                        paymentDate: '21.06.2022',
                    },
                ],
                state: 3,
                total: 1600,
                subTotal: 1800
            },
            {
                id: '21111118',
                userId: '34567245',
                userName: t('finance.data.users.user8'),
                userBuilding: `${t('finance.data.buildings.building')} 3`,
                purpose: `${financePremisesTypes['flat']} 63`,
                dueDate: '25.06.2022',
                paymentDate: '21.06.2022',
                premises: [
                    {
                        id: '12',
                        name: `${financePremisesTypes['flat']} 63`,
                        total: 1200,
                        state: 4,
                        paymentDate: '21.06.2022',
                    }
                ],
                state: 4,
                total: 1200,
                subTotal: 1200
            },
        ],
        financeCheckingInvoices: [
            {
                id: '21111119',
                date: '18.06.2022',
                time: '11:33',
                total: {
                    exhibited: 1100,
                    paid: 900
                },
                payment: {
                    client: t('finance.data.users.user1'),
                    number: 'GE29NB0000000101904917',
                    purpose: t('finance.review.purpose')
                },
                state: 2,
                premises: [
                    {
                        id: '1',
                        buildingId: '1',
                        userId: '34567238',
                        name: `${financePremisesTypes['flat']} 23`,
                        balance: 1100
                    }
                ],
            },
            {
                id: '21111120',
                date: '13.06.2022',
                time: '12:31',
                total: {
                    exhibited: 1200,
                    paid: 800
                },
                payment: {
                    client: t('finance.data.users.user2'),
                    number: 'GE29NB0000000101904917',
                    purpose: t('finance.review.purpose')
                },
                state: 2,
                premises: [
                    {
                        id: '2',
                        buildingId: '1',
                        userId: '34567239',
                        name: `${financePremisesTypes['flat']} 30`,
                        balance: 1000
                    },
                    {
                        id: '3',
                        buildingId: '1',
                        userId: '34567239',
                        name: `${financePremisesTypes['parking']} 11`,
                        balance: 200
                    }
                ],
            },
            {
                id: '21111121',
                date: '14.06.2022',
                time: '17:31',
                total: {
                    exhibited: 1400,
                    paid: 1300
                },
                payment: {
                    client: t('finance.data.users.user3'),
                    number: 'GE29NB0000000101904917',
                    purpose: t('finance.review.purpose')
                },
                state: 2,
                premises: [
                    {
                        id: '4',
                        buildingId: '1',
                        userId: '34567240',
                        name: `${financePremisesTypes['flat']} 47`,
                        balance: 1400
                    }
                ],
            },
            {
                id: '21111122',
                date: '22.06.2022',
                time: '09:31',
                total: {
                    exhibited: 1400,
                    paid: 1600
                },
                payment: {
                    client: t('finance.data.users.user4'),
                    number: 'GE29NB0000000101904917',
                    purpose: t('finance.review.purpose')
                },
                state: 3,
                premises: [
                    {
                        id: '5',
                        buildingId: '2',
                        userId: '34567241',
                        name: `${financePremisesTypes['flat']} 11`,
                        balance: 1200
                    },
                    {
                        id: '6',
                        buildingId: '2',
                        userId: '34567241',
                        name: `${financePremisesTypes['parking']} 3`,
                        balance: 200,
                        area: 15
                    },
                ],
            },
            {
                id: '21111123',
                date: '23.06.2022',
                time: '16:31',
                total: {
                    exhibited: 1600,
                    paid: 1900
                },
                payment: {
                    client: t('finance.data.users.user5'),
                    number: 'GE29NB0000000101904917',
                    purpose: t('finance.review.purpose')
                },
                state: 3,
                premises: [
                    {
                        id: '7',
                        buildingId: '2',
                        userId: '34567242',
                        name: `${financePremisesTypes['flat']} 25`,
                        balance: 1500
                    },
                    {
                        id: '8',
                        buildingId: '2',
                        userId: '34567242',
                        name: `${financePremisesTypes['pantry']} 8`,
                        balance: 100
                    },
                ],
            },
            {
                id: '21111124',
                date: '11.06.2022',
                time: '11:34',
                total: {
                    exhibited: 1500,
                    paid: 1500
                },
                payment: {
                    client: t('finance.data.users.user6'),
                    number: 'GE29NB0000000101904917',
                    purpose: t('finance.review.purpose')
                },
                state: 5,
                premises: [
                    {
                        id: '9',
                        buildingId: '3',
                        userId: '34567243',
                        name: `${financePremisesTypes['flat']} 2`,
                        balance: 1500
                    },
                ],
            },
            {
                id: '21111125',
                date: '24.06.2022',
                time: '15:21',
                total: {
                    exhibited: 1600,
                    paid: 1300
                },
                payment: {
                    client: t('finance.data.users.user7'),
                    number: 'GE29NB0000000101904917',
                    purpose: t('finance.review.purpose')
                },
                state: 2,
                premises: [
                    {
                        id: '10',
                        buildingId: '3',
                        userId: '34567244',
                        name: `${financePremisesTypes['flat']} 32`,
                        balance: 1400
                    },
                    {
                        id: '11',
                        buildingId: '3',
                        userId: '34567244',
                        name: `${financePremisesTypes['parking']} 9`,
                        balance: 200
                    },
                ],
            },
            {
                id: '21111126',
                date: '25.06.2022',
                time: '09:11',
                total: {
                    exhibited: 1200,
                    paid: 1100
                },
                payment: {
                    client: t('finance.data.users.user8'),
                    number: 'GE29NB0000000101904917',
                    purpose: t('finance.review.purpose')
                },
                state: 2,
                premises: [
                    {
                        id: '12',
                        buildingId: '3',
                        userId: '34567245',
                        name: `${financePremisesTypes['flat']} 63`,
                        balance: 1200
                    }
                ],
            },
        ]
    }),

    getters: {
        groups(state) {
            return state.buildingGroups;
        },

        contracts(state) {
            return state.financeContracts;
        },

        invoices(state) {
            return state.financeInvoices;
        },

        checkingInvoices(state) {
            return state.financeCheckingInvoices;
        },

        mappedGroups(state) {
            return state.buildingGroups.map((group) => {
                const { buildings, ...rest } = group;

                return { children: buildings.map(building => {
                    const { users, ...rest } = building;

                    return { children: users.map((user => {
                        const { premises, ...rest } = user;

                        return { children: premises, ...rest };
                        })), ...rest };
                    }), ...rest};
            })
        },

        filters(state) {
            return {
                groups: state.buildingGroups.map((group) => {
                    return {
                        id: group.id,
                        name: group.name,
                    }
                }),
                buildings: state.buildingGroups[0].buildings.map((building) => {
                    return {
                        id: building.id,
                        name: building.name,
                        groupName: building.groupName
                    }
                }),
                entrances: [
                    {
                        id: '1',
                        name: `1 ${t('finance.data.entrances')}`
                    },
                    {
                        id: '2',
                        name: `2 ${t('finance.data.entrances')}`
                    },
                ],
                floors: [
                    {
                        id: '1',
                        name: `1 ${t('finance.data.floors')}`
                    },
                    {
                        id: '2',
                        name: `2 ${t('finance.data.floors')}`
                    },
                    {
                        id: '3',
                        name: `3 ${t('finance.data.floors')}`
                    },
                    {
                        id: '4',
                        name: `4 ${t('finance.data.floors')}`
                    },
                ],
                categories: [
                    {
                        id: '1',
                        name: t('finance.data.categories.living')
                    },
                    {
                        id: '2',
                        name: t('finance.data.categories.apartments')
                    },
                    {
                        id: '3',
                        name: t('finance.data.categories.commercial')
                    },
                    {
                        id: '4',
                        name: t('finance.data.categories.technical')
                    },
                    {
                        id: '5',
                        name: t('finance.data.categories.parking')
                    },
                ]
            }
        },

        extendedFilters(state, getters) {
            const extendedFilters = {
                views: [
                    {
                        id: '1',
                        name: t('filters.view.day')
                    },
                    {
                        id: '2',
                        name: t('filters.view.week')
                    }
                ],
                operations: [
                    {
                        id: '3',
                        name: t('filters.operation.debit')
                    },
                    {
                        id: '4',
                        name: t('filters.operation.credit')
                    }
                ]
            }

            return { ...extendedFilters, ...getters.filters };
        },

        extendedBalanceFilters(state, getters) {
            const extendedFilters = {
                balance: [
                    {
                        id: 'debt',
                        name: t('filters.balance.debt')
                    },
                    {
                        id: 'overpay',
                        name: t('filters.balance.overpay')
                    },
                    {
                        id: 'zero',
                        name: t('filters.balance.zero')
                    },
                ]
            }

            return { ...extendedFilters, ...getters.filters };
        }
    }
}

export {
    finance
}