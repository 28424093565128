<template>
    <div class="filter-element">
        <div class="filter-element__row">
            <button type="button" class="filter-element__btn" @click="toggleShowElements"
                :class="{
                    'filter-element__btn--show-elements': showElements
                }"
            >
                <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_768_22103)">
                        <path d="M2 1.675L5.81667 5.5L2 9.325L3.175 10.5L8.175 5.5L3.175 0.5L2 1.675Z" fill="#2B3037"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_768_22103">
                            <rect width="10" height="10" fill="white" transform="matrix(0 1 1 0 0 0.5)"/>
                        </clipPath>
                    </defs>
                </svg>
            </button>
            <div class="filter-element__name">{{ elementName }}</div>
        </div>
        <div v-if="showElements" class="filter-element__elements">
            <div v-if="elementsToView.length">
                <div v-for="element in elementsToView" :key="element.id" class="filter-element__element">
                    <app-base-radio-btn v-if="showRadioButtons"
                        v-model="activeElement"
                        :name="name"
                        :id="element.id"
                        :label="element.name"
                        :is-filter="true"
                    />
                    <app-base-checkbox v-else
                        v-model="filterActiveElements"
                        :value="element.id"
                        :label="element.name"
                    />
                </div>
<!--                <div v-if="elements.length > 3 && !showAllElements">-->
<!--                    <app-text-btn-->
<!--                        :text="$t('filters.more')"-->
<!--                        @action="showAllElements = true"-->
<!--                    />-->
<!--                </div>-->
            </div>
            <div v-else class="filter-element__empty">
                {{ noDataText }}
            </div>
        </div>
    </div>
</template>

<script>
    import { filtersText } from "../../constants/filters";

    export default {
        name: "AppFilterElement",

        props: {
            name: {
                type: String,
                required: true
            },
            elements: {
                type: Array,
                required: true
            },
            activeElements: {
                type: Array,
                required: true
            }
        },

        data() {
            return {
                activeElement: '',
                showElements: false,
                showAllElements: false,
                filterActiveElements: []
            }
        },

        created() {
            this.init();
        },

        mounted() {
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromEvents();
        },

        watch: {
            activeElement(newState, oldState) {
                this.filterActiveElements = [];
                this.filterActiveElements.push(newState);
            }
        },

        computed: {
            elementName() {
                return filtersText[this.name];
            },

            noDataText() {
                return filtersText.noData;
            },

            elementsToView() {
                // return this.elements.length > 3
                //     ? !this.showAllElements
                //     ? this.elements.slice(0,3)
                //     : this.elements
                //     : this.elements;

                return this.elements;
            },

            showRadioButtons() {
                // return this.name === 'views' || this.name === 'operations';
                return false;
            }
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('updateFilterActiveElements', this.updateActiveFilters);
            },

            unsubscribeFromEvents() {
                this.emitter.off('updateFilterActiveElements', this.updateActiveFilters);
            },

            init() {
                this.filterActiveElements = [ ...this.activeElements ];

                // if (this.name === 'views' || this.name === 'operations') {
                //     if (this.filterActiveElements.length) {
                //         this.activeElement = this.filterActiveElements[0];
                //     }
                // }
            },

            updateActiveFilters() {
                this.$emit('update', this.name, this.filterActiveElements);
            },

            toggleShowElements() {
                this.showElements = !this.showElements;

                if (!this.showElements && this.showAllElements) {
                    this.showAllElements = false;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .filter-element {
        display: flex;
        flex-direction: column;

        &__row {
            display: flex;
            align-items: center;
            padding: 15px 30px;
        }

        &__btn {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;

            &--show-elements {
                transform: rotate(90deg);
            }
        }

        &__name {
            font-size: 20px;
            line-height: 23px;
            color: var(--app-base-primary);
        }

        &__elements {
            padding: 15px 30px;
            background: var(--app-table-background);
        }

        &__element:not(:last-child) {
            margin-bottom: 15px;
        }

        &__empty {
            padding-left: 20px;
        }
    }
</style>