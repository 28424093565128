import { showToast } from "../../../helpers/toasts";

const reviewInvoicesToastsMixin = {
    methods: {
        showDeleteReviewInvoiceToast() {
            const deleteToastTitle = this.$t('toasts.reviewInvoices.delete.title');

            const deleteToastDetails = `
                    ${this.$t(`toasts.reviewInvoices.delete.details1`)}
                    ${this.$t(`toasts.reviewInvoices.delete.details2`)}`
            ;

            showToast('success',
                deleteToastTitle,
                deleteToastDetails
            );
        }
    }
}

export {
    reviewInvoicesToastsMixin
}