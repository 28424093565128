import i18n from "../../src/localization/i18n"
const { t } = i18n.global;
const langCode = i18n.global.locale;

const selectPeriods = [
    {
        id: '1',
        name: t('sort.period.day'),
        children: [
            {
                id: '11',
                name: t('sort.period.male.current'),
            },
            {
                id: '12',
                name: t('sort.period.male.last'),
            },
            {
                id: '13',
                name: t('sort.period.male.before'),
            },
        ]
    },
    {
        id: '2',
        name: t('sort.period.week'),
        children: [
            {
                id: '21',
                name: langCode === 'ru'
                    ? t('sort.period.female.current')
                    : t('sort.period.male.current')
            },
            {
                id: '22',
                name: langCode === 'ru'
                    ? t('sort.period.female.last')
                    : t('sort.period.male.last')
            },
            {
                id: '23',
                name: langCode === 'ru'
                    ? t('sort.period.female.before')
                    : t('sort.period.male.before')
            },
        ]
    },
    {
        id: '3',
        name: t('sort.period.month'),
        children: [
            {
                id: '31',
                name: t('sort.period.male.current'),
            },
            {
                id: '32',
                name: t('sort.period.male.last'),
            },
            {
                id: '33',
                name: t('sort.period.male.before'),
            },
        ]
    },
    {
        id: '4',
        name: t('sort.period.quarter'),
        children: [
            {
                id: '41',
                name: t('sort.period.numbers.quarter1'),
            },
            {
                id: '42',
                name: t('sort.period.numbers.quarter2'),
            },
            {
                id: '43',
                name: t('sort.period.numbers.quarter3'),
            },
            {
                id: '44',
                name: t('sort.period.numbers.quarter4'),
            }
        ]
    },
    {
        id: '5',
        name: t('sort.period.year'),
        children: [
            {
                id: '51',
                name: t('sort.period.male.current'),
            },
            {
                id: '52',
                name: t('sort.period.male.last'),
            },
            {
                id: '53',
                name: t('sort.period.male.before'),
            },
        ]
    },
]

export {
    selectPeriods
}