const languages = [
    {
        type: 'uk',
        name: 'UA'
    },
    {
        type: 'en',
        name: 'EN'
    },
    {
        type: 'ru',
        name: 'RU'
    },
    {
        type: 'ka',
        name: 'KA'
    },
]

export {
    languages
}