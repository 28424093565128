<template>
    <app-modal-wrapper>
        <div class="modal-invoice" ref="modalInvoice">
            <form @submit.prevent="doActionInvoice" class="invoice-form">
                <h4>{{ $t('finance.invoice.title') }}</h4>
<!--                <div class="invoice-form__info">-->
<!--                    <div class="invoice-form__details">-->
<!--                        <app-base-input-->
<!--                            v-model="invoice.number"-->
<!--                            :required="false"-->
<!--                            :label="$t('finance.invoice.number.label')"-->
<!--                            :place-holder="$t('finance.invoice.number.ph')"-->
<!--                        />-->
<!--                    </div>-->
<!--                    <div class="invoice-form__details">-->
<!--                        <app-base-select-->
<!--                            :is-clear="true"-->
<!--                            :label="$t('finance.invoice.from.label')"-->
<!--                            :place-holder="$t('finance.invoice.from.ph')"-->
<!--                            :selected-option="selectedGroup"-->
<!--                            :options="invoiceGroups"-->
<!--                            :type="'text'"-->
<!--                            @select="selectGroup"-->
<!--                        />-->
<!--                    </div>-->
<!--                </div>-->
                <div class="invoice-form__info">
                    <div class="invoice-form__details">
                        <div class="invoice-form__building">
                            <app-base-select
                                :label="$t('finance.invoice.building.label')"
                                :place-holder="$t('finance.invoice.building.ph')"
                                :selected-option="selectedBuilding"
                                :options="buildings"
                                :type="'text'"
                                @select="selectBuilding"
                            />
                        </div>
                        <div class="invoice-form__to">
                            <app-base-select ref="to"
                                :label="$t('finance.invoice.to.label')"
                                :place-holder="$t('finance.invoice.to.ph')"
                                :is-clear="true"
                                :is-searchable="true"
                                :is-disabled="isLoadedUsers"
                                :selected-option="selectedUser"
                                :options="filteredUsers"
                                :type="'text'"
                                :error-message="errors.selectedUser"
                                @select="selectUser"
                            />
                        </div>
                    </div>
                    <div class="invoice-form__details">
                        <app-base-select ref="premise"
                            :label="$t('finance.invoice.premise.label')"
                            :place-holder="$t('finance.invoice.premise.ph')"
                            :is-clear="true"
                            :is-searchable="true"
                            :is-disabled="isLoadedPremises"
                            :selected-option="selectedPremise"
                            :options="premises"
                            :type="'text'"
                            @select="selectPremise"
                        />
                    </div>
                </div>
                <div class="invoice-form__info">
                    <div class="invoice-form__details">
                        <app-date-picker
                            :label="$t('finance.invoice.date.label')"
                            :init-date="invoice.dueDate"
                            :is-disabled="selectedType?.id === '2'"
                            @update="selectDueDate"
                        />
                    </div>
                    <div class="invoice-form__details">
<!--                        <app-base-select-->
<!--                            :label="$t('finance.invoice.type.label')"-->
<!--                            :place-holder="$t('finance.invoice.type.ph')"-->
<!--                            :selected-option="selectedType"-->
<!--                            :options="invoiceTypes"-->
<!--                            :type="'text'"-->
<!--                            @select="selectInvoiceType"-->
<!--                        />-->
                    </div>
                </div>
                <div class="invoice-form__positions">
                    <invoice-position-row v-for="(position, index) in invoice.positions" :key="position.id"
                        :index="index"
                        :length="invoice.positions.length"
                        :id="position.id"
                        :price="position.price"
                        :templates="templates"
                        @update="updateTotalValue"
                        @delete="deleteInvoicePosition"
                    />
                </div>
                <div class="invoice-form__total">
                    <div class="invoice-form__details">
                        <app-text-btn
                            :text="$t('finance.invoice.position.addBtn')"
                            @action="addPosition"
                        >
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.0416 10.2923H10.2916V15.0423H8.70825V10.2923H3.95825V8.70898H8.70825V3.95898H10.2916V8.70898H15.0416V10.2923Z" fill="var(--brand-main)"/>
                            </svg>
                        </app-text-btn>
                    </div>
                    <div class="invoice-form__details">
                        <div class="invoice-form__total-row">
                            <div>{{ $t('finance.invoice.total.pay') }}</div>
                            <div class="invoice-form__total-value">
                                {{ formattedTotal }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="invoice-form__control">
                    <app-base-btn
                        :size="'fixed'"
                        :variation="'cancel'"
                        :text="$t('common.buttons.cancel')"
                        @action="closeModalInvoice"
                    />
                    <app-base-btn
                        :type="'submit'"
                        :size="'fixed'"
                        :variation="'save'"
                        :text="$t('common.buttons.createInvoice')"
                    />
                </div>
            </form>
        </div>
    </app-modal-wrapper>
</template>

<script>
    import InvoicePositionRow from "../../finance/invoices/InvoicePositionRow";
    import { financeInvoiceGroups, financeInvoiceTypes } from "../../../constants/finance";
    import { clearDependentSelects } from "../../../helpers/selects";
    import { formatCurrencyValue } from "../../../helpers/finance";
    import { generateTemporaryId } from "../../../helpers/common";
    import { mapInvoiceTemplatesToView } from "../../../api/mappers/finance/templates";
    import { mapUsersToSelect, mapUsersToView } from "../../../api/mappers/users";
    import { mapPremisesToView } from "../../../api/mappers/premises";
    import { validateInvoice } from "../../../validations/finance/invoices";
    import cloneDeep from "lodash.clonedeep";
    import { djinAPI } from "../../../config/api";
    import { mapGetters } from "vuex";
    import { mapInvoiceToSend } from "../../../api/mappers/finance/invoices";
    import { invoicesToastsMixin } from "../../../mixins/toasts/finance/invoices";

    export default {
        name: "ModalInvoice",

        components: {
            InvoicePositionRow
        },

        data() {
            return {
                isLoadedUsers: false,
                isLoadedPremises: false,
                invoice: {
                    number: '',
                    total: 0,
                    dueDate: Date.now(),
                    positions: []
                },
                templates: [],
                premises: [],
                users: [],
                premiseUsers: [],
                selectedGroup: null,
                selectedBuilding: null,
                selectedPremise: null,
                selectedUser: null,
                selectedType: null,

                errors: {
                    selectedUser: ''
                }
            }
        },

        mixins: [
            invoicesToastsMixin
        ],

        created() {
            this.init();
        },

        computed: {
            ...mapGetters({
                buildings: 'buildings/mappedBuildings',
            }),

            // invoiceGroups() {
            //     return financeInvoiceGroups;
            // },
            //
            // invoiceTypes() {
            //     return financeInvoiceTypes;
            // },

            formattedTotal() {
                const { balance, currency } = formatCurrencyValue(this.invoice.total);

                return `${balance} ${currency}`;
            },

            filteredUsers() {
                return !!this.premiseUsers.length
                    ? this.users.filter((user) => this.premiseUsers.map((premiseUser) => premiseUser.id).includes(user.id))
                    : this.selectedPremise ? [] : this.users;
            }
        },

        methods: {
            init() {
                this.getTemplates();
                // this.selectedGroup = this.invoiceGroups[0];
                // this.selectedType = this.invoiceTypes[0];
                this.addPosition();
            },

            getTemplates() {
                this.isLoaded = true;

                djinAPI.get('/finance/templates')
                    .then((result) => {
                        const { invoices, ...rest } = result.data;
                        this.templates = mapInvoiceTemplatesToView(invoices);
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.isLoaded = false;
                        }, 500)
                    })
            },

            addPosition() {
                const id = generateTemporaryId()

                this.invoice.positions.push({
                    id: id,
                    isValid: false,
                    name: '',
                    price: 0,
                    qty: 1,
                    total: 0
                })

                this.invoice.total = this.getTotal(this.invoice.positions);

                if (this.invoice.positions.length !== 1) {
                    this.$nextTick(() => {
                        //Scroll modal to bottom
                        const modal = this.$refs.modalInvoice;
                        modal.scrollTop = modal.scrollHeight;
                    })
                }
            },

            deleteInvoicePosition(id) {
                this.invoice.positions = this.invoice.positions.filter((position) => position.id !== id);
                this.invoice.total = this.getTotal(this.invoice.positions);
            },

            doActionInvoice() {
                this.clearInvoiceErrors();
                //Validate and update invoice and position data
                this.emitter.emit('validatePosition');

                const { isValid, errors } = validateInvoice(this.invoice, this.selectedUser);
                this.errors = errors;

                const isValidPositions = this.invoice.positions.every((position) => position.isValid);

                if (isValid && isValidPositions) {
                    this.createInvoice();
                }
            },

            createInvoice() {
                const invoice = cloneDeep(this.invoice);
                invoice.userId = this.selectedUser.id;

                const mappedInvoice = mapInvoiceToSend(invoice);

                djinAPI.post('/finance/invoices', mappedInvoice)
                    .then((result) => {
                        this.showCreateToast();
                        this.updateInvoices();
                    })
            },

            updateInvoices() {
                this.closeModalInvoice()
                this.emitter.emit('updateInvoices');
            },

            clearInvoiceErrors() {
                for (let key in this.errors) {
                    this.errors[key] = '';
                }
            },

            closeModalInvoice() {
                this.closeModal('modalInvoice');
            },

            selectGroup(group) {
                this.selectedGroup = group;
            },

            selectPremise(premise) {
                this.selectedPremise = premise;

                if (this.selectedPremise) {
                    this.getPremiseUsers(this.selectedPremise.id);
                } else {
                    this.premiseUsers = [];
                    this.clearSelectedUser();
                }
            },

            clearSelectedUser() {
                this.$refs.to.clearSelectedOption();
            },

            reInitSelects() {
                this.$refs.to.initSelect();
            },

            selectBuilding(building) {
                this.selectedBuilding = building;

                this.clearDependentFields();
                this.premiseUsers = [];

                this.getBuildingUsers(this.selectedBuilding.id);
                this.getBuildingPremises(this.selectedBuilding.id);
            },

            getBuildingUsers(id) {
                this.isLoadedUsers = true;
                const params = {
                    params: {
                        filter: `building:${id}`
                    }
                };

                djinAPI.get(`/users`, params)
                    .then((result) => {
                        const users = mapUsersToView(result.data);
                        this.users = mapUsersToSelect(users);
                    })
                    .finally(() => {
                        this.isLoadedUsers = false;
                    })
            },

            getBuildingPremises(id) {
                this.isLoadedPremises = true;
                const params = {
                    params: {
                        filter: `building:${id}`
                    }
                };

                djinAPI.get('/premises', params)
                    .then((result) => {
                        const premises = result.data;
                        this.premises = mapPremisesToView(premises);
                    })
                    .finally(() => {
                        this.isLoadedPremises = false;
                    })
            },

            getPremiseUsers(id) {
                this.isLoadedUsers = true;

                djinAPI.get(`/premises/${id}`)
                    .then((result) => {
                        const users = mapUsersToView(result.data.users);
                        this.premiseUsers = mapUsersToSelect(users);

                        if (this.premiseUsers.length === 1) {
                            this.selectedUser = this.premiseUsers[0];

                            this.$nextTick(() => {
                                this.reInitSelects();
                            })
                        }
                    })
                    .finally(() => {
                        this.isLoadedUsers = false;
                    })
            },

            selectUser(user) {
                this.selectedUser = user;
            },

            clearDependentFields() {
                const dependentSelects = {
                    to: this.$refs.to,
                    premise: this.$refs.premise
                };

                clearDependentSelects(dependentSelects);
            },

            // selectInvoiceType(type) {
            //     this.selectedType = type;
            // },

            selectDueDate(date) {
                this.invoice.dueDate = date;
            },

            updateTotalValue(id, name, price, qty, total, isValid) {
                const position = this.invoice.positions.find((position) => position.id === id);
                position.name = name;
                position.price = parseFloat(price);
                position.total = parseFloat(total);
                position.qty = qty;
                position.isValid = isValid;
                this.invoice.total = this.getTotal(this.invoice.positions);
            },

            getTotal(positions) {
                let sum = 0;

                positions.forEach((position) => {
                    sum += position.total;
                });

                return sum;
            },
        }
    }
</script>

<style lang="scss" scoped>
    .modal-invoice {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        max-width: 1118px;
        width: 100%;
        max-height: 80vh;
        overflow-y: auto;
        scroll-behavior: smooth;
    }

    .invoice-form {
        &__info {
            display: flex;
            padding: 30px 0;
            border-bottom: 1px solid var(--app-border);

            &--is-period {
                align-items: flex-start;
            }

            &--is-premises {
                flex-direction: column;
            }

            h5 {
                margin-bottom: 30px;
            }
        }

        &__total {
            display: flex;
            padding: 30px 0;
            font-size: 16px;
        }

        &__total-row {
            display: flex;
            justify-content: space-between;
        }

        &__total-value {
            font-weight: 700;
        }

        &__details {
            flex-basis: 50%;

            &:first-child {
                margin-right: 30px;
            }
        }

        &__control {
            display: flex;
            justify-content: flex-end;
            margin-top: 45px;

            button:not(:last-child) {
                margin-right: 30px;
            }
        }

        &__building {
            margin-bottom: 15px;
        }

        /*&__label {*/
        /*    display: flex;*/
        /*    align-items: center;*/
        /*    font-size: 14px;*/
        /*    line-height: 16px;*/
        /*    height: 16px;*/
        /*    color: var(--app-base-secondary);*/
        /*    margin-bottom: 10px;*/

        /*    button {*/
        /*        margin: 0 0 2px 10px;*/
        /*    }*/
        /*}*/

        /*&__autorenewal {*/
        /*    margin-top: 15px;*/
        /*}*/

        /*&__building, &__floor {*/
        /*    margin-bottom: 15px;*/
        /*}*/

        /*&__premise {*/
        /*    &:not(:last-child) {*/
        /*        margin-bottom: 15px;*/
        /*    }*/
        /*}*/

        /*&__amount {*/
        /*    display: flex;*/
        /*}*/

        /*&__tariff {*/
        /*    display: flex;*/
        /*    flex-direction: column;*/
        /*}*/

        /*&__total {*/
        /*    display: flex;*/
        /*    justify-content: space-between;*/
        /*    padding-top: 30px;*/
        /*    font-size: 16px;*/
        /*}*/

        /*&__total-value {*/
        /*    font-weight: 500;*/
        /*}*/
    }
</style>