import { showToast } from "../../helpers/toasts";

const buildingsToastsMixin = {
    methods: {
        showDeleteGroupBuildingsToast(name) {
            const deleteToastTitle = this.$t('toasts.groups.delete.title');

            const deleteToastDetails = `
                    ${this.$t(`toasts.groups.delete.details1`)}
                    ${name}
                    ${this.$t(`toasts.groups.delete.details2`)}
                    ${this.$t(`toasts.groups.delete.details3`)}`
            ;

            showToast('success',
                deleteToastTitle,
                deleteToastDetails
            );
        },

        showCreateGroupBuildingsToast() {
            showToast('success',
                this.$t('toasts.success'),
                this.$t('toasts.groups.create.details')
            );
        },

        showDeleteBuildingToast(name) {
            const deleteToastTitle = this.$t('toasts.buildings.delete.title');

            const deleteToastDetails = `
                    ${this.$t(`toasts.buildings.delete.details1`)}
                    ${name}
                    ${this.$t(`toasts.buildings.delete.details2`)}`
            ;

            showToast('success',
                deleteToastTitle,
                deleteToastDetails
            );
        },

        showCreateBuildingToast() {
            showToast('success',
                this.$t('toasts.success'),
                this.$t('toasts.buildings.create.details')
            );
        }
    }
}

export {
    buildingsToastsMixin
}