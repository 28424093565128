<template>
    <app-modal-wrapper>
        <div class="modal-user" ref="modalUser">
            <form @submit.prevent="doActionUser" class="user-resident-form">
                <h4>{{ title }}</h4>
                <div class="user-resident-form__user">
                    <user-form
                        :id="user.id"
                        :is-new="true"
                        :surname="user.surname"
                        :name="user.name"
                        :middle-name="user.middleName"
                        :phone="user.phone"
                        :email="user.email"
                        :document-id="user.documentId"
                        @update="updateUser"
                    />
                </div>
                <div class="user-resident-form__premises">
                    <user-premise-form v-for="(premise, index) in user.premises" :key="premise.id"
                        :is-update="isUpdate"
                        :index="index"
                        :length="user.premises.length"
                        :id="premise.id"
                        :is-new="premise.isNew"
                        :buildings="buildings"
                        :building-id="premise.building.id"
                        :premise-id="premise.premise.id"
                        :is-owner="premise.isOwner"
                        :is-registered="premise.isRegistered"
                        @add="addNewUserPremise"
                        @delete="deleteUserPremise"
                        @update="updateUserPremise"
                    />
                </div>
                <div v-if="showInviteForm" class="user-resident-form__invite">
                    <invite-form
                        @update="updateInviteVariant"
                    />
                </div>
                <div class="user-resident-form__control">
                    <app-base-btn v-if="isUpdate"
                        :size="'fixed'"
                        :variation="'delete'"
                        :text="$t('common.buttons.delete')"
                        @action="openModalConfirm"
                    />
                    <app-base-btn
                        :size="'fixed'"
                        :variation="'cancel'"
                        :text="$t('common.buttons.cancel')"
                        @action="closeModalUser"
                    />
                    <app-base-btn
                        :type="'submit'"
                        :size="'fixed'"
                        :variation="'save'"
                        :text="saveBtnText"
                    />
                </div>
            </form>
        </div>
    </app-modal-wrapper>
</template>

<script>
    import cloneDeep from "lodash.clonedeep";
    import UserForm from "../../users/UserForm";
    import UserPremiseForm from "../../users/UserPremiseForm";
    import InviteForm from "../../invite/InviteForm";
    import { generateTemporaryId } from "../../../helpers/common";
    import { mapGetters } from "vuex";
    import { djinAPI } from "../../../config/api";
    import { mapUserToSend } from "../../../api/mappers/users";
    import { usersToastsMixin } from "../../../mixins/toasts/users";

    export default {
        name: "ModalUser",

        components: {
            UserForm,
            UserPremiseForm,
            InviteForm
        },

        props: {
            selectedUser: {
                type: Object
            }
        },

        mixins: [
            usersToastsMixin
        ],

        data() {
            return {
                user: {
                    id: '1',
                    surname: '',
                    name: '',
                    middleName: '',
                    email: '',
                    documentId: '',
                    phone: {
                        code: '+380',
                        number: '',
                    },
                    premises: []
                },
                inviteVariant: 'sms'
            }
        },

        created() {
            this.checkUpdateUser();
        },

        computed: {
            ...mapGetters({
                buildings: 'buildings/mappedBuildings',
            }),

            isUpdate() {
                return !!this.selectedUser;
            },

            title() {
                return this.isUpdate
                    ? this.$t('users.user.title.edit')
                    : this.$t('users.user.title.create');
            },

            showInviteForm() {
                return this.user.premises.some((premise) => premise.isNew);
            },

            saveBtnText() {
                return this.isUpdate
                    ? this.$t('common.buttons.save')
                    : this.$t('common.buttons.createUser');
            }
        },

        methods: {
            checkUpdateUser() {
                if (this.isUpdate) {
                    this.user = { ...cloneDeep(this.selectedUser) };
                    this.user.premises = this.user.premises.map((premise) => {
                        return { ...premise,
                            isValid: false,
                            isNew: false
                        }
                    })

                    if (!this.user.premises.length) {
                        this.addNewUserPremise(true);
                    }
                } else {
                    this.addNewUserPremise(true);
                }
            },

            doActionUser() {
                //Validate and update user and premise data
                this.emitter.emit('validateUser');

                const isValidUser = this.user.isValid;
                const isValidUserPremises = this.user.premises.every((premise) => premise.isValid);

                if (isValidUser && isValidUserPremises) {
                    if (!this.isUpdate) {
                        this.createUser();
                    } else {
                        this.changeUser();
                    }
                }
            },

            createUser() {
                const userToSend = mapUserToSend(this.user, this.inviteVariant);

                djinAPI.post('/users', userToSend)
                    .then((result) => {
                        //We need this for contracts
                        const user = result.data;
                        this.emitter.emit('updateContractUser', user._id);

                        this.showCreateUserToast();
                        this.updateUsers();
                    })
            },

            changeUser() {
                const id = this.user.id;
                const userToSend = mapUserToSend(this.user);

                djinAPI.patch(`/users/${id}`, userToSend)
                    .then(() => {
                        this.updateUsers();
                    })
            },

            updateUsers() {
                this.closeModalUser();
                this.emitter.emit('updateUsers');
            },

            addNewUserPremise(isInit) {
                const id = generateTemporaryId();

                const userPremise = {
                    id: id,
                    isValid: false,
                    isNew: true,
                    building: {
                        id: ''
                    },
                    premise: {
                        id: ''
                    },
                    isOwner: true,
                    isRegistered: true
                };

                this.user.premises.push(userPremise);

                if (!isInit) {
                    //Need to use nextTick for get new height block after add
                    this.$nextTick(() => {
                        const modal = this.$refs.modalUser;
                        modal.scrollTop = modal.scrollHeight;
                    });
                }
            },

            updateUser(id, isValid, user) {
                const userPremises = cloneDeep(this.user.premises);

                this.user = { ...user };
                this.user.isValid = isValid;
                this.user.premises = userPremises;
            },

            updateUserPremise(id, isNew, isValid, properties, selectedPremise) {
                let userPremise = null;

                if (!isNew) {
                    userPremise = this.user.premises.find((premise) => premise.premise.id === id);
                } else {
                    userPremise = this.user.premises.find((premise) => premise.id === id);
                }

                userPremise.isOwner = properties.isOwner;
                userPremise.isRegistered = properties.isRegistered;
                userPremise.premiseId = selectedPremise.id;
                userPremise.isValid = isValid;
            },

            updateInviteVariant(variant) {
                this.inviteVariant = variant;
            },

            deleteUserPremise(id) {
                this.user.premises = this.user.premises.filter((premise) => premise.id !== id);
            },

            closeModalUser() {
                this.closeModal('modalUser');
            },

            openModalConfirm() {
                const selectedElement = {
                    id: this.user.id,
                    type: 'user'
                };

                this.openModal({
                    name: 'modalConfirm',
                    selectedEl: selectedElement
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    .modal-user {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1118px;
        max-height: 80vh;
        overflow-y: auto;
        scrollbar-width: thin;
    }

    .user-resident-form {
        h4 {
            margin-bottom: 30px;
        }

        &__premises {
            display: flex;
            flex-wrap: wrap;
        }

        &__invite {
            border-top: 1px solid var(--app-border);
            padding-bottom: 15px;
        }

        &__control {
            display: flex;
            justify-content: flex-end;
            margin-top: 15px;

            button:not(:last-child) {
                margin-right: 30px;
            }
        }
    }
</style>