<template>
    <div class="buildings">
        <div class="buildings__panel">
            <div class="buildings__title">{{ $t('buildings.title') }}</div>
            <div class="buildings__actions">
                <div class="buildings__action-btn">
                    <app-base-btn
                        :size="'padding'"
                        :variation="'base'"
                        :text="$t('buildings.groupBtn')"
                        @action="openModalGroupBuildings"
                    >
                        <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.7917 14.0413H13.5417V20.2913H11.4584V14.0413H5.20837V11.958H11.4584V5.70801H13.5417V11.958H19.7917V14.0413Z" fill="#2B3037"/>
                        </svg>
                    </app-base-btn>
                </div>
                <app-add-btn
                    @open-modal="openModalBuilding"
                />
            </div>
        </div>
        <div class="buildings__control">
            <div class="buildings__filters">
                <buildings-filters
                    :qty="groups.allBuildings.length"
                    @update="updateQueryParams"
                />
            </div>
        </div>
        <div class="buildings__content" ref="buildings">
            <div v-if="showTable" class="buildings__table">
                <buildings-table
                    :building-groups="groups.buildingGroups"
                    :free-buildings="groups.freeBuildings"
                    :cities="groups.cities"
                    :max-height="maxHeightScroll"
                    :search-sub-string="searchSubString"
                />
            </div>
            <div v-if="showEmpty" class="buildings__empty">
                <app-empty-content
                    :content-type="'buildings'"
                    @open-modal="openModalBuilding"
                />
            </div>
            <div v-if="showNoResults" class="buildings__empty">
                <app-no-results
                    @clear="clearQueryParams"
                />
            </div>
            <div v-if="isLoaded" class="buildings__loader">
                <app-loader
                    :size="'large'"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import BuildingsTable from "./BuildingsTable";
    import BuildingsFilters from "./BuildingsFilters";
    import { djinAPI } from "../../config/api";
    import { maxHeightMixin } from "../../mixins/calcMaxHeight";
    import { buildingsToastsMixin } from "../../mixins/toasts/buildings";
    import { mapGetters, mapActions } from "vuex";
    import { mapBuildingsToView, mapCitiesToView, mapGroupBuildingsToView, mapGroupBuildingsToStore } from "../../api/mappers/buildings";

    export default {
        name: "BuildingsControl",

        components: {
            BuildingsFilters,
            BuildingsTable
        },

        mixins: [
            maxHeightMixin,
            buildingsToastsMixin
        ],

        data() {
            return {
                isLoaded: false,
                groups: {
                    buildingGroups: [],
                    freeBuildings: [],
                    allBuildings: [],
                    cities: []
                },
                params: null
            }
        },

        created() {
            this.getGroupBuildings();
        },

        mounted() {
            this.subscribeToResize('buildings');
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromResize();
            this.unsubscribeFromEvents();
        },

        computed: {
            ...mapGetters({
                buildings: 'buildings/mappedBuildings'
            }),

            isExistBuildings() {
                return this.groups.buildingGroups.length || this.groups.freeBuildings.length;
            },

            showTable() {
                return this.isExistBuildings && !this.isLoaded;
            },

            showEmpty() {
                return !this.isExistBuildings && !this.isLoaded && !this.isExistQueryParams;
            },

            showNoResults() {
                return !this.isExistBuildings && !this.isLoaded && this.isExistQueryParams;
            },

            isExistQueryParams() {
                return !!this.searchSubString;
            },

            searchSubString() {
                return this.params?.params?.match ? this.params?.params?.match : '';
            }
        },

        methods: {
            ...mapActions({
                updateGroupBuildingsToStore: 'buildings/getGroupBuildings'
            }),

            subscribeToEvents() {
                this.emitter.on('updateGroupBuildings', this.updateGroupBuildings);
                this.emitter.on('deleteGroupBuildings', this.deleteGroupBuildings);
                this.emitter.on('deleteBuilding', this.deleteBuilding);
            },

            unsubscribeFromEvents() {
                this.emitter.off('updateGroupBuildings', this.updateGroupBuildings);
                this.emitter.off('deleteGroupBuildings', this.deleteGroupBuildings);
                this.emitter.off('deleteBuilding', this.deleteBuilding);
            },

            getGroupBuildings() {
                this.isLoaded = true;

                djinAPI.get('/buildings', this.params)
                    .then((result) => {
                        const { cities, ...groupBuildings } = result.data;
                        const { buildingGroups, freeBuildings } =  { ...groupBuildings };

                        const { buildings, ...rest } = mapGroupBuildingsToStore(groupBuildings);

                        this.groups.buildingGroups = mapGroupBuildingsToView(buildingGroups);
                        this.groups.freeBuildings = mapBuildingsToView(freeBuildings);
                        this.groups.allBuildings = buildings;
                        this.groups.cities = mapCitiesToView(cities);

                        this.emitter.emit('updateShowQty');

                        this.$nextTick(() => {
                            this.calcMaxHeight();
                        });
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.isLoaded = false;
                        }, 500)
                    })
            },

            deleteGroupBuildings(id) {
                const group = this.groups.buildingGroups.find((group) => group.id === id);

                djinAPI.delete(`/buildings/groups/${id}`)
                    .then(() => {
                        this.closeModalConfirm();
                        this.closeModalGroupBuildings();
                        this.showDeleteGroupBuildingsToast(group.name);
                        this.updateGroupBuildings();
                    })
            },

            deleteBuilding(id) {
                const building = this.buildings.find((building) => building.id === id);

                djinAPI.delete(`/buildings/${id}`)
                    .then(() => {
                        this.closeModalConfirm();
                        this.closeModalBuilding();
                        this.showDeleteBuildingToast(building.name);
                        this.updateGroupBuildings();
                    })
            },

            openModalBuilding() {
                this.openModal({
                    name: 'modalBuilding'
                });
            },

            openModalGroupBuildings() {
                this.openModal({
                    name: 'modalGroupBuildings'
                });
            },

            closeModalConfirm() {
                this.closeModal('modalConfirm');
            },

            closeModalGroupBuildings() {
                this.closeModal('modalGroupBuildings');
            },

            closeModalBuilding() {
                this.closeModal('modalBuilding');
            },

            updateQueryParams(params) {
                this.params = { ...params };
                this.getGroupBuildings();
            },

            clearQueryParams() {
                this.emitter.emit('clearQueryParams');
            },

            updateGroupBuildings() {
                this.getGroupBuildings();
                this.updateGroupBuildingsToStore();
            }
        }
    }
</script>