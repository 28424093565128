<template>
    <div class="profile">
        <div class="profile__panel">
            <div class="profile__title">{{ $t('profile.title') }}</div>
            <div class="profile__logout">
                <app-base-btn
                    :size="'padding'"
                    :variation="'base'"
                    :text="$t('profile.logout')"
                    @action="openModalLogout"
                >
                    <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.7083 8.83333L16.2396 10.3021L17.8854 11.9583H9.375V14.0417H17.8854L16.2396 15.6875L17.7083 17.1667L21.875 13L17.7083 8.83333ZM5.20833 5.70833H12.5V3.625H5.20833C4.0625 3.625 3.125 4.5625 3.125 5.70833V20.2917C3.125 21.4375 4.0625 22.375 5.20833 22.375H12.5V20.2917H5.20833V5.70833Z" fill="#2B3037"/>
                    </svg>
                </app-base-btn>
            </div>
        </div>
        <div class="profile__content" ref="profile">
            <form @submit.prevent="doActionProfile" class="profile__details">
                <div class="profile__row profile-photo">
                    <div class="profile__column">
                        <div class="profile-photo__info">
                            <div class="profile-photo__title">
                                {{ $t('profile.photo.title') }}
                            </div>
                            <div class="profile-photo__details">
                                {{ $t('profile.photo.details') }}
                            </div>
                        </div>
                    </div>
                    <div class="profile__column">
                        <div class="profile-photo__control">
                            <div class="profile-photo__image">
                                <app-loader v-if="avatar.isLoaded || isLoaded"
                                    :size="'small'"
                                />
                                <div v-else>
                                    <img v-if="avatar.url" :src="avatar.url" alt="Avatar">
                                    <div v-else class="profile-photo__avatar-ph"
                                    >
                                        <div class="profile-photo__avatar-abbr">
                                            {{ profile.abbrNoAvatar }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="profile-photo__delete-btn">
                                <app-text-btn
                                    :text="$t('profile.photo.delete')"
                                    :is-not-main-color="true"
                                    @action="openModalConfirm('photo')"
                                />
                            </div>
                            <div class="profile-photo__edit-btn">
                                <app-text-btn
                                    :text="$t('profile.photo.edit')"
                                    @action="openModalEditAvatar"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="profile__column"></div>
                </div>
                <div class="profile__row profile-attributes">
                    <div class="profile__column">
                        <div class="profile-attributes__info">
                            <div class="profile-attributes__title">
                                {{ $t('profile.attributes.title') }}
                            </div>
                            <div class="profile-attributes__details">
                                {{ $t('profile.attributes.details') }}
                            </div>
                        </div>
                    </div>
                    <div class="profile__column">
                        <app-base-input
                            v-model="formattedLogin"
                            :label="formattedDate"
                            :is-disabled="true"
                        />
                    </div>
                    <div class="profile__column profile__column--is-start">
                        <div class="profile-attributes__control">
                            <div class="profile-photo__change-login-btn">
                                <app-text-btn
                                    :text="$t('profile.attributes.changeLogin')"
                                    @action="openModalChangeLogin"
                                />
                            </div>
                            <div class="profile-photo__change-pass-btn">
                                <app-text-btn
                                    :text="$t('profile.attributes.changePassword')"
                                    @action="openModalChangePassword"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="profile__row profile-contacts">
                    <div class="profile__column profile__column--is-self-center">
                        <div class="profile-contacts__info">
                            <div class="profile-contacts__title">
                                {{ $t('profile.contacts.title') }}
                            </div>
                            <div class="profile-contacts__details">
                                {{ $t('profile.contacts.details') }}
                            </div>
                        </div>
                    </div>
                    <div class="profile__column">
                        <app-base-input
                            v-model="profile.email"
                            :label="$t('profile.contacts.email.label')"
                            :place-holder="$t('profile.contacts.email.ph')"
                        />
                    </div>
                    <div class="profile__column">
                        <div class="profile-contacts__phone">
                            <app-base-input
                                v-model="profile.phone.number"
                                :label="$t('profile.contacts.phone.label')"
                                :place-holder="$t('profile.contacts.phone.ph')"
                                :error-message="errors.phone"
                            />
                            <div class="profile-contacts__phone-code">
                                <app-base-select
                                    :is-searchable="true"
                                    :selected-option="selectedCode"
                                    :options="optionsCodes"
                                    :type="'code'"
                                    @select="selectCode"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="profile__row profile-personal">
                    <div class="profile__column">
                        <div class="profile-personal__info">
                            <div class="profile-personal__title">
                                {{ $t('profile.personal.title') }}
                            </div>
                            <div class="profile-personal__details">
                                {{ $t('profile.personal.details') }}
                            </div>
                        </div>
                    </div>
                    <div class="profile__column">
                        <app-base-input
                            v-model="profile.name"
                            :label="$t('profile.personal.name.label')"
                            :place-holder="$t('profile.personal.name.ph')"
                        />
                    </div>
                    <div class="profile__column">
                        <app-base-input
                            v-model="profile.surname"
                            :label="$t('profile.personal.surname.label')"
                            :place-holder="$t('profile.personal.surname.ph')"
                        />
                    </div>
                </div>
                <div class="profile__row profile-office">
                    <div class="profile__column">
                        <div class="profile-office__info">
                            <div class="profile-office__title">
                                {{ $t('profile.office.title') }}
                            </div>
                            <div class="profile-office__details">
                                {{ $t('profile.office.details') }}
                            </div>
                        </div>
                    </div>
                    <div class="profile__column">
                        <app-base-input
                            v-model="profile.company"
                            :label="$t('profile.office.company.label')"
                            :place-holder="$t('profile.office.company.ph')"
                        />
                    </div>
                    <div class="profile__column">
                        <app-base-input
                            v-model="profile.position"
                            :label="$t('profile.office.post.label')"
                            :place-holder="$t('profile.office.post.ph')"
                        />
                    </div>
                </div>
                <div class="profile__save-btn">
                    <app-base-btn
                        :type="'submit'"
                        :variation="'save'"
                        :text="$t('common.buttons.save')"
                    />
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import { login, logout} from "../../services/checkAccess";
    import { formatEmail, formatPhone } from "../../helpers/symbols";
    import { getUniqueCodes } from "../../constants/countries";
    import { djinAPI } from "../../config/api";
    import { mapProfileToView, mapProfileToSend } from "../../api/mappers/profile";
    import { formatTimestampToDate } from "../../helpers/date";
    import { getImageFromUrl } from "../../api/requests/common";
    import {adminErrors, validatePhoneNumber, validateProfile} from "../../validations/admin";

    export default {
        name: "ProfileControl",

        data() {
            return {
                isLoaded: false,

                profile: {
                    id: '',
                    username: '',
                    name: '',
                    surname: '',
                    fullName: '',
                    time: 0,
                    email: '',
                    phone: {
                        code: '',
                        number: ''
                    },
                    company: '',
                    position: '',
                    abbrNoAvatar: '',
                    avatar: ''
                },

                avatar: {
                    url: '',
                    isLoaded: false
                },

                errors: {
                    phone: ''
                },

                selectedCode: null
            }
        },

        created() {
            this.subscribeToEvents();
            this.getMe();
        },

        unmounted() {
            this.unsubscribeFromEvents();
        },

        computed: {
            formattedLogin() {
                const login = this.profile.username;

                return login.includes('@') ? formatEmail(login) : formatPhone(login);
            },

            formattedDate() {
                return this.profile.time
                    ? `${this.createdDateLabel} ${formatTimestampToDate(this.profile.time)}`
                    : this.createdDateLabel;
            },

            createdDateLabel() {
                return this.$t('profile.attributes.created');
            },

            optionsCodes() {
                return getUniqueCodes();
            }
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('logout', this.logoutUser);
                this.emitter.on('deleteAvatar', this.deleteAvatar);
                this.emitter.on('updateMeAvatar', this.updateMeAvatar);
            },

            unsubscribeFromEvents() {
                this.emitter.off('logout', this.logoutUser);
                this.emitter.off('deleteAvatar', this.deleteAvatar);
                this.emitter.off('updateMeAvatar', this.updateMeAvatar);
            },

            getMe() {
                this.isLoaded = true;

                djinAPI.get('/admins/me')
                    .then((result) => {
                        const profile = result.data;
                        this.profile = mapProfileToView(profile);

                        if (this.profile.avatar) {
                            this.getProfileAvatar(this.profile.avatar);
                        } else {
                            this.avatar.url = '';
                        }

                        this.initSelectedCode();
                    })
                    .finally(() => {
                        this.isLoaded = false;
                    })
            },

            async getProfileAvatar(url) {
                this.avatar.isLoaded = true;

                await getImageFromUrl(url)
                    .then((image) => {
                        this.avatar.url = image;
                    })
                    .finally(() => {
                        this.avatar.isLoaded = false;
                    })
            },

            deleteAvatar() {
                djinAPI.delete(`/admins/${this.profile.id}/avatar`)
                    .finally(() => {
                        this.closeModal('modalConfirm');
                        this.getMe();
                        this.emitter.emit('updateMe');
                    })
            },

            updateMeAvatar(avatar) {
                const data = new FormData();
                data.append('avatar', avatar);

                djinAPI.post(`/admins/${this.profile.id}/avatar`, data, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                    .finally(() => {
                        this.closeModal('modalEditAvatar');
                        this.getMe();
                        this.emitter.emit('updateMe');
                    })
            },

            initSelectedCode() {
                this.selectedCode = this.optionsCodes.find((code) => code.name === this.profile.phone.code);
            },

            openModalConfirm() {
                const selectedElement = {
                    id: this.profile.id,
                    type: 'avatar'
                };

                this.openModal({
                    name: 'modalConfirm',
                    selectedEl: selectedElement
                });
            },

            openModalEditAvatar() {
                const selectedElement = {
                    url: this.profile.avatar
                }

                this.openModal({
                    name: 'modalEditAvatar',
                    selectedEl: selectedElement
                });
            },

            openModalChangeLogin() {
                this.openModal({
                    name: 'modalChangeLogin'
                });
            },

            openModalChangePassword() {
                this.openModal({
                    name: 'modalChangePassword'
                });
            },

            openModalLogout() {
                this.openModal({
                    name: 'modalLogout'
                });
            },

            async logoutUser() {
                await logout()
                    .then(isSuccess => {
                        if (isSuccess) {
                            this.$router.push({ name: 'Login' });
                        }
                    })
            },

            doActionProfile() {
                this.clearMeErrors();
                const { isValid, errors } = validateProfile(this.profile);
                this.errors = errors;

                if (isValid) {
                    const profile = mapProfileToSend(this.profile);

                    djinAPI.patch(`/admins/${this.profile.id}`, profile)
                        .then(() => {
                            this.getMe();
                            this.emitter.emit('updateMe');
                        })
                }
            },

            selectCode(code) {
                this.selectedCode = code;
                this.updateAdminPhoneCode(code.name);
            },

            updateAdminPhoneCode(code) {
                this.profile.phone.code = code;
            },

            clearMeErrors() {
                for (let key in this.errors) {
                    this.errors[key] = '';
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .profile {
        &__panel {
            border-bottom: 1px solid var(--app-border);
        }

        &__details {
            display: flex;
            flex-direction: column;
        }

        &__row {
            display: flex;
            align-items: center;
            padding: 30px 15px;
            border-bottom: 1px solid var(--app-border);

            /*&:not(:last-child) {*/
            /*    border-bottom: 1px solid var(--app-border);*/
            /*}*/
        }

        &__column {
            width: 33.333%;
            padding: 0px 15px;

            &--is-start {
                display: flex;
                align-self: flex-start;
            }

            &--is-self-center {
                align-self: center;
            }
        }

        &__save-btn {
            padding: 30px;
            display: flex;
            justify-content: flex-end;
        }
    }

    .profile-photo, .profile-attributes,
    .profile-contacts, .profile-personal,
    .profile-office {
        &__info {
            display: flex;
            flex-direction: column;
            min-width: 300px;
        }

        &__title {
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 15px;
        }

        &__details {
            font-size: 16px;
            line-height: 19px;
        }

        &__control {
            display: flex;
            align-items: flex-start;
        }

        &__image {
            margin-right: 30px;
            width: 74px;
            height: 74px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                max-width: 100%;
                object-fit: cover;
                border-radius: 50%;
            }
        }

        &__delete-btn {
            margin-right: 15px;
        }

        &__change-login-btn {
            margin-right: 30px;
        }

        &__avatar-ph {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            width: 74px;
            height: 74px;
            background: var(--brand-pink);
        }

        &__avatar-abbr {
            font-size: 24px;
            font-weight: 500;
            color: var(--system-white);
        }
    }

    .profile-contacts {
        align-items: flex-start;

        &__phone {
            position: relative;
        }

        &__phone-code {
            position: absolute;
            top: 30px;
            left: 4px;
        }
    }
</style>

<style lang="scss">
    .profile-contacts__phone {
        .field__entry {
            input {
                padding-left: 110px !important;
            }
        }
    }
</style>