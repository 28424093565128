<template>
    <div class="user-form" :class="{ 'user-form--is-premise-user': isPremiseUser }">
        <h4 v-if="isPremiseUser">{{ title }}</h4>
        <div v-if="isNew" class="new-user">
            <div class="new-user__info">
                <div class="new-user__row">
                    <div class="new-user__details">
                        <app-base-input
                            v-model="user.surname"
                            :label="$t('users.user.surname.label')"
                            :place-holder="$t('users.user.surname.ph')"
                            :required="false"
                            :is-one-of="true"
                            :error-message="errors.surname"
                        />
                    </div>
                    <div class="new-user__details">
                        <div class="new-user__phone">
                            <app-base-input
                                v-model="user.phone.number"
                                :label="$t('users.user.phone.label')"
                                :place-holder="$t('users.user.phone.ph')"
                                :required="false"
                                :is-one-of="true"
                                :error-message="errors.phone"
                            />
                            <div class="new-user__phone-code">
                                <app-base-select
                                    :is-searchable="true"
                                    :selected-option="selectedCode"
                                    :options="optionsCodes"
                                    :type="'code'"
                                    @select="selectCode"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="new-user__row">
                    <div class="new-user__details">
                        <app-base-input
                            v-model="user.name"
                            :label="$t('users.user.name.label')"
                            :place-holder="$t('users.user.name.ph')"
                            :required="false"
                            :is-one-of="true"
                            :error-message="errors.name"
                        />
                    </div>
                    <div class="new-user__details">
                        <app-base-input
                            v-model="user.email"
                            :label="$t('users.user.email.label')"
                            :place-holder="$t('users.user.email.ph')"
                            :required="false"
                            :is-one-of="true"
                            :error-message="errors.email"
                        />
                    </div>
                </div>
                <div class="new-user__row">
                    <div class="new-user__details">
                        <app-base-input
                            v-model="user.middleName"
                            :label="$t('users.user.middleName.label')"
                            :place-holder="$t('users.user.middleName.ph')"
                            :required="false"
                            :is-one-of="true"
                        />
                    </div>
                    <div class="new-user__details">
                        <app-base-input
                            v-model="user.documentId"
                            :label="$t('users.user.numberId.label')"
                            :place-holder="$t('users.user.numberId.ph')"
                            :required="false"
                        />
                    </div>
                </div>
            </div>
            <div v-if="isPremiseUser" class="new-user__properties">
                <h5>{{ $t('users.user.properties.title') }}</h5>
                <div class="new-user__is-owner">
                    <app-base-checkbox
                        v-model="user.isOwner"
                        :value="'isOwner'"
                        :label="$t('users.user.properties.isOwner')"
                    />
                </div>
                <div class="new-user__is-registered">
                    <app-base-checkbox
                        v-model="user.isRegistered"
                        :value="'isRegistered'"
                        :label="$t('users.user.properties.isRegistered')"
                    />
                </div>
            </div>
        </div>
        <div v-else class="exist-user">
            <div class="exist-user__info">
                <div class="exist-user__row">
                    <div class="exist-user__details">
                        <app-base-select
                            :selected-option="selectedBuilding"
                            :options="buildings"
                            :label="$t('users.user.building.label')"
                            :place-holder="$t('users.user.building.ph')"
                            :type="'text'"
                            @select="selectBuilding"
                        />
                    </div>
                    <div class="exist-user__details">
                        <app-base-select
                            :selected-option="selectedPremise"
                            :options="premises"
                            :label="$t('users.user.premise.label')"
                            :place-holder="$t('users.user.premise.ph')"
                            :type="'text'"
                            :is-clear="true"
                            :is-disabled="isLoaded"
                            @select="selectPremise"
                        />
                    </div>
                </div>
                <div class="exist-user__row">
                    <div class="exist-user__details">
                        <app-base-select
                            :selected-option="user.selectedUser"
                            :options="users"
                            :label="$t('users.user.user.label')"
                            :place-holder="$t('users.user.user.ph')"
                            :type="'text'"
                            :is-clear="true"
                            :is-user="true"
                            :is-disabled="isLoadedUsers"
                            :error-message="errors.selectedUser"
                            @select="selectUser"
                        />
                    </div>
                    <div class="exist-user__details"></div>
                </div>
            </div>
            <div class="exist-user__properties"
                 :class="{
                    'exist-user__properties--is-one': isOneExistUser
                }"
            >
                <h5>{{ $t('users.user.properties.title') }}</h5>
                <div class="exist-user__is-owner">
                    <app-base-checkbox
                        v-model="user.isOwner"
                        :label="$t('users.user.properties.isOwner')"
                    />
                </div>
                <div class="exist-user__is-registered">
                    <app-base-checkbox
                        v-model="user.isRegistered"
                        :label="$t('users.user.properties.isRegistered')"
                    />
                </div>
            </div>
        </div>
        <div v-if="isPremiseUser" class="user-form__delete-btn">
            <app-circle-btn
                @action="deleteUser"
            >
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.875 4.00625L10.9938 3.125L7.5 6.61875L4.00625 3.125L3.125 4.00625L6.61875 7.5L3.125 10.9938L4.00625 11.875L7.5 8.38125L10.9938 11.875L11.875 10.9938L8.38125 7.5L11.875 4.00625Z" fill="#2B3037"/>
                </svg>
            </app-circle-btn>
        </div>
    </div>
</template>

<script>
    import { getUniqueCodes } from "../../constants/countries";
    import { validateUser } from "../../validations/user";
    import { mapGetters } from "vuex";
    import { djinAPI } from "../../config/api";
    import { mapUsersToView, mapUsersToSelect } from "../../api/mappers/users";
    import { mapPremisesToView } from "../../api/mappers/premises";

    export default {
        name: "UserForm",

        props: {
            index: {
                type: Number,
                default: 0
            },
            length: {
                type: Number,
                default: 0
            },
            isPremiseUser: {
                type: Boolean,
                default: false
            },
            id: {
                type: [Number, String]
            },
            isNew: {
                type: Boolean,
                required: true
            },
            surname: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            middleName: {
                type: String,
                required: true
            },
            phone: {
                type: Object,
                required: true
            },
            email: {
                type: String,
                required: true
            },
            documentId: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                isLoaded: false,
                isLoadedUsers: false,
                user: {
                    id: '',
                    name: '',
                    surname: '',
                    middleName: '',
                    isNew: false,
                    phone: {
                        code: '+380',
                        number: ''
                    },
                    email: '',
                    documentId: '',
                    isOwner: true,
                    isRegistered: true,
                    selectedUser: null,
                },

                selectedCode: null,
                selectedBuilding: null,
                selectedPremise: null,
                premises: [],
                users: [],

                errors: {
                    name: '',
                    surname: '',
                    email: '',
                    phone: '',
                    selectedUser: ''
                }
            }
        },

        created() {
            this.mapUser();
        },

        mounted() {
            this.subscribeToEvents();
        },

        unmounted() {
            this.unSubscribeFromEvents();
        },

        computed: {
            ...mapGetters({
                buildings: 'buildings/mappedBuildings'
            }),

            title() {
                return this.isNew
                    ? this.$t('users.user.title.create')
                    : this.$t('users.user.title.exist');
            },

            optionsCodes() {
                return getUniqueCodes();
            },

            isOneExistUser() {
                return !this.index && this.length === 1 && !this.isNew;
            }
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('validateUser', this.validateAndUpdateUser);
            },

            unSubscribeFromEvents() {
                this.emitter.off('validateUser', this.validateAndUpdateUser);
            },

            mapUser() {
                if (this.isNew) {
                    this.user.id = this.id;
                    this.user.surname = this.surname;
                    this.user.name = this.name;
                    this.user.middleName = this.middleName;
                    this.user.phone = { ...this.phone };
                    this.user.email = this.email;
                    this.user.documentId = this.documentId;
                    this.selectedCode = this.optionsCodes[0];
                }
            },

            selectCode(code) {
                this.selectedCode = code;
                this.user.phone.code = code.name;
            },

            selectBuilding(building) {
                this.selectedBuilding = building;
                const buildingId = this.selectedBuilding.id;
                this.getPremises(buildingId);
                this.getUsers(buildingId);
            },

            getPremises(buildingId) {
                this.isLoaded = true;

                const params = {
                    params: {
                        filter: `building:${buildingId}`
                    }
                }

                djinAPI.get(`/premises`, params)
                    .then((result) => {
                        this.premises = mapPremisesToView(result.data);
                    })
                    .finally(() => {
                        this.isLoaded = false;
                    })
            },

            getUsers(buildingId) {
                this.isLoadedUsers = true;

                const params = {
                    params: {
                        filter: `building:${buildingId}`
                    }
                }

                djinAPI.get(`/users`, params)
                    .then((result) => {
                        const users = mapUsersToView(result.data);
                        this.users = mapUsersToSelect(users);
                    })
                    .finally(() => {
                        this.isLoadedUsers = false;
                    })
            },

            getSpecificUsers(premiseId) {
                this.isLoadedUsers = true;

                djinAPI.get(`/premises/${premiseId}`)
                    .then((result) => {
                        const users = mapUsersToView(result.data.users);
                        this.users = mapUsersToSelect(users);
                    })
                    .finally(() => {
                        this.isLoadedUsers = false;
                    })
            },

            selectPremise(premise) {
                this.selectedPremise = premise;

                if (this.selectedPremise) {
                    this.getSpecificUsers(this.selectedPremise.id);
                } else {
                    this.getUsers(this.selectedBuilding.id);
                }
            },

            selectUser(user) {
                this.user.selectedUser = user;
            },

            deleteUser() {
                this.$emit('delete', this.id);
            },

            validateAndUpdateUser() {
                this.clearUserErrors();
                const { isValid, errors} = validateUser(this.user, this.isNew, this.user.selectedUser);

                this.errors = errors;
                this.$emit('update', this.id, isValid, this.user, this.isNew);
            },

            clearUserErrors() {
                for (let key in this.errors) {
                    this.errors[key] = '';
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .user-form {
        position: relative;

        h4 {
            margin-bottom: 30px;
        }

        &__delete-btn {
            position: absolute;
            top: 0;
            right: 0;
        }

        &--is-premise-user {
            margin-top: 45px;
        }
    }

    .new-user, .exist-user {
        &__info {
            display: flex;
            flex-direction: column;
            padding-bottom: 30px;
            border-bottom: 1px solid var(--app-border);
        }

        &__properties {
            padding: 30px 0;
            border-bottom: 1px solid var(--app-border);

            h5 {
                margin-bottom: 30px;
            }

            &--is-one {
                border-bottom: none;
            }
        }

        &__is-owner {
            margin-bottom: 15px;
        }

        &__row {
            display: flex;

            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }

        &__details {
            flex-basis: 50%;

            &:first-child {
                margin-right: 30px;
            }
        }

        &__fullName, &__email, &__building {
            margin-bottom: 15px;
        }

        &__phone {
            position: relative;
        }

        &__phone-code {
            position: absolute;
            top: 30px;
            left: 4px;
        }
    }
</style>

<style lang="scss">
    .new-user__phone {
        .field__entry {
            input {
                padding-left: 110px !important;
            }
        }
    }
</style>