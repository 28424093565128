<template>
    <div class="login">
        <div v-if="!showChangePassword" class="login__form">
            <form @submit.prevent="loginUser" class="access">
                <h3>{{ $t('login.title') }}</h3>
                <div class="access__login">
                    <app-base-input
                        v-model="access.login"
                        :label="$t('login.email.label')"
                        :place-holder="$t('login.email.ph')"
                        :error-message="errors.login"
                    />
                </div>
                <div class="access__password">
                    <app-base-input
                        v-model="access.password"
                        :type="'password'"
                        :label="$t('login.password.label')"
                        :place-holder="$t('login.password.ph')"
                        :error-message="errors.password"
                    />
                </div>
                <div class="access__forgot">
                    <app-text-btn
                        :text="$t('common.buttons.forgot')"
                        @action="openModalRecovery"
                    />
                </div>
                <div class="access__submit">
                    <app-base-btn
                        :type="'submit'"
                        :size="'full'"
                        :variation="'save'"
                        :text="$t('login.btn')"
                    />
                </div>
            </form>
        </div>
        <div v-else class="login__form">
            <form @submit.prevent="confirmUserPassword" class="new-password">
                <h3>{{ $t('newPassword.title') }}</h3>
                <div class="new-password__value">
                    <app-base-input
                        v-model="newPassword.value"
                        :type="'password'"
                        :label="$t('newPassword.value.label')"
                        :error-message="errors.newPasswordValue"
                    />
                </div>
                <div class="new-password__confirm">
                    <app-base-input
                        v-model="newPassword.confirm"
                        :type="'password'"
                        :label="$t('newPassword.confirm.label')"
                        :error-message="errors.newPasswordConfirm"
                    />
                </div>
                <div class="new-password__control">
                    <app-base-btn
                        :size="'full'"
                        :variation="'cancel'"
                        :text="$t('common.buttons.cancel')"
                    />
                    <app-base-btn
                        :type="'submit'"
                        :size="'full'"
                        :variation="'save'"
                        :text="$t('newPassword.btn')"
                    />
                </div>
            </form>
        </div>
        <div class="login__logo">
            <admin-logo
                :logo-url="'icons/logo.svg'"
            />
        </div>
        <modal-recovery-password
            v-if="showModalRecoveryPassword"
        />
    </div>
</template>

<script>
    import AdminLogo from "../components/logo/AdminLogo";
    import ModalRecoveryPassword from "../components/modals/auth/ModalRecoveryPassword";
    import { validateLoginData, loginErrors } from "../validations/login";
    import { validatePasswordData, passwordErrors } from "../validations/password";
    import { login, completeNewPassword } from "../services/checkAccess";
    import { authToastsMixin } from "../mixins/toasts/auth";
    import { mapGetters } from "vuex";

    export default {
        name: "UserLogin",

        components: {
            ModalRecoveryPassword,
            AdminLogo
        },

        mixins: [authToastsMixin],

        data() {
            return {
                access: {
                    login: '',
                    password: ''
                },
                newPassword: {
                    value: '',
                    confirm: ''
                },

                errors: {
                    login: '',
                    password: '',
                    newPasswordValue: '',
                    newPasswordConfirm: '',
                },

                showChangePassword: false
            }
        },

        computed: {
            ...mapGetters({
                showModalRecoveryPassword: 'modals/showModalRecoveryPassword',
            }),
        },

        methods: {
            openModalRecovery() {
                this.openModal({
                    name: 'modalRecoveryPassword'
                });
            },

            async loginUser() {
                this.clearErrorsLogin();
                const isValid = validateLoginData(this.access.login);

                if (isValid) {
                    await login(this.access)
                        .then(userSession => {
                            if (userSession) {
                                if (userSession.challengeName === 'NEW_PASSWORD_REQUIRED') {
                                    this.showChangePassword = true;
                                    this.session = userSession;
                                } else {
                                    this.$router.push({path: '/buildings'});
                                }
                            } else {
                                this.setLoginErrors('access');
                            }
                        });
                } else {
                    this.setLoginErrors('validation');
                }
            },

            async confirmUserPassword() {
                this.clearErrorsNewPassword();
                const isValid = this.validateNewPasswordData(this.newPassword.value, this.newPassword.confirm);

                if (isValid) {
                    await completeNewPassword(this.session, this.newPassword.value)
                        .then(userSession => {
                            if (userSession) {
                                this.showCompleteSignUpToast();
                                this.$router.push({path: '/buildings'});
                            }
                        })
                }
            },

            validateNewPasswordData(value, confirm) {
                let isValid = false;

                if (value === confirm) {
                    const isValidByRules = validatePasswordData(value);

                    if (isValidByRules) {
                        isValid = true;
                    } else {
                        this.setNewPasswordErrors('validation');
                    }
                } else {
                    this.setNewPasswordErrors('identity');
                }

                return isValid;
            },

            setLoginErrors(type) {
                this.errors.login = loginErrors[type];
                this.errors.password = 'isNotShowError';
            },

            clearErrorsLogin() {
                this.errors.login = '';
                this.errors.password = '';
            },

            setNewPasswordErrors(type) {
                this.errors.newPasswordValue = passwordErrors[type];
                this.errors.newPasswordConfirm = 'isNotShowError';
            },

            clearErrorsNewPassword() {
                this.errors.newPasswordValue = '';
                this.errors.newPasswordConfirm = '';
            }
        }
    }
</script>

<style lang="scss" scoped>
    .login {
        width: 100%;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        background-image: url("../assets/login_background.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-attachment: fixed;
        background-size: cover;

        &__form {
            width: 100%;
            max-width: 574px;
            padding: 30px;
            background: var(--system-white);
            box-shadow: 0 4px 10px rgba(45, 40, 243, 0.1);
            color: var(--app-base-primary);
        }

        &__logo {
            position: absolute;
            top: 22px;
            left: 30px;
        }
    }

    .access {
        h3 {
            margin-bottom: 30px;
        }

        &__login {
            margin-bottom: 15px;
        }

        &__password {
            margin-bottom: 10px;
        }

        &__forgot {
            margin-bottom: 45px;
        }
    }

    .new-password {
        h3 {
            margin-bottom: 30px;
        }

        &__value {
            margin-bottom: 15px;
        }

        &__confirm {
            margin-bottom: 45px;
        }

        &__control {
            display: flex;

            button:first-child {
                margin-right: 30px;
            }
        }
    }
</style>