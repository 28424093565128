<template>
    <div class="modal" ref="modalWrapper" @mousedown="handlerClick">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'AppModalWrapper',

        methods: {
            handlerClick(event) {
                if (event.target === this.$refs.modalWrapper) {
                    this.closeOneModal();
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal {
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--app-modal-wrapper);
    }
</style>
