<template>
    <div class="admins">
        <div class="admins__panel">
            <div class="admins__title">{{ $t('admins.title') }}</div>
            <div class="admins__actions">
                <div class="admins__transfer-btn">
                    <app-base-btn
                        :size="'padding'"
                        :variation="'base'"
                        :text="$t('admins.transferBtn')"
                        :is-disabled="!isMaster"
                        @action="openModalAdminTransfer"
                    />
                </div>
                <app-add-btn
                    @open-modal="openModalAdmin"
                />
            </div>
        </div>
        <div class="admins__control">
            <div class="admins__filters">
                <admins-filters
                    :qty="admins.length"
                    @update="updateQueryParams"
                />
            </div>
        </div>
        <div class="admins__content" ref="admins">
            <div v-if="showTable" class="admins__table">
                <admins-table
                    :admins="admins"
                    :max-height="maxHeightScroll"
                    :search-sub-string="searchSubString"
                    @update="updateAdmin"
                    @delete="openModalConfirm"
                    @update-avatar="updateAdminAvatar"
                />
            </div>
            <div v-if="showEmpty" class="admins__empty">
                <app-empty-content
                    :content-type="'admins'"
                    @open-modal="openModalAdmin"
                />
            </div>
            <div v-if="showNoResults" class="admins__empty">
                <app-no-results
                    @clear="clearSearch"
                />
            </div>
            <div v-if="isLoaded" class="admins__loader">
                <app-loader
                    :size="'large'"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import AdminsTable from "./AdminsTable";
    import { maxHeightMixin } from "../../mixins/calcMaxHeight";
    import { adminToastsMixin } from "../../mixins/toasts/admins";
    import { djinAPI } from "../../config/api";
    import { mapAdminsToView } from "../../api/mappers/admins";
    import { adminsElements } from "../../constants/sorting";
    import AdminsFilters from "./AdminsFilters";

    export default {
        name: "AdminsControl",

        components: {
            AdminsFilters,
            AdminsTable
        },

        mixins: [maxHeightMixin, adminToastsMixin],

        data() {
            return {
                isMaster: false,
                isLoaded: false,
                admins: [],
                params: null,
            }
        },

        created() {
            this.checkMeIsMaster();
            this.getAdmins();
        },

        mounted() {
            this.subscribeToResize('admins');
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromResize();
            this.unsubscribeFromEvents();
        },

        computed: {
            elements() {
                return adminsElements;
            },

            showTable() {
                return this.admins.length && !this.isLoaded;
            },

            showEmpty() {
                return !this.admins.length && !this.isLoaded && !this.searchSubString;
            },

            showNoResults() {
                return !this.admins.length && !this.isLoaded && this.searchSubString;
            },

            searchSubString() {
                return this.params
                    ? this.params.params
                    ? this.params.params.match
                    : ''
                    : '';
            }
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('deleteAdmin', this.deleteAdmin);
                this.emitter.on('updateMe', this.updateIsMaster);
                this.emitter.on('updateAdmins', this.getAdmins);
            },

            unsubscribeFromEvents() {
                this.emitter.off('deleteAdmin', this.deleteAdmin);
                this.emitter.off('updateMe', this.updateIsMaster);
                this.emitter.off('updateAdmins', this.getAdmins);
            },

            checkMeIsMaster() {
                djinAPI.get('/admins/me')
                    .then((result) => {
                        const admin = result.data;
                        this.isMaster = admin.isMaster;
                    })
            },

            getAdmins() {
                this.isLoaded = true;

                djinAPI.get('/admins', this.params)
                    .then((result) => {
                        const admins = result.data;
                        this.admins = mapAdminsToView(admins);
                        this.emitter.emit('updateShowQty');
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.isLoaded = false;
                        }, 500)
                    })
            },

            deleteAdmin(id) {
                const admin = this.admins.find((admin) => admin.id === id);

                djinAPI.delete(`/admins/${id}`)
                    .then(() => {
                        this.closeModalConfirm();
                        this.showDeleteToast(admin.username);
                        this.getAdmins();
                    })
            },

            updateAdmin(id) {
                const admin = this.admins.find((admin) => admin.id === id);

                this.openModal({
                    name: 'modalAdmin',
                    selectedEl: admin
                });
            },

            updateAdminAvatar(id, url) {
                const admin = this.admins.find((admin) => admin.id === id);

                if (admin) {
                    admin.avatar = url;
                }
            },

            updateIsMaster(username) {
                this.showAdminTransferToast(username);
                this.checkMeIsMaster();
            },

            updateQueryParams(params) {
                this.params = { ...params };
                this.getAdmins();
            },

            clearSearch() {
                this.emitter.emit('clearSearch');
            },

            openModalAdmin() {
                this.openModal({
                    name: 'modalAdmin'
                });
            },

            openModalAdminTransfer() {
                this.openModal({
                    name: 'modalAdminTransfer'
                });
            },

            openModalConfirm(id) {
                const admin = this.admins.find((admin) => admin.id === id);

                const selectedElement = {
                    id: admin.id,
                    name: admin.username,
                    type: 'admin'
                };

                this.openModal({
                    name: 'modalConfirm',
                    selectedEl: selectedElement
                })
            },

            closeModalConfirm() {
                this.closeModal('modalConfirm');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .admins {
        &__actions {
            display: flex;
        }

        &__transfer-btn {
            margin-right: 30px;
        }
    }
</style>