<template>
    <div class="finance-main-premise">
        <div class="table__row finance-main-table__row finance-main-table__premise">
            <div class="table__column finance-main-table__name finance-main-table__name--is-premise" :class="{ 'finance-main-table__name--is-free-premise': isFreeBuilding }">
                <app-overflow-match-wrapper
                    :text="name"
                    :sub-text="subString"
                    :is-match="true"
                />
            </div>
            <div class="table__column finance-main-table__document-id"></div>
            <div class="table__column finance-main-table__total">
                <finance-total-item
                    :balance="balance"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import FinanceTotalItem from "./FinanceTotalItem";

    export default {
        name: "FinanceMainPremiseRow",

        components: {
            FinanceTotalItem
        },

        props: {
            isFreeBuilding: {
                type: Boolean,
                required: true
            },
            id: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            balance: {
                type: Number,
                required: true
            },
            subString: {
                type: String,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>
    .finance-main-premise {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__users {
            display: flex;
            flex-direction: column;
        }
    }
</style>