<template>
    <div class="view-template">
        <div class="view-template__navigation">
            <div class="view-template__panel">
                <div class="view-template__logo">
                    <admin-logo
                        :logo-url="logo"
                    />
                </div>
                <div class="view-template__language">
                    <admin-select-lang/>
                </div>
            </div>
            <div class="view-template__menu">
                <div v-for="item in items" :key="item.id">
                    <div class="view-template__item">
                        <admin-menu-item
                            :in-active-color="'var(--app-base-secondary)'"
                            :active-color="'var(--brand-main)'"
                            :icon="item.link"
                            :name="item.name"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="view-template__manage">
            <div class="view-template__content">

            </div>
        </div>
    </div>
</template>

<script>
    import AdminMenuItem from "../common/local/AdminMenuItem";
    import AdminLogo from "../logo/AdminLogo";
    import AdminSelectLang from "../common/local/AdminSelectLang";

    export default {
        name: "ViewSettingsTemplate",

        components: {
            AdminMenuItem,
            AdminLogo,
            AdminSelectLang
        },

        props: {
            logo: {
                type: String,
                required: true
            },
            items: {
                type: Array,
                required: true
            }
        },

        data() {
            return {
                image: '/admins1.png'
            }
        }
    }
</script>

<style lang="scss" scoped>
    .view-template {
        height: 754px;
        border: 1px solid var(--app-border);
        border-radius: 13px;
        display: flex;
        width: 100%;
        pointer-events: none;
        background: var(--app-menu-background);

        &__navigation {
            width: 260px;
            display: flex;
            flex-direction: column;
        }

        &__manage {
            display: flex;
            flex-direction: column;

            width: calc(100% - 260px);
        }

        &__content {
            background: var(--system-white);
            flex-grow: 1;
            border-radius: 0px 13px 13px 0px;

            background-image: url("../../assets/admins1.png");
            background-repeat: no-repeat;
            background-position: center left;
            background-size: cover;
        }

        &__menu {
            flex-grow: 1;
            padding: 30px;
        }

        &__panel {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 80px;
            padding: 0px 15px 0px 30px;
            border-bottom: 1px solid var(--app-border);
        }

        &__item {
            display: flex;
            margin-bottom: 15px;
        }
    }
</style>