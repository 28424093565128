<template>
    <app-modal-wrapper>
        <div class="modal-determine-payer">
            <form @submit.prevent="doActionDeterminePayer" class="determine-payer-form">
                <div class="determine-payer-form__info determine-payer-form__info--is-head">
                    <div class="determine-payer-form__row">
                        <div class="determine-payer-form__details">
                            <h4>{{ $t('finance.review.payer.title') }}</h4>
                        </div>
                        <div class="determine-payer-form__details">
                            <div class="determine-payer-form__row">
                                <div class="determine-payer-form__details">
                                    <div class="app__label">
                                        {{ $t('finance.review.client') }}
                                    </div>
                                    <div class="determine-payer-form__user-name">
                                        {{ invoice.bankName }}
                                    </div>
                                    <div>{{ invoice.accountNumber }}</div>
                                </div>
                                <div class="determine-payer-form__details">
                                    <div class="app__label">
                                        {{ $t('finance.review.target') }}
                                    </div>
                                    <div>{{ invoice.details }}</div>
                                </div>
                            </div>
                            <div class="determine-payer-form__row">
                                <div class="determine-payer-form__details">
                                    <div class="app__label">
                                        {{ $t('finance.review.date') }}
                                    </div>
                                    <div>{{ formattedDateTime }}</div>
                                </div>
                                <div class="determine-payer-form__details">
                                    <div class="app__label">
                                        {{ $t('finance.review.amount') }}
                                    </div>
                                    <div>{{ formattedAmount }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="determine-payer-form__info determine-payer-form__selects">
                    <div class="determine-payer-form__row">
                        <div class="determine-payer-form__details">
                            <app-base-select
                                :label="$t('finance.review.payer.building.label')"
                                :place-holder="$t('finance.review.payer.building.ph')"
                                :is-clear="true"
                                :is-searchable="true"
                                :selected-option="selectedBuilding"
                                :options="buildings"
                                :type="'text'"
                                @select="selectBuilding"
                            />
                        </div>
                        <div class="determine-payer-form__details">
                            <app-base-select ref="premise"
                                :label="$t('finance.review.payer.premise.label')"
                                :place-holder="$t('finance.review.payer.premise.ph')"
                                :is-clear="true"
                                :is-searchable="true"
                                :selected-option="selectedPremise"
                                :options="premises"
                                :type="'text'"
                                @select="selectPremise"
                            />
                        </div>
                    </div>
                    <div class="determine-payer-form__row">
                        <div class="determine-payer-form__details">
                            <app-base-select ref="user"
                                :label="$t('finance.review.payer.client.label')"
                                :place-holder="$t('finance.review.payer.client.ph')"
                                :is-clear="true"
                                :is-searchable="true"
                                :selected-option="selectedUser"
                                :options="filteredUsers"
                                :type="'text'"
                                :error-message="errors.user"
                                @select="selectUser"
                            />
                        </div>
                        <div class="determine-payer-form__details">
                            <app-base-select ref="invoice"
                                :label="$t('finance.review.payer.invoice.label')"
                                :place-holder="$t('finance.review.payer.invoice.ph')"
                                :is-clear="true"
                                :is-searchable="true"
                                :selected-option="selectedReviewInvoice"
                                :options="invoices"
                                :type="'text'"
                                @select="selectInvoice"
                            />
                        </div>
                    </div>
                </div>
                <div v-if="selectedReviewInvoice" class="determine-payer-form__properties">
                    <div class="determine-payer-form__details">
                        <h5>{{ $t('finance.review.title.payment') }}</h5>
                    </div>
                    <div class="determine-payer-form__details">
                        <div class="determine-payer-form__row">
                            <div class="determine-payer-form__details">
                                <div class="app__label">
                                    {{ $t('finance.review.number') }}
                                </div>
                                <div>{{ selectedReviewInvoice.number }}</div>
                            </div>
                            <div class="determine-payer-form__details">
                                <div class="app__label">
                                    {{ $t('finance.review.invoice') }}
                                </div>
                                <div>{{ formattedInvoiceTotal }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="invoicePremises.length" class="determine-payer-form__premises">
                    <finance-review-premise-row v-for="(premise,index) in invoicePremises" :key="premise.id"
                        :index="index"
                        :id="premise.id"
                        :name="premise.name"
                        :balance="premise.balance"
                        @update="updatePremise"
                    />
                </div>
                <div class="determine-payer-form__control">
                    <app-base-btn
                        :size="'fixed'"
                        :variation="'cancel'"
                        :text="$t('common.buttons.cancel')"
                        @action="closeModalDeterminePayer"
                    />
                    <app-base-btn
                        :type="'submit'"
                        :size="'fixed'"
                        :variation="'save'"
                        :text="$t('common.buttons.pay')"
                    />
                </div>
            </form>
        </div>
    </app-modal-wrapper>
</template>

<script>
    import FinanceReviewPremiseRow from "../../finance/review/FinanceReviewPremiseRow";
    import { formatCurrencyValue } from "../../../helpers/finance";
    import { clearDependentSelects } from "../../../helpers/selects";
    import { formatTimestampToDateTime } from "../../../helpers/date";
    import { mapPremisesToView } from "../../../api/mappers/premises";
    import { mapUsersToView, mapUsersToSelect } from "../../../api/mappers/users";
    import { mapReviewUserPremisesToView } from "../../../api/mappers/finance/review";
    import { validateReviewUser } from "../../../validations/finance/invoices";
    import { mapReviewInvoiceToSend } from "../../../api/mappers/finance/balance";
    import { mapGetters } from "vuex";
    import { djinAPI } from "../../../config/api";

    export default {
        name: "ModalDeterminePayer",

        components: {
            FinanceReviewPremiseRow
        },

        props: {
            selectedInvoice: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                isLoadedUsers: false,
                isLoadedPremises: false,
                invoice: {
                    id: '',
                    date: '',
                    bankName: '',
                    accountNumber: '',
                    details: '',
                    amount: 0,
                    state: 0
                },
                users: [],
                premises: [],
                premiseUsers: [],
                invoices: [],
                invoicePremises: [],
                selectedBuilding: null,
                selectedPremise: null,
                selectedUser: null,
                selectedReviewInvoice: null,

                errors: {
                    user: ''
                }
            }
        },

        created() {
            this.init();
        },

        computed: {
            ...mapGetters({
                buildings: 'buildings/mappedBuildings',
            }),

            formattedAmount() {
                const { balance, currency } = formatCurrencyValue(this.invoice.amount);

                return `${balance} ${currency}`;
            },

            formattedInvoiceTotal() {
                const { balance, currency } = formatCurrencyValue(this.selectedReviewInvoice.total);

                return `${balance} ${currency}`;
            },

            formattedDateTime() {
                return formatTimestampToDateTime(this.invoice.date);
            },

            filteredUsers() {
                return !!this.premiseUsers.length
                    ? this.users.filter((user) => this.premiseUsers.map((premiseUser) => premiseUser.id).includes(user.id))
                    : this.selectedPremise ? [] : this.users;
            }
        },

        methods: {
            init() {
                this.invoice = { ...this.selectedInvoice };
            },

            doActionDeterminePayer() {
                const { isValid, errors } = validateReviewUser(this.selectedUser);
                const isValidUser = isValid;
                this.errors = errors;

                if (isValidUser) {
                    this.emitter.emit('validateReviewPosition');
                }

                const isValidPremises = this.invoicePremises.every((premise) => premise.isValid);

                if (isValidUser && isValidPremises) {
                    this.updateReviewInvoice();
                }
            },

            updateReviewInvoice() {
                const invoice = {
                    id: this.invoice.id,
                    userId: this.selectedUser.id,
                    invoiceNumber: this.selectedReviewInvoice ? this.selectedReviewInvoice.id : '',
                    payments: this.invoicePremises.filter((premise) => premise.amount)
                }

                const mappedInvoice = mapReviewInvoiceToSend(invoice);

                console.log(mappedInvoice);

                djinAPI.post('/finance/review', mappedInvoice)
                    .then((result) => {
                        this.updateReviewInvoices();
                    })
            },

            updateReviewInvoices() {
                this.closeModalDeterminePayer()
                this.emitter.emit('updateReviewInvoices');
            },

            updatePremise(id, isValid, amount) {
                const premise = this.invoicePremises.find((premise) => premise.id === id);
                premise.isValid = isValid;

                if (isValid) {
                    premise.amount = amount;
                }
            },

            closeModalDeterminePayer() {
                this.closeModal('modalDeterminePayer');
            },

            selectBuilding(building) {
                this.selectedBuilding = building;

                clearDependentSelects(
                    {
                        user: this.$refs.user,
                        premise: this.$refs.premise,
                        invoice: this.$refs.invoice
                    }
                );

                this.users = [];
                this.premises = [];
                this.invoices = [];
                this.premiseUsers = [];
                this.invoicePremises = [];


                if (this.selectedBuilding) {
                    const buildingId = this.selectedBuilding.id;

                    this.getBuildingUsers(buildingId);
                    this.getBuildingPremises(buildingId);
                }
            },

            getBuildingUsers(id) {
                this.isLoadedUsers = true;

                const params = {
                    params: {
                        filter: `building:${id}`
                    }
                };

                djinAPI.get(`/users`, params)
                    .then((result) => {
                        const users = mapUsersToView(result.data);
                        this.users = mapUsersToSelect(users);
                    })
                    .finally(() => {
                        this.isLoadedUsers = false;
                    })
            },

            getBuildingPremises(id) {
                this.isLoadedPremises = true;

                const params = {
                    params: {
                        filter: `building:${id}`
                    }
                };

                djinAPI.get('/premises', params)
                    .then((result) => {
                        const premises = result.data;
                        this.premises = mapPremisesToView(premises);
                    })
                    .finally(() => {
                        this.isLoadedPremises = false;
                    })
            },

            getPremiseUsers(id) {
                this.isLoadedUsers = true;

                djinAPI.get(`/premises/${id}`)
                    .then((result) => {
                        const users = mapUsersToView(result.data.users);
                        this.premiseUsers = mapUsersToSelect(users);
                    })
                    .finally(() => {
                        this.isLoadedUsers = false;
                    })
            },

            selectUser(user) {
                this.selectedUser = user;

                clearDependentSelects(
                    {
                        invoice: this.$refs.invoice
                    }
                );

                this.invoices = [];
                this.invoicePremises = [];

                if (this.selectedUser) {
                    this.getInvoicePremises(this.selectedUser.id);
                } else {
                    this.invoicePremises = [];
                }
            },

            getInvoicePremises(id) {
                djinAPI.get(`/finance/user/${id}`)
                    .then((result) => {
                        const { premises, invoices } = mapReviewUserPremisesToView(result.data);
                        this.invoicePremises = premises;
                        this.invoices = invoices;
                    })
            },

            selectPremise(premise) {
                this.selectedPremise = premise;

                if (this.selectedPremise) {
                    this.getPremiseUsers(this.selectedPremise.id);
                } else {
                    this.premiseUsers = [];
                    this.clearSelectedUser();
                }
            },

            clearSelectedUser() {
                this.$refs.user.clearSelectedOption();
            },

            reInitSelects() {
                this.$refs.user.initSelect();
            },

            selectInvoice(invoice) {
                this.selectedReviewInvoice = invoice;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-determine-payer {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        max-width: 1118px;
        width: 100%;
        max-height: 80vh;
        overflow-y: auto;
        scrollbar-width: thin;
    }

    .determine-payer-form {
        h4 {
            margin-bottom: 30px;
        }

        &__control {
            display: flex;
            justify-content: flex-end;
            margin-top: 45px;

            button:first-child {
                margin-right: 30px;
            }
        }

        &__info {
            display: flex;
            flex-direction: column;
            padding-bottom: 30px;
            border-bottom: 1px solid var(--app-border);

            &--is-head {
                font-size: 16px;
            }
        }

        &__selects {
            padding-top: 30px;
            padding-bottom: unset;
            border-bottom: unset;
        }

        &__properties {
            display: flex;
            font-size: 16px;
            margin-top: 30px;
            padding-top: 30px;
            border-top: 1px solid var(--app-border);
        }

        &__row {
            display: flex;

            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }

        &__details {
            flex-basis: 50%;

            &:first-child {
                margin-right: 30px;
            }
        }

        &__user-name {
            font-weight: 700;
            white-space: nowrap;
            margin-bottom: 4px;
        }

        &__premises {
            margin-top: 30px;
        }
    }
</style>