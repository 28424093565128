import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

const recoveryErrors = {
    access: t('validation.recovery.access'),
    code: t('validation.recovery.code'),
    submit: t('validation.recovery.submit')
}

export {
    recoveryErrors
}