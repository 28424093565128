<template>
    <div class="finance-main-user">
        <div class="table__row finance-main-table__row finance-main-table__user">
            <div class="table__column finance-main-table__name finance-main-table__name--is-user" :class="{ 'finance-main-table__name--is-free-user': isFreeBuilding }">
                <app-collapse-btn
                    :default-is-active="showPremises"
                    @collapse="collapseBuildings"
                />
                <app-overflow-match-wrapper
                    :text="name"
                    :sub-text="subString"
                    :is-match="true"
                />
            </div>
            <div class="table__column finance-main-table__document-id">
                <div class="finance-main-table__user-id-row">
                    <div v-if="documentId" class="finance-main-table__user-id">
                        {{ documentId }}
                    </div>
                    <app-clipboard-btn
                        :text="copyText"
                    />
                </div>
            </div>
            <div class="table__column finance-main-table__total">
                <finance-total-item
                    :balance="balance"
                    @open-modal="openModalClient"
                />
            </div>
        </div>
        <div v-if="showPremises" class="finance-main-group__premises">
            <finance-main-premise-row v-for="premise in premises" :key="premise.id"
                :is-free-building="isFreeBuilding"
                :id="premise.id"
                :name="premise.name"
                :balance="premise.balance"
                :sub-string="subString"
            />
        </div>
    </div>
</template>

<script>
    import FinanceTotalItem from "./FinanceTotalItem";
    import FinanceMainPremiseRow from "./FinanceMainPremiseRow";
    import AppClipboardBtn from "../../common/local/AppClipboardBtn";

    export default {
        name: "FinanceMainUserRow",

        components: {
            AppClipboardBtn,
            FinanceMainPremiseRow,
            FinanceTotalItem
        },

        props: {
            isFreeBuilding: {
                type: Boolean,
                required: true
            },
            id: {
                type: String,
                required: true
            },
            documentId: {
                type: String,
                default: ''
            },
            name: {
                type: String,
                required: true
            },
            balance: {
                type: Number,
                required: true
            },
            premises: {
                type: Array,
                required: true
            },
            subString: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                showPremises: true
            }
        },

        computed: {
            copyText() {
                return this.documentId ? this.documentId : this.name
            }
        },

        methods: {
            collapseBuildings() {
                this.showPremises = !this.showPremises;
            },

            openModalClient() {
                const selectedElement = {
                    debt: this.total.value,
                    fullName: this.name,
                    serialNumber: this.id
                };

                this.openModal({
                    name: 'modalRestructuringDebt',
                    selectedEl: selectedElement
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .finance-main-user {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__premises {
            display: flex;
            flex-direction: column;
        }
    }
</style>