<template>
    <div class="loader"
        :class="{
            'loader--is-small': isSmall,
            'loader--is-medium': isMedium,
            'loader--is-large': isLarge
        }"
    >
    </div>
</template>

<script>
    export default {
        name: "AppLoader",

        props: {
            size: {
                type: String,
                required: true,
                validator(size) {
                    return ['small', 'medium', 'large'].includes(size);
                }
            }
        },

        computed: {
            isSmall() {
                return this.size === 'small';
            },
            isMedium() {
                return this.size === 'medium';
            },
            isLarge() {
                return this.size === 'large';
            }
        }
    }
</script>

<style lang="scss" scoped>
    .loader {
        border-radius: 50%;
        background: conic-gradient(#0000 10%, var(--brand-main));
        animation: spinner-zp9dbg 1.2s infinite linear;

        &--is-small {
            width: 24px;
            height: 24px;
            -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 3.8px),#000 0);
        }

        &--is-medium {
            width: 40px;
            height: 40px;
            -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 6.4px),#000 0);
        }

        &--is-large {
            width: 72px;
            height: 72px;
            -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 11.5px),#000 0);
        }
    }

    @keyframes spinner-zp9dbg {
        to {
            transform: rotate(1turn);
        }
    }
</style>