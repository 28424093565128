<template>
    <div class="finance-operations">
        <div v-if="showFinanceOperations" class="finance-operations__filters">
            <finance-operations-filters
                :qty="operations.length"
                :user-full-name="userFullName"
                :amount="amount"
                :user-premises="userPremises"
                :users="users"
                @select-user="selectUser"
                @open-modal="openModalOperation"
                @update="updateQueryParams"
            />
        </div>
        <div class="finance-operations__content" ref="financeOperations">
            <div v-if="showWelcome" class="finance-operations__welcome">
                <finance-operations-welcome
                    :users="users"
                    @select-user="selectWelcomeUser"
                />
            </div>
            <div v-if="showTable" class="finance-operations__content-table">
                <finance-operations-table
                    :operations="operations"
                    :max-height="maxHeightScroll"
                />
            </div>
            <div v-if="showEmpty" class="finance-operations__empty">
                <app-empty-content
                    :is-small="true"
                    :content-type="'operations'"
                    @open-modal="openModalOperation"
                />
            </div>
            <div v-if="showNoResults" class="finance-operations__empty">
                <app-no-results
                    :is-small="true"
                    @clear="clearQueryParams"
                />
            </div>
            <div v-if="isLoaded" class="finance-operations__loader">
                <app-loader
                    :size="'large'"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { djinAPI } from "../../../config/api";
    import { maxHeightMixin } from "../../../mixins/calcMaxHeight";
    import { operationsToastsMixin } from "../../../mixins/toasts/finance/operations";
    import { mapUsersToView, mapUsersToSelect } from "../../../api/mappers/users";
    import { mapOperationsToView } from "../../../api/mappers/finance/operations";
    import FinanceOperationsWelcome from "./FinanceOperationsWelcome";
    import FinanceOperationsFilters from "./FinanceOperationsFilters";
    import FinanceOperationsTable from "./FinanceOperationsTable";

    export default {
        name: "FinanceOperationsControl",

        components: {
            FinanceOperationsWelcome,
            FinanceOperationsFilters,
            FinanceOperationsTable
        },

        mixins: [
            maxHeightMixin,
            operationsToastsMixin
        ],

        data() {
            return {
                showFinanceOperations: false,
                isLoaded: false,
                users: [],
                operations: [],
                amount: 0,
                selectedUser: null,
                params: null
            }
        },

        created() {
            this.getUsers();
        },

        mounted() {
            this.subscribeToResize('financeOperations');
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromResize();
            this.unsubscribeFromEvents();
        },

        computed: {
            showWelcome() {
                return !this.showFinanceOperations && !this.isLoaded;
            },

            showTable() {
                return this.operations.length && this.showFinanceOperations && !this.isLoaded;
            },

            userFullName() {
                return this.selectedUser?.info;
            },

            userPremises() {
                return this.selectedUser?.premises;
            },

            showEmpty() {
                return !this.operations.length && !this.isLoaded && !this.isExistQueryParams;
            },

            showNoResults() {
                return !this.operations.length && !this.isLoaded && this.isExistQueryParams;
            },

            isExistQueryParams() {
                return this.amountSubString || this.filterSubString || this.timeFilterSubString;
            },

            amountSubString() {
                return this.params?.params?.amount ? this.params?.params?.amount : '';
            },

            filterSubString() {
                return this.params?.params?.filter ? this.params?.params?.filter : '';
            },

            timeFilterSubString() {
                return this.params?.params?.time_filter ? this.params?.params?.time_filter : '';
            }
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('updateOperations', this.updateOperations);
                this.emitter.on('deleteOperation', this.deleteOperation);
            },

            unsubscribeFromEvents() {
                this.emitter.off('updateOperations', this.updateOperations);
                this.emitter.off('deleteOperation', this.deleteOperation);
            },

            getUsers() {
                this.isLoaded = true;

                djinAPI.get('/users')
                    .then((result) => {
                        const users = mapUsersToView(result.data);
                        this.users = mapUsersToSelect(users, true);
                    })
                    .finally(() => {
                        this.isLoaded = false;
                    })
            },

            getUserFinanceOperations() {
                this.isLoaded = true;

                const queryId = this.selectedUser.id;

                djinAPI.get(`/finance/operations/${queryId}`, this.params)
                    .then((result) => {
                        const { amount, operations } = mapOperationsToView(result.data);
                        this.amount = amount;
                        this.operations = operations;

                        this.$nextTick(() => {
                            this.calcMaxHeight();
                        });
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.isLoaded = false;
                        }, 500)
                    })
            },

            deleteOperation(id) {
                djinAPI.delete(`/finance/operations/${id}`)
                    .then(() => {
                        this.closeModalConfirm();
                        this.showDeleteOperationToast();
                        this.getUserFinanceOperations();
                    })
            },

            updateQueryParams(params) {
                this.params = { ...params };
                this.getUserFinanceOperations();
            },

            clearQueryParams() {
                this.emitter.emit('clearQueryParams');
            },

            selectWelcomeUser(user) {
                this.selectedUser = user;

                if (!this.showFinanceOperations) {
                    this.showFinanceOperations = true;
                }

                this.getUserFinanceOperations();
            },

            selectUser(user) {
                this.selectedUser = user;
                this.getUserFinanceOperations();
            },

            openModalOperation() {
                this.openModal({
                    name: 'modalOperation',
                    selectedEl: this.selectedUser
                })
            },

            updateOperations() {
                this.getUserFinanceOperations();
            },

            closeModalConfirm() {
                this.closeModal('modalConfirm');
            }
        }
    }
</script>

<style lang="scss">
    .operation {
        &__date {
            width: 15%;
        }

        &__amount {
            width: 15%;
        }

        &__premise {
            width: 20%;
        }

        &__type {
            width: 20%;
        }

        &__basis {
            width: 15%;
        }

        &__actions {
            width: 15%;
            display: flex;
            justify-content: flex-end;
            padding-right: unset;
        }
    }
</style>