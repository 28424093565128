<template>
    <div class="personalization">
        <div class="personalization__control">
            <div class="personalization__settings">
                <div class="personalization__title">
                    {{ $t('personalization.title') }}
                </div>
                <div class="personalization__settings-btn">
                    <app-text-btn
                        :text="$t('personalization.resetBtn')"
                        @action="openModalConfirm"
                    >
                        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.2708 10C12.2708 9.12917 11.5583 8.41667 10.6875 8.41667C9.81667 8.41667 9.10417 9.12917 9.10417 10C9.10417 10.8708 9.81667 11.5833 10.6875 11.5833C11.5583 11.5833 12.2708 10.8708 12.2708 10ZM10.6875 2.875C6.75292 2.875 3.5625 6.06542 3.5625 10H1.1875L4.35417 13.1667L7.52083 10H5.14583C5.14583 6.93625 7.62375 4.45833 10.6875 4.45833C13.7513 4.45833 16.2292 6.93625 16.2292 10C16.2292 13.0638 13.7513 15.5417 10.6875 15.5417C9.49208 15.5417 8.38375 15.1538 7.47333 14.5125L6.34917 15.6525C7.5525 16.5708 9.05667 17.125 10.6875 17.125C14.6221 17.125 17.8125 13.9346 17.8125 10C17.8125 6.06542 14.6221 2.875 10.6875 2.875Z" fill="var(--brand-main)"/>
                        </svg>
                    </app-text-btn>
                </div>
            </div>
            <div class="personalization__actions">
                <div class="personalization__action-btn">
                    <app-base-btn
                        :size="'fixed'"
                        :variation="'cancel'"
                        :text="$t('common.buttons.cancelAll')"
                        :is-disabled="!isChanged"
                        @action="cancelChanges"
                    />
                </div>
                <app-base-btn
                    :size="'fixed'"
                    :variation="'save'"
                    :text="$t('common.buttons.save')"
                    :is-disabled="!isChanged"
                    @action="saveSettings"
                />
            </div>
        </div>
        <div class="personalization__content" ref="personalization">
            <div v-if="!isLoaded">
                <personalization-settings
                    :app-icons="icons"
                    :app-colors="colors"
                    :max-height="maxHeightScroll"
                    @update-colors="updateColors"
                    @update-icons="updateIcons"
                />
            </div>
            <div v-if="isLoaded" class="personalization__loader">
                <app-loader
                    :size="'large'"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import cloneDeep from "lodash.clonedeep";
    import PersonalizationSettings from "./PersonalizationSettings";
    import { maxHeightMixin } from "../../mixins/calcMaxHeight";
    import { djinAPI } from "../../config/api";
    import { defaultColors } from "../../helpers/colors";
    import { menuIconParams} from "../../constants/menu";
    import AppTextBtn from "../common/global/buttons/AppTextBtn";

    export default {
        name: "PersonalizationControl",

        components: {
            AppTextBtn,
            PersonalizationSettings
        },

        data() {
            return {
                isLoaded: false,
                isChanged: false,
                colors: null,
                icons: [],
                updatedColors: null,
                updatedIcons: []
            }
        },

        beforeRouteLeave (to, from, next) {
            if (this.isChanged) {
                this.openModalLeaveRoute(to);
                next(false);
            } else {
                next();
            }
        },

        mixins: [maxHeightMixin],

        created() {
            this.getAppSettings();
        },

        mounted() {
            this.subscribeToResize('personalization');
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromResize();
            this.unsubscribeFromEvents();
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('deleteSettings', this.resetAllSettings);
                this.emitter.on('leaveRoute', this.leaveCurrentRoute);
            },

            unsubscribeFromEvents() {
                this.emitter.off('deleteSettings', this.resetAllSettings);
                this.emitter.off('leaveRoute', this.leaveCurrentRoute);
            },

            getAppSettings() {
                this.isLoaded = true;

                djinAPI.get('/personalization')
                    .then((result) => {
                        const settings = result.data;
                        const colors = settings.appColors;
                        const icons = settings.icons;

                        this.colors = this.updatedColors = colors;
                        this.mapAppIcons(icons);
                    })
                    .catch(() => {
                        // this.colors = this.updatedColors = { ...defaultColors };
                        // this.mapAppDefaultIcons(menuIconParams, defaultIcons);
                    })
                    .finally(() => {
                        this.isLoaded = false;
                    })
            },

            mapAppIcons(icons) {
                this.icons = [];
                const iconKeys = Object.keys(icons);

                iconKeys.forEach((key) => {
                    const modifiedIcon = Object.assign(icons[key], menuIconParams[key]);
                    modifiedIcon.id = key;

                    this.icons.push(modifiedIcon);
                });
            },

            openModalConfirm() {
                const selectedElement = {
                    type: 'settings'
                };

                this.openModal({
                    name: 'modalConfirm',
                    selectedEl: selectedElement
                })
            },

            openModalLeaveRoute(to) {
                const selectedElement = {
                    id: to,
                    type: 'leave'
                }

                this.openModal({
                    name: 'modalConfirm',
                    selectedEl: selectedElement
                })
            },

            leaveCurrentRoute(to) {
                this.isChanged = false;
                this.closeModal('modalConfirm');
                this.$router.push({ path: to.path });
            },

            resetAllSettings() {
                this.closeModal('modalConfirm');

                this.isLoaded = true;

                djinAPI.put('/personalization/default')
                    .then((result) => {
                        document.location.reload();
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.isLoaded = false;
                        }, 500)
                    })
            },

            updateColors(colors) {
                this.updatedColors = { ...colors };
                this.setIsChanged();
            },

            updateIcons(icons) {
                this.updatedIcons = cloneDeep(icons);
                this.setIsChanged();
            },

            setIsChanged() {
                if (!this.isChanged) {
                    this.isChanged = true;
                }
            },

            saveSettings() {
                this.sendColors();
            },

            sendColors() {
                this.isLoaded = true;
                const colors = this.updatedColors;

                djinAPI.patch('/personalization/appColors', colors)
                    .finally(() => {
                        this.sendIcons();
                    })
            },

            sendIcons() {
                const icons = this.updatedIcons;
                const updateIconsToSend = new FormData();
                const deleteIconsToSend = new FormData();

                icons.forEach((icon) => {
                    if (icon.isDefault) {
                        if (icon.file) {
                            updateIconsToSend.append(`${icon.id}`, icon.file);
                        }
                    }  else {
                        if (icon.defaultLink === icon.link) {
                            deleteIconsToSend.append(`${icon.id}`, '');
                        } else {
                            if (icon.file) {
                                updateIconsToSend.append(`${icon.id}`, icon.file);
                            }
                        }
                    }
                })

                const isNeedToUpdate = Array.from(updateIconsToSend).length;
                const isNeedToDelete = Array.from(deleteIconsToSend).length;

                if (isNeedToUpdate) {
                    this.updateIconsToSend(updateIconsToSend, deleteIconsToSend, isNeedToDelete);
                } else {
                    if (isNeedToDelete) {
                        this.deleteIcons(deleteIconsToSend);
                    }
                }

                if (!isNeedToUpdate && !isNeedToDelete) {
                    this.reloadPage();
                }
            },

            updateIconsToSend(updateIcons, deleteIcons, isNeedToDelete) {
                djinAPI.post('/personalization/icons', updateIcons, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                    .finally(() => {
                        if (isNeedToDelete) {
                            this.deleteIcons(deleteIcons);
                        } else {
                            this.reloadPage();
                        }
                    })
            },

            deleteIcons(deleteIcons) {
                djinAPI.delete('/personalization/icons', {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    data: deleteIcons
                })
                .finally(() => {
                    this.reloadPage();
                })
            },

            reloadPage() {
                window.location.reload();
            },

            cancelChanges() {
                this.isChanged = false;
                this.getAppSettings();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .settings {
        display: flex;
        flex-direction: column;

        &__individual, &__advanced, &__view {
            padding: 30px 15px;
            border-bottom: 1px solid var(--app-border);
            align-items: flex-start;
            display: flex;
        }

        &__info {
            display: flex;
            flex-direction: column;
            min-width: 300px;
        }

        &__title {
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 15px;
        }

        &__details {
            font-size: 16px;
            line-height: 19px;
        }

        &__column {
            width: 33.33333%;
            padding: 0px 15px;
        }

        &__colors {
            display: flex;
            flex-direction: column;
        }

        &__colors-row {
            display: flex;

            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }

        &__color-picker {
            flex-basis: 50%;

            &:first-child {
                margin-right: 30px;
            }
        }
    }
</style>
