function formatTimestampToDate(timestamp) {
    const date = new Date(timestamp);

    let day = date.getDate();

    if (day < 10) {
        day = '0' + day;
    }

    let month = date.getMonth() + 1;

    if (month < 10) {
        month = '0' + month;
    }

    let year = date.getFullYear();

    return `${day}.${month}.${year}`;
}

function formatTimestampToTime(timestamp) {
    const date = new Date(timestamp);

    let hours = date.getHours();

    if (hours < 10) {
        hours = '0' + hours;
    }

    let minutes = date.getMinutes();

    if (minutes < 10) {
        minutes = '0' + minutes;
    }

    return `${hours}:${minutes}`;
}

function formatTimestampToDateTime(timestamp) {
    return `${formatTimestampToDate(timestamp)} ${formatTimestampToTime(timestamp)}`;
}

function formatTimestampToExpirationDate(timestamp, months) {
    const date = new Date(timestamp);

    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const qtyMonths = month + months;
    const qtyYears = Math.floor(qtyMonths/12);

    let expirationDay = date.getDate();
    let expirationMonth = qtyMonths % 12;
    const expirationYear = year + qtyYears;

    if (expirationDay < 10) {
        expirationDay = '0' + expirationDay;
    }

    if (expirationMonth < 10) {
        expirationMonth = '0' + expirationMonth;
    }

    return `${expirationDay}.${expirationMonth}.${expirationYear}`;
}

function getStartAndEndDate(id) {
    let date = null;

    switch (id) {
        case '11':
            date = getStartAndEndDay(0);
            break;
        case '12':
            date = getStartAndEndDay(1);
            break;
        case '13':
            date = getStartAndEndDay(2);
            break;
        case '21':
            date = getStartAndEndWeek(0);
            break;
        case '22':
            date = getStartAndEndWeek(7);
            break;
        case '23':
            date = getStartAndEndWeek(14);
            break;
        case '31':
            date = getStartAndEndMonth(0);
            break;
        case '32':
            date = getStartAndEndMonth(1);
            break;
        case '33':
            date = getStartAndEndMonth(2);
            break;
        case '41':
            date = getStartAndEndQuarter(0);
            break;
        case '42':
            date = getStartAndEndQuarter(1);
            break;
        case '43':
            date = getStartAndEndQuarter(2);
            break;
        case '44':
            date = getStartAndEndQuarter(3);
            break;
        case '51':
            date = getStartAndEndYear(0);
            break;
        case '52':
            date = getStartAndEndYear(1);
            break;
        case '53':
            date = getStartAndEndYear(2);
            break;
    }

    return date;
}

function getStartAndEndDay(offset) {
    let startDate = new Date();
    let endDate = new Date();

    if (offset !== 0) {
        startDate.setUTCDate(startDate.getUTCDate() - offset);
        endDate.setUTCDate(endDate.getUTCDate() - offset);
    }

    startDate.setUTCHours(0, 0, 0, 0);
    endDate.setUTCHours(23, 59, 59, 999);

    return {
        startDate: startDate.getTime(),
        endDate: endDate.getTime()
    }
}

function getStartAndEndWeek(offset) {
    let startDate = new Date();
    let endDate = new Date();

    startDate.setUTCDate(startDate.getUTCDate() - startDate.getUTCDay() + 1 - offset);
    endDate.setUTCDate(endDate.getUTCDate() - endDate.getUTCDay() + 7 - offset);

    startDate.setUTCHours(0, 0, 0, 0);
    endDate.setUTCHours(23, 59, 59, 999);

    return {
        startDate: startDate.getTime(),
        endDate: endDate.getTime()
    }
}

function getStartAndEndMonth(offset) {
    let date = new Date();

    const startDate = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth() - offset, 1));
    const endDate = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1 - offset, 0));

    startDate.setUTCHours(0, 0, 0, 0);
    endDate.setUTCHours(23, 59, 59, 999);

    return {
        startDate: startDate.getTime(),
        endDate: endDate.getTime()
    }
}

function getStartAndEndQuarter(quarter) {
    let date = new Date();

    const startMonth = quarter * 3;
    const endMonth = startMonth + 2;
    const endDayOfMonth= quarter === 0 || quarter === 3 ? 31 : 30;

    let startDate = new Date(Date.UTC(date.getUTCFullYear(), startMonth, 1));
    let endDate = new Date(Date.UTC(date.getUTCFullYear(), endMonth, endDayOfMonth));


    startDate.setUTCHours(0, 0, 0, 0);
    endDate.setUTCHours(23, 59, 59, 999);

    return {
        startDate: startDate.getTime(),
        endDate: endDate.getTime()
    }
}

function getStartAndEndYear(offset) {
    let date = new Date();

    let startDate = new Date(Date.UTC(date.getUTCFullYear() - offset, 0, 1));
    let endDate = new Date(Date.UTC(date.getUTCFullYear() - offset, 11, 31));

    startDate.setUTCHours(0, 0, 0, 0);
    endDate.setUTCHours(23, 59, 59, 999);

    return {
        startDate: startDate.getTime(),
        endDate: endDate.getTime()
    }
}

function convertToISOStringWithoutTime(dateTime) {
    return new Date(dateTime).toISOString().substring(0, 10);
}

export {
    formatTimestampToDate,
    formatTimestampToDateTime,
    formatTimestampToExpirationDate,
    getStartAndEndDate,
    convertToISOStringWithoutTime
}