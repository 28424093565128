<template>
    <app-modal-wrapper>
        <div class="modal-premise" ref="modalPremise">
            <form @submit.prevent="doActionPremise" class="premise-form">
                <h4>{{ title }}</h4>
                <div class="premise-form__info">
                    <div class="premise-form__details">
                        <div class="premise-form__building">
                            <app-base-select
                                :label="$t('premises.premise.buildings.label')"
                                :place-holder="$t('premises.premise.buildings.ph')"
                                :is-searchable="true"
                                :selected-option="selectedBuilding"
                                :options="buildings"
                                :type="'text'"
                                :error-message="errors.building"
                                @select="selectBuilding"
                            />
                        </div>
                        <div class="premise-form__type">
                            <app-base-select
                                :label="$t('premises.premise.premiseType.label')"
                                :place-holder="$t('premises.premise.premiseType.ph')"
                                :selected-option="selectedType"
                                :options="types"
                                :type="'text'"
                                :error-message="errors.type"
                                @select="selectType"
                            />
                        </div>
                        <div class="premise-form__entrance">
                            <app-base-select ref="entrance"
                                :label="$t('premises.premise.entrances.label')"
                                :place-holder="$t('premises.premise.entrances.ph')"
                                :selected-option="selectedEntrance"
                                :options="entrances"
                                :type="'text'"
                                :required="false"
                                :is-clear="true"
                                @select="selectEntrance"
                            />
                        </div>
                        <div class="premise-form__floor">
                            <app-base-select ref="floor"
                                :label="$t('premises.premise.floors.label')"
                                :place-holder="$t('premises.premise.floors.ph')"
                                :selected-option="selectedFloor"
                                :options="floors"
                                :type="'text'"
                                :error-message="errors.floor"
                                @select="selectFloor"
                            />
                        </div>
                        <div class="premise-form__name">
                            <app-base-input
                                v-model="premise.name"
                                :label="$t('premises.premise.name.label')"
                                :place-holder="$t('premises.premise.name.ph')"
                                :error-message="errors.name"
                            />
                        </div>
                    </div>
                    <div class="premise-form__details">
                        <div class="premise-form__file">
                            <div class="premise-form__area">
                                <app-base-input
                                    v-model="premise.area"
                                    :label="$t('premises.premise.area.label')"
                                    :place-holder="$t('premises.premise.area.ph')"
                                    :required="false"
                                    :error-message="errors.area"
                                />
                            </div>
                            <div class="premise-form__upload">
                                <app-upload-file
                                    :label="$t('upload.label.file')"
                                    :accept-types="[]"
                                    :height="'full'"
                                    :file-name="document.name"
                                    :file-size="document.size"
                                    @update="updateFile"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="premise-form__users">
                    <div v-if="!isUpdate" class="premise-form__users-new">
                        <div v-for="(user, index) in users" :key="user.id">
                            <user-form
                                :index="index"
                                :length="users.length"
                                :is-premise-user="true"
                                :id="user.id"
                                :is-new="user.isNew"
                                :surname="user.surname"
                                :name="user.name"
                                :middle-name="user.middleName"
                                :phone="user.phone"
                                :email="user.email"
                                :document-id="user.documentId"
                                @delete="deleteUser"
                                @update="updateUser"
                            />
                        </div>
                        <div v-if="showInviteForm" class="premise-form__invite">
                            <invite-form
                                @update="updateInviteVariant"
                            />
                        </div>
                    </div>
                    <div v-else class="premise-form__users-table">
                        <premise-users-table v-if="!isLoaded && users.length"
                            :users="users"
                        />
                    </div>
                </div>
                <div class="premise-form__control">
                    <app-base-btn v-if="isUpdate"
                        :size="'fixed'"
                        :variation="'delete'"
                        :text="$t('common.buttons.delete')"
                        @action="openModalConfirm"
                    />
                    <app-base-btn
                        :size="'fixed'"
                        :variation="'cancel'"
                        :text="$t('common.buttons.cancel')"
                        @action="closeModalPremise"
                    />
                    <app-base-btn v-if="!isUpdate"
                        :size="'fixed'"
                        :variation="'addMain'"
                        :text="$t('common.buttons.existUser')"
                        @action="addUser(false)"
                    >
                        <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.4115 15.349H15.5885L15.2969 15.0677C16.3177 13.8802 16.9323 12.3385 16.9323 10.6615C16.9323 6.92188 13.901 3.89062 10.1615 3.89062C6.42188 3.89062 3.39062 6.92188 3.39062 10.6615C3.39062 14.401 6.42188 17.4323 10.1615 17.4323C11.8385 17.4323 13.3802 16.8177 14.5677 15.7969L14.849 16.0885V16.9115L20.0573 22.1094L21.6094 20.5573L16.4115 15.349ZM10.1615 15.349C7.56771 15.349 5.47396 13.2552 5.47396 10.6615C5.47396 8.06771 7.56771 5.97396 10.1615 5.97396C12.7552 5.97396 14.849 8.06771 14.849 10.6615C14.849 13.2552 12.7552 15.349 10.1615 15.349Z" fill="var(--brand-main)"/>
                        </svg>
                    </app-base-btn>
                    <app-base-btn v-if="!isUpdate"
                        :size="'fixed'"
                        :variation="'addMain'"
                        :text="$t('common.buttons.newUser')"
                        @action="addUser(true)"
                    >
                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.2918 14.0413H14.0418V20.2913H11.9585V14.0413H5.7085V11.958H11.9585V5.70801H14.0418V11.958H20.2918V14.0413Z" fill="var(--brand-main)"/>
                        </svg>
                    </app-base-btn>
                    <app-base-btn
                        :type="'submit'"
                        :size="'fixed'"
                        :variation="'save'"
                        :text="saveBtnText"
                    />
                </div>
            </form>
        </div>
    </app-modal-wrapper>
</template>

<script>
    import AppUploadFile from "../../common/local/upload/AppUploadFile";
    import UserForm from "../../users/UserForm";
    import InviteForm from "../../invite/InviteForm";
    import PremiseUsersTable from "../../premises/PremiseUsersTable";
    import { generateTemporaryId } from "../../../helpers/common";
    import { clearDependentSelects } from "../../../helpers/selects";
    import { premiseTypes } from "../../../constants/premises";
    import { validatePremise } from "../../../validations/premise";
    import { djinAPI } from "../../../config/api";
    import { mapPremiseToSend } from "../../../api/mappers/premises";
    import cloneDeep from "lodash.clonedeep";
    import { premisesToastsMixin } from "../../../mixins/toasts/premises";
    import { mapGetters } from "vuex";
    import { mapUsersToView, mapPremiseExistUsersToSend, mapPremiseNewUsersToSend } from "../../../api/mappers/users";

    export default {
        name: "ModalPremise",

        components: {
            PremiseUsersTable,
            UserForm,
            InviteForm,
            AppUploadFile
        },

        props: {
            selectedPremise: {
                type: Object
            }
        },

        mixins: [
            premisesToastsMixin
        ],

        data() {
            return {
                isLoaded: false,
                selectedBuilding: null,
                selectedType: null,
                selectedEntrance: null,
                selectedFloor: null,
                inviteVariant: 'sms',

                premise: {
                    name: '',
                    area: '',
                    file: '',
                },
                users: [],

                document: {
                    file: '',
                    name: '',
                    size: '',
                    isChanged: false
                },

                errors: {
                    building: '',
                    type: '',
                    floor: '',
                    name: '',
                    area: ''
                }
            }
        },

        mounted() {
            this.checkUpdatePremise();
        },

        computed: {
            ...mapGetters({
                buildings: 'buildings/mappedBuildings'
            }),

            isUpdate() {
                return !!this.selectedPremise;
            },

            title() {
                return this.isUpdate
                    ? this.$t('premises.premise.title.edit')
                    : this.$t('premises.premise.title.create');
            },

            saveBtnText() {
                return this.isUpdate
                    ? this.$t('common.buttons.save')
                    : this.$t('common.buttons.addPremise');
            },

            types() {
                return premiseTypes;
            },

            entrances() {
                return this.selectedBuilding?.entrances || [];
            },

            floors() {
                return this.selectedBuilding?.floors || [];
            },

            showInviteForm() {
                return this.users.some((user) => user.isNew);
            }
        },

        methods: {
            checkUpdatePremise() {
                if (this.isUpdate) {
                    this.premise = cloneDeep(this.selectedPremise);

                    this.selectedBuilding = this.buildings.find((building) => building.id === this.premise.building.id);
                    this.selectedType = this.types.find((type) => type.id === this.premise.type);

                    this.selectedFloor = this.floors.find((floor) => floor.id.toString() === this.premise.floor);

                    if (this.premise.entrance) {
                        this.selectedEntrance = this.entrances.find((entrance) => entrance.id.toString() === this.premise.entrance);
                    }

                    if (this.premise.document) {
                        this.document.name = this.premise.name;
                    }

                    this.getPremiseUsers();
                }
            },

            getPremiseUsers() {
                this.isLoaded = true;
                const id = this.premise.id;

                djinAPI.get(`/premises/${id}`)
                    .then((result) => {
                        this.users = mapUsersToView(result.data.users);
                    })
                    .finally(() => {
                        this.isLoaded = false;
                    })
            },

            doActionPremise() {
                this.clearPremiseErrors();
                const { isValid, errors } = validatePremise(this.premise, this.selectedBuilding, this.selectedType, this.selectedFloor);
                this.errors = errors;

                let isValidAllUsers = false;

                if (this.isUpdate) {
                    isValidAllUsers = true;
                } else {
                    this.emitter.emit('validateUser');
                    isValidAllUsers = this.users.every(user => user.isValid);
                }

                if (isValid && isValidAllUsers) {
                    if (!this.isUpdate) {
                        this.createPremise();
                    } else {
                        this.changePremise();
                    }
                }
            },

            createPremise() {
                this.premise.buildingId = this.selectedBuilding.id;
                this.premise.type = this.selectedType.id;
                this.premise.floor = this.selectedFloor.id;

                if (this.selectedEntrance) {
                    this.premise.entrance = this.selectedEntrance.id;
                }

                const premiseToSend = mapPremiseToSend(this.premise);

                if (this.users.length) {
                    const existUsers = this.users.filter((user) => !user.isNew);

                    if (existUsers.length) {
                        premiseToSend.existed_users = mapPremiseExistUsersToSend(existUsers);
                    }

                    const newUsers = this.users.filter((user) => user.isNew);

                    if (newUsers.length) {
                        premiseToSend.new_users = mapPremiseNewUsersToSend(newUsers, this.inviteVariant);
                    }
                }

                djinAPI.post('/premises', premiseToSend)
                    .then((result) => {
                        const id = result.data._id;
                        this.showCreatePremiseToast();
                        this.uploadDocument(id);
                    })
            },

            changePremise() {
                const id = this.premise.id;

                this.premise.buildingId = this.selectedBuilding.id;
                this.premise.type = this.selectedType.id;
                this.premise.floor = this.selectedFloor.id;

                if (this.selectedEntrance) {
                    this.premise.entrance = this.selectedEntrance.id;
                } else {
                    this.premise.entrance = '';
                }

                const premiseToSend = mapPremiseToSend(this.premise);

                djinAPI.patch(`/premises/${id}`, premiseToSend)
                    .then((result) => {
                        this.uploadDocument(id);
                    })
            },

            uploadDocument(id) {
                if (!this.isUpdate) {
                    if (this.premise.document) {
                        this.sendFile(id, this.premise.document);
                    } else {
                        this.updatePremises();
                    }
                } else {
                    if (this.premise.document) {
                        //File have already been uploaded
                        if (this.document.isChanged) {
                            //File has been changed
                            if (this.document.file) {
                                this.sendFile(id, this.document.file);
                            } else {
                                this.deleteFile(id);
                            }
                        } else {
                            this.updatePremises();
                        }
                    } else {
                        if (this.document.file) {
                            this.sendFile(id, this.document.file);
                        } else {
                            this.updatePremises();
                        }
                    }
                }
            },

            deleteFile(id) {
                djinAPI.delete(`/premises/${id}/document`)
                    .then(() => {
                        this.updatePremises();
                    })
            },

            sendFile(id, document) {
                const data = new FormData();
                data.append('document', document);

                djinAPI.post(`/premises/${id}/document`, data, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                    .then(() => {
                        this.updatePremises();
                    })
            },

            updatePremises() {
                this.closeModalPremise();
                this.emitter.emit('updatePremises');
            },

            selectBuilding(building) {
                this.selectedBuilding = building;
                this.clearDependentFields();
            },

            selectFloor(floor) {
                this.selectedFloor = floor;
            },

            selectType(type) {
                this.selectedType = type;
            },

            selectEntrance(entrance) {
                this.selectedEntrance = entrance;
            },

            clearDependentFields() {
                const dependentSelects = {
                    entrance: this.$refs.entrance,
                    floor: this.$refs.floor
                };

                clearDependentSelects(dependentSelects);
            },

            updateFile(uploadedFile) {
                if (!this.isUpdate) {
                    this.premise.document = uploadedFile.file;
                } else {
                    this.document.file = uploadedFile.file;
                    this.document.isChanged = uploadedFile.isChanged;
                }

                const file = this.isUpdate ? this.document.file : this.premise.document;

                if (file) {
                    this.document.name = uploadedFile.file.name;
                    this.document.size = `${Math.round(uploadedFile.file.size/1000)} KB`;
                } else {
                    this.document.name = '';
                    this.document.size = '';
                }
            },

            closeModalPremise() {
                this.closeModal('modalPremise');
            },

            addUser(isNew) {
                const temporaryId = generateTemporaryId();

                const user = {
                    id: temporaryId,
                    isNew: isNew,
                    isValid: false,
                    surname: '',
                    name: '',
                    middleName: '',
                    phone: {
                        code: '+380',
                        number: ''
                    },
                    email: '',
                    documentId: '',
                    isOwner: false,
                    isRegistered: false,
                    selectedUser: null
                }

                this.users.push(user);

                //Need to use nextTick for get new height block after add
                this.$nextTick(() => {
                    const modal = this.$refs.modalPremise;
                    modal.scrollTop = modal.scrollHeight;
                });
            },

            deleteUser(id) {
                this.users = this.users.filter((user) => user.id !== id);
            },

            updateUser(id, isValid, userData) {
                const user = this.users.find((user) => user.id === id);

                user.surname = userData.surname;
                user.name = userData.name;
                user.middleName = userData.middleName;
                user.phone = userData.phone;
                user.email = userData.email;
                user.documentId = userData.documentId;
                user.phone = userData.phone;
                user.selectedUser = userData.selectedUser;
                user.isOwner = userData.isOwner;
                user.isRegistered = userData.isRegistered;

                user.isValid = isValid;
            },

            clearPremiseErrors() {
                for (let key in this.errors) {
                    this.errors[key] = '';
                }
            },

            openModalConfirm() {
                const selectedElement = {
                    id: this.selectedPremise.id,
                    name: this.selectedPremise.name,
                    type: 'premise'
                };

                this.openModal({
                    name: 'modalConfirm',
                    selectedEl: selectedElement
                })
            },

            updateInviteVariant(variant) {
                this.inviteVariant = variant;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-premise {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1118px;
        max-height: 80vh;
        overflow-y: auto;
        scrollbar-width: thin;
    }

    .premise-form {
        h4 {
            margin-bottom: 30px;
        }

        &__info {
            display: flex;
        }

        &__details {
            flex-basis: 50%;

            &:first-child {
                margin-right: 30px;
            }
        }

        &__building, &__type, &__floor,
        &__entrance, &__area {
            margin-bottom: 15px;
        }

        &__file {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        &__upload {
            flex-grow: 1
        }

        &__control {
            display: flex;
            justify-content: flex-end;
            margin-top: 45px;

            button:not(:last-child) {
                margin-right: 30px;
            }
        }

        &__users-table {
            margin-top: 30px;
        }
    }
</style>