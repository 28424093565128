<template>
    <app-modal-wrapper>
        <div class="modal-operation">
            <form @submit.prevent="doActionOperation" class="operation-form">
                <h4>{{ $t('finance.operations.operation.title.create') }}</h4>
                <div class="operation-form__row">
                    <div class="operation-form__amount">
                        <div class="operation-form__currency">
                            {{ $t('finance.data.currency') }}
                        </div>
                        <app-base-input
                            v-model="operation.amount"
                            :label="$t('finance.operations.operation.amount.label')"
                            :error-message="errors.amount"
                        />
                    </div>
                </div>
                <div class="operation-form__row">
                    <app-base-select
                        :label="$t('finance.operations.operation.type.label')"
                        :selected-option="selectedType"
                        :options="types"
                        :type="'text'"
                        @select="selectType"
                    />
                </div>
                <div v-if="!isServiceType" class="operation-form__row">
                    <app-base-select
                        :label="$t('finance.operations.operation.premise.label')"
                        :place-holder="$t('finance.operations.operation.premise.ph')"
                        :selected-option="selectedPremise"
                        :options="premises"
                        :type="'text'"
                        :error-message="errors.premise"
                        @select="selectPremise"
                    />
                </div>
                <div class="operation-form__row">
                    <app-base-input
                        v-model="operation.basis"
                        :label="$t('finance.operations.operation.basis.label')"
                        :place-holder="$t('finance.operations.operation.basis.ph')"
                        :error-message="errors.basis"
                    />
                </div>
                <div class="operation-form__control">
                    <app-base-btn
                        :variation="'cancel'"
                        :text="$t('common.buttons.cancel')"
                        @action="closeModalOperation"
                    />
                    <app-base-btn
                        :type="'submit'"
                        :variation="'save'"
                        :text="$t('common.buttons.createOperation')"
                    />
                </div>
            </form>
        </div>
    </app-modal-wrapper>
</template>

<script>
    import { djinAPI } from "../../../config/api";
    import { operationsToastsMixin} from "../../../mixins/toasts/finance/operations";
    import { operationTypes } from "../../../constants/finance";
    import { validateOperation } from "../../../validations/finance/operations";
    import { mapOperationToSend } from "../../../api/mappers/finance/operations";

    export default {
        name: "ModalOperation",

        props: {
            selectedUser: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                operation: {
                    amount: 0,
                    basis: '',
                },

                selectedType: null,
                selectedPremise: null,

                errors: {
                    amount: '',
                    premise: '',
                    basis: ''
                }
            }
        },

        mixins: [
            operationsToastsMixin
        ],

        created() {
            this.selectedType = this.types[0];
        },

        computed: {
            types() {
                return operationTypes;
            },

            premises() {
                return this.selectedUser?.premises;
            },

            isServiceType() {
                return this.selectedType?.id === 'service';
            }
        },

        methods: {
            doActionOperation() {
                this.clearOperationErrors()
                const { isValid, errors } = validateOperation(this.operation.amount, this.selectedType, this.selectedPremise, this.operation.basis);
                this.errors = errors;

                if (isValid) {
                    this.createOperation();
                }
            },

            createOperation() {
                this.operation.userId = this.selectedUser.id;
                this.operation.type = this.selectedType.id;

                if (!this.isServiceType) {
                    this.operation.premiseId = this.selectedPremise.id;
                }

                const mappedOperation = mapOperationToSend(this.operation);

                djinAPI.post('/finance/operations', mappedOperation)
                    .then((result) => {
                        this.showCreateOperationToast();
                        this.updateOperations();
                    })
            },

            updateOperations() {
                this.closeModalOperation()
                this.emitter.emit('updateOperations');
            },

            clearOperationErrors() {
                for (let key in this.errors) {
                    this.errors[key] = '';
                }
            },

            selectType(type) {
                this.selectedType = type;
            },

            selectPremise(premise) {
                this.selectedPremise = premise;
            },

            closeModalOperation() {
                this.closeModal('modalOperation');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-operation {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 574px;
    }

    .operation-form {
        h4 {
            margin-bottom: 30px;
        }

        &__row:not(:last-child) {
            margin-bottom: 15px;
        }

        &__amount {
            position: relative;
        }

        &__currency {
            position: absolute;
            left: 15px;
            top: 38px;
            z-index: 10;
        }

        &__control {
            display: flex;
            align-items: center;
            margin-top: 45px;

            button:not(:last-child) {
                margin-right: 30px;
            }
        }
    }
</style>

<style lang="scss">
    .operation-form__amount {
        .field__entry {
            input {
                padding-left: 55px !important;
            }
        }
    }
</style>