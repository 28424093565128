import { validatePositiveNumber } from "../common";
import i18n from "../../../src/localization/i18n"
const { t } = i18n.global;

const contractErrors = {
    selectedTemplate: t('validation.contract.selectedTemplate'),
    selectedPeriod: t('validation.contract.selectedPeriod'),
    selectedBuilding: t('validation.contract.selectedBuilding'),
    selectedUser: t('validation.contract.selectedUser'),
    selectedPremises: t('validation.contract.selectedPremises'),
}

const contractTariffErrors = {
    tariff: t('validation.numberPositive'),
}

function validateContract(contract, template, period, building, user, premises) {
    let isValid = false;

    const errors = {
        selectedTemplate: '',
        selectedPeriod: '',
        selectedBuilding: '',
        selectedUser: '',
        selectedPremises: ''
    }

    const isValidTemplate = !!template;

    if (!isValidTemplate) {
        errors.selectedTemplate = contractErrors.selectedTemplate;
    }

    const isValidPeriod= !!period;

    if (!isValidPeriod) {
        errors.selectedPeriod = contractErrors.selectedPeriod;
    }

    // const isValidBuilding = !!building || !!user;
    //
    // if (!isValidBuilding) {
    //     errors.selectedBuilding = contractErrors.selectedBuilding;
    // }

    const isValidUser = !!user;

    if (!isValidUser) {
        errors.selectedUser = contractErrors.selectedUser;
    }

    const isValidPremises = !!premises.length;

    if (!isValidPremises) {
        errors.selectedPremises = contractErrors.selectedPremises;
    }

    isValid = isValidTemplate && isValidPeriod && isValidUser && isValidPremises;

    return { isValid, errors };
}

function validateContractTariff(tariff) {
    let isValid = false;

    const errors = {
        tariff: ''
    }

    const isValidTariff = validatePositiveNumber(tariff);

    if (!isValidTariff) {
        errors.tariff = contractTariffErrors.tariff;
    }

    isValid = isValidTariff;

    return { isValid, errors };
}


export {
    validateContract,
    validateContractTariff
}