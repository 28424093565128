<template>
    <div class="color-type"
        :style="{
            'backgroundColor': currentColorWithOpacity,
            'color': currentColor
        }"
    >
        <div>{{ text }}</div>
    </div>
</template>

<script>
    export default {
        name: "AdvancedColorType",

        props: {
            currentColor: {
                type: String,
                required: true
            },
            text: {
                type: String,
                required: true
            }
        },

        computed: {
            currentColorWithOpacity() {
                return this.hexToRGBA(this.currentColor);
            }
        },

        methods: {
            hexToRGBA(hex) {
                const [ hex1, hex2, hex3, hex4, hex5, hex6 ] = hex.substring(1).split('');
                const r = this.hexPairToDecimal(hex1, hex2);
                const g = this.hexPairToDecimal(hex3, hex4);
                const b = this.hexPairToDecimal(hex5, hex6);

                return `rgba( ${r}, ${g}, ${b}, 0.3)`;
            },

            hexPairToDecimal(firstHex, secondHex) {
                return parseInt(firstHex, 16) * 16 + parseInt(secondHex, 16);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .color-type {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        width: 120px;
        border-radius: 13px;
        font-size: 12px;
        /*margin-bottom: 12px;*/
    }
</style>

