<template>
    <div class="base-state"
         :style="{
            'backgroundColor': background
        }"
    >
        <span>{{ name }}</span>
    </div>
</template>

<script>
    export default {
        name: "AppBaseState",

        props: {
            id: {
                type: [ String, Number ],
                required: true
            },
            name: {
                type: String,
                required: true
            },
            background: {
                type: String,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>
    .base-state {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        border-radius: 100px;
        font-size: 12px;
        line-height: 14px;
        padding: 5px 15px;
        color: var(--app-base-primary);
        white-space: nowrap;
    }
</style>