<template>
    <div class="logo"
        :style="{
            'backgroundImage': `url(${logoUrl})`
        }"
    />
</template>

<script>
    export default {
        name: "AdminLogo",

        props: {
            logoUrl: {
                type: String,
                required: true
            },
            isTemplate: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style lang="scss" scoped>
    .logo {
        background-repeat: no-repeat;
        background-size: contain;
        background-color: transparent;
        background-position: center center;
        width: 100px;
        height: 30px;
    }
</style>