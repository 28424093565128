<template>
    <app-modal-wrapper>
        <div class="modal-change-login">
            <div v-if="currentStep === 0" class="change-login-step-1">
                <form @submit.prevent="confirmCurrentAuthenticatedUser" class="change-login-step-1__form">
                    <h4>{{ $t('changeLogin.step1.title') }}</h4>
                    <div class="change-login-step-1__password">
                        <app-base-input
                            v-model="access.password"
                            :type="'password'"
                            :show-password-icon="false"
                            :label="$t('changeLogin.step1.label')"
                            :error-message="errors.password"
                        />
                    </div>
                    <div class="change-login-step-1__control">
                        <app-base-btn
                            :size="'full'"
                            :variation="'cancel'"
                            :text="$t('common.buttons.cancel')"
                            @action="previousStep"
                        />
                        <app-base-btn
                            :type="'submit'"
                            :size="'full'"
                            :variation="'save'"
                            :text="$t('common.buttons.next')"
                        />
                    </div>
                </form>
            </div>
            <div v-if="currentStep === 1" class="change-login-step-2">
                <form @submit.prevent="changeUserLogin" class="change-login-step-2__form">
                    <h4>{{ $t('changeLogin.step2.title') }}</h4>
                    <div class="change-login-step-2__new-login">
                        <app-base-input
                            v-model="newLogin"
                            :label="$t('changeLogin.step2.label')"
                            :error-message="errors.newLogin"
                        />
                    </div>
                    <div class="change-login-step-2__control">
                        <app-base-btn
                            :size="'full'"
                            :variation="'cancel'"
                            :text="$t('common.buttons.cancel')"
                            @action="previousStep"
                        />
                        <app-base-btn
                            :type="'submit'"
                            :size="'full'"
                            :variation="'save'"
                            :text="$t('common.buttons.next')"
                        />
                    </div>
                </form>
            </div>
            <div v-if="currentStep === 2" class="change-login-step-3">
                <form @submit.prevent="verifyUserAttributeSubmit" class="change-login-step-3__form">
                    <h4>{{ $t('recovery.step3.title') }}</h4>
                    <div class="change-login-step-3__code">
                        <app-base-input
                            v-model="code"
                            :label="formattedCodeLabel"
                            :error-message="errors.code"
                        />
                    </div>
                    <div class="change-login-step-3__repeat-btn">
                        <app-text-btn
                            :text="$t('common.buttons.repeat')"
                            @action="verifyUserAttribute"
                        />
                    </div>
                    <div class="change-login-step-3__control">
                        <app-base-btn
                            :size="'full'"
                            :variation="'cancel'"
                            :text="$t('common.buttons.cancel')"
                            @action="previousStep"
                        />
                        <app-base-btn
                            :type="'submit'"
                            :size="'full'"
                            :variation="'save'"
                            :text="$t('common.buttons.next')"
                        />
                    </div>
                </form>
            </div>
        </div>
    </app-modal-wrapper>
</template>

<script>
    import {
        getCurrentUserInfo,
        login,
        logout,
        updateUserAttributes,
        verifyCurrentUserAttribute,
        verifyCurrentUserAttributeSubmit
    } from "../../../services/checkAccess";
    import { validateEmail, validatePhone } from "../../../validations/login";
    import { changeLoginErrors } from "../../../validations/changeLogin";
    import { formatEmail, formatPhone } from "../../../helpers/symbols";
    import { authToastsMixin } from "../../../mixins/toasts/auth";

    export default {
        name: "ModalChangeLogin",

        data() {
            return {
                currentStep: 0,
                access: {
                    login: '',
                    password: '',
                },
                isEmailLogin: false,
                newLogin: '',
                code: '',

                errors: {
                    password: '',
                    newLogin: '',
                    code: ''
                }
            }
        },

        mixins: [authToastsMixin],

        computed: {
            formattedCodeLabel() {
                const loginType = this.isEmailLogin
                    ? this.$t('changeLogin.step3.label.email')
                    : this.$t('changeLogin.step3.label.phone');
                const formattedLogin = this.isEmailLogin
                    ? formatEmail(this.newLogin)
                    : formatPhone(this.newLogin);

                return `${this.$t('changeLogin.step3.label.details')} ${formattedLogin}${loginType}`;
            }
        },

        methods: {
            nextStep() {
                this.currentStep += 1;
            },

            previousStep() {
                if (this.currentStep === 0) {
                    this.closeModalChangeLogin();
                } else {
                    this.currentStep -= 1;
                }
            },

            async confirmCurrentAuthenticatedUser() {
                this.clearErrorsStep1();

                await getCurrentUserInfo()
                    .then((user) => {
                        if (user) {
                            if (user.attributes.email) {
                                this.access.login = user.attributes.email;
                                this.isEmailLogin = true;
                            } else {
                                this.access.login = user.attributes.phone_number;
                            }
                        }
                    })

                await login(this.access)
                    .then(userSession => {
                        if (userSession) {
                            this.nextStep();
                        } else {
                            this.setErrorsStep1();
                        }
                    });
            },

            async changeUserLogin() {
                this.clearErrorsStep2();
                const isValid = this.isEmailLogin ? validateEmail(this.newLogin) : validatePhone(this.newLogin);

                if (isValid) {
                    await updateUserAttributes(this.newLogin, this.isEmailLogin)
                        .then(() => {
                            this.nextStep();
                        })
                } else {
                    this.setErrorsStep2();
                }
            },

            async verifyUserAttribute() {
                await verifyCurrentUserAttribute();
            },

            async verifyUserAttributeSubmit() {
                this.clearErrorsStep3();
                const isValid = !!this.code;

                if (isValid) {
                    await verifyCurrentUserAttributeSubmit(this.code)
                        .then(() => {
                            this.showChangeLoginToast();
                            this.closeModalChangeLogin();
                            this.logoutUser();
                        })
                } else {
                    this.setErrorsStep3();
                }
            },

            async logoutUser() {
                await logout()
                    .then(isSuccess => {
                        if (isSuccess) {
                            this.$router.push({ name: 'Login' });
                        }
                    })
            },

            setErrorsStep1() {
                this.errors.password = changeLoginErrors.access;
            },

            clearErrorsStep1() {
                this.errors.password = '';
            },

            setErrorsStep2() {
                this.errors.newLogin = changeLoginErrors.newLogin;
            },

            clearErrorsStep2() {
                this.errors.newLogin = '';
            },

            setErrorsStep3() {
                this.errors.code = changeLoginErrors.code;
            },

            clearErrorsStep3() {
                this.errors.code = '';
            },

            closeModalChangeLogin() {
                this.closeModal('modalChangeLogin');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-change-login {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        max-width: 574px;
        width: 100%;
    }

    .change-login-step-1, .change-login-step-2, .change-login-step-3 {
        h4 {
            margin-bottom: 30px;
        }

        &__control {
            display: flex;
            margin-top: 45px;

            button:first-child {
                margin-right: 30px;
            }
        }
    }

    .change-login-step-3 {
        &__code {
            margin-bottom: 10px;
        }
    }
</style>