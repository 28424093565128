<template>
    <div class="icon-upload"
         :class="{
            'icon-upload--is-logo': isLogo
         }"
    >
        <div class="icon-upload__label app__label">
            {{ formattedLabel }}
        </div>
        <div class="icon-upload__view"
             :class="{
                'icon-upload__view--is-uploaded': icon.isUploaded
             }"
        >
            <div v-if="isLogo">
                <div v-if="!icon.isUploaded" class="icon-upload__logo"
                    :style="{
                        'backgroundImage': `url(${link})`
                    }"
                />
                <div v-else class="icon-upload__uploaded-logo"
                    :style="{
                        'backgroundImage': `url(${icon.link})`,
                    }"
                />
                <button type="button" v-if="icon.isUploaded" class="icon-upload__clear-btn" @click="clearUploadedIcon">
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.875 4.00625L10.9938 3.125L7.5 6.61875L4.00625 3.125L3.125 4.00625L6.61875 7.5L3.125 10.9938L4.00625 11.875L7.5 8.38125L10.9938 11.875L11.875 10.9938L8.38125 7.5L11.875 4.00625Z" fill="#2B3037"/>
                    </svg>
                </button>
            </div>
            <div v-else>
                <div v-if="!icon.isUploaded" class="icon-upload__icon"
                    :style="{
                        'maskImage': `url(${link})`,
                        'backgroundColor': 'var(--app-base-secondary)'
                    }"
                />
                <div v-else class="icon-upload__uploaded-icon"
                    :style="{
                        'backgroundImage': `url(${icon.link})`,
                    }"
                />
                <button type="button" v-if="icon.isUploaded" class="icon-upload__clear-btn" @click="clearUploadedIcon">
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.875 4.00625L10.9938 3.125L7.5 6.61875L4.00625 3.125L3.125 4.00625L6.61875 7.5L3.125 10.9938L4.00625 11.875L7.5 8.38125L10.9938 11.875L11.875 10.9938L8.38125 7.5L11.875 4.00625Z" fill="#2B3037"/>
                    </svg>
                </button>
            </div>
        </div>
        <div class="icon-upload__control">
            <app-text-btn
                :text="$t('common.buttons.change')"
                @action="openModalUploadIcon"
            />
        </div>
    </div>
</template>

<script>
    import AppTextBtn from "../../common/global/buttons/AppTextBtn";

    export default {
        name: "ViewIconUpload",

        components: {
            AppTextBtn
        },

        props: {
            id: {
                type: String,
                required: true
            },
            label: {
                type: String,
                required: true
            },
            link: {
                type: String,
                required: true
            },
            defaultLink: {
                type: String,
                required: true
            },
            isDefault: {
                type: Boolean,
                required: true
            },
            isLogo: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                icon: {
                    isUploaded: false,
                    link: '',
                    file: ''
                }
            }
        },
        created() {
            this.checkIconIsDefault();
        },

        mounted() {
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromEvents();
        },

        computed: {
            formattedLabel() {
                return this.label.split(' ')[0];
            }
        },

        methods: {
            checkIconIsDefault() {
                //Imitate isUploaded step
                this.icon.isUploaded = !this.isDefault;
                this.icon.link = this.link;
            },

            subscribeToEvents() {
                this.emitter.on('updateUploadIcon', this.updateUploadIcon);
            },

            unsubscribeFromEvents() {
                this.emitter.off('updateUploadIcon', this.updateUploadIcon);
            },

            openModalUploadIcon() {
                const selectedElement = {
                    id: this.id,
                    type: this.isLogo ? 'logo' : 'icon'
                };

                this.openModal({
                    name: 'modalUploadIcon',
                    selectedEl: selectedElement
                })
            },

            updateUploadIcon({ id, url, file }) {
                if (this.id === id) {
                    this.icon.isUploaded = true;
                    this.icon.link = url;
                    this.icon.file = file;
                    this.$emit('update', { id, url, file });
                }
            },

            clearUploadedIcon() {
                this.icon.isUploaded = false;
                this.icon.link = '';
                this.icon.file = '';
                this.$emit('clear', this.id);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .icon-upload {
        display: flex;
        flex-direction: column;
        width: 108px;

        &--is-logo {
            width: 246px;
        }

        &__icon {
            width: 50px;
            height: 50px;
            mask-size: cover;
            mask-repeat: no-repeat;
            mask-position: 50% 50%;
        }

        &__uploaded-icon {
            height: 50px;
            width: 50px;
            background-repeat: no-repeat;
            background-size: contain;
            background-color: transparent;
        }

        &__uploaded-logo, &__logo {
            height: 50px;
            width: 150px;
            background-repeat: no-repeat;
            background-size: contain;
            background-color: transparent;
            background-position: center center;
        }

        &__view {
            border: 1px dashed var(--app-border);
            border-radius: 13px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 28px;
            margin-bottom: 10px;
            position: relative;

            &--is-uploaded {
                border: 1px solid transparent;
                background: var(--app-menu-background);
            }
        }

        &__clear-btn {
            position: absolute;
            right: 10px;
            top: 10px;
            width: 15px;
            height: 15px;
        }
    }
</style>
