import i18n from "../localization/i18n"
const { t } = i18n.global;

const menuIconParams = {
    logo: {
        name: t('personalization.label.logo'),
        file: ''
    },
    buildings: {
        route: '/buildings',
        name: t('menu.buildings'),
        file: ''
    },
    contacts: {
        route: '/contacts',
        name: t('menu.contacts'),
        file: ''
    },
    documents: {
        route: '/documents',
        name: t('menu.documents'),
        file: ''
    },
    bids: {
        route: '/bids',
        name: t('menu.bids'),
        file: ''
    },
    services: {
        route: '/services',
        name: t('menu.services'),
        file: ''
    },
    notifications: {
        route: '/notifications',
        name: t('menu.notifications'),
        file: ''
    },
    polls: {
        route: '/polls',
        name: t('menu.polls'),
        file: ''
    },
    news: {
        route: '/news',
        name: t('menu.news'),
        file: ''
    },
    premises: {
        route: '/premises',
        name: t('menu.premises'),
        file: ''
    },
    users: {
        route: '/users',
        name: t('menu.users'),
        file: ''
    },
    cameras: {
        route: '/cameras',
        name: t('menu.cameras'),
        file: ''
    },
    personalization: {
        route: '/personalization',
        name: t('menu.personalization'),
        file: ''
    },
    admins: {
        route: '/admins',
        name: t('menu.admins'),
        file: ''
    },
    finance: {
        route: '/finance/main',
        name: t('menu.finance'),
        file: ''
    }
}

export {
    menuIconParams
}