<template>
    <div class="individual-content">
        <div class="element">
            <div class="element__panel"
                :style="{
                    'borderBottomColor': colorBorder,
                }"
            >
                <div class="element__title"
                    :style="{
                        'color': colorBasePrimary,
                    }"
                >
                    {{ $t('admins.title') }}
                </div>
                <div class="element__control">
                    <div class="element__add-btn"
                         :style="{
                            'background': `linear-gradient(90deg, ${colorBrandPurple} 0%, ${colorBrandIris} 100%)`
                         }"
                    >
                       {{ $t('common.buttons.addUser') }}
                    </div>
                </div>
            </div>
            <div class="element__content">
                <div class="element-table">
                    <div class="element-table__head"
                         :style="{
                            'color': colorBasePrimary,
                            'backgroundColor': colorBrandPink
                         }"
                    >
                        <div class="element-table__column element-table__user">
                            <div>{{ $t('admins.table.user') }}</div>
                            <div class="element-table__sort-btn">
                                <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.175 2.01367L5 5.83034L8.825 2.01367L10 3.18867L5 8.18867L0 3.18867L1.175 2.01367Z" fill="#2B3037"/>
                                </svg>
                            </div>
                        </div>
                        <div class="element-table__column element-table__created-date">
                            {{ $t('admins.table.createdDate') }}
                        </div>
                        <div class="element-table__column element-table__action">
                            {{ $t('admins.table.actions') }}
                        </div>
                    </div>
                    <div class="element-table__body">
                        <individual-element-row v-for="(element, index) in elements" :key="element.id"
                            :index="index"
                            :id="element.id"
                            :image="element.image"
                            :name="element.name"
                            :email="element.email"
                            :created-date="element.createdDate"
                            :color-base-primary="colorBasePrimary"
                            :color-table-background="colorTableBackground"
                            :color-border="colorBorder"
                            :color-btn-secondary="colorBtnSecondary"
                            :color-brand-pink="colorBrandPink"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import IndividualElementRow from "./IndividualElementRow";

    export default {
        name: "IndividualTemplateContent",

        components: {
            IndividualElementRow
        },

        props: {
            colorTableBackground: {
                type: String,
                required: true
            },
            colorBasePrimary: {
                type: String,
                required: true
            },
            colorBorder: {
                type: String,
                required: true
            },
            colorBtnSecondary: {
                type: String,
                required: true
            },
            colorBrandIris: {
                type: String,
                required: true
            },
            colorBrandPurple: {
                type: String,
                required: true
            },
            colorBrandPink: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                elements: [
                    {
                        id: 1,
                        image: 'profile1.png',
                        name: 'Віталій Кулініч',
                        email: 'vitaliy.kulinich@gmail.com',
                        createdDate: '21.03.2022'
                    },
                    {
                        id: 2,
                        image: '',
                        name: '',
                        email: 'user.admin@gmail.com',
                        createdDate: '21.03.2022'
                    },
                    {
                        id: 3,
                        image: 'profile2.png',
                        name: 'Олег Кушнір',
                        email: 'oleg.kushnir@gmail.com',
                        createdDate: '21.03.2022'
                    },
                    {
                        id: 4,
                        image: 'profile3.png',
                        name: 'Ігор Гріценко',
                        email: 'igor.grichenko@gmail.com',
                        createdDate: '21.03.2022'
                    },
                    {
                        id: 5,
                        image: 'profile4.png',
                        name: 'Олена Василенко',
                        email: 'olena.vasilenko@gmail.com',
                        createdDate: '21.03.2022'
                    },
                    {
                        id: 6,
                        image: 'profile5.png',
                        name: 'Сергій Саленко',
                        email: 'serg.salenko@gmail.com',
                        createdDate: '21.03.2022'
                    },
                    {
                        id: 7,
                        image: '',
                        name: 'Володимир Крамар',
                        email: 'volodymyr.cramar@gmail.com',
                        createdDate: '21.03.2022'
                    }
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>
    .individual-content {
        background-color: var(--system-white);
        flex-grow: 1;
        border-radius: 0px 13px 13px 0px;
    }

    .element {
        &__panel {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 50px;
            padding: 0px 15px;
            border-bottom: 1px solid;
        }

        &__add-btn {
            height: 36px;
            width: 100%;
            min-width: 120px;
            border-radius: 13px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            padding: 0px 10px;
            color: var(--system-white);
            white-space: nowrap;
        }

        &__title {
            font-size: 18px;
        }

        &__content {
            flex-grow: 1;
        }
    }
</style>

<style lang="scss">
    .element-table {
        &__head, &__body {
            display: flex;
            align-items: center;
        }

        &__body {
            flex-direction: column;
        }

        &__head {
            font-size: 12px;
            font-weight: 500;
            padding: 5px 15px;
            height: 40px;
            justify-content: flex-start;
        }

        &__user {
            width: 58%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__date {
            font-size: 10px;
        }

        &__sort-btn {
            transform: scale(0.7);
            transform-origin: center center;
            padding: 5px 40px 0px 0px;
        }

        &__created-date {
            width: 27%;
        }

        &__actions {
            width: 15%;
            display: flex;
            align-items: center;
        }

        &__row {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            font-size: 12px;
            padding: 5px 15px;
            transition: background 0.3s ease;
            border-bottom: 1px solid var(--app-border);
        }
    }

    .element-user {
        display: flex;
        align-items: center;

        &__image {
            margin-right: 6px;
            width: 35px;
            height: 35px;

            img {
                max-width: 100%;
                object-fit: cover;
                border-radius: 50%;
                border: 1px solid transparent;
                transition: border 0.2s;
            }
        }

        &__name {
            font-weight: 500;
        }

        &__email {
            font-size: 10px;
        }

        &__ph {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            width: 35px;
            height: 35px;
        }

        &__abbr {
            font-size: 16px;
            font-weight: 500;
            color: var(--system-white);
        }
    }

    .element-actions {
        &__btn {
            transform: scale(0.8);
            transform-origin: center center;
        }

        &__delete-btn, &__edit-btn {
            min-width: 24px;
            height: 24px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: background 0.3s;
        }

        &__delete-btn {
            margin-right: 8px;
            background: var(--system-red-10);
        }
    }
</style>