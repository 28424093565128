import { validatePositiveNumber } from "../common";
import i18n from "../../../src/localization/i18n"
const { t } = i18n.global;

const invoiceTemplateErrors = {
    name: t('validation.required'),
    price: t('validation.numberPositive'),
}

const contractTemplateErrors = {
    name: t('validation.required'),
    file: t('validation.required'),
}

function validateInvoiceTemplate(invoice) {
    let isValid = false;

    const errors = {
        name: '',
        price: ''
    }

    const isValidName = !!invoice.name;

    if (!isValidName) {
        errors.name = invoiceTemplateErrors.name;
    }

    const isValidPrice = validatePositiveNumber(invoice.price);

    if (!isValidPrice) {
        errors.price = invoiceTemplateErrors.price;
    }

    isValid = isValidName && isValidPrice;

    return { isValid, errors };
}

function validateContractTemplate(contract) {
    let isValid = false;

    const errors = {
        name: '',
        file: ''
    }

    const isValidName = !!contract.name;

    if (!isValidName) {
        errors.name = contractTemplateErrors.name;
    }

    const isValidFile = !!contract.file;

    if (!isValidFile) {
        errors.file = contractTemplateErrors.file;
    }

    isValid = isValidName && isValidFile;

    return { isValid, errors };
}

export {
    validateInvoiceTemplate,
    validateContractTemplate
}