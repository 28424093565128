<template>
    <div class="contract-templates" :style="{ 'maxHeight': maxHeight }">
        <div v-for="(contract, index) in contracts" :key="contract.id" class="contract-templates__card">
            <contract-template-card
                :index="index"
                :id="contract.id"
                :name="contract.name"
                :size="contract.size"
                :link="contract.link"
                @download="downloadInvoiceTemplate"
                @delete="deleteContractTemplate"
            />
        </div>
    </div>
</template>

<script>
    import ContractTemplateCard from "./ContractTemplateCard";

    export default {
        name: "ContractTemplatesTable",

        components: {
            ContractTemplateCard
        },

        props: {
            contracts: {
                type: Array,
                required: true
            },
            maxHeight: {
                type: String,
                required: true
            },
        },

        methods: {
            downloadInvoiceTemplate(id) {

            },

            deleteContractTemplate(id) {
                const contract = this.contracts.find((contract) => contract.id === id);

                const selectedElement = {
                    id: contract.id,
                    name: contract.name,
                    type: 'contractTemplate'
                };

                this.openModal({
                    name: 'modalConfirm',
                    selectedEl: selectedElement
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .contract-templates {
        display: flex;
        flex-wrap: wrap;
        overflow-y: auto;
        scrollbar-width: thin;
        padding-right: 30px;

        &__card {
            width: 50%;
            padding: 0 0 30px 30px;
        }
    }
</style>