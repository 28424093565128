<template>
    <div class="debit-credit" v-if="value !== -1">
        {{ formattedDebitCreditValue }}
    </div>
</template>

<script>
    import { formatCurrencyValue } from "../../../helpers/finance";

    export default {
        name: "AppDebitCreditValue",

        props: {
            value: {
                type: Number,
                required: true
            }
        },

        computed: {
            formattedDebitCreditValue() {
                const { balance, currency } = formatCurrencyValue(this.value);

                return `${balance} ${currency}`;
            },
        }
    }
</script>