<template>
    <div class="finance-contracts-table contract">
        <div v-if="isNoneSelected" class="table__head">
            <div class="table__column contract__control">
                <app-base-checkbox
                    :value="'selectAll'"
                    v-model="isSelectAll"
                />
            </div>
            <div class="table__column contract__number">
                <app-overflow-match-wrapper
                    :text="$t('finance.table.number')"
                />
            </div>
            <div class="table__column contract__start-date">
                <app-overflow-match-wrapper
                    :text="$t('finance.table.startDate')"
                />
            </div>
            <div class="table__column contract__state">
                <app-overflow-match-wrapper
                    :text="$t('finance.table.state')"
                />
            </div>
            <div class="table__column contract__end-date">
                <app-overflow-match-wrapper
                    :text="$t('finance.table.endDate')"
                />
            </div>
            <div class="table__column contract__tariff">
                <app-overflow-match-wrapper
                    :text="$t('finance.table.tariff')"
                />
            </div>
            <div class="table__column contract__user-name">
                <app-overflow-match-wrapper
                    :text="$t('finance.table.userName')"
                />
            </div>
            <div class="table__column contract__user-document">
                <app-overflow-match-wrapper
                    :text="$t('finance.table.userDocument')"
                />
            </div>
        </div>
        <div v-else class="table__head">
            <div class="table__column contract__control">
                <app-base-checkbox
                    :value="'selectAll'"
                    v-model="isSelectAll"
                />
            </div>
            <div class="table__column contract__panel">
                <div class="contract__selected">
                    {{ selectedQty }}
                </div>
                <div class="contract__send">
                    <finance-panel-btn
                        :type="'send'"
                        :variation="'contract'"
                        :is-disabled="!isAllHasEmail"
                        @send="sendContractsByEmail"
                    />
                </div>
                <div class="contract__delete">
                    <finance-panel-btn v-if="showDeleteBtn"
                        :type="'delete'"
                        :variation="'contract'"
                        @delete="openModalConfirm"
                    />
                </div>
            </div>
        </div>
        <div class="table__body" :style="{ 'maxHeight': maxHeight }" @scroll="handlerScrollBodyTable">
            <finance-contract-row v-for="contract in contracts" :key="contract.id"
                :id="contract.id"
                :name="contract.name"
                :link="contract.link"
                :start-date="contract.startDate"
                :state="contract.state"
                :end-date="contract.endDate"
                :tariff="contract.tariff"
                :user-id="contract.userId"
                :user-name="contract.userName"
                :user-document="contract.userDocument"
                :premises="contract.premises"
                :sub-string="searchSubString"
                @select-contract="updateSelectedContracts"
                @update="openModalContract"
            />
        </div>
    </div>
</template>

<script>
    import FinanceContractRow from "./FinanceContractRow";
    import FinancePanelBtn from "../FinancePanelBtn";
    import { djinAPI } from "../../../config/api";
    import { mapUsersToView } from "../../../api/mappers/users";

    export default {
        name: "FinanceContractsTable",

        components: {
            FinanceContractRow,
            FinancePanelBtn
        },

        props: {
            contracts: {
                type: Array,
                required: true
            },
            maxHeight: {
                type: String,
                required: true
            },
            searchSubString: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                isSelectAll: false,
                selectedContracts: [],
                selectedContractUsers: [],
                isAllHasEmail: false,
                panelState: false
            }
        },

        watch: {
            isSelectAll(newState) {
                this.emitter.emit('selectAll', newState);
            },

            selectedContracts: {
                handler(newState) {
                    this.getAllContractUsers(newState);
                },
                deep: true
            }
        },

        computed: {
            isNoneSelected() {
                return this.selectedContracts.length === 0;
            },

            selectedQty() {
                return `${this.$t('finance.actions.selected')} ${this.selectedContracts.length}`;
            },

            hashContracts() {
                const hash = {};

                this.contracts.forEach((contract) => {
                    if (!hash[contract.id]) {
                        hash[contract.id] = contract;
                    }
                })

                return hash;
            },

            showDeleteBtn() {
                let showBtn = true;

                this.selectedContracts.forEach((id) => {
                    if (this.hashContracts[id]) {
                        const contract = this.hashContracts[id];

                        if (contract.state === 1 || contract.state === 1) {
                            showBtn = false;
                        }
                    }
                });

                return showBtn;
            }
        },

        methods: {
            updateActionPanelState(state) {
                this.panelState = state;
            },

            getAllContractUsers(contractIds) {
                const users = [];
                const array = [];

                contractIds.forEach((id) => {
                    const contract = this.contracts.find((contract) => contract.id === id);
                    array.push(this.getSpecificUser(contract.userId, users));
                })

                Promise.all(array).then(() => {
                    this.isAllHasEmail = users.every((user) => user.email);
                });
            },

            getSpecificUser(id , users) {
                return djinAPI.get(`/users/${id}`)
                    .then((result) => {
                        const user = mapUsersToView([result.data])[0];
                        users.push(user);
                    })
            },

            sendContractsByEmail() {
                const contracts = {
                    contracts: this.selectedContracts
                }

                djinAPI.post('finance/contracts/email', contracts)
                    .then(() => {

                    })
            },

            updateSelectedContracts(id) {
                if (this.selectedContracts.includes(id)) {
                    this.selectedContracts = this.selectedContracts.filter((contractId) => contractId !== id);
                } else {
                    this.selectedContracts.push(id);
                }
            },

            openModalConfirm() {
                const selectedElement = {
                    id: this.selectedContracts,
                    type: 'contracts'
                };

                this.openModal({
                    name: 'modalConfirm',
                    selectedEl: selectedElement
                })
            },

            openModalContract(id) {
                const contract = this.contracts.find((contract) => contract.id === id);

                this.openModal({
                    name: 'modalContract',
                    selectedEl: contract
                })
            }
        }
    }
</script>