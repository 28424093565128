<template>
    <app-modal-wrapper>
        <div class="modal-premises-autocomplete">
            <form @submit.prevent="generatePremisesAutoFill" class="premises-autocomplete">
                <h4>{{ $t('premises.autocomplete.title') }}</h4>
                <div class="premises-autocomplete__building">
                    <app-base-select
                        :label="$t('premises.autocomplete.buildings.label')"
                        :place-holder="$t('premises.autocomplete.buildings.ph')"
                        :is-searchable="true"
                        :selected-option="selectedBuilding"
                        :options="buildings"
                        :type="'text'"
                        :error-message="errors.building"
                        @select="selectBuilding"
                    />
                </div>
                <div class="premises-autocomplete__entrance">
                    <app-base-select ref="entrances"
                        :label="$t('premises.autocomplete.entrances.label')"
                        :place-holder="$t('premises.autocomplete.entrances.ph')"
                        :selected-option="selectedEntrance"
                        :options="entrances"
                        :type="'text'"
                        :required="false"
                        @select="selectEntrance"
                    />
                </div>
                <div class="premises-autocomplete__type">
                    <app-base-select
                        :label="$t('premises.autocomplete.premiseType.label')"
                        :place-holder="$t('premises.autocomplete.premiseType.ph')"
                        :selected-option="selectedType"
                        :options="types"
                        :type="'text'"
                        :error-message="errors.type"
                        @select="selectType"
                    />
                </div>
                <div class="premises-autocomplete__floors floors">
                    <div class="app__label">
                        {{ $t('premises.autocomplete.floors.label') }}
                    </div>
                    <div class="floors__info">
                        <div class="floors__details">
                            <div class="floors__input">
                                <div class="floors__label"
                                     :class="{
                                        'floors__label--is-over': isOpenedFloorSelect
                                    }"
                                >
                                    {{ $t('premises.autocomplete.from') }}
                                </div>
                                <app-base-select ref="minFloors"
                                    :place-holder="$t('premises.autocomplete.floors.from.ph')"
                                    :selected-option="selectedMinFloor"
                                    :options="floors"
                                    :type="'text'"
                                    :error-message="errors.minFloor"
                                    @focus="selectIsOpened"
                                    @close="selectIsClosed"
                                    @select="selectMinFloor"
                                />
                            </div>
                        </div>
                        <div class="floors__details">
                            <div class="floors__input">
                                <div class="floors__label"
                                     :class="{
                                        'floors__label--is-over': isOpenedFloorSelect
                                     }"
                                >
                                    {{ $t('premises.autocomplete.to') }}
                                </div>
                                <app-base-select ref="maxFloors"
                                    :place-holder="$t('premises.autocomplete.floors.to.ph')"
                                    :selected-option="selectedMaxFloor"
                                    :options="floors"
                                    :type="'text'"
                                    :error-message="errors.maxFloor"
                                    @focus="selectIsOpened"
                                    @close="selectIsClosed"
                                    @select="selectMaxFloor"
                                />
                            </div>
                        </div>
                    </div>
                    <div v-if="errors.floors" class="floors__error">
                        {{ errors.floors }}
                    </div>
                </div>
                <div class="premises-autocomplete__qty">
                    <app-base-input
                        v-model="qty"
                        :label="$t('premises.autocomplete.premiseQty.label')"
                        :place-holder="$t('premises.autocomplete.premiseQty.ph')"
                        :error-message="errors.qty"
                    />
                </div>
                <div class="premises-autocomplete__numbering">
                    <app-base-input
                        v-model="numbering"
                        :label="$t('premises.autocomplete.premiseNumbering.label')"
                        :place-holder="$t('premises.autocomplete.premiseNumbering.ph')"
                        :error-message="errors.numbering"
                    />
                </div>
                <div class="premises-autocomplete__control">
                    <app-base-btn
                        :size="'full'"
                        :variation="'cancel'"
                        :text="$t('common.buttons.cancel')"
                        @action="closeModalPremisesAutocomplete"
                    />
                    <app-base-btn
                        :type="'submit'"
                        :size="'full'"
                        :variation="'save'"
                        :text="$t('common.buttons.generate')"
                    />
                </div>
            </form>
        </div>
    </app-modal-wrapper>
</template>

<script>
    import { clearDependentSelects } from "../../../helpers/selects";
    import { premiseTypes } from "../../../constants/premises";
    import { mapGetters } from "vuex";
    import { validateAutoFillPremises } from "../../../validations/premise";
    import { mapAutoFillPremisesToSend } from "../../../api/mappers/premises";
    import { djinAPI } from "../../../config/api";
    import { premisesToastsMixin } from "../../../mixins/toasts/premises";

    export default {
        name: "ModalPremisesAutocomplete",

        mixins: [
            premisesToastsMixin
        ],

        data() {
            return {
                selectedBuilding: null,
                selectedEntrance: null,
                selectedType: null,
                selectedMinFloor: null,
                selectedMaxFloor: null,
                isOpenedFloorSelect: false,
                qty: '',
                numbering: '',

                errors: {
                    building: '',
                    type: '',
                    minFloor: '',
                    maxFloor: '',
                    floors: '',
                    qty: '',
                    numbering: ''
                }
            }
        },

        computed: {
            ...mapGetters({
                buildings: 'buildings/mappedBuildings'
            }),

            entrances() {
                return this.selectedBuilding?.entrances || [];
            },

            floors() {
                return this.selectedBuilding?.floors || [];
            },

            types() {
                return premiseTypes;
            }
        },

        methods: {
            generatePremisesAutoFill() {
                this.clearAutoFillPremises();
                const { isValid, errors } = validateAutoFillPremises(
                    this.selectedBuilding,
                    this.selectedType,
                    this.selectedMinFloor,
                    this.selectedMaxFloor,
                    this.qty,
                    this.numbering
                );
                this.errors = errors;

                if (isValid) {
                    const autoPremises = {
                        building: this.selectedBuilding.id,
                        type: this.selectedType.id,
                        minFloor: this.selectedMinFloor.id,
                        maxFloor: this.selectedMaxFloor.id,
                        qty: this.qty,
                        numbering: this.numbering
                    }

                    if (this.selectedEntrance) {
                        autoPremises.entrance = this.selectedEntrance.id;
                    }

                    this.createAutoFillPremises(autoPremises);
                }
            },

            createAutoFillPremises(autoPremises) {
                const autoFillPremises =  mapAutoFillPremisesToSend(autoPremises);

                djinAPI.post('/premises/auto', autoFillPremises)
                    .then(() => {
                        this.closeModalPremisesAutocomplete();
                        this.showCreatePremiseToast();
                        this.emitter.emit('updatePremises');
                    })
            },

            clearAutoFillPremises() {
                for (let key in this.errors) {
                    this.errors[key] = '';
                }
            },

            selectBuilding(building) {
                this.selectedBuilding = building;
                this.clearDependentFields();
            },

            selectEntrance(entrance) {
                this.selectedEntrance = entrance;
            },

            selectType(type) {
                this.selectedType = type;
            },

            selectMinFloor(minFloor) {
                this.selectedMinFloor = minFloor;
            },

            selectMaxFloor(maxFloor) {
                this.selectedMaxFloor = maxFloor;
            },

            clearDependentFields() {
                const dependentSelects = this.$refs;
                clearDependentSelects(dependentSelects);
            },

            selectIsOpened() {
                this.isOpenedFloorSelect = true;
            },

            selectIsClosed() {
                this.isOpenedFloorSelect = false;
            },

            closeModalPremisesAutocomplete() {
                this.closeModal('modalPremisesAutocomplete');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-premises-autocomplete {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 574px;
        max-height: 80vh;
        overflow-y: auto;
        scrollbar-width: thin;
    }

    .premises-autocomplete {
        h4 {
            margin-bottom: 30px;
        }

        &__building, &__entrance, &__type,
        &__floors, &__qty {
            margin-bottom: 15px;
        }

        &__floors {
            display: flex;
            flex-direction: column;
        }

        &__control {
            display: flex;
            margin-top: 45px;

            button:first-child {
                margin-right: 30px;
            }
        }
    }

    .floors {
        display: flex;
        flex-direction: column;

        &__info {
            display: flex;
        }

        &__details {
            flex-basis: 50%;

            &:first-child {
                margin-right: 30px;
            }
        }

        &__input {
            position: relative;
        }

        &__label {
            position: absolute;
            top: 12px;
            left: 15px;
            z-index: 10;
            height: 24px;
            width: 40px;
            border-right: 1px solid var(--app-border);
            font-size: 16px;
            line-height: 24px;

            &--is-over {
                z-index: 100;
            }
        }

        &__error {
            margin-top: 10px;
            font-size: 14px;
            color: var(--system-red);
        }
    }
</style>

<style lang="scss">
    .floors__input {
        .multiselect__tags {
            padding-left: 60px !important;
        }
    }
</style>