<template>
    <div class="premise-users-table premise-users">
        <div class="table__head">
            <div class="table__column premise-users__name">
                {{ $t('users.table.user') }}
            </div>
            <div class="table__column premise-users__email">
                {{ $t('users.table.email') }}
            </div>
            <div class="table__column premise-users__phone">
                {{ $t('users.table.phone') }}
            </div>
            <div class="table__column premise-users__document-id">
                {{ $t('users.table.numberId') }}
            </div>
            <div class="table__column premise-users__is-owner">
                {{ $t('users.table.owner') }}
            </div>
            <div class="table__column premise-users__is-registered">
                {{ $t('users.table.registered') }}
            </div>
        </div>
        <div class="table__body">
            <premise-user-row v-for="user in users" :key="user.id"
                :id="user.id"
                :surname="user.surname"
                :name="user.name"
                :middle-name="user.middleName"
                :email="user.email"
                :phone="user.phone"
                :document-id="user.documentId"
                :is-owner="user.isOwner"
                :is-registered="user.isRegistered"
            />
        </div>
    </div>
</template>

<script>
    import PremiseUserRow from "./PremiseUserRow";

    export default {
        name: "PremiseUsersTable",

        components: {
            PremiseUserRow
        },

        props: {
            users: {
                type: Array,
                required: true
            }
        }
    }
</script>

<style lang="scss">
    .premise-users {
        &__name {
            width: 24%;
        }

        &__email {
            width: 24%;
        }

        &__phone {
            width: 15%;
        }

        &__document-id {
            width: 15%;
        }

        &__is-owner {
            width: 11%;
        }

        &__is-registered {
            width: 11%;
        }
    }
</style>