<template>
    <div class="users__table user">
        <div class="table__head">
            <div class="table__column user__groups">
                <app-overflow-match-wrapper
                    :text="$t('users.table.group')"
                />
            </div>
            <div class="table__column user__buildings">
                <app-overflow-match-wrapper
                    :text="$t('users.table.building')"
                />
            </div>
            <div class="table__column user__premises">
                <app-overflow-match-wrapper
                    :text="$t('users.table.premise')"
                />
            </div>
            <div class="table__column user__fullName user__fullName--is-head">
                <app-overflow-match-wrapper
                    :text="$t('users.table.fullName')"
                />
            </div>
            <div class="table__column user__owner">
                <app-overflow-match-wrapper
                    :text="$t('users.table.owner')"
                />
            </div>
            <div class="table__column user__registered">
                <app-overflow-match-wrapper
                    :text="$t('users.table.registered')"
                />
            </div>
            <div class="table__column user__email">
                <app-overflow-match-wrapper
                    :text="$t('users.table.email')"
                />
            </div>
            <div class="table__column user__phone">
                <app-overflow-match-wrapper
                    :text="$t('users.table.phone')"
                />
            </div>
            <div class="table__column user__actions">
                <app-overflow-match-wrapper
                    :text="$t('users.table.actions')"
                />
            </div>
        </div>
        <div class="table__body" :style="{ 'maxHeight': maxHeight }" @scroll="handlerScrollBodyTable">
            <user-row v-for="user in users" :key="user.id"
                :id="user.id"
                :access-link="user.accessLink"
                :access-code="user.accessCode"
                :premises="user.premises"
                :surname="user.surname"
                :name="user.name"
                :middle-name="user.middleName"
                :email="user.email"
                :phone="user.phone"
                :state="user.state"
                :sub-string="searchSubString"
                :scroll-position="scrollPosition"
                @update="updateUser"
            />
        </div>
    </div>
</template>

<script>
    import UserRow from "./UserRow";
    import { scrollPositionMixin } from "../../mixins/scrollPosition";

    export default {
        name: "UsersTable",

        components: {
            UserRow
        },

        props: {
            users: {
                type: Array,
                required: true
            },
            maxHeight: {
                type: String,
                required: true
            },
            searchSubString: {
                type: String,
                required: true
            }
        },

        mixins: [ scrollPositionMixin ],

        methods: {
            updateUser(id) {
                const user = this.users.find((user) => user.id === id);

                this.openModal({
                    name: 'modalUser',
                    selectedEl: user
                })
            }
        }
    }
</script>

<style lang="scss">
    .user {
        &__groups {
            width: 12%;
        }

        &__group, &__building, &__premise {
            height: 26px;
            line-height: 26px;

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }

        &__buildings {
            width: 12%;
        }

        &__premises {
            width: 14%;
        }

        &__fullName {
            width: 15%;
            font-weight: 500;
        }

        &__owner {
            width: 9%;
        }


        &__is-owner-check, &__is-registered-check {
            width: 26px;
            height: 26px;

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }

        &__registered {
            width: 8%;
        }

        &__email {
            width: 13%;
        }

        &__phone {
            width: 10%;
        }

        &__actions {
            width: 7%;
            position: relative;
        }

        &__action-control {
            width: 30px;
            height: 30px;
            position: relative;
        }

        &__action-wrapper {
            position: absolute;
            right: 35px;
            top: 15px;
            background: var(--system-white);
            box-shadow: 0 16px 32px rgba(35, 23, 5, 0.26);
            border-radius: 13px;
            padding: 7px 0;
            z-index: 10;
        }

        &__info {
            display: flex;
            align-items: center;
        }

        &__state {
            width: 20px;
            height: 20px;
            margin-left: 5px;
        }
    }
</style>