<template>
    <button type="button" class="text-btn"  @click="handlerClick"
        :class="{
            'text-btn--is-not-main-color': isNotMainColor
        }"
    >
        <slot/>
        <span>{{ text }}</span>
    </button>
</template>

<script>
    export default {
        name: "AppTextBtn",

        props: {
            text: {
                type: String,
                required: true
            },

            isNotMainColor: {
                type: Boolean,
                default: false
            }
        },

        methods: {
            handlerClick() {
                this.$emit('action');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .text-btn {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            color: var(--brand-main);
            font-weight: 500;
            font-size: 16px;
            white-space: nowrap;
        }

        &--is-not-main-color {
            span {
                color: var(--app-base-primary);
            }
        }
    }
</style>

<style lang="scss">
    .text-btn {
        svg {
            margin-right: 10px;
            margin-bottom: 1px;
        }
    }
</style>