<template>
    <button type="button" class="collapse-btn" @click.stop="handlerClick"
        :class="{
            'collapse-btn--is-active': isActive
        }"
    >
        <svg class="arrow" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.825 1.8252L5 5.64186L1.175 1.8252L0 3.0002L5 8.0002L10 3.0002L8.825 1.8252Z"/>
        </svg>
    </button>
</template>

<script>
    export default {
        name: "AppCollapseBtn",

        props: {
            defaultIsActive: {
                type: Boolean,
                default: false
            }
        },

        created() {
            this.init();
        },

        data() {
            return {
                isActive: false
            }
        },

        methods: {
            init() {
                this.isActive = this.defaultIsActive;
            },

            handlerClick() {
                this.isActive = !this.isActive;
                this.$emit('collapse');
            }
        }
    }
</script>

<style lang="scss">
    .arrow {
        path {
            fill: var(--app-base-primary);
        }
    }

    .collapse-btn {
        width: 10px;
        min-width: 10px;
        height: 10px;
        transition: .25s;
        display: flex;
        align-items: center;
        justify-content: center;

        &--is-active {
            transform: scaleY(-1);

            .arrow {
                path {
                    fill: var(--brand-main);
                }
            }
        }
    }
</style>