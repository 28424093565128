<template>
    <app-modal-wrapper>
        <div class="modal-transfer-rights">
            <div v-if="currentStep === 0" class="transfer-rights-step-1">
                <div class="transfer-rights-step-1__form">
                    <h4>{{ $t('admins.transfer.step1.title') }}</h4>
                    <div class="transfer-rights-step-1__control">
                        <app-base-btn
                            :size="'full'"
                            :variation="'cancel'"
                            :text="$t('common.buttons.cancel')"
                            @action="previousStep"
                        />
                        <app-base-btn
                            :type="'submit'"
                            :size="'full'"
                            :variation="'save'"
                            :text="$t('common.buttons.continue')"
                            @action="nextStep"
                        />
                    </div>
                </div>
            </div>
            <div v-if="currentStep === 1" class="transfer-rights-step-2">
                <form @submit.prevent="transferRights" class="transfer-rights-step-2__form">
                    <h4>{{ $t('admins.transfer.step2.title') }}</h4>
                    <div class="transfer-rights-step-2__user-select">
                        <app-base-select
                            :label="$t('admins.transfer.step2.label')"
                            :is-searchable="false"
                            :selected-option="selectedAdmin"
                            :options="admins"
                            :type="'admin'"
                            @select="selectAdmin"
                        />
                    </div>
                    <div class="transfer-rights-step-2__control">
                        <app-base-btn
                            :size="'full'"
                            :variation="'cancel'"
                            :text="$t('common.buttons.cancel')"
                            @action="previousStep"
                        />
                        <app-base-btn
                            :type="'submit'"
                            :size="'full'"
                            :variation="'save'"
                            :text="$t('common.buttons.transfer')"
                        />
                    </div>
                </form>
            </div>
        </div>
    </app-modal-wrapper>
</template>

<script>
    import { djinAPI } from "../../../config/api";
    import { mapAdminsToView } from "../../../api/mappers/admins";

    export default {
        name: "ModalAdminTransfer",

        data() {
            return {
                currentStep: 0,
                selectedAdmin: null,
                admins: []
            }
        },

        created() {
            this.getAllAdmins();
        },

        methods: {
            getAllAdmins() {
                djinAPI.get('/admins')
                    .then((result) => {
                        const admins = result.data;
                        this.admins = mapAdminsToView(admins);
                    })
            },

            nextStep() {
                this.currentStep += 1;
            },

            previousStep() {
                if (this.currentStep === 0) {
                    this.closeModal('modalAdminTransfer');
                } else {
                    this.currentStep -= 1;
                }
            },

            transferRights() {
                if (this.selectedAdmin) {
                    const id = this.selectedAdmin.id;
                    const username = this.selectedAdmin.username;

                    djinAPI.patch(`/admins/${id}`, {
                        isMaster: true
                    })
                        .then(() => {
                            this.emitter.emit('updateMe', username);
                            this.closeModal('modalAdminTransfer');
                        })
                }
            },

            selectAdmin(admin) {
                this.selectedAdmin = admin;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-transfer-rights {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        max-width: 574px;
        width: 100%;
    }

    .transfer-rights-step-1, .transfer-rights-step-2 {
        h4 {
            margin-bottom: 30px;
        }

        &__control {
            display: flex;
            margin-top: 45px;

            button:first-child {
                margin-right: 30px;
            }
        }
    }
</style>
