<template>
    <div class="table__row user-row" @click="handlerClick">
        <div class="table__column user__groups">
            <div v-for="premise in premises" :key="premise.id" class="user__group">
                <app-overflow-match-wrapper v-if="premise.buildingGroup"
                    :text="premise.buildingGroup.name"
                    :sub-text="subString"
                    :is-match="true"
                    :scroll-position="scrollPosition"
                />
            </div>
        </div>
        <div class="table__column user__buildings">
            <div v-for="premise in premises" :key="premise.id" class="user__building">
                <app-overflow-match-wrapper
                    :text="premise.building.name"
                    :sub-text="subString"
                    :is-match="true"
                    :scroll-position="scrollPosition"
                />
            </div>
        </div>
        <div class="table__column user__premises">
            <div v-for="premise in premises" :key="premise.id" class="user__premise">
                <app-overflow-match-wrapper
                    :text="premise.premise.name"
                    :sub-text="subString"
                    :is-match="true"
                    :scroll-position="scrollPosition"
                />
            </div>
        </div>
        <div class="table__column user__fullName">
            <app-overflow-match-wrapper
                :text="fullName"
                :sub-text="subString"
                :is-match="true"
                :scroll-position="scrollPosition"
            />
<!--            <div class="user__info">-->
<!--                &lt;!&ndash;TODO: NEED TO LINE&ndash;&gt;-->
<!--                <div v-if="state" class="user__state">-->
<!--                    <svg v-if="state === 1" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                        <path d="M17.4267 9L15.495 6.79125L15.7642 3.87L12.9063 3.22083L11.41 0.6875L8.71837 1.84333L6.02671 0.6875L4.53046 3.21292L1.67254 3.85417L1.94171 6.78333L0.0100403 9L1.94171 11.2087L1.67254 14.1379L4.53046 14.7871L6.02671 17.3125L8.71837 16.1488L11.41 17.3046L12.9063 14.7792L15.7642 14.13L15.495 11.2087L17.4267 9ZM7.20629 12.7367L4.19796 9.72042L5.36962 8.54875L7.20629 10.3933L11.8375 5.74625L13.0092 6.91792L7.20629 12.7367Z" fill="var(&#45;&#45;app-border)"/>-->
<!--                    </svg>-->
<!--                    <svg v-else width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                        <path d="M17.4267 9L15.495 6.79125L15.7642 3.87L12.9063 3.22083L11.41 0.6875L8.71837 1.84333L6.02671 0.6875L4.53046 3.21292L1.67254 3.85417L1.94171 6.78333L0.0100403 9L1.94171 11.2087L1.67254 14.1379L4.53046 14.7871L6.02671 17.3125L8.71837 16.1488L11.41 17.3046L12.9063 14.7792L15.7642 14.13L15.495 11.2087L17.4267 9ZM7.20629 12.7367L4.19796 9.72042L5.36962 8.54875L7.20629 10.3933L11.8375 5.74625L13.0092 6.91792L7.20629 12.7367Z" fill="var(&#45;&#45;brand-main)"/>-->
<!--                    </svg>-->
<!--                </div>-->
<!--            </div>-->
        </div>
        <div class="table__column user__owner">
            <div v-for="premise in premises" :key="premise.id" class="user__is-owner-check">
                <svg v-if="premise.isOwner" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.574707" y="0.5" width="25" height="25" rx="6" fill="var(--app-menu-background)"/>
                    <g clip-path="url(#clip0_1210_15659)">
                        <path d="M10.3474 16.4091L6.76789 12.8295L5.57471 14.0227L10.3474 18.7955L20.5747 8.56818L19.3815 7.375L10.3474 16.4091Z" fill="var(--app-base-primary)"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_1210_15659">
                            <rect width="15" height="15" fill="white" transform="translate(5.57471 5.5)"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>
        </div>
        <div class="table__column user__registered">
            <div v-for="premise in premises" :key="premise.id" class="user__is-registered-check">
                <svg v-if="premise.isRegistered" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.574707" y="0.5" width="25" height="25" rx="6" fill="var(--app-menu-background)"/>
                    <g clip-path="url(#clip0_1210_15659)">
                        <path d="M10.3474 16.4091L6.76789 12.8295L5.57471 14.0227L10.3474 18.7955L20.5747 8.56818L19.3815 7.375L10.3474 16.4091Z" fill="var(--app-base-primary)"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_1210_15659">
                            <rect width="15" height="15" fill="white" transform="translate(5.57471 5.5)"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>
        </div>
        <div class="table__column user__email">
            <app-overflow-match-wrapper
                :text="email"
                :sub-text="subString"
                :is-match="true"
                :scroll-position="scrollPosition"
            />
        </div>
        <div class="table__column user__phone">
            <app-overflow-match-wrapper
                :text="formattedPhone"
                :scroll-position="scrollPosition"
            />
        </div>
        <div class="table__column user__actions">
            <div class="user__action-control" ref="actionControlPanel">
                <app-circle-btn
                    @action="toggleActionPanel"
                >
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.25 10.0754C10.775 10.0754 10.35 10.2629 10.025 10.5566L5.56875 7.96289C5.6 7.81914 5.625 7.67539 5.625 7.52539C5.625 7.37539 5.6 7.23164 5.56875 7.08789L9.975 4.51914C10.3125 4.83164 10.7563 5.02539 11.25 5.02539C12.2875 5.02539 13.125 4.18789 13.125 3.15039C13.125 2.11289 12.2875 1.27539 11.25 1.27539C10.2125 1.27539 9.375 2.11289 9.375 3.15039C9.375 3.30039 9.4 3.44414 9.43125 3.58789L5.025 6.15664C4.6875 5.84414 4.24375 5.65039 3.75 5.65039C2.7125 5.65039 1.875 6.48789 1.875 7.52539C1.875 8.56289 2.7125 9.40039 3.75 9.40039C4.24375 9.40039 4.6875 9.20664 5.025 8.89414L9.475 11.4941C9.44375 11.6254 9.425 11.7629 9.425 11.9004C9.425 12.9066 10.2437 13.7254 11.25 13.7254C12.2563 13.7254 13.075 12.9066 13.075 11.9004C13.075 10.8941 12.2563 10.0754 11.25 10.0754ZM11.25 2.52539C11.5938 2.52539 11.875 2.80664 11.875 3.15039C11.875 3.49414 11.5938 3.77539 11.25 3.77539C10.9062 3.77539 10.625 3.49414 10.625 3.15039C10.625 2.80664 10.9062 2.52539 11.25 2.52539ZM3.75 8.15039C3.40625 8.15039 3.125 7.86914 3.125 7.52539C3.125 7.18164 3.40625 6.90039 3.75 6.90039C4.09375 6.90039 4.375 7.18164 4.375 7.52539C4.375 7.86914 4.09375 8.15039 3.75 8.15039ZM11.25 12.5379C10.9062 12.5379 10.625 12.2566 10.625 11.9129C10.625 11.5691 10.9062 11.2879 11.25 11.2879C11.5938 11.2879 11.875 11.5691 11.875 11.9129C11.875 12.2566 11.5938 12.5379 11.25 12.5379Z" fill="#2B3037"/>
                    </svg>
                </app-circle-btn>
                <div v-if="showActionPanel" class="user__action-wrapper">
                    <app-action-btn
                            :id="'email'"
                            :text="$t('users.user.actions.email')"
                            @action="handlerClickActionBtn"
                    >
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22.9167 6.25033C22.9167 5.10449 21.9792 4.16699 20.8334 4.16699H4.16671C3.02087 4.16699 2.08337 5.10449 2.08337 6.25033V18.7503C2.08337 19.8962 3.02087 20.8337 4.16671 20.8337H20.8334C21.9792 20.8337 22.9167 19.8962 22.9167 18.7503V6.25033ZM20.8334 6.25033L12.5 11.4587L4.16671 6.25033H20.8334ZM20.8334 18.7503H4.16671V8.33366L12.5 13.542L20.8334 8.33366V18.7503Z" fill="var(--app-border)"/>
                        </svg>
                    </app-action-btn>
                    <app-action-btn
                            :id="'sms'"
                            :text="$t('users.user.actions.sms')"
                            @action="handlerClickActionBtn"
                    >
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.16671 4.16634H20.8334V16.6663H5.38546L4.16671 17.8851V4.16634ZM4.16671 2.08301C3.02087 2.08301 2.09379 3.02051 2.09379 4.16634L2.08337 22.9163L6.25004 18.7497H20.8334C21.9792 18.7497 22.9167 17.8122 22.9167 16.6663V4.16634C22.9167 3.02051 21.9792 2.08301 20.8334 2.08301H4.16671ZM6.25004 12.4997H18.75V14.583H6.25004V12.4997ZM6.25004 9.37467H18.75V11.458H6.25004V9.37467ZM6.25004 6.24967H18.75V8.33301H6.25004V6.24967Z" fill="var(--app-border)"/>
                        </svg>
                    </app-action-btn>
                    <app-action-btn
                            :id="'copyInvite'"
                            :text="$t('users.user.actions.copyInvite')"
                            @action="handlerClickActionBtn"
                    >
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.1875 1.04199H4.68746C3.54163 1.04199 2.60413 1.97949 2.60413 3.12533V17.7087H4.68746V3.12533H17.1875V1.04199ZM20.3125 5.20866H8.85413C7.70829 5.20866 6.77079 6.14616 6.77079 7.29199V21.8753C6.77079 23.0212 7.70829 23.9587 8.85413 23.9587H20.3125C21.4583 23.9587 22.3958 23.0212 22.3958 21.8753V7.29199C22.3958 6.14616 21.4583 5.20866 20.3125 5.20866ZM20.3125 21.8753H8.85413V7.29199H20.3125V21.8753Z" fill="var(--app-border)"/>
                        </svg>
                    </app-action-btn>
                    <app-action-btn
                            :id="'copyCode'"
                            :text="$t('users.user.actions.copyCode')"
                            @action="handlerClickActionBtn"
                    >
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.75 8.85417H17.7083V6.77083C17.7083 3.89583 15.375 1.5625 12.5 1.5625C9.62496 1.5625 7.29163 3.89583 7.29163 6.77083V8.85417H6.24996C5.10413 8.85417 4.16663 9.79167 4.16663 10.9375V21.3542C4.16663 22.5 5.10413 23.4375 6.24996 23.4375H18.75C19.8958 23.4375 20.8333 22.5 20.8333 21.3542V10.9375C20.8333 9.79167 19.8958 8.85417 18.75 8.85417ZM9.37496 6.77083C9.37496 5.04167 10.7708 3.64583 12.5 3.64583C14.2291 3.64583 15.625 5.04167 15.625 6.77083V8.85417H9.37496V6.77083ZM18.75 21.3542H6.24996V10.9375H18.75V21.3542ZM12.5 18.2292C13.6458 18.2292 14.5833 17.2917 14.5833 16.1458C14.5833 15 13.6458 14.0625 12.5 14.0625C11.3541 14.0625 10.4166 15 10.4166 16.1458C10.4166 17.2917 11.3541 18.2292 12.5 18.2292Z" fill="var(--app-border)"/>
                        </svg>
                    </app-action-btn>
                    <app-action-btn
                            :id="'copyLink'"
                            :text="$t('users.user.actions.copyLink')"
                            @action="handlerClickActionBtn"
                    >
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.7084 7.29199H13.5417V9.37533H17.7084C19.4271 9.37533 20.8334 10.7816 20.8334 12.5003C20.8334 14.2191 19.4271 15.6253 17.7084 15.6253H13.5417V17.7087H17.7084C20.5834 17.7087 22.9167 15.3753 22.9167 12.5003C22.9167 9.62533 20.5834 7.29199 17.7084 7.29199ZM11.4584 15.6253H7.29171C5.57296 15.6253 4.16671 14.2191 4.16671 12.5003C4.16671 10.7816 5.57296 9.37533 7.29171 9.37533H11.4584V7.29199H7.29171C4.41671 7.29199 2.08337 9.62533 2.08337 12.5003C2.08337 15.3753 4.41671 17.7087 7.29171 17.7087H11.4584V15.6253ZM8.33337 11.4587H16.6667V13.542H8.33337V11.4587Z" fill="var(--app-border)"/>
                        </svg>
                    </app-action-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { usersToastsMixin } from "../../mixins/toasts/users";

    export default {
        name: "UserRow",

        props: {
            id: {
                type: String,
                required: true
            },
            accessLink: {
                type: String,
                required: true
            },
            accessCode: {
                type: String,
                required: true
            },
            premises: {
                type: Array,
                required: true
            },
            surname: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            middleName: {
                type: String,
                required: true
            },
            email: {
                type: String,
                required: true
            },
            phone: {
                type: Object,
                required: true
            },
            state: {
                type: Number,
                required: true
            },
            subString: {
                type: String,
                required: true
            },
            scrollPosition: {
                type: Number,
                required: true
            }
        },

        mixins: [
            usersToastsMixin
        ],

        data() {
            return {
                showActionPanel :false
            }
        },

        mounted() {
            document.addEventListener("mousedown", this.handlerClickOutside);
        },

        unmounted() {
            document.removeEventListener("mousedown", this.handlerClickOutside);
        },

        computed: {
            formattedPhone() {
                return this.phone.number
                    ? `${this.phone.code}${this.phone.number}`
                    : '';
            },

            fullName() {
                return `${this.surname} ${this.name} ${this.middleName}`;
            }
        },

        methods: {
            handlerClick() {
                if (!this.showActionPanel) {
                    this.$emit('update', this.id);
                }
            },

            handlerClickActionBtn(id) {
                switch (id) {
                    case 'email':
                        break;
                    case 'sms':
                        break;
                    case 'copyInvite':
                        break;
                    case 'copyCode':
                        this.copyToClipBoard('code', this.accessCode);
                        break;
                    case 'copyLink':
                        this.copyToClipBoard('link', this.accessLink);
                        break;
                }

                this.closeActionPanel();
            },

            copyToClipBoard(type, text) {
                const vm = this;
                let isSuccess = false;

                if (navigator.clipboard) {
                    navigator.clipboard.writeText(text)
                        .then(() => {
                            isSuccess = true;
                        })
                        .finally(() => {
                            vm.showCopyToClipBoardToast(type);
                        });
                } else {
                    isSuccess = document.execCommand('copy', false, text);
                    vm.showCopyToClipBoardToast(type);
                }
            },

            showCopyToClipBoardToast(type) {
                switch (type) {
                    case 'code':
                        this.showCopyCodeToClipboardToast();
                        break;
                    case 'link':
                        this.showCopyLinkToClipboardToast();
                        break;
                }
            },

            toggleActionPanel() {
                this.showActionPanel = !this.showActionPanel;
            },

            handlerClickOutside(event) {
                if (!this.$refs.actionControlPanel.contains(event.target)) {
                    this.closeActionPanel();
                }
            },

            closeActionPanel() {
                //Need it to keep the modal user from opening, after click outside, but it doesn't work for another row
                setTimeout(() => {
                    this.showActionPanel = false;
                }, 100)
            }
        }
    }
</script>

<style scoped lang="scss">
    .user-row {
        min-height: unset;

        &:hover {
            cursor: pointer;
        }
    }
</style>