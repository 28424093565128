<template>
    <div class="finance-statements-table"
         :style="{
            'maxHeight': maxHeight
         }"
    >
        <div class="finance-statements-table__head finance-statements-table__head--is-sticky">
            <div class="finance-statements-table__details">
                <div class="table__f-column table__f-column--is-start statement__name statement__name--is-head">
                    <app-overflow-match-wrapper
                        :text="$t('finance.statements.type.enterprise')"
                    />
                </div>
                <div class="table__f-column statement__e-period statement__e-period--is-head">
                    <app-overflow-match-wrapper
                        :text="$t('finance.statements.table.period.start')"
                    />
                </div>
                <div class="table__f-column statement__e-period statement__e-period--is-head">
                    <app-overflow-match-wrapper
                        :text="$t('finance.statements.table.period.turnover')"
                    />
                </div>
                <div class="table__f-column statement__e-period statement__e-period--is-head">
                    <app-overflow-match-wrapper
                        :text="$t('finance.statements.table.period.end')"
                    />
                </div>
            </div>
            <div class="finance-statements-table__operations">
                <div class="table__f-column table__f-column--is-start statement__name statement__name--is-head"></div>
                <div class="table__f-column statement__e-period">
                    <div class="table__f-column table__f-column--is-debit statement__debit">
                        <app-overflow-match-wrapper
                            :text="$t('finance.statements.table.debit')"
                        />
                    </div>
                    <div class="table__f-column table__f-column--is-credit statement__credit">
                        <app-overflow-match-wrapper
                            :text="$t('finance.statements.table.credit')"
                        />
                    </div>
                </div>
                <div class="table__f-column statement__e-period">
                    <div class="table__f-column table__f-column--is-debit statement__debit">
                        <app-overflow-match-wrapper
                            :text="$t('finance.statements.table.debit')"
                        />
                    </div>
                    <div class="table__f-column table__f-column--is-credit statement__credit">
                        <app-overflow-match-wrapper
                            :text="$t('finance.statements.table.credit')"
                        />
                    </div>
                </div>
                <div class="table__f-column statement__e-period">
                    <div class="table__f-column table__f-column--is-debit statement__debit">
                        <app-overflow-match-wrapper
                            :text="$t('finance.statements.table.debit')"
                        />
                    </div>
                    <div class="table__f-column table__f-column--is-credit statement__credit">
                        <app-overflow-match-wrapper
                            :text="$t('finance.statements.table.credit')"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="table__body">
            <finance-enterprise-statement-row
                :level="0"
                :name="enterprise.name"
                :end-of-period="enterprise.endOfPeriod"
                :turnover="enterprise.turnover"
                :start-of-period="enterprise.startOfPeriod"
                :children="enterprise.children"
            />
        </div>
    </div>
</template>

<script>
    import FinanceEnterpriseStatementRow from "../elements/FinanceEnterpriseStatementRow";

    export default {
        name: "FinanceEnterpriseStatementsTable",

        components: {
            FinanceEnterpriseStatementRow
        },

        props: {
            enterprise: {
                type: Object,
                required: true
            },
            maxHeight: {
                type: String,
                required: true
            }
        }
    }
</script>