<template>
    <div class="individual-menu">
        <div class="individual-menu__item" v-for="item in items" :key="item.id">
            <admin-menu-btn
                :in-active-color="colorBaseSecondary"
                :active-color="colorBrandMain"
                :is-individual-template="true"
                :is-active="item.id === 'admins'"
                :icon="item.link"
                :name="item.name"
            />
        </div>
    </div>
</template>

<script>
    import AdminMenuBtn from "../../common/local/AdminMenuItem";

    export default {
        name: "IndividualTemplateMenu",

        components: {
            AdminMenuBtn
        },

        props: {
            items: {
                type: Array,
                required: true
            },
            colorBaseSecondary: {
                type: String,
                required: true
            },
            colorBrandMain: {
                type: String,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>
    .individual-menu {
        flex-grow: 1;
        padding: 10px 15px;
        display: flex;
        flex-direction: column;

        &__item {
            display: flex;
            align-items: center;
            font-size: 12px;
            margin-bottom: 8px;
        }
    }
</style>