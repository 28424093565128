import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

const variants = [
    {
        id: 'none',
        label: t('users.user.invite.variants.none')
    },
    {
        id: 'sms',
        label: t('users.user.invite.variants.sms')
    },
    {
        id: 'email',
        label: t('users.user.invite.variants.email')
    },
    {
        id: 'both',
        label: t('users.user.invite.variants.both')
    }
]

const variables = {
    name: {
        text: t('invite.variables.name')
    },
    building: {
        text: t('invite.variables.building')
    },
    linkAccess: {
        text: t('invite.variables.linkAccess')
    },
    codeAccess: {
        text: t('invite.variables.codeAccess')
    },
    linkApp: {
        text: t('invite.variables.linkApp')
    }
}

export {
    variants,
    variables
}