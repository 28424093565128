<template>
    <div class="panel-btn" @click.stop="actionControlPanel" ref="controlPanel" :class="{ 'panel-btn--is-disabled': isDisabled }">
        <div class="panel-btn__text">
            {{ panelBtn.text }}
        </div>
        <div v-if="!isDeleteBtn && !isDisabled" class="panel-btn__btn">
            <div class="panel-control-btn" :class="{ 'panel-control-btn--is-active': showControlPanel }">
                <svg class="arrow" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.825 1.8252L5 5.64186L1.175 1.8252L0 3.0002L5 8.0002L10 3.0002L8.825 1.8252Z"/>
                </svg>
            </div>
        </div>
        <div v-if="showControlPanel" class="panel-btn__control">
            <div v-for="action in panelBtn.actions" :key="action.id">
                <app-action-btn
                    :id="action.id"
                    :text="action.name"
                    @action="selectAction"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { financePanelBtnTypes } from "../../constants/finance";

    export default {
        name: "FinancePanelBtn",

        props: {
            type: {
                type: String,
                required: true,
                validator(type) {
                    return ['send', 'delete'].includes(type);
                }
            },
            variation: {
                type: String,
                required: true,
                validator(type) {
                    return ['contract', 'invoice'].includes(type);
                }
            },
            isDisabled: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                showControlPanel: false
            }
        },

        mounted() {
            document.addEventListener("mousedown", this.handlerClickOutside);
        },

        unmounted() {
            document.removeEventListener("mousedown", this.handlerClickOutside);
        },

        computed: {
            isDeleteBtn() {
                return this.type === 'delete';
            },

            panelBtn() {
                return financePanelBtnTypes[this.type];
            }
        },

        methods: {
            actionControlPanel() {
                if (!this.isDeleteBtn) {
                    this.showControlPanel = !this.showControlPanel;
                } else {
                    this.$emit('delete');
                }
            },

            handlerClickOutside(event) {
                if (this.showControlPanel) {
                    if (!this.$refs.controlPanel.contains(event.target)) {
                        this.showControlPanel = false;
                    }
                }
            },

            selectAction(id) {
                if (id === '1') {
                    this.$emit('send');
                }
                this.showControlPanel = false;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .panel-btn {
        display: flex;
        align-items: center;
        position: relative;
        color: var(--brand-main);

        &:hover {
            cursor: pointer;
        }

        &--is-disabled {
            pointer-events: none;
            color: var(--app-base-secondary);
        }

        &__text {
            font-weight: 500;
        }

        &__btn {
            margin-left: 10px;
        }

        &__control {
            background: var(--system-white);
            box-shadow: 0px 16px 32px rgba(35, 23, 5, 0.26);
            border-radius: 13px;
            padding: 8px 0;
            position: absolute;
            top: 24px;
            right: 0;
            z-index: 10;
            font-weight: 400;
        }
    }
</style>

<style lang="scss">
    .arrow {
        path {
            fill: var(--app-base-primary);
        }
    }

    .panel-control-btn {
        width: 10px;
        min-width: 10px;
        height: 10px;
        transition: .25s;
        display: flex;
        align-items: center;
        justify-content: center;

        &--is-active {
            transform: scaleY(-1);

            .arrow {
                path {
                    fill: var(--brand-main);
                }
            }
        }
    }
</style>