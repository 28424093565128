<template>
    <div class="review-premise__row">
        <div class="review-premise__details">
            <div class="review-premise__info">
                <div class="review-premise__name">
                    <div class="app__label">
                        {{ itemNumber }}
                    </div>
                    <div class="review-premise__block">
                        {{ name }}
                    </div>
                </div>
                <div class="review-premise__balance">
                    <div class="app__label">
                        {{ $t('finance.review.balance') }}
                    </div>
                    <div class="review-premise__block">
                        {{ formattedBalance }}
                    </div>
                </div>
            </div>
        </div>
        <div class="review-premise__details">
            <div class="review-premise__currency">
                {{ $t('finance.data.currency') }}
            </div>
            <app-base-input
                v-model="amount"
                :label="$t('finance.review.amount')"
                :error-message="errors.amount"
            />
        </div>
    </div>
</template>

<script>
    import { formatCurrencyValue } from "../../../helpers/finance";
    import { validateReviewInvoicePosition } from "../../../validations/finance/invoices";

    export default {
        name: "FinanceReviewPremiseRow",

        props: {
            index: {
                type: Number,
                required: true
            },
            length: {
                type: Number,
                required: true
            },
            id: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            balance: {
                type: Number,
                required: true
            }
        },

        data() {
            return {
                amount: '',

                errors: {
                    amount: ''
                }
            }
        },

        mounted() {
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromEvents();
        },

        computed: {
            itemNumber() {
                return `${this.$t('finance.review.invoiceItem')} ${this.index + 1}`;
            },

            formattedBalance() {
                const { balance, currency } = formatCurrencyValue(this.balance);

                return `${balance} ${currency}`;
            },

            isLastReviewPremise() {
                return this.index === this.length - 1;
            }
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('validateReviewPosition', this.validateAndUpdateReviewPosition);
                this.emitter.on('setErrorInvoiceReviewPremise', this.setErrorReviewPremise);
            },

            unsubscribeFromEvents() {
                this.emitter.off('validateReviewPosition', this.validateAndUpdateReviewPosition);
                this.emitter.off('setErrorInvoiceReviewPremise', this.setErrorReviewPremise);
            },

            validateAndUpdateReviewPosition() {
                this.clearReviewPositionErrors();
                const { isValid, errors } = validateReviewInvoicePosition(this.amount);
                this.errors = errors;
                this.$emit('update', this.id, isValid, this.amount);
            },

            clearReviewPositionErrors() {
                for (let key in this.errors) {
                    this.errors[key] = '';
                }
            },

            setErrorReviewPremise() {
                this.errors.amount = this.isLastReviewPremise
                    ? this.$t('validation.invoice.amount')
                    : 'isNotShowError'
                ;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .review-premise {
        &__row {
            display: flex;
            padding: 30px 0;
            border-top: 1px solid var(--app-border);

            &:last-child {
                padding-bottom: unset;
            }
        }

        &__details {
            position: relative;
            flex-basis: 50%;

            &:first-child {
                margin-right: 30px;
            }
        }

        &__info {
            display: flex;
            justify-content: space-between;
        }

        &__block {
            height: 48px;
            display: flex;
            align-items: center;
        }

        &__balance {
            min-width: 140px;
            padding-right: 30px;
        }

        &__currency {
            height: 48px;
            display: flex;
            align-items: center;
            position: absolute;
            left: 15px;
            top: 26px;
            z-index: 10;
        }
    }
</style>

<style lang="scss">
    .review-premise__details {
        .field__entry {
            input {
                padding-left: 50px !important;
            }
        }
    }
</style>