<template>
    <div class="premises">
        <div class="premises__panel">
            <div class="premises__settings">
                <div class="premises__title">
                    {{ $t('premises.title') }}
                </div>
            </div>
            <div class="premises__actions">
                <div class="premises__action-btn">
                    <app-base-btn
                        :size="'padding'"
                        :variation="'base'"
                        :text="$t('premises.autocompleteBtn')"
                        @action="openModalPremisesAutocomplete"
                    />
                </div>
                <app-add-btn
                    @open-modal="openModalPremise"
                />
            </div>
        </div>
        <div class="premises__control">
            <div class="premises__filters">
                <premises-filters
                    :qty="premises.length"
                    @update="updateQueryParams"
                />
            </div>
        </div>
        <div class="premises__content" ref="premises">
            <div v-if="showTable" class="premises__table">
                <premises-table
                    :premises="premises"
                    :max-height="maxHeightScroll"
                    :search-sub-string="searchSubString"
                />
            </div>
            <div v-if="showEmpty" class="premises__empty">
                <app-empty-content
                    :content-type="'premises'"
                    @open-modal="openModalPremise"
                />
            </div>
            <div v-if="showNoResults" class="premises__empty">
                <app-no-results
                    @clear="clearQueryParams"
                />
            </div>
            <div v-if="isLoaded" class="premises__loader">
                <app-loader
                    :size="'large'"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import PremisesFilters from "./PremisesFilters";
    import PremisesTable from "./PremisesTable";
    import { djinAPI } from "../../config/api";
    import { maxHeightMixin } from "../../mixins/calcMaxHeight";
    import { premisesToastsMixin } from "../../mixins/toasts/premises";
    import { mapPremisesToView } from "../../api/mappers/premises";

    export default {
        name: "PremisesControl",

        components: {
            PremisesFilters,
            PremisesTable
        },

        mixins: [
            maxHeightMixin,
            premisesToastsMixin
        ],

        data() {
            return {
                premises: [],
                isLoaded: false,
                params: null
            }
        },

        created() {
            this.getPremises();
        },

        mounted() {
            this.subscribeToResize('premises');
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromResize();
            this.unsubscribeFromEvents();
        },

        computed: {
            showTable() {
                return this.premises.length && !this.isLoaded;
            },

            showEmpty() {
                return !this.premises.length && !this.isLoaded && !this.isExistQueryParams;
            },

            showNoResults() {
                return !this.premises.length && !this.isLoaded && this.isExistQueryParams;
            },

            isExistQueryParams() {
                return this.searchSubString || this.filterSubString;
            },

            searchSubString() {
                return this.params?.params?.match ? this.params?.params?.match : '';
            },

            filterSubString() {
                return this.params?.params?.filter ? this.params?.params?.filter : '';
            }
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('updatePremises', this.updatePremises);
                this.emitter.on('deletePremise', this.deletePremise);
            },

            unsubscribeFromEvents() {
                this.emitter.off('updatePremises', this.updatePremises);
                this.emitter.off('deletePremise', this.deletePremise);
            },

            getPremises() {
                this.isLoaded = true;

                djinAPI.get('/premises', this.params)
                    .then((result) => {
                        const premises = result.data;
                        this.premises = mapPremisesToView(premises);

                        this.emitter.emit('updateShowQty');
                        this.$nextTick(() => {
                            this.calcMaxHeight();
                        });
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.isLoaded = false;
                        }, 500)
                    })
            },

            deletePremise(id) {
                const premise = this.premises.find((premise) => premise.id === id);

                djinAPI.delete(`/premises/${id}`)
                    .then(() => {
                        this.closeModalConfirm();
                        this.closeModalPremise();
                        this.showDeletePremiseToast(premise.name);
                        this.updatePremises();
                    })
            },

            openModalPremisesAutocomplete() {
                this.openModal({
                    name: 'modalPremisesAutocomplete'
                })
            },

            openModalPremise() {
                this.openModal({
                    name: 'modalPremise'
                })
            },

            closeModalConfirm() {
                this.closeModal('modalConfirm');
            },

            closeModalPremise() {
                this.closeModal('modalPremise');
            },

            updateQueryParams(params) {
                this.params = { ...params };
                this.getPremises();
            },

            clearQueryParams() {
                this.emitter.emit('clearQueryParams');
            },

            updatePremises() {
                this.getPremises();
            }
        }
    }
</script>