import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

const MAX_SIZE = 2000000;

const errors = {
    size: t('validation.upload.size'),
    type: t('validation.upload.type')
}

function checkSizeFile(file) {
    return file.size < MAX_SIZE;
}

function checkTypeFile(file, acceptTypes) {
    return acceptTypes.includes(file.type);
}

function acceptTypesToString(acceptTypes) {
    return acceptTypes.map((type) => type.substring(type.lastIndexOf('/') + 1)).join(', ');
}

function validateFile(file, acceptTypes, checkType) {
    let isValid = false;
    let isValidType = true;
    let errorMsg = '';

    if (checkType) {
        isValidType = checkTypeFile(file, acceptTypes);
    }

    const isValidSize = checkSizeFile(file);

    if (isValidType) {
        if (isValidSize) {
            isValid = true;
            // this.convertFile(file);
        } else {
            errorMsg = errors.size;
        }
    } else {
        const acceptTypesString = acceptTypesToString(acceptTypes);

        errorMsg = `${errors.type} ${acceptTypesString}`;
    }

    return { isValid, errorMsg };
}

export {
    validateFile
}