<template>
    <div class="table__row building-row" @click="handlerClick">
        <div class="table__column building__name building__name--is-building">
            <app-overflow-match-wrapper
                :text="name"
                :sub-text="subString"
                :is-match="true"
                :scroll-position="scrollPosition"
            />
        </div>
        <div class="table__column building__logo"
            :class="{
                'building__logo--is-more-16' : isMore16,
                'building__logo--is-more-32': isMore32
            }"
        >
            <img v-if="logo" :src="logo" alt="logo">
            <div v-if="!logo && !logoIsLoaded" class="building__logo-name">
                <div>{{ firstPart }}</div>
                <div v-if="!!secondPart" class="building__logo--is-second-part">
                    {{ secondPart }}
                </div>
            </div>
            <app-loader v-if="!logo && logoIsLoaded"
                :size="'small'"
            />
        </div>

        <div class="table__column building__city">
            <app-overflow-match-wrapper
                :text="city"
                :sub-text="subString"
                :is-match="true"
                :scroll-position="scrollPosition"
            />
        </div>
        <div class="table__column building__address">
            <app-overflow-match-wrapper
                :text="address"
                :sub-text="subString"
                :is-match="true"
                :scroll-position="scrollPosition"
            />
        </div>
        <div class="table__column building__entrances">
            {{ entrances }}
        </div>
        <div class="table__column building__floors">
            {{ floors }}
        </div>
        <div class="table__column building__living">
            <premises-type-qty v-if="premisesIsExist"
                :type="'living'"
                :qty="premiseTypes.living"
            />
        </div>
        <div class="table__column building__apartments">
            <premises-type-qty v-if="premisesIsExist"
                :type="'apts'"
                :qty="premiseTypes.apts"
            />
        </div>
        <div class="table__column building__commercial">
            <premises-type-qty v-if="premisesIsExist"
                :type="'commercial'"
                :qty="premiseTypes.commercial"
            />
        </div>
        <div class="table__column building__technical">
            <premises-type-qty v-if="premisesIsExist"
                :type="'technical'"
                :qty="premiseTypes.technical"
            />
        </div>
        <div class="table__column building__parking">
            <premises-type-qty v-if="premisesIsExist"
                :type="'parking'"
                :qty="premiseTypes.parking"
            />
        </div>
    </div>
</template>

<script>
    import PremisesTypeQty from "./PremisesTypeQty";
    import i18n from "../../localization/i18n";
    import { getImageFromUrl } from "../../api/requests/common";

    export default {
        name: "BuildingRow",

        components: {
            PremisesTypeQty
        },

        props: {
            id: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            logoUrl: {
                type: String,
                required: true
            },
            location: {
                type: Object,
                required: true
            },
            entrances: {
                type: String,
                required: true
            },
            floors: {
                type: Number,
                required: true
            },
            premiseTypes: {
                type: Object,
                required: true
            },
            cities: {
                type: Array,
                required: true
            },
            subString: {
                type: String,
                required: true
            },
            scrollPosition: {
                type: Number,
                required: true
            }
        },

        data() {
            return {
                logo: '',
                logoIsLoaded: false,
                isMore16: false,
                isMore32: false,
                firstPart: '',
                secondPart: ''
            }
        },

        created() {
            this.initBuilding();
        },

        computed: {
            premisesIsExist() {
                return !!this.premiseTypes;
            },

            langCode() {
                return i18n.global.locale;
            },

            city() {
                return this.cities.find((city) => city.id === this.location.cityId)?.name[this.langCode];
            },

            address() {
                return this.location.address[this.langCode]
                    ? this.location.address[this.langCode]
                    : `${this.$t('buildings.building.address.unknown')}, 0`;
            }
        },

        methods: {
            initBuilding() {
                if (this.logoUrl) {
                    this.getBuildingLogo(this.logoUrl);
                } else {
                    this.logo = '';
                    this.modifyBuildingLogoName();
                }
            },

            async getBuildingLogo(imageUrl) {
                this.logoIsLoaded = true;

                await getImageFromUrl(imageUrl)
                    .then((image) => {
                        this.logo = image;
                    })
                    .finally(() => {
                        this.logoIsLoaded = false;
                    })
            },

            modifyBuildingLogoName() {
                if (this.name.length <= 16) {
                    this.firstPart = this.name;
                } else {
                    if (this.name.length >= 64) {
                        this.firstPart = this.name.substring(0, 32);
                        this.secondPart = `${this.name.substring(32, 62)}...`;
                        this.isMore32 = true;
                    } else if (this.name.length <= 32) {
                        this.firstPart = this.name;
                    } else {
                        this.firstPart = this.name.substring(0, 32);
                        this.secondPart = this.name.substring(32, this.name.length);
                        this.isMore32 = true;
                    }

                    this.isMore16 = true;
                }
            },

            handlerClick() {
                this.$emit('update', this.id);
            }
        }
    }
</script>

<style lang="scss">
    .building-row {
        position: relative;
        background: var(--app-table-background);

        &__item {
            position: absolute;
            top: 0;
            left: 35px;
        }

        &:hover {
            cursor: pointer;
        }
    }
</style>
