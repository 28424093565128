<template>
    <div class="menu-btn" @mouseenter="isHover = true" @mouseleave="isHover = false"
         :class="{
            'menu-btn--is-individual-template': isIndividualTemplate
         }"
    >
        <div class="menu-btn__icon"
            :style="{
                 'maskImage': `url(${icon})`,
                 'backgroundColor': currentColor
            }"
        />
        <div v-if="isNotAdaptive" class="menu-btn__name"
             :style="{
                'color': currentColor
             }"
        >
            {{ name }}
        </div>
    </div>
</template>

<script>
    export default {
        name: "AdminMenuBtn",

        props: {
            inActiveColor: {
                type: String,
                required: true
            },
            activeColor: {
                type: String,
                required: true
            },
            isIndividualTemplate: {
                type: Boolean,
                default: false
            },
            isViewTemplate: {
                type: Boolean,
                default: false
            },
            isActive: {
                type: Boolean,
                default: false
            },
            icon: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            isNotAdaptive: {
                type: Boolean,
                default: true
            },
        },

        data() {
            return {
                isHover: false,
                hoverColor: 'var(--brand-main)'
            }
        },

        computed: {
            currentColor() {
                return this.isActive
                    ? this.activeColor
                    : this.isHover && !this.isIndividualTemplate && !this.isViewTemplate
                    ? this.hoverColor
                    : this.inActiveColor;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .menu-btn {
        display: flex;
        align-items: center;

        &__icon {
            width: 25px;
            height: 25px;
            mask-size: cover;
            mask-repeat: no-repeat;
            mask-position: 50% 50%;
            transition: background-color 0.3s;
        }

        &__name {
            font-size: 16px;
            transition: color 0.3s;
            margin-left: 15px;
        }

        &--is-individual-template {
            .menu-btn__icon {
                width: 16px;
                height: 16px;
            }

            .menu-btn__name {
                margin-left: 6px;
            }

            .menu-btn__name {
                font-size: 12px;
            }
        }
    }
</style>