<template>
    <app-modal-wrapper>
        <div class="modal-sign-contract">
            <h4>{{ type.text }}</h4>
            <div class="modal-sign-contract__control">
                <app-base-btn
                    :variation="'cancel'"
                    :text="$t('common.buttons.cancel')"
                    @action="closeModalSignContract"
                />
                <app-base-btn
                    :variation="'save'"
                    :text="type.btn"
                    @action="signContract"
                />
            </div>
        </div>
    </app-modal-wrapper>
</template>

<script>
    import { types } from "../../../constants/confirm";
    import {djinAPI} from "../../../config/api";

    export default {
        name: "ModalSignContract",

        props: {
            selectedContract: {
                type: Object,
                required: true
            }
        },

        computed: {
            type() {
                return types['signContract'];
            }
        },

        methods: {
            signContract() {
                const id = this.selectedContract.id;
                const contract = {
                    status: 1
                }

                djinAPI.patch(`/finance/contracts/${id}`, contract)
                    .then(() => {
                        this.closeModalSignContract();
                        this.emitter.emit('updateContracts');
                    })

            },

            closeModalSignContract() {
                this.closeModal('modalSignContract');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-sign-contract {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 574px;

        &__control {
            display: flex;
            align-items: center;
            margin-top: 45px;

            button:not(:last-child) {
                margin-right: 30px;
            }
        }
    }
</style>