<template>
    <div class="admins-table admin">
        <div class="table__head">
            <div class="table__column admin__user">
                <app-overflow-match-wrapper
                    :text="$t('admins.table.user')"
                />
            </div>
            <div class="table__column admin__created-date">
                <app-overflow-match-wrapper
                    :text="$t('admins.table.createdDate')"
                />
            </div>
            <div class="table__column admin__phone">
                <app-overflow-match-wrapper
                    :text="$t('admins.table.phone')"
                />
            </div>
            <div class="table__column admin__email">
                <app-overflow-match-wrapper
                    :text="$t('admins.table.email')"
                />
            </div>
            <div class="table__column admin__company">
                <app-overflow-match-wrapper
                    :text="$t('admins.table.company')"
                />
            </div>
            <div class="table__column admin__position">
                <app-overflow-match-wrapper
                    :text="$t('admins.table.position')"
                />
            </div>
            <div class="table__column admin__actions">
                <app-overflow-match-wrapper
                    :text="$t('admins.table.actions')"
                />
            </div>
        </div>
        <div class="table__body" :style="{ 'maxHeight': maxHeight }" @scroll="handlerScrollBodyTable">
            <admin-row v-for="admin in admins" :key="admin.id"
                :id="admin.id"
                :is-master="admin.isMaster"
                :username="admin.username"
                :name="admin.name"
                :surname="admin.surname"
                :full-name="admin.fullName"
                :time="admin.time"
                :phone="admin.phone"
                :email="admin.email"
                :company="admin.company"
                :position="admin.position"
                :avatar-url="admin.avatar"
                :abbr-no-avatar="admin.abbrNoAvatar"
                :sub-string="searchSubString"
                :scroll-position="scrollPosition"
                @update="updateAdmin"
                @delete="deleteAdmin"
                @update-avatar="updateAdminAvatar"
            />
        </div>
    </div>
</template>

<script>
    import AdminRow from "./AdminRow";
    import { scrollPositionMixin } from "../../mixins/scrollPosition";

    export default {
        name: "AdminsTable",

        components: {
            AdminRow
        },

        props: {
            admins: {
                type: Array,
                required: true
            },
            maxHeight: {
                type: String,
                required: true
            },
            searchSubString: {
                type: String,
                required: true
            }
        },

        mixins: [ scrollPositionMixin ],

        methods: {
            updateAdmin(id) {
                this.$emit('update', id);
            },

            deleteAdmin(id) {
                this.$emit('delete', id);
            },

            updateAdminAvatar(id, url) {
                this.$emit('updateAvatar', id, url);
            }
        }
    }
</script>

<style lang="scss">
    .admin {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__user {
            width: 23%;
        }

        &__created-date {
            width: 13%;
        }

        &__phone {
            width: 13%;
        }

        &__email {
            width: 15%;
        }

        &__company {
            width: 14%;
        }

        &__position {
            width: 15%;


            &--is-row {
                font-weight: 700;
            }
        }

        &__actions {
            width: 7%;
        }
    }

    .admin-user {
        display: flex;
        align-items: center;

        &__avatar {
            margin-right: 10px;
            min-width: 50px;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                max-width: 100%;
                object-fit: cover;
                border-radius: 50%;
                border: 1px solid transparent;
                transition: border 0.2s;
            }
        }

        &__info {
            display: flex;
            flex-direction: column;
            width: calc(100% - 60px);
        }

        &__name {
            margin-bottom: 5px;
            font-size: 14px;
            font-weight: 600;
            color: var(--app-base-primary);
            transition: color 0.2s;
        }

        &__email {
            font-size: 12px;
            color: var(--app-base-primary);
        }

        &__avatar-ph {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            background: var(--brand-pink);
        }

        &__avatar-abbr {
            font-size: 16px;
            font-weight: 500;
            color: var(--system-white);
        }
    }

    .admin-actions {
        display: flex;
        align-items: center;

        &__delete-btn, &__edit-btn {
            min-width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: background 0.3s;
        }

        &__delete-btn {
            margin-right: 15px;
            background: var(--system-red10);

            &:hover {
                background: var(--system-red20);
            }
        }

        &__edit-btn {
            background: var(--app-btn-secondary);

            &:hover {
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), var(--app-btn-secondary);
            }
        }
    }
</style>
