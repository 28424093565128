<template>
    <button type="button" class="add-btn" @click=handlerClick>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.9999 9H11.0001V0.999939C11.0001 0.448059 10.552 0 9.99994 0C9.44806 0 9 0.448059 9 0.999939V9H0.999939C0.448059 9 0 9.44806 0 9.99994C0 10.552 0.448059 11.0001 0.999939 11.0001H9V18.9999C9 19.552 9.44806 20.0001 9.99994 20.0001C10.552 20.0001 11.0001 19.552 11.0001 18.9999V11.0001H18.9999C19.552 11.0001 20.0001 10.552 20.0001 9.99994C20.0001 9.44806 19.552 9 18.9999 9Z" fill="white"/>
        </svg>
    </button>
</template>

<script>
    export default {
        name: "AppAddBtn",

        methods: {
            handlerClick() {
                this.$emit('openModal');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .add-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        min-width: 48px;
        height: 48px;
        background: linear-gradient(90deg, var(--brand-purple) 0%, var(--brand-iris) 100%);
        box-shadow: 0px 10px 15px var(--app-shadow-btn);
        border-radius: 13px;
        position: relative;
        z-index: 1;

        //WE NEED THIS FOR TRANSITION LINEAR GRADIENT
        &:after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 13px;
            background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(90deg, var(--brand-purple) 0%, var(--brand-iris) 100%);
            transition: opacity 0.3s ease-out;
            opacity: 0;
            z-index: 2;
        }

        &:hover:after {
            opacity: 1;
        }

        svg {
            z-index: 3;
        }
    }
</style>