<template>
    <div class="table__row">
        <div class="table__column admin__user">
            <div class="admin-user">
                <div class="admin-user__avatar">
                    <app-loader
                        v-if="avatar.isLoaded"
                        :size="'small'"
                    />
                    <div v-else>
                        <img v-if="avatar.url" :src="avatar.url" alt="Avatar">
                        <div v-else class="admin-user__avatar-ph"
                        >
                            <div class="admin-user__avatar-abbr">
                                {{ abbrNoAvatar }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="admin-user__info">
                    <div v-if="fullName" class="admin-user__name">
                        <app-overflow-match-wrapper
                            :text="formattedName"
                            :sub-text="subString"
                            :is-match="true"
                            :scroll-position="scrollPosition"
                        />
                    </div>
                    <div class="admin-user__username">
                        <app-overflow-match-wrapper
                            :text="username"
                            :sub-text="subString"
                            :is-match="true"
                            :scroll-position="scrollPosition"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="table__column admin__created-date">
            {{ createdDate }}
        </div>
        <div class="table__column admin__phone">
            <app-overflow-match-wrapper
                :text="formattedPhone"
                :scroll-position="scrollPosition"
            />
        </div>
        <div class="table__column admin__email">
            <app-overflow-match-wrapper
                :text="email"
                :sub-text="subString"
                :is-match="true"
                :scroll-position="scrollPosition"
            />
        </div>
        <div class="table__column admin__company">
            <app-overflow-match-wrapper
                :text="company"
                :sub-text="subString"
                :is-match="true"
                :scroll-position="scrollPosition"
            />
        </div>
        <div class="table__column admin__position admin__position--is-row">
            <app-overflow-match-wrapper
                :text="position"
                :sub-text="subString"
                :is-match="true"
                :scroll-position="scrollPosition"
            />
        </div>
        <div class="table__column admin__actions">
            <div class="admin-actions">
                <div class="admin-actions__delete-btn">
                    <app-circle-btn
                        :is-delete="true"
                        @action="deleteAdmin"
                    >
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 5.625V11.875H5V5.625H10ZM9.0625 1.875H5.9375L5.3125 2.5H3.125V3.75H11.875V2.5H9.6875L9.0625 1.875ZM11.25 4.375H3.75V11.875C3.75 12.5625 4.3125 13.125 5 13.125H10C10.6875 13.125 11.25 12.5625 11.25 11.875V4.375Z" fill="#FF3B30"/>
                        </svg>
                    </app-circle-btn>
                </div>
                <div class="admin-actions__edit-btn">
                    <app-circle-btn
                        @action="updateAdmin"
                    >
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.87427 13.1256H4.21802L11.1305 6.21309L8.78677 3.86934L1.87427 10.7818V13.1256ZM3.12427 11.3006L8.78677 5.63809L9.36177 6.21309L3.69927 11.8756H3.12427V11.3006Z" fill="#2B3037"/>
                            <path d="M11.4805 2.05684C11.2368 1.81309 10.843 1.81309 10.5993 2.05684L9.45552 3.20059L11.7993 5.54434L12.943 4.40059C13.1868 4.15684 13.1868 3.76309 12.943 3.51934L11.4805 2.05684Z" fill="#2B3037"/>
                        </svg>
                    </app-circle-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { formatTimestampToDate } from "../../helpers/date";
    import { getImageFromUrl } from "../../api/requests/common";

    export default {
        name: "AdminRow",

        props: {
            id: {
                type: String,
                required: true
            },
            isMaster: {
                type: Boolean,
                required: true
            },
            username: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            surname: {
                type: String,
                required: true
            },
            fullName: {
                type: String,
                required: true
            },
            time: {
                type: Number,
                required: true
            },
            phone: {
                type: Object,
                required: true
            },
            email: {
                type: String,
                required: true
            },
            company: {
                type: String,
                required: true
            },
            position: {
                type: String,
                required: true
            },
            avatarUrl: {
                type: String,
                required: true
            },
            abbrNoAvatar: {
                type: String,
                required: true
            },
            subString: {
                type: String,
                required: true
            },
            scrollPosition: {
                type: Number,
                required: true
            }
        },

        data() {
            return {
                avatar: {
                    url: '',
                    isLoaded: false
                }
            }
        },

        created() {
            this.initAdmin();
        },

        computed: {
            createdDate() {
                return formatTimestampToDate(this.time);
            },

            formattedPhone() {
                return this.phone.number
                    ? `${this.phone.code}${this.phone.number}`
                    : '';
            },

            formattedName() {
                return !this.fullName
                    ? ''
                    : `${this.surname} ${this.name}`.trim();
            }
        },

        methods: {
            initAdmin() {
                if (this.avatarUrl) {
                    this.getAdminAvatar(this.avatarUrl);
                }
            },

            async getAdminAvatar(url) {
                this.avatar.isLoaded = true;

                await getImageFromUrl(url)
                    .then((image) => {
                        this.avatar.url = image;
                    })
                    .finally(() => {
                        this.avatar.isLoaded = false;
                    })
            },

            deleteAdmin() {
                this.$emit('delete', this.id, this.username);
            },

            updateAdmin() {
                this.$emit('update', this.id);
            }
        }
    }
</script>
