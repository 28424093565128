<template>
    <div class="finance-templates">
        <div class="finance-templates__head">
            <div class="finance-templates__contracts-head">
                <div class="finance-templates__head--is-contracts">
                    <h4>{{ $t('finance.templates.contracts.title') }}</h4>
                    <app-base-btn
                        :size="'padding'"
                        :variation="'save'"
                        :text="$t('finance.templates.contracts.btn')"
                        @action="openModalTemplateContract"
                    >
                        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.7916 9.04232H8.54165V15.2923H6.45831V9.04232H0.208313V6.95898H6.45831V0.708984H8.54165V6.95898H14.7916V9.04232Z" fill="white"/>
                        </svg>
                    </app-base-btn>
                </div>
            </div>
            <div class="finance-templates__invoices-head">
                <div class="finance-templates__head--is-invoices">
                    <h4>{{ $t('finance.templates.payments.title') }}</h4>
                    <app-add-btn
                        @open-modal="openModalTemplateInvoice"
                    />
                </div>
            </div>
        </div>
        <div class="finance-templates__content" ref="financeTemplates">
            <div class="finance-templates__contracts-content">
                <div v-if="showTableContracts">
                    <contract-templates-table
                        :contracts="contracts"
                        :max-height="maxHeightScroll"
                    />
                </div>
                <div v-if="showEmptyContracts" class="finance-templates__empty">
                    <app-empty-content
                        :is-small="true"
                        :content-type="'contractTemplates'"
                        @open-modal="openModalTemplateContract"
                    />
                </div>
                <div v-if="isLoaded" class="finance-templates__loader">
                    <app-loader
                        :size="'large'"
                    />
                </div>
            </div>
            <div class="finance-templates__invoices-content">
                <div v-if="showTableInvoices">
                    <invoice-templates-table
                        :invoices="invoices"
                        :max-height="maxHeightScroll"
                    />
                </div>
                <div v-if="showEmptyInvoices" class="finance-templates__empty">
                    <app-empty-content
                        :is-small="true"
                        :content-type="'invoiceTemplates'"
                        @open-modal="openModalTemplateInvoice"
                    />
                </div>
                <div v-if="isLoaded" class="finance-templates__loader">
                    <app-loader
                        :size="'large'"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FinanceContractCard from "./ContractTemplateCard";
    import FinancePaymentCard from "./InvoiceTemplateCard";
    import ContractTemplatesTable from "./ContractTemplatesTable";
    import InvoiceTemplatesTable from "./InvoiceTemplatesTable";
    import { maxHeightMixin } from "../../../mixins/calcMaxHeight";
    import { templatesToastsMixin } from "../../../mixins/toasts/finance/templates";
    import { djinAPI } from "../../../config/api";
    import { mapInvoiceTemplatesToView, mapContractTemplatesToView } from "../../../api/mappers/finance/templates";

    export default {
        name: "FinanceTemplatesControl",

        components: {
            InvoiceTemplatesTable,
            ContractTemplatesTable,
            FinanceContractCard,
            FinancePaymentCard
        },

        data() {
            return {
                isLoaded: false,
                contracts: [],
                invoices: []
            }
        },

        mixins: [
            maxHeightMixin,
            templatesToastsMixin
        ],

        created() {
            this.getTemplates();
        },

        mounted() {
            this.subscribeToResize('financeTemplates');
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromResize();
            this.unsubscribeFromEvents();
        },

        computed: {
            showTableContracts() {
                return !this.isLoaded && this.contracts.length;
            },

            showEmptyContracts() {
                return !this.isLoaded && !this.contracts.length;
            },

            showTableInvoices() {
                return !this.isLoaded && this.invoices.length;
            },

            showEmptyInvoices() {
                return !this.isLoaded && !this.invoices.length;
            }
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('updateTemplates', this.updateTemplates);
                this.emitter.on('deleteInvoiceTemplate', this.deleteInvoiceTemplate);
                this.emitter.on('deleteContractTemplate', this.deleteContractTemplate);
            },

            unsubscribeFromEvents() {
                this.emitter.off('updateTemplates', this.updateTemplates);
                this.emitter.off('deleteInvoiceTemplate', this.deleteInvoiceTemplate);
                this.emitter.off('deleteContractTemplate', this.deleteContractTemplate);
            },

            getTemplates() {
                this.isLoaded = true;

                djinAPI.get('/finance/templates')
                    .then((result) => {
                        const { contracts, invoices } = result.data;
                        this.contracts = mapContractTemplatesToView(contracts);
                        this.invoices = mapInvoiceTemplatesToView(invoices);
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.isLoaded = false;
                        }, 500)
                    })
            },

            updateTemplates() {
                this.getTemplates();
            },

            deleteInvoiceTemplate(id) {
                const invoice = this.invoices.find((invoice) => invoice.id === id);

                djinAPI.delete(`/finance/templates/invoices/${id}`)
                    .then(() => {
                        this.closeModalConfirm();
                        this.showDeleteInvoiceTemplateToast(invoice.name);
                        this.updateTemplates();
                    })
            },

            deleteContractTemplate(id) {
                const contract = this.contracts.find((contract) => contract.id === id);

                djinAPI.delete(`/finance/templates/contracts/${id}`)
                    .then(() => {
                        this.closeModalConfirm();
                        this.showDeleteContractTemplateToast(contract.name);
                        this.updateTemplates();
                    })
            },

            openModalTemplateContract() {
                this.openModal({
                    name: 'modalTemplateContract'
                })
            },

            openModalTemplateInvoice() {
                this.openModal({
                    name: 'modalTemplateInvoice'
                })
            },

            closeModalConfirm() {
                this.closeModal('modalConfirm');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .finance-templates {
        display: flex;
        flex-direction: column;
        height: 100%;

        h4 {
            font-weight: normal;
            align-self: flex-end;
        }

        &__contracts-head {
            width: 66.66%;
            display: flex;
        }

        &__invoices-head {
            width: 33.33%;
            display: flex;
        }

        &__contracts-content {
            width: 66.66%;
            height: 100%;
            padding-right: 30px;
        }

        &__invoices-content {
            width: 33.33%;
            height: 100%;
        }

        &__head {
            display: flex;
            width: 100%;
            height: 93px;
            padding: 15px 30px 30px 30px;


            &--is-contracts, &--is-invoices {
                display: flex;
                justify-content: space-between;
                width: 100%;
            }

            &--is-contracts {
                padding-right: 30px;
            }

            /*&--is-invoices {*/
            /*    padding-left: 30px;*/
            /*}*/
        }

        &__content {
            flex-grow: 1;
            display: flex;
        }
    }
</style>