<template>
    <div class="period-item">
        <div>{{ name }}</div>
        <div class="period-item__children-btn">
            <button type="button" @click="toggleChildrenPanel">
                <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1145_51761)">
                        <path d="M2 9.325L5.81667 5.5L2 1.675L3.175 0.5L8.175 5.5L3.175 10.5L2 9.325Z" fill="#A4AFBE"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_1145_51761">
                            <rect width="10" height="10" fill="white" transform="translate(0 10.5) rotate(-90)"/>
                        </clipPath>
                    </defs>
                </svg>
            </button>
        </div>
        <div v-if="showChildrenPanel" class="period-item__children">
            <div v-for="period in children" :key="period.id" class="period-item" @click="selectPeriod(period.id, period.name)">
                <div>{{ period.name }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AppPeriodItem",

        props: {
            id: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            children: {
                type: Array,
                required: true
            }
        },

        data() {
            return {
                showChildrenPanel: false
            }
        },

        mounted() {
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromEvents();
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('openChildrenPanel', this.checkClosePanel);
            },

            unsubscribeFromEvents() {
                this.emitter.off('openChildrenPanel', this.checkClosePanel);
            },

            toggleChildrenPanel() {
                this.showChildrenPanel = !this.showChildrenPanel;
                this.emitter.emit('openChildrenPanel', this.id);
            },

            checkClosePanel(id) {
                if (id !== this.id) {
                    this.showChildrenPanel = false;
                }
            },

            selectPeriod(id, name) {
                this.$emit('select', id, name, this.name);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .period-item {
        display: flex;
        justify-content: space-between;
        padding: 10px 15px;
        white-space: nowrap;
        position: relative;

        &__children {
            position: absolute;
            background: var(--system-white);
            box-shadow: 0 16px 32px rgba(35, 23, 5, 0.26);
            border-radius: 13px;
            padding: 8px 0;
            top: 0;
            right: -150px;
            z-index: 20;
            font-weight: 400;
            width: 160px;
        }

        &__children-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 16px;
            width: 16px;
            transition: transform .2s ease;
        }

        &:hover {
            cursor: pointer;
            background: var(--app-btn-secondary);
        }
    }
</style>