<template>
    <div class="field"
         :class="{
            'field--is-disabled': isDisabled
         }"
    >
        <div v-if="showLabel" class="app__label">
            {{ label }}
            <span class="field__optional">
                {{ optionalText }}
            </span>
        </div>
        <div class="field__entry"
             :class="{
                'field__entry--is-error' : isError,
                'field__entry--is-password' : isPassword
             }"
        >
            <input class="field__input" @keypress.enter.prevent
                :value="modelValue"
                :type="inputType"
                :placeholder="placeHolder"
                @input="$emit('update:modelValue', $event.target.value)"
            >
            <button v-if="isPassword && showPasswordIcon" type="button" @click="showPassword=!showPassword">
                <svg v-if="!showPassword" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.86328 6.14923L11.44 8.72592L11.4522 8.59095C11.4522 7.23715 10.352 6.13696 8.99825 6.13696L8.86328 6.14923Z" fill="#B6C3D3"/>
                    <path d="M8.99795 4.50103C11.2556 4.50103 13.088 6.33336 13.088 8.59102C13.088 9.11863 12.9816 9.6217 12.7976 10.0839L15.1902 12.4765C16.4254 11.4458 17.3988 10.1125 18 8.59102C16.5808 5.00003 13.0921 2.45605 8.99799 2.45605C7.85278 2.45605 6.75669 2.66054 5.73828 3.02864L7.50516 4.79141C7.96727 4.61146 8.47034 4.50103 8.99795 4.50103Z" fill="#B6C3D3"/>
                    <path d="M0.817983 2.27195L2.68301 4.13698L3.05521 4.50918C1.70552 5.56439 0.638037 6.96727 0 8.59097C1.41515 12.182 4.90798 14.7259 8.99797 14.7259C10.2659 14.7259 11.4765 14.4805 12.5849 14.0347L12.9326 14.3824L15.317 16.7709L16.36 15.7321L1.86093 1.229L0.817983 2.27195ZM5.34153 6.79139L6.60533 8.05519C6.56852 8.23107 6.54398 8.40692 6.54398 8.59097C6.54398 9.94476 7.64417 11.045 8.99797 11.045C9.18202 11.045 9.3579 11.0204 9.52968 10.9836L10.7935 12.2474C10.2495 12.5173 9.64421 12.681 8.99797 12.681C6.7403 12.681 4.90798 10.8486 4.90798 8.59097C4.90798 7.94476 5.07159 7.33943 5.34153 6.79139Z" fill="#B6C3D3"/>
                </svg>
                <svg v-else width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                        <path d="M8.99751 6.54553C7.64341 6.54553 6.54297 7.64597 6.54297 9.00007C6.54297 10.3542 7.64341 11.4546 8.99751 11.4546C10.3516 11.4546 11.452 10.3542 11.452 9.00007C11.452 7.64597 10.3516 6.54553 8.99751 6.54553Z" fill="#6E6DF8"/>
                        <path d="M9.00002 2.86365C4.90909 2.86365 1.41547 5.40817 0 9.00002C1.41547 12.5918 4.90909 15.1364 9.00002 15.1364C13.095 15.1364 16.5846 12.5918 18 9.00002C16.5846 5.40817 13.095 2.86365 9.00002 2.86365ZM9.00002 13.0909C6.74184 13.0909 4.90909 11.2582 4.90909 8.99998C4.90909 6.74179 6.74184 4.90909 9.00002 4.90909C11.2582 4.90909 13.0909 6.74183 13.0909 9.00002C13.0909 11.2582 11.2582 13.0909 9.00002 13.0909Z" fill="#6E6DF8"/>
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <rect width="18" height="18" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </button>
        </div>
        <div v-if="showError" class="field__error">
            {{ errorMessage }}
        </div>
    </div>
</template>

<script>
    export default {
        name: "AppBaseInput",

        props: {
            modelValue: {
                type: [Number, String],
                required: true
            },
            type: {
                type: String,
                default: 'text'
            },
            label: {
                type: String,
                default: ''
            },
            placeHolder: {
                type: String,
                default: ''
            },
            errorMessage: {
                type: String,
                default: ''
            },
            required: {
                type: Boolean,
                default: true
            },
            isDisabled: {
                type: Boolean,
                default: false
            },
            showPasswordIcon: {
                type: Boolean,
                default: true
            },
            isOneOf: {
                type: Boolean,
                default: false
            },
            isPeriod: {
                type: Boolean,
                default: false
            }
        },

        emits: ['update:modelValue'],

        data() {
            return {
                showPassword: false,
            }
        },

        computed: {
            showLabel() {
                return !!this.label;
            },

            isPassword() {
                return this.type === 'password';
            },

            inputType() {
                return this.isPassword ?
                    this.showPassword ? 'text' : 'password': this.type;
            },

            optionalText() {
                return this.required
                    ? ''
                    : this.isOneOf
                    ? ` ${this.$t('common.additional')}`
                    : ` ${this.$t('common.optional')}`;
            },

            isError() {
                return !!this.errorMessage;
            },

            showError() {
                return !!this.errorMessage && this.errorMessage !== 'isNotShowError';
            }
        }
    }
</script>

<style lang="scss" scoped>
    .field {
        display: flex;
        flex-direction: column;

        &__optional {
            font-size: 12px;
            font-style: italic;
        }

        &__entry {
            position: relative;

            input {
                width: 100%;
                padding: 14px;
                color: var(--app-base-primary);
                font-size: 16px;
                line-height: 18px;
                border-radius: 13px;
                border: 1px solid var(--app-border);
                transition: border 0.3s ease;
            }

            input::placeholder {
                font-size: 12px;
                font-style: italic;
                color: var(--app-base-secondary);
            }

            input:focus {
                border: 1px solid var(--brand-main);
            }

            button {
                position: absolute;
                height: 18px;
                top: 50%;
                right: 15px;
                transform: translate(0, -50%);
                background: transparent;
            }

            &--is-error {
                input {
                    border: 1px solid var(--system-red);
                }
            }

            &--is-password {
                input {
                    padding-right: 40px;
                }
            }
        }

        &__error {
            margin-top: 10px;
            font-size: 14px;
            color: var(--system-red);
        }

        &--is-disabled {
            pointer-events: none;

            input {
                opacity: 0.5;
            }
        }
    }
</style>