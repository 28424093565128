<template>
    <div class="upload-cropper"
        :class="{
            'is-fixed': isFixedHeight,
            'is-full': !isFixedHeight
         }"
    >
        <div v-if="showLabel" class="app__label">
            {{ label }}
            <span class="upload-cropper__optional">
                {{ optionalText }}
            </span>
        </div>
        <div v-if="!image.isUploaded && !image.isLoaded" class="upload-cropper__upload">
            <app-upload-area
                :help-text="upload.helpText"
                :btn-text="upload.btnText"
                :accept-types="acceptTypes"
                @uploaded="showUploadedFile"
            />
        </div>
        <div v-else class="upload-cropper__cropper cropper">
            <div class="cropper__area">
                <div v-if="isNotResult">
                    <cropper class="cropper__advanced" ref="cropper"
                        image-restriction="none"
                        :src="image.url"
                        :stencil-component="stencilComponent"
                        :stencil-props="stencilProps"
                        @change="resizeUploadedFile"
                    />
                </div>
                <div v-else class="cropper__result" :class="{ 'is-logo': isLogo }">
                    <img v-if="!image.isLoaded" :src="image.url" alt="Logo">
                    <app-loader v-else
                        :size="'small'"
                    />
                </div>
            </div>
            <app-circle-btn v-if="!image.isCropped && !image.isLoaded" class="cropper__cropBtn"
                @action="cropUploadedFile"
            >
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.49687 9.92189L2.89062 7.31564L2.00312 8.19689L5.49687 11.6906L12.9969 4.19064L12.1156 3.30939L5.49687 9.92189Z" fill="#2B3037"/>
                </svg>
            </app-circle-btn>
            <app-circle-btn v-if="!isDisabled" class="cropper__deleteBtn"
                @action="deleteUploadedFile"
            >
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.875 4.00625L10.9938 3.125L7.5 6.61875L4.00625 3.125L3.125 4.00625L6.61875 7.5L3.125 10.9938L4.00625 11.875L7.5 8.38125L10.9938 11.875L11.875 10.9938L8.38125 7.5L11.875 4.00625Z" fill="#2B3037"/>
                </svg>
            </app-circle-btn>
        </div>
    </div>
</template>

<script>
    import AppUploadArea from "./AppUploadArea";
    import { Cropper, CircleStencil, RectangleStencil } from 'vue-advanced-cropper';
    import 'vue-advanced-cropper/dist/style.css';
    import { uploadTypes } from "../../../../constants/uploadFileTypes";
    import { stencilTypes } from "../../../../constants/uploadFileTypes";

    export default {
        name: "AppCropperView",

        components: {
            AppUploadArea,
            Cropper,
            CircleStencil,
            RectangleStencil
        },

        props: {
            label: {
                type: String,
                default: '',
            },
            type: {
                type: String,
                required: true,
                validator(type) {
                    return ['image', 'logo'].includes(type);
                }
            },
            acceptTypes: {
                type: Array,
                default() {
                    return [];
                }
            },
            url: {
                type: String,
                required: true
            },
            isDisabled: {
                type: Boolean,
                default: false
            },
            height: {
                type: String,
                default: 'fixed',
                validator(height) {
                    return ['full', 'fixed'].includes(height);
                }
            },
            cropperType: {
                type: String,
                default: 'logo',
                validator(type) {
                    return ['logo', 'polls', 'news', 'camera', 'face'].includes(type);
                }
            },
            required: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                image: {
                    isLoaded: false,
                    isUploaded: false,
                    isCropped: false,
                    url: '',
                    base64: '',
                    file: '',
                    isChanged: false
                }
            }
        },

        created() {
            this.initImage();
        },

        computed: {
            upload() {
                return uploadTypes[this.type];
            },

            isFixedHeight() {
                return this.height === 'fixed';
            },

            stencilComponent() {
                return this.isLogo ? CircleStencil : RectangleStencil;
            },

            isLogo() {
                return this.cropperType === 'logo';
            },

            stencilProps() {
                return stencilTypes[this.cropperType];
            },

            optionalText() {
                return this.required ? '' : ` ${this.$t('common.optional')}`;
            },

            isNotResult() {
                return !this.image.isCropped && !this.image.isLoaded;
            },

            showLabel() {
                return !!this.label;
            }
        },

        methods: {
            initImage() {
                if (this.url) {
                    //TODO: ANOTHER LOGIC FOR GET IMAGE
                    // this.getImage(this.url);
                    this.image.url = this.url;
                    //Imitate step with cropper
                    this.image.isCropped = true;
                    this.image.isUploaded = true;
                }
            },

            // async getImage(imageUrl) {
            //     this.image.isLoaded = true;
            //
            //     await getImageFromUrl(imageUrl)
            //         .then((image) => {
            //             this.image.url = image;
            //             //Imitate step with cropper
            //             this.image.isCropped = true;
            //             this.image.isUploaded = true;
            //         })
            //         .finally(() => {
            //             this.image.isLoaded = false;
            //         })
            // },

            showUploadedFile(url, convertedFile, file) {
                this.image.url = url;
                this.image.base64 = convertedFile;
                this.image.isUploaded = true;
            },

            cropUploadedFile() {
                //Need to review uploaded file or not if we want to use this function outside?
                if (this.image.isUploaded && !this.image.isCropped) {
                    this.image.url = this.image.base64;
                    this.image.isCropped = true;
                }
            },

            resizeUploadedFile() {
                const { canvas } = this.$refs.cropper.getResult();
                this.image.base64 = canvas.toDataURL();

                this.canvasToBlob(canvas)
                    .then(blob => {
                        const fileName = blob.type.split('/').join('.');

                        this.image.file = new File([ blob ], `${fileName}`, {
                            type: blob.type,
                        });

                        this.$emit('update', {
                            base64: this.image.base64,
                            file: this.image.file,
                            isChanged: this.image.isChanged
                        });
                    })
            },

            canvasToBlob(canvas) {
                return new Promise((resolve) => {
                    canvas.toBlob((blob) =>{
                        resolve(blob);
                    });
                });
            },

            deleteUploadedFile() {
                this.image.isUploaded = false;
                this.image.url = '';
                this.image.base64 = '';
                this.image.isCropped = false;

                if (this.url && !this.image.isChanged) {
                    this.image.isChanged = true;
                }

                this.$emit('update', {
                    base64: '',
                    file: '',
                    isChanged: this.image.isChanged
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .upload-cropper {
        display: flex;
        flex-direction: column;

        &__cropper {
            position: relative;
            max-width: 514px;
            border-radius: 13px;
            border: 1px solid var(--app-border);
            padding: 0 56px;
        }

        &__upload {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
        }

        &__optional {
            font-size: 12px;
            font-style: italic;
        }
    }

    .cropper {
        &__area {
            position: relative;
            height: 100%;
            text-align: center;
        }

        &__deleteBtn, &__cropBtn {
            position: absolute;
            right: 15px;
            top: 15px;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            /*background: var(--grey40);*/
        }

        &__deleteBtn {
            right: 15px;
            top: 15px;
        }

        &__cropBtn {
            left: 15px;
            top: 15px;
        }

        &__result {
            height: 100%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px 0;

            img {
                height: 100%;
                width: 100%;
                max-width: 100%;
                object-fit: contain;
            }
        }

        &__result.is-logo {
            width: 214px;

            img {
                border-radius: 50%;
            }
        }
    }
</style>

<style lang="scss">
    //GLOBAL STYLES FOR ADVANCED CROPPER
    .cropper__advanced {
        height: 100%;
        width: 100%;
        position: absolute;
    }

    .upload-cropper.is-full {
        height: 100%;

        .upload, .cropper {
            height: 100%;
        }

        .cropper__area {
            display: flex;
            width: 100%;
        }

        .cropper__result {
            height: unset;
            margin: auto;
        }

    }

    .upload-cropper.is-fixed {
        .cropper, .upload__area {
            height: 230px;
        }
    }
</style>