<template>
    <div class="invite-details">
        <h4>{{ title }}</h4>
        <div class="invite-details__properties">
            <div class="app__label">
                {{ $t('invite.title.description') }}
            </div>
            <div class="invite-details__options">
                <div v-for="(value, key) in inviteVariables">
                    <div class="invite-details__option"
                        draggable="true"
                        @dragstart="dragOptionStart($event, key)"
                        @dragend="dragOptionEnd"
                    >
                        {{ value.text }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!isSms" class="invite-details__theme">
            <app-base-input
                v-model="invite.theme"
                :label="$t('invite.theme.label')"
                :place-holder="$t('invite.theme.ph')"
            />
        </div>
        <div class="invite-details__text">
            <div class="app__label">
                {{ $t('invite.text.email.label') }}
            </div>
            <div class="invite-details__text-area"
            >
                <div class="invite-details__text-input text-input" tabindex="0" ref="inviteText"
                    v-html="invite.text"
                    contenteditable="true"
                    @[dragOverEvent].prevent
                    @drop="dropOption($event)"
                >
                </div>
            </div>
        </div>
        <div class="invite-details__sender">
            <app-base-input
                v-model="invite.sender"
                :label="$t('invite.sender.label')"
                :place-holder="$t('invite.sender.ph')"
            />
        </div>
<!--        <div>{{ string }}</div>-->
    </div>
</template>

<script>
    import { variables } from "../../constants/invite";

    export default {
        name: "InviteDetails",

        props: {
            inviteDetails: {
                type: Object,
                required: true
            },
            isSms: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                invite: {
                    theme: '',
                    text: '',
                    sender: ''
                },
                isDragStarted: false,
                string: ''
            }
        },

        created() {
            this.mapInviteDetails();
        },

        mounted() {
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromEvents();
        },

        computed: {
            title() {
                return this.isSms
                    ? this.$t('invite.title.sms')
                    : this.$t('invite.title.email')
            },

            inviteVariables() {
                return variables;
            },

            dragOverEvent() {
                return this.isDragStarted ? 'dragover' : null;
            }
        },

        methods: {
            subscribeToEvents() {
                document.addEventListener('keydown', this.handlerKeyBackspace);
            },

            unsubscribeFromEvents() {
                document.removeEventListener('keydown', this.handlerKeyBackspace);
            },

            handlerKeyBackspace(event) {
                if(event.keyCode === 8) {
                    //Need to review this, because delete all elements in div
                    if (this.$refs.inviteText.innerHTML === '<p><br></p>') {
                        event.preventDefault();
                    }
                }
            },

            mapInviteDetails() {
                this.invite = { ...this.inviteDetails };

                const variableKeys = Object.keys(this.inviteVariables);

                variableKeys.forEach((key) => {
                    const variable = `{{${key}}}`;

                    this.invite.text = this.invite.text.replace(new RegExp(variable, "gi"), match => {
                        return `<input type="button" value="${this.inviteVariables[key].text}">`;
                    });
                })
            },

            dropOption(event) {
                const variableKey = event.dataTransfer.getData('variableKey');
                const element = this.$refs.inviteText;

                if (element.contains(window.getSelection().anchorNode)) {
                    if (window.getSelection().rangeCount) {
                        const range = window.getSelection().getRangeAt(0);

                        const input = document.createElement("input");
                        input.type = 'button';
                        input.value = this.inviteVariables[variableKey].text;

                        range.surroundContents(input);
                        //Clear input slection after insert if we need
                        window.getSelection().removeAllRanges();

                        this.mapInviteToSend();
                    }
                }
            },

            mapInviteToSend() {

            },

            dragOptionStart(event, key) {
                this.isDragStarted = true;
                event.dataTransfer.setData('variableKey', key);
            },

            dragOptionEnd() {
                this.isDragStarted = false;
            },

            dragOptionOver() {
            },

            // test() {
            //     let inviteText = this.$refs.inviteText.innerHTML;
            //
            //     const spacesQty = inviteText.split('&nbsp;').length - 1;
            //
            //     for (let i = 0; i < spacesQty; i++) {
            //         inviteText = inviteText.replace(new RegExp('&nbsp;', "gi"), () => {
            //             return ' ';
            //         });
            //     }
            //
            //     const brs = inviteText.split('<br>').length - 1;
            //
            //     for (let i = 0; i < brs; i++) {
            //         inviteText = inviteText.replace(new RegExp('<br>', "gi"), () => {
            //             return '';
            //         });
            //     }
            //
            //     const emptyQty = inviteText.split('<p></p>').length - 1;
            //
            //     for (let i = 0; i < emptyQty; i++) {
            //         inviteText = inviteText.replace(new RegExp('<p></p>', "gi"), () => {
            //             return '';
            //         });
            //     }
            //
            //     const variableKeys = Object.keys(this.inviteVariables);
            //
            //     variableKeys.forEach((key) => {
            //         const variable = `<input type="button" value="${this.inviteVariables[key].text}">`;
            //         const variableKey = `{{${key}}}`;
            //
            //         inviteText = inviteText.replace(new RegExp(variable, "gi"), () => {
            //             return variableKey;
            //         });
            //     })
            //
            //     this.string = inviteText;
            // }
        }
    }
</script>

<style lang="scss" scoped>
    .invite-details {
        h4 {
            margin-bottom: 30px;
        }

        &__options {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        &__option {
            font-size: 14px;
            line-height: 19px;
            color: var(--app-base-primary);
            background: var(--app-btn-secondary);
            padding: 0 5px;
            border-radius: 5px;
            margin: 0 15px 10px 0;

            &:hover {
                cursor: pointer;
            }
        }

        &__theme {
            margin-top: 5px;
        }

        &__theme, &__text {
            margin-bottom: 15px;
        }

        &__text-area {
            height: 160px;
            border: 1px solid var(--app-border);
            border-radius: 13px;
            font-size: 16px;
            color: var(--app-base-primary);
            transition: 0.3s;

            &:focus-within {
                border: 1px solid var(--brand-main);
            }
        }

        &__text-input {
            padding: 15px;
        }
    }
</style>

<style lang="scss">
    .text-input {
        max-height: 145px;
        overflow-y: auto;
        scrollbar-width: none;
        scroll-behavior: smooth;

        p {
            line-height: 22px;
        }

        p:not(:last-child) {
            margin-bottom: 10px;
        }

        input {
            font-size: 14px;
            line-height: 19px;
            padding: 0 5px;
            background: var(--app-btn-secondary);
            border-radius: 5px;
            pointer-events: none;
        }

        input:focus {
            user-select: none;
        }
    }

    .text-input::-webkit-scrollbar {
        width: 0;
        display: none;
    }
</style>