<template>
    <app-modal-wrapper>
        <div class="modal-template-contract">
            <form @submit.prevent="doActionTemplateContract" class="template-contract-form">
                <h4>{{ $t('finance.templates.contract.title') }}</h4>
                <div class="template-contract-form__info">
                    <app-base-input
                        v-model="contractTemplate.name"
                        :label="$t('finance.templates.contract.name.label')"
                        :place-holder="$t('finance.templates.contract.name.ph')"
                        :error-message="errors.name"
                    />
                    <div class="template-contract-form__upload">
                        <div class="app__label template-contract-form__label">
                            {{ $t('upload.contract.helpText') }}
                            <span>
                                <a href="/instruction.pdf" target="_blank">{{ $t('finance.templates.contract.upload.label') }}</a>
                            </span>
                        </div>
                        <app-upload-file
                            :accept-types="[]"
                            :height="'fixed'"
                            :file-name="fileName"
                            :file-size="fileSize"
                            @update="updateFile"
                        />
                        <div class="template-contract-form__error">
                            {{ errors.file }}
                        </div>
                    </div>
                </div>
                <div class="template-contract-form__control">
                    <app-base-btn
                        :size="'fixed'"
                        :variation="'cancel'"
                        :text="$t('common.buttons.cancel')"
                        @action="closeModalTemplateContract"
                    />
                    <app-base-btn
                        :type="'submit'"
                        :size="'fixed'"
                        :variation="'save'"
                        :text="$t('common.buttons.addTemplate')"
                    />
                </div>
            </form>
        </div>
    </app-modal-wrapper>
</template>

<script>
    import AppUploadFile from "../../common/local/upload/AppUploadFile";
    import { validateContractTemplate } from "../../../validations/finance/templates";
    import { templatesToastsMixin } from "../../../mixins/toasts/finance/templates";
    import { djinAPI } from "../../../config/api";

    export default {
        name: "ModalTemplateContract",

        components: {
            AppUploadFile
        },

        data() {
            return {
                contractTemplate: {
                    name: '',
                    file: ''
                },

                errors: {
                    name: '',
                    file: ''
                },

                fileName: '',
                fileSize: '',
            }
        },

        mixins: [
            templatesToastsMixin
        ],

        methods: {
            doActionTemplateContract() {
                this.clearContractTemplateErrors();
                const { isValid, errors } = validateContractTemplate(this.contractTemplate);
                this.errors = errors;

                if (isValid) {
                    this.createTemplateContract();
                }
            },

            createTemplateContract() {
                const document = this.contractTemplate.file;
                const name = this.contractTemplate.name;
                const data = new FormData();
                data.append('document', document);
                data.append('name', name);

                djinAPI.post('finance/templates/contracts/', data, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                    .then(() => {
                        this.showCreateContractTemplateToast();
                        this.updateTemplates();
                    })
            },

            updateTemplates() {
                this.closeModalTemplateContract();
                this.emitter.emit('updateTemplates');
            },

            clearContractTemplateErrors() {
                for (let key in this.errors) {
                    this.errors[key] = '';
                }
            },

            closeModalTemplateContract() {
                this.closeModal('modalTemplateContract');
            },

            updateFile(uploadedFile) {
                this.contractTemplate.file = uploadedFile.file;

                if (this.contractTemplate.file) {
                    this.fileName = uploadedFile.file.name;
                    this.fileSize = `${Math.round(uploadedFile.file.size/1000)} KB`;
                } else {
                    this.fileName = '';
                    this.fileSize = '';
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .modal-template-contract {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        max-width: 574px;
        width: 100%;
    }

    .template-contract-form {
        h4 {
            margin-bottom: 30px;
        }

        &__control {
            display: flex;
            justify-content: flex-end;
            margin-top: 45px;

            button:first-child {
                margin-right: 30px;
            }
        }

        &__upload {
            margin-top: 15px;

            span {
                color: var(--brand-main);
                font-weight: 500;
            }
        }

        &__label {
            button {
                display: inline-flex;
            }
        }

        &__error {
            margin-top: 10px;
            font-size: 14px;
            color: var(--system-red);
        }
    }
</style>