function mapOperationsToView(operations) {
    return {
        amount: operations.amount,
        operations: operations.financeOperations.map((operation) => {
            return {
                id: operation._id,
                userId: operation.user_id,
                premise: operation.premise_name || '',
                type: operation.type,
                amount: operation.amount,
                basis: operation.basis || '',
                date: operation.date
            }
        })
    }
}

function mapOperationToSend(operation) {
    const operationToSend = {
        user_id: operation.userId,
        type: operation.type,
        amount: operation.amount,
        basis: operation.basis
    }

    if (operation.premiseId) {
        operationToSend.premise_id = operation.premiseId
    }

    return operationToSend;
}

export {
    mapOperationsToView,
    mapOperationToSend
}