<template>
    <div class="finance-main-building">
        <div class="table__row finance-main-table__row finance-main-table__building">
            <div class="table__column finance-main-table__name finance-main-table__name--is-building"
                 :class="{
                    'finance-main-table__name--is-free-building': isFreeBuilding,
                    'finance-main-table__name--is-not-exist-users': isNotExistUsers
                 }"
            >
                <app-collapse-btn v-if="!isNotExistUsers"
                    :default-is-active="showUsers"
                    @collapse="collapseBuildings"
                />
                <app-overflow-match-wrapper
                    :text="name"
                    :sub-text="subString"
                    :is-match="true"
                />
            </div>
            <div class="table__column finance-main-table__document-id"></div>
            <div class="table__column finance-main-table__total">
                <finance-total-item
                    :balance="balance"
                />
            </div>
        </div>
        <div v-if="showUsers" class="finance-main-group__users">
            <finance-main-user-row v-for="user in users" :key="user.id"
                :is-free-building="isFreeBuilding"
                :id="user.id"
                :document-id="user.documentId"
                :name="user.name"
                :balance="user.balance"
                :premises="user.premises"
                :sub-string="subString"
            />
        </div>
    </div>
</template>

<script>
    import FinanceMainUserRow from "./FinanceMainUserRow";
    import FinanceTotalItem from "./FinanceTotalItem";

    export default {
        name: "FinanceMainBuildingRow",

        components: {
            FinanceMainUserRow,
            FinanceTotalItem
        },

        props: {
            isFreeBuilding: {
                type: Boolean,
                default: false
            },
            id: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            balance: {
                type: Number,
                required: true
            },
            users: {
                type: Array,
                required: true
            },
            subString: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                showUsers: true
            }
        },

        computed: {
            isNotExistUsers() {
                return !this.users.length;
            }
        },

        methods: {
            collapseBuildings() {
                this.showUsers = !this.showUsers;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .finance-main-building {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__users {
            display: flex;
            flex-direction: column;
        }
    }
</style>