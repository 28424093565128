import { djinAPI } from "../../config/api";
import { mapCitiesToView, mapGroupBuildingsToStore } from "../../api/mappers/buildings";
import { createEntrancesList, createFloorsList } from "../../helpers/buildings";
import { premiseTypes } from "../../constants/premises";
import { filtersText } from "../../constants/filters";
import {
    financeBalanceFilters,
    reviewInvoiceStates,
    statementOperationTypes,
    statementViewTypes
} from "../../constants/finance";
import { operationAmountTypes } from "../../constants/finance";
import i18n from "../../../src/localization/i18n"
const { t } = i18n.global;

const buildings = {
    namespaced: true,

    state: () => ({
        groups: [],
        buildings: [],
        cities: []
    }),

    getters: {
        mappedGroups(state, getters) {
            const isExistFreeBuildings = getters.mappedFreeBuildings.length;
            const freeBuildingsGroup = [];

            if (isExistFreeBuildings) {
                freeBuildingsGroup.push({
                    id: 'free',
                    name: filtersText.noGroup
                })
            }

            return state.groups
                .map((group) => {
                    return {
                        id: group.id,
                        name: group.name,
                    }
                }).concat(freeBuildingsGroup);
        },

        mappedCities(state) {
            const langCode = i18n.global.locale;

            return state.cities.map((city) => {
                return {
                    id: city.id,
                    name: city.name[langCode]
                }
            });
        },

        mappedBuildings(state) {
            return state.buildings.map((building) => {
                return {
                    id: building.id,
                    name: building.groupId ? `${building.groupName}/${building.name}`: building.name,
                    groupId: building.groupId,
                    groupName: building.groupName,
                    entrances: createEntrancesList(parseInt(building.entrances)),
                    floors: createFloorsList(parseInt(building.minFloor), parseInt(building.maxFloor), building.isZeroFloor),
                    location: building.location
                }
            })
        },

        mappedFreeBuildings(state) {
            return state.buildings.filter((building) => !building.groupId);
        },

        mappedEntrances(state) {
            if (state.buildings.length) {
                let maxEntrances = 1;

                state.buildings.forEach((building) => {
                    if (parseInt(building.entrances) > maxEntrances) {
                        maxEntrances = building.entrances;
                    }
                })

                return createEntrancesList(parseInt(maxEntrances))
                        .map((entrance) => {
                            return {
                                id: entrance.id,
                                name: `${entrance.name} ${t('filters.entrance')}`
                            }
                        });
            } else {
                return [];
            }
        },

        mappedFloors(state) {
            if (state.buildings.length) {
                let minFloor = state.buildings[0].minFloor;
                let maxFloor = state.buildings[0].maxFloor;

                state.buildings.forEach((building) => {
                    if (parseInt(building.minFloor) < minFloor) {
                        minFloor = building.minFloor;
                    }

                    if (parseInt(building.maxFloor) > maxFloor) {
                        maxFloor = building.maxFloor;
                    }
                })

                return createFloorsList(parseInt(minFloor), parseInt(maxFloor), true)
                    .map((floor) => {
                        return {
                            id: floor.id,
                            name: `${floor.name} ${t('filters.floor')}`
                        }
                    });
            } else {
                return [];
            }
        }
    },

    mutations: {
        setGroups(state, groups) {
            state.groups = groups;
        },

        setBuildings(state, buildings) {
            state.buildings = buildings;
        },

        setCities(state, cities) {
            state.cities = cities;
        },

        setFilters(state, filters) {
            state.filters = filters;
        }
    },

    actions: {
        getGroupBuildings({ commit, getters }) {
            djinAPI.get('/buildings')
                .then((result) => {
                    const { cities, ...groupBuildings } = result.data;
                    const { groups, buildings } = mapGroupBuildingsToStore(groupBuildings);

                    commit('setGroups', groups);
                    commit('setBuildings', buildings);
                    commit('setCities', mapCitiesToView(cities));

                    const allFilters = {
                        groups: getters.mappedGroups,
                        cities: getters.mappedCities,
                        buildings: getters.mappedBuildings,
                        entrances: getters.mappedEntrances,
                        floors: getters.mappedFloors,
                        types: premiseTypes,
                        balance: financeBalanceFilters,
                        invoiceReviewStates: reviewInvoiceStates.filter((state) => state.id !== 0),
                        operationAmountTypes: operationAmountTypes,
                        views: statementViewTypes,
                        operations: statementOperationTypes
                    };

                    commit('filters/setFilters', allFilters, { root: true });
                })
        }
    }
}

export {
    buildings
}


