<template>
    <div class="multiple-select" ref="multipleSelect"
         :class="{
            'multiple-select--is-opened': isOpened
         }"
    >
        <div v-if="showLabel" class="app__label">
            {{ label }}
            <span class="multiple-select__optional">
                {{ optionalText }}
            </span>
        </div>
        <VueMultiselect ref="select"
            v-model="selectedValues"
            label="name"
            track-by="name"
            :multiple="true"
            :options="options"
            :placeholder="placeHolder"
            :show-labels="false"
            :close-on-select="false"
            :clear-on-select="false"
            @select="selectOptions"
            @open="openMultiSelect"
            @close="closeMultiSelect"
        >
            <template v-slot:option="{ option }">
                <div class="multiple-select__option option">
                    <div class="option__checkbox">
                        <svg v-if="selectedValues.some((opt) => opt.id === option.id)" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="25" height="25" rx="6" fill="var(--brand-main)"/>
                            <g clip-path="url(#clip0_653_5515)">
                                <path d="M9.77273 15.9091L6.19318 12.3295L5 13.5227L9.77273 18.2955L20 8.06818L18.8068 6.875L9.77273 15.9091Z" fill="white"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_653_5515">
                                    <rect width="15" height="15" fill="white" transform="translate(5 5)"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <svg v-else width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="0.5" width="24" height="24" rx="5.5" stroke="#B6C3D3"/>
                        </svg>
                    </div>
                    <div class="option__text can-overflow">
                        {{ option.name }}
                    </div>
                </div>
            </template>
            <template v-slot:noResult>
                {{ noResultText }}
            </template>
            <template v-slot:noOptions>
                {{ noDataText }}
            </template>
        </VueMultiselect>
    </div>
</template>

<script>
    import cloneDeep from "lodash.clonedeep";
    import VueMultiselect  from 'vue-multiselect';
    import "vue-multiselect/dist/vue-multiselect.css";
    import { filtersText } from "../../../constants/filters";

    export default {
        name: "AppBaseMultipleSelect",

        components: {
            VueMultiselect
        },

        props: {
            label: {
                type: String,
                default: ''
            },
            options: {
                type: Array,
                required: true
            },
            isSearchable: {
                type: Boolean,
                default: false
            },
            isRequired: {
                type: Boolean,
                default: true
            },
            placeHolder: {
                type: String,
                default: ''
            },
            selectedOptions: {
                type: Array
            },
        },

        data() {
            return {
                selectedValues: [],
                isOpened: false
            }
        },

        created() {
            this.initSelect();
        },

        mounted() {
            document.addEventListener("mousedown", this.handlerClickOutside);
        },

        unmounted() {
            document.removeEventListener("mousedown", this.handlerClickOutside);
        },

        watch: {
            //We need to watch delete options from input
            selectedValues: {
                handler() {
                    this.selectOptions();
                },
                deep: true
            }
        },

        computed: {
            optionalText() {
                return this.isRequired
                    ? ''
                    : ` ${this.$t('common.optional')}`;
            },

            noResultText() {
                return filtersText.noResult;
            },

            noDataText() {
                return filtersText.noData;
            },

            showLabel() {
                return !!this.label;
            }
        },

        methods: {
            initSelect() {
                if (this.selectedOptions) {
                    this.selectedValues = cloneDeep(this.selectedOptions);
                }
            },

            handlerClickOutside(event) {
                if(!this.$refs.multipleSelect.contains(event.target)) {
                    this.$refs.select.deactivate();
                }
            },

            selectOptions() {
                //Need to use nextTick for get updated value from v-model
                this.$nextTick(() => {
                    this.$emit('select', this.selectedValues);
                });
            },

            openMultiSelect() {
                this.isOpened = true;
            },

            closeMultiSelect() {
                this.isOpened = false;
            }
        }
    }
</script>

<style lang="scss">
    .multiple-select {
        position: relative;
        width: 100%;

        &__optional {
            font-size: 12px;
            font-style: italic;
        }

        .multiselect__select {
            height: 44px;
        }

        .multiselect__input {
            display: none;
        }

        .multiselect__content-wrapper {
            background: var(--system-white);
            box-shadow: 0px 16px 32px rgba(35, 23, 5, 0.26);
            border-radius: 13px;
            border: none;
            margin-top: 10px;
            padding: 5px 0px;
            max-height: 160px !important;
        }

        .multiselect__tags {
            border: 1px solid var(--app-border);
            padding: 12px 40px 0px 8px;
        }

        .multiselect__tag {
            border-radius: 100px;
            color: var(--app-base-primary);
            background: var(--app-btn-secondary);
        }

        .multiselect__tag-icon {
            font-weight: unset;

            &:after {
                font-size: 16px;
                color: var(--app-base-secondary);
            }

            &:hover {
                color: var(--app-base-secondary);
            }
        }

        .multiselect--active {
            .multiselect__tags {
                border-color: var(--brand-main) !important;
            }
        }

        &--is-opened {
            .multiselect__tags {
                pointer-events: none;
            }
        }
    }

    .option {
        display: flex;
        align-items: center;

        &__checkbox {
            margin-right: 15px;
        }

        &__text {
            max-width: 380px;
        }
    }
</style>