<template>
    <div class="search" @keyup.enter="handlerKeyEnter">
        <div class="search__entry">
            <div class="search__icon">
                <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.4115 12.349H12.5885L12.2969 12.0677C13.3177 10.8802 13.9323 9.33854 13.9323 7.66146C13.9323 3.92188 10.901 0.890625 7.16146 0.890625C3.42188 0.890625 0.390625 3.92188 0.390625 7.66146C0.390625 11.401 3.42188 14.4323 7.16146 14.4323C8.83854 14.4323 10.3802 13.8177 11.5677 12.7969L11.849 13.0885V13.9115L17.0573 19.1094L18.6094 17.5573L13.4115 12.349ZM7.16146 12.349C4.56771 12.349 2.47396 10.2552 2.47396 7.66146C2.47396 5.06771 4.56771 2.97396 7.16146 2.97396C9.75521 2.97396 11.849 5.06771 11.849 7.66146C11.849 10.2552 9.75521 12.349 7.16146 12.349Z" fill="#A4AFBE"/>
                </svg>
            </div>
            <input type="text" class="search__input"
                :value="modelValue"
                :placeholder="$t('common.search.ph')"
                @input="$emit('update:modelValue', $event.target.value)"
            >
            <div v-if="showQty" class="search__control">
                <div class="search__qty">
                    {{ qty }}
                </div>
                <div class="search__clear-btn">
                    <button type="button" @click="clearSearch">
                        <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.875 1.50625L7.99375 0.625L4.5 4.11875L1.00625 0.625L0.125 1.50625L3.61875 5L0.125 8.49375L1.00625 9.375L4.5 5.88125L7.99375 9.375L8.875 8.49375L5.38125 5L8.875 1.50625Z" fill="#A4AFBE"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AppBaseSearch",

        props: {
            modelValue: {
                type: String,
                required: true
            },
            qty: {
                type: Number,
                required: true
            },
            showQty: {
                type: Boolean,
                required: true
            }
        },

        emits: ['update:modelValue', 'update', 'clear'],

        methods: {
            handlerKeyEnter() {
                this.$emit('update');
            },

            clearSearch() {
                this.$emit('clear');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .search {
        display: flex;
        flex-direction: column;

        &__entry {
            position: relative;

            input {
                width: 100%;
                padding: 14px 74px 14px 50px;
                color: var(--app-base-primary);
                font-size: 16px;
                line-height: 18px;
                border-radius: 13px;
                border: 1px solid var(--app-border);
                transition: border 0.3s ease;
            }

            input::placeholder {
                font-size: 12px;
                font-style: italic;
                color: var(--app-base-secondary);
            }

            input:focus {
                border: 1px solid var(--brand-main);
            }
        }

        &__icon, &__control {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            display: flex;
            align-items: center;
        }

        &__icon {
            left: 16px;
        }

        &__control {
            right: 16px;
        }

        &__qty {
            padding-right: 15px;
            color: var(--app-base-secondary);
            font-weight: 400;
            font-size: 16px;
        }

        &__clear-btn {
            padding-left: 18px;
            border-left: 1px solid var(--app-border);
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;

            button {
                width: 10px;
                height: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
</style>