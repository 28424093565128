function mapContractTemplatesToView(templates) {
    return templates.map((template) => {
        return {
            id: template._id,
            name: template.name,
            size: template.size,
            link: template.link,
        }
    })
}

function mapInvoiceTemplatesToView(templates) {
    return templates.map((template) => {
        return {
            id: template._id,
            name: template.name,
            price: template.price
        }
    })
}

function mapContractTemplateToSend(template) {
    return {
        name: template.name
    }
}

function mapInvoiceTemplateToSend(template) {
    return {
        name: template.name,
        price: Number(template.price)
    }
}

export {
    mapContractTemplatesToView,
    mapInvoiceTemplatesToView,
    mapContractTemplateToSend,
    mapInvoiceTemplateToSend
}