import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

const changeLoginErrors = {
    access: t('validation.changeLogin.access'),
    newLogin: t('validation.changeLogin.newLogin'),
    code: t('validation.changeLogin.code')
}

export {
    changeLoginErrors
}