<template>
    <app-modal-wrapper>
        <div class="modal-invite">
            <form @submit="sendInviteTemplates" class="invite-form">
                <div class="invite-form__info">
                    <div class="invite-form__details">
                        <invite-details
                            :invite-details="invite.email"
                        />
                    </div>
                    <div class="invite-form__details">
                        <invite-details
                            :invite-details="invite.sms"
                            :is-sms="true"
                        />
                    </div>
                </div>
                <div class="invite-form__control">
                    <app-base-btn
                        :size="'fixed'"
                        :variation="'cancel'"
                        :text="$t('common.buttons.cancel')"
                        @action="closeModalInvite"
                    />
                    <app-base-btn
                        :type="'submit'"
                        :size="'fixed'"
                        :variation="'save'"
                        :text="$t('common.buttons.save')"
                    />
                </div>
            </form>
        </div>
    </app-modal-wrapper>
</template>

<script>
    import InviteDetails from "../../invite/InviteDetails";

    export default {
        name: "ModalInvite",

        components: {
            InviteDetails
        },

        data() {
            return {
                invite: {
                    email: {
                        theme: '',
                        text: '<p>{{name}} , Вас вітає адміністрація {{building}}</p>' +
                              '<p>Перейдіть за посиланням, щоб отримати доступ у мобільний додаток DJIN {{linkAccess}} або скопіюйте код доступу {{codeAccess}} та вставте у застосунок.</p>'+
                              '<p>Додаток можна завантажити тут {{linkApp}}</p>',
                        sender: ''
                    },
                    sms: {
                        theme: '',
                        text: '<p>{{name}} , Вас вітає адміністрація {{building}}</p>' +
                              '<p>Перейдіть за посиланням, щоб отримати доступ у мобільний додаток DJIN {{linkAccess}} або скопіюйте код доступу {{codeAccess}} та вставте у застосунок.</p>' +
                              '<p>Додаток можна завантажити тут {{linkApp}}</p>',
                        sender: ''
                    }
                }
            }
        },

        methods: {
            sendInviteTemplates() {

            },

            closeModalInvite() {
                this.closeModal('modalInvite');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-invite {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        max-width: 1118px;
        width: 100%;
    }

    .invite-form {
        &__info {
            display: flex;
        }

        &__details {
            flex-basis: 50%;

            &:first-child {
                margin-right: 30px;
            }
        }

        &__control {
            display: flex;
            justify-content: flex-end;
            margin-top: 45px;

            button:not(:last-child) {
                margin-right: 30px;
            }
        }
    }
</style>