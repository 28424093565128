import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

const adminsElements = [
    {
        id: 'surname:asc',
        name: t('sort.surname')
    },
    {
        id: 'position:asc',
        name: t('sort.position')
    },
    {
        id: 'time:desc',
        name: t('sort.createdDate')
    },
    {
        id: 'time:asc',
        name: t('sort.revCreatedDate')
    }
]

const premisesElements = [
    {
        id: 'building:asc',
        name: t('sort.building')
    },
    {
        id: 'name:asc',
        name: t('sort.premise')
    },
    {
        id: 'area:desc',
        name: t('sort.area')
    },
    {
        id: 'area:asc',
        name: t('sort.revArea')
    }
]

const usersElements = [
    {
        id: 'name:asc',
        name: t('sort.alphabetName')
    },
    {
        id: 'name:desc',
        name: t('sort.revAlphabetName')
    },
    {
        id: 'verify_status:2',
        name: t('sort.verifyAccepted')
    },
    {
        id: 'verify_status:1',
        name: t('sort.verifySent')
    },
    {
        id: 'verify_status:0',
        name: t('sort.verifyNone')
    },
]

const financeContractsElements = [
    {
        id: 'start_date:asc',
        name: t('sort.new')
    },
    {
        id: 'start_date:desc',
        name: t('sort.revNew')
    },
    {
        id: 'user_name:asc',
        name: t('sort.alphabet')
    }
]

const financeInvoiceElements = [];

const financeReviewElements = [
    {
        id: 'EntryDate:desc',
        name: t('sort.new')
    },
    {
        id: 'EntryDate:asc',
        name: t('sort.revNew')
    },
    {
        id: 'status:1',
        name: t('sort.underpayment')
    },
    {
        id: 'status:2',
        name: t('sort.overpayment')
    },
    {
        id: 'status:3',
        name: t('sort.unknownPayment')
    },
    {
        id: 'status:4',
        name: t('sort.unknownInvoice')
    }
]

export {
    adminsElements,
    premisesElements,
    usersElements,
    financeContractsElements,
    financeInvoiceElements,
    financeReviewElements
}