<template>
    <div class="finance-operations-filters filters">
        <div class="filters__searching finance-operations-filters__users">
            <div class="finance-operations-filters__search-icon">
                <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.4115 12.349H12.5885L12.2969 12.0677C13.3177 10.8802 13.9323 9.33854 13.9323 7.66146C13.9323 3.92188 10.901 0.890625 7.16146 0.890625C3.42188 0.890625 0.390625 3.92188 0.390625 7.66146C0.390625 11.401 3.42188 14.4323 7.16146 14.4323C8.83854 14.4323 10.3802 13.8177 11.5677 12.7969L11.849 13.0885V13.9115L17.0573 19.1094L18.6094 17.5573L13.4115 12.349ZM7.16146 12.349C4.56771 12.349 2.47396 10.2552 2.47396 7.66146C2.47396 5.06771 4.56771 2.97396 7.16146 2.97396C9.75521 2.97396 11.849 5.06771 11.849 7.66146C11.849 10.2552 9.75521 12.349 7.16146 12.349Z" fill="#A4AFBE"/>
                </svg>
            </div>
            <app-base-select
                :place-holder="$t('finance.operations.user.ph.another')"
                :is-searchable="true"
                :selected-option="selectedAnotherUser"
                :options="users"
                :type="'financeUser'"
                @select="selectUser"
            />
        </div>
        <div class="filters__control finance-operations-filters__control">
            <div class="finance-operations-filters__user-details">
                <div class="finance-operations-filters__user-name">
                    {{ userFullName }}
                </div>
                <div class="finance-operations-filters__user-amount">
                    <finance-total-item
                        :is-amount="true"
                        :balance="amount"
                    />
                </div>
            </div>
            <div class="finance-operations-filters__user-filters">
                <div class="filters__sorting filters__element">
                    <app-base-select-period
                        :selected-period="selectedPeriod"
                        @update="updatePeriod"
                    />
                </div>
                <div class="filters__filtering filters__element">
                    <app-base-filters
                        :qty="qty"
                        :filters="filters"
                        :active-filters="activeFilters"
                        @update="updateActiveFilters"
                    />
                </div>
                <div class="filters__element">
                    <app-base-btn
                        :size="'padding'"
                        :variation="'brandMain'"
                        :text="$t('common.buttons.operation')"
                        @action="openModalOperation"
                    />
                </div>
            </div>
        </div>
        <div v-if="showFilterPanel" class="filters__panel">
            <div class="filters__results">
                {{ qtyResults }}
            </div>
            <div v-for="filter in modActiveFilters" :key="filter.id">
                <app-filter-active-item class="filters__active-element"
                    :id="filter.id"
                    :name="filter.name"
                    :filter-key="filter.key"
                    @remove="removeActiveFilter"
                />
            </div>
            <div class="filters__reset-btn">
                <app-text-btn
                    :text="$t('filters.clear')"
                    @action="clearAndUpdateActiveFilters"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import AppFilterActiveItem from "../../filters/AppFilterActiveItem";
    import { getResultText, mapActiveFiltersToString } from "../../../constants/filters";
    import FinanceTotalItem from "../main/FinanceTotalItem";
    import { mapGetters } from "vuex";
    import { operationServices } from "../../../constants/finance";

    export default {
        name: "FinanceOperationsFilters",

        components: {
            FinanceTotalItem,
            AppFilterActiveItem
        },

        props: {
            qty: {
                type: Number,
                required: true
            },
            userFullName: {
                type: String,
                required: true
            },
            userPremises: {
                type: Array,
                required: true
            },
            amount: {
                type: Number,
                required: true
            },
            users: {
                type: Array,
                required: true
            }
        },

        data() {
            return {
                selectedAnotherUser: null,
                selectedPeriod: null,
                activeFilters: {
                    operationServices: [],
                    operationAmountTypes: []
                },
                modActiveFilters: [],
                showFilterPanel: false
            }
        },

        mounted() {
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromEvents();
        },

        computed: {
            ...mapGetters({
                operationFilters: 'filters/operationFilters'
            }),

            services() {
                return operationServices;
            },

            qtyResults() {
                return `${this.qty} ${getResultText(this.qty)}`
            },

            filters() {
                return { operationServices: [ ...this.services, ...this.userPremises ], ...this.operationFilters };
            }
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('clearQueryParams', this.clearQueryParams);
                this.emitter.on('updateFilters', this.updateFilters);
            },

            unsubscribeFromEvents() {
                this.emitter.off('clearQueryParams', this.clearQueryParams);
                this.emitter.off('updateFilters', this.updateFilters);
            },

            updatePeriod(period) {
                this.selectedPeriod = period;
                this.updateQueryParams();
            },

            clearQueryParams() {
                this.clearActiveFilters();
                this.clearPeriod();
                this.updateQueryParams();
            },

            clearPeriod() {
                this.selectedPeriod = null;
            },

            updateFilters() {
                this.updateQueryParams();
            },

            clearActiveFilters() {
                const activeFiltersKeys = Object.keys(this.activeFilters);

                activeFiltersKeys.forEach((key) => {
                    this.activeFilters[key].length = 0;
                });

                // this.showFilterPanel = false;
                this.checkShowFiltersPanel();
            },

            clearAndUpdateActiveFilters() {
                this.clearActiveFilters();
                this.updateQueryParams();
            },

            removeActiveFilter(key, id) {
                this.activeFilters[key] = this.activeFilters[key].filter((elementId) => elementId !== id);
                this.checkShowFiltersPanel();
                this.updateQueryParams();
            },

            checkShowFiltersPanel() {
                const activeFiltersKeys = Object.keys(this.activeFilters);
                this.modActiveFilters = [];

                activeFiltersKeys.forEach((key) => {
                    this.activeFilters[key].forEach((id) => {
                        const targetFilter = this.filters[key].find((filter) => filter.id === id);
                        const filter = { ...targetFilter };
                        filter.key = key;
                        this.modActiveFilters.push(filter);
                    })
                })

                this.showFilterPanel = this.modActiveFilters.length;
            },

            updateActiveFilters(name, activeFilters) {
                this.activeFilters[name] = activeFilters;
                //TODO: SEVERAL TIMES I CHECK SHOW PANEL
                this.checkShowFiltersPanel();
            },

            updateQueryParams() {
                let parameters = null;
                const timeFilter = this.selectedPeriod;
                const filters = this.modActiveFilters.length;

                if (timeFilter || filters) {
                    parameters = {
                        params: {}
                    };

                    if (timeFilter) {
                        parameters.params.time_filter = `${timeFilter.startDate}:${timeFilter.endDate}`
                    }

                    if (filters) {
                        const { operationAmountTypes, operationServices } = this.activeFilters;

                        if (operationAmountTypes.length) {
                            parameters.params.amount = `${operationAmountTypes.join(',')}`;
                        }

                        if (operationServices.length) {
                            let serviceFilters = {
                                services: []
                            };

                            if (operationServices.includes('service')) {
                                serviceFilters.services.push('service');
                            }

                            const premiseFilters = operationServices.filter((service) => service !== 'service');

                            if (premiseFilters.length) {
                                serviceFilters.services.push('periodic');
                                serviceFilters.premises = premiseFilters;
                            }

                            parameters.params.filter =  mapActiveFiltersToString(serviceFilters);
                        }

                    }
                }

                this.$emit('update', parameters);
            },

            selectUser(user) {
                this.selectedAnotherUser = user;
                this.$emit('selectUser', user);
            },

            openModalOperation() {
                this.$emit('openModal');
            }
        }
    }
</script>

<style scoped lang="scss">
    .finance-operations-filters {
        &__users {
            padding: 15px 30px;
            position: relative;
        }

        &__search-icon {
            position: absolute;
            left: 46px;
            top: 50%;
            transform: translate(0, -50%);
            display: flex;
            align-items: center;
            z-index: 100;
        }

        &__control {
            justify-content: space-between;
            background-color: var(--app-table-background);
            padding: 15px 30px;
        }

        &__user-details {
            display: flex;
            align-items: center;
            width: 100%;
        }

        &__user-name {
            font-size: 24px;
            line-height: 28px;
            font-weight: 500;
            color: var(--app-base-primary);
            margin-right: 15px;
        }

        &__user-amount {
            font-size: 16px;
        }

        &__user-filters {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
        }
    }
</style>

<style lang="scss">
    .finance-operations-filters__users {
        .multiselect__tags {
            padding-left: 40px !important;
        }
    }

    .finance-operations-filters {
        .filters__panel {
            padding: 0 30px 15px 30px !important;
        }
    }
</style>