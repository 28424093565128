<template>
    <div class="finance-main-group">
        <div class="table__row finance-main-table__row">
            <div class="table__column finance-main-table__name finance-main-table__name--is-group" :class="{ 'finance-main-table__name--is-not-exist-buildings': isNotExistBuildings }">
                <app-collapse-btn v-if="!isNotExistBuildings"
                    :default-is-active="showBuildings"
                    @collapse="collapseBuildings"
                />
                <app-overflow-match-wrapper
                    :text="name"
                    :sub-text="subString"
                    :is-match="true"
                />
            </div>
            <div class="table__column finance-main-table__document-id"></div>
            <div class="table__column finance-main-table__total">
                <finance-total-item
                    :balance="balance"
                />
            </div>
        </div>
        <div v-if="showBuildings" class="finance-main-group__buildings">
            <finance-main-building-row v-for="building in buildings" :key="building.id"
                :id="building.id"
                :name="building.name"
                :balance="building.balance"
                :users="building.users"
                :sub-string="subString"
            />
        </div>
    </div>
</template>

<script>
    import FinanceMainBuildingRow from "./FinanceMainBuildingRow";
    import FinanceTotalItem from "./FinanceTotalItem";

    export default {
        name: "FinanceMainGroupRow",

        components: {
            FinanceTotalItem,
            FinanceMainBuildingRow
        },

        props: {
            id: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            balance: {
                type: Number,
                required: true
            },
            buildings: {
                type: Array,
                required: true
            },
            subString: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                showBuildings: true
            }
        },

        computed: {
            isNotExistBuildings() {
                return !this.buildings.length;
            }
        },

        methods: {
            collapseBuildings() {
                this.showBuildings = !this.showBuildings;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .finance-main-group {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__buildings {
            display: flex;
            flex-direction: column;
        }
    }
</style>