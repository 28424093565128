<template>
    <button class="base-btn" ref="baseBtn" @click="handlerClick"
        :class="{
            'base-btn--is-red': isRed,
            'base-btn--is-main': isMain,
            'base-btn--is-add-main': isAddMain,
            'base-btn--is-brand-main': isBrandMain,
            'base-btn--is-base': isBase,
            'base-btn--is-disabled': isDisabled
        }"
        :type="type"
        :style="styleBtn"
    >
        <slot/>
        <span>{{ text }}</span>
    </button>
</template>

<script>
    export default {
        name: "AppTextBtn",

        props: {
            type: {
                type: String,
                default: 'button',
                validator(type) {
                    return ['submit', 'button'].includes(type);
                }
            },
            size: {
                type: String,
                default: 'fixed',
                validator(size) {
                    return ['fixed', 'padding', 'full'].includes(size);
                }
            },
            variation: {
                type: String,
                required: true,
                validator(variation) {
                    return [
                        'cancel',
                        'save',
                        'add',
                        'addMain',
                        'brandMain',
                        'delete',
                        'base'
                    ].includes(variation);
                }
            },
            text: {
                type: String,
                required: true
            },
            isDisabled: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                styleBtn: {}
            }
        },

        created() {
            this.initStyles();
        },

        computed: {
            isRed() {
                return this.variation === 'delete';
            },

            isMain() {
                return this.variation === 'save';
            },

            isAddMain() {
                return this.variation === 'addMain';
            },

            isBrandMain() {
                return this.variation === 'brandMain';
            },

            isBase() {
                return !this.isRed && !this.isMain && !this.isAddMain;
            }
        },

        methods: {
            initStyles() {
                switch (this.size) {
                    case 'fixed':
                        this.styleBtn.width = '242px';
                        break;
                    case 'full':
                        this.styleBtn.width = '100%';
                        break;
                    case 'padding':
                        this.styleBtn.padding = '0px 25px';
                        break;
                }
            },

            handlerClick() {
                this.$emit('action');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .base-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        min-height: 48px;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        letter-spacing: 0.3px;
        border-radius: 13px;
        transition: 0.3s;

        span {
            white-space: nowrap;
        }

        &--is-red {
            border: 1px solid var(--system-red-70);
            color: var(--system-red-70);
            background: var(--system-red-10);

            &:hover {
                background: var(--system-red-20);
            }

            /*&:active {*/
            /*    border: 2px solid var(--system-red-70);*/
            /*    font-weight: 500;*/
            /*}*/
        }

        &--is-base {
            border: 1px solid var(--app-base-primary);
            color: var(--app-base-primary);
            background: var(--system-white);


            &:hover:not(.base-btn--is-disabled) {
                background-color: var(--app-base-primary-10);
            }

            /*&:active {*/
            /*    border: 2px solid var(--base);*/
            /*    font-weight: 500;*/
            /*}*/
        }

        &--is-add-main {
            border: 1px solid var(--brand-main);
            color: var(--brand-main);
            background: var(--system-white);

            &:hover {
                background-color: var(--brand-main-10);
            }
        }

        &--is-brand-main {
            border: unset;
            color: var(--system-white);
            background-color: var(--brand-main);
            font-weight: 500;

            &:hover {
                background-color: var(--brand-main-70) !important;
            }
        }

        &--is-disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        &--is-main {
            background-image: linear-gradient(90deg, var(--brand-purple) 0%, var(--brand-iris) 100%);
            box-shadow: 0 10px 15px rgba(45, 40, 243, 0.1);
            color: var(--system-white);
            font-weight: 500;
            position: relative;
            z-index: 1;

            //WE NEED THIS FOR TRANSITION LINEAR GRADIENT
            &:after {
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 13px;
                background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(90deg, var(--brand-purple) 0%, var(--brand-iris) 100%);
                transition: opacity 0.3s ease-out;
                opacity: 0;
                z-index: 2;
            }

            &:hover:after {
                opacity: 1;
            }

            span {
                z-index: 3;
            }

            /*&:active {*/
            /*    background: linear-gradient(90deg, var(--brand-main) 0%, var(--brand-main) 100%);*/
            /*}*/
        }
    }
</style>

<style lang="scss">
    .base-btn {
        svg {
            margin-right: 15px;
            z-index: 10;
        }
    }
</style>