<template>
    <div class="period-date-picker">
        <date-picker
            v-model="date"
            :locale="langCode"
            :masks="masks"
            :is-required="true"
            :min-date="today"
            :popover="{
                placement: 'bottom-end'
            }"
            @dayclick="selectDate"
        />
    </div>
</template>

<script>
    import i18n from "../../../localization/i18n";
    import 'v-calendar/dist/style.css';
    import { DatePicker } from 'v-calendar';

    export default {
        name: "AppPeriodDatePicker",

        components: {
            DatePicker
        },

        props: {
            initDate: {
                //Only Number?
                type: [String, Number, Date],
            },
            typeDate: {
                default: 'any',
                validator(type) {
                    return ['any', 'future'].includes(type);
                }
            },
            isStartDate: {
                type: Boolean,
                default: true
            }
        },

        data() {
            return {
                date: '',
                masks: {
                    input: 'DD.MM.YYYY',
                },
                timezone: 'UTC'
            }
        },

        created() {
            this.initDatePicker();
        },

        computed: {
            today() {
                return this.typeDate === 'future' ? new Date().setUTCDate(new Date().getUTCDate() + 1) : '';
            },

            langCode() {
                return i18n.global.locale;
            }
        },

        methods: {
            initDatePicker() {
                this.date = this.initDate;
            },

            selectDate() {
                const timeStampWithoutTime = this.date.getTime();

                this.$emit('update', timeStampWithoutTime);
            }
        }
    }
</script>