<template>
    <div class="finance-statement-row finance-enterprise-statement-row">
        <div class="finance-enterprise-statement-row__parent"
             :class="{
                'finance-enterprise-statement-row__parent--is-enterprise': isEnterpriseStatement,
                'finance-enterprise-statement-row__parent--is-group': isGroupStatement,
             }"
        >
            <div class="table__row">
                <div class="table__f-column table__f-column--is-start statement__name"
                     :style="{
                        'paddingLeft': `${40 * level}px`
                     }"
                >
                    <div class="water-pad__collapse-btn">
                        <app-collapse-btn v-if="isExistChildren"
                            :default-is-active="showChildren"
                            @collapse="collapseChildren"
                        />
                    </div>
                    <app-overflow-match-wrapper
                        :is-parent="true"
                        :text="name"
                    />
                </div>
                <div class="table__f-column statement__e-period">
                    <div class="table__f-column table__f-column--is-debit statement__debit">
                        <app-debit-credit-value
                            :value="startOfPeriod.debit"
                        />
                    </div>
                    <div class="table__f-column table__f-column--is-credit statement__credit">
                        <app-debit-credit-value
                            :value="startOfPeriod.credit"
                        />
                    </div>
                </div>
                <div class="table__f-column statement__e-period">
                    <div class="table__f-column table__f-column--is-debit statement__debit">
                        <app-debit-credit-value
                            :value="turnover.debit"
                        />
                    </div>
                    <div class="table__f-column table__f-column--is-credit statement__credit">
                        <app-debit-credit-value
                            :value="turnover.credit"
                        />
                    </div>
                </div>
                <div class="table__f-column statement__e-period">
                    <div class="table__f-column table__f-column--is-debit statement__debit">
                        <app-debit-credit-value
                            :value="endOfPeriod.debit"
                        />
                    </div>
                    <div class="table__f-column table__f-column--is-credit statement__credit">
                        <app-debit-credit-value
                            :value="endOfPeriod.credit"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isExistChildren && showChildren">
            <finance-enterprise-statement-row v-for="(entity, index) in children" :key="index"
                :level="level + 1"
                :name="entity.name"
                :end-of-period="entity.endOfPeriod"
                :turnover="entity.turnover"
                :start-of-period="entity.startOfPeriod"
                :children="entity.children || []"
            />
        </div>
    </div>
</template>

<script>
    import AppDebitCreditValue from "../../../common/local/AppDebitCreditValue";

    export default {
        name: "FinanceEnterpriseStatementRow",

        components: {
            AppDebitCreditValue
        },

        props: {
            level: {
                type: Number,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            endOfPeriod: {
                type: Object,
                required: true
            },
            turnover: {
                type: Object,
                required: true
            },
            startOfPeriod: {
                type: Object,
                required: true
            },
            children: {
                type: Array,
                required: true
            }
        },

        data() {
            return {
                showChildren: false
            }
        },

        created() {
            this.init();
        },

        computed: {
            isExistChildren() {
                return !!this.children.length;
            },

            isEnterpriseStatement() {
                return this.level === 0;
            },

            isGroupStatement() {
                return this.level === 1;
            }
        },

        methods: {
            init() {
                if (this.level < 1) {
                    this.showChildren = true;
                }
            },

            collapseChildren() {
                this.showChildren = !this.showChildren;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .finance-enterprise-statement-row {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__parent {
            &--is-enterprise {
                font-weight: 500;
            }

            &--is-group {
                background: var(--app-table-background) !important;
            }
        }
    }
</style>