const maxHeightMixin = {
    data() {
        return {
            maxHeight: '',
            type: ''
        }
    },
    methods: {
        subscribeToResize(type) {
            this.type = type;
            window.addEventListener('resize', this.calcMaxHeight);
            this.calcMaxHeight();
        },

        unsubscribeFromResize() {
            window.removeEventListener('resize', this.calcMaxHeight);
        },

        calcMaxHeight() {
            // debugger;

            this.maxHeight = this.$refs[this.type].offsetHeight;
        },

        // calcMenuHeight() {
        //     if (window.screen.width > 1600) {
        //         return `${this.maxHeight - 60}px`;
        //     }  else {
        //         return `${this.maxHeight - 40}px`;
        //     }
        // }
    },

    computed: {
        maxHeightScroll() {
            let maxHeight = '';

            switch (this.type) {
                case 'menu':
                    maxHeight = `${this.maxHeight - 60}px`;
                    break;
                case 'admins':
                case 'buildings':
                case 'premises':
                case 'users':
                    maxHeight = `${this.maxHeight - 50}px`;
                    break;
                case 'personalization':
                    maxHeight = `${this.maxHeight}px`;
                    break;
                case 'financeMain':
                    maxHeight = `${this.maxHeight - 51}px`;
                    break;
                case 'financeContracts':
                case 'financeInvoices':
                case 'financeReview':
                case 'financeOperations':
                    maxHeight = `${this.maxHeight - 50}px`;
                    break;
                case 'financeStatements':
                    maxHeight = `${this.maxHeight - 140}px`;
                    break;
                case 'financeTemplates':
                    maxHeight = `${this.maxHeight - 4}px`;
                    break;
                default:
                    maxHeight = `${this.maxHeight}px`;
            }

            return maxHeight;
        }
    }
};

export {
    maxHeightMixin
}