<template>
    <app-modal-wrapper>
        <div class="modal-logout">
            <h4>{{ type.text }}</h4>
            <div class="modal-logout__control">
                <app-base-btn
                    :variation="'save'"
                    :text="type.btn"
                    @action="logoutUser"
                />
                <app-base-btn
                    :variation="'cancel'"
                    :text="$t('common.buttons.cancel')"
                    @action="closeModalLogout"
                />
            </div>
        </div>
    </app-modal-wrapper>
</template>

<script>
    import { types } from "../../../constants/confirm";
    import { logout } from "../../../services/checkAccess";

    export default {
        name: "ModalLogout",

        computed: {
            type() {
                return types['logout'];
            }
        },

        methods: {
            closeModalLogout() {
                this.closeModal('modalLogout');
            },

            async logoutUser() {
                await logout()
                    .then(isSuccess => {
                        if (isSuccess) {
                            this.closeModalLogout();
                            this.$router.push({ name: 'Login' });
                        }
                    })
            },
        }
    }
</script>

<style lang="scss" scoped>
    .modal-logout {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 574px;

        &__control {
             display: flex;
             align-items: center;
             margin-top: 45px;

            button:not(:last-child) {
                margin-right: 30px;
            }
        }
    }
</style>