import { mapPhoneToView, mapPhoneToSend, defaultPhone } from "./phone";

function mapAdminsToView(admins) {
    return admins.map((admin) => {
        const username = admin.username;
        const name = admin.name ? admin.name : '';
        const surname = admin.surname ? admin.surname : '';
        const fullName = `${name} ${surname}`.trim();

        return {
            id: admin._id,
            isMaster: admin.isMaster,
            time: admin.time,
            username: username,
            tempPass: '********',
            name: name,
            surname: surname,
            fullName: fullName,
            email: admin.email ? admin.email : '',
            phone: admin.phone ? mapPhoneToView(admin.phone) : defaultPhone,
            company: admin.company ? admin.company : '',
            position: admin.position ? admin.position : '',
            abbrNoAvatar: !fullName ? username.charAt(0).toUpperCase() : `${surname.charAt(0).toUpperCase()}${name.charAt(0).toUpperCase()}`,
            avatar: admin.avatar ? admin.avatar : '',
            accessLevel: admin.accessLevel
        }
    })
}

function mapAdminToSend(admin) {
    const adminToSend = {
        name: admin.name,
        surname: admin.surname,
        email: admin.email,
        phone: mapPhoneToSend(admin.phone),
        company: admin.company,
        position: admin.position,
        accessLevel: admin.accessLevel
    }

    if (!admin.id) {
        adminToSend.username = admin.username;
        adminToSend.tempPass = admin.tempPass;
    }

    return adminToSend;
}

export {
    mapAdminsToView,
    mapAdminToSend
}