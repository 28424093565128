<template>
    <div class="table__row premise-user-row">
        <div class="table__column premise-users__name">
            {{ fullName }}
        </div>
        <div class="table__column premise-users__email">
            {{ email }}
        </div>
        <div class="table__column premise-users__phone">
            {{ formattedPhone }}
        </div>
        <div class="table__column premise-users__document-id">
            {{ documentId }}
        </div>
        <div class="table__column premise-users__is-owner">
            <svg v-if="isOwner" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.574707" y="0.5" width="25" height="25" rx="6" fill="var(--app-menu-background)"/>
                <g clip-path="url(#clip0_1210_15659)">
                    <path d="M10.3474 16.4091L6.76789 12.8295L5.57471 14.0227L10.3474 18.7955L20.5747 8.56818L19.3815 7.375L10.3474 16.4091Z" fill="var(--app-base-primary)"/>
                </g>
                <defs>
                    <clipPath id="clip0_1210_15659">
                        <rect width="15" height="15" fill="white" transform="translate(5.57471 5.5)"/>
                    </clipPath>
                </defs>
            </svg>
        </div>
        <div class="table__column premise-users__is-registered">
            <svg v-if="isRegistered" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.574707" y="0.5" width="25" height="25" rx="6" fill="var(--app-menu-background)"/>
                <g clip-path="url(#clip0_1210_15659)">
                    <path d="M10.3474 16.4091L6.76789 12.8295L5.57471 14.0227L10.3474 18.7955L20.5747 8.56818L19.3815 7.375L10.3474 16.4091Z" fill="var(--app-base-primary)"/>
                </g>
                <defs>
                    <clipPath id="clip0_1210_15659">
                        <rect width="15" height="15" fill="white" transform="translate(5.57471 5.5)"/>
                    </clipPath>
                </defs>
            </svg>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PremiseUserRow",

        props: {
            id: {
                type: String,
                required: true
            },
            surname: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            middleName: {
                type: String,
                required: true
            },
            email: {
                type: String,
                required: true
            },
            phone: {
                type: Object,
                required: true
            },
            documentId: {
                type: String,
                required: true
            },
            isOwner: {
                type: Boolean,
                required: true
            },
            isRegistered: {
                type: Boolean,
                required: true
            }
        },

        computed: {
            formattedPhone() {
                return this.phone.number
                    ? `${this.phone.code}${this.phone.number}`
                    : '';
            },

            fullName() {
                return `${this.surname} ${this.name} ${this.middleName}`;
            }
        }
    }
</script>