<template>
    <div class="color-toast"
        :style="{
            'borderColor': currentColor
        }"
    >
        <div class="color-toast__content">
            <div class="color-toast__icon-wrapper">
                <slot/>
            </div>
            <div class="color-toast__info">
                <div class="color-toast__title">
                    {{ title }}
                </div>
                <div class="color-toast__details">
                    {{ details }}
                    <span :style="{ 'color': additionalColor, 'fontWeight': 600 }">{{ additionalDetails }}</span>
                </div>
            </div>
        </div>
        <div class="color-toast__control">
            <div class="color-toast__close-btn">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.875 4.00625L10.9938 3.125L7.5 6.61875L4.00625 3.125L3.125 4.00625L6.61875 7.5L3.125 10.9938L4.00625 11.875L7.5 8.38125L10.9938 11.875L11.875 10.9938L8.38125 7.5L11.875 4.00625Z" fill="#A4AFBE"/>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AdvancedColorToast",

        props: {
            index: {
                type: Number,
                required: true
            },
            currentColor: {
                type: String,
                required: true
            },
            additionalColor: {
                type: String,
                default: ''
            },
            title: {
                type: String,
                required: true
            },
            details: {
                type: String,
                required: true
            },
            additionalDetails: {
                type: String,
                default: ''
            }
        },

        mounted() {
            this.updateSvgFillColor(this.currentColor);
        },

        methods: {
            updateSvgFillColor(color) {
                const icons = this.getArrayAllIconsMenu();
                const icon = [icons[this.index]];

                this.setFillColor(icon, color)
            },

            getArrayAllIconsMenu() {
                return Array.from(document.getElementsByClassName('color-toast__icon'));
            },

            setFillColor(icons, color) {
                icons.forEach((icon) => {
                    const nodes = icon.childNodes;

                    nodes.forEach((node) => {
                        node.style.fill = color;
                    })
                })
            }
        },

        watch: {
            currentColor(newColor) {
                this.updateSvgFillColor(newColor);
            }
        },
    }
</script>

<style lang="scss" scoped>
    .color-toast {
        background: var(--system-white);
        box-shadow: 0 2px 4px var(--app-base-primary-25);
        border-radius: 13px;
        padding: 7px;
        position: relative;
        font-size: 12px;
        color: var(--app-base-primary);
        border: 1px solid;

        &__content {
            display: flex;
            align-items: center;
            margin-right: 15px;
        }

        &__icon-wrapper {
            padding: 8px;
            margin-right: 5px;
        }

        &__title {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 5px;
        }

        &__title, &__details {
            white-space: nowrap;
        }

        &__control {
            position: absolute;
            top: 15px;
            right: 15px;
        }

        &__close-btn {
            width: 15px;
            height: 15px;
        }
    }
</style>