<template>
    <div class="app__modals">
        <modal-admin
            v-if="showModalAdmin"
            :selected-admin="selectedElement"
        />
        <modal-admin-transfer
            v-if="showModalAdminTransfer"
        />
        <modal-change-login
            v-if="showModalChangeLogin"
        />
        <modal-change-password
            v-if="showModalChangePassword"
        />
        <modal-recovery-password
            v-if="showModalRecoveryPassword"
            :is-logged-in="true"
        />
        <modal-logout
            v-if="showModalLogout"
        />
        <modal-edit-avatar
            v-if="showModalEditAvatar"
            :selected-avatar="selectedElement"
        />
        <modal-upload-icon
            v-if="showModalUploadIcon"
            :selected-icon="selectedElement"
        />
        <modal-building
            v-if="showModalBuilding"
            :selected-building="selectedElement"
        />
        <modal-group-buildings
            v-if="showModalGroupBuildings"
            :selected-group-buildings="selectedElement"
        />
        <modal-premises-autocomplete
            v-if="showModalPremisesAutocomplete"
        />
        <modal-premise
            v-if="showModalPremise"
            :selected-premise="selectedElement"
        />
        <modal-restructuring-debt
            v-if="showModalRestructuringDebt"
            :selected-client="selectedElement"
        />
        <modal-sign-contract
            v-if="showModalSignContract"
            :selected-contract="selectedElement"
        />
        <modal-terminate-contract
            v-if="showModalTerminateContract"
            :selected-contract="selectedElement"
        />
        <modal-contract
            v-if="showModalContract"
            :selected-contract="selectedElement"
        />
        <modal-invoice
            v-if="showModalInvoice"
        />
        <modal-review-invoice
            v-if="showModalReviewInvoice"
            :selected-invoice="selectedElement"
        />
        <modal-determine-payer
            v-if="showModalDeterminePayer"
            :selected-invoice="selectedElement"
        />
        <modal-template-contract
            v-if="showModalTemplateContract"
        />
        <modal-template-invoice
            v-if="showModalTemplateInvoice"
            :selected-invoice-template="selectedElement"
        />
        <modal-operation
            v-if="showModalOperation"
            :selected-user="selectedElement"
        />
        <modal-user
            v-if="showModalUser"
            :selected-user="selectedElement"
        />
        <modal-invite
            v-if="showModalInvite"
        />
        <modal-confirm
            v-if="showModalConfirm"
            :selected-element="selectedElement"
        />
    </div>
</template>

<script>
    import ModalAdmin from "../../components/modals/admins/ModalAdmin";
    import ModalAdminTransfer from "../../components/modals/admins/ModalAdminTransfer";
    import ModalChangeLogin from "../../components/modals/auth/ModalChangeLogin";
    import ModalChangePassword from "../../components/modals/auth/ModalChangePassword";
    import ModalRecoveryPassword from "../../components/modals/auth/ModalRecoveryPassword";
    import ModalLogout from "../../components/modals/auth/ModalLogout";
    import ModalEditAvatar from "../../components/modals/profile/ModalEditAvatar";
    import ModalUploadIcon from "../../components/modals/upload/ModalUploadIcon";
    import ModalBuilding from "../../components/modals/buildings/ModalBuilding";
    import ModalGroupBuildings from "../../components/modals/buildings/ModalGroupBuildings";
    import ModalPremisesAutocomplete from "./premises/ModalPremisesAutocomplete";
    import ModalPremise from "./premises/ModalPremise";
    import ModalInvite from "./invite/ModalInvite";
    import ModalUser from "./user/ModalUser";
    import ModalRestructuringDebt from "./finance/ModalRestructuringDebt";
    import ModalConfirm from "../../components/modals/ModalConfirm";
    import ModalSignContract from "./finance/ModalSignContract";
    import ModalContract from "./finance/ModalContract";
    import ModalInvoice from "./finance/ModalInvoice";
    import ModalReviewInvoice from "./finance/ModalReviewInvoice";
    import ModalDeterminePayer from "./finance/ModalDeterminePayer";
    import ModalTemplateContract from "./finance/ModalTemplateContract";
    import ModalTemplateInvoice from "./finance/ModalTemplateInvoice";
    import ModalTerminateContract from "./finance/ModalTerminateContract";
    import ModalOperation from "./finance/ModalOperation";
    import { mapGetters } from "vuex";

    export default {
        name: "AppAllModals",

        components: {
            ModalOperation,
            ModalTerminateContract,
            ModalTemplateInvoice,
            ModalTemplateContract,
            ModalDeterminePayer,
            ModalReviewInvoice,
            ModalInvoice,
            ModalContract,
            ModalSignContract,
            ModalRestructuringDebt,
            ModalPremise,
            ModalPremisesAutocomplete,
            ModalAdmin,
            ModalAdminTransfer,
            ModalChangeLogin,
            ModalChangePassword,
            ModalRecoveryPassword,
            ModalLogout,
            ModalEditAvatar,
            ModalUploadIcon,
            ModalBuilding,
            ModalGroupBuildings,
            ModalInvite,
            ModalUser,
            ModalConfirm
        },

        computed: {
            ...mapGetters({
                selectedElement: 'modals/selectedElement',
                showModalAdmin: 'modals/showModalAdmin',
                showModalAdminTransfer: 'modals/showModalAdminTransfer',
                showModalChangeLogin: 'modals/showModalChangeLogin',
                showModalChangePassword: 'modals/showModalChangePassword',
                showModalRecoveryPassword: 'modals/showModalRecoveryPassword',
                showModalLogout: 'modals/showModalLogout',
                showModalEditAvatar: 'modals/showModalEditAvatar',
                showModalUploadIcon: 'modals/showModalUploadIcon',
                showModalConfirm: 'modals/showModalConfirm',
                showModalBuilding: 'modals/showModalBuilding',
                showModalGroupBuildings: 'modals/showModalGroupBuildings',
                showModalPremisesAutocomplete: 'modals/showModalPremisesAutocomplete',
                showModalPremise: 'modals/showModalPremise',
                showModalInvite: 'modals/showModalInvite',
                showModalUser: 'modals/showModalUser',
                showModalRestructuringDebt: 'modals/showModalRestructuringDebt',
                showModalSignContract: 'modals/showModalSignContract',
                showModalTerminateContract: 'modals/showModalTerminateContract',
                showModalContract: 'modals/showModalContract',
                showModalInvoice: 'modals/showModalInvoice',
                showModalReviewInvoice: 'modals/showModalReviewInvoice',
                showModalDeterminePayer: 'modals/showModalDeterminePayer',
                showModalTemplateContract: 'modals/showModalTemplateContract',
                showModalTemplateInvoice: 'modals/showModalTemplateInvoice',
                showModalOperation: 'modals/showModalOperation'
            })
        }
    }
</script>