<template>
    <div class="wrapper" ref="wrapper"
        :class="{
            'wrapper--is-parent': isParent

        }"
        @mouseenter="actionTooltip(true)"
        @mouseleave="actionTooltip(false)"
    >
        <div class="wrapper__overflow"
             :class="{
                'wrapper--is-overflow': isOverflow
            }"
        >
            <span class="wrapper__content" ref="content">
                <span v-if="!isMatch">
                    {{ text }}
                </span>
                <span v-else v-html="match()"/>
            </span>
        </div>
        <div v-if="showTooltip" class="wrapper__tooltip">
            <div class="wrapper__tooltip-text"
                :class="{
                    'wrapper__tooltip-text--is-statement': isStatement
                }"
            >
                {{ text }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AppOverflowMatchWrapper",

        props: {
            isStatement: {
                type: Boolean,
                default: false
            },
            isParent: {
                type: Boolean,
                default: false
            },
            text: {
                type: String,
                required: true
            },
            subText: {
                type: String,
                default: ''
            },
            isMatch: {
                type: Boolean,
                default: false
            },
            scrollPosition: {
                type: Number,
                default: 0
            }
        },

        data() {
            return {
                isOverflow: false,
                showTooltip: false,
            };
        },

        mounted() {
            const availableWidth = this.$refs.wrapper.offsetWidth;
            const contentWidth = this.$refs.content.offsetWidth;

            if (availableWidth < contentWidth) {
                this.isOverflow = true;
            }
        },

        methods: {
            actionTooltip(showTooltip) {
                if (this.isOverflow) {
                    this.showTooltip = showTooltip;
                }
            },

            match() {
                //We have error with RegExp if string starts with '+'
                if(!this.subText || this.subText.startsWith('+')) {
                    return this.text;
                }

                return this.text.replace(new RegExp(this.subText, "gi"), match => {
                    return '<span class="app__highlight">' + match + '</span>';
                });
            },
        },
    }
</script>

<style scoped lang="scss">
    .wrapper {
        position: relative;

        &--is-parent {
            width: calc(100% - 30px);
        }

        &__content {
            white-space: nowrap;
        }

        &__tooltip {
            position: relative;
            z-index: 10;
        }

        &__tooltip-text {
            position: absolute;
            color: var(--system-white);
            white-space: nowrap;
            padding: 10px 15px;
            background: linear-gradient(0deg, rgba(43, 48, 55, 0.8), rgba(43, 48, 55, 0.8)), #FFFFFF;
            border-radius: 8px;
            font-weight: 400;
            font-size: 16px;
            line-height: 18.75px;
            top: 10px;
            left: -15px;

            &--is-statement {
                top: -10px;
            }
        }

        &--is-overflow {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            &:hover {
                cursor: pointer;
            }
        }
    }
</style>