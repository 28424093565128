import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

function formatCurrencyValue(currencyBalance) {
    const parts = currencyBalance.toFixed(2).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    const balance = parts.join('.');
    const currency = t('finance.data.currency');

    return { balance, currency };
}

function getPeriodsRestructuringDebt() {
    const periods = [];

    for (let i = 1; i < 13; i++) {
        let monthText = '';

        switch (i) {
            case 1:
                monthText = t('finance.months.1');
                break;
            case 2:
            case 3:
            case 4:
                monthText = t('finance.months.2');
                break;
            default:
                monthText = t('finance.months.5');
                break;
        }

        periods.push({
            id: i,
            name: `${i} ${monthText}`
        })
    }

    return periods;
}

function getPeriodsContracts() {
    const periods = [];

    for (let i = 1; i < 37; i++) {
        let months = 0;
        let years = 0;
        let monthText = '';
        let yearText = '';
        let text = '';

        if ( i < 12 ) {
            months = i;
        } else {
            years = Math.floor(i/12);

            if (i % 12 !== 0) {
                months = i % 12;
            }
        }

        switch (months) {
            case 0:
                monthText = '';
                break;
            case 1:
                monthText = t('finance.months.1');
                break;
            case 2:
            case 3:
            case 4:
                monthText = t('finance.months.2');
                break;
            default:
                monthText = t('finance.months.5');
                break;
        }

        switch (years) {
            case 1:
                yearText = t('finance.year.1');
                break;
            default:
                yearText = t('finance.year.2');
                break;
        }

        if (years === 0) {
            text = `${months} ${monthText}`;
        } else {
            if (months === 0) {
                text = `${years} ${yearText}`;
            } else {
                text = `${years} ${yearText} ${months} ${monthText}`;
            }
        }

        periods.push({
            id: i,
            name: text
        })
    }

    return periods;
}

function getContractTemplateIconColor(index) {
    let iconColor = 'var(--system-red)';

    switch (index % 8) {
        case 0:
            iconColor = 'var(--system-red)';
            break;
        case 1:
            iconColor = 'var(--system-orange)';
            break;
        case 2:
            iconColor = 'var(--system-yellow)';
            break;
        case 3:
            iconColor = 'var(--system-green)';
            break;
        case 4:
            iconColor = 'var(--system-blue)';
            break;
        case 5:
            iconColor = 'var(--system-iris)';
            break;
        case 6:
            iconColor = 'var(--system-purple)';
            break;
        case 7:
            iconColor = 'var(--system-pink)';
            break;
    }

    return iconColor;
}

export {
    formatCurrencyValue,
    getPeriodsRestructuringDebt,
    getPeriodsContracts,
    getContractTemplateIconColor
}