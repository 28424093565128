<template>
    <div class="color-picker">
        <div v-if="showLabel" class="app__label">
            {{ label }}
        </div>
        <div class="color-picker__field">
            <div class="color-picker__color">
                <div class="color-picker__palette" @click.stop="openColorPicker"
                     :style="{
                        'backgroundColor': color.hex
                     }"
                />
                <div class="color-picker__hex">
                    {{ color.hex }}
                </div>
            </div>
            <div class="color-picker__opacity">
                <span>100%</span>
            </div>
        </div>
        <div class="color-picker__sketch" ref="picker">
            <sketch v-if=showColorPicker
                v-model="color"
            />
        </div>
    </div>
</template>

<script>
    import { Sketch } from '@ckpack/vue-color';

    export default {
        name: "AppColorPicker",

        components: {
            Sketch
        },

        props: {
            colorKey: {
                type: String,
                required: true
            },

            label: {
                type: String,
                default: ''
            },

            currentColor: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                styleColor: null,
                color: {
                    hex: ''
                },
                showColorPicker: false
            }
        },

        created() {
            this.initColor();
        },

        mounted() {
            this.emitter.on('closeColorPicker', this.closeColorPicker);
            document.addEventListener('keyup', this.handlerKeyEsc);
            document.addEventListener('mousedown', this.handlerClickOutside);
        },

        unmounted() {
            this.emitter.off('closeColorPicker', this.closeColorPicker);
            document.removeEventListener('keyup', this.handlerKeyEsc);
            document.removeEventListener('mousedown', this.handlerClickOutside);
        },

        watch: {
            color(newColor, oldColor) {
                this.updateValue(newColor.hex);
            }
        },

        computed: {
            showLabel() {
                return !!this.label;
            }
        },

        methods: {
            initColor() {
                this.color.hex = this.currentColor;
            },

            updateValue(value) {
                const key = this.colorKey;

                this.$emit('update', { key, value });
            },

            openColorPicker() {
                this.showColorPicker = true;
                //EMIT CLOSE COLOR PICKER EVENT FOR CLOSE OPENED COLOR PICKERS
                this.emitter.emit('closeColorPicker', this.colorKey);
            },

            closeColorPicker(key) {
                //CHECK COLOR PICKERS THAT WE NEED TO CLOSE
                if (key !== this.colorKey) {
                    this.showColorPicker = false;
                }
            },

            handlerKeyEsc(event) {
                if (event.keyCode === 27) {
                    this.showColorPicker = false;
                }
            },

            handlerClickOutside(event) {
                if (!this.$refs.picker.contains(event.target)) {
                    this.showColorPicker = false;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .color-picker {
        display: flex;
        flex-direction: column;
        position: relative;

        &__field {
            border: 1px solid var(--app-border);
            border-radius: 13px;
            padding: 9px 15px;
            display: flex;
            justify-content: space-between;
        }

        &__palette {
            width: 30px;
            height: 30px;
            border: 1px solid var(--app-border);
            border-radius: 5px;
            margin-right: 12px;

            &:hover {
                cursor: pointer;
            }
        }

        &__color {
            display: flex;
            align-items: center;
            padding-right: 12px;
        }

        &__hex {
            font-size: 16px;
        }

        &__opacity {
            border-left: 1px solid var(--app-border);
            padding-left: 15px;
            font-size: 14px;
            color: var(--app-base-primary);
            display: flex;
            align-items: center;
        }

        &__sketch {
            position: absolute;
            top: 80px;
            left: -160px;
        }
    }
</style>

<style lang="scss">
    .vc-sketch {
        width: 350px;
        padding: 16px;
        background: #FFFFFF;
        box-shadow: 0px 16px 32px rgba(35, 23, 5, 0.26);
        border-radius: 12px;
        z-index: 10;
    }

    .vc-saturation-circle {
        height: 16px;
        width: 16px;
        border: 3px solid var(--system-white);
        outline: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: unset;
    }

    .vc-sketch-alpha-wrap, .vc-sketch-color-wrap,
    .vc-sketch-presets {
        display: none;
    }

    .vc-sketch-hue-wrap {
        height: 12px;
    }

    .vc-hue {
        border-radius: 24px !important;
    }

    .vc-hue-picker {
        height: 16px;
        width: 16px;
        border: 3px solid var(--system-white);
        outline: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: unset;
        background: transparent;
        border-radius: 50%;
        margin-top: unset;
    }

    .vc-hue-pointer {
        top: -2px !important;
    }

    .vc-sketch-sliders {
        padding: 16px 0px;
    }

    .vc-input__label {
        text-align: left !important;
        font-size: 12px !important;
        color: var(--app-base-primary) !important;
        font-weight: 700;
        padding-left: 2px;
    }

    .vc-sketch-field--single:last-child {
        display: none;
    }

    .vc-input__input {
        height: 40px;
        border: 1px solid #B6C3D3;
        border-radius: 8px;
        font-size: 16px !important;
        color: var(--app-base-primary);
    }

    .vc-saturation, .vc-saturation--black, .vc-saturation--white {
        border-radius: 0px 8px 8px 8px;
    }
</style>
