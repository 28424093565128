import i18n from "../../../../src/localization/i18n"
const { t } = i18n.global;

function mapReviewInvoicesToView(invoices) {
    return invoices.map((invoice) => {
        return {
            id: invoice._id,
            date: invoice.EntryDate,
            amount: invoice.EntryAmount,
            accountNumber: invoice.AccountNumber,
            bankName: invoice.BankName,
            details: invoice.EntryComment,
            invoiceNumber: invoice.InvoiceNumber,
            invoiceLink: invoice.InvoiceLink,
            invoiceId: invoice.invoice_id,
            userId: invoice.user_id,
            userName: invoice.UserName,
            shouldBe: invoice.ShouldBe,
            state: invoice.status
        }
    })
}

function mapReviewUserPremisesToView(balance) {
    const balancePremises = balance.premises.map((premise) => {
        return {
            id: premise.premise_id,
            name: premise.name,
            balance: premise.balance,
            amount: 0
        }
    });

    if (balance.service_balance !== 0) {
        balancePremises.push({
            id: '0',
            name: t('finance.services'),
            balance: balance.service_balance,
            amount: 0
        });
    }

    return {
        premises: balancePremises,
        invoices: mapInvoicesToSelect(balance.invoices)
    };
}

function mapInvoicesToSelect(invoices) {
    return invoices.map((invoice) => {
        return {
            id: invoice.invoice_id,
            name: `№${invoice.invoice_no}`,
            number: invoice.invoice_no,
            total: invoice.total
        }
    })
}

export {
    mapReviewInvoicesToView,
    mapReviewUserPremisesToView
}