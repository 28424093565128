import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

const passwordErrors = {
    validation: t('validation.password.validation'),
    identity: t('validation.password.identity'),
}

function validatePasswordData(password) {
    const regExpPassword = /^(?=[^A-Z\n]*[A-Z])(?=[^a-z\n]*[a-z])(?=[^0-9\n]*[0-9])(?=[^#?!@$%^&*\n-]*[#?!@$%^&*-]).{8,}$/;

    return regExpPassword.test(password);
}

export {
    validatePasswordData,
    passwordErrors
}