<template>
    <div class="finance-invoice">
        <div class="table__row">
            <div class="table__column invoice__control">
                <app-base-checkbox
                    :value="id"
                    v-model="isSelectInvoice"
                />
            </div>
            <div class="table__column invoice__number">
                <app-collapse-btn
                    @collapse="collapsePremises"
                />
                <app-circle-btn
                    @action="getAndDownloadInvoice"
                >
                    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.0179 11.875H3.26785V3.125H7.64285V1.875H3.26785C2.5741 1.875 2.01785 2.4375 2.01785 3.125V11.875C2.01785 12.5625 2.5741 13.125 3.26785 13.125H12.0179C12.7054 13.125 13.2679 12.5625 13.2679 11.875V7.5H12.0179V11.875ZM8.89285 1.875V3.125H11.1366L4.99285 9.26875L5.8741 10.15L12.0179 4.00625V6.25H13.2679V1.875H8.89285Z" fill="#2B3037"/>
                    </svg>
                </app-circle-btn>
                <div v-if="numberIsExist" class="invoice__name">
                    <app-match-string
                        :string="name"
                        :sub-string="subString"
                    />
                </div>
            </div>
            <div class="table__column invoice__user-name">
                <app-match-string
                    :string="userName"
                    :sub-string="subString"
                    :can-overflow="false"
                />
            </div>
            <div class="table__column invoice__user-document">
                <app-match-string v-if="userDocument"
                    :string="userDocument"
                    :sub-string="subString"
                />
            </div>
            <div class="table__column invoice__building">
                <app-match-string
                    :string="buildingsName"
                    :sub-string="subString"
                    :can-overflow="false"
                />
            </div>
            <div class="table__column invoice__due-date">
                {{ formattedDueDate }}
            </div>
            <div class="table__column invoice__total invoice__total--is-row">
                {{ formattedTotal }}
            </div>
            <div class="table__column invoice__state">
                <app-invoice-state
                    :state="state"
                />
            </div>
            <div class="table__column invoice__payment-date">
                <div v-if="state !== 0 && state !== 2 && payDate">
                    {{ formattedPayDate }}
                </div>
            </div>
        </div>
        <div v-if="showPremises" class="finance-invoice__premises">
            <finance-invoice-position-row v-for="(position, index) in positions" :key="position.id"
                :id="position.id"
                :index="index"
                :length="positions.length"
                :name="position.name"
                :price="position.price"
                :state="position.state"
                :sub-string="subString"
            />
        </div>
    </div>
</template>

<script>
    import FinanceInvoicePositionRow from "./FinanceInvoicePositionRow";
    import AppInvoiceState from "./AppInvoiceState";
    import { formatCurrencyValue } from "../../../helpers/finance";
    import { formatTimestampToDate } from "../../../helpers/date";
    import { getAndDownloadFile } from "../../../api/requests/common";

    export default {
        name: "FinanceInvoiceRow",

        components: {
            FinanceInvoicePositionRow,
            AppInvoiceState
        },

        props: {
            id: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            userName: {
                type: String,
                required: true
            },
            userDocument: {
                type: String,
                required: true
            },
            buildingsName: {
                type: String,
                required: true
            },
            dueDate: {
                type: Number,
                required: true
            },
            total: {
                type: Number,
                required: true
            },
            state: {
                type: Number,
                required: true
            },
            payDate: {
                type: Number,
                required: true
            },
            positions: {
                type: Array,
                required: true
            },
            link: {
                type: String,
                required: true
            },
            subString: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                isSelectInvoice: false,
                showPremises: false
            }
        },

        mounted() {
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromEvents();
        },

        watch: {
            isSelectInvoice(newState, oldState) {
                this.$emit('selectInvoice', this.id);
            }
        },

        computed: {
            numberIsExist() {
                return !!this.name;
            },

            formattedDueDate() {
                return formatTimestampToDate(this.dueDate);
            },

            formattedPayDate() {
                return formatTimestampToDate(this.payDate);
            },

            formattedTotal() {
                const { balance, currency } = formatCurrencyValue(this.total);

                return `${balance} ${currency}`;
            }
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('selectAll', this.selectInvoice);
            },

            unsubscribeFromEvents() {
                this.emitter.off('selectAll', this.selectInvoice);
            },

            collapsePremises() {
                this.showPremises = !this.showPremises;
            },

            getAndDownloadInvoice() {
                const name = `${this.$t('finance.invoice.name')} ${this.name}`.trim();
                getAndDownloadFile(this.link, name);
            },

            selectInvoice(state) {
                this.isSelectInvoice = state;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .finance-invoice {
        width: 100%;
    }
</style>