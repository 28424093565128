import i18n from "../../src/localization/i18n"
const { t } = i18n.global;
import { validatePhoneNumber } from "./common";
import { validateEmail } from "./login";

const userErrors = {
    phone: t('validation.phone'),
    email: t('validation.email'),
    oneOf: t('validation.oneOf'),
    selectedUser: t('validation.user.selected'),
}

const userPremiseErrors = {
    selectedPremise: t('validation.premise.selected'),
}

function validateUser(user, isNew, selectedUser) {
    let isValid = false;

    const errors = {
        name: '',
        surname: '',
        email: '',
        phone: '',
        selectedUser: ''
    }

    if (isNew) {
        let isValidEmail = true;
        let isValidPhone = true;

        const isValidOneOf = !!((user.name && user.surname) || user.email || user.phone.number);

        if (!isValidOneOf) {
            errors.name = userErrors.oneOf;
            errors.surname = userErrors.oneOf;
            errors.email = userErrors.oneOf;
            errors.phone = userErrors.oneOf;
        }

        if (user.email) {
            isValidEmail = validateEmail(user.email);

            if (!isValidEmail) {
                errors.email = userErrors.email;
            }
        }

        if (user.phone.number) {
            isValidPhone = validatePhoneNumber(user.phone.number);

            if (!isValidPhone) {
                errors.phone = userErrors.phone;
            }
        }

        isValid = isValidEmail && isValidPhone && isValidOneOf;
    } else {
        const isValidSelectedUser = !!selectedUser;

        if (isValidSelectedUser) {
            isValid = true;
        } else {
            errors.selectedUser = userErrors.selectedUser;
        }
    }

    return { isValid, errors };
}

function validateUserPremise(selectedPremise) {
    let isValid = false;

    const errors = {
        selectedPremise: ''
    };

    const isValidSelectedUserPremise = !!selectedPremise;

    if (isValidSelectedUserPremise) {
        isValid = true;
    } else {
        errors.selectedPremise = userPremiseErrors.selectedPremise;
    }

    return { isValid, errors };
}

export {
    validateUser,
    validateUserPremise
}