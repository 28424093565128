import { Auth } from 'aws-amplify';
// import router from "../router";

async function checkAccess() {
    let userSession = null;

    await Auth.currentSession()
        .then((session) => {
            const idTokenExpire = session.getIdToken().getExpiration();
            const refreshToken = session.getRefreshToken();
            const currentTimeSeconds = Math.round(+new Date() / 1000);

            if (idTokenExpire < currentTimeSeconds) {
                Auth.currentAuthenticatedUser()
                    .then((user) => {
                        user.refreshSession(refreshToken, (error, session) => {
                            if (error) {
                                Auth.signOut({ global: true });
                                handleErrors(error);
                            } else {
                                userSession = session;
                            }
                        });
                    });
            } else {
                userSession = session;
                // verifyAuthToken(refreshToken);
            }
        })
        .catch((error) => {
            // No logged-in user: don't set auth header;
            handleErrors(error);
        });

    return userSession;
}

// async function verifyAuthToken(refreshToken) {
//     //Check the token, because after signOut the session is saved in local storage
//     //Maybe not here, maybe review 401 in axios?
//     await Auth.currentAuthenticatedUser()
//         .then((user) => {
//             user.refreshSession(refreshToken, (error) => {
//                 if (error) {
//                     Auth.signOut();
//                     router.push({ name: 'Login' });
//                     handleErrors(error);
//                 }
//             });
//         });
// }

async function login(access) {
    let userSession = null;

    await Auth.signIn(access.login, access.password)
        .then(session => {
            userSession = session;
        })
        .catch(error => {
            handleErrors(error);
        })

    return userSession;
}

async function completeNewPassword(session, newPassword) {
    let userSession = null;

    await Auth.completeNewPassword(session, newPassword)
        .then(session => {
            userSession = session;
        })
        .catch(error => {
            handleErrors(error);
        });

    return userSession;
}

async function forgotPassword(userName) {
    let isSuccess = false;

    await Auth.forgotPassword(userName)
        .then(() => {
            isSuccess = true;
        })
        .catch(error => {
            handleErrors(error);
        });

    return isSuccess;
}

async function forgotPasswordSubmit(username, code, newPassword) {
    let isSuccess = false;

    await Auth.forgotPasswordSubmit(username, code, newPassword)
        .then(() => {
            isSuccess = true;
        })
        .catch(error => {
            handleErrors(error);
        });

    return isSuccess;
}

async function logout() {
    let isSuccess = false;

    await Auth.signOut({ global: true })
        .then(() => {
            isSuccess = true;
        })
        .catch(error => {
            handleErrors(error);
        });

    return isSuccess;
}

async function getCurrentUserInfo() {
    return await Auth.currentUserInfo();
}

async function updateUserAttributes(newLogin, isEmailLogin) {
    const user = await Auth.currentAuthenticatedUser();

    const attributes = isEmailLogin
        ? {
            'email': newLogin,
        }
        : {
            'phone_number': newLogin
        };


    return await Auth.updateUserAttributes(user, attributes);
}

async function verifyCurrentUserAttribute() {
    await Auth.verifyCurrentUserAttribute('email');
}

async function verifyCurrentUserAttributeSubmit(code) {
    await Auth.verifyCurrentUserAttributeSubmit('email', code);
}

async function changePassword(oldPassword, newPassword) {
    const currentUser = await Auth.currentAuthenticatedUser();

    return await Auth.changePassword(currentUser, oldPassword, newPassword);
}

function handleErrors(error) {
    console.log(error);
}

export {
    checkAccess,
    login,
    forgotPassword,
    forgotPasswordSubmit,
    logout,
    completeNewPassword,
    getCurrentUserInfo,
    updateUserAttributes,
    verifyCurrentUserAttribute,
    verifyCurrentUserAttributeSubmit,
    changePassword
}