import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

const profilePhotoTypes = [
    'image/png',
    'image/jpeg',
    'image/pjpeg'
]

const adminPhotoTypes = [
    'image/png',
    'image/jpeg',
    'image/pjpeg'
]

const logoTypes = [
    'image/png',
    'image/svg+xml'
]

const iconTypes = [
    'image/png',
    'image/svg+xml'
]

const buildingLogoTypes = [
    'image/png',
    'image/svg+xml'
]
//
// const typesContacts = [
//     'image/png',
//     "image/jpeg",
//     "image/pjpeg"
// ]
//
// const typesPolls = [
//     'image/png',
//     "image/jpeg",
//     "image/pjpeg"
// ]
//
// const typesNews = [
//     'image/png',
//     "image/jpeg",
//     "image/pjpeg"
// ]
//
// const typesServices = [
//     'image/png',
//     "image/jpeg",
//     "image/pjpeg"
// ]
//
// const cameraImageTypes = [
//     'image/png',
//     "image/jpeg",
//     "image/pjpeg"
// ]
//
// const typesFaces = [
//     'image/png',
//     "image/jpeg",
//     "image/pjpeg"
// ]

const uploadTypes = {
    image: {
        helpText: t('upload.image.helpText'),
        btnText: t('upload.image.btn')
    },
    logo: {
        helpText: t('upload.logo.helpText'),
        btnText: t('upload.logo.btn')
    },
    document: {
        helpText: t('upload.file.helpText'),
        btnText: t('upload.file.btn')
    },
    // face: {
    //     helpText: t('upload.face.helpText'),
    //     btnText: t('upload.face.btn')
    // }
}

const stencilTypes = {
    logo: {
        previewClass: 'preview',
        aspectRatio: 1/1
    },
    // news: {
    //     previewClass: 'preview',
    //     aspectRatio: 16/9
    // },
    // polls: {
    //     previewClass: 'preview',
    //     aspectRatio: 311/165
    // },
    // camera: {
    //     previewClass: 'preview',
    //     aspectRatio: 343/185
    // },
    // face: {
    //     previewClass: 'preview',
    //     aspectRatio: 16/9
    // }
}

export {
    buildingLogoTypes,
    profilePhotoTypes,
    adminPhotoTypes,
    uploadTypes,
    stencilTypes,
    iconTypes,
    logoTypes
}