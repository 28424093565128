function mapInvoicesToView(invoices) {
    return invoices.map((invoice) => {
        return {
            id: invoice._id,
            number: invoice.invoice_no ? invoice.invoice_no : '',
            name: invoice.invoice_no ? `№${invoice.invoice_no}` : '',
            userId: invoice.user_id,
            userName: invoice.user_name,
            userDocument: invoice.user_document ? invoice.user_document : '',
            buildingsName: mapInvoiceBuildingsToView(invoice.building_data).join(', '),
            total: invoice.total,
            state: invoice.status,
            dueDate: invoice.due_date,
            payDate: invoice.pay_date ? invoice.pay_date : 0,
            link: invoice.file,
            positions: mapInvoicePositionsToView(invoice.positions)
        }
    })
}

function mapInvoicesQtyTypesToView(qty) {
    return {
        overpayment: qty.overpayment,
        underpayment: qty.underpayment,
        overdue: qty.overdue
    }
}

function mapInvoiceBuildingsToView(buildings) {
    const buildingsName = buildings.map((building) => {
        const groupId = building.building_group_id ? building.building_group_id : '';
        const groupName = building.building_group_name ? building.building_group_name : '';
        const buildingName = building.building_name;

        return groupId ? `${groupName}/${buildingName}`: buildingName;
    })

    return Array.from(new Set(buildingsName));
}

function mapInvoicePositionsToView(positions) {
    return positions.map((position) => {
        return {
            id: position._id,
            name: position.name,
            price: position.price,
            state: mapInvoicePositionStateToView(position.status)
        }
    })
}

function mapInvoicePositionStateToView(state) {
    return {
        code: state.code,
        price: state.price
    }
}

function mapInvoiceToSend(invoice) {
    return {
        invoice_no: invoice.number,
        user_id: invoice.userId,
        due_date: invoice.dueDate,
        positions: mapInvoicePositionsToSend(invoice.positions)
    }
}

function mapInvoicePositionsToSend(positions) {
    return positions.map((position) => {
        return {
            name: position.name,
            qty: position.qty,
            price: Number(position.price),
        }
    })
}


export {
    mapInvoicesToView,
    mapInvoicesQtyTypesToView,
    mapInvoiceToSend
}