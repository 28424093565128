import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

const types = {
    logout: {
        text: t('confirm.logout.text'),
        btn: t('confirm.logout.btn')
    },
    leave: {
        text: t('confirm.leave.text'),
        btn: t('confirm.leave.btn'),
        cancelBtn: t('confirm.leave.cancelBtn'),
        event: 'leaveRoute'
    },
    settings: {
        text: t('confirm.settings.text'),
        btn: t('confirm.settings.btn'),
        cancelBtn: t('common.buttons.cancel'),
        event: 'deleteSettings'
    },
    avatar: {
        text: t('confirm.avatar.text'),
        btn: t('confirm.avatar.btn'),
        cancelBtn: t('common.buttons.cancel'),
        event: 'deleteAvatar'
    },
    admin: {
        text1: t('confirm.admin.text1'),
        text2: t('confirm.admin.text2'),
        btn: t('confirm.admin.btn'),
        cancelBtn: t('common.buttons.cancel'),
        event: 'deleteAdmin'
    },
    groupBuildings: {
        text1: t('confirm.groupBuildings.text1'),
        text2: t('confirm.groupBuildings.text2'),
        btn: t('confirm.groupBuildings.btn'),
        cancelBtn: t('common.buttons.cancel'),
        event: 'deleteGroupBuildings'
    },
    building: {
        text1: t('confirm.building.text1'),
        text2: t('confirm.building.text2'),
        btn: t('confirm.building.btn'),
        cancelBtn: t('common.buttons.cancel'),
        event: 'deleteBuilding'
    },
    premise: {
        text1: t('confirm.premise.text1'),
        text2: t('confirm.premise.text2'),
        btn: t('confirm.premise.btn'),
        cancelBtn: t('common.buttons.cancel'),
        event: 'deletePremise'
    },
    user: {
        text: `${t('confirm.user.text1')} ${t('confirm.user.text2')}`,
        btn: t('confirm.user.btn'),
        cancelBtn: t('common.buttons.cancel'),
        event: 'deleteUser'
    },
    signContract: {
        text: t('confirm.signContract.text'),
        btn: t('confirm.signContract.btn')
    },
    terminateContract: {
        text: t('confirm.terminateContract.text'),
        btn: t('confirm.terminateContract.btn')
    },
    contract: {
        text: `${t('confirm.contract.text1')} ${t('confirm.contract.text2')}`,
        text1: t('confirm.contract.text1'),
        text2: t('confirm.contract.text2'),
        btn: t('confirm.contract.btn'),
        cancelBtn: t('common.buttons.cancel'),
        event: 'deleteContracts'
    },
    contracts: {
        text: `${t('confirm.contracts.text1')} ${t('confirm.contracts.text2')}`,
        btn: t('confirm.contracts.btn'),
        cancelBtn: t('common.buttons.cancel'),
        event: 'deleteContracts'
    },
    invoice: {
        text: `${t('confirm.invoice.text1')} ${t('confirm.invoice.text2')}`,
        text1: t('confirm.invoice.text1'),
        text2: t('confirm.invoice.text2'),
        btn: t('confirm.invoice.btn'),
        cancelBtn: t('common.buttons.cancel'),
        event: 'deleteInvoices'
    },
    invoices: {
        text: `${t('confirm.invoices.text1')} ${t('confirm.invoices.text2')}`,
        btn: t('confirm.invoices.btn'),
        cancelBtn: t('common.buttons.cancel'),
        event: 'deleteInvoices'
    },
    invoiceTemplate: {
        text1: t('confirm.invoiceTemplate.text1'),
        text2: t('confirm.invoiceTemplate.text2'),
        btn: t('confirm.invoiceTemplate.btn'),
        cancelBtn: t('common.buttons.cancel'),
        event: 'deleteInvoiceTemplate'
    },
    contractTemplate: {
        text1: t('confirm.contractTemplate.text1'),
        text2: t('confirm.contractTemplate.text2'),
        btn: t('confirm.contractTemplate.btn'),
        cancelBtn: t('common.buttons.cancel'),
        event: 'deleteContractTemplate'
    },
    reviewInvoice: {
        text: t('confirm.reviewInvoice.text'),
        btn: t('confirm.reviewInvoice.btn'),
        cancelBtn: t('common.buttons.cancel'),
        event: 'deleteReviewInvoice'
    },
    operation: {
        text: t('confirm.operation.text'),
        btn: t('confirm.operation.btn'),
        cancelBtn: t('common.buttons.cancel'),
        event: 'deleteOperation'
    }
}

export {
    types
}