<template>
    <div class="group-buildings">
        <div class="table__row table__row--is-group" @click="handlerClick">
            <div class="table__column building__name building__name--is-group">
                <div class="building__collapse-btn">
                    <app-collapse-btn v-if="buildingsIsExist"
                        @collapse="collapseBuildings"
                    />
                </div>
                <app-overflow-match-wrapper
                    :text="name"
                    :sub-text="subString"
                    :is-match="true"
                    :scroll-position="scrollPosition"
                />
            </div>
            <div class="table__column building__logo"
                 :class="{
                    'building__logo--is-more-16' : isMore16,
                    'building__logo--is-more-32': isMore32
                 }"
            >
                <img v-if="logo" :src="logo" alt="logo">
                <div v-if="!logo && !logoIsLoaded" class="building__logo-name">
                    <div>{{ firstPart }}</div>
                    <div v-if="!!secondPart" class="building__logo--is-second-part">
                        {{ secondPart }}
                    </div>
                </div>
                <app-loader v-if="!logo && logoIsLoaded"
                    :size="'small'"
                />
            </div>
            <div class="table__column building__city">
                <app-overflow-match-wrapper
                    :text="city"
                    :sub-text="subString"
                    :is-match="true"
                    :scroll-position="scrollPosition"
                />
            </div>
            <div class="table__column building__address"></div>
            <div class="table__column building__entrances"></div>
            <div class="table__column building__floors"></div>
            <div class="table__column building__living">
                <premises-type-qty v-if="premisesIsExist"
                    :type="'living'"
                    :qty="premiseTypes.living"
                />
            </div>
            <div class="table__column building__apartments">
                <premises-type-qty v-if="premisesIsExist"
                    :type="'apts'"
                    :qty="premiseTypes.apts"
                />
            </div>
            <div class="table__column building__commercial">
                <premises-type-qty v-if="premisesIsExist"
                    :type="'commercial'"
                    :qty="premiseTypes.commercial"
                />
            </div>
            <div class="table__column building__technical">
                <premises-type-qty v-if="premisesIsExist"
                    :type="'technical'"
                    :qty="premiseTypes.technical"
                />
            </div>
            <div class="table__column building__parking">
                <premises-type-qty v-if="premisesIsExist"
                    :type="'parking'"
                    :qty="premiseTypes.parking"
                />
            </div>
        </div>
        <div v-if="showBuildings" class="group-buildings__buildings">
            <div class="table__body">
                <building-row v-for="building in buildings" :key="building.id"
                    :id="building.id"
                    :name="building.name"
                    :logo-url="building.logo"
                    :location="building.location"
                    :entrances="building.entrances"
                    :floors="building.floors"
                    :premise-types="building.premiseTypes"
                    :cities="cities"
                    :sub-string="subString"
                    :scroll-position="scrollPosition"
                    @update="updateBuilding"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import PremisesTypeQty from "./PremisesTypeQty";
    import BuildingRow from "./BuildingRow";
    import { getImageFromUrl } from "../../api/requests/common";
    import i18n from "../../localization/i18n";

    export default {
        name: "GroupBuildingsRow",

        components: {
            PremisesTypeQty,
            BuildingRow
        },

        data() {
            return {
                showBuildings: true,
                logo: '',
                logoIsLoaded: false,
                isMore16: false,
                isMore32: false,
                firstPart: '',
                secondPart: ''
            }
        },

        props: {
            id: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            logoUrl: {
                type: String,
                required: true
            },
            cityId: {
                type: String,
                required: true
            },
            premiseTypes: {
                type: Object
            },
            buildings: {
                type: Array,
                required: true
            },
            cities: {
                type: Array,
                required: true
            },
            subString: {
                type: String,
                required: true
            },
            scrollPosition: {
                type: Number,
                required: true
            }
        },

        created() {
            this.initBuilding();
        },

        computed: {
            premisesIsExist() {
                return !!this.premiseTypes;
            },

            buildingsIsExist() {
                return !!this.buildings.length;
            },

            langCode() {
                return i18n.global.locale;
            },

            city() {
                return this.cityId
                    ? this.cities.find((city) => city.id === this.cityId)?.name[this.langCode]
                    : '';
            },
        },

        methods: {
            collapseBuildings() {
                this.showBuildings = !this.showBuildings;
            },

            initBuilding() {
                if (this.logoUrl) {
                    this.getBuildingLogo(this.logoUrl);
                } else {
                    this.logo = '';
                    this.modifyBuildingLogoName();
                }
            },

            async getBuildingLogo(imageUrl) {
                this.logoIsLoaded = true;

                await getImageFromUrl(imageUrl)
                    .then((image) => {
                        this.logo = image;
                    })
                    .finally(() => {
                        this.logoIsLoaded = false;
                    })
            },

            modifyBuildingLogoName() {
                if (this.name.length <= 16) {
                    this.firstPart = this.name;
                } else {
                    if (this.name.length >= 64) {
                        this.firstPart = this.name.substring(0, 32);
                        this.secondPart = `${this.name.substring(32, 62)}...`;
                        this.isMore32 = true;
                    } else if (this.name.length <= 32) {
                        this.firstPart = this.name;
                    } else {
                        this.firstPart = this.name.substring(0, 32);
                        this.secondPart = this.name.substring(32, this.name.length);
                        this.isMore32 = true;
                    }

                    this.isMore16 = true;
                }
            },

            handlerClick() {
                this.$emit('update', this.id);
            },

            updateBuilding(id) {
                const building = this.buildings.find((building) => building.id === id);

                this.openModal({
                    name: 'modalBuilding',
                    selectedEl: building
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .group-buildings {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__buildings {
            display: flex;
            flex-direction: column;
        }

        .table__row--is-group:hover {
            cursor: pointer;
        }
    }
</style>
