import { mapPhoneToView, mapPhoneToSend, defaultPhone } from "./phone";

function mapUsersToView(users) {
    return users.map((user) => {
        return {
            id: user._id,
            accessLink: user.access_link ? user.access_link : '',
            accessCode: user.access_code ? user.access_code : '',
            isOwner: user.owner ? user.owner : false,
            isRegistered: user.registered ? user.registered : false,
            name: user.name,
            surname: user.surname,
            middleName: user.middlename,
            email: user.email ? user.email : '',
            documentId: user.document_id,
            phone: user.phone ? mapPhoneToView(user.phone) : defaultPhone,
            state: user.verify_status,
            premises: user.premises ? mapUserPremisesToView(user.premises) : []
        }
    })
}

function mapUserPremisesToView(premises) {
    return premises.map((premise) => {
        return {
            id: premise.premise.id,
            name: premise.premise.name,
            isOwner: premise.owner,
            isRegistered: premise.registered,
            buildingGroup: premise.buildingGroup ? mapPremiseBuildingGroupToView(premise.buildingGroup) : null,
            building: mapPremiseBuildingToView(premise.building),
            premise: mapPremiseToView(premise.premise)
        }
    })
}

function mapUsersToSelect(users, isFinanceOperations) {
    return users.map((user) => {
        const userDetails = [];

        if (user.surname) {
            const name = `${user.surname} ${user.name} ${user.middleName}`.trim();
            userDetails.push(name);
        }

        if (!isFinanceOperations) {
            if (user.email) {
                userDetails.push(user.email);
            }

            if (user.phone.number) {
                userDetails.push(`${user.phone.code}${user.phone.number}`);
            }
        } else {
            if (user.documentId) {
                userDetails.push(user.documentId);
            }
        }

        const fullName = userDetails.join('/');

        return {
            id: user.id,
            name: fullName,
            info: user.surname && user.name ? `${user.surname} ${user.name} ${user.middleName}`.trim() : '',
            phone: user.phone.number ? `${user.phone.code}${user.phone.number}` : '',
            documentId: user.documentId ? user.documentId : '',
            email: user.email ? user.email : '',
            premises: user.premises
        }
    })
}

function mapPremiseBuildingGroupToView(group) {
    return {
        id: group.id,
        name: group.name
    }
}

function mapPremiseBuildingToView(building) {
    return {
        id: building.id,
        name: building.name
    }
}

function mapPremiseToView(premise) {
    return {
        id: premise.id,
        name: premise.name,
        area: premise.area ? premise.area : 0
    }
}

function mapUserToSend(user, inviteVariant, isPremise) {
    const userToSend = {
        name: user.name,
        surname: user.surname,
        middlename: user.middleName,
        phone: mapPhoneToSend(user.phone),
        document_id: user.documentId
    }

    if (!isPremise) {
        userToSend.premises = mapUserPremisesToSend(user.premises)
    }

    if (user.email) {
        userToSend.email = user.email;
    }

    if (inviteVariant && !isPremise) {
        userToSend.send_sms = inviteVariant === 'both' || inviteVariant === 'sms';
        userToSend.send_email = inviteVariant === 'both' || inviteVariant === 'email';
    }

    if (isPremise) {
        userToSend.owner = user.isOwner;
        userToSend.registered = user.isRegistered
    }

    return userToSend;
}

function mapUserPremisesToSend(premises) {
    return premises.map((premise) => {
        return {
            id: premise.premiseId,
            owner: premise.isOwner,
            registered: premise.isRegistered
        }
    })
}

function mapPremiseExistUsersToSend(users) {
    return users.map((user) => {
        return {
            id: user.selectedUser.id,
            owner: user.isOwner,
            registered: user.isRegistered
        }
    })
}

function mapPremiseNewUsersToSend(users, inviteVariant) {
    return {
        users: users.map((user) => mapUserToSend(user, inviteVariant, true)),
        send_sms: inviteVariant === 'both' || inviteVariant === 'sms',
        send_email: inviteVariant === 'both' || inviteVariant === 'email'
    }
}

export {
    mapUsersToView,
    mapUserToSend,
    mapUsersToSelect,
    mapUserPremisesToView,
    mapPremiseExistUsersToSend,
    mapPremiseNewUsersToSend
}