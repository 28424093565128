<template>
    <div class="finance-review-table review-invoice">
        <div class="table__head">
            <div class="table__column review-invoice__date">
                <app-overflow-match-wrapper
                    :text="$t('finance.review.table.date')"
                />
            </div>
            <div class="table__column review-invoice__amount">
                <app-overflow-match-wrapper
                    :text="$t('finance.review.table.paid')"
                />
            </div>
            <div class="table__column review-invoice__payment">
                <app-overflow-match-wrapper
                    :text="$t('finance.review.table.payment')"
                />
            </div>
            <div class="table__column review-invoice__user-name">
                <app-overflow-match-wrapper
                    :text="$t('finance.review.table.client')"
                />
            </div>
            <div class="table__column review-invoice__invoice-number">
                <app-overflow-match-wrapper
                    :text="$t('finance.review.table.number')"
                />
            </div>
            <div class="table__column review-invoice__should-be">
                <app-overflow-match-wrapper
                    :text="$t('finance.review.table.exhibited')"
                />
            </div>
            <div class="table__column review-invoice__state">
                <app-overflow-match-wrapper
                    :text="$t('finance.review.table.state')"
                />
            </div>
            <div class="table__column review-invoice__actions">
<!--                <app-overflow-match-wrapper-->
<!--                    :text="$t('finance.review.table.actions')"-->
<!--                />-->
            </div>
        </div>
        <div class="table__body" :style="{ 'maxHeight': maxHeight }">
            <finance-review-invoice-row v-for="invoice in invoices" :key="invoice.id"
                :id="invoice.id"
                :date="invoice.date"
                :amount="invoice.amount"
                :bank-name="invoice.bankName"
                :account-number="invoice.accountNumber"
                :details="invoice.details"
                :user-id="invoice.userId"
                :user-name="invoice.userName"
                :invoice-link="invoice.invoiceLink"
                :invoice-number="invoice.invoiceNumber"
                :should-be="invoice.shouldBe"
                :state="invoice.state"
                :sub-string="searchSubString"
                @review="reviewInvoice"
                @delete="deleteInvoice"
            />
        </div>
    </div>
</template>

<script>
    import FinanceReviewInvoiceRow from "./FinanceReviewInvoiceRow";

    export default {
        name: "FinanceReviewTable",

        components: {
            FinanceReviewInvoiceRow
        },

        props: {
            invoices: {
                type: Array,
                required: true
            },
            maxHeight: {
                type: String,
                required: true
            },
            searchSubString: {
                type: String,
                required: true
            }
        },

        methods: {
            reviewInvoice(id) {
                const invoice = this.invoices.find((invoice) => invoice.id === id);

                if (invoice.state !== 0) {
                    this.openModal({
                        name: 'modalReviewInvoice',
                        selectedEl: invoice
                    })
                }
            },

            deleteInvoice(id) {
                const selectedElement = {
                    id: id,
                    type: 'reviewInvoice'
                };

                this.openModal({
                    name: 'modalConfirm',
                    selectedEl: selectedElement
                })
            }
        }
    }
</script>