<template>
    <div class="access-element">
        <div class="access-element__column">
            <label class="checkbox">
                <input
                    v-model="isChecked"
                    type="checkbox"
                    @input="updateValue"
                />
                <div class="checkbox__label">
                    {{ label }}
                </div>
                <span class="checkmark"></span>
            </label>
        </div>
        <div v-if="!isNotToggle" class="access-element__column">
            <div class="toggle"
                 :class="{
                    'toggle--is-view': isViewAccess,
                    'toggle--is-edit': isEditAccess,
                    'toggle--is-not-checked': !isChecked
                 }"
            >
                <div class="toggle__element" @click="handlerClickToggle">
                    <div class="toggle__circle"></div>
                </div>
                <div class="toggle__label">
                    {{ toggleLabel }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AdminAccessElement",

        props: {
            index: {
                type: Number,
                required: true
            },
            value: {
                type: String,
                required: true
            },
            label: {
                type: String,
                required: true
            },
            isNotToggle: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                isChecked: false,
                isToggled: false
            }
        },

        created() {
            this.initAccessElement();
        },

        computed: {
            isViewAccess() {
                return this.isChecked && !this.isToggled;
            },

            isEditAccess() {
                return this.isChecked && this.isToggled;
            },

            toggleLabel() {
                return this.isChecked
                    ? this.isViewAccess
                    ? this.$t('admins.modal.access.viewAccess')
                    : this.$t('admins.modal.access.editAccess')
                    : this.$t('admins.modal.access.isNotAccess')
            }
        },

        methods: {
            initAccessElement() {
                this.isChecked = !!parseInt(this.value.substring(0,1));

                if (!this.isNotToggle) {
                    this.isToggled = !!parseInt(this.value.substring(1,2));
                }
            },

            handlerClickToggle() {
                this.isToggled = !this.isToggled;
                this.updateValue();
            },

            updateValue() {
                setTimeout(() => {
                    const checkedString = this.isChecked ? '1' : '0';
                    const toggledString = this.isChecked
                        ? this.isToggled ? '1' : '0'
                        : '0';
                    const accessLevelString = this.isNotToggle ?  `${checkedString}` : `${toggledString}${checkedString}`;
                    this.$emit('update', this.index, accessLevelString);
                }, 0)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .access-element {
        display: flex;
        padding: 15px 0;
        border-bottom: 1px solid var(--app-border);

        &__column {
            flex-basis: 50%;
            height: 25px;
        }

        &__access {
            display: flex;
        }
    }

    .toggle {
        display: flex;
        align-items: center;

        &__element {
            width: 48px;
            height: 25px;
            display: flex;
            align-items: center;
            padding: 2px;
            border-radius: 100px;
            background: rgba(182, 195, 211, 0.5);
            margin-right: 15px;

            &:hover {
                cursor: pointer;
            }
        }

        &__circle {
            width: 21px;
            height: 21px;
            border-radius: 100px;
            background: var(--system-white);
        }

        &__label {
            font-size: 12px;
        }

        &--is-view {
            .toggle__element {
                background: var(--brand-pink);
            }
        }

        &--is-edit {
            .toggle__element {
                background: var(--brand-main);
                justify-content: end;
            }
        }

        &--is-not-checked {
            .toggle__element {
                pointer-events: none;
            }
        }
    }

    .checkbox {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 19px;
        height: 25px;
        color: var(--app-base-primary);
        position: relative;
        padding-left: 37px;
        margin-bottom: 12px;

        &__label {
            white-space: nowrap;
        }

        input {
            opacity: 0;
            height: 0;
            width: 0;
        }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            border: 1px solid var(--app-border);
            border-radius: 6px;
        }

        .checkmark:hover {
            cursor: pointer;
        }

        input:checked ~ .checkmark {
            border: none;
            background: var(--brand-main);
        }

        input:checked ~ .checkmark:after {
            display: block;
        }

        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
            left: 9px;
            top: 3px;
            width: 6px;
            height: 13px;
            border: solid var(--system-white);
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
</style>