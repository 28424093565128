<template>
    <div class="finance-main-table table">
        <h4>{{ $t('finance.balance') }}</h4>
        <div class="table__body" :style="{ 'maxHeight': maxHeight }">
            <finance-main-group-row v-for="group in buildingGroups" :key="group.id"
                :id="group.id"
                :name="group.name"
                :balance="group.balance"
                :buildings="group.buildings"
                :sub-string="searchSubString"
            />
            <finance-main-building-row v-for="building in freeBuildings" :key="building.id"
                :is-free-building="true"
                :id="building.id"
                :name="building.name"
                :balance="building.balance"
                :users="building.users"
                :sub-string="searchSubString"
            />
        </div>
    </div>
</template>

<script>
    import FinanceMainGroupRow from "./FinanceMainGroupRow";
    import FinanceMainBuildingRow from "./FinanceMainBuildingRow";

    export default {
        name: "FinanceMainTable",

        components: {
            FinanceMainGroupRow,
            FinanceMainBuildingRow
        },

        props: {
            buildingGroups: {
                type: Array,
                required: true
            },
            freeBuildings: {
                type: Array,
                required: true
            },
            maxHeight: {
                type: String,
                required: true
            },
            searchSubString: {
                type: String,
                required: true
            }
        }
    }
</script>

<style lang="scss">
    .finance-main-table {
        h4 {
            padding: 0 0 15px 30px;
            line-height: 35px;
            border-bottom: 1px solid var(--app-border);
        }

        &__row {
            min-height: 50px !important;
            padding: 0 30px !important;
        }

        &__name {
            width: 60%;
            display: flex;
            align-items: center;

            &--is-group {
                font-weight: 700;
            }

            //TODO: NOT GOOD THIS

            &--is-not-exist-buildings {
                padding-left: 25px;
            }

            &--is-building {
                padding-left: 25px;
            }

            &--is-free-building {
                padding-left: 0;
            }

            &--is-not-exist-users {
                padding-left: 25px;
            }

            &--is-user {
                padding-left: 50px;
            }

            &--is-free-user {
                padding-left: 25px;
            }

            &--is-premise {
                padding-left: 75px;
            }

            &--is-free-premise {
                padding-left: 50px;
            }

            button {
                margin-right: 15px;
            }
        }

        &__document-id {
            width: 20%;
        }

        &__user-id-row {
            display: flex;
            align-items: center;
        }

        &__user-id {
            margin-right: 15px;
        }

        &__total {
            width: 20%;
        }

        /*&__building {*/
        /*    &--is-free {*/
        /*        padding-left: unset !important;*/
        /*    }*/
        /*}*/
    }
</style>