import { createFloorsList } from "../../helpers/buildings";

function mapGroupBuildingsToView(groups) {
    return groups.map((group) => {
        return {
            id: group._id,
            name: group.name,
            logo: group.logo ? group.logo : '',
            cityId: group.city_id ? group.city_id : '',
            premiseTypes: mapPremiseTypesToView(group.premise_types),
            buildings: mapBuildingsToView(group.buildings, group.name)
        }
    })
}

function mapBuildingsToView(buildings, groupName) {
    return buildings.map((building) => {
        return {
            id: building._id,
            name: building.name,
            logo: building.logo ? building.logo : '',
            premiseTypes: mapPremiseTypesToView(building.premise_types),
            location: mapLocationToView(building.location),
            entrances: building.entrances.toString(),
            minFloor: building.min_floor.toString(),
            maxFloor: building.max_floor.toString(),
            isZeroFloor: building.ground_floor,
            floors: createFloorsList(building.min_floor, building.max_floor, building.ground_floor).length,
            groupId: building.groupId,
            groupName: groupName ? groupName : 'free'
        }
    })
}

function mapPremiseTypesToView(types) {
    return {
        living: types.living,
        commercial: types.commercial,
        apts: types.apts,
        technical: types.technical,
        parking: types.parking
    }
}

function mapLocationToView(location) {
    return {
        placeId: location.place_id,
        longitude: location.lon,
        latitude: location.lat,
        cityId: location.city_id,
        address: mapAddressToView(location.address)
    }
}

function mapAddressToView(address) {
    return {
        ru: address.ru,
        uk: address.uk,
        en: address.en
    }
}

function mapCitiesToView(cities) {
    return cities.map((city) => {
        return {
            id: city._id,
            name: city.city_name,
            region: city.city_region
        }
    })
}

function mapGroupBuildingsToStore(groupBuildings) {
    const { buildingGroups, freeBuildings } = groupBuildings;
    const mappedBuildingGroups = mapGroupBuildingsToView(buildingGroups);
    const mappedFreeBuildings = mapBuildingsToView(freeBuildings);

    const mappedBuildingsWithGroup = [];

    mappedBuildingGroups.forEach((group) => {
        if (group.buildings.length) {
            group.buildings.forEach((building) => {
                mappedBuildingsWithGroup.push(building);
            })
        }
    });

    const mappedAllBuildings = mappedBuildingsWithGroup.concat(mappedFreeBuildings);

    return {
        groups: mappedBuildingGroups,
        buildings: mappedAllBuildings
    }
}

function mapGroupBuildingToSend(group) {
    const groupToSend = {
        name: group.name,
        buildings: group.buildings.map((building) => building.id)
    }

    return groupToSend;
}

function mapBuildingToSend(building) {
    const buildingToSend = {
        name: building.name,
        location: mapLocationToSend(building.location),
        entrances: Number(building.entrances),
        min_floor: Number(building.minFloor),
        max_floor: Number(building.maxFloor),
        ground_floor: building.isZeroFloor
    }

    if (building.groupId) {
        buildingToSend.groupId = building.groupId;
    }

    return buildingToSend;
}

function mapLocationToSend(location) {
    return {
        place_id: location.placeId,
        lon: location.longitude,
        lat: location.latitude
    }
}

export {
    mapGroupBuildingsToView,
    mapBuildingsToView,
    mapCitiesToView,
    mapGroupBuildingsToStore,
    mapGroupBuildingToSend,
    mapBuildingToSend
}