<template>
    <div class="notifications">
<!--        <div class="notifications__panel">-->
<!--            <div class="notifications__title">Сповіщення</div>-->
<!--        </div>-->
    </div>
</template>

<script>
    export default {
        name: "NotificationsControl"
    }
</script>
