<template>
    <div class="polls">
<!--        <div class="polls__panel">-->
<!--            <div class="polls__title">Голосування</div>-->
<!--        </div>-->
    </div>
</template>

<script>
    export default {
        name: "PollsControl"
    }
</script>