<template>
    <app-modal-wrapper>
        <div class="modal-edit-profile-photo">
            <div class="profile-photo">
                <form @submit.prevent="updateAvatar" class="profile-photo__form">
                    <h4>{{ $t('profile.editPhoto.title') }}</h4>
                    <div class="profile-photo__upload">
                        <app-upload-cropper
                            :label="$t('upload.label.image')"
                            :type="'logo'"
                            :accept-types="acceptTypes"
                            :url="avatarUrl"
                            @update="updateProfileAvatar"
                        />
                    </div>
                    <div class="profile-photo__control">
                        <app-base-btn
                            :size="'full'"
                            :variation="'cancel'"
                            :text="$t('common.buttons.cancel')"
                            @action="closeModalEditAvatar"
                        />
                        <app-base-btn
                            :type="'submit'"
                            :size="'full'"
                            :variation="'save'"
                            :text="$t('profile.editPhoto.btn')"
                            :is-disabled="isNotUploaded"
                        />
                    </div>
                </form>
            </div>
        </div>
    </app-modal-wrapper>
</template>

<script>
    import AppUploadCropper from "../../common/local/upload/AppUploadCropper";
    import { profilePhotoTypes } from "../../../constants/uploadFileTypes";

    export default {
        name: "ModalEditProfilePhoto",

        components: {
            AppUploadCropper
        },

        props: {
            selectedAvatar: {
                type: Object,
                required: true,
            }
        },

        data() {
            return {
                avatar: '',
                avatarUrl: ''
            }
        },

        created() {
            this.avatarUrl = this.selectedAvatar.url;
        },

        computed: {
            acceptTypes() {
                return profilePhotoTypes;
            },

            isNotUploaded() {
                return !this.avatar;
            }
        },

        methods: {
            updateProfileAvatar(image) {
                this.avatar = image.file;
            },

            updateAvatar() {
                if (this.avatar) {
                    this.emitter.emit('updateMeAvatar', this.avatar);
                }
            },

            closeModalEditAvatar() {
                this.closeModal('modalEditAvatar');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-edit-profile-photo {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        max-width: 574px;
        width: 100%;
    }

    .profile-photo {
        h4 {
            margin-bottom: 30px;
        }

        &__control {
            display: flex;
            margin-top: 45px;

            button:first-child {
                margin-right: 30px;
            }
        }
    }
</style>