<template>
    <div class="contract-state"
         :style="{
            'backgroundColor': contractState.background
         }"
    >
        <span>{{ contractState.name }}</span>
    </div>
</template>

<script>
    import { contractStates } from "../../../constants/finance";

    export default {
        name: "AppContractState",

        props: {
            state: {
                type: Number,
                required: true
            }
        },

        computed: {
            contractState() {
                return contractStates.find((state) => state.id === this.state);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .contract-state {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        border-radius: 100px;
        font-size: 12px;
        line-height: 14px;
        padding: 5px 15px;
    }
</style>