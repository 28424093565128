<template>
    <div class="services">
<!--        <div class="services__panel">-->
<!--            <div class="services__title">Сервіси</div>-->
<!--        </div>-->
    </div>
</template>

<script>
    export default {
        name: "ServicesControl"
    }
</script>