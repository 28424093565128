<template>
    <div class="finance-operations-welcome">
        <div class="finance-operations-welcome__image">
            <svg width="336" height="283" viewBox="0 0 336 283" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M237.297 211.325L92.6226 248.58C87.715 249.838 82.509 249.098 78.1465 246.522C73.784 243.946 70.6214 239.745 69.3524 234.841L25.599 64.9321C24.3412 60.0246 25.0814 54.8185 27.6572 50.456C30.233 46.0935 34.434 42.9309 39.3387 41.662L173.935 7.00195L208.385 22.4242L251.037 188.055C252.295 192.962 251.555 198.168 248.979 202.531C246.403 206.893 242.202 210.056 237.297 211.325Z" fill="#F2F2F2"/>
                <path d="M40.7104 46.9869C37.2177 47.8905 34.226 50.1426 32.3918 53.2493C30.5575 56.3559 30.0304 60.0632 30.9261 63.558L74.6795 233.467C75.5831 236.959 77.8353 239.951 80.9419 241.785C84.0486 243.62 87.7559 244.147 91.2507 243.251L235.925 205.995C239.418 205.092 242.41 202.84 244.244 199.733C246.078 196.626 246.605 192.919 245.71 189.424L203.719 26.3601L173.443 12.8066L40.7104 46.9869Z" fill="white"/>
                <path d="M208.017 23.3014L185.627 29.0672C183.993 29.4881 182.258 29.2425 180.804 28.3843C179.351 27.5261 178.298 26.1257 177.877 24.4912L173.618 7.95398C173.6 7.88158 173.602 7.80539 173.624 7.73415C173.647 7.66291 173.689 7.5995 173.747 7.5512C173.804 7.5029 173.873 7.47165 173.947 7.46105C174.021 7.45045 174.097 7.46092 174.165 7.49126L208.08 22.5527C208.155 22.5862 208.218 22.6424 208.26 22.7135C208.302 22.7845 208.321 22.8669 208.315 22.9492C208.308 23.0315 208.275 23.1096 208.222 23.1727C208.169 23.2359 208.097 23.2808 208.017 23.3014Z" fill="#F2F2F2"/>
                <path d="M196.238 168.4L130.37 185.362C129.965 185.466 129.544 185.49 129.13 185.431C128.716 185.373 128.318 185.233 127.958 185.021C127.598 184.808 127.284 184.527 127.033 184.193C126.782 183.859 126.599 183.479 126.494 183.074C126.39 182.67 126.367 182.248 126.425 181.835C126.484 181.421 126.623 181.023 126.836 180.663C127.048 180.303 127.33 179.988 127.664 179.737C127.998 179.486 128.378 179.303 128.783 179.199L194.651 162.237C195.056 162.133 195.477 162.11 195.89 162.168C196.304 162.227 196.702 162.366 197.062 162.579C197.422 162.791 197.737 163.072 197.988 163.406C198.239 163.74 198.422 164.121 198.526 164.525C198.63 164.93 198.654 165.351 198.595 165.765C198.536 166.179 198.397 166.577 198.184 166.937C197.972 167.297 197.691 167.611 197.357 167.862C197.023 168.113 196.643 168.296 196.238 168.4Z" fill="#CCCCCC"/>
                <path d="M211.628 175.525L133.048 195.76C132.231 195.97 131.363 195.848 130.636 195.418C129.91 194.989 129.383 194.289 129.173 193.472C128.962 192.655 129.085 191.787 129.514 191.06C129.943 190.334 130.643 189.807 131.461 189.597L210.041 169.362C210.858 169.152 211.724 169.275 212.45 169.704C213.177 170.134 213.703 170.833 213.913 171.65C214.123 172.467 214.001 173.334 213.572 174.06C213.144 174.787 212.444 175.314 211.628 175.525Z" fill="#CCCCCC"/>
                <path d="M103.486 205.791C109.697 205.791 114.733 200.755 114.733 194.544C114.733 188.332 109.697 183.297 103.486 183.297C97.2744 183.297 92.239 188.332 92.239 194.544C92.239 200.755 97.2744 205.791 103.486 205.791Z" fill="#E6E6E6"/>
                <path d="M189.234 138.963L101.755 161.482C99.2264 162.129 96.5445 161.748 94.2973 160.421C92.0501 159.094 90.421 156.929 89.7676 154.403L71.4351 83.1851C70.7873 80.657 71.1689 77.9751 72.496 75.7278C73.8231 73.4806 75.9874 71.8516 78.5142 71.1982L165.994 48.6795C168.522 48.0317 171.204 48.4133 173.451 49.7404C175.698 51.0675 177.327 53.2319 177.981 55.7586L196.313 126.976C196.961 129.504 196.58 132.186 195.252 134.433C193.925 136.681 191.761 138.31 189.234 138.963Z" fill="white"/>
                <path d="M189.234 138.963L101.755 161.482C99.2264 162.129 96.5445 161.748 94.2973 160.421C92.0501 159.094 90.421 156.929 89.7676 154.403L71.4351 83.1851C70.7873 80.657 71.1689 77.9751 72.496 75.7278C73.8231 73.4806 75.9874 71.8516 78.5142 71.1982L165.994 48.6795C168.522 48.0317 171.204 48.4133 173.451 49.7404C175.698 51.0675 177.327 53.2319 177.981 55.7586L196.313 126.976C196.961 129.504 196.58 132.186 195.252 134.433C193.925 136.681 191.761 138.31 189.234 138.963ZM78.8029 72.3197C76.5734 72.8963 74.6637 74.3337 73.4927 76.3165C72.3217 78.2993 71.9851 80.6657 72.5566 82.8965L90.8891 154.114C91.4657 156.343 92.9031 158.253 94.8859 159.424C96.8688 160.595 99.2351 160.932 101.466 160.36L188.946 137.841C191.175 137.265 193.085 135.828 194.256 133.845C195.427 131.862 195.763 129.495 195.192 127.265L176.859 56.0473C176.283 53.8178 174.845 51.9081 172.863 50.7371C170.88 49.5661 168.513 49.2295 166.283 49.801L78.8029 72.3197Z" fill="#E6E6E6"/>
                <path d="M161.556 72.6267L117.406 83.9916C117.02 84.0993 116.608 84.0565 116.252 83.8718C115.896 83.6871 115.624 83.3742 115.49 82.9965C115.428 82.7991 115.407 82.5912 115.427 82.3853C115.448 82.1794 115.511 81.9799 115.611 81.799C115.711 81.6181 115.848 81.4595 116.011 81.3329C116.175 81.2064 116.363 81.1145 116.563 81.0629L161.505 69.4941C163.32 70.414 162.843 72.2953 161.556 72.6267Z" fill="#F2F2F2"/>
                <path d="M163.549 80.3709L119.399 91.7357C119.013 91.8435 118.601 91.8007 118.245 91.6159C117.89 91.4312 117.617 91.1184 117.484 90.7406C117.421 90.5433 117.4 90.3353 117.421 90.1294C117.442 89.9236 117.504 89.7241 117.604 89.5431C117.705 89.3622 117.841 89.2036 118.005 89.077C118.168 88.9505 118.356 88.8586 118.557 88.807L163.498 77.2383C165.314 78.1581 164.837 80.0395 163.549 80.3709Z" fill="#F2F2F2"/>
                <path d="M110.99 98.6048L97.5162 102.073C97.0761 102.186 96.6093 102.12 96.2181 101.889C95.8269 101.658 95.5434 101.281 95.4297 100.841L91.3367 84.9405C91.2238 84.5005 91.2902 84.0337 91.5212 83.6425C91.7523 83.2513 92.129 82.9678 92.5688 82.8541L106.043 79.3856C106.483 79.273 106.95 79.3394 107.341 79.5704C107.732 79.8014 108.016 80.1781 108.129 80.6178L112.222 96.5184C112.335 96.9584 112.269 97.4253 112.038 97.8164C111.807 98.2076 111.43 98.4911 110.99 98.6048Z" fill="#E6E6E6"/>
                <path d="M167.503 96.2616L100.122 113.607C99.7358 113.714 99.3233 113.671 98.9676 113.487C98.612 113.302 98.3398 112.989 98.206 112.611C98.1437 112.414 98.1223 112.206 98.1431 112C98.1638 111.794 98.2263 111.595 98.3267 111.414C98.4271 111.233 98.5633 111.074 98.727 110.948C98.8907 110.821 99.0785 110.729 99.2789 110.678L167.452 93.1289C169.268 94.0488 168.79 95.9303 167.503 96.2616Z" fill="#F2F2F2"/>
                <path d="M169.497 104.008L102.116 121.353C101.73 121.46 101.317 121.418 100.962 121.233C100.606 121.048 100.334 120.735 100.2 120.357C100.138 120.16 100.116 119.952 100.137 119.746C100.158 119.54 100.22 119.341 100.321 119.16C100.421 118.979 100.557 118.82 100.721 118.694C100.885 118.567 101.073 118.475 101.273 118.424L169.446 100.875C171.262 101.795 170.784 103.676 169.497 104.008Z" fill="#F2F2F2"/>
                <path d="M171.489 111.75L104.109 129.095C103.723 129.203 103.31 129.16 102.955 128.975C102.599 128.79 102.327 128.477 102.193 128.1C102.131 127.902 102.109 127.694 102.13 127.489C102.151 127.283 102.213 127.083 102.314 126.902C102.414 126.721 102.55 126.563 102.714 126.436C102.878 126.31 103.065 126.218 103.266 126.166L171.439 108.617C173.255 109.537 172.777 111.419 171.489 111.75Z" fill="#F2F2F2"/>
                <path d="M173.483 119.494L106.102 136.839C105.716 136.947 105.304 136.904 104.948 136.719C104.593 136.534 104.321 136.222 104.187 135.844C104.124 135.646 104.103 135.439 104.124 135.233C104.145 135.027 104.207 134.827 104.307 134.646C104.408 134.465 104.544 134.307 104.708 134.18C104.871 134.054 105.059 133.962 105.26 133.91L173.433 116.361C175.248 117.281 174.771 119.163 173.483 119.494Z" fill="#F2F2F2"/>
                <path d="M175.476 127.236L108.095 144.581C107.709 144.689 107.297 144.646 106.941 144.461C106.586 144.277 106.313 143.964 106.18 143.586C106.117 143.389 106.096 143.181 106.117 142.975C106.137 142.769 106.2 142.569 106.3 142.389C106.401 142.208 106.537 142.049 106.701 141.922C106.864 141.796 107.052 141.704 107.253 141.652L175.426 124.104C177.241 125.023 176.764 126.905 175.476 127.236Z" fill="#F2F2F2"/>
                <path d="M104.316 198.485C104.071 198.548 103.812 198.53 103.578 198.434L103.563 198.428L100.5 197.129C100.357 197.069 100.228 196.981 100.12 196.871C100.012 196.761 99.9263 196.631 99.8684 196.487C99.8106 196.344 99.7815 196.191 99.7829 196.037C99.7843 195.882 99.8161 195.73 99.8765 195.588C99.9369 195.445 100.025 195.317 100.135 195.209C100.245 195.1 100.376 195.015 100.519 194.957C100.662 194.9 100.815 194.871 100.97 194.873C101.124 194.874 101.277 194.906 101.419 194.967L103.404 195.811L106.006 189.691C106.066 189.549 106.154 189.421 106.264 189.313C106.374 189.205 106.504 189.119 106.647 189.062C106.79 189.004 106.943 188.975 107.097 188.977C107.251 188.978 107.404 189.01 107.546 189.07L107.53 189.11L107.547 189.07C107.833 189.192 108.059 189.423 108.176 189.712C108.292 190.001 108.289 190.324 108.168 190.61L105.107 197.806C105.036 197.972 104.928 198.12 104.791 198.237C104.654 198.354 104.491 198.439 104.316 198.483L104.316 198.485Z" fill="white"/>
                <path d="M292.789 276.071H143.394C138.328 276.065 133.471 274.05 129.889 270.468C126.306 266.886 124.291 262.029 124.286 256.963V81.5109C124.291 76.4448 126.306 71.5878 129.889 68.0054C133.471 64.4231 138.328 62.4081 143.394 62.4023H282.381L311.897 85.9284V256.963C311.891 262.029 309.876 266.886 306.294 270.468C302.712 274.05 297.855 276.065 292.789 276.071Z" fill="#E6E6E6"/>
                <path d="M143.394 67.9043C139.786 67.9084 136.328 69.3433 133.777 71.8944C131.225 74.4454 129.791 77.9042 129.786 81.5119V256.964C129.791 260.571 131.225 264.03 133.777 266.581C136.328 269.132 139.786 270.567 143.394 270.571H292.789C296.396 270.567 299.855 269.132 302.406 266.581C304.957 264.03 306.392 260.571 306.396 256.964V88.5795L280.457 67.9043H143.394Z" fill="white"/>
                <path d="M263.731 118.923H195.714C194.87 118.923 194.061 118.588 193.464 117.991C192.867 117.394 192.532 116.585 192.532 115.741C192.532 114.897 192.867 114.087 193.464 113.491C194.061 112.894 194.87 112.559 195.714 112.559H263.731C264.575 112.559 265.385 112.894 265.982 113.491C266.578 114.087 266.914 114.897 266.914 115.741C266.914 116.585 266.578 117.394 265.982 117.991C265.385 118.588 264.575 118.923 263.731 118.923Z" fill="var(--brand-purple)"/>
                <path d="M276.857 129.661H195.714C194.871 129.66 194.063 129.324 193.467 128.728C192.871 128.131 192.536 127.322 192.536 126.479C192.536 125.636 192.871 124.827 193.467 124.23C194.063 123.634 194.871 123.298 195.714 123.297H276.857C277.276 123.296 277.69 123.378 278.077 123.538C278.463 123.698 278.814 123.932 279.11 124.227C279.406 124.523 279.641 124.874 279.801 125.26C279.961 125.647 280.044 126.061 280.044 126.479C280.044 126.897 279.961 127.311 279.801 127.698C279.641 128.084 279.406 128.435 279.11 128.731C278.814 129.026 278.463 129.26 278.077 129.42C277.69 129.58 277.276 129.662 276.857 129.661Z" fill="var(--brand-purple)"/>
                <path d="M263.732 160.684H195.717C194.873 160.684 194.063 161.019 193.467 161.616C192.87 162.212 192.535 163.022 192.535 163.865C192.535 164.709 192.87 165.519 193.467 166.115C194.063 166.712 194.873 167.047 195.717 167.047H263.732C264.576 167.047 265.385 166.712 265.982 166.115C266.578 165.519 266.914 164.709 266.914 163.865C266.914 163.022 266.578 162.212 265.982 161.616C265.385 161.019 264.576 160.684 263.732 160.684Z" fill="#CCCCCC"/>
                <path d="M276.859 171.426H195.717C194.873 171.426 194.064 171.761 193.467 172.358C192.87 172.954 192.535 173.764 192.535 174.608C192.535 175.452 192.87 176.261 193.467 176.858C194.064 177.454 194.873 177.79 195.717 177.79H276.859C277.703 177.79 278.512 177.454 279.109 176.858C279.705 176.261 280.041 175.452 280.041 174.608C280.041 173.764 279.705 172.954 279.109 172.358C278.512 171.761 277.703 171.426 276.859 171.426Z" fill="#CCCCCC"/>
                <path d="M263.731 215.181H195.714C194.87 215.181 194.061 214.845 193.464 214.249C192.867 213.652 192.532 212.842 192.532 211.999C192.532 211.155 192.867 210.345 193.464 209.748C194.061 209.152 194.87 208.816 195.714 208.816H263.731C264.575 208.816 265.385 209.152 265.982 209.748C266.578 210.345 266.914 211.155 266.914 211.999C266.914 212.842 266.578 213.652 265.982 214.249C265.385 214.845 264.575 215.181 263.731 215.181Z" fill="#CCCCCC"/>
                <path d="M276.857 225.921H195.714C194.871 225.92 194.063 225.584 193.467 224.987C192.871 224.391 192.536 223.582 192.536 222.739C192.536 221.896 192.871 221.087 193.467 220.49C194.063 219.894 194.871 219.558 195.714 219.557H276.857C277.276 219.556 277.69 219.638 278.077 219.798C278.463 219.957 278.814 220.192 279.11 220.487C279.406 220.783 279.641 221.134 279.801 221.52C279.961 221.906 280.044 222.321 280.044 222.739C280.044 223.157 279.961 223.571 279.801 223.957C279.641 224.344 279.406 224.695 279.11 224.99C278.814 225.286 278.463 225.52 278.077 225.68C277.69 225.84 277.276 225.921 276.857 225.921Z" fill="#CCCCCC"/>
                <path d="M167.39 132.357C173.602 132.357 178.637 127.322 178.637 121.11C178.637 114.899 173.602 109.863 167.39 109.863C161.179 109.863 156.143 114.899 156.143 121.11C156.143 127.322 161.179 132.357 167.39 132.357Z" fill="var(--brand-purple)"/>
                <path d="M166.276 125.398C166.023 125.398 165.776 125.316 165.574 125.164L165.561 125.155L162.918 123.133C162.795 123.039 162.692 122.922 162.615 122.789C162.537 122.655 162.487 122.507 162.466 122.354C162.446 122.201 162.456 122.046 162.496 121.896C162.536 121.747 162.604 121.607 162.698 121.485C162.792 121.362 162.91 121.259 163.043 121.182C163.177 121.105 163.325 121.055 163.478 121.035C163.631 121.015 163.787 121.025 163.936 121.065C164.085 121.105 164.225 121.174 164.347 121.269L166.059 122.581L170.105 117.304C170.199 117.181 170.316 117.078 170.449 117.001C170.583 116.924 170.73 116.874 170.883 116.854C171.036 116.834 171.191 116.844 171.34 116.884C171.489 116.923 171.629 116.992 171.751 117.086L171.726 117.121L171.752 117.086C171.999 117.276 172.161 117.556 172.201 117.865C172.242 118.173 172.159 118.486 171.969 118.733L167.211 124.938C167.101 125.081 166.959 125.197 166.797 125.276C166.635 125.356 166.457 125.397 166.277 125.396L166.276 125.398Z" fill="white"/>
                <path d="M179.793 169.236C179.794 170.713 179.503 172.176 178.938 173.541C178.373 174.906 177.545 176.146 176.501 177.191C175.457 178.235 174.217 179.064 172.852 179.63C171.488 180.195 170.025 180.487 168.548 180.487C168.451 180.489 168.355 180.485 168.258 180.475C166.047 180.418 163.901 179.711 162.09 178.441C160.279 177.171 158.882 175.395 158.075 173.335C157.268 171.275 157.087 169.023 157.553 166.861C158.02 164.698 159.114 162.722 160.698 161.178C162.282 159.634 164.287 158.592 166.461 158.181C168.635 157.771 170.881 158.011 172.919 158.871C174.957 159.731 176.697 161.173 177.919 163.017C179.142 164.86 179.794 167.024 179.793 169.236Z" fill="#CCCCCC"/>
                <path d="M167.39 228.613C173.602 228.613 178.637 223.578 178.637 217.366C178.637 211.155 173.602 206.119 167.39 206.119C161.179 206.119 156.143 211.155 156.143 217.366C156.143 223.578 161.179 228.613 167.39 228.613Z" fill="#CCCCCC"/>
                <path d="M311.323 86.6883H288.202C286.514 86.6883 284.895 86.0178 283.702 84.8243C282.508 83.6307 281.838 82.012 281.838 80.3241V63.2474C281.838 63.1726 281.859 63.0994 281.898 63.036C281.938 62.9727 281.995 62.9218 282.062 62.8893C282.13 62.8567 282.205 62.8438 282.279 62.852C282.353 62.8602 282.424 62.8892 282.482 62.9356L311.57 85.9787C311.634 86.03 311.682 86.1002 311.705 86.1795C311.728 86.2588 311.725 86.3433 311.698 86.4213C311.671 86.4993 311.62 86.5669 311.553 86.6147C311.486 86.6626 311.405 86.6883 311.323 86.6883Z" fill="#CCCCCC"/>
                <path d="M179.793 169.239C179.793 170.716 179.503 172.179 178.938 173.544C178.373 174.909 177.545 176.149 176.501 177.194C175.457 178.239 174.217 179.068 172.852 179.633C171.488 180.199 170.025 180.49 168.548 180.49C168.451 180.492 168.354 180.488 168.258 180.478C166.805 176.854 166.245 172.934 166.625 169.048C167.005 165.162 168.314 161.424 170.441 158.15C173.058 158.599 175.431 159.958 177.142 161.987C178.853 164.016 179.792 166.585 179.793 169.239Z" fill="var(--brand-purple)"/>
                <path d="M192.532 163.868C192.534 163.024 192.87 162.215 193.466 161.618C194.063 161.021 194.873 160.685 195.717 160.684H212.666C213.655 162.697 214.359 164.839 214.756 167.047H195.717C194.873 167.047 194.064 166.712 193.467 166.116C192.87 165.52 192.534 164.712 192.532 163.868Z" fill="var(--brand-purple)"/>
                <path d="M215.15 171.426C215.151 173.575 214.866 175.715 214.304 177.79H195.717C194.873 177.79 194.063 177.454 193.467 176.858C192.87 176.261 192.535 175.452 192.535 174.608C192.535 173.764 192.87 172.954 193.467 172.358C194.063 171.761 194.873 171.426 195.717 171.426H215.15Z" fill="var(--brand-purple)"/>
                <path d="M257.085 210.673C256.605 211.46 255.833 212.024 254.937 212.24C254.042 212.457 253.097 212.31 252.31 211.83L211.774 187.104C211.384 186.867 211.045 186.555 210.777 186.186C210.508 185.818 210.314 185.4 210.206 184.956C210.099 184.513 210.08 184.053 210.15 183.602C210.221 183.151 210.379 182.719 210.617 182.329C210.855 181.94 211.167 181.601 211.535 181.332C211.904 181.063 212.322 180.869 212.765 180.762C213.208 180.654 213.669 180.635 214.119 180.706C214.57 180.776 215.003 180.935 215.392 181.172L255.928 205.898C256.715 206.378 257.279 207.15 257.496 208.046C257.713 208.941 257.565 209.886 257.085 210.673Z" fill="#3F3D56"/>
                <path d="M216.549 185.946C213.686 190.639 209.497 194.377 204.509 196.689C199.522 199.001 193.961 199.782 188.529 198.933C183.098 198.084 178.04 195.644 173.996 191.921C169.952 188.198 167.102 183.359 165.808 178.016C164.513 172.674 164.832 167.067 166.724 161.906C168.616 156.745 171.996 152.26 176.437 149.02C180.878 145.779 186.179 143.929 191.672 143.701C197.164 143.474 202.601 144.881 207.294 147.744C213.581 151.588 218.086 157.769 219.821 164.931C221.556 172.093 220.379 179.65 216.549 185.946ZM175.024 160.617C172.877 164.137 171.822 168.214 171.993 172.334C172.163 176.453 173.551 180.429 175.981 183.76C178.412 187.09 181.775 189.625 185.646 191.044C189.517 192.463 193.722 192.702 197.729 191.732C201.736 190.761 205.365 188.624 208.157 185.591C210.95 182.557 212.78 178.764 213.416 174.691C214.053 170.617 213.467 166.446 211.733 162.706C210 158.965 207.195 155.823 203.676 153.676C198.954 150.803 193.286 149.921 187.915 151.222C182.543 152.523 177.907 155.902 175.024 160.617Z" fill="#3F3D56"/>
            </svg>
        </div>
        <div class="finance-operations-welcome__title">
            <h2>{{ $t('finance.operations.welcome.title') }}</h2>
        </div>
        <div class="finance-operations-welcome__details">
            {{ $t('finance.operations.welcome.details') }}
        </div>
        <div class="finance-operations-welcome__user">
            <div class="finance-operations-welcome__search-icon">
                <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.4115 12.349H12.5885L12.2969 12.0677C13.3177 10.8802 13.9323 9.33854 13.9323 7.66146C13.9323 3.92188 10.901 0.890625 7.16146 0.890625C3.42188 0.890625 0.390625 3.92188 0.390625 7.66146C0.390625 11.401 3.42188 14.4323 7.16146 14.4323C8.83854 14.4323 10.3802 13.8177 11.5677 12.7969L11.849 13.0885V13.9115L17.0573 19.1094L18.6094 17.5573L13.4115 12.349ZM7.16146 12.349C4.56771 12.349 2.47396 10.2552 2.47396 7.66146C2.47396 5.06771 4.56771 2.97396 7.16146 2.97396C9.75521 2.97396 11.849 5.06771 11.849 7.66146C11.849 10.2552 9.75521 12.349 7.16146 12.349Z" fill="#A4AFBE"/>
                </svg>
            </div>
            <app-base-select
                :place-holder="$t('finance.operations.user.ph.welcome')"
                :is-searchable="true"
                :selected-option="selectedUser"
                :options="users"
                :type="'financeUser'"
                @select="selectUser"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: "FinanceOperationsWelcome",

        props: {
            users: {
                type: Array,
                required: true
            }
        },

        data() {
            return {
                selectedUser: null,
            }
        },

        methods: {
            selectUser(user) {
                this.selectedUser = user;
                this.$emit('selectUser', this.selectedUser);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .finance-operations-welcome {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        max-width: 514px;
        width: 100%;

        &__image {
            margin: 64px 0;

            @media screen and (max-width: 1600px) {
                margin: 16px 0;
            }
        }

        &__title {
            margin-bottom: 15px;
        }

        &__details {
            max-width: 400px;
            text-align: center;
            margin-bottom: 30px;
        }

        &__user {
            width: 100%;
            position: relative
        }

        &__search-icon {
            position: absolute;
            left: 16px;
            top: 50%;
            transform: translate(0, -50%);
            display: flex;
            align-items: center;
            z-index: 100;
        }
    }
</style>

<style lang="scss">
    .finance-operations-welcome__user {
        .multiselect__tags {
            padding-left: 40px !important;
        }
    }
</style>