const scrollPositionMixin = {
    data() {
        return {
            scrollPosition: 0
        }
    },

    methods: {
        handlerScrollBodyTable(event) {
            this.scrollPosition = event.target.scrollTop;
        }
    }
};

export {
    scrollPositionMixin
}