import { mapPhoneToView, mapPhoneToSend, defaultPhone } from "./phone";

function mapPremisesToView(premises) {
    return premises.map((premise) => {
        return {
            id: premise._id,
            buildingGroup: premise.building_group ? mapPremiseBuildingGroupToView(premise.building_group) : null,
            building: mapPremiseBuildingToView(premise.building),
            entrance: premise.entrance ? premise.entrance.toString() : '',
            floor: premise.floor ? premise.floor.toString() : '',
            type: premise.premise_type,
            name: premise.name,
            area: premise.area ? premise.area.toString() : '',
            registered: premise.registered,
            document: premise.document ? premise.document : '',
        }
    })
}

function mapPremiseBuildingGroupToView(group) {
    return {
        id: group._id,
        name: group.name
    }
}

function mapPremiseBuildingToView(building) {
    return {
        id: building._id,
        name: building.name
    }
}

function mapPremiseToSend(premise) {
    const premiseToSend = {
        building_id: premise.buildingId,
        premise_type: premise.type === 'apartments' ? 'apts' : premise.type,
        floor: premise.floor,
        name: premise.name
    }

    //<-------------Optional fields-------------->
    if (premise.entrance) {
        premiseToSend.entrance = Number(premise.entrance);
    }

    if (premise.area) {
        premiseToSend.area = Number(premise.area);
    } else {
        premiseToSend.area = 0;
    }
    //<-------------Optional fields-------------->

    return premiseToSend;
}

function mapAutoFillPremisesToSend(autoPremises) {
    const autoPremisesToSend = {
        building_id: autoPremises.building,
        premise_type: autoPremises.type,
        start_floor: Number(autoPremises.minFloor),
        end_floor: Number(autoPremises.maxFloor),
        qty_per_floor: Number(autoPremises.qty),
        first_no: Number(autoPremises.numbering),
    }

    if (autoPremises.entrance) {
        autoPremisesToSend.entrance = Number(autoPremises.entrance);
    }

    return autoPremisesToSend;
}

export {
    mapPremisesToView,
    mapPremiseToSend,
    mapAutoFillPremisesToSend
}