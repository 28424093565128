import { useToast } from "vue-toastification";
import AppNotificationToast from "../components/common/local/toasts/AppNotificationToast";

function showToast(type, title, details) {
    setTimeout(() => {
        //Define vue notification toast
        const toast = useToast();
        // Define the content object with the component, props and listeners
        const content = {
            component: AppNotificationToast,
            // Any prop can be passed, but don't expect them to be reactive
            props: {
                type: type,
                title: title,
                details: details
            },
            // Listen and react to events using callbacks. In this case we listen for
            // the "click" event emitted when clicking the toast button
            listeners: {
                closeToast: () => {
                    toast.dismiss(toastId);
                }
            }
        }
        // Render the toast and its contents ang get toastId
        const toastId = toast(content);
    }, 500)
}

export {
    showToast
}