<template>
    <div class="location">
        <div class="location__data">
            <div class="app__label">
                {{ $t('buildings.building.address.label') }}
            </div>
            <div class="location__entry"
                 :class="{
                    'location__entry--is-error' : showError
                 }"
            >
                <input type="text" ref="searchBox"
                    v-model="address"
                    :placeholder="$t('buildings.building.address.ph')"
                    @focus="$emit('onFocus', 'location')"
                />
                <app-circle-btn
                    :is-transparent="true"
                    @action="clearLocationAddress"
                >
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.875 4.00625L10.9938 3.125L7.5 6.61875L4.00625 3.125L3.125 4.00625L6.61875 7.5L3.125 10.9938L4.00625 11.875L7.5 8.38125L10.9938 11.875L11.875 10.9938L8.38125 7.5L11.875 4.00625Z" fill="#2B3037"/>
                    </svg>
                </app-circle-btn>
            </div>
            <div v-if="showError" class="location__error">
                {{ errorMessage }}
            </div>
        </div>
        <div ref="map" class="location__map"></div>
    </div>
</template>

<script>
    import axios from 'axios';
    import { defaultLocation } from "../../constants/location";
    import { storage } from "../../services/storage";
    import i18n from "../../localization/i18n";

    export default {
        name: "SelectLocation",

        props: {
            selectedBuilding: {
                type: Object
            },
            cities: {
                type: Array,
                required: true
            },
            errorMessage: {
                type: String,
                default: ''
            }
        },

        data() {
            return {
                map: null,
                marker: null,
                searchBox: null,
                address: ''
            }
        },

        mounted() {
            this.initGoogleMaps();

            if (!this.selectedBuilding.location) {
                this.getCurrentLocation();
            }
        },

        computed: {
            showError() {
                return !!this.errorMessage && this.errorMessage !== 'isNotShowError';
            },

            langCode() {
                return i18n.global.locale;
            }
        },

        methods: {
            initGoogleMaps() {
                let location = null;

                if (!this.selectedBuilding.location) {
                    location = this.checkStorageLocation();
                } else {
                    location = {
                        lat: this.selectedBuilding.location.latitude,
                        lng: this.selectedBuilding.location.longitude
                    }

                    this.getLocationAddress(location);
                    // const city = this.cities.find((city) => city.id === this.selectedBuilding.location.cityId);
                    // const cityName = city.name[this.langCode];
                    // const cityRegion = city.region[this.langCode];
                    // const address = this.selectedBuilding.location.address[this.langCode];
                    //
                    // this.address = `${address} , ${cityName} , ${cityRegion}`;
                }

                this.initMap(location);
                this.initMarker(location);
                this.initSearchBox();
                this.subscribeToEvents(this.searchBox, this.map, this.marker);

                if (!this.selectedBuilding.location) {
                    this.getLocationAddress(location);
                }
            },

            getCurrentLocation() {
                (async () => {
                    if (!storage.location) {
                        await this.getLocation()
                            .then((location) => {
                                const currentLocation = {
                                    lat: location.coords.latitude,
                                    lng: location.coords.longitude
                                }

                                this.getLocationAddress(currentLocation);
                                this.updateMap(currentLocation);
                                storage.location = currentLocation;
                            })
                            .catch((err) => {
                                storage.location = defaultLocation;
                            });
                    }
                })();
            },

            checkStorageLocation() {
                return storage.location || defaultLocation;
            },

            initMap(location) {
                this.map = new google.maps.Map(this.$refs.map, {
                    center: location,
                    zoom: 17,
                    fullscreenControl: false,
                    mapTypeControl: false,
                    streetViewControl: false,
                });
            },

            initMarker(location) {
                this.marker = new google.maps.Marker({
                    position: location,
                    map: this.map,
                    icon: 'google_marker.png'
                });
            },

            initSearchBox() {
                this.searchBox = new google.maps.places.SearchBox(this.$refs.searchBox);
            },

            subscribeToEvents(searchBox, map, marker) {
                searchBox.addListener("places_changed", () => {
                    const place = this.searchBox.getPlaces();

                    if (place.length) {
                        const locationLatLng = {
                            lat: place[0].geometry.location.lat(),
                            lng: place[0].geometry.location.lng()
                        }

                        this.createLocationInfo(place[0], locationLatLng);

                        marker.setPosition(place[0].geometry.location);
                        map.panTo(locationLatLng);
                    }
                })
            },

            updateMap(location) {
                this.marker.setPosition(location);
                this.map.panTo(location);
            },

            createLocationInfo(place, latLng) {
                // const location = {
                //     latitude: latLng.lat,
                //     longitude: latLng.lng,
                //     placeId: place.place_id,
                //     build_no: '',
                //     street: '',
                //     city: '',
                //     region: '',
                //     postal_code: ''
                // };

                const location = {
                    latitude: latLng.lat,
                    longitude: latLng.lng,
                    placeId: place.place_id,
                    build_no: '',
                    street: ''
                };

                place.address_components.forEach(item => {
                    switch (item.types[0]) {
                        case 'street_number':
                            location.build_no = item.short_name;
                            break;
                        case 'route':
                            location.street = item.short_name;
                            break;
                        // case 'locality':
                        //     location.city = item.short_name;
                        //     break;
                        // case 'administrative_area_level_1':
                        //     location.region = item.short_name;
                        //     break;
                        // case 'postal_code':
                        //     //Use postal_code first two digits as place_id
                        //     location.postal_code = item.short_name.substring(0, 2);
                        //     break;
                    }
                })

                //Not all addresses have types: ['administrative_area_level_1']
                // if (!location.region) {
                //     location.region = location.city;
                // }

                if (!location.street) {
                    location.street = this.$t('buildings.building.address.unknown');
                }

                if (!location.build_no) {
                    location.build_no = '0';
                }

                // if (!location.postal_code) {
                //     location.postal_code = '00';
                // }

                this.$emit('update', location);
            },

            getLocation() {
                return new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(resolve, reject);
                });
            },

            getLocationAddress(location) {
                const langCode = i18n.global.locale;

                axios.get('https://maps.googleapis.com/maps/api/geocode/json',
                    {
                        params: {
                            latlng: `${location.lat},${location.lng}`,
                            language: langCode,
                            key: `${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}`
                        }
                    })
                    .then(response => {
                        const results = response.data.results;

                        if (results.length) {
                            const place = results[0];
                            const locationLatLng = {
                                lat: place.geometry.location.lat,
                                lng: place.geometry.location.lng
                            }

                            this.createLocationInfo(place, locationLatLng);
                            this.address = place.formatted_address;
                        }
                    })
            },

            clearLocationAddress() {
                this.address = '';
                this.$emit('update', null);
            },
        }
    }
</script>

<style lang="scss" scoped>
    .location {
        display: flex;
        flex-direction: column;
        height: 100%;

        &__data {
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;
        }

        &__entry {
            position: relative;

            input {
                width: 100%;
                padding: 14px 55px;
                color: var(--app-base-primary);
                font-size: 16px;
                line-height: 18px;
                border-radius: 13px;
                border: 1px solid var(--app-border);
                transition: border 0.3s ease;
            }

            input::placeholder {
                font-size: 12px;
                font-style: italic;
                color: var(--app-base-secondary);
            }

            input:focus {
                border: 1px solid var(--brand-main);
            }

            button {
                position: absolute;
                top: 50%;
                right: 2%;
                transform: translate(0, -50%);
            }

            &--is-error {
                input {
                    border: 1px solid var(--system-red);
                }
            }
        }

        &__entry:before {
            content: "";
            position: absolute;
            left: 22px;
            top: 50%;
            transform: translate(0, -50%);
            background-image: url("../../assets/google_marker_icon.png");
            background-repeat: no-repeat;
            background-position: center center;
            width: 20px;
            height: 25px;
        }

        &__map {
            width: 100%;
            flex-grow: 1;
        }

        &__error {
            margin-top: 10px;
            font-size: 14px;
            color: var(--system-red);
        }
    }
</style>