<template>
    <div class="finance-contract">
        <div class="table__row">
            <div class="table__column contract__control">
                <app-base-checkbox
                    :value="id"
                    v-model="isSelectContract"
                />
            </div>
            <div class="table__column contract__number">
                <app-collapse-btn
                    @collapse="collapsePremises"
                />
                <app-circle-btn
                    @action="getAndDownloadContract"
                >
                    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.0179 11.875H3.26785V3.125H7.64285V1.875H3.26785C2.5741 1.875 2.01785 2.4375 2.01785 3.125V11.875C2.01785 12.5625 2.5741 13.125 3.26785 13.125H12.0179C12.7054 13.125 13.2679 12.5625 13.2679 11.875V7.5H12.0179V11.875ZM8.89285 1.875V3.125H11.1366L4.99285 9.26875L5.8741 10.15L12.0179 4.00625V6.25H13.2679V1.875H8.89285Z" fill="#2B3037"/>
                    </svg>
                </app-circle-btn>
                <div v-if="numberIsExist" class="contract__name">
                    <app-match-string
                        :string="name"
                        :sub-string="subString"
                    />
                </div>
            </div>
            <div class="table__column contract__start-date">
                {{ formattedStartDate }}
            </div>
            <div class="table__column contract__state">
                <app-contract-state
                    :state="state"
                />
                <div class="contract__actions" ref="actionControlPanel">
                    <app-circle-btn
                        @action="doActionPanel"
                    >
                        <svg v-if="isSent" width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.4286 4.66732C8.07027 4.66732 8.59527 4.14232 8.59527 3.50065C8.59527 2.85898 8.07027 2.33398 7.4286 2.33398C6.78693 2.33398 6.26193 2.85898 6.26193 3.50065C6.26193 4.14232 6.78693 4.66732 7.4286 4.66732ZM7.4286 5.83398C6.78693 5.83398 6.26193 6.35898 6.26193 7.00065C6.26193 7.64232 6.78693 8.16732 7.4286 8.16732C8.07027 8.16732 8.59527 7.64232 8.59527 7.00065C8.59527 6.35898 8.07027 5.83398 7.4286 5.83398ZM7.4286 9.33398C6.78693 9.33398 6.26193 9.85898 6.26193 10.5007C6.26193 11.1423 6.78693 11.6673 7.4286 11.6673C8.07027 11.6673 8.59527 11.1423 8.59527 10.5007C8.59527 9.85898 8.07027 9.33398 7.4286 9.33398Z" fill="#2B3037"/>
                        </svg>
                        <svg v-if="isActual" width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.48443 12.8337H3.9286C3.2811 12.8337 2.76193 12.3145 2.76193 11.667V2.33366C2.76193 2.02424 2.88485 1.72749 3.10364 1.5087C3.32243 1.28991 3.61918 1.16699 3.9286 1.16699H8.59527L12.0953 4.66699V7.63616C11.9028 7.60699 11.7103 7.58366 11.5119 7.58366C11.3136 7.58366 11.1211 7.60699 10.9286 7.63616V5.25033H8.01193V2.33366H3.9286V11.667H8.06443C8.13443 12.087 8.28027 12.4778 8.48443 12.8337ZM13.5769 12.3203L12.3344 11.0837L13.5769 9.84699L12.7486 9.02449L11.5119 10.2612L10.2753 9.02449L9.45277 9.84699L10.6894 11.0837L9.45277 12.3203L10.2753 13.1487L11.5119 11.9062L12.7486 13.1487L13.5769 12.3203Z" fill="#2B3037"/>
                        </svg>
                        <svg v-if="isNotActual" width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.76191 5.25V11.0833H5.09525V5.25H9.76191ZM8.88691 1.75H5.97025L5.38691 2.33333H3.34525V3.5H11.5119V2.33333H9.47025L8.88691 1.75ZM10.9286 4.08333H3.92858V11.0833C3.92858 11.725 4.45358 12.25 5.09525 12.25H9.76191C10.4036 12.25 10.9286 11.725 10.9286 11.0833V4.08333Z" fill="#2B3037"/>
                        </svg>
                    </app-circle-btn>
                    <div v-if="showActionPanel && isSent" class="contract__action-wrapper">
                        <app-action-btn
                            :id="'sign'"
                            :text="$t('finance.contract.actions.sign')"
                            @action="openModalSignContract"
                        />
                        <app-action-btn
                            :id="'edit'"
                            :text="$t('finance.contract.actions.edit')"
                            @action="updateContract"
                        />
                        <app-action-btn
                            :id="'delete'"
                            :text="$t('finance.contract.actions.delete')"
                            @action="openModalConfirm"
                        />
                    </div>
                </div>
            </div>
            <div class="table__column contract__end-date">
                {{ formattedEndDate }}
            </div>
            <div class="table__column contract__tariff contract__tariff--is-row">
                {{ formattedTariff }}
            </div>
            <div class="table__column contract__user-name">
                <app-match-string
                    :string="userName"
                    :sub-string="subString"
                    :can-overflow="false"
                />
            </div>
            <div class="table__column contract__user-document">
                <app-match-string
                    :string="userDocument"
                    :sub-string="subString"
                />
            </div>
        </div>
        <div v-if="showPremises" class="finance-contract__premises">
            <finance-contract-premise-row v-for="(premise, index) in premises" :key="premise.id"
                :id="premise.id"
                :index="index"
                :length="premises.length"
                :name="premise.name"
                :total="premise.total"
                :sub-string="subString"
            />
        </div>
    </div>
</template>

<script>
    import AppContractState from "./AppContractState";
    import FinanceContractPremiseRow from "./FinanceContractPremiseRow";
    import AppMatchString from "../../common/global/AppMatchString";
    import { formatCurrencyValue } from "../../../helpers/finance";
    import { formatTimestampToDate } from "../../../helpers/date";
    import { getAndDownloadFile } from "../../../api/requests/common";

    export default {
        name: "FinanceContractRow",

        components: {
            AppMatchString,
            FinanceContractPremiseRow,
            AppContractState
        },

        props: {
            id: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            link: {
                type: String,
                required: true
            },
            startDate: {
                type: Number,
                required: true
            },
            state: {
                type: Number,
                required: true
            },
            endDate: {
                type: Number,
                required: true
            },
            tariff: {
                type: Number,
                required: true
            },
            userId: {
                type: String,
                required: true
            },
            userName: {
                type: String,
                required: true
            },
            userDocument: {
                type: String,
                required: true
            },
            premises: {
                type: Array,
                required: true
            },
            subString: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                isSelectContract: false,
                showPremises: false,
                showActionPanel: false
            }
        },

        mounted() {
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromEvents();
        },

        watch: {
            isSelectContract() {
                this.$emit('selectContract', this.id);
            }
        },

        computed: {
            numberIsExist() {
                return !!this.name;
            },

            formattedStartDate() {
                return formatTimestampToDate(this.startDate);
            },

            formattedEndDate() {
                return formatTimestampToDate(this.endDate);
            },

            formattedTariff() {
                const { balance, currency } = formatCurrencyValue(this.tariff);

                return `${balance} ${currency}`;
            },

            isSent() {
                return this.state === 0;
            },

            isActual() {
                return this.state === 1 || this.state === 2;
            },

            isNotActual() {
                return this.state === 3 || this.state === 4;
            }
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('selectAll', this.selectContract);
                document.addEventListener("mousedown", this.handlerClickOutside);
            },

            unsubscribeFromEvents() {
                this.emitter.off('selectAll', this.selectContract);
                document.removeEventListener("mousedown", this.handlerClickOutside);
            },

            collapsePremises() {
                this.showPremises = !this.showPremises;
            },

            getAndDownloadContract() {
                const name = `${this.$t('finance.contract.name')} ${this.name}`.trim();
                getAndDownloadFile(this.link, name);
            },

            selectContract(state) {
                this.isSelectContract = state;
            },

            doActionPanel() {
                if (this.isSent) {
                    this.toggleActionPanel();
                } else if (this.isActual) {
                    this.openModalTerminateContract();
                } else {
                    this.openModalConfirm();
                }
            },

            toggleActionPanel() {
                this.showActionPanel = !this.showActionPanel;
            },

            handlerClickOutside(event) {
                if (!this.$refs.actionControlPanel.contains(event.target)) {
                    this.closeActionPanel();
                }
            },

            closeActionPanel() {
                this.showActionPanel = false;
            },

            updateContract() {
                this.$emit('update', this.id);
            },

            openModalSignContract() {
                const selectedElement = {
                    id: this.id
                };

                this.openModal({
                    name: 'modalSignContract',
                    selectedEl: selectedElement
                });
            },

            openModalTerminateContract() {
                const selectedElement = {
                    id: this.id
                };

                this.openModal({
                    name: 'modalTerminateContract',
                    selectedEl: selectedElement
                });
            },

            openModalConfirm() {
                const selectedElement = {
                    id: [this.id],
                    type: 'contract'
                };

                if (this.name) {
                    selectedElement.name = this.name;
                }

                this.openModal({
                    name: 'modalConfirm',
                    selectedEl: selectedElement
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .finance-contract {
        width: 100%;
    }
</style>