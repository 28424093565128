import { createRouter, createWebHistory } from 'vue-router'
import { checkAccess } from '../services/checkAccess'
import UserLogin from '../views/UserLogin.vue'
import AdminPanel from '../views/AdminPanel.vue'
import BuildingsControl from '../components/buildings/BuildingsControl.vue'
import ContactsControl from '../components/contacts/ContactsControl'
import DocumentsControl from '../components/documents/DocumentsControl'
import BidsControl from '../components/bids/BidsControl'
import ServicesControl from '../components/services/ServicesControl'
import NotificationsControl from '../components/notifications/NotificationsControl'
import PollsControl from '../components/polls/PollsControl'
import NewsControl from '../components/news/NewsControl'
import PremisesControl from '../components/premises/PremisesControl'
import UsersControl from "../components/users/UsersControl"
import CamerasControl from '../components/cameras/CamerasControl'
import PersonalizationControl from '../components/personalization/PersonalizationControl'
import AdminsControl from '../components/admins/AdminsControl'
import FinanceControl from "../components/finance/FinanceControl";
import FinanceMainControl from "../components/finance/main/FinanceMainControl";
import FinanceContractsControl from "../components/finance/contracts/FinanceContractsControl";
import FinanceInvoicesControl from "../components/finance/invoices/FinanceInvoicesControl";
import FinanceStatementsControl from "../components/finance/statements/FinanceStatementsControl";
import FinanceReviewControl from "../components/finance/review/FinanceReviewControl";
import FinanceTemplatesControl from "../components/finance/templates/FinanceTemplatesControl";
import ProfileControl from '../components/profile/ProfileControl'
import FinanceOperationsControl from "../components/finance/operations/FinanceOperationsControl";

const routes = [
    {
        path: '/',
        name: 'Admin',
        component: AdminPanel,
        children: [
            {
                path: 'buildings',
                component: BuildingsControl
            },
            {
                path: 'contacts',
                component: ContactsControl
            },
            {
                path: 'documents',
                component: DocumentsControl
            },
            {
                path: 'bids',
                component: BidsControl
            },
            {
                path: 'services',
                component: ServicesControl
            },
            {
                path: 'notifications',
                component: NotificationsControl
            },
            {
                path: 'polls',
                component: PollsControl
            },
            {
                path: 'news',
                component: NewsControl
            },
            {
                path: 'premises',
                component: PremisesControl
            },
            {
                path: 'users',
                component: UsersControl
            },
            {
                path: 'cameras',
                component: CamerasControl
            },
            {
                path: 'personalization',
                component: PersonalizationControl
            },
            {
                path: 'admins',
                component: AdminsControl
            },
            {
                path: 'finance',
                component: FinanceControl,
                children: [
                    {
                        path: 'main',
                        component: FinanceMainControl
                    },
                    {
                        path: 'contracts',
                        component: FinanceContractsControl
                    },
                    {
                        path: 'invoices',
                        component: FinanceInvoicesControl
                    },
                    {
                        path: 'statements',
                        component: FinanceStatementsControl
                    },
                    {
                        path: 'review',
                        component: FinanceReviewControl
                    },
                    {
                        path: 'templates',
                        component: FinanceTemplatesControl
                    },
                    {
                        path: 'operations',
                        component: FinanceOperationsControl
                    }
                ]
            },
            {
                path: 'profile',
                component: ProfileControl
            }
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: UserLogin
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to,from,next) => {
  await checkAccess()
      .then(userSession => {
          if (!userSession && to.path !== '/login') {
              router.push({ name: 'Login' });
          }

          next();
      });
});

export default router
