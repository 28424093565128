<template>
    <div v-bind="$attrs" class="toast"
         :class="{
            'toast--is-success': isSuccess,
            'toast--is-error': isError,
            'toast--is-warning': isWarning,
            'toast--is-info': isInfo
         }"
    >
        <div class="toast__content">
            <div class="toast__icon">
                <svg v-if="isSuccess" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" fill="#24A645"/>
                </svg>
                <svg v-if="isError" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM15.59 7L12 10.59L8.41 7L7 8.41L10.59 12L7 15.59L8.41 17L12 13.41L15.59 17L17 15.59L13.41 12L17 8.41L15.59 7Z" fill="#FF3B30"/>
                </svg>
                <svg v-if="isWarning" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 15H13V17H11V15ZM11 7H13V13H11V7ZM11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" fill="#FF9500"/>
                </svg>
                <svg v-if="isInfo" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" fill="#2D28F3"/>
                </svg>
            </div>
            <div class="toast__info">
                <div class="toast__title">
                    {{ title }}
                </div>
                <div class="toast__details">
                    {{ details }}
                </div>
            </div>
        </div>
        <div class="toast__control">
            <button type="button" @click="closeToast">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.875 4.00625L10.9938 3.125L7.5 6.61875L4.00625 3.125L3.125 4.00625L6.61875 7.5L3.125 10.9938L4.00625 11.875L7.5 8.38125L10.9938 11.875L11.875 10.9938L8.38125 7.5L11.875 4.00625Z" fill="#A4AFBE"/>
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AppNotificationToast",

        props: {
            type: {
                type: String,
                required: true,
                validator(type) {
                    return ['success', 'error', 'warning', 'info'].includes(type);
                }
            },
            title: {
                type: String,
                required: true
            },
            details: {
                type: String,
                required: true
            }
        },

        computed: {
            isSuccess() {
                return this.type === 'success';
            },

            isWarning() {
                return this.type === 'warning';
            },

            isInfo() {
                return this.type === 'info';
            },

            isError() {
                return this.type === 'error';
            }
        },

        methods: {
            closeToast() {
                this.$emit('closeToast');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .toast {
        background: var(--system-white);
        box-shadow: 0px 2px 4px var(--app-base-primary-25);
        border-radius: 13px;
        padding: 15px;
        position: relative;
        font-size: 14px;
        color: var(--app-base-primary);

        &__content {
            display: flex;
            align-items: center;
            margin-right: 75px;
        }

        &__icon {
            padding: 12px;
            margin-right: 15px;
        }

        &__title {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 5px;
        }

        &__title {
            white-space: nowrap;
        }

        &__control {
            position: absolute;
            top: 15px;
            right: 15px;

            button {
                width: 15px;
                height: 15px;
            }
        }

        &--is-success {
            border: 1px solid var(--system-green);
        }

        &--is-error {
            border: 1px solid var(--system-red);
        }

        &--is-info {
            border: 1px solid var(--brand-main);
        }

        &--is-warning {
            border: 1px solid var(--system-yellow);
        }
    }
</style>

<style lang="scss">
    .Vue-Toastification__toast {
        padding: unset !important;
        background-color: unset !important;
        color: unset !important;
        box-shadow: unset !important;
        font-weight: unset !important;
    }
</style>