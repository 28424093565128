import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

const loginErrors = {
    access: t('validation.login.access'),
    validation: t('validation.login.validation')
}

function validateLoginData(login) {
    return validateEmail(login) || validatePhone(login);
}

function validateEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
}

function validatePhone(phone) {
    return /^\+\d+$/.test(phone);
}

export {
    validateLoginData,
    validateEmail,
    validatePhone,
    loginErrors
}