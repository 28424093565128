import { defaultPhone, mapPhoneToSend, mapPhoneToView } from "./phone";

function mapProfileToView(profile) {
    const username = profile.username;
    const name = profile.name ? profile.name : '';
    const surname = profile.surname ? profile.surname : '';
    const fullName = `${name} ${surname}`.trim();

    return {
        id: profile._id,
        time: profile.time,
        username: username,
        name: name,
        surname: surname,
        fullName: fullName,
        email: profile.email ? profile.email : '',
        phone: profile.phone ? mapPhoneToView(profile.phone) : defaultPhone,
        company: profile.company ? profile.company : '',
        position: profile.position ? profile.position : '',
        abbrNoAvatar: !fullName ? username.charAt(0).toUpperCase() : `${surname.charAt(0).toUpperCase()}${name.charAt(0).toUpperCase()}`,
        avatar: profile.avatar
    }
}

function mapProfileToSend(profile) {
    const profileToSend = {
        name: profile.name,
        surname: profile.surname,
        email: profile.email,
        phone: mapPhoneToSend(profile.phone),
        company: profile.company,
        position: profile.position
    }

    return profileToSend;
}

export {
    mapProfileToView,
    mapProfileToSend
}