<template>
    <div class="contract-tariff">
        <div class="contract-tariff__details">
            <div class="contract-tariff__properties">
                <div class="contract-tariff__premise-name">
                    <div class="app__label">
                        {{ $t('finance.contract.premises.premise') }}
                    </div>
                    <div class="contract-tariff__block">
                        {{ name }}
                    </div>
                </div>
                <div v-if="isExistArea" class="contract-form__area">
                    <div class="app__label">
                        {{ $t('finance.contract.premises.area') }}
                    </div>
                    <div class="contract-tariff__block">
                        {{ formattedArea }}
                    </div>
                </div>
            </div>
        </div>
        <div class="contract-tariff__details">
            <div class="contract-tariff__tariff">
                <div class="contract-tariff__value">
                    <div class="contract-tariff__block contract-tariff__currency">
                        {{ $t('finance.data.currency') }}
                    </div>
                    <app-base-input
                        v-model="tariff"
                        :label="tariffLabel"
                        :error-message="errors.tariff"
                        @input="updateTariffValue(false)"
                    />
                    <div class="contract-tariff__select">
                        <app-base-select
                            :selected-option="selectedTariff"
                            :options="filteredTariffs"
                            :type="'tariff'"
                            @select="selectTariff"
                        />
                    </div>
                </div>
                <div v-if="isTariffType" class="contract-tariff__month">
                    <div class="app__label">
                        {{ $t('finance.contract.premises.tariff.areaMonth') }}
                    </div>
                    <div class="contract-tariff__block contract-tariff__block--is-total">
                        {{ formattedTotal }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { contractTariffs } from "../../../constants/finance";
    import { formatCurrencyValue } from "../../../helpers/finance";
    import { validateContractTariff } from "../../../validations/finance/contracts";

    export default {
        name: "ContractTariffRow",

        props: {
            isUpdate: {
                type: Boolean,
                required: true
            },
            id: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            area: {
                type: Number,
                required: true
            },
            totalPrice: {
                type: Number,
            },
            price: {
                type: Number,
            },
            type: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                selectedTariff: null,
                tariff: 0,
                total: 0,

                errors: {
                    tariff: ''
                }
            }
        },

        created() {
            this.initTariff();
        },

        mounted() {
            this.subscribeToEvents();
        },

        unmounted() {
            this.unSubscribeFromEvents();
        },

        computed: {
            isExistArea() {
                return this.area !== 0;
            },

            formattedArea() {
                return `${this.area} ${this.$t('finance.contract.premises.unit')}`;
            },

            tariffs() {
                return contractTariffs;
            },

            filteredTariffs() {
                return this.isExistArea ? this.tariffs : this.tariffs.filter((tariff) => tariff.id === 'fixed');
            },

            tariffLabel() {
                return this.isTariffType
                    ? this.$t('finance.contract.premises.tariff.area')
                    : this.$t('finance.contract.premises.tariff.month');
            },

            isTariffType() {
                return this.selectedTariff.id === 'tariff';
            },

            formattedTotal() {
                const { balance, currency } = formatCurrencyValue(this.total);

                return `${balance} ${currency}`;
            }
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('validateTariff', this.validateAndUpdateTariff);
            },

            unSubscribeFromEvents() {
                this.emitter.off('validateTariff', this.validateAndUpdateTariff);
            },

            initTariff() {
                this.selectedTariff = this.tariffs.find((tariff) => tariff.id === this.type);
                this.total = this.totalPrice;
                this.tariff = this.price;
                this.updateTariffValue(true);
            },

            selectTariff(tariff) {
                this.selectedTariff = tariff;
                this.updateTariffValue(false);
            },

            validateAndUpdateTariff() {
                this.clearTariffErrors();
                const { isValid, errors } = validateContractTariff(this.tariff);
                this.errors = errors;

                this.updateTariffValue(isValid)
            },

            updateTariffValue(isValid) {
                this.total = this.isTariffType ? this.area * this.tariff : this.tariff;
                this.$emit('update', this.id, this.tariff, this.total, this.selectedTariff.id, isValid);
            },

            clearTariffErrors() {
                for (let key in this.errors) {
                    this.errors[key] = '';
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .contract-tariff {
        display: flex;
        padding: 30px 0;
        border-bottom: 1px solid var(--app-border);
        font-size: 16px;

        &__details {
            flex-basis: 50%;

            &:first-child {
                margin-right: 30px;
            }
        }

        &__properties {
            display: flex;
            justify-content: space-between;
        }

        &__tariff {
            display: flex;
            justify-content: space-between;
        }

        &__value {
            position: relative;
            width: 100%;
        }

        &__select {
            position: absolute;
            left: 2px;
            top: 30px;
        }

        &__month {
            white-space: nowrap;
            margin-left: 30px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        &__block {
            height: 48px;
            display: flex;
            align-items: center;

            /*&--is-total {*/
            /*    justify-content: flex-end;*/
            /*}*/
        }

        &__currency {
            position: absolute;
            left: 205px;
            top: 26px;
            z-index: 10;
        }
    }
</style>

<style lang="scss">
    .contract-tariff__value {
        .field__entry {
            input {
                padding-left: 240px !important;
            }
        }
    }
</style>

