<template>
    <div class="admin-access">
        <div class="admin-access__details">
            <div class="admin-access__title">
                <div class="admin-access__column">
                    {{ $t('admins.modal.access.title') }}
                </div>
                <div class="admin-access__column">
                    {{ $t('admins.modal.access.permission') }}
                </div>
            </div>
<!--            TODO: V_FOR!!!!!!!!-->
            <admin-access-element
                :index="1"
                :label="$t('admins.modal.elements.buildings')"
                :value="accessLevels[1]"
                @update="updateAccessLevel"
            />
            <admin-access-element
                :index="2"
                :label="$t('admins.modal.elements.contacts')"
                :value="accessLevels[2]"
                @update="updateAccessLevel"
            />
            <admin-access-element
                :index="3"
                :label="$t('admins.modal.elements.documents')"
                :value="accessLevels[3]"
                @update="updateAccessLevel"
            />
            <admin-access-element
                :index="4"
                :label="$t('admins.modal.elements.services')"
                :value="accessLevels[4]"
                @update="updateAccessLevel"
            />
            <admin-access-element
                :index="5"
                :label="$t('admins.modal.elements.notifications')"
                :value="accessLevels[5]"
                @update="updateAccessLevel"
            />
            <admin-access-element
                :index="6"
                :label="$t('admins.modal.elements.polls')"
                :value="accessLevels[6]"
                @update="updateAccessLevel"
            />
            <admin-access-element
                :index="7"
                :label="$t('admins.modal.elements.news')"
                :value="accessLevels[7]"
                @update="updateAccessLevel"
            />
        </div>
        <div class="admin-access__details">
            <div class="admin-access__title">
                <div class="admin-access__column">
                    {{ $t('admins.modal.access.title') }}
                </div>
                <div class="admin-access__column">
                    {{ $t('admins.modal.access.permission') }}
                </div>
            </div>
            <admin-access-element
                :index="8"
                :label="$t('admins.modal.elements.bids')"
                :value="accessLevels[8]"
                @update="updateAccessLevel"
            />
            <admin-access-element
                :index="9"
                :label="$t('admins.modal.elements.premises')"
                :value="accessLevels[9]"
                @update="updateAccessLevel"
            />
            <admin-access-element
                :index="10"
                :label="$t('admins.modal.elements.users')"
                :value="accessLevels[10]"
                @update="updateAccessLevel"
            />
            <admin-access-element
                :index="11"
                :label="$t('admins.modal.elements.cameras')"
                :value="accessLevels[11]"
                @update="updateAccessLevel"
            />
            <admin-access-element
                :index="0"
                :label="$t('admins.modal.elements.admins')"
                :value="accessLevels[0]"
                :is-not-toggle="true"
                @update="updateAccessLevel"
            />
            <admin-access-element
                :index="12"
                :label="$t('admins.modal.elements.personalization')"
                :value="accessLevels[12]"
                :is-not-toggle="true"
                @update="updateAccessLevel"
            />
        </div>
    </div>
</template>

<script>
    import AdminAccessElement from "./AdminAccessElement";

    export default {
        name: "AdminAccess",

        components: {
            AdminAccessElement
        },

        props: {
            accessLevel: {
                type: Number,
                required: true
            }
        },

        data() {
            return {
                levelBinString: '',
                accessLevels: [],
            }
        },

        created() {
            this.levelBinString = this.formatDecToBin(this.accessLevel);
            this.createAccessLevelsToView(this.levelBinString);
        },

        methods: {
            formatDecToBin(decimal) {
                const binaryString = decimal.toString(2);
                const qtyAdditionalZeros = 24 - binaryString.length;
                const stringAdditionalZeros = this.generateAdditionalZeros(qtyAdditionalZeros);

                return stringAdditionalZeros + binaryString;
            },

            generateAdditionalZeros(qty) {
                    let i = 1;
                    let stringZeros = '';

                    while (i <= qty) {
                        stringZeros = stringZeros + '0';
                        i += 1;
                    }

                    return stringZeros;
            },

            createAccessLevelsToView(binString) {
                const firstBit = binString.substring(23);
                const middleBits = binString.substring(1,23);
                const reverseMiddleBits = middleBits.split("").reverse().join("");
                const lastBit = binString.substring(0,1);

                this.accessLevels.push(firstBit);


                for (let i = 0; i < reverseMiddleBits.length; i = i + 2) {
                    this.accessLevels.push(reverseMiddleBits.slice(i, i + 2));
                }

                this.accessLevels.push(lastBit);
            },

            createAccessLevelToSend(accessLevels) {
                const firstBit = accessLevels[12];
                const lastBit = accessLevels[0];
                const middleBits = accessLevels.slice(1,12).reverse().join('');

                const levelBinString = `${firstBit}${middleBits}${lastBit}`;

                return parseInt(levelBinString, 2);
            },

            updateAccessLevel(index, updatedAccessLevel) {
                this.accessLevels[index] = updatedAccessLevel;
                const accessLevels = this.createAccessLevelToSend(this.accessLevels);

                this.$emit('update', accessLevels);
            },
        }
    }
</script>

<style lang="scss" scoped>
    .admin-access {
        display: flex;

        &__details {
            flex-basis: 50%;

            &:first-child {
                margin-right: 30px;
            }

            display: flex;
            flex-direction: column;
        }

        &__row, &__title {
            display: flex;
        }

        &__title {
            font-size: 14px;
            color: var(--app-base-secondary);
            padding-bottom: 10px;
            border-bottom: 1px solid var(--app-border);
        }

        &__column {
            flex-basis: 50%;
        }
    }
</style>