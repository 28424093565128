<template>
    <div class="buildings-table building">
        <div class="table__head">
            <div class="table__column building__name">
                <app-overflow-match-wrapper
                    :text="$t('buildings.table.name')"
                />
            </div>
            <div class="table__column building__logo">
                <app-overflow-match-wrapper
                    :text="$t('buildings.table.logo')"
                />
            </div>
            <div class="table__column building__city">
                <app-overflow-match-wrapper
                    :text="$t('buildings.table.city')"
                />
            </div>
            <div class="table__column building__address">
                <app-overflow-match-wrapper
                    :text="$t('buildings.table.address')"
                />
            </div>
            <div class="table__column building__entrances">
                <app-overflow-match-wrapper
                    :text="$t('buildings.table.entrances')"
                />
            </div>
            <div class="table__column building__floors">
                <app-overflow-match-wrapper
                    :text="$t('buildings.table.floors')"
                />
            </div>
            <div class="table__column building__living">
                <app-overflow-match-wrapper
                    :text="$t('buildings.table.living')"
                />
            </div>
            <div class="table__column building__apartments">
                <app-overflow-match-wrapper
                    :text="$t('buildings.table.apartments')"
                />
            </div>
            <div class="table__column building__commercial">
                <app-overflow-match-wrapper
                    :text="$t('buildings.table.commercial')"
                />
            </div>
            <div class="table__column building__technical">
                <app-overflow-match-wrapper
                    :text="$t('buildings.table.technical')"
                />
            </div>
            <div class="table__column building__parking">
                <app-overflow-match-wrapper
                    :text="$t('buildings.table.parking')"
                />
            </div>
        </div>
        <div class="table__body" :style="{ 'maxHeight': maxHeight }" @scroll="handlerScrollBodyTable">
            <group-buildings-row v-for="group in buildingGroups" :key="group.id"
                :id="group.id"
                :name="group.name"
                :logo-url="group.logo"
                :city-id="group.cityId"
                :premise-types="group.premiseTypes"
                :buildings="group.buildings"
                :cities="cities"
                :sub-string="searchSubString"
                :scroll-position="scrollPosition"
                @update="updateBuildingGroup"
            />
            <building-row v-for="(building, index) in freeBuildings" :key="building.id"
                :index="index"
                :length="freeBuildings.length"
                :id="building.id"
                :name="building.name"
                :logo-url="building.logo"
                :location="building.location"
                :entrances="building.entrances"
                :floors="building.floors"
                :premise-types="building.premiseTypes"
                :cities="cities"
                :sub-string="searchSubString"
                :scroll-position="scrollPosition"
                 @update="updateBuilding"
            />
        </div>
    </div>
</template>

<script>
    import GroupBuildingsRow from "./GroupBuildingsRow";
    import BuildingRow from "./BuildingRow";
    import { scrollPositionMixin } from "../../mixins/scrollPosition";

    export default {
        name: "BuildingsTable",

        components: {
            GroupBuildingsRow,
            BuildingRow
        },

        props: {
            buildingGroups: {
                type: Array,
                required: true
            },
            freeBuildings: {
                type: Array,
                required: true
            },
            cities: {
                type: Array,
                required: true
            },
            maxHeight: {
                type: String,
                required: true
            },
            searchSubString: {
                type: String,
                required: true
            }
        },

        mixins: [ scrollPositionMixin ],

        methods: {
            updateBuildingGroup(id) {
                const group = this.buildingGroups.find((group) => group.id === id);

                this.openModal({
                    name: 'modalGroupBuildings',
                    selectedEl: group
                })
            },

            updateBuilding(id) {
                const building = this.freeBuildings.find((building) => building.id === id);

                this.openModal({
                    name: 'modalBuilding',
                    selectedEl: building
                })
            }
        }
    }
</script>

<style lang="scss">
    .building {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__name {
            width: 13%;
            padding-left: 40px;

            &--is-group {
                font-weight: 700;
                position: relative;
            }
        }

        &__collapse-btn {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            left: 0;
        }

        &__logo {
            width: 14%;
            overflow: hidden;
            text-overflow: ellipsis;

            img {
                max-width: 100%;
                max-height: 35px;
                object-fit: contain;
            }

            &--is-more-16 {
                .building__logo-name {
                    font-size: 16px;
                }
            }

            &--is-more-32 {
                .building__logo-name {
                    line-height: 16px;
                }
            }

            &--is-second-part {
                margin-top: 1px;
            }
        }

        &__logo-name {
            font-size: 28px;
            line-height: 33px;
            word-break: break-all;
            display: flex;
            flex-direction: column;
            justify-content: center;

            div {
                white-space: nowrap;
            }
        }

        &__city {
            width: 10%;
        }

        &__address {
            width: 13%;
        }

        &__entrances {
            width: 7%;
        }

        &__floors {
            width: 6%;
        }

        &__living {
            width: 7%;
        }

        &__apartments {
            width: 8%;
        }

        &__commercial {
            width: 8%;
        }

        &__technical {
            width: 7%;
        }

        &__parking {
            width: 7%;
        }

        &__living, &__apartments, &__commercial,
        &__technical, &__parking {
            display: flex;
            justify-content: center;
        }
    }
</style>
