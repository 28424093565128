<template>
    <div class="switch-toggle">
        <div class="switch-toggle__element switch-toggle__f-element" @click="toggleElement"
            :class="{
                'switch-toggle__f-element--is-active': fElementIsActive
            }"
        >
           {{ fElementText }}
        </div>
        <div class="switch-toggle__element switch-toggle__s-element" @click="toggleElement"
             :class="{
                'switch-toggle__s-element--is-active': !fElementIsActive
            }"
        >
            {{ sElementText }}
        </div>
    </div>
</template>

<script>
    export default {
        name: "AppSwitchToggle",

        props: {
            fElementText: {
                type: String,
                required: true
            },
            sElementText: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                fElementIsActive: true
            }
        },

        methods: {
            toggleElement() {
                this.fElementIsActive = !this.fElementIsActive;
                this.$emit('update', !this.fElementIsActive);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .switch-toggle {
        display: flex;
        align-items: center;
        border: 1px solid var(--app-border);
        border-radius: 13px;
        height: 48px;

        &__element {
            padding: 15px;
            height: 100%;
            display: flex;
            align-items: center;
            color: var(--app-base-secondary);
            font-size: 16px;

            &:hover {
                cursor: pointer;
            }
        }


        &__f-element--is-active, &__s-element--is-active {
            border-radius: 13px;
            background: var(--app-btn-secondary);
            color: var(--app-base-text);
            pointer-events: none;

            &:hover {
                cursor: unset;
            }
        }


        &__f-element--is-active {
            border-right: 1px solid var(--app-border);
        }

        &__s-element--is-active {
            border-left: 1px solid var(--app-border);
        }
    }
</style>