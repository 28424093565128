<template>
    <div class="finance-statements-filters filters">
        <div class="filters__control finance-statements-filters__control">
            <h4>{{ title }}</h4>
            <div class="filters__filtering filters__element">
                <app-base-filters
                    :is-end-position="true"
                    :filters="filters"
                    :active-filters="activeFilters"
                    @update="updateActiveFilters"
                />
            </div>
        </div>
        <div v-if="showFilterPanel" class="filters__panel">
            <div v-for="filter in modActiveFilters" :key="filter.id">
                <app-filter-active-item class="filters__active-element"
                    :id="filter.id"
                    :name="filter.name"
                    :filter-key="filter.key"
                    @remove="removeActiveFilter"
                />
            </div>
            <div class="filters__reset-btn">
                <app-text-btn
                    :text="$t('filters.clear')"
                    @action="clearAndUpdateActiveFilters"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import AppFilterActiveItem from "../../filters/AppFilterActiveItem";

    export default {
        name: "FinanceStatementsFilters",

        components: {
            AppFilterActiveItem
        },

        props: {
            title: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                activeFilters: {
                    views: [],
                    operations: []
                },
                modActiveFilters: [],
                showFilterPanel: false
            }
        },

        mounted() {
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromEvents();
        },

        computed: {
            ...mapGetters({
                filters: 'filters/statementsFilters'
            })
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('clearQueryParams', this.clearQueryParams);
                this.emitter.on('updateFilters', this.updateFilters);
            },

            unsubscribeFromEvents() {
                this.emitter.off('clearQueryParams', this.clearQueryParams);
                this.emitter.off('updateFilters', this.updateFilters);
            },


            clearQueryParams() {
                this.clearActiveFilters();
                this.updateQueryParams();
            },

            clearPeriod() {
                this.selectedPeriod = null;
            },

            updateFilters() {
                this.updateQueryParams();
            },

            clearActiveFilters() {
                const activeFiltersKeys = Object.keys(this.activeFilters);

                activeFiltersKeys.forEach((key) => {
                    this.activeFilters[key].length = 0;
                });

                // this.showFilterPanel = false;
                this.checkShowFiltersPanel();
            },

            clearAndUpdateActiveFilters() {
                this.clearActiveFilters();
                this.updateQueryParams();
            },

            removeActiveFilter(key, id) {
                this.activeFilters[key] = this.activeFilters[key].filter((elementId) => elementId !== id);
                this.checkShowFiltersPanel();
                this.updateQueryParams();
            },

            checkShowFiltersPanel() {
                const activeFiltersKeys = Object.keys(this.activeFilters);
                this.modActiveFilters = [];

                activeFiltersKeys.forEach((key) => {
                    this.activeFilters[key].forEach((id) => {
                        const targetFilter = this.filters[key].find((filter) => filter.id === id);
                        const filter = { ...targetFilter };
                        filter.key = key;
                        this.modActiveFilters.push(filter);
                    })
                })

                this.showFilterPanel = this.modActiveFilters.length;
            },

            updateActiveFilters(name, activeFilters) {
                this.activeFilters[name] = activeFilters;
                //TODO: SEVERAL TIMES I CHECK SHOW PANEL
                this.checkShowFiltersPanel();
            },

            updateQueryParams() {
                let params= null;
                const filters = this.modActiveFilters.length;

                if (filters) {
                    params = {};

                    const weeksFilter = this.modActiveFilters.find((filter) => filter.id === 'weeks');
                    params.weeks = !!weeksFilter;

                    const operations = this.modActiveFilters.filter((filter) => filter.key === 'operations');
                    if (operations.length === 1) {
                        params.operations = operations[0].id;
                    }
                }

                this.$emit('update-query-params', params);
            }
        }
    }
</script>

<style scoped lang="scss">
    .finance-statements-filters {
        z-index: 2;

        &__control {
            display: flex;
            justify-content: space-between;
        }
    }
</style>