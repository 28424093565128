<template>
    <button type="button" class="clipboard-btn" @click.stop="copyToClipBoard">
        <div class="clipboard-btn__toast">
            <div v-if="!!toast.text" class="clipboard-btn__toast-text">
                {{ toast.text }}
            </div>
        </div>
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.62501 0.583008H2.62501C1.98334 0.583008 1.45834 1.10801 1.45834 1.74967V9.91634H2.62501V1.74967H9.62501V0.583008ZM11.375 2.91634H4.95834C4.31668 2.91634 3.79168 3.44134 3.79168 4.08301V12.2497C3.79168 12.8913 4.31668 13.4163 4.95834 13.4163H11.375C12.0167 13.4163 12.5417 12.8913 12.5417 12.2497V4.08301C12.5417 3.44134 12.0167 2.91634 11.375 2.91634ZM11.375 12.2497H4.95834V4.08301H11.375V12.2497Z" fill="#2B3037"/>
        </svg>
    </button>
</template>

<script>
    export default {
        name: "AppClipboardBtn",

        props: {
            text: {
                type: String,
                default: ''
            }
        },

        data() {
            return {
                toast: {
                    text: ''
                }
            }
        },

        methods: {
            copyToClipBoard() {
                const vm = this;
                let isSuccess = false;

                if (navigator.clipboard) {
                    navigator.clipboard.writeText(this.text)
                        .then(() => {
                            isSuccess = true;
                        })
                        .finally(() => {
                            vm.showToast(isSuccess);
                        });
                } else {
                    isSuccess = document.execCommand('copy', false, this.text);
                    vm.showToast(isSuccess);
                }
            },

            showToast(isSuccess) {
                this.toast.text = isSuccess ? this.$t('toasts.copy') : this.$t('toasts.error');

                setTimeout(() => {
                    this.toast.text = '';
                }, 2000);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .clipboard-btn {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        min-width: 30px;
        height: 30px;
        min-height: 30px;
        border-radius: 100px;
        transition: 0.3s;
        background: var(--app-btn-secondary);

        &__toast {
            position: absolute;
            top: -85%;
            transform: translate(0, -50%);
        }

        &__toast-text {
            padding: 14px;
            background: var(--system-grey);
            border-radius: 8px;
            color: var(--system-white);
            font-size: 16px;
            letter-spacing: 0.3px;
            text-align: center;
        }

        &:hover {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), var(--app-btn-secondary);
            cursor: pointer;
        }
    }
</style>