<template>
    <div class="lang-select">
        <app-base-select
            :selected-option="selectedLanguage"
            :options="optionsLanguages"
            :type="'language'"
            @select="selectLanguage"
        />
    </div>
</template>

<script>
    import i18n from "../../../localization/i18n";
    import { languages } from "../../../constants/language";
    import { localStorageData } from "../../../services/storage";

    export default {
        name: "AdminSelectLang",

        data() {
            return {
                selectedLanguage: null
            }
        },

        created() {
            this.initDefaultLanguage();
        },

        computed: {
            optionsLanguages() {
                return languages;
            }
        },

        methods: {
            initDefaultLanguage() {
                const langCode = i18n.global.locale;
                this.selectedLanguage = this.optionsLanguages.find(item => item.type === langCode);
            },

            selectLanguage(language) {
                localStorageData.language = language.type;
                document.location.reload();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .lang-select {
        padding-top: 6px;
    }
</style>
