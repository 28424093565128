<template>
    <div class="empty">
        <div class="empty__image">
            <svg v-if="!isSmall" width="652" height="283" viewBox="0 0 652 283" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_532_17983)">
                    <path d="M588.123 116.746H205.591C203.967 116.746 202.409 117.392 201.261 118.541C200.112 119.69 199.467 121.249 199.467 122.874C199.467 124.499 200.112 126.058 201.261 127.207C202.409 128.356 203.967 129.002 205.591 129.002H216.427V275.596C216.427 277.221 217.072 278.779 218.22 279.929C219.369 281.078 220.927 281.723 222.551 281.723C224.175 281.723 225.733 281.078 226.881 279.929C228.03 278.779 228.675 277.221 228.675 275.596L228.768 129.002H535.789L564.097 275.596C564.097 277.221 564.742 278.779 565.891 279.929C567.039 281.078 568.597 281.723 570.221 281.723C571.846 281.723 573.404 281.078 574.552 279.929C575.701 278.779 576.346 277.221 576.346 275.596L578.128 129.002H588.123C589.747 129.002 591.305 128.356 592.454 127.207C593.602 126.058 594.247 124.499 594.247 122.874C594.247 121.249 593.602 119.69 592.454 118.541C591.305 117.392 589.747 116.746 588.123 116.746Z" fill="#CCCCCC"/>
                    <path d="M271.898 89.4415V115.391H206.922C205.219 115.45 203.521 115.173 201.925 114.576C200.33 113.979 198.867 113.073 197.621 111.91C196.375 110.747 195.37 109.35 194.663 107.798C193.957 106.247 193.563 104.571 193.504 102.867C193.445 101.164 193.721 99.4648 194.318 97.8679C194.915 96.2711 195.821 94.8075 196.983 93.5608C198.145 92.3141 199.541 91.3086 201.092 90.6018C202.643 89.895 204.317 89.5007 206.02 89.4414C206.321 89.431 206.621 89.431 206.922 89.4414L271.898 89.4415Z" fill="#F2F2F2"/>
                    <path d="M273.494 115.191C273.494 115.456 273.389 115.71 273.202 115.897C273.015 116.084 272.761 116.189 272.497 116.189H205.765C202.142 116.187 198.669 114.745 196.108 112.181C193.547 109.617 192.109 106.141 192.109 102.516C192.109 98.8913 193.547 95.4149 196.108 92.851C198.669 90.287 202.142 88.8453 205.765 88.8428H272.497C272.628 88.8424 272.758 88.868 272.879 88.918C273 88.968 273.111 89.0415 273.204 89.1342C273.296 89.2269 273.37 89.337 273.421 89.4583C273.471 89.5796 273.497 89.7095 273.497 89.8408C273.497 89.9721 273.471 90.1021 273.421 90.2234C273.37 90.3446 273.296 90.4548 273.204 90.5475C273.111 90.6402 273 90.7137 272.879 90.7637C272.758 90.8137 272.628 90.8392 272.497 90.8389H205.765C202.673 90.8435 199.709 92.0759 197.524 94.2653C195.339 96.4547 194.112 99.4221 194.112 102.516C194.112 105.61 195.339 108.577 197.524 110.767C199.709 112.956 202.673 114.189 205.765 114.193H272.497C272.761 114.193 273.015 114.299 273.202 114.486C273.389 114.673 273.494 114.927 273.494 115.191Z" fill="var(--brand-purple)"/>
                    <path d="M271.898 95.4295H202.074C202.022 95.4298 201.971 95.4091 201.933 95.372C201.896 95.335 201.874 95.2845 201.874 95.2317C201.873 95.1789 201.894 95.1281 201.931 95.0903C201.968 95.0526 202.018 95.031 202.071 95.0303H271.898C271.951 95.0308 272.001 95.0521 272.038 95.0894C272.075 95.1268 272.096 95.1773 272.096 95.2299C272.096 95.2825 272.075 95.3329 272.038 95.3703C272.001 95.4077 271.951 95.429 271.898 95.4295Z" fill="#CCCCCC"/>
                    <path d="M271.898 100.22H202.074C202.022 100.221 201.971 100.2 201.933 100.163C201.896 100.126 201.874 100.076 201.874 100.023C201.873 99.9699 201.894 99.9191 201.931 99.8813C201.968 99.8436 202.018 99.822 202.071 99.8213H271.898C271.951 99.8218 272.001 99.8431 272.038 99.8804C272.075 99.9178 272.096 99.9683 272.096 100.021C272.096 100.073 272.075 100.124 272.038 100.161C272.001 100.199 271.951 100.22 271.898 100.22Z" fill="#CCCCCC"/>
                    <path d="M271.898 105.012H202.074C202.022 105.012 201.971 104.991 201.933 104.954C201.896 104.917 201.874 104.867 201.874 104.814C201.873 104.761 201.894 104.71 201.931 104.672C201.968 104.635 202.018 104.613 202.071 104.612H271.898C271.951 104.613 272.001 104.634 272.038 104.671C272.075 104.709 272.096 104.759 272.096 104.812C272.096 104.865 272.075 104.915 272.038 104.952C272.001 104.99 271.951 105.011 271.898 105.012Z" fill="#CCCCCC"/>
                    <path d="M271.898 109.802H202.074C202.022 109.802 201.971 109.781 201.933 109.744C201.896 109.707 201.874 109.657 201.874 109.604C201.873 109.551 201.894 109.5 201.931 109.462C201.968 109.425 202.018 109.403 202.071 109.402H271.898C271.951 109.403 272.001 109.424 272.038 109.462C272.075 109.499 272.096 109.549 272.096 109.602C272.096 109.655 272.075 109.705 272.038 109.742C272.001 109.78 271.951 109.801 271.898 109.802Z" fill="#CCCCCC"/>
                    <path d="M259.649 62.1026V88.0518H194.673C191.234 88.1715 187.889 86.9194 185.372 84.5708C182.856 82.2223 181.375 78.9697 181.255 75.5286C181.135 72.0875 182.387 68.7398 184.734 66.2219C187.081 63.7041 190.332 62.2223 193.771 62.1026C194.072 62.0921 194.373 62.0921 194.673 62.1026H259.649Z" fill="#F2F2F2"/>
                    <path d="M261.246 87.8524C261.245 88.117 261.14 88.3708 260.953 88.558C260.766 88.7451 260.513 88.8503 260.248 88.8504H193.516C189.892 88.8504 186.416 87.4098 183.853 84.8456C181.29 82.2814 179.851 78.8035 179.851 75.1771C179.851 71.5508 181.29 68.0729 183.853 65.5087C186.416 62.9445 189.892 61.5039 193.516 61.5039H260.248C260.379 61.5039 260.509 61.5297 260.63 61.5799C260.751 61.63 260.861 61.7035 260.953 61.7962C261.046 61.8889 261.12 61.9989 261.17 62.12C261.22 62.2411 261.246 62.3709 261.246 62.502C261.246 62.633 261.22 62.7628 261.17 62.8839C261.12 63.005 261.046 63.115 260.953 63.2077C260.861 63.3004 260.751 63.3739 260.63 63.424C260.509 63.4742 260.379 63.5 260.248 63.5H193.516C190.421 63.5 187.453 64.7303 185.264 66.9202C183.075 69.1101 181.846 72.0802 181.846 75.1772C181.846 78.2741 183.075 81.2443 185.264 83.4342C187.453 85.6241 190.421 86.8543 193.516 86.8543H260.248C260.513 86.8544 260.766 86.9596 260.953 87.1468C261.14 87.3339 261.245 87.5877 261.246 87.8524Z" fill="#6B6A6A"/>
                    <path d="M259.65 68.0906H189.826C189.773 68.0909 189.722 68.0702 189.685 68.0332C189.647 67.9961 189.626 67.9457 189.625 67.8928C189.625 67.84 189.645 67.7892 189.682 67.7514C189.719 67.7137 189.769 67.6921 189.822 67.6914H259.65C259.702 67.6919 259.752 67.7132 259.789 67.7506C259.826 67.7879 259.847 67.8384 259.847 67.891C259.847 67.9436 259.826 67.9941 259.789 68.0315C259.752 68.0688 259.702 68.0901 259.65 68.0906Z" fill="#CCCCCC"/>
                    <path d="M259.65 72.8816H189.826C189.773 72.8819 189.722 72.8613 189.685 72.8242C189.647 72.7871 189.626 72.7367 189.625 72.6839C189.625 72.6311 189.645 72.5802 189.682 72.5425C189.719 72.5047 189.769 72.4831 189.822 72.4824H259.65C259.702 72.4829 259.752 72.5042 259.789 72.5416C259.826 72.579 259.847 72.6294 259.847 72.682C259.847 72.7346 259.826 72.7851 259.789 72.8225C259.752 72.8599 259.702 72.8811 259.65 72.8816Z" fill="#CCCCCC"/>
                    <path d="M259.65 77.6717H189.826C189.773 77.6719 189.722 77.6513 189.685 77.6142C189.647 77.5772 189.626 77.5267 189.625 77.4739C189.625 77.4211 189.645 77.3702 189.682 77.3325C189.719 77.2947 189.769 77.2732 189.822 77.2725H259.65C259.702 77.273 259.752 77.2942 259.789 77.3316C259.826 77.369 259.847 77.4195 259.847 77.4721C259.847 77.5247 259.826 77.5751 259.789 77.6125C259.752 77.6499 259.702 77.6711 259.65 77.6717Z" fill="#CCCCCC"/>
                    <path d="M259.65 82.4627H189.826C189.773 82.463 189.722 82.4423 189.685 82.4053C189.647 82.3682 189.626 82.3177 189.625 82.2649C189.625 82.2121 189.645 82.1613 189.682 82.1235C189.719 82.0858 189.769 82.0642 189.822 82.0635H259.65C259.702 82.064 259.752 82.0853 259.789 82.1226C259.826 82.16 259.847 82.2105 259.847 82.2631C259.847 82.3157 259.826 82.3661 259.789 82.4035C259.752 82.4409 259.702 82.4622 259.65 82.4627Z" fill="#CCCCCC"/>
                    <path d="M335.237 208.569V185.944C335.237 184.944 334.839 183.985 334.133 183.277C333.426 182.57 332.467 182.173 331.468 182.173H245.728C244.728 182.173 243.77 182.57 243.063 183.277C242.356 183.985 241.959 184.944 241.959 185.944V208.569H335.237Z" fill="#CCCCCC"/>
                    <path d="M335.237 217.054H241.959V246.278H335.237V217.054Z" fill="#CCCCCC"/>
                    <path d="M241.959 254.763V277.388C241.959 278.388 242.356 279.347 243.063 280.055C243.77 280.762 244.728 281.159 245.728 281.159H331.468C332.467 281.159 333.426 280.762 334.133 280.055C334.839 279.347 335.237 278.388 335.237 277.388V254.763H241.959Z" fill="#CCCCCC"/>
                    <path d="M288.127 199.142C289.688 199.142 290.953 197.876 290.953 196.314C290.953 194.752 289.688 193.485 288.127 193.485C286.566 193.485 285.3 194.752 285.3 196.314C285.3 197.876 286.566 199.142 288.127 199.142Z" fill="white"/>
                    <path d="M288.127 236.851C289.688 236.851 290.953 235.584 290.953 234.023C290.953 232.461 289.688 231.194 288.127 231.194C286.566 231.194 285.3 232.461 285.3 234.023C285.3 235.584 286.566 236.851 288.127 236.851Z" fill="white"/>
                    <path d="M288.278 273.762C289.839 273.762 291.105 272.496 291.105 270.934C291.105 269.372 289.839 268.105 288.278 268.105C286.717 268.105 285.452 269.372 285.452 270.934C285.452 272.496 286.717 273.762 288.278 273.762Z" fill="white"/>
                    <path d="M324.661 156.375V182.324H259.685C256.248 182.442 252.904 181.188 250.39 178.84C247.875 176.492 246.395 173.241 246.276 169.801C246.156 166.362 247.406 163.015 249.751 160.498C252.096 157.98 255.345 156.497 258.782 156.375C259.083 156.365 259.384 156.365 259.685 156.375H324.661Z" fill="#F2F2F2"/>
                    <path d="M326.257 182.125C326.257 182.389 326.152 182.643 325.965 182.83C325.778 183.018 325.524 183.123 325.26 183.123H258.528C254.904 183.124 251.429 181.685 248.866 179.122C246.302 176.56 244.861 173.083 244.859 169.458C244.857 165.832 246.294 162.354 248.854 159.789C251.414 157.223 254.888 155.78 258.511 155.776H325.26C325.391 155.776 325.521 155.802 325.642 155.852C325.764 155.902 325.874 155.975 325.967 156.068C326.06 156.16 326.133 156.271 326.184 156.392C326.234 156.513 326.26 156.643 326.26 156.774C326.26 156.906 326.234 157.036 326.184 157.157C326.133 157.278 326.06 157.388 325.967 157.481C325.874 157.574 325.764 157.647 325.642 157.697C325.521 157.747 325.391 157.773 325.26 157.772H258.528C255.435 157.777 252.471 159.009 250.285 161.198C248.1 163.387 246.873 166.355 246.873 169.45C246.873 172.544 248.1 175.512 250.285 177.701C252.471 179.891 255.435 181.123 258.528 181.127H325.26C325.524 181.127 325.778 181.232 325.965 181.419C326.152 181.606 326.257 181.86 326.257 182.125Z" fill="#6B6A6A"/>
                    <path d="M324.661 162.363H254.837C254.785 162.363 254.734 162.343 254.696 162.306C254.659 162.269 254.637 162.218 254.637 162.165C254.636 162.113 254.657 162.062 254.694 162.024C254.731 161.986 254.781 161.965 254.834 161.964H324.661C324.714 161.964 324.764 161.986 324.801 162.023C324.838 162.06 324.859 162.111 324.859 162.163C324.859 162.216 324.838 162.267 324.801 162.304C324.764 162.341 324.714 162.363 324.661 162.363H324.661Z" fill="#CCCCCC"/>
                    <path d="M324.661 167.154H254.837C254.785 167.154 254.734 167.134 254.696 167.097C254.659 167.06 254.637 167.009 254.637 166.956C254.636 166.904 254.657 166.853 254.694 166.815C254.731 166.777 254.781 166.756 254.834 166.755H324.661C324.714 166.755 324.764 166.777 324.801 166.814C324.838 166.851 324.859 166.902 324.859 166.954C324.859 167.007 324.838 167.058 324.801 167.095C324.764 167.132 324.714 167.154 324.661 167.154H324.661Z" fill="#CCCCCC"/>
                    <path d="M324.661 171.945H254.837C254.785 171.945 254.734 171.925 254.696 171.888C254.659 171.851 254.637 171.8 254.637 171.747C254.636 171.695 254.657 171.644 254.694 171.606C254.731 171.568 254.781 171.547 254.834 171.546H324.661C324.714 171.546 324.764 171.568 324.801 171.605C324.838 171.642 324.859 171.693 324.859 171.746C324.859 171.798 324.838 171.849 324.801 171.886C324.764 171.923 324.714 171.945 324.661 171.945H324.661Z" fill="#CCCCCC"/>
                    <path d="M324.661 176.735H254.837C254.785 176.735 254.734 176.715 254.696 176.678C254.659 176.641 254.637 176.59 254.637 176.537C254.636 176.485 254.657 176.434 254.694 176.396C254.731 176.358 254.781 176.337 254.834 176.336H324.661C324.714 176.336 324.764 176.358 324.801 176.395C324.838 176.432 324.859 176.483 324.859 176.536C324.859 176.588 324.838 176.639 324.801 176.676C324.764 176.713 324.714 176.735 324.661 176.735H324.661Z" fill="#CCCCCC"/>
                    <path d="M248.35 53.446H218.747C218.466 53.4457 218.188 53.5102 217.936 53.6345L210.465 57.3771C210.219 57.4817 210.009 57.6563 209.862 57.8793C209.714 58.1022 209.636 58.3636 209.636 58.6309C209.636 58.8982 209.714 59.1597 209.862 59.3826C210.009 59.6055 210.219 59.7802 210.465 59.8847L217.936 63.6273C218.188 63.7516 218.466 63.8162 218.747 63.8159H248.35C248.758 63.8395 249.159 63.7014 249.466 63.4314C249.773 63.1614 249.961 62.7814 249.99 62.3735V54.8883C249.961 54.4805 249.773 54.1004 249.466 53.8304C249.159 53.5605 248.758 53.4223 248.35 53.446Z" fill="#6B6A6A"/>
                    <path d="M249.99 54.8884V62.3735C249.961 62.7814 249.773 63.1615 249.466 63.4315C249.159 63.7014 248.758 63.8396 248.35 63.816H242.452V53.446H248.35C248.758 53.4223 249.159 53.5605 249.466 53.8305C249.773 54.1004 249.961 54.4805 249.99 54.8884Z" fill="var(--brand-purple)"/>
                    <path d="M70.8667 200.115C74.6786 201.456 78.7713 201.792 82.7506 201.091C86.7299 200.389 90.4616 198.674 93.5862 196.111C101.544 189.428 104.039 178.418 106.069 168.224C108.07 158.173 110.072 148.121 112.073 138.07L99.5023 146.731C90.4619 152.959 81.2179 159.387 74.9584 168.409C68.6989 177.43 65.9666 189.745 70.9959 199.507" fill="#E6E6E6"/>
                    <path d="M71.9414 236.623C70.669 227.35 69.3607 217.958 70.2532 208.585C71.0458 200.261 73.5838 192.131 78.751 185.465C81.4937 181.934 84.831 178.908 88.613 176.524C89.5992 175.901 90.5069 177.465 89.525 178.085C82.9811 182.223 77.9197 188.333 75.0704 195.535C71.9234 203.544 71.4181 212.274 71.9604 220.782C72.2883 225.927 72.9834 231.038 73.6838 236.143C73.739 236.373 73.7045 236.617 73.5874 236.823C73.4703 237.029 73.2792 237.184 73.0528 237.254C72.8218 237.317 72.5755 237.285 72.3675 237.167C72.1594 237.049 72.0063 236.854 71.9414 236.623L71.9414 236.623Z" fill="#F2F2F2"/>
                    <path d="M84.1964 220.214C85.8312 222.7 88.0784 224.723 90.7215 226.088C93.3646 227.453 96.3144 228.114 99.2869 228.007C106.926 227.644 113.295 222.31 119.027 217.245L135.984 202.265L124.762 201.728C116.691 201.341 108.412 200.981 100.725 203.471C93.0382 205.961 85.9489 211.954 84.5433 219.915" fill="#E6E6E6"/>
                    <path d="M68.3455 241.954C74.4696 231.111 81.573 219.062 94.2655 215.21C97.7946 214.143 101.495 213.761 105.168 214.084C106.325 214.184 106.036 215.969 104.881 215.869C98.7247 215.358 92.5804 216.984 87.4824 220.475C82.5767 223.816 78.7571 228.461 75.5242 233.387C73.544 236.404 71.7702 239.549 69.9961 242.69C69.4291 243.694 67.7719 242.969 68.3455 241.954L68.3455 241.954Z" fill="#F2F2F2"/>
                    <path d="M58.5329 253.366C58.7392 250.974 59.5976 248.684 61.0146 246.746C62.4317 244.808 64.3529 243.296 66.5692 242.376V236.107H77.0763V242.573C79.1835 243.539 80.997 245.047 82.3319 246.943C83.6668 248.839 84.4753 251.055 84.6751 253.366L87.1457 282.102H56.0622L58.5329 253.366Z" fill="#F2F2F2"/>
                    <path d="M415.86 116.157L412.735 114.84L410.59 99.1621H382.036L379.712 114.776L376.916 116.175C376.782 116.242 376.675 116.352 376.611 116.488C376.548 116.623 376.532 116.776 376.567 116.922C376.601 117.068 376.683 117.197 376.801 117.29C376.918 117.383 377.063 117.434 377.213 117.434H415.603C415.756 117.434 415.905 117.381 416.023 117.283C416.142 117.186 416.223 117.051 416.254 116.901C416.284 116.75 416.261 116.594 416.19 116.459C416.118 116.323 416.002 116.216 415.86 116.157Z" fill="#E6E6E6"/>
                    <path d="M466.602 101.248H326.337C325.601 101.246 324.896 100.952 324.376 100.431C323.857 99.9098 323.565 99.2035 323.565 98.4671V78.6768H469.375V98.4671C469.375 99.2035 469.083 99.9097 468.563 100.431C468.044 100.952 467.338 101.246 466.602 101.248Z" fill="#CCCCCC"/>
                    <path d="M469.522 88.0305H323.437V3.35179C323.438 2.46314 323.791 1.61118 324.419 0.982812C325.047 0.354449 325.899 0.000994348 326.787 0H466.172C467.06 0.000994332 467.911 0.35445 468.539 0.982812C469.167 1.61117 469.521 2.46313 469.522 3.35179L469.522 88.0305Z" fill="#6B6A6A"/>
                    <path d="M460.797 81.8842H332.162C331.477 81.8834 330.821 81.6109 330.337 81.1265C329.853 80.6421 329.58 79.9853 329.579 79.3002V8.72967C329.58 8.04456 329.852 7.38772 330.337 6.90326C330.821 6.4188 331.477 6.14629 332.162 6.14551H460.797C461.482 6.14629 462.138 6.4188 462.622 6.90326C463.106 7.38772 463.379 8.04456 463.379 8.72967V79.3002C463.379 79.9853 463.106 80.6421 462.622 81.1265C462.138 81.6109 461.482 81.8834 460.797 81.8842Z" fill="white"/>
                    <path d="M426.916 106.785C427.747 106.018 428.735 105.442 429.812 105.098C430.889 104.754 432.029 104.65 433.15 104.795C434.271 104.939 435.348 105.328 436.303 105.933C437.258 106.538 438.068 107.346 438.678 108.299L456.077 105.204L460.536 115.324L435.903 119.35C434.235 120.268 432.289 120.542 430.433 120.121C428.577 119.699 426.94 118.61 425.832 117.062C424.724 115.513 424.223 113.611 424.422 111.717C424.622 109.823 425.51 108.068 426.916 106.785Z" fill="#F7DECE"/>
                    <path d="M443.025 119.708L461.165 119.482L463.461 119.451L500.366 118.986C500.366 118.986 510.387 109.419 519.114 99.57L517.891 90.7181C516.698 82.0309 512.391 74.0732 505.772 68.3262C493.273 76.6823 486.025 93.2222 486.025 93.2222L463.143 98.6984L460.853 99.2461L446.635 102.646L443.025 119.708Z" fill="var(--brand-purple)"/>
                    <path d="M535.882 174.115H502.023C502.023 175.567 465.01 176.745 465.01 176.745C464.194 177.36 463.45 178.066 462.792 178.849C460.816 181.207 459.737 184.187 459.743 187.264V189.368C459.744 192.855 461.129 196.198 463.593 198.664C466.058 201.129 469.399 202.515 472.884 202.516H535.882C539.366 202.513 542.707 201.127 545.171 198.662C547.635 196.197 549.02 192.854 549.023 189.368V187.264C549.019 183.778 547.632 180.437 545.169 177.972C542.706 175.507 539.366 174.12 535.882 174.115Z" fill="#E4E4E4"/>
                    <path d="M511.26 201.994H497.593V280.925H511.26V201.994Z" fill="#E4E4E4"/>
                    <path d="M458.695 280.444C458.695 281.922 479.405 281.016 504.952 281.016C530.499 281.016 551.209 281.922 551.209 280.444C551.209 278.967 530.499 267.25 504.952 267.25C479.405 267.25 458.695 278.967 458.695 280.444Z" fill="#E4E4E4"/>
                    <path d="M449.514 271.425L439.343 271.425L434.504 232.17L449.516 232.171L449.514 271.425Z" fill="#F7DECE"/>
                    <path d="M452.108 281.29L419.311 281.289V280.874C419.311 277.487 420.656 274.238 423.05 271.843C425.444 269.447 428.691 268.102 432.077 268.102H432.077L452.109 268.102L452.108 281.29Z" fill="#2B3037"/>
                    <path d="M419.452 271.425L409.28 271.425L404.441 232.17L419.453 232.171L419.452 271.425Z" fill="#F7DECE"/>
                    <path d="M422.045 281.29L389.248 281.289V280.874C389.249 277.487 390.594 274.238 392.988 271.843C395.381 269.447 398.628 268.102 402.014 268.102H402.015L422.046 268.102L422.045 281.29Z" fill="#2B3037"/>
                    <path d="M510.565 45.4306C520.333 45.4306 528.251 37.5082 528.251 27.7353C528.251 17.9625 520.333 10.04 510.565 10.04C500.798 10.04 492.88 17.9625 492.88 27.7353C492.88 37.5082 500.798 45.4306 510.565 45.4306Z" fill="#F7DECE"/>
                    <path d="M539.188 148.977C539.188 148.977 545.123 180.373 522.545 184.371C499.967 188.369 467.375 191.179 467.375 191.179L452.739 259.502L433.077 257.746C433.077 257.746 435.692 175.499 442.123 169.065C448.554 162.63 489.2 143.363 489.2 143.363L539.188 148.977Z" fill="#2B3037"/>
                    <path d="M495.092 135.818L485.218 145.73C485.218 145.73 413.919 157.221 411.168 173.698C407.015 198.574 402.11 252.67 402.11 252.67L423.547 251.061L431.953 189.569L466.252 181.763L505.275 160.485L495.092 135.818Z" fill="#2B3037"/>
                    <path d="M527.583 17.1724C525.838 13.8767 523.619 7.30831 520.096 6.09013C518.139 5.44091 516.116 5.00981 514.065 4.80467L508.076 7.10956L511.335 4.57291C510.701 4.53507 510.066 4.50415 509.432 4.48013L505.391 6.03525L507.459 4.42631C503.691 4.38763 499.802 4.77958 496.883 7.07435C494.395 9.03065 491.929 16.4415 491.53 19.5828C491.104 22.5879 491.253 25.6467 491.97 28.596L492.993 29.5697C493.351 28.7484 493.62 27.8911 493.796 27.0125C494.163 25.3708 494.868 23.8237 495.865 22.4696C496.863 21.1155 498.131 19.9842 499.59 19.1478L499.646 19.1163C501.362 18.1498 503.445 18.1943 505.413 18.2652L514.762 18.6018C517.011 18.6828 519.433 18.8252 521.186 20.2368C522.301 21.2781 523.177 22.5482 523.755 23.96C524.626 25.7196 526.329 32.3561 526.329 32.3561C526.329 32.3561 527.292 31.1034 527.754 32.0358C528.494 29.6031 528.944 27.0913 529.095 24.5527C529.242 22.0029 528.721 19.4588 527.583 17.1724Z" fill="#2B3037"/>
                    <path d="M589.439 35.5066C588.31 35.581 587.212 35.9019 586.222 36.4468C585.231 36.9917 584.372 37.7473 583.704 38.6605C583.037 39.5736 582.578 40.622 582.359 41.7319C582.141 42.8418 582.168 43.9861 582.439 45.0843L568.218 55.5808L572.485 65.7838L592.36 50.6785C594.174 50.1003 595.713 48.8765 596.685 47.239C597.657 45.6015 597.995 43.6642 597.635 41.794C597.274 39.9239 596.241 38.2509 594.731 37.0921C593.22 35.9333 591.337 35.3692 589.439 35.5066Z" fill="#F7DECE"/>
                    <path d="M587.15 56.9353L551.497 96.5535C551.497 96.5535 534.448 91.0924 521.262 85.1717L515.338 66.9676C513.963 62.7444 513.376 58.3041 513.607 53.8683C531.886 49.0015 553.147 67.1103 553.147 67.1103L579.834 46.1943L587.15 56.9353Z" fill="var(--brand-purple)"/>
                    <path d="M503.959 151.903C497.598 149.704 491.021 147.431 483.927 146.578L483.622 146.541L483.706 146.246C491.05 120.446 489.217 103.942 485.618 79.1504C484.853 73.8029 485.836 68.3512 488.422 63.6089C491.007 58.8666 495.056 55.088 499.964 52.8371L500.008 52.8174L520.27 51.5292L520.316 51.5264L535.052 55.8629C537.172 56.4913 539.028 57.7978 540.334 59.5823C541.641 61.3667 542.327 63.5306 542.287 65.7424C542.128 73.8955 542.466 82.9983 542.825 92.6352C543.629 114.255 544.46 136.611 539.742 151.117L539.717 151.193L539.654 151.242C535.316 154.777 529.882 156.687 524.288 156.643C517.674 156.643 510.924 154.31 503.959 151.903Z" fill="var(--brand-purple)"/>
                    <path d="M651.058 283H0.942198C0.692312 283 0.452663 282.9 0.275967 282.724C0.0992715 282.547 0 282.307 0 282.057C0 281.807 0.0992715 281.567 0.275967 281.39C0.452663 281.214 0.692312 281.114 0.942198 281.114H651.058C651.308 281.114 651.547 281.214 651.724 281.39C651.901 281.567 652 281.807 652 282.057C652 282.307 651.901 282.547 651.724 282.724C651.547 282.9 651.308 283 651.058 283Z" fill="#CCCCCC"/>
                </g>
                <defs>
                    <clipPath id="clip0_532_17983">
                        <rect width="652" height="283" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
            <svg v-else width="434" height="188" viewBox="0 0 434 188" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M391.481 77.5557H136.851C135.77 77.5557 134.733 77.9845 133.968 78.748C133.204 79.5114 132.774 80.5468 132.774 81.6264C132.774 82.706 133.204 83.7414 133.968 84.5048C134.733 85.2682 135.77 85.6971 136.851 85.6971H144.063V183.081C144.063 184.161 144.493 185.196 145.257 185.96C146.022 186.723 147.059 187.152 148.14 187.152C149.221 187.152 150.258 186.723 151.022 185.96C151.787 185.196 152.216 184.161 152.216 183.081L152.278 85.6971H356.645L375.488 183.081C375.488 184.161 375.917 185.196 376.682 185.96C377.447 186.723 378.483 187.152 379.565 187.152C380.646 187.152 381.683 186.723 382.447 185.96C383.212 185.196 383.641 184.161 383.641 183.081L384.827 85.6971H391.481C392.562 85.6971 393.599 85.2682 394.363 84.5048C395.128 83.7414 395.557 82.706 395.557 81.6264C395.557 80.5468 395.128 79.5114 394.363 78.748C393.599 77.9845 392.562 77.5557 391.481 77.5557V77.5557Z" fill="#CCCCCC"/>
                <path d="M180.987 59.4173V76.6557H137.737C136.603 76.6951 135.473 76.5111 134.411 76.1143C133.348 75.7176 132.375 75.1158 131.545 74.3432C130.716 73.5707 130.047 72.6426 129.577 71.612C129.106 70.5813 128.844 69.4683 128.805 68.3364C128.765 67.2045 128.949 66.0759 129.347 65.0151C129.744 63.9543 130.347 62.9821 131.12 62.1539C131.894 61.3256 132.824 60.6577 133.856 60.1882C134.888 59.7186 136.002 59.4567 137.136 59.4173C137.336 59.4104 137.536 59.4104 137.737 59.4173L180.987 59.4173Z" fill="#F2F2F2"/>
                <path d="M182.05 76.5231C182.05 76.6989 181.98 76.8675 181.855 76.9919C181.731 77.1162 181.562 77.1861 181.386 77.1861H136.966C134.555 77.1844 132.243 76.2267 130.538 74.5234C128.834 72.8202 127.876 70.5108 127.876 68.1028C127.876 65.6949 128.834 63.3855 130.538 61.6822C132.243 59.9789 134.555 59.0212 136.966 59.0195H181.386C181.473 59.0193 181.56 59.0363 181.64 59.0695C181.721 59.1027 181.795 59.1515 181.856 59.2131C181.918 59.2747 181.967 59.3479 182.001 59.4284C182.034 59.509 182.051 59.5953 182.051 59.6825C182.051 59.7698 182.034 59.8561 182.001 59.9367C181.967 60.0172 181.918 60.0904 181.856 60.152C181.795 60.2136 181.721 60.2624 181.64 60.2956C181.56 60.3288 181.473 60.3458 181.386 60.3456H136.966C134.908 60.3487 132.935 61.1673 131.481 62.6218C130.026 64.0762 129.209 66.0475 129.209 68.1028C129.209 70.1582 130.026 72.1295 131.481 73.5839C132.935 75.0383 134.908 75.857 136.966 75.8601H181.386C181.562 75.8602 181.731 75.93 181.855 76.0544C181.98 76.1787 182.05 76.3473 182.05 76.5231V76.5231Z" fill="var(--brand-purple)"/>
                <path d="M180.988 63.3951H134.51C134.474 63.3953 134.441 63.3815 134.416 63.3569C134.391 63.3323 134.376 63.2988 134.376 63.2637C134.376 63.2286 134.389 63.1948 134.414 63.1698C134.439 63.1447 134.472 63.1303 134.507 63.1299H180.988C181.023 63.1302 181.056 63.1444 181.081 63.1692C181.105 63.194 181.119 63.2275 181.119 63.2625C181.119 63.2974 181.105 63.331 181.081 63.3558C181.056 63.3806 181.023 63.3947 180.988 63.3951Z" fill="#CCCCCC"/>
                <path d="M180.988 66.5777H134.51C134.474 66.5779 134.441 66.5642 134.416 66.5395C134.391 66.5149 134.376 66.4814 134.376 66.4463C134.376 66.4112 134.389 66.3775 134.414 66.3524C134.439 66.3273 134.472 66.313 134.507 66.3125H180.988C181.023 66.3128 181.056 66.327 181.081 66.3518C181.105 66.3766 181.119 66.4102 181.119 66.4451C181.119 66.48 181.105 66.5136 181.081 66.5384C181.056 66.5632 181.023 66.5773 180.988 66.5777Z" fill="#CCCCCC"/>
                <path d="M180.988 69.7603H134.51C134.474 69.7605 134.441 69.7468 134.416 69.7222C134.391 69.6975 134.376 69.664 134.376 69.6289C134.376 69.5939 134.389 69.5601 134.414 69.535C134.439 69.5099 134.472 69.4956 134.507 69.4951H180.988C181.023 69.4955 181.056 69.5096 181.081 69.5344C181.105 69.5593 181.119 69.5928 181.119 69.6277C181.119 69.6627 181.105 69.6962 181.081 69.721C181.056 69.7458 181.023 69.76 180.988 69.7603Z" fill="#CCCCCC"/>
                <path d="M180.988 72.9429H134.51C134.474 72.9431 134.441 72.9294 134.416 72.9048C134.391 72.8802 134.376 72.8466 134.376 72.8116C134.376 72.7765 134.389 72.7427 134.414 72.7176C134.439 72.6925 134.472 72.6782 134.507 72.6777H180.988C181.023 72.6781 181.056 72.6922 181.081 72.717C181.105 72.7419 181.119 72.7754 181.119 72.8103C181.119 72.8453 181.105 72.8788 181.081 72.9036C181.056 72.9285 181.023 72.9426 180.988 72.9429Z" fill="#CCCCCC"/>
                <path d="M172.834 41.2552V58.4936H129.583C127.294 58.5731 125.067 57.7413 123.392 56.1811C121.717 54.6209 120.731 52.4602 120.651 50.1743C120.572 47.8883 121.405 45.6644 122.967 43.9917C124.53 42.3191 126.693 41.3347 128.983 41.2552C129.183 41.2483 129.383 41.2483 129.583 41.2552H172.834Z" fill="#F2F2F2"/>
                <path d="M173.897 58.361C173.897 58.5368 173.827 58.7054 173.702 58.8297C173.578 58.954 173.409 59.0239 173.233 59.024H128.813C126.401 59.024 124.087 58.067 122.381 56.3636C120.675 54.6601 119.717 52.3497 119.717 49.9407C119.717 47.5317 120.675 45.2213 122.381 43.5179C124.087 41.8144 126.401 40.8574 128.813 40.8574H173.233C173.32 40.8574 173.406 40.8746 173.487 40.9079C173.567 40.9412 173.64 40.99 173.702 41.0516C173.764 41.1132 173.813 41.1863 173.846 41.2667C173.879 41.3472 173.897 41.4334 173.897 41.5204C173.897 41.6075 173.879 41.6937 173.846 41.7741C173.813 41.8546 173.764 41.9277 173.702 41.9893C173.64 42.0508 173.567 42.0997 173.487 42.133C173.406 42.1663 173.32 42.1835 173.233 42.1835H128.813C126.753 42.1835 124.777 43.0007 123.32 44.4555C121.863 45.9103 121.045 47.8834 121.045 49.9407C121.045 51.9981 121.863 53.9712 123.32 55.4259C124.777 56.8807 126.753 57.698 128.813 57.698H173.233C173.409 57.6981 173.578 57.7679 173.702 57.8922C173.827 58.0166 173.897 58.1852 173.897 58.361Z" fill="#6B6A6A"/>
                <path d="M172.834 45.233H126.356C126.321 45.2332 126.287 45.2194 126.262 45.1948C126.237 45.1702 126.223 45.1367 126.223 45.1016C126.223 45.0665 126.236 45.0327 126.261 45.0077C126.285 44.9826 126.319 44.9682 126.354 44.9678H172.834C172.869 44.9681 172.903 44.9822 172.927 45.0071C172.952 45.0319 172.966 45.0654 172.966 45.1004C172.966 45.1353 172.952 45.1688 172.927 45.1937C172.903 45.2185 172.869 45.2326 172.834 45.233V45.233Z" fill="#CCCCCC"/>
                <path d="M172.834 48.4156H126.356C126.321 48.4158 126.287 48.4021 126.262 48.3774C126.237 48.3528 126.223 48.3193 126.223 48.2842C126.223 48.2491 126.236 48.2154 126.261 48.1903C126.285 48.1652 126.319 48.1509 126.354 48.1504H172.834C172.869 48.1507 172.903 48.1649 172.927 48.1897C172.952 48.2145 172.966 48.2481 172.966 48.283C172.966 48.3179 172.952 48.3515 172.927 48.3763C172.903 48.4011 172.869 48.4152 172.834 48.4156Z" fill="#CCCCCC"/>
                <path d="M172.834 51.5982H126.356C126.321 51.5984 126.287 51.5847 126.262 51.56C126.237 51.5354 126.223 51.5019 126.223 51.4668C126.223 51.4317 126.236 51.398 126.261 51.3729C126.285 51.3478 126.319 51.3335 126.354 51.333H172.834C172.869 51.3334 172.903 51.3475 172.927 51.3723C172.952 51.3971 172.966 51.4307 172.966 51.4656C172.966 51.5005 172.952 51.5341 172.927 51.5589C172.903 51.5837 172.869 51.5978 172.834 51.5982Z" fill="#CCCCCC"/>
                <path d="M172.834 54.7808H126.356C126.321 54.781 126.287 54.7673 126.262 54.7427C126.237 54.718 126.223 54.6845 126.223 54.6494C126.223 54.6144 126.236 54.5806 126.261 54.5555C126.285 54.5304 126.319 54.5161 126.354 54.5156H172.834C172.869 54.516 172.903 54.5301 172.927 54.5549C172.952 54.5798 172.966 54.6133 172.966 54.6482C172.966 54.6832 172.952 54.7167 172.927 54.7415C172.903 54.7664 172.869 54.7805 172.834 54.7808V54.7808Z" fill="#CCCCCC"/>
                <path d="M223.148 138.555V123.525C223.148 122.86 222.884 122.223 222.413 121.753C221.943 121.283 221.305 121.02 220.64 121.02H163.567C162.902 121.02 162.264 121.283 161.793 121.753C161.323 122.223 161.059 122.86 161.059 123.525V138.555H223.148Z" fill="#CCCCCC"/>
                <path d="M223.148 144.191H161.059V163.606H223.148V144.191Z" fill="#CCCCCC"/>
                <path d="M161.059 169.241V184.272C161.059 184.936 161.323 185.573 161.793 186.043C162.264 186.513 162.902 186.777 163.567 186.777H220.64C221.305 186.777 221.943 186.513 222.413 186.043C222.884 185.573 223.148 184.936 223.148 184.272V169.241H161.059Z" fill="#CCCCCC"/>
                <path d="M191.79 132.292C192.829 132.292 193.671 131.451 193.671 130.413C193.671 129.375 192.829 128.534 191.79 128.534C190.751 128.534 189.908 129.375 189.908 130.413C189.908 131.451 190.751 132.292 191.79 132.292Z" fill="white"/>
                <path d="M191.79 157.343C192.829 157.343 193.671 156.501 193.671 155.464C193.671 154.426 192.829 153.585 191.79 153.585C190.751 153.585 189.908 154.426 189.908 155.464C189.908 156.501 190.751 157.343 191.79 157.343Z" fill="white"/>
                <path d="M191.891 181.863C192.93 181.863 193.772 181.022 193.772 179.984C193.772 178.947 192.93 178.105 191.891 178.105C190.852 178.105 190.009 178.947 190.009 179.984C190.009 181.022 190.852 181.863 191.891 181.863Z" fill="white"/>
                <path d="M216.109 103.882V121.121H172.858C170.57 121.198 168.344 120.366 166.67 118.806C164.997 117.246 164.012 115.086 163.932 112.801C163.852 110.516 164.684 108.293 166.246 106.621C167.807 104.948 169.969 103.963 172.257 103.882C172.457 103.875 172.658 103.875 172.858 103.882H216.109Z" fill="#F2F2F2"/>
                <path d="M217.171 120.988C217.171 121.164 217.101 121.332 216.977 121.457C216.852 121.581 216.683 121.651 216.507 121.651H172.088C169.676 121.652 167.362 120.696 165.656 118.993C163.95 117.291 162.99 114.982 162.989 112.573C162.987 110.165 163.944 107.854 165.648 106.15C167.352 104.445 169.665 103.487 172.077 103.484H216.507C216.595 103.484 216.681 103.501 216.762 103.534C216.843 103.568 216.916 103.616 216.978 103.678C217.04 103.74 217.089 103.813 217.122 103.893C217.156 103.974 217.173 104.06 217.173 104.147C217.173 104.235 217.156 104.321 217.122 104.402C217.089 104.482 217.04 104.555 216.978 104.617C216.916 104.678 216.843 104.727 216.762 104.76C216.681 104.794 216.595 104.811 216.507 104.81H172.088C170.029 104.813 168.056 105.632 166.601 107.086C165.146 108.541 164.329 110.512 164.329 112.568C164.329 114.623 165.146 116.595 166.601 118.049C168.056 119.504 170.029 120.322 172.088 120.325H216.507C216.683 120.325 216.852 120.395 216.977 120.519C217.101 120.644 217.171 120.812 217.171 120.988V120.988Z" fill="#6B6A6A"/>
                <path d="M216.109 107.86H169.631C169.596 107.86 169.562 107.846 169.537 107.822C169.512 107.797 169.498 107.764 169.498 107.729C169.497 107.693 169.511 107.66 169.535 107.635C169.56 107.61 169.593 107.595 169.629 107.595H216.109C216.144 107.595 216.177 107.609 216.202 107.634C216.227 107.659 216.24 107.692 216.24 107.727C216.24 107.762 216.227 107.796 216.202 107.821C216.177 107.845 216.144 107.86 216.109 107.86H216.109Z" fill="#CCCCCC"/>
                <path d="M216.109 111.043H169.631C169.596 111.043 169.562 111.029 169.537 111.004C169.512 110.98 169.498 110.946 169.498 110.911C169.497 110.876 169.511 110.842 169.535 110.817C169.56 110.792 169.593 110.778 169.629 110.777H216.109C216.144 110.778 216.177 110.792 216.202 110.817C216.227 110.841 216.24 110.875 216.24 110.91C216.24 110.945 216.227 110.978 216.202 111.003C216.177 111.028 216.144 111.042 216.109 111.043H216.109Z" fill="#CCCCCC"/>
                <path d="M216.109 114.225H169.631C169.596 114.225 169.562 114.212 169.537 114.187C169.512 114.162 169.498 114.129 169.498 114.094C169.497 114.059 169.511 114.025 169.535 114C169.56 113.975 169.593 113.96 169.629 113.96H216.109C216.144 113.96 216.177 113.974 216.202 113.999C216.227 114.024 216.24 114.058 216.24 114.093C216.24 114.128 216.227 114.161 216.202 114.186C216.177 114.211 216.144 114.225 216.109 114.225H216.109Z" fill="#CCCCCC"/>
                <path d="M216.109 117.407H169.631C169.596 117.407 169.562 117.393 169.537 117.369C169.512 117.344 169.498 117.31 169.498 117.275C169.497 117.24 169.511 117.207 169.535 117.181C169.56 117.156 169.593 117.142 169.629 117.142H216.109C216.144 117.142 216.177 117.156 216.202 117.181C216.227 117.206 216.24 117.239 216.24 117.274C216.24 117.309 216.227 117.343 216.202 117.367C216.177 117.392 216.144 117.406 216.109 117.407H216.109Z" fill="#CCCCCC"/>
                <path d="M165.313 35.5047H145.607C145.42 35.5045 145.236 35.5474 145.068 35.6299L140.095 38.1162C139.931 38.1856 139.791 38.3016 139.693 38.4498C139.595 38.5979 139.543 38.7715 139.543 38.9491C139.543 39.1267 139.595 39.3003 139.693 39.4484C139.791 39.5965 139.931 39.7125 140.095 39.782L145.068 42.2683C145.236 42.3508 145.42 42.3937 145.607 42.3935H165.313C165.585 42.4092 165.851 42.3174 166.056 42.1381C166.26 41.9588 166.385 41.7063 166.404 41.4353V36.4628C166.385 36.1919 166.26 35.9394 166.056 35.7601C165.851 35.5807 165.585 35.489 165.313 35.5047V35.5047Z" fill="#6B6A6A"/>
                <path d="M166.404 36.4629V41.4354C166.385 41.7063 166.26 41.9588 166.056 42.1381C165.851 42.3175 165.584 42.4093 165.313 42.3936H161.387V35.5047H165.313C165.584 35.489 165.851 35.5807 166.056 35.7601C166.26 35.9394 166.385 36.1919 166.404 36.4629V36.4629Z" fill="var(--brand-purple)"/>
                <path d="M47.172 132.939C49.7094 133.83 52.4337 134.053 55.0825 133.587C57.7313 133.121 60.2153 131.982 62.2951 130.279C67.5921 125.839 69.2528 118.525 70.6041 111.753C71.9364 105.076 73.2687 98.3988 74.601 91.7217L66.2331 97.4752C60.2155 101.613 54.0622 105.883 49.8957 111.876C45.7291 117.869 43.9103 126.05 47.258 132.535" fill="#E6E6E6"/>
                <path d="M47.8873 157.191C47.0404 151.031 46.1695 144.792 46.7636 138.565C47.2912 133.036 48.9806 127.635 52.4201 123.207C54.2458 120.86 56.4673 118.85 58.9847 117.266C59.6411 116.853 60.2453 117.892 59.5918 118.304C55.2359 121.052 51.8667 125.112 49.9702 129.896C47.8754 135.216 47.539 141.016 47.9 146.668C48.1183 150.085 48.5809 153.481 49.0472 156.872C49.0839 157.025 49.061 157.187 48.983 157.324C48.905 157.461 48.7778 157.564 48.6271 157.611C48.4734 157.652 48.3094 157.631 48.171 157.553C48.0325 157.474 47.9306 157.344 47.8873 157.191L47.8873 157.191Z" fill="#F2F2F2"/>
                <path d="M56.0448 146.291C57.133 147.942 58.6289 149.286 60.3882 150.193C62.1476 151.1 64.1111 151.539 66.0897 151.468C71.1746 151.227 75.4138 147.683 79.2299 144.319L90.5171 134.367L83.0468 134.01C77.6747 133.753 72.1639 133.514 67.0471 135.168C61.9304 136.822 57.2114 140.804 56.2757 146.092" fill="#E6E6E6"/>
                <path d="M45.4938 160.733C49.5702 153.53 54.2985 145.525 62.7472 142.967C65.0964 142.258 67.5596 142.004 70.0043 142.218C70.7748 142.285 70.5824 143.471 69.8133 143.405C65.7155 143.065 61.6256 144.145 58.2321 146.464C54.9667 148.684 52.4242 151.769 50.2723 155.042C48.9541 157.046 47.7734 159.135 46.5925 161.222C46.2151 161.889 45.112 161.407 45.4938 160.733L45.4938 160.733Z" fill="#F2F2F2"/>
                <path d="M38.962 168.314C39.0994 166.724 39.6708 165.203 40.614 163.916C41.5572 162.629 42.8361 161.624 44.3114 161.013V156.849H51.3054V161.144C52.708 161.786 53.9152 162.787 54.8037 164.047C55.6923 165.306 56.2305 166.779 56.3634 168.314L58.008 187.403H37.3174L38.962 168.314Z" fill="#F2F2F2"/>
                <path d="M276.815 77.1638L274.734 76.289L273.307 65.874H254.3L252.753 76.2465L250.892 77.1758C250.803 77.2203 250.731 77.2935 250.689 77.3836C250.647 77.4736 250.636 77.5753 250.659 77.672C250.682 77.7688 250.737 77.855 250.815 77.9167C250.893 77.9784 250.99 78.012 251.089 78.012H276.643C276.745 78.012 276.844 77.9768 276.923 77.9122C277.002 77.8477 277.057 77.7579 277.077 77.658C277.097 77.5581 277.082 77.4543 277.034 77.3642C276.986 77.2742 276.909 77.2034 276.815 77.1638V77.1638Z" fill="#E6E6E6"/>
                <path d="M310.591 67.2597H217.225C216.735 67.2586 216.265 67.0635 215.919 66.7172C215.573 66.3709 215.379 65.9017 215.379 65.4126V52.2656H312.437V65.4126C312.437 65.9017 312.242 66.3709 311.896 66.7172C311.55 67.0635 311.081 67.2586 310.591 67.2597V67.2597Z" fill="#CCCCCC"/>
                <path d="M312.534 58.4796H215.294V2.22663C215.295 1.63629 215.53 1.07032 215.948 0.652893C216.366 0.235464 216.933 0.000660556 217.524 0H310.304C310.896 0.000660545 311.462 0.235465 311.881 0.652893C312.299 1.07032 312.534 1.63629 312.534 2.22663L312.534 58.4796Z" fill="#6B6A6A"/>
                <path d="M306.727 54.397H221.102C220.646 54.3965 220.209 54.2155 219.887 53.8937C219.564 53.5719 219.383 53.1356 219.383 52.6805V5.7997C219.383 5.34457 219.564 4.90822 219.887 4.58639C220.209 4.26456 220.646 4.08353 221.102 4.08301H306.727C307.183 4.08353 307.62 4.26456 307.942 4.58639C308.264 4.90822 308.445 5.34457 308.446 5.7997V52.6805C308.445 53.1356 308.264 53.5719 307.942 53.8937C307.62 54.2155 307.183 54.3965 306.727 54.397V54.397Z" fill="white"/>
                <path d="M284.174 70.9385C284.727 70.4286 285.385 70.046 286.102 69.8175C286.819 69.5891 287.577 69.5204 288.324 69.6162C289.07 69.7121 289.787 69.9702 290.422 70.3724C291.058 70.7746 291.598 71.3111 292.003 71.9441L303.585 69.888L306.553 76.6113L290.156 79.2856C289.046 79.8955 287.751 80.0776 286.515 79.7974C285.28 79.5172 284.19 78.7941 283.452 77.7653C282.715 76.7364 282.381 75.4731 282.514 74.2149C282.647 72.9567 283.238 71.791 284.174 70.9385V70.9385Z" fill="#F7DECE"/>
                <path d="M294.897 79.5233L306.971 79.373L308.5 79.3523L333.065 79.0433C333.065 79.0433 339.736 72.6882 345.545 66.1452L344.731 60.2649C343.937 54.4938 341.07 49.2075 336.664 45.3896C328.344 50.9407 323.52 61.9284 323.52 61.9284L308.288 65.5662L306.764 65.9301L297.3 68.1884L294.897 79.5233Z" fill="var(--brand-purple)"/>
                <path d="M356.707 115.667H334.169C334.169 116.631 309.531 117.414 309.531 117.414C308.988 117.823 308.493 118.291 308.055 118.812C306.74 120.378 306.021 122.358 306.025 124.402V125.799C306.026 128.116 306.948 130.337 308.588 131.975C310.229 133.613 312.453 134.533 314.773 134.534H356.707C359.026 134.532 361.25 133.611 362.89 131.974C364.53 130.336 365.452 128.115 365.454 125.799V124.402C365.451 122.086 364.528 119.866 362.889 118.229C361.249 116.592 359.026 115.67 356.707 115.667V115.667Z" fill="#E4E4E4"/>
                <path d="M340.317 134.187H331.22V186.621H340.317V134.187Z" fill="#E4E4E4"/>
                <path d="M305.328 186.302C305.328 187.284 319.113 186.682 336.118 186.682C353.124 186.682 366.909 187.284 366.909 186.302C366.909 185.321 353.124 177.537 336.118 177.537C319.113 177.537 305.328 185.321 305.328 186.302Z" fill="#E4E4E4"/>
                <path d="M299.217 180.311L292.446 180.311L289.225 154.233L299.217 154.234L299.217 180.311Z" fill="#F7DECE"/>
                <path d="M300.943 186.864L279.112 186.863V186.587C279.112 184.337 280.007 182.179 281.601 180.588C283.194 178.997 285.356 178.103 287.609 178.103H287.61L300.944 178.103L300.943 186.864Z" fill="#2B3037"/>
                <path d="M279.205 180.311L272.435 180.311L269.214 154.233L279.206 154.234L279.205 180.311Z" fill="#F7DECE"/>
                <path d="M280.932 186.864L259.101 186.863V186.587C259.101 184.337 259.996 182.179 261.59 180.588C263.183 178.997 265.345 178.103 267.598 178.103H267.599L280.932 178.103L280.932 186.864Z" fill="#2B3037"/>
                <path d="M339.855 30.1803C346.356 30.1803 351.627 24.9173 351.627 18.4251C351.627 11.9329 346.356 6.66992 339.855 6.66992C333.353 6.66992 328.083 11.9329 328.083 18.4251C328.083 24.9173 333.353 30.1803 339.855 30.1803Z" fill="#F7DECE"/>
                <path d="M358.908 98.9667C358.908 98.9667 362.858 119.823 347.829 122.479C332.8 125.135 311.106 127.002 311.106 127.002L301.363 172.39L288.275 171.223C288.275 171.223 290.016 116.586 294.296 112.311C298.577 108.036 325.633 95.2373 325.633 95.2373L358.908 98.9667Z" fill="#2B3037"/>
                <path d="M329.555 90.2256L322.982 96.81C322.982 96.81 275.522 104.444 273.692 115.389C270.927 131.915 267.662 167.851 267.662 167.851L281.932 166.783L287.527 125.933L310.358 120.747L336.333 106.612L329.555 90.2256Z" fill="#2B3037"/>
                <path d="M351.183 11.4085C350.021 9.21907 348.544 4.85564 346.199 4.04639C344.896 3.61511 343.55 3.32873 342.184 3.19245L338.198 4.72362L340.367 3.03849C339.945 3.01336 339.523 2.99281 339.101 2.97685L336.411 4.00994L337.787 2.94111C335.279 2.9154 332.691 3.17578 330.748 4.70022C329.091 5.99981 327.45 10.9229 327.184 13.0097C326.901 15.006 327 17.038 327.477 18.9973L328.158 19.6441C328.396 19.0985 328.576 18.529 328.693 17.9453C328.937 16.8548 329.406 15.827 330.07 14.9274C330.734 14.0279 331.578 13.2764 332.549 12.7207L332.586 12.6998C333.729 12.0577 335.115 12.0873 336.425 12.1344L342.648 12.358C344.145 12.4118 345.757 12.5064 346.925 13.4442C347.666 14.1359 348.25 14.9797 348.634 15.9176C349.214 17.0864 350.348 21.4952 350.348 21.4952C350.348 21.4952 350.989 20.663 351.296 21.2824C351.789 19.6663 352.089 17.9977 352.189 16.3113C352.287 14.6174 351.94 12.9273 351.183 11.4085V11.4085Z" fill="#2B3037"/>
                <path d="M392.356 23.5877C391.605 23.6371 390.874 23.8503 390.215 24.2123C389.556 24.5742 388.983 25.0762 388.539 25.6828C388.095 26.2894 387.789 26.9859 387.644 27.7232C387.499 28.4605 387.517 29.2207 387.697 29.9502L378.231 36.9232L381.071 43.7012L394.301 33.6666C395.509 33.2824 396.533 32.4694 397.18 31.3816C397.827 30.2939 398.052 29.0068 397.812 27.7645C397.572 26.5222 396.884 25.4108 395.879 24.641C394.873 23.8712 393.62 23.4964 392.356 23.5877V23.5877Z" fill="#F7DECE"/>
                <path d="M390.833 37.8229L367.101 64.1417C367.101 64.1417 355.752 60.5138 346.975 56.5806L343.032 44.4874C342.116 41.6819 341.726 38.7321 341.879 35.7854C354.047 32.5524 368.199 44.5822 368.199 44.5822L385.963 30.6875L390.833 37.8229Z" fill="var(--brand-purple)"/>
                <path d="M335.457 100.911C331.224 99.4503 326.845 97.9402 322.123 97.3732L321.92 97.3488L321.976 97.1527C326.865 80.0135 325.645 69.0496 323.249 52.5805C322.74 49.0281 323.394 45.4064 325.115 42.256C326.836 39.1057 329.531 36.5955 332.798 35.1003L332.827 35.0872L346.315 34.2314L346.345 34.2295L356.155 37.1103C357.566 37.5278 358.801 38.3957 359.671 39.5811C360.54 40.7665 360.997 42.204 360.97 43.6734C360.864 49.0896 361.09 55.1366 361.328 61.5385C361.863 75.9009 362.417 90.7523 359.276 100.388L359.26 100.439L359.218 100.472C356.33 102.82 352.713 104.089 348.989 104.059C344.586 104.059 340.093 102.51 335.457 100.911Z" fill="var(--brand-purple)"/>
                <path d="M433.373 188H0.627169C0.460834 188 0.301313 187.934 0.183696 187.816C0.0660795 187.699 0 187.539 0 187.373C0 187.207 0.0660795 187.048 0.183696 186.93C0.301313 186.813 0.460834 186.747 0.627169 186.747H433.373C433.539 186.747 433.699 186.813 433.816 186.93C433.934 187.048 434 187.207 434 187.373C434 187.539 433.934 187.699 433.816 187.816C433.699 187.934 433.539 188 433.373 188Z" fill="#CCCCCC"/>
            </svg>
        </div>
        <div class="empty__welcome">{{ welcomeText }}</div>
        <div class="empty__label">{{ text.label }}</div>
        <app-base-btn v-if="showBtn" class="empty__add-btn"
            :variation="'save'"
            :text="text.btn"
            @action="openModal"
        />
    </div>
</template>

<script>
    import { emptyContentInfo } from "../../../constants/empty";

    export default {
        name: "AppEmptyContent",

        props: {
            contentType: {
                type: String,
                required: true
            },
            showBtn: {
                type: Boolean,
                default: true
            },
            isSmall: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            text() {
                return emptyContentInfo[this.contentType];
            },

            welcomeText() {
                return emptyContentInfo.welcome;
            }
        },

        methods: {
            openModal() {
                this.$emit('openModal');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;

        &__welcome {
            font-weight: 500;
            font-size: 36px;
            line-height: 42px;
            text-align: center;
            color: var(--grey);
        }

        &__image {
            display: flex;
            justify-content: center;
            /*height: 288px;*/
            //We need dynamic margin here
            margin-bottom: 2.5vw;

            /*img {*/
            /*    max-width: 100%;*/
            /*    object-fit: cover;*/
            /*}*/
        }

        &__label {
            max-width: 415px;
            text-align: center;
            margin-top: 15px;
        }

        &__add-btn {
            margin-top: 30px;
        }
    }
</style>