import i18n from "../../../../src/localization/i18n"
const { t } = i18n.global;

function mapUserStatementsToView(statements) {
    return mapUserStatementEntityToView(statements.user, 'user');
}

function mapUserStatementEntityToView(entity, type) {
    const entityToView = {
        name: entity.name || '',
        endOfPeriod: mapFinancePeriodToView(entity.end_of_period),
        turnover: mapFinancePeriodToView(entity.turnover),
        startOfPeriod: mapFinancePeriodToView(entity.start_of_period),
        weeks: mapFinanceWeeksToView(entity.weeks),
    }

    if (entity.document_id) {
        entityToView.documentId = entity.document_id;
    }

    if (type === 'services') {
        entityToView.name = t('finance.services');
    }

    if (entity.premises) {
        entityToView.premises = entity.premises.map((premise) => mapUserStatementEntityToView(premise, 'premises'));
    }

    if (entity.services) {
        entityToView.services = mapUserStatementEntityToView(entity.services, 'services')
    }

    if (type === 'user') {
        entityToView.children = entityToView.weeks.concat(entityToView.premises).concat(entityToView.services);
    } else {
        entityToView.children = entityToView.weeks;
    }

    return entityToView;
}

function mapEnterpriseStatementsToView(statements) {
    return mapEnterpriseStatementEntityToView(statements.enterprise, 'enterprise');
}

function mapEnterpriseStatementEntityToView(entity, type) {
    const entityToView = {
        name: entity.name || '',
        endOfPeriod: mapFinancePeriodToView(entity.end_of_period),
        turnover: mapFinancePeriodToView(entity.turnover),
        startOfPeriod: mapFinancePeriodToView(entity.start_of_period),
        weeks: mapFinanceWeeksToView(entity.weeks),
    }

    if (type === 'enterprise') {
        entityToView.name = t('finance.statements.type.enterprise');
    }

    if (entity.building_groups) {
        entityToView.buildingGroups = entity.building_groups.map((group) => mapEnterpriseStatementEntityToView(group, 'group'));
    }

    if (entity.free_buildings) {
        entityToView.freeBuildings = entity.free_buildings.map((building) => mapEnterpriseStatementEntityToView(building, 'freeBuildings'));
    }

    if (entity.buildings) {
        entityToView.buildings = entity.buildings.map((building) => mapEnterpriseStatementEntityToView(building, 'buildings'));
    }

    if (type === 'enterprise') {
        entityToView.children = entityToView.weeks.concat(entityToView.buildingGroups).concat(entityToView.freeBuildings);
    } else if (type === 'group') {
        entityToView.children = entityToView.weeks.concat(entityToView.buildings);
    } else {
        entityToView.children = entityToView.weeks;
    }

    return entityToView;
}

function mapFinancePeriodToView(period) {
    return {
        debit: period.debit,
        credit: period.credit
    }
}

function mapFinanceWeeksToView(weeks) {
    return weeks.map((week) => {
        return {
            name: `${t('finance.statements.table.turn')} ${week.name}`,
            endOfPeriod: mapFinancePeriodToView(week.end_of_period),
            turnover: mapFinancePeriodToView(week.turnover),
            startOfPeriod: mapFinancePeriodToView(week.start_of_period),
        }
    })
}

export {
    mapUserStatementsToView,
    mapEnterpriseStatementsToView
}