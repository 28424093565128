import { showToast } from "../../helpers/toasts";

const usersToastsMixin = {
    methods: {
        showDeleteUserToast(name) {
            const deleteToastTitle = this.$t('toasts.users.delete.title');

            const deleteToastDetails = `
                    ${this.$t(`toasts.users.delete.details1`)}
                    ${this.$t(`toasts.users.delete.details2`)}`
            ;

            showToast('success',
                deleteToastTitle,
                deleteToastDetails
            );
        },

        showCreateUserToast() {
            showToast('success',
                this.$t('toasts.success'),
                this.$t('toasts.users.create.details')
            );
        },

        showCopyCodeToClipboardToast() {
            showToast('success',
                this.$t('toasts.copy'),
                this.$t('toasts.users.copy.code'),
            );
        },

        showCopyLinkToClipboardToast() {
            showToast('success',
                this.$t('toasts.copy'),
                this.$t('toasts.users.copy.link'),
            );
        }
    }
}

export {
    usersToastsMixin
}