<template>
    <app-modal-wrapper>
        <div class="modal-restructuring-debt">
            <form @submit.prevent="doActionRestructuring" class="debt-form">
                <div class="debt-form__head">
                    <div class="debt-form__title">
                        <h4>{{ $t('finance.restructuring.title') }}</h4>
                    </div>
                    <div class="debt-form__info">
                        <div class="debt-form__details">
                            <div class="app__label">
                                {{ $t('finance.restructuring.debt') }}
                            </div>
                            <div class="debt-form__debt">
                                {{ formattedDebt }}
                            </div>
                        </div>
                        <div class="debt-form__details">
                            <div class="app__label">
                                {{ $t('finance.restructuring.client') }}
                            </div>
                            <div class="debt-form__client">
                                {{ client.fullName }}
                            </div>
                            <div class="debt-form__serial-number">
                                {{ client.serialNumber }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="debt-form__distribution">
                    <div class="debt-form__period">
                        <app-base-select
                            :label="$t('finance.restructuring.period')"
                            :selected-option="selectedPeriod"
                            :options="periods"
                            @select="selectPeriod"
                        />
                    </div>
                    <div class="debt-form__part">
                        <div class="app__label">
                            {{ $t('finance.restructuring.payment') }}
                        </div>
                        <div class="debt-form__part-month">
                            {{ formattedMonthPart }}
                        </div>
                    </div>
                </div>
                <div class="debt-form__control">
                    <app-base-btn
                        :size="'fixed'"
                        :variation="'cancel'"
                        :text="$t('common.buttons.cancel')"
                        @action="closeModalRestructuringDebt"
                    />
                    <app-base-btn
                        :type="'submit'"
                        :size="'fixed'"
                        :variation="'save'"
                        :text="$t('common.buttons.restructure')"
                    />
                </div>
            </form>
        </div>
    </app-modal-wrapper>
</template>

<script>
    import { formatCurrencyValue, getPeriodsRestructuringDebt } from "../../../helpers/finance";

    export default {
        name: "ModalRestructuringDebt",

        props: {
            selectedClient: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                client: {
                    debt: '',
                    fullName: '',
                    serialNumber: ''
                },

                selectedPeriod: null
            }
        },

        created() {
            this.mapClient();
        },

        computed: {
            periods() {
                return getPeriodsRestructuringDebt();
            },

            formattedDebt() {
                const { balance, currency } = formatCurrencyValue(this.client.debt);

                return `${balance.substring(1)} ${currency}`;
            },

            formattedMonthPart() {
                const { balance, currency } = formatCurrencyValue(this.client.debt/this.selectedPeriod?.id);

                return `${balance.substring(1)} ${currency}`;
            }
        },

        methods: {
            mapClient() {
                this.client = { ...this.selectedClient };
                this.selectedPeriod = this.periods[5];
            },

            doActionRestructuring() {
                this.closeModalRestructuringDebt();
            },

            selectPeriod(period) {
                this.selectedPeriod = period;
            },

            closeModalRestructuringDebt() {
                this.closeModal('modalRestructuringDebt');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-restructuring-debt {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        max-width: 1118px;
        width: 100%;
    }

    .debt-form {
        font-size: 16px;

        h4 {
            margin-bottom: 30px;
        }

        &__control {
            display: flex;
            justify-content: flex-end;
            margin-top: 45px;

            button:first-child {
                margin-right: 30px;
            }
        }

        &__head, &__info {
            display: flex;
        }

        &__head {
            margin-bottom: 30px;
        }

        &__title, &__info, &__details,
        &__period, &__part {
            flex-basis: 50%;
        }

        &__details {
            display: flex;
            flex-direction: column;
        }

        &__title, &__period {
            margin-right: 30px;
        }

        &__debt, &__client {
            font-weight: 500;
        }

        &__distribution {
            display: flex;
            padding-top: 30px;
            border-top: 1px solid var(--app-border);
        }

        &__part-month {
            height: 48px;
            display: flex;
            align-items: center;
        }
    }
</style>