import { showToast } from "../../helpers/toasts";

const adminToastsMixin = {
    methods: {
        showDeleteToast(username) {
            const deleteToastTitle = this.$t('toasts.admins.delete.title');

            const deleteToastDetails = `
                    ${this.$t(`toasts.admins.delete.details1`)}
                    ${username}
                    ${this.$t(`toasts.admins.delete.details2`)}`
            ;

            showToast('success',
                deleteToastTitle,
                deleteToastDetails
            );
        },

        showAdminTransferToast(username) {
            const transferRightsTitle = this.$t('toasts.admins.transferRights.title');

            const transferRightsDetails = `
                    ${this.$t(`toasts.admins.transferRights.details1`)}
                    ${username}
                    ${this.$t(`toasts.admins.transferRights.details2`)}`
            ;

            showToast('success',
                transferRightsTitle,
                transferRightsDetails
            );
        },

        showCreateToast() {
            showToast('success',
                this.$t('toasts.success'),
                this.$t('toasts.admins.create.details')
            );
        }
    }
}

export {
    adminToastsMixin
}