<template>
    <app-modal-wrapper>
        <div class="modal-admin">
            <form @submit.prevent="doActionAdmin" class="admin-form">
                <h4>{{ title }}</h4>
                <div class="admin-form__info">
                    <div class="admin-form__details">
                        <div class="admin-form__login">
                            <app-base-input
                                v-model="admin.username"
                                :is-disabled="isUpdate"
                                :label="$t('admins.modal.username.label')"
                                :place-holder="$t('admins.modal.username.ph')"
                                :error-message="errors.username"
                            />
                        </div>
                        <div class="admin-form__name">
                            <app-base-input
                                v-model="admin.name"
                                :label="$t('admins.modal.name.label')"
                                :place-holder="$t('admins.modal.name.ph')"
                                :required="false"
                            />
                        </div>
                    </div>
                    <div class="admin-form__details">
                        <div class="admin-form__password">
                            <div class="admin-form__password-label"
                                :class="{
                                    'admin-form__password-label--is-disabled': isUpdate
                                }"
                            >
                                <app-text-btn
                                    :text="$t('admins.modal.password.generate')"
                                    @action="generateRandomPassword"
                                />
                                <div>{{ $t('admins.modal.password.label') }}</div>
                            </div>
                            <app-base-input
                                v-model="admin.tempPass"
                                :is-disabled="isUpdate"
                                :type="'password'"
                                :error-message="errors.tempPass"
                            />
                        </div>
                        <div class="admin-form__surname">
                            <app-base-input
                                v-model="admin.surname"
                                :label="$t('admins.modal.surname.label')"
                                :place-holder="$t('admins.modal.surname.ph')"
                                :required="false"
                            />
                        </div>
                    </div>
                </div>
                <div class="admin-form__contacts">
                    <div class="admin-form__details">
                        <div class="admin-form__email">
                            <app-base-input
                                v-model="admin.email"
                                :label="$t('admins.modal.email.label')"
                                :place-holder="$t('admins.modal.email.ph')"
                                :required="false"
                            />
                        </div>
                        <div class="admin-form__phone">
                            <app-base-input
                                v-model="admin.phone.number"
                                :label="$t('admins.modal.phone.label')"
                                :place-holder="$t('admins.modal.phone.ph')"
                                :required="false"
                                :error-message="errors.phone"
                            />
                            <div class="admin-form__phone-code">
                                <app-base-select
                                    :is-searchable="true"
                                    :selected-option="selectedCode"
                                    :options="optionsCodes"
                                    :type="'code'"
                                    @select="selectCode"
                                />
                            </div>
                        </div>
                        <div class="admin-form__company">
                            <app-base-input
                                v-model="admin.company"
                                :label="$t('admins.modal.company.label')"
                                :place-holder="$t('admins.modal.company.ph')"
                                :required="false"
                            />
                        </div>
                        <div class="admin-form__position">
                            <app-base-input
                                v-model="admin.position"
                                :label="$t('admins.modal.position.label')"
                                :place-holder="$t('admins.modal.position.ph')"
                                :required="false"
                            />
                        </div>
                    </div>
                    <div class="admin-form__details">
                        <div class="admin-form__avatar">
                            <app-upload-cropper
                                :label="$t('upload.label.image')"
                                :type="'logo'"
                                :height="'image'"
                                :accept-types="acceptTypes"
                                :url="admin.avatar"
                                @update="updateAdminAvatar"
                            />
                        </div>
                    </div>
                </div>
                <div class="admin-form__access">
                    <admin-access
                        :access-level="admin.accessLevel"
                        @update="updateAccessLevel"
                    />
                </div>
                <div class="admin-form__control">
                    <app-base-btn
                        :size="'fixed'"
                        :variation="'cancel'"
                        :text="$t('common.buttons.cancel')"
                        @action="closeModalAdmin"
                    />
                    <app-base-btn
                        :type="'submit'"
                        :size="'fixed'"
                        :variation="'save'"
                        :text="saveBtnText"
                    />
                </div>
            </form>
        </div>
    </app-modal-wrapper>
</template>

<script>
    import cloneDeep from "lodash.clonedeep";
    import AppUploadCropper from "../../common/local/upload/AppUploadCropper";
    import AdminAccess from "../../admins/AdminAccess";
    import { djinAPI } from "../../../config/api";
    import { getUniqueCodes } from "../../../constants/countries";
    import { generatePassword } from "../../../helpers/generatePassword";
    import { adminPhotoTypes } from "../../../constants/uploadFileTypes";
    import { mapAdminToSend } from "../../../api/mappers/admins";
    import { validateAdmin } from "../../../validations/admin";
    import { adminToastsMixin } from "../../../mixins/toasts/admins";

    export default {
        name: "ModalAdmin",

        components: {
            AdminAccess,
            AppUploadCropper
        },

        props: {
            selectedAdmin: {
                type: Object
            }
        },

        mixins: [
            adminToastsMixin
        ],

        data() {
            return {
                admin: {
                    username: '',
                    tempPass: '',
                    name: '',
                    surname: '',
                    email: '',
                    phone: {
                        code: '',
                        number: ''
                    },
                    company: '',
                    position: '',
                    avatar: '',
                    accessLevel: 16777215
                },

                avatar: {
                    file: '',
                    isChanged: false
                },

                errors: {
                    username: '',
                    tempPass: '',
                    phone: ''
                },

                selectedCode: null
            }
        },

        created() {
            this.checkUpdateAdmin();
        },

        computed: {
            isUpdate() {
                return !!this.selectedAdmin;
            },

            title() {
                return this.isUpdate
                    ? this.$t('admins.modal.title.edit')
                    : this.$t('admins.modal.title.create');
            },

            saveBtnText() {
                return this.isUpdate
                    ? this.$t('common.buttons.save')
                    : this.$t('common.buttons.send');
            },

            optionsCodes() {
                return getUniqueCodes();
            },

            acceptTypes() {
                return adminPhotoTypes;
            }
        },

        methods: {
            checkUpdateAdmin() {
                if (this.isUpdate) {
                    this.admin = cloneDeep(this.selectedAdmin);
                }

                this.initAdmin();
            },

            initAdmin() {
                if (this.isUpdate) {
                    this.selectedCode = this.optionsCodes.find((code) => code.name === this.admin.phone.code);
                } else {
                    const defaultCode = this.optionsCodes[0];

                    this.selectedCode = defaultCode;
                    this.updateAdminPhoneCode(defaultCode.name);
                }
            },

            selectCode(code) {
                this.selectedCode = code;
                this.updateAdminPhoneCode(code.name);
            },

            updateAdminPhoneCode(code) {
                this.admin.phone.code = code;
            },

            updateAdminAvatar(image) {
                if (!this.isUpdate) {
                    this.admin.avatar = image.file;
                } else {
                    this.avatar.file = image.file;
                    this.avatar.isChanged = image.isChanged;
                }
            },

            updateAccessLevel(accessLevel) {
                this.admin.accessLevel = accessLevel;
            },

            doActionAdmin() {
                this.clearAdminErrors();
                const { isValid, errors } = validateAdmin(this.admin, this.isUpdate);
                this.errors = errors;

                if (isValid) {
                    if (!this.isUpdate) {
                        this.createAdmin();
                    } else {
                        this.changeAdmin();
                    }
                }
            },

            clearAdminErrors() {
                for (let key in this.errors) {
                    this.errors[key] = '';
                }
            },

            createAdmin() {
                const adminToSend = mapAdminToSend(this.admin);

                djinAPI.post('/admins', adminToSend)
                    .then((result) => {
                        const id = result.data._id;
                        this.showCreateToast();
                        this.uploadAvatar(id);
                    })
            },

            changeAdmin() {
                const id = this.admin.id
                const adminToSend = mapAdminToSend(this.admin);

                djinAPI.patch(`/admins/${id}`, adminToSend)
                    .then(() => {
                        this.uploadAvatar(id);
                    })
            },

            uploadAvatar(id) {
                if (!this.isUpdate) {
                    if (this.admin.avatar) {
                        this.sendAvatar(id, this.admin.avatar);
                    } else {
                        this.updateAdmins();
                    }
                } else {
                    if (this.admin.avatar) {
                        //Avatar have already been uploaded
                        if (this.avatar.isChanged) {
                            //Avatar has been changed
                            if (this.avatar.file) {
                                this.sendAvatar(id, this.avatar.file);
                            } else {
                                this.deleteAvatar(id);
                            }
                        } else {
                            this.updateAdmins();
                        }
                    } else {
                        if (this.avatar.file) {
                            this.sendAvatar(id, this.avatar.file);
                        } else {
                            this.updateAdmins();
                        }
                    }
                }
            },

            deleteAvatar(id) {
                djinAPI.delete(`/admins/${id}/avatar`)
                    .then(() => {
                        this.updateAdmins();
                    })
            },

            sendAvatar(id, avatar) {
                const data = new FormData();
                data.append('avatar', avatar);

                djinAPI.post(`/admins/${id}/avatar`, data, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    })
                    .then(() => {
                        this.updateAdmins();
                    })
            },

            updateAdmins() {
                this.closeModalAdmin();
                this.emitter.emit('updateAdmins');
            },

            generateRandomPassword() {
                this.admin.tempPass = generatePassword();
            },

            closeModalAdmin() {
                this.closeModal('modalAdmin');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-admin {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        max-width: 1118px;
        width: 100%;
        max-height: 80vh;
        overflow-y: auto;
    }

    .admin-form {
        h4 {
            margin-bottom: 30px;
        }

        &__info {
            display: flex;
            border-bottom: 1px solid var(--app-border);
            margin-bottom: 30px;
        }

        &__contacts {
            display: flex;
            border-bottom: 1px solid var(--app-border);
            padding-bottom: 30px;
            margin-bottom: 30px;
        }

        &__details {
            flex-basis: 50%;

            &:first-child {
                margin-right: 30px;
            }
        }

        &__login, &__name, &__password, &__surname {
            margin-bottom: 30px;
        }

        &__email, &__phone, &__company {
            margin-bottom: 15px;
        }

        &__avatar {
            height: 100%;
        }

        &__password-label {
            font-size: 14px;
            line-height: 17px;
            color: var(--app-base-secondary);
            margin-bottom: 9px;
            display: flex;
            align-items: center;

            button {
                margin-right: 4px;
            }

            div {
                white-space: nowrap;
            }

            &--is-disabled {
                pointer-events: none;
            }
        }

        &__control {
            display: flex;
            justify-content: flex-end;
            margin-top: 45px;

            button:first-child {
                margin-right: 30px;
            }
        }

        &__phone {
            position: relative;
        }

        &__phone-code {
            position: absolute;
            top: 30px;
            left: 4px;
        }
    }
</style>

<style lang="scss">
    .admin-form__password-label {
        button {
            span {
                font-size: 14px !important;
            }
        }
    }

    .admin-form__phone {
        .field__entry {
            input {
                padding-left: 110px !important;
            }
        }
    }
</style>