<template>
    <div class="invoice-state"
         :style="{
            'backgroundColor': invoiceState.background
         }"
    >
        <div v-if="showAdditionalInfo" class="invoice-state__icon">
            <svg v-if="isUnderPayment" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.41669 8.74935H7.58335V9.91602H6.41669V8.74935ZM6.41669 4.08268H7.58335V7.58268H6.41669V4.08268ZM6.99419 1.16602C3.77419 1.16602 1.16669 3.77935 1.16669 6.99935C1.16669 10.2193 3.77419 12.8327 6.99419 12.8327C10.22 12.8327 12.8334 10.2193 12.8334 6.99935C12.8334 3.77935 10.22 1.16602 6.99419 1.16602ZM7.00002 11.666C4.42169 11.666 2.33335 9.57768 2.33335 6.99935C2.33335 4.42102 4.42169 2.33268 7.00002 2.33268C9.57835 2.33268 11.6667 4.42102 11.6667 6.99935C11.6667 9.57768 9.57835 11.666 7.00002 11.666Z" fill="var(--app-base-primary)"/>
            </svg>
            <svg v-if="isOverPayment" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.00002 11.666C4.42752 11.666 2.33335 9.57185 2.33335 6.99935C2.33335 4.42685 4.42752 2.33268 7.00002 2.33268C9.57252 2.33268 11.6667 4.42685 11.6667 6.99935C11.6667 9.57185 9.57252 11.666 7.00002 11.666ZM7.00002 12.8327C10.22 12.8327 12.8334 10.2193 12.8334 6.99935C12.8334 3.77935 10.22 1.16602 7.00002 1.16602C3.78002 1.16602 1.16669 3.77935 1.16669 6.99935C1.16669 10.2193 3.78002 12.8327 7.00002 12.8327ZM6.41669 6.99935V9.33268H7.58335V6.99935H9.33335L7.00002 4.66602L4.66669 6.99935H6.41669Z" fill="var(--app-base-primary)"/>
            </svg>
        </div>
        <div>
            <span v-if="showAdditionalInfo" class="invoice-state__total">
                {{ formattedSubPrice }}
            </span>
            <span v-else class="invoice-state__name">
                {{ invoiceState.name }}
            </span>
        </div>
    </div>
</template>

<script>
    import { invoiceStates } from "../../../constants/finance";
    import { formatCurrencyValue } from "../../../helpers/finance";

    export default {
        name: "AppInvoiceState",

        props: {
            state: {
                type: Number,
                required: true
            },
            subPrice: {
                type: Number,
                default: 0
            },
            hideAdvanced: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            isUnderPayment() {
                return this.state === 4;
            },

            isOverPayment() {
                return this.state === 3;
            },

            invoiceState() {
                return invoiceStates.find((state) => state.id === this.state);
            },

            formattedSubPrice() {
                const { balance, currency } = formatCurrencyValue(this.subPrice);

                return `${(balance.substring(0, balance.length - 3))} ${currency}`;
            },

            showAdditionalInfo() {
                return !this.hideAdvanced && (this.isUnderPayment || this.isOverPayment);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .invoice-state {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        border-radius: 100px;
        font-size: 12px;
        line-height: 14px;
        padding: 5px 15px;

        &__icon {
            height: 14px;
            width: 14px;
            margin-right: 10px;
        }

        &__total, &__name {
            white-space: nowrap;
        }
    }
</style>