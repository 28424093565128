<template>
    <div class="settings" :style="{ 'maxHeight': maxHeight }">
        <div class="settings__individual">
            <div class="settings__column1">
                <div class="settings__info">
                    <div class="settings__title">
                        {{ $t('personalization.individual.title') }}
                    </div>
                    <div class="settings__details">
                        {{ $t('personalization.individual.details') }}
                    </div>
                </div>
            </div>
            <div class="settings__column2">
<!--                TODO: V_FOR!!!!!!!-->
                <div class="settings__colors">
                    <div class="settings__colors-row">
                        <div class="settings__color-picker">
                            <app-color-picker
                                :color-key="'--app-menu-background'"
                                :label="$t('personalization.label.app-menu-background')"
                                :current-color="colors['--app-menu-background']"
                                @update="updateColorValue"
                            />
                        </div>
                        <div class="settings__color-picker">
                            <app-color-picker
                                :color-key="'--app-table-background'"
                                :label="$t('personalization.label.app-table-background')"
                                :current-color="colors['--app-table-background']"
                                @update="updateColorValue"
                            />
                        </div>
                    </div>
                    <div class="settings__colors-row">
                        <div class="settings__color-picker">
                            <app-color-picker
                                :color-key="'--app-base-primary'"
                                :label="$t('personalization.label.app-base-primary')"
                                :current-color="colors['--app-base-primary']"
                                @update="updateColorValue"
                            />
                        </div>
                        <div class="settings__color-picker">
                            <app-color-picker
                                :color-key="'--app-base-secondary'"
                                :label="$t('personalization.label.app-base-secondary')"
                                :current-color="colors['--app-base-secondary']"
                                @update="updateColorValue"
                            />
                        </div>
                    </div>
                    <div class="settings__colors-row">
                        <div class="settings__color-picker">
                            <app-color-picker
                                :color-key="'--app-border'"
                                :label="$t('personalization.label.app-border')"
                                :current-color="colors['--app-border']"
                                @update="updateColorValue"
                            />
                        </div>
                        <div class="settings__color-picker">
                            <app-color-picker
                                :color-key="'--app-btn-secondary'"
                                :label="$t('personalization.label.app-btn-secondary')"
                                :current-color="colors['--app-btn-secondary']"
                                @update="updateColorValue"
                            />
                        </div>
                    </div>
                    <div class="settings__colors-row">
                        <div class="settings__color-picker">
                            <app-color-picker
                                :color-key="'--brand-main'"
                                :label="$t('personalization.label.brand-main')"
                                :current-color="colors['--brand-main']"
                                @update="updateColorValue"
                            />
                        </div>
                        <div class="settings__color-picker">
                            <app-color-picker
                                :color-key="'--brand-iris'"
                                :label="$t('personalization.label.brand-iris')"
                                :current-color="colors['--brand-iris']"
                                @update="updateColorValue"
                            />
                        </div>
                    </div>
                    <div class="settings__colors-row">
                        <div class="settings__color-picker">
                            <app-color-picker
                                :color-key="'--brand-purple'"
                                :label="$t('personalization.label.brand-purple')"
                                :current-color="colors['--brand-purple']"
                                @update="updateColorValue"
                            />
                        </div>
                        <div class="settings__color-picker">
                            <app-color-picker
                                :color-key="'--brand-pink'"
                                :label="$t('personalization.label.brand-pink')"
                                :current-color="colors['--brand-pink']"
                                @update="updateColorValue"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="settings__column3">
                <individual-settings-template
                    :icons="appIcons"
                    :color-menu-background="colors['--app-menu-background']"
                    :color-table-background="colors['--app-table-background']"
                    :color-base-primary="colors['--app-base-primary']"
                    :color-base-secondary="colors['--app-base-secondary']"
                    :color-border="colors['--app-border']"
                    :color-btn-secondary="colors['--app-btn-secondary']"
                    :color-brand-main="colors['--brand-main']"
                    :color-brand-iris="colors['--brand-iris']"
                    :color-brand-purple="colors['--brand-purple']"
                    :color-brand-pink="colors['--brand-pink']"
                />
            </div>
        </div>
        <div class="settings__advanced">
            <div class="settings__column1">
                <div class="settings__info">
                    <div class="settings__title">
                        {{ $t('personalization.advanced.title') }}
                    </div>
                    <div class="settings__details">
                        {{ $t('personalization.advanced.details') }}
                    </div>
                </div>
            </div>
            <div class="settings__column2">
                <!--                TODO: V_FOR!!!!!!!-->
                <div class="settings__colors">
                    <div class="settings__colors-row">
                        <div class="settings__color-picker">
                            <app-color-picker
                                :color-key="'--system-red'"
                                :label="$t('personalization.label.system-red')"
                                :current-color="colors['--system-red']"
                                @update="updateColorValue"
                            />
                        </div>
                        <div class="settings__color-picker">
                            <app-color-picker
                                :color-key="'--system-orange'"
                                :label="$t('personalization.label.system-orange')"
                                :current-color="colors['--system-orange']"
                                @update="updateColorValue"
                            />
                        </div>
                    </div>
                    <div class="settings__colors-row">
                        <div class="settings__color-picker">
                            <app-color-picker
                                :color-key="'--system-yellow'"
                                :label="$t('personalization.label.system-yellow')"
                                :current-color="colors['--system-yellow']"
                                @update="updateColorValue"
                            />
                        </div>
                        <div class="settings__color-picker">
                            <app-color-picker
                                :color-key="'--system-green'"
                                :label="$t('personalization.label.system-green')"
                                :current-color="colors['--system-green']"
                                @update="updateColorValue"
                            />
                        </div>
                    </div>
                    <div class="settings__colors-row">
                        <div class="settings__color-picker">
                            <app-color-picker
                                :color-key="'--system-blue'"
                                :label="$t('personalization.label.system-blue')"
                                :current-color="colors['--system-blue']"
                                @update="updateColorValue"
                            />
                        </div>
                        <div class="settings__color-picker">
                            <app-color-picker
                                :color-key="'--system-iris'"
                                :label="$t('personalization.label.system-iris')"
                                :current-color="colors['--system-iris']"
                                @update="updateColorValue"
                            />
                        </div>
                    </div>
                    <div class="settings__colors-row">
                        <div class="settings__color-picker">
                            <app-color-picker
                                :color-key="'--system-purple'"
                                :label="$t('personalization.label.system-purple')"
                                :current-color="colors['--system-purple']"
                                @update="updateColorValue"
                            />
                        </div>
                        <div class="settings__color-picker">
                            <app-color-picker
                                :color-key="'--system-pink'"
                                :label="$t('personalization.label.system-pink')"
                                :current-color="colors['--system-pink']"
                                @update="updateColorValue"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="settings__column3">
                <advanced-settings-template
                    :color-brand-main="colors['--brand-main']"
                    :color-system-red="colors['--system-red']"
                    :color-system-orange="colors['--system-orange']"
                    :color-system-yellow="colors['--system-yellow']"
                    :color-system-green="colors['--system-green']"
                    :color-system-blue="colors['--system-blue']"
                    :color-system-iris="colors['--system-iris']"
                    :color-system-purple="colors['--system-purple']"
                    :color-system-pink="colors['--system-pink']"
                />
            </div>
        </div>
        <div class="settings__view">
            <div class="settings__column1">
                <div class="settings__info">
                    <div class="settings__title">
                        {{ $t('personalization.view.title') }}
                    </div>
                    <div class="settings__details">
                        {{ $t('personalization.view.details') }}
                    </div>
                </div>
            </div>
            <div class="settings__column2">
                <!--                TODO: V_FOR!!!!!!!-->
                <div>
                    <div class="settings__icons-row">
                        <div class="settings__icons-column">
                            <view-icon-upload
                                    :id="items[0].id"
                                    :label="items[0].name"
                                    :link="items[0].link"
                                    :default-link="items[0].defaultLink"
                                    :is-default="items[0].isDefault"
                                    :is-logo="true"
                                    @update="updateUploadIcon"
                                    @clear="clearUploadIcon"
                            />
                        </div>
                        <div class="settings__icons-column">
                            <div class="settings__icons-row-2">
                                <div class="settings__icon-item">
                                    <view-icon-upload
                                            :id="items[1].id"
                                            :label="items[1].name"
                                            :link="items[1].link"
                                            :default-link="items[1].defaultLink"
                                            :is-default="items[1].isDefault"
                                            @update="updateUploadIcon"
                                            @clear="clearUploadIcon"
                                    />
                                </div>
                                <div class="settings__icon-item">
                                    <view-icon-upload
                                            :id="items[2].id"
                                            :label="items[2].name"
                                            :link="items[2].link"
                                            :default-link="items[2].defaultLink"
                                            :is-default="items[2].isDefault"
                                            @update="updateUploadIcon"
                                            @clear="clearUploadIcon"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="settings__icons-row">
                        <div class="settings__icons-column">
                            <div class="settings__icons-row-2">
                                <div class="settings__icon-item">
                                    <view-icon-upload
                                            :id="items[3].id"
                                            :label="items[3].name"
                                            :link="items[3].link"
                                            :default-link="items[3].defaultLink"
                                            :is-default="items[3].isDefault"
                                            @update="updateUploadIcon"
                                            @clear="clearUploadIcon"
                                    />
                                </div>
                                <div class="settings__icon-item">
                                    <view-icon-upload
                                            :id="items[4].id"
                                            :label="items[4].name"
                                            :link="items[4].link"
                                            :default-link="items[4].defaultLink"
                                            :is-default="items[4].isDefault"
                                            @update="updateUploadIcon"
                                            @clear="clearUploadIcon"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="settings__icons-column">
                            <div class="settings__icons-row-2">
                                <div class="settings__icon-item">
                                    <view-icon-upload
                                            :id="items[5].id"
                                            :label="items[5].name"
                                            :link="items[5].link"
                                            :default-link="items[5].defaultLink"
                                            :is-default="items[5].isDefault"
                                            @update="updateUploadIcon"
                                            @clear="clearUploadIcon"
                                    />
                                </div>
                                <div class="settings__icon-item">
                                    <view-icon-upload
                                            :id="items[6].id"
                                            :label="items[6].name"
                                            :link="items[6].link"
                                            :default-link="items[6].defaultLink"
                                            :is-default="items[6].isDefault"
                                            @update="updateUploadIcon"
                                            @clear="clearUploadIcon"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="settings__icons-row">
                        <div class="settings__icons-column">
                            <div class="settings__icons-row-2">
                                <div class="settings__icon-item">
                                    <view-icon-upload
                                            :id="items[7].id"
                                            :label="items[7].name"
                                            :link="items[7].link"
                                            :default-link="items[7].defaultLink"
                                            :is-default="items[7].isDefault"
                                            @update="updateUploadIcon"
                                            @clear="clearUploadIcon"
                                    />
                                </div>
                                <div class="settings__icon-item">
                                    <view-icon-upload
                                            :id="items[8].id"
                                            :label="items[8].name"
                                            :link="items[8].link"
                                            :default-link="items[8].defaultLink"
                                            :is-default="items[8].isDefault"
                                            @update="updateUploadIcon"
                                            @clear="clearUploadIcon"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="settings__icons-column">
                            <div class="settings__icons-row-2">
                                <div class="settings__icon-item">
                                    <view-icon-upload
                                            :id="items[9].id"
                                            :label="items[9].name"
                                            :link="items[9].link"
                                            :default-link="items[9].defaultLink"
                                            :is-default="items[9].isDefault"
                                            @update="updateUploadIcon"
                                            @clear="clearUploadIcon"
                                    />
                                </div>
                                <div class="settings__icon-item">
                                    <view-icon-upload
                                            :id="items[10].id"
                                            :label="items[10].name"
                                            :link="items[10].link"
                                            :default-link="items[10].defaultLink"
                                            :is-default="items[10].isDefault"
                                            @update="updateUploadIcon"
                                            @clear="clearUploadIcon"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="settings__icons-row">
                        <div class="settings__icons-column">
                            <div class="settings__icons-row-2">
                                <div class="settings__icon-item">
                                    <view-icon-upload
                                            :id="items[11].id"
                                            :label="items[11].name"
                                            :link="items[11].link"
                                            :default-link="items[11].defaultLink"
                                            :is-default="items[11].isDefault"
                                            @update="updateUploadIcon"
                                            @clear="clearUploadIcon"
                                    />
                                </div>
                                <div class="settings__icon-item">
                                    <view-icon-upload
                                            :id="items[12].id"
                                            :label="items[12].name"
                                            :link="items[12].link"
                                            :default-link="items[12].defaultLink"
                                            :is-default="items[12].isDefault"
                                            @update="updateUploadIcon"
                                            @clear="clearUploadIcon"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="settings__icons-column">
                            <div class="settings__icons-row-2">
                                <div class="settings__icon-item">
                                    <view-icon-upload
                                            :id="items[13].id"
                                            :label="items[13].name"
                                            :link="items[13].link"
                                            :default-link="items[13].defaultLink"
                                            :is-default="items[13].isDefault"
                                            @update="updateUploadIcon"
                                            @clear="clearUploadIcon"
                                    />
                                </div>
                                <div class="settings__icon-item">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="settings__column3">
                <view-settings-template
                    :logo="items[0].link"
                    :items="items.filter((item) => item.id !== 'logo')"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import cloneDeep from "lodash.clonedeep";
    import AppColorPicker from "../common/local/AppColorPicker";
    import IndividualSettingsTemplate from "./IndividualSettingsTemplate";
    import AdvancedSettingsTemplate from "./AdvancedSettingsTemplate";
    import ViewIconUpload from "./view/ViewIconUpload";
    import ViewSettingsTemplate from "./ViewSettingsTemplate";

    export default {
        name: "PersonalizationSettings",

        components: {
            ViewSettingsTemplate,
            ViewIconUpload,
            AdvancedSettingsTemplate,
            IndividualSettingsTemplate,
            AppColorPicker
        },

        props: {
            maxHeight: {
                type: String,
                required: true
            },
            appIcons: {
                type: Array,
                required: true
            },
            appColors: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                colors: null,
                items: []
            }
        },


        created() {
            this.mapAppColors();
            this.mapAppItems();
        },

        methods: {
            mapAppColors() {
                this.colors = { ...this.appColors };
            },

            mapAppItems() {
                this.items = cloneDeep(this.appIcons);
            },

            updateColorValue({ value, key }) {
                this.colors[key] = value;
                this.$emit('updateColors', this.colors);
            },

            updateUploadIcon({ id, url, file }) {
                const item = this.items.find((item) => item.id === id);
                item.link = url;
                item.file = file;
                this.$emit('updateIcons', this.items);
            },

            clearUploadIcon(id) {
                const item = this.items.find((item) => item.id === id);
                item.link = item.defaultLink;
                item.file = '';
                this.$emit('updateIcons', this.items);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .settings {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-y: auto;

        &__individual, &__advanced, &__view {
            padding: 30px 15px;
            border-bottom: 1px solid var(--app-border);
            align-items: flex-start;
            display: flex;
        }

        &__info {
            display: flex;
            flex-direction: column;
        }

        &__title {
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 15px;
        }

        &__details {
            font-size: 16px;
            line-height: 19px;
        }

        //<------Adaptive block for personalization with media------>
        &__column1 {
            width: 33.333%;
            padding: 0px 15px;
        }

        &__column2 {
            width: 33.333%;
            padding: 0px 15px;
        }

        &__column3 {
            width: 33.333%;
            padding: 0px 15px;
        }

        @media screen and (max-width: 1900px) {
            &__column1 {
                width: 25%;
            }

            &__column2 {
                width: 37.5%;
            }

            &__column3 {
                width: 37.5%;
            }
        }

        @media screen and (max-width: 1770px) {
            &__column1 {
                width: 20%;
            }

            &__column2 {
                width: 40%;
            }

            &__column3 {
                width: 40%;
            }
        }

        @media screen and (max-width: 1680px) {
            &__column1 {
                width: 16%;
            }

            &__column2 {
                width: 42%;
            }

            &__column3 {
                width: 42%;
            }
        }
        //<------Adaptive block for personalization with media------>

        &__colors {
            display: flex;
            flex-direction: column;
        }

        &__colors-row {
            display: flex;

            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }

        &__color-picker {
            flex-basis: 50%;

            &:first-child {
                margin-right: 30px;
            }
        }

        &__icons-row {
            display: flex;

            &:not(:last-child) {
                margin-bottom: 30px;
            }
        }

        &__icons-column {
            flex-basis: 50%;

            &:first-child {
                margin-right: 30px;
            }
        }

        &__icons-row-2 {
            display: flex;
        }

        &__icon-logo {
            flex-basis: 50%;

            &:not(:last-child) {
                margin-right: 30px;
            }
        }

        &__icon-logo2 {
            flex-basis: 50%;
            display: flex;
        }

        &__icon-first {
            flex-basis: 50%;

            &:not(:last-child) {
                margin-right: 30px;
            }
        }

        &__icon-item {
            flex-basis: 25%;

            &:not(:last-child) {
                margin-right: 30px;
            }
        }
    }
</style>