<template>
    <div class="finance-invoices">
        <div class="finance-invoices__head">
            <div class="finance-invoices__card">
                <finance-card
                    :type="'payments'"
                    :total="qtyOverpaymentInvoices"
                    :details="$t('finance.cards.invoices.overpayment')"
                    @action="updateStateFilter('3')"
                >
                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25 41.666C15.8125 41.666 8.33332 34.1868 8.33332 24.9993C8.33332 15.8118 15.8125 8.33268 25 8.33268C34.1875 8.33268 41.6667 15.8118 41.6667 24.9993C41.6667 34.1868 34.1875 41.666 25 41.666ZM25 45.8327C36.5 45.8327 45.8333 36.4993 45.8333 24.9993C45.8333 13.4993 36.5 4.16602 25 4.16602C13.5 4.16602 4.16666 13.4993 4.16666 24.9993C4.16666 36.4993 13.5 45.8327 25 45.8327ZM22.9167 24.9993V33.3327H27.0833V24.9993H33.3333L25 16.666L16.6667 24.9993H22.9167Z" fill="var(--brand-main)"/>
                    </svg>
                </finance-card>
            </div>
            <div class="finance-invoices__card">
                <finance-card
                    :type="'payments'"
                    :total="qtyUnderpaymentInvoices"
                    :details="$t('finance.cards.invoices.underpayment')"
                    @action="updateStateFilter('4')"
                >
                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.9167 31.2493H27.0833V35.416H22.9167V31.2493ZM22.9167 14.5827H27.0833V27.0827H22.9167V14.5827ZM24.9792 4.16602C13.4792 4.16602 4.16666 13.4993 4.16666 24.9993C4.16666 36.4993 13.4792 45.8327 24.9792 45.8327C36.5 45.8327 45.8333 36.4993 45.8333 24.9993C45.8333 13.4993 36.5 4.16602 24.9792 4.16602ZM25 41.666C15.7917 41.666 8.33332 34.2077 8.33332 24.9993C8.33332 15.791 15.7917 8.33268 25 8.33268C34.2083 8.33268 41.6667 15.791 41.6667 24.9993C41.6667 34.2077 34.2083 41.666 25 41.666Z" fill="var(--system-orange)"/>
                    </svg>
                </finance-card>
            </div>
            <div class="finance-invoices__card">
                <finance-card
                    :type="'payments'"
                    :total="qtyOverdueInvoices"
                    :details="$t('finance.cards.invoices.overdue')"
                    @action="updateStateFilter('2')"
                >
                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25 8.33268C34.1875 8.33268 41.6667 15.8118 41.6667 24.9993C41.6667 34.1868 34.1875 41.666 25 41.666C15.8125 41.666 8.33335 34.1868 8.33335 24.9993C8.33335 15.8118 15.8125 8.33268 25 8.33268ZM25 4.16602C13.5 4.16602 4.16669 13.4993 4.16669 24.9993C4.16669 36.4993 13.5 45.8327 25 45.8327C36.5 45.8327 45.8334 36.4993 45.8334 24.9993C45.8334 13.4993 36.5 4.16602 25 4.16602ZM27.0834 24.9993V16.666H22.9167V24.9993H16.6667L25 33.3327L33.3334 24.9993H27.0834Z" fill="var(--system-red)"/>
                    </svg>
                </finance-card>
            </div>
        </div>
        <div class="finance-invoices__filters">
            <finance-invoices-filters
                :qty="invoices.length"
                @update="updateQueryParams"
            />
            <app-base-btn
                :size="'padding'"
                :variation="'save'"
                :text="$t('common.buttons.invoice')"
                @action="openModalInvoice"
            />
        </div>
        <div class="finance-invoices__content" ref="financeInvoices">
            <div v-if="showTable">
                <finance-invoices-table
                    :invoices="invoices"
                    :max-height="maxHeightScroll"
                    :search-sub-string="searchSubString"
                />
            </div>
            <div v-if="showEmpty" class="finance-invoices__empty">
                <app-empty-content
                    :is-small="true"
                    :content-type="'invoices'"
                    @open-modal="openModalInvoice"
                />
            </div>
            <div v-if="showNoResults" class="finance-invoices__empty">
                <app-no-results
                    :is-small="true"
                    @clear="clearQueryParams"
                />
            </div>
            <div v-if="isLoaded" class="finance-invoices__loader">
                <app-loader
                    :size="'large'"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import FinanceCard from "../FinanceCard";
    import FinanceInvoicesFilters from "./FinanceInvoicesFilters";
    import FinanceInvoicesTable from "./FinanceInvoicesTable";
    import { maxHeightMixin } from "../../../mixins/calcMaxHeight";
    import { djinAPI } from "../../../config/api";
    import { mapInvoicesQtyTypesToView, mapInvoicesToView } from "../../../api/mappers/finance/invoices";
    import { invoicesToastsMixin } from "../../../mixins/toasts/finance/invoices";

    export default {
        name: "FinanceInvoicesControl",

        components: {
            FinanceCard,
            FinanceInvoicesFilters,
            FinanceInvoicesTable
        },

        mixins: [
            maxHeightMixin,
            invoicesToastsMixin
        ],

        data() {
            return {
                isLoaded: false,
                qty: {
                    overpayment: 0,
                    underpayment: 0,
                    overdue: 0
                },
                invoices: [],
                params: null
            }
        },

        created() {
            this.getInvoices();
        },

        mounted() {
            this.subscribeToResize('financeInvoices');
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromResize();
            this.unsubscribeFromEvents();
        },

        computed: {
            showTable() {
                return this.invoices.length && !this.isLoaded;
            },

            showEmpty() {
                return !this.invoices.length && !this.isLoaded && !this.isExistQueryParams;
            },

            showNoResults() {
                return !this.invoices.length && !this.isLoaded && this.isExistQueryParams;
            },

            isExistQueryParams() {
                return this.searchSubString || this.filterSubString || this.timeFilterSubString;
            },

            searchSubString() {
                return this.params?.params?.match ? this.params?.params?.match : '';
            },

            filterSubString() {
                return this.params?.params?.filter ? this.params?.params?.filter : '';
            },

            timeFilterSubString() {
                return this.params?.params?.time_filter ? this.params?.params?.time_filter : '';
            },

            qtyOverpaymentInvoices() {
                return `${this.$t('finance.cards.invoices.qty')} ${this.qty.overpayment}`;
            },

            qtyUnderpaymentInvoices() {
                return `${this.$t('finance.cards.invoices.qty')} ${this.qty.underpayment}`;
            },

            qtyOverdueInvoices() {
                return `${this.$t('finance.cards.invoices.qty')} ${this.qty.overdue}`;
            },
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('updateInvoices', this.updateInvoices);
                this.emitter.on('deleteInvoices', this.deleteInvoices);
            },

            unsubscribeFromEvents() {
                this.emitter.off('updateInvoices', this.updateInvoices);
                this.emitter.off('deleteInvoices', this.deleteInvoices);
            },

            getInvoices() {
                this.isLoaded = true;

                djinAPI.get('/finance/invoices', this.params)
                    .then((result) => {
                        const { invoices, ...qtyInvoices } = result.data;
                        this.invoices = mapInvoicesToView(invoices);
                        this.qty = mapInvoicesQtyTypesToView(qtyInvoices);

                        this.emitter.emit('updateShowQty');
                        this.$nextTick(() => {
                            this.calcMaxHeight();
                        });
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.isLoaded = false;
                        }, 500)
                    })
            },

            updateInvoices() {
                this.getInvoices();
            },

            clearQueryParams() {
                this.emitter.emit('clearQueryParams');
            },

            updateQueryParams(params) {
                this.params = { ...params };
                this.getInvoices();
            },

            deleteInvoices(ids) {
                const invoiceIds = {
                    invoices: ids
                }

                djinAPI.delete('finance/invoices', {
                    data: invoiceIds
                })
                    .then(() => {
                        this.closeModalConfirm();

                        if (ids.length > 1) {
                            this.showDeleteSeveralToast();
                        } else {
                            const invoice = this.invoices.find((invoice) => invoice.id === ids[0]);
                            this.showDeleteOneToast(invoice.name);
                        }

                        this.updateInvoices();
                    })
            },

            openModalInvoice() {
                this.openModal({
                    name: 'modalInvoice'
                })
            },

            closeModalConfirm() {
                this.closeModal('modalConfirm');
            },

            updateStateFilter(id) {
                this.emitter.emit('updateStateFilter', id);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .finance-invoices {
        display: flex;
        flex-direction: column;
        height: 100%;

        &__content {
            flex-grow: 1;
        }

        &__head {
            display: flex;
            padding: 30px;
        }

        &__card {
            width: 100%;

            &:not(:last-child) {
                margin-right: 30px;
            }
        }

        &__filters {
            display: flex;
            justify-content: space-between;
            padding: 0 30px 15px 30px;
        }
    }
</style>

<style lang="scss">
    .invoice {
        &__control {
            width: 4%;

            .checkmark {
                border: 1px solid var(--app-base-primary);
            }
        }

        &__panel {
            width: 96%;
            display: flex;
            align-items: center;
        }

        &__number {
            width: 18%;
            position: relative;

            display: flex;
            align-items: center;

            button:first-child {
                margin-right: 30px;
            }

            &--is-position {
                padding-left: 40px;
            }
        }

        &__name {
            margin-left: 30px;
        }

        &__user-name {
            width: 12%;
        }

        &__user-document {
            width: 10%;
        }

        &__building {
            width: 14%;
        }

        &__due-date {
            width: 12%;
        }

        &__total {
            width: 10%;

            &--is-row {
                font-weight: 700;
            }
        }

        &__state {
            width: 10%;
        }

        &__payment-date {
            width: 10%;
        }

        /*@media screen and (max-width: 1770px) {*/
        /*    &__payment-date {*/
        /*        width: 10%;*/
        /*    }*/

        /*    &__user-name {*/
        /*        width: 12%;*/
        /*    }*/
        /*}*/

        &__item {
            position: absolute;
            top: -18px;
            left: 5px;
            height: 50px;
        }

        &__selected {
            font-weight: 500;
        }

        &__send, &__delete {
            display: flex;
            align-items: center;
            margin-left: 30px;
        }
    }
</style>