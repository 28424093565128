<template>
    <div class="upload-file"
         :class="{
            'is-fixed': isFixedHeight,
            'is-full': !isFixedHeight
        }"
    >
        <div v-if="showLabel" class="app__label">
            {{ label }}
            <span class="upload-file__optional">
                {{ optionalText }}
            </span>
        </div>
        <div v-if="!uploadedFile.isUploaded && !fileName" class="upload-file__upload">
            <app-upload-area
                :help-text="upload.helpText"
                :btn-text="upload.btnText"
                :accept-types="acceptTypes"
                :check-type="false"
                @uploaded="showUploadedFile"
            />
        </div>
        <div v-else class="upload-file__preview preview">
            <div class="preview__type">
                <div class="preview__document">
                    <div class="preview__file file">
                        <div class="file__icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 2H6C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM6 20V4H13V9H18V20H6Z" fill="var(--brand-purple)"/>
                            </svg>
                        </div>
                        <div class="file__details">
                            <div class="file__name can-overflow">{{ fileName }}</div>
                            <div>{{ fileSize }}</div>
                        </div>
                    </div>
                    <app-circle-btn class="preview__clear-btn"
                        @action="deleteDocument"
                    >
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.875 4.00625L10.9938 3.125L7.5 6.61875L4.00625 3.125L3.125 4.00625L6.61875 7.5L3.125 10.9938L4.00625 11.875L7.5 8.38125L10.9938 11.875L11.875 10.9938L8.38125 7.5L11.875 4.00625Z" fill="#2B3037"/>
                        </svg>
                    </app-circle-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AppUploadArea from "./AppUploadArea";
    import { uploadTypes } from "../../../../constants/uploadFileTypes";

    export default {
        name: "AppUploadDocument",

        components: {
            AppUploadArea
        },

        props: {
            label: {
                type: String,
                default: '',
            },
            acceptTypes: {
                type: Array,
                default() {
                    return [];
                }
            },
            height: {
                type: String,
                default: 'fixed',
                validator(height) {
                    return ['full', 'fixed'].includes(height);
                }
            },
            fileName: {
                type: String,
                default: '',
            },
            fileSize: {
                type: String,
                default: '',
            },
            required: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                uploadedFile: {
                    isUploaded: false,
                    url: '',
                    base64: '',
                    file: '',
                    isChanged: false
                }
            }
        },

        computed: {
            upload() {
                return uploadTypes.document;
            },

            isFixedHeight() {
                return this.height === 'fixed';
            },

            optionalText() {
                return this.required ? '' : ` ${this.$t('common.optional')}`;
            },

            showLabel() {
                return this.label;
            }
        },

        methods: {
            showUploadedFile(url, convertedFile, file) {
                this.uploadedFile.url = url;
                this.uploadedFile.base64 = convertedFile;
                this.uploadedFile.file = file;
                this.uploadedFile.isUploaded = true;

                this.$emit('update', {
                    base64: this.uploadedFile.base64,
                    file: this.uploadedFile.file,
                    isChanged: this.uploadedFile.isChanged
                })
            },

            deleteDocument() {
                this.uploadedFile.url = '';
                this.uploadedFile.base64 = '';
                this.uploadedFile.file = '';
                this.uploadedFile.isUploaded = false;

                if (this.fileName) {
                    this.uploadedFile.isChanged = true;
                }

                this.$emit('update', {
                    base64: '',
                    file: '',
                    isChanged: this.uploadedFile.isChanged
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .upload-file {
        display: flex;
        flex-direction: column;

        &__upload {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
        }

        &__optional {
            font-size: 12px;
            font-style: italic;
        }
    }

    .preview {
        position: relative;
        border: 1px solid var(--app-border);
        border-radius: 13px;
        flex-grow: 1;

        &__clear-btn {
            position: absolute;
            right: 15px;
            top: 15px;
        }

        &__type {
            padding: 8px 37px;
            width: 100%;
            height: 100%;
        }

        &__document {
            display: flex;
            align-items: center;
            height: 100%;
        }
    }

    .file {
        display: flex;

        &__icon {
            margin-right: 15px;
            height: 48px;
            width: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--app-table-background);
            border-radius: 13px;
        }

        &__details {
            font-size: 14px;
            line-height: 16px;
            color: var(--app-base-secondary);
        }

        &__name {
            font-size: 18px;
            line-height: 21px;
            color: var(--app-base-primary);
            font-weight: 500;
            margin-bottom: 5px;
            max-width: 300px;
        }
    }
</style>

<style lang="scss">
    .upload-file.is-full {
        height: 100%;

        &__upload {
            height: 100%;
        }

        .upload {
            height: 100%;
        }
    }

    .upload-file.is-fixed {
        .preview, .upload__area {
            height: 230px;
        }
    }
</style>