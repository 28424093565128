<template>
    <app-modal-wrapper>
        <div class="modal-change-password">
            <div class="change-password">
                <form @submit.prevent="changeUserPassword" class="change-password__form">
                    <h4>{{ $t('changePassword.title') }}</h4>
                    <div class="change-password__old-password">
                        <app-base-input
                            v-model="oldPassword"
                            :type="'password'"
                            :label="$t('changePassword.label.oldPassword')"
                            :error-message="errors.oldPassword"
                        />
                    </div>
                    <div class="change-password__forgot">
                        <app-text-btn
                            :text="$t('common.buttons.forgot')"
                            @action="openModalRecovery"
                        />
                    </div>
                    <div class="change-password__new-password-value">
                        <app-base-input
                            v-model="newPassword.value"
                            :label="$t('changePassword.label.value')"
                            :type="'password'"
                            :error-message="errors.newPasswordValue"
                        />
                    </div>
                    <div class="change-password__confirm-password-value">
                        <app-base-input
                            v-model="newPassword.confirm"
                            :label="$t('changePassword.label.confirm')"
                            :type="'password'"
                            :error-message="errors.newPasswordConfirm"
                        />
                    </div>
                    <div class="change-password__control">
                        <app-base-btn
                            :size="'full'"
                            :variation="'cancel'"
                            :text="$t('common.buttons.cancel')"
                            @action="closeModalChangePassword"
                        />
                        <app-base-btn
                            :type="'submit'"
                            :size="'full'"
                            :variation="'save'"
                            :text="$t('newPassword.btn')"
                        />
                    </div>
                </form>
            </div>
        </div>
    </app-modal-wrapper>
</template>

<script>
    import { changePassword, logout } from "../../../services/checkAccess";
    import { passwordErrors, validatePasswordData } from "../../../validations/password";
    import { changeLoginErrors } from "../../../validations/changeLogin";
    import { authToastsMixin } from "../../../mixins/toasts/auth";

    export default {
        name: "ModalChangePassword",

        data() {
            return {
                oldPassword: '',
                newPassword: {
                    value: '',
                    confirm: ''
                },
                errors: {
                    oldPassword: '',
                    newPasswordValue: '',
                    newPasswordConfirm: ''
                }
            }
        },

        mixins: [authToastsMixin],

        methods: {
            async changeUserPassword() {
                this.clearErrors();
                const isValid = this.validateNewPasswordData(this.newPassword.value, this.newPassword.confirm);

                if (isValid) {
                    await changePassword(this.oldPassword, this.newPassword.value)
                        .then(() => {
                            this.showChangePasswordToast();
                            this.closeModalChangePassword();
                            this.logoutUser();
                        })
                }
            },

            validateNewPasswordData(value, confirm) {
                let isValid = false;

                if (value === confirm) {
                    const isValidByRules = validatePasswordData(value);

                    if (isValidByRules) {
                        isValid = true;
                    } else {
                        this.setNewPasswordErrors('validation');
                    }
                } else {
                    this.setNewPasswordErrors('identity');
                }

                return isValid;
            },

            clearErrors() {
                this.clearErrorsOldPassword();
                this.clearErrorsNewPassword();

            },

            clearErrorsOldPassword() {
                this.errors.oldPassword = '';
            },

            setNewPasswordErrors(type) {
                this.errors.newPasswordValue = passwordErrors[type];
                this.errors.newPasswordConfirm = 'isNotShowError';
            },

            clearErrorsNewPassword() {
                this.errors.newPasswordValue = '';
                this.errors.newPasswordConfirm = '';
            },

            async logoutUser() {
                await logout()
                    .then(isSuccess => {
                        if (isSuccess) {
                            this.$router.push({ name: 'Login' });
                        }
                    })
            },

            closeModalChangePassword() {
                this.closeModal('modalChangePassword');
            },

            openModalRecovery() {
                this.openModal({
                    name: 'modalRecoveryPassword'
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
    .modal-change-password {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        max-width: 574px;
        width: 100%;
    }

    .change-password {
        h4 {
            margin-bottom: 30px;
        }

        &__old-password {
            margin-bottom: 10px;
        }

        &__forgot {
            margin-bottom: 15px;
        }

        &__new-password-value {
            margin-bottom: 10px;
        }

        &__control {
            display: flex;
            margin-top: 45px;

            button:first-child {
                margin-right: 30px;
            }
        }
    }
</style>