import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

const emptyContentInfo = {
    welcome: t('empty.welcome'),
    admins: {
        label: t('empty.admins.label'),
        btn: t('empty.admins.btn')
    },
    buildings: {
        label: t('empty.buildings.label'),
        btn: t('empty.buildings.btn')
    },
    premises: {
        label: t('empty.premises.label'),
        btn: t('empty.premises.btn')
    },
    users: {
        label: t('empty.users.label'),
        btn: t('empty.users.btn')
    },
    contracts: {
        label: t('empty.contracts.label'),
        btn: t('empty.contracts.btn')
    },
    invoices: {
        label: t('empty.invoices.label'),
        btn: t('empty.invoices.btn')
    },
    contractTemplates: {
        label: t('empty.contractTemplates.label'),
        btn: t('empty.contractTemplates.btn')
    },
    invoiceTemplates: {
        label: t('empty.invoiceTemplates.label'),
        btn: t('empty.invoiceTemplates.btn')
    },
    balance: {
        label: t('empty.balance.label')
    },
    reviewInvoices: {
        label: t('empty.reviewInvoices.label')
    },
    operations: {
        label: t('empty.operations.label'),
        btn: t('empty.operations.btn')
    }
}

const noResultsContentInfo = {
    title: t('filters.empty.title'),
    details: t('filters.empty.details'),
    btn: t('filters.empty.btn')
}

export {
    emptyContentInfo,
    noResultsContentInfo
}