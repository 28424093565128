<template>
    <div class="table__row review-invoice-row" @click="handlerClick">
        <div class="table__column review-invoice__date">
            {{ formattedDate }}
        </div>
        <div class="table__column review-invoice__amount review-invoice__amount--is-row">
            {{ formattedAmount }}
        </div>
        <div class="table__column review-invoice__payment">
            <div class="review-invoice__bank-name">
                <app-match-string
                    :string="bankName"
                    :sub-string="subString"
                />
            </div>
            <div class="review-invoice__account-number">
                <app-match-string
                    :string="accountNumber"
                    :sub-string="subString"
                />
            </div>
            <div>
                <app-match-string
                    :string="details"
                    :sub-string="subString"
                />
            </div>
        </div>
        <div class="table__column review-invoice__user-name">
            <app-match-string
                :string="userName"
                :sub-string="subString"
            />
        </div>
        <div class="table__column review-invoice__invoice-number">
            <app-match-string
                :string="formattedNumber"
                :sub-string="subString"
            />
            <div v-if="invoiceLink">
                <app-circle-btn
                    @action="viewInvoice"
                >
                    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.0179 11.875H3.26785V3.125H7.64285V1.875H3.26785C2.5741 1.875 2.01785 2.4375 2.01785 3.125V11.875C2.01785 12.5625 2.5741 13.125 3.26785 13.125H12.0179C12.7054 13.125 13.2679 12.5625 13.2679 11.875V7.5H12.0179V11.875ZM8.89285 1.875V3.125H11.1366L4.99285 9.26875L5.8741 10.15L12.0179 4.00625V6.25H13.2679V1.875H8.89285Z" fill="#2B3037"/>
                    </svg>
                </app-circle-btn>
            </div>
        </div>
        <div class="table__column review-invoice__should-be review-invoice__should-be--is-row">
            <div v-if="shouldBe">
                {{ formattedShouldBeTotal }}
            </div>
        </div>
        <div class="table__column review-invoice__state">
            <app-base-state
                :id="reviewInvoiceState.id"
                :name="reviewInvoiceState.name"
                :background="reviewInvoiceState.background"
            />
        </div>
        <div class="table__column review-invoice__actions">
            <app-circle-btn
                :is-delete="true"
                @action="deleteInvoice"
            >
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 5.625V11.875H5V5.625H10ZM9.0625 1.875H5.9375L5.3125 2.5H3.125V3.75H11.875V2.5H9.6875L9.0625 1.875ZM11.25 4.375H3.75V11.875C3.75 12.5625 4.3125 13.125 5 13.125H10C10.6875 13.125 11.25 12.5625 11.25 11.875V4.375Z" fill="#FF3B30"/>
                </svg>
            </app-circle-btn>
        </div>
    </div>
</template>

<script>
    import { formatCurrencyValue } from "../../../helpers/finance";
    import { formatTimestampToDate } from "../../../helpers/date";
    import { reviewInvoiceStates } from "../../../constants/finance";

    export default {
        name: "FinanceReviewInvoiceRow",

        props: {
            id: {
                type: String,
                required: true
            },
            date: {
                type: Number,
                required: true
            },
            amount: {
                type: Number,
                required: true
            },
            bankName: {
                type: String,
                required: true
            },
            accountNumber: {
                type: String,
                required: true
            },
            details: {
                type: String,
                required: true
            },
            userId: {
                type: String,
                required: true
            },
            userName: {
                type: String,
                required: true
            },
            invoiceLink: {
                type: String,
                required: true
            },
            invoiceNumber: {
                type: String,
                required: true
            },
            shouldBe: {
                type: [ Number, null ],
                required: true
            },
            state: {
                type: Number,
                required: true
            },
            subString: {
                type: String,
                required: true
            }
        },

        computed: {
            formattedNumber() {
                return this.invoiceNumber ? `№${this.invoiceNumber}` : '';
            },

            formattedAmount() {
                const { balance, currency } = formatCurrencyValue(this.amount);

                return `${balance} ${currency}`;
            },

            formattedShouldBeTotal() {
                const shouldBe = this.shouldBe ? this.shouldBe : 0;
                const { balance, currency } = formatCurrencyValue(shouldBe);

                return `${balance} ${currency}`;
            },

            formattedDate() {
                return formatTimestampToDate(this.date);
            },

            reviewInvoiceState() {
                return reviewInvoiceStates.find(state => state.id === this.state);
            }
        },

        methods: {
            viewInvoice() {
                window.open(this.invoiceLink, '_blank');
            },

            handlerClick() {
                this.$emit('review', this.id);
            },

            deleteInvoice() {
                this.$emit('delete', this.id);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .review-invoice-row {
        min-height: unset;

        &:hover {
            cursor: pointer;
        }
    }
</style>