import { showToast } from "../../helpers/toasts";

const authToastsMixin = {
    methods: {
        showCompleteSignUpToast() {
            showToast('success',
                this.$t('toasts.changePassword.title'),
                this.$t('toasts.changePassword.details'),
            );
        },

        showChangeLoginToast() {
            showToast('success',
                this.$t('toasts.changeLogin.title'),
                this.$t('toasts.changeLogin.details'),
            );
        },

        showChangePasswordToast() {
            showToast('success',
                this.$t('toasts.changePassword.title'),
                this.$t('toasts.changePassword.details'),
            );
        },

        showRecoveryPasswordToast() {
            showToast('success',
                this.$t('toasts.changePassword.title'),
                this.$t('toasts.changePassword.details'),
            );
        }
    }
}

export {
    authToastsMixin
}