<template>
    <router-link :to="route" class="menu-item">
        <span>{{ name }}</span>
        <div v-if="showQty" class="menu-item__qty">
            {{ qty }}
        </div>
    </router-link>
</template>

<script>
    export default {
        name: "AppMenuItem",

        props: {
            id: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            route: {
                type: String,
                required: true
            },
            qty: {
                type: Number,
                default: 0
            }
        },

        computed: {
            showQty() {
                return this.id === '5' && this.qty;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .menu-item {
        color: var(--app-base-primary);
        font-size: 16px;
        margin-right: 30px;
        padding: 15px 0 12px 0;
        transition: 0.3s;
        border-bottom: 3px solid transparent;
        position: relative;

        &__qty {
            position: absolute;
            top: -8px;
            right: -24px;
            padding: 4px;
            min-width: 25px;
            min-height: 25px;
            color: var(--brand-main);
            background: var(--brand-main-15);
            border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
        }

        &:hover {
            color: var(--brand-main);
        }
    }

    .menu-item.router-link-active {
        color: var(--brand-main);
        border-bottom: 3px solid var(--brand-main);
    }
</style>