<template>
    <div class="contacts">
<!--        <div class="contacts__panel">-->
<!--            <div class="contacts__title">Контакти будинку</div>-->
<!--        </div>-->
    </div>
</template>

<script>
    export default {
        name: "ContactsControl"
    }
</script>