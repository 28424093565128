import { showToast } from "../../helpers/toasts";

const premisesToastsMixin = {
    methods: {
        showDeletePremiseToast(name) {
            const deleteToastTitle = this.$t('toasts.premises.delete.title');

            const deleteToastDetails = `
                    ${this.$t(`toasts.premises.delete.details1`)}
                    ${name}
                    ${this.$t(`toasts.premises.delete.details2`)}`
            ;

            showToast('success',
                deleteToastTitle,
                deleteToastDetails
            );
        },

        showCreatePremiseToast() {
            showToast('success',
                this.$t('toasts.success'),
                this.$t('toasts.premises.create.details')
            );
        }
    }
}

export {
    premisesToastsMixin
}