<template>
    <div class="finance-statements">
        <div class="finance-statements__details">
            <finance-statements-details
                :users="users"
                @select-user="selectUser"
                @select-period="selectPeriod"
                @update-query-details="updateQueryDetails"
                @update-query-type="updateQueryType"
            />
        </div>
        <div class="finance-statements__content" ref="financeStatements">
            <div v-if="!showStatementsFilters">
                <finance-empty-statements-table
                    :empty-rows="emptyRows"
                />
            </div>
            <div v-if="showStatementsFilters" class="finance-statements__filters">
                <finance-statements-filters
                    :title="title"
                    @update-query-params="updateQueryParams"
                />
            </div>
            <div v-if="showUserStatementsTable">
                <finance-user-statements-table
                    :user="userStatements"
                    :max-height="maxHeightScroll"
                />
            </div>
            <div v-if="showEnterpriseStatementsTable">
                <finance-enterprise-statements-table
                    :enterprise="enterpriseStatements"
                    :max-height="maxHeightScroll"
                />
            </div>
            <div v-if="isLoaded" class="finance-statements__loader">
                <app-loader
                    :size="'large'"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { djinAPI } from "../../../config/api";
    import { maxHeightMixin } from "../../../mixins/calcMaxHeight";
    import { selectPeriods } from "../../../constants/period";
    import { mapUsersToSelect, mapUsersToView } from "../../../api/mappers/users";
    import { mapUserStatementsToView, mapEnterpriseStatementsToView } from "../../../api/mappers/finance/statements";
    import FinanceStatementsDetails from "./FinanceStatementsDetails";
    import FinanceStatementsFilters from "./FinanceStatementsFilters";
    import FinanceUserStatementsTable from "./tables/FinanceUserStatementsTable";
    import FinanceEnterpriseStatementsTable from "./tables/FinanceEnterpriseStatementsTable";
    import FinanceEmptyStatementsTable from "./tables/FinanceEmptyStatementsTable";

    export default {
        name: "FinanceStatementsControl",

        components: {
            FinanceEmptyStatementsTable,
            FinanceEnterpriseStatementsTable,
            FinanceStatementsDetails,
            FinanceStatementsFilters,
            FinanceUserStatementsTable
        },

        data() {
            return {
                emptyRows: [],
                isLoaded: false,
                showUserStatements: false,
                showEnterpriseStatements: false,
                selectedUser: null,
                selectedPeriod: null,
                title: '',
                users: [],
                userStatements: null,
                enterpriseStatements: null,
                query: {
                    type: 'enterprise',
                    details: null,
                    params: null
                }
            }
        },

        mixins: [
            maxHeightMixin
        ],

        created() {
            this.emptyRows.length = 8;
            this.getUsers();
        },

        mounted() {
            this.subscribeToResize('financeStatements');
        },

        unmounted() {
            this.unsubscribeFromResize();
        },

        computed: {
            isUserType() {
                return this.query.type === 'user';
            },

            showUserStatementsTable() {
                return !this.isLoaded && this.showUserStatements;
            },

            showEnterpriseStatementsTable() {
                return !this.isLoaded && this.showEnterpriseStatements;
            },

            showStatementsFilters() {
                return this.showUserStatements || this.showEnterpriseStatements;
            }
        },

        methods: {
            getUsers() {
                this.isLoaded = true;

                djinAPI.get('/users')
                    .then((result) => {
                        const users = mapUsersToView(result.data);
                        this.users = mapUsersToSelect(users, true);
                    })
                    .finally(() => {
                        this.isLoaded = false;
                    })
            },

            selectUser(user) {
                this.selectedUser = user;
            },

            selectPeriod(period) {
                this.selectedPeriod = period;
            },

            getStatements() {
               if (this.isUserType) {
                   this.getUserStatements();
               } else {
                   this.getEnterpriseStatements();
               }
            },

            getUserStatements() {
                this.isLoaded = true;
                const queryId = this.selectedUser.id;

                const params = {
                    params: { ...this.query.details, ...this.query.params }
                };

                djinAPI.get(`/finance/statements/user/${queryId}`, params)
                    .then((result) => {
                        this.userStatements = mapUserStatementsToView(result.data);
                        this.executeAdditionalActions(true);
                    })
                    .finally(() => {
                        this.isLoaded = false;
                    })
            },

            getEnterpriseStatements() {
                this.isLoaded = true;

                const params = {
                    params: { ...this.query.details, ...this.query.params }
                };

                djinAPI.get('/finance/statements/enterprise', params)
                    .then((result) => {
                        this.enterpriseStatements = mapEnterpriseStatementsToView(result.data);
                        this.executeAdditionalActions(false);
                    })
                    .finally(() => {
                        this.isLoaded = false;
                    })
            },

            executeAdditionalActions(isUser) {
                this.showUserStatements = isUser;
                this.showEnterpriseStatements = !isUser;

                this.formStatementsTableTitle();

                this.$nextTick(() => {
                    this.calcMaxHeight();
                });
            },

            formStatementsTableTitle() {
                const details = this.$t('finance.statements.table.title');

                if (this.selectedPeriod.id === 'date') {
                    this.title = `${details} ${this.selectedPeriod.name}`;
                } else {
                    const parentPeriod = selectPeriods.find((parent) => parent.id === this.selectedPeriod.id.substring(0,1));
                    const childrenPeriod = parentPeriod.children.find((period) => period.id === this.selectedPeriod.id);
                    this.title = `${details} ${childrenPeriod.name.toLowerCase()} ${parentPeriod.name.toLowerCase()}`;
                }
            },

            updateQueryDetails(details) {
                this.query.details = { ...details };
                this.getStatements();
            },

            updateQueryType(isUserType) {
                this.query.type = isUserType ? 'user' : 'enterprise';
            },

            updateQueryParams(params) {
                this.query.params = { ...params };
                this.getStatements();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .finance-statements {
        display: flex;
        flex-direction: column;
        height: 100%;

        &__content {
            flex-grow: 1;
        }

        &__details {
            display: flex;
            justify-content: space-between;
            padding: 15px 30px 15px 30px;
        }

        &__filters {
            padding: 15px 30px;
            background: var(--app-table-background);
        }
    }
</style>

<style lang="scss">
    .finance-statements-table {
        overflow-y: auto;
        scrollbar-width: thin;
        height: 100%;

        &__head {
            display: flex;
            flex-direction: column;
            width: 100%;
            font-size: 16px;
            line-height: 18.75px;
            color: var(--app-base-primary);
            background: var(--brand-pink);

            &--is-sticky {
                position: sticky;
                top:0;
                z-index: 1;
            }
        }

        &__details, &__operations {
            display: flex;
            align-items: center;
            width: 100%;
            height: 50px;
            border-bottom: 1px solid var(--app-border);
        }
    }

    .finance-statement-row {
        .table__row {
            background: unset;
            min-height: 50px !important;
            padding: 0 0 0 30px;
        }

        &--is-head {
            background: var(--brand-pink);
        }
    }

    .statement {
        &__name {
            width: 25%;
            display: flex;
            align-items: center;

            button {
                margin-right: 30px;
            }

            &--is-head {
                font-weight: 500;
                margin-left: 30px;
            }
        }

        &__document-id {
            width: 15%;
        }

        &__period, &__e-period {
            display: flex;

            &--is-head {
                justify-content: center;
            }
        }

        &__period {
            width: 20%;
        }

        &__e-period {
            width: 25%;
        }

        &__debit, &__credit {
            width: 50%;
        }

        &__credit.table__f-column {
            border-right: unset !important;
        }
    }
</style>