<template>
    <div class="invoice-template">
        <div class="invoice-template__icon">
            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M27.4375 8.33268L37.5 18.3952V41.666H12.5V8.33268H27.4375ZM29.1666 4.16602H12.5C10.2083 4.16602 8.33331 6.04102 8.33331 8.33268V41.666C8.33331 43.9577 10.2083 45.8327 12.5 45.8327H37.5C39.7916 45.8327 41.6666 43.9577 41.6666 41.666V16.666L29.1666 4.16602ZM31.25 22.916H22.9166V24.9994H29.1666C30.3125 24.9994 31.25 25.9369 31.25 27.0827V33.3327C31.25 34.4785 30.3125 35.416 29.1666 35.416H27.0833V37.4994H22.9166V35.416H18.75V31.2494H27.0833V29.166H20.8333C19.6875 29.166 18.75 28.2285 18.75 27.0827V20.8327C18.75 19.6869 19.6875 18.7493 20.8333 18.7493H22.9166V16.666H27.0833V18.7493H31.25V22.916Z" fill="var(--brand-main)"/>
            </svg>
        </div>
        <div class="invoice-template__info">
            <div class="invoice-template__price">
                {{ formattedPrice }}
            </div>
            <div class="invoice-template__details">
                <div class="invoice-template__name">
                    {{ name }}
                </div>
                <div class="invoice-template__actions" ref="invoiceControlPanel">
                    <app-circle-btn
                        :is-transparent="true"
                        @action="toggleControlPanel"
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 8C13.1 8 14 7.1 14 6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6C10 7.1 10.9 8 12 8ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM12 16C10.9 16 10 16.9 10 18C10 19.1 10.9 20 12 20C13.1 20 14 19.1 14 18C14 16.9 13.1 16 12 16Z" fill="#2D28F3"/>
                        </svg>
                    </app-circle-btn>
                    <div v-if="showPanel" class="invoice-template__actions-panel">
                        <app-action-btn
                            :id="'update'"
                            :text="$t('finance.templates.actions.edit')"
                            @action="updateInvoiceTemplate"
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19 12V19H5V12H3V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V12H19ZM13 12.67L15.59 10.09L17 11.5L12 16.5L7 11.5L8.41 10.09L11 12.67V3H13V12.67Z" fill="#B6C3D3"/>
                            </svg>
                        </app-action-btn>
                        <app-action-btn
                            :id="'delete'"
                            :text="$t('finance.templates.actions.delete')"
                            @action="deleteInvoiceTemplate"
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7Z" fill="#FF3B30"/>
                            </svg>
                        </app-action-btn>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { formatCurrencyValue } from "../../../helpers/finance";
    import AppActionBtn from "../../common/global/buttons/AppActionBtn";

    export default {
        name: "InvoiceTemplateCard",
        components: {AppActionBtn},
        props: {
            id: {
                type: String,
                required: true
            },
            price: {
                type: Number,
                required: true
            },
            name: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                showPanel: false
            }
        },

        mounted() {
            document.addEventListener("mousedown", this.handlerClickOutside);
        },

        unmounted() {
            document.removeEventListener("mousedown", this.handlerClickOutside);
        },

        computed: {
            formattedPrice() {
                const { balance, currency } = formatCurrencyValue(this.price);

                return `${balance} ${currency}`;
            }
        },

        methods: {
            handlerClickOutside(event) {
                if (!this.$refs.invoiceControlPanel.contains(event.target)) {
                    this.showPanel = false;
                }
            },

            toggleControlPanel() {
                this.showPanel = !this.showPanel;
            },

            closeControlPanel() {
                this.showPanel = false;
            },

            updateInvoiceTemplate() {
                this.$emit('update', this.id);
                this.closeControlPanel();
            },

            deleteInvoiceTemplate() {
                this.$emit('delete', this.id);
                this.closeControlPanel();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .invoice-template {
        display: flex;
        align-items: center;
        background: var(--app-btn-secondary);
        border-radius: 15px;
        padding: 15px;

        &__info {
            width: 100%;
        }

        &__icon {
            width: 106px;
            min-width: 106px;
            height: 106px;
            background: var(--system-white);
            border-radius: 13px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 30px;
        }

        &__price {
            font-size: 28px;
            font-weight: 500;
            margin-bottom: 15px;
        }

        &__details {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            position: relative;

            button {
                width: 24px;
                height: 24px;
            }
        }

        &__actions-panel {
            position: absolute;
            top: -55px;
            right: 30px;
            background: var(--system-white);
            box-shadow: 0px 16px 32px rgba(35, 23, 5, 0.26);
            border-radius: 13px;
            padding: 7px 0;
        }

        &__name {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        @media screen and (max-width: 1770px) {
            font-size: 16px;
        }
    }
</style>