import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

const filtersText = {
    noResult: t('filters.noResult'),
    noData: t('filters.noData'),
    noGroup: t('filters.noGroup'),
    groups: t('filters.groups'),
    buildings: t('filters.buildings'),
    cities: t('filters.cities'),
    entrances: t('filters.entrances'),
    floors: t('filters.floors'),
    types: t('filters.categories'),
    categories: t('filters.categories'),
    views: t('filters.view.title'),
    operations: t('filters.operation.title'),
    balance: t('filters.balance.title'),
    invoiceReviewStates: t('filters.state.title'),
    operationAmountTypes: t('filters.operations.type'),
    operationServices: t('filters.operations.service'),
}

function getResultText(qty) {
    let defaultText = t('filters.results.more');

    switch (qty) {
        case 1:
            defaultText = t('filters.results.one');
            break;
        case 2:
        case 3:
        case 4:
            defaultText = t('filters.results.two');
            break;
    }

    return defaultText;
}

function mapActiveFiltersToString(filters) {
    const filterStrings = [];
    const filterKeys = Object.keys(filters);

    filterKeys.forEach((key) => {
        if (filters[key].length) {
            const keyFilter = `${filtersKeyMatch[key]}:${filters[key].join(',')}`;
            filterStrings.push(keyFilter);
        }
    })

    return filterStrings.join(';');
}

const filtersKeyMatch = {
    groups: 'building_groups',
    cities: 'cities',
    buildings: 'building',
    entrances: 'entrance',
    floors: 'floor',
    types: 'premise_type',
    states: 'status',
    balance: 'balance',
    invoiceReviewStates: 'status',
    operationAmountTypes: 'amount',
    services: 'type',
    premises: 'premise_id'
}

const filterKeys = {
    buildings: ['groups', 'cities'],
    premises: [ 'groups', 'buildings', 'entrances', 'floors', 'types'],
    users: [ 'groups', 'buildings', 'entrances', 'floors'],
    contracts: [ 'groups', 'buildings', 'entrances', 'floors', 'types', 'states'],
    invoices: [ 'groups', 'buildings', 'entrances', 'floors', 'types', 'states'],
    balance: [ 'groups', 'buildings', 'entrances', 'floors', 'types', 'balance'],
    reviewInvoices: [ 'invoiceReviewStates' ],
    operations: [ 'operationAmountTypes' ],
    statements: ['views', 'operations']
}

function getFiltersByType(type, filters) {
    const keys = filterKeys[type];

    return keys.reduce((acc, key) => {
        return { ...acc, [key] : filters ? filters[key] : [] };
    }, {});
}

export {
    filtersText,
    mapActiveFiltersToString,
    getResultText,
    getFiltersByType
}