<template>
    <div class="finance-statement-row finance-empty-statement-row"
        :class="{
            'finance-statement-row--is-head': isHeadRow
        }"
    >
        <div class="table__row">
            <div class="table__f-column statement__name"></div>
            <div class="table__f-column statement__document-id"></div>
            <div class="table__f-column statement__period"></div>
            <div class="table__f-column statement__period"></div>
            <div class="table__f-column statement__period"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FinanceEmptyStatementRow",

        props: {
            index: {
                type: Number,
                required: true
            }
        },

        computed: {
            isHeadRow() {
                return this.index < 2;
            }
        }
    }
</script>

<style scoped lang="scss">
    .finance-empty-statement-row {
        width: 100%;
    }
</style>