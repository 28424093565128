<template>
    <div class="empty">
        <div class="empty__image">
            <svg v-if="!isSmall" width="290" height="283" viewBox="0 0 290 283" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_762_20547)">
                    <path d="M184.093 63.6456H105.972C104.192 63.6477 102.485 64.3559 101.226 65.6147C99.9674 66.8735 99.2592 68.5803 99.2571 70.3605V243.986L98.3618 244.259L79.1973 250.128C78.2891 250.405 77.3081 250.31 76.4696 249.865C75.6312 249.419 75.0038 248.659 74.7253 247.751L17.7199 61.546C17.4425 60.6378 17.5371 59.6565 17.9828 58.8179C18.4285 57.9794 19.189 57.3521 20.097 57.0739L49.6294 48.0311L135.245 21.825L164.777 12.7823C165.226 12.6439 165.699 12.5956 166.167 12.64C166.635 12.6844 167.09 12.8207 167.505 13.0411C167.92 13.2615 168.288 13.5616 168.587 13.9243C168.887 14.287 169.112 14.7052 169.249 15.1549L183.82 62.7502L184.093 63.6456Z" fill="#F2F2F2"/>
                    <path d="M201.14 62.7507L183.579 5.38735C183.286 4.43157 182.809 3.54273 182.173 2.77162C181.537 2.00051 180.756 1.36225 179.873 0.893305C178.99 0.42436 178.024 0.133919 177.029 0.0385902C176.034 -0.0567388 175.03 0.044912 174.075 0.337723L132.554 13.0468L46.9434 39.2574L5.4228 51.971C3.49397 52.5633 1.87894 53.8967 0.932197 55.6785C-0.014545 57.4603 -0.215646 59.545 0.373043 61.4749L60.3956 257.519C60.8738 259.077 61.8387 260.441 63.1488 261.41C64.4588 262.379 66.045 262.903 67.6747 262.905C68.4289 262.905 69.1789 262.792 69.8994 262.569L98.3618 253.857L99.2571 253.58V252.644L98.3618 252.917L69.6354 261.714C67.9328 262.233 66.0939 262.055 64.5221 261.22C62.9502 260.385 61.7737 258.961 61.2507 257.26L1.23274 61.2108C0.973581 60.3678 0.883259 59.482 0.966938 58.604C1.05062 57.7261 1.30665 56.8733 1.72039 56.0944C2.13412 55.3156 2.69744 54.626 3.37807 54.0651C4.05869 53.5043 4.84325 53.0832 5.68684 52.8261L47.2075 40.1125L132.818 13.9064L174.339 1.19277C174.979 0.997495 175.644 0.897931 176.313 0.897307C177.749 0.900531 179.146 1.36339 180.299 2.2181C181.453 3.0728 182.303 4.27444 182.724 5.647L200.205 62.7507L200.482 63.6461H201.414L201.14 62.7507Z" fill="#2B3037"/>
                    <path d="M54.9586 57.22C54.0958 57.2194 53.2559 56.9423 52.5621 56.4294C51.8683 55.9164 51.3571 55.1946 51.1036 54.3699L45.3376 35.536C45.1826 35.0301 45.1289 34.4986 45.1794 33.9719C45.2298 33.4452 45.3836 32.9336 45.6318 32.4664C45.88 31.9991 46.2179 31.5853 46.6261 31.2486C47.0342 30.912 47.5047 30.659 48.0107 30.5042L126.772 6.39142C127.794 6.07963 128.897 6.18597 129.841 6.68708C130.784 7.18819 131.49 8.04314 131.804 9.06427L137.57 27.8984C137.881 28.9201 137.775 30.0238 137.274 30.9671C136.773 31.9105 135.918 32.6166 134.897 32.9305L56.1357 57.0432C55.7543 57.1603 55.3576 57.2199 54.9586 57.22Z" fill="var(--brand-purple)"/>
                    <path d="M85.1635 20.1242C90.1082 20.1242 94.1167 16.1157 94.1167 11.171C94.1167 6.22627 90.1082 2.21777 85.1635 2.21777C80.2187 2.21777 76.2102 6.22627 76.2102 11.171C76.2102 16.1157 80.2187 20.1242 85.1635 20.1242Z" fill="var(--brand-purple)"/>
                    <path d="M85.1635 16.8409C88.2946 16.8409 90.8329 14.3026 90.8329 11.1714C90.8329 8.04026 88.2946 5.50195 85.1635 5.50195C82.0323 5.50195 79.494 8.04026 79.494 11.1714C79.494 14.3026 82.0323 16.8409 85.1635 16.8409Z" fill="white"/>
                    <path d="M269.816 260.617H118.507C117.498 260.616 116.531 260.215 115.817 259.502C115.104 258.788 114.703 257.821 114.702 256.812V75.5092C114.703 74.5004 115.104 73.5332 115.817 72.8198C116.531 72.1065 117.498 71.7052 118.507 71.7041H269.816C270.825 71.7053 271.792 72.1065 272.506 72.8199C273.219 73.5332 273.62 74.5004 273.621 75.5092V256.812C273.62 257.821 273.219 258.788 272.506 259.502C271.792 260.215 270.825 260.616 269.816 260.617Z" fill="#E6E6E6"/>
                    <path d="M200.205 62.75H105.972C103.955 62.7529 102.02 63.5556 100.594 64.9821C99.1673 66.4087 98.3646 68.3428 98.3618 70.3602V252.917L99.2571 252.644V70.3602C99.2592 68.58 99.9674 66.8733 101.226 65.6145C102.485 64.3556 104.192 63.6475 105.972 63.6453H200.482L200.205 62.75ZM282.351 62.75H105.972C103.955 62.7529 102.02 63.5556 100.594 64.9821C99.1673 66.4087 98.3646 68.3428 98.3618 70.3602V275.389C98.3646 277.407 99.1673 279.341 100.594 280.767C102.02 282.194 103.955 282.997 105.972 283H282.351C284.368 282.997 286.302 282.194 287.729 280.767C289.155 279.341 289.958 277.407 289.961 275.389V70.3602C289.958 68.3428 289.155 66.4087 287.729 64.9821C286.302 63.5556 284.368 62.7529 282.351 62.75ZM289.066 275.389C289.063 277.17 288.355 278.876 287.096 280.135C285.838 281.394 284.131 282.102 282.351 282.104H105.972C104.192 282.102 102.485 281.394 101.226 280.135C99.9674 278.876 99.2592 277.17 99.2571 275.389V70.3602C99.2592 68.58 99.9674 66.8733 101.226 65.6145C102.485 64.3556 104.192 63.6475 105.972 63.6453H282.351C284.131 63.6475 285.838 64.3556 287.096 65.6145C288.355 66.8733 289.063 68.58 289.066 70.3602V275.389Z" fill="#2B3037"/>
                    <path d="M235.346 82.4474H152.977C151.908 82.4462 150.884 82.0213 150.129 81.266C149.374 80.5107 148.949 79.4866 148.948 78.4184V58.7213C148.949 57.6532 149.374 56.6291 150.129 55.8738C150.884 55.1185 151.908 54.6936 152.977 54.6924H235.346C236.415 54.6936 237.439 55.1185 238.194 55.8738C238.949 56.6291 239.374 57.6532 239.375 58.7213V78.4184C239.374 79.4866 238.949 80.5107 238.194 81.266C237.439 82.0213 236.415 82.4462 235.346 82.4474Z" fill="var(--brand-purple)"/>
                    <path d="M194.161 56.0354C199.106 56.0354 203.115 52.0269 203.115 47.0821C203.115 42.1374 199.106 38.1289 194.161 38.1289C189.217 38.1289 185.208 42.1374 185.208 47.0821C185.208 52.0269 189.217 56.0354 194.161 56.0354Z" fill="var(--brand-purple)"/>
                    <path d="M194.161 52.5356C197.173 52.5356 199.615 50.0941 199.615 47.0823C199.615 44.0705 197.173 41.6289 194.161 41.6289C191.15 41.6289 188.708 44.0705 188.708 47.0823C188.708 50.0941 191.15 52.5356 194.161 52.5356Z" fill="white"/>
                </g>
                <defs>
                    <clipPath id="clip0_762_20547">
                        <rect width="289.922" height="283" fill="white" transform="translate(0.039032)"/>
                    </clipPath>
                </defs>
            </svg>
            <svg v-else width="194" height="188" viewBox="0 0 194 188" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_762_20548)">
                    <path d="M122.97 42.2809H71.0733C69.8906 42.2823 68.7569 42.7528 67.9206 43.589C67.0844 44.4253 66.6139 45.5591 66.6125 46.7417V162.083L66.0177 162.264L53.2866 166.163C52.6832 166.347 52.0315 166.284 51.4745 165.988C50.9175 165.692 50.5008 165.187 50.3158 164.584L12.4465 40.8861C12.2622 40.2828 12.325 39.6309 12.6211 39.0738C12.9172 38.5168 13.4224 38.1 14.0256 37.9153L33.6443 31.908L90.5194 14.499L110.138 8.49183C110.437 8.39994 110.75 8.36784 111.061 8.39734C111.372 8.42685 111.674 8.51739 111.95 8.66379C112.226 8.81019 112.471 9.00956 112.669 9.25051C112.868 9.49146 113.018 9.76925 113.109 10.068L122.789 41.6861L122.97 42.2809Z" fill="#F2F2F2"/>
                    <path d="M134.295 41.6853L122.628 3.57823C122.434 2.9433 122.117 2.35283 121.694 1.84057C121.272 1.32832 120.753 0.904315 120.166 0.59279C119.58 0.281265 118.938 0.0883222 118.277 0.0249941C117.616 -0.038334 116.949 0.0291937 116.315 0.223712L88.732 8.66652L31.8599 26.0785L4.27732 34.5243C2.99598 34.9177 1.92309 35.8035 1.29416 36.9872C0.66523 38.1709 0.531636 39.5557 0.922709 40.8378L40.7963 171.072C41.114 172.107 41.755 173.013 42.6253 173.657C43.4956 174.301 44.5493 174.649 45.6319 174.65C46.133 174.65 46.6312 174.575 47.1098 174.427L66.0177 168.64L66.6125 168.455V167.834L66.0177 168.015L46.9344 173.859C45.8034 174.204 44.5818 174.086 43.5376 173.531C42.4934 172.976 41.7118 172.03 41.3644 170.9L1.49381 40.6623C1.32165 40.1023 1.26165 39.5139 1.31724 38.9306C1.37283 38.3474 1.54291 37.7809 1.81776 37.2635C2.09261 36.7461 2.46683 36.288 2.91898 35.9154C3.37112 35.5429 3.89232 35.2631 4.45272 35.0923L32.0353 26.6465L88.9076 9.2375L116.49 0.791726C116.915 0.662005 117.357 0.595863 117.802 0.595449C118.755 0.597591 119.683 0.905076 120.45 1.47287C121.216 2.04066 121.78 2.83891 122.06 3.75072L133.673 41.6854L133.858 42.2801H134.476L134.295 41.6853Z" fill="#2B3037"/>
                    <path d="M37.1845 38.0125C36.6113 38.0121 36.0534 37.8281 35.5925 37.4873C35.1316 37.1465 34.792 36.6671 34.6236 36.1192L30.7932 23.6076C30.6902 23.2715 30.6545 22.9184 30.6881 22.5686C30.7216 22.2187 30.8237 21.8788 30.9886 21.5684C31.1535 21.258 31.378 20.9831 31.6491 20.7595C31.9203 20.5358 32.2328 20.3677 32.5689 20.2649L84.8908 4.24653C85.5696 4.03941 86.3027 4.11005 86.9295 4.44295C87.5562 4.77584 88.0252 5.34379 88.2337 6.02214L92.0641 18.5339C92.2711 19.2126 92.2004 19.9458 91.8675 20.5724C91.5346 21.1991 90.9668 21.6682 90.2885 21.8767L37.9665 37.8951C37.7131 37.9728 37.4495 38.0124 37.1845 38.0125V38.0125Z" fill="var(--brand-purple)"/>
                    <path d="M57.2499 13.3691C60.5348 13.3691 63.1977 10.7062 63.1977 7.42136C63.1977 4.13652 60.5348 1.47363 57.2499 1.47363C53.9651 1.47363 51.3022 4.13652 51.3022 7.42136C51.3022 10.7062 53.9651 13.3691 57.2499 13.3691Z" fill="var(--brand-purple)"/>
                    <path d="M57.2499 11.1878C59.33 11.1878 61.0162 9.50162 61.0162 7.42156C61.0162 5.3415 59.33 3.65527 57.2499 3.65527C55.1699 3.65527 53.4836 5.3415 53.4836 7.42156C53.4836 9.50162 55.1699 11.1878 57.2499 11.1878Z" fill="white"/>
                    <path d="M179.917 173.131H79.4002C78.73 173.13 78.0875 172.864 77.6136 172.39C77.1397 171.916 76.8731 171.273 76.8724 170.603V50.1616C76.8731 49.4914 77.1397 48.8489 77.6136 48.375C78.0875 47.9011 78.73 47.6345 79.4002 47.6338H179.917C180.587 47.6346 181.229 47.9011 181.703 48.375C182.177 48.8489 182.444 49.4914 182.445 50.1616V170.603C182.444 171.273 182.177 171.916 181.703 172.39C181.229 172.864 180.587 173.13 179.917 173.131V173.131Z" fill="#E6E6E6"/>
                    <path d="M133.673 41.6855H71.0733C69.733 41.6874 68.4482 42.2207 67.5005 43.1684C66.5528 44.1161 66.0196 45.4009 66.0177 46.7411V168.015L66.6125 167.834V46.7411C66.6139 45.5585 67.0843 44.4247 67.9206 43.5884C68.7569 42.7522 69.8906 42.2818 71.0733 42.2803H133.858L133.673 41.6855ZM188.244 41.6855H71.0733C69.733 41.6874 68.4482 42.2207 67.5005 43.1684C66.5528 44.1161 66.0196 45.4009 66.0177 46.7411V182.944C66.0196 184.284 66.5528 185.569 67.5005 186.517C68.4482 187.465 69.733 187.998 71.0733 188H188.244C189.584 187.998 190.869 187.465 191.816 186.517C192.764 185.569 193.297 184.284 193.299 182.944V46.7411C193.297 45.4009 192.764 44.1161 191.816 43.1684C190.869 42.2207 189.584 41.6874 188.244 41.6855V41.6855ZM192.704 182.944C192.703 184.127 192.232 185.261 191.396 186.097C190.56 186.933 189.426 187.404 188.244 187.405H71.0733C69.8906 187.404 68.7569 186.933 67.9206 186.097C67.0843 185.261 66.6139 184.127 66.6125 182.944V46.7411C66.6139 45.5585 67.0843 44.4247 67.9206 43.5884C68.7569 42.7522 69.8906 42.2818 71.0733 42.2803H188.244C189.426 42.2818 190.56 42.7522 191.396 43.5884C192.232 44.4247 192.703 45.5585 192.704 46.7411V182.944Z" fill="#2B3037"/>
                    <path d="M157.018 54.771H102.299C101.589 54.7702 100.909 54.4879 100.407 53.9862C99.9055 53.4844 99.6232 52.8041 99.6224 52.0945V39.0095C99.6232 38.2999 99.9055 37.6196 100.407 37.1178C100.909 36.616 101.589 36.3338 102.299 36.333H157.018C157.728 36.3338 158.408 36.616 158.91 37.1178C159.411 37.6196 159.694 38.2999 159.695 39.0095V52.0945C159.694 52.8041 159.412 53.4844 158.91 53.9862C158.408 54.4879 157.728 54.7702 157.018 54.771V54.771Z" fill="var(--brand-purple)"/>
                    <path d="M129.658 37.2246C132.943 37.2246 135.606 34.5617 135.606 31.2768C135.606 27.992 132.943 25.3291 129.658 25.3291C126.374 25.3291 123.711 27.992 123.711 31.2768C123.711 34.5617 126.374 37.2246 129.658 37.2246Z" fill="var(--brand-purple)"/>
                    <path d="M129.658 34.8997C131.659 34.8997 133.281 33.2778 133.281 31.277C133.281 29.2762 131.659 27.6543 129.658 27.6543C127.658 27.6543 126.036 29.2762 126.036 31.277C126.036 33.2778 127.658 34.8997 129.658 34.8997Z" fill="white"/>
                </g>
                <defs>
                    <clipPath id="clip0_762_20548">
                        <rect width="192.598" height="188" fill="white" transform="translate(0.700836)"/>
                    </clipPath>
                </defs>
            </svg>
        </div>
        <div class="empty__title">{{ info.title }}</div>
        <div class="empty__details">{{ info.details }}</div>
        <app-base-btn class="empty__add-btn"
            :variation="'save'"
            :text="info.btn"
            @action="clear"
        />
    </div>
</template>

<script>
    import { noResultsContentInfo } from "../../../constants/empty";

    export default {
        name: "AppNoResults",

        props: {
            isSmall: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            info() {
                return noResultsContentInfo;
            }
        },

        methods: {
            clear() {
                this.$emit('clear');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;

        &__title {
            font-weight: 500;
            font-size: 36px;
            line-height: 42px;
            text-align: center;
            color: var(--grey);
        }

        &__image {
            display: flex;
            justify-content: center;
            /*height: 283px;*/
            //We need dynamic margin here
            margin-bottom: 2.5vw;

            /*img {*/
            /*    max-width: 100%;*/
            /*    object-fit: cover;*/
            /*}*/
        }

        &__details {
            max-width: 415px;
            text-align: center;
            margin-top: 15px;
        }

        &__add-btn {
            margin-top: 30px;
        }
    }
</style>