<template>
    <div class="finance-contract-premise">
        <div class="table__row">
            <div class="table__column contract__control"></div>
            <div class="table__column contract__number contract__number--is-premise">
                <app-match-string
                    :string="name"
                    :sub-string="subString"
                />
            </div>
            <div class="table__column contract__start-date"></div>
            <div class="table__column contract__state"></div>
            <div class="table__column contract__end-date"></div>
            <div class="table__column contract__tariff">
                {{ formattedTariff }}
            </div>
            <div class="table__column contract__user-name"></div>
            <div class="table__column contract__user-document"></div>
        </div>
    </div>
</template>

<script>
    import { formatCurrencyValue } from "../../../helpers/finance";

    export default {
        name: "FinanceContractPremiseRow",

        props: {
            id: {
                type: String,
                required: true
            },
            index: {
                type: Number,
                required: true
            },
            length: {
                type: Number,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            total: {
                type: Number,
                required: true
            },
            subString: {
                type: String,
                required: true
            }
        },

        computed: {
            formattedTariff() {
                const { balance, currency } = formatCurrencyValue(this.total);

                return `${balance} ${currency}`;
            }
        }
    }
</script>

<style lang="scss">
    .finance-contract-premise {

        .table__row {
            min-height: 50px !important;
            padding: 0 30px !important;
            background: var(--app-table-background) !important;
        }
    }
</style>