import { getFiltersByType } from "../../constants/filters";

const filters = {
    namespaced: true,

    state: () => ({
        filters: null
    }),

    getters: {
        buildingFilters(state) {
            return getFiltersByType('buildings', state.filters);
        },

        premiseFilters(state) {
            return getFiltersByType('premises', state.filters);
        },

        userFilters(state) {
            return getFiltersByType('users', state.filters);
        },

        balanceFilters(state) {
            return getFiltersByType('balance', state.filters);
        },

        contractFilters(state) {
            return getFiltersByType('contracts', state.filters);
        },

        invoiceFilters(state) {
            return getFiltersByType('invoices', state.filters);
        },

        reviewInvoiceFilters(state) {
            return getFiltersByType('reviewInvoices', state.filters);
        },

        operationFilters(state) {
            return getFiltersByType('operations', state.filters);
        },

        statementsFilters(state) {
            return getFiltersByType('statements', state.filters);
        }
    },

    mutations: {
        setFilters(state, filters) {
            state.filters = filters;
            //IF WE NEED TO ADD SOME DYNAMIC FILTERS TO GLOBAL
            // state.filters = { ...state.filters, ...filters };
        }
    }
}

export {
    filters
}