function formatEmail(email) {
    const parts = email.split('@');
    const startPart = email.substring(0,2);
    const endPart = parts[1];
    const qtySymbols = parts[0].length - 2;
    const stringSymbols = generateSymbols(qtySymbols);

    return `${startPart}${stringSymbols}@${endPart}`;
}

function formatPhone(phone) {
    const startPart = phone.substring(0,6);
    const endPart = phone.substring(phone.length - 2);
    const qtySymbols = phone.length - 8;
    const stringSymbols = generateSymbols(qtySymbols);

    return `${startPart}${stringSymbols}${endPart}`;
}

function formatPassword(password) {
    const qtySymbols = password.length;
    const stringSymbols = generateSymbols(qtySymbols);

    return stringSymbols;
}

function generateSymbols(qty) {
    let i = 1;
    let stringSymbols = '';

    while (i <= qty) {
        stringSymbols = stringSymbols + '*';
        i += 1;
    }

    return stringSymbols;
}

export {
    formatEmail,
    formatPhone,
    formatPassword
}