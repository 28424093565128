<template>
    <div class="finance-contracts">
        <div class="finance-contracts__head">
            <div class="finance-contracts__card">
                <finance-card
                    :type="'payments'"
                    :total="qtyUnsignedContracts"
                    :details="$t('finance.cards.contracts.unsigned')"
                    @action="updateStateFilter('0')"
                >
                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.24738 35.9375V43.75H14.0599L37.1015 20.7083L29.289 12.8958L6.24738 35.9375ZM12.3307 39.5833H10.414V37.6667L29.289 18.7917L31.2057 20.7083L12.3307 39.5833ZM43.1432 11.7292L38.2682 6.85417C37.8515 6.4375 37.3307 6.25 36.789 6.25C36.2474 6.25 35.7265 6.45833 35.3307 6.85417L31.5182 10.6667L39.3307 18.4792L43.1432 14.6667C43.9557 13.8542 43.9557 12.5417 43.1432 11.7292Z" fill="var(--brand-main)"/>
                    </svg>
                </finance-card>
            </div>
            <div class="finance-contracts__card">
                <finance-card
                    :type="'payments'"
                    :total="qtyExpiredContracts"
                    :details="$t('finance.cards.contracts.expired')"
                    @action="updateStateFilter('2')"
                >
                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24.9792 4.16699C13.4792 4.16699 4.16669 13.5003 4.16669 25.0003C4.16669 36.5003 13.4792 45.8337 24.9792 45.8337C36.5 45.8337 45.8334 36.5003 45.8334 25.0003C45.8334 13.5003 36.5 4.16699 24.9792 4.16699ZM25 41.667C15.7917 41.667 8.33335 34.2087 8.33335 25.0003C8.33335 15.792 15.7917 8.33366 25 8.33366C34.2084 8.33366 41.6667 15.792 41.6667 25.0003C41.6667 34.2087 34.2084 41.667 25 41.667ZM26.0417 14.5837H22.9167V27.0837L33.8542 33.6462L35.4167 31.0837L26.0417 25.5212V14.5837Z" fill="var(--system-orange)"/>
                    </svg>
                </finance-card>
            </div>
            <div class="finance-contracts__card">
                <finance-card
                    :type="'payments'"
                    :total="qtyOverdueContracts"
                    :details="$t('finance.cards.contracts.overdue')"
                    @action="updateStateFilter('3')"
                >
                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.9167 31.2503H27.0834V35.417H22.9167V31.2503ZM22.9167 14.5837H27.0834V27.0837H22.9167V14.5837ZM24.9792 4.16699C13.4792 4.16699 4.16669 13.5003 4.16669 25.0003C4.16669 36.5003 13.4792 45.8337 24.9792 45.8337C36.5 45.8337 45.8334 36.5003 45.8334 25.0003C45.8334 13.5003 36.5 4.16699 24.9792 4.16699ZM25 41.667C15.7917 41.667 8.33335 34.2087 8.33335 25.0003C8.33335 15.792 15.7917 8.33366 25 8.33366C34.2084 8.33366 41.6667 15.792 41.6667 25.0003C41.6667 34.2087 34.2084 41.667 25 41.667Z" fill="var(--system-red)"/>
                    </svg>
                </finance-card>
            </div>
        </div>
        <div class="finance-contracts__filters">
            <finance-contracts-filters
                :qty="contracts.length"
                @update="updateQueryParams"
            />
            <app-base-btn
                :size="'padding'"
                :variation="'save'"
                :text="$t('common.buttons.contract')"
                @action="openModalContract"
            />
        </div>
        <div class="finance-contracts__content" ref="financeContracts">
            <div v-if="showTable" class="finance-contracts__content-table">
                <finance-contracts-table
                    :contracts="contracts"
                    :max-height="maxHeightScroll"
                    :search-sub-string="searchSubString"
                />
            </div>
            <div v-if="showEmpty" class="finance-contracts__empty">
                <app-empty-content
                    :is-small="true"
                    :content-type="'contracts'"
                    @open-modal="openModalContract"
                />
            </div>
            <div v-if="showNoResults" class="finance-contracts__empty">
                <app-no-results
                    :is-small="true"
                    @clear="clearQueryParams"
                />
            </div>
            <div v-if="isLoaded" class="finance-contracts__loader">
                <app-loader
                    :size="'large'"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import FinanceContractsFilters from "./FinanceContractsFilters";
    import FinanceCard from "../FinanceCard";
    import FinanceContractsTable from "./FinanceContractsTable";
    import { mapContractsToView, mapContractsQtyTypesToView } from "../../../api/mappers/finance/contracts";
    import { maxHeightMixin } from "../../../mixins/calcMaxHeight";
    import { contractsToastsMixin } from "../../../mixins/toasts/finance/contracts";
    import { djinAPI } from "../../../config/api";

    export default {
        name: "FinanceContractsControl",

        components: {
            FinanceCard,
            FinanceContractsFilters,
            FinanceContractsTable
        },

        data() {
            return {
                isLoaded: false,
                contracts: [],
                qty: {
                    unsigned: 0,
                    expired: 0,
                    overdue: 0
                },
                params: null
            }
        },

        mixins: [
            maxHeightMixin,
            contractsToastsMixin
        ],

        created() {
            this.getContracts();
        },

        mounted() {
            this.subscribeToResize('financeContracts');
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromResize();
            this.unsubscribeFromEvents();
        },

        computed: {
            showTable() {
                return this.contracts.length && !this.isLoaded;
            },

            showEmpty() {
                return !this.contracts.length && !this.isLoaded && !this.isExistQueryParams;
            },

            showNoResults() {
                return !this.contracts.length && !this.isLoaded && this.isExistQueryParams;
            },

            isExistQueryParams() {
                return this.searchSubString || this.filterSubString;
            },

            searchSubString() {
                return this.params?.params?.match ? this.params?.params?.match : '';
            },

            filterSubString() {
                return this.params?.params?.filter ? this.params?.params?.filter : '';
            },

            qtyUnsignedContracts() {
                return `${this.$t('finance.cards.contracts.qty')} ${this.qty.unsigned}`;
            },

            qtyExpiredContracts() {
                return `${this.$t('finance.cards.contracts.qty')} ${this.qty.expired}`;
            },

            qtyOverdueContracts() {
                return `${this.$t('finance.cards.contracts.qty')} ${this.qty.overdue}`;
            },
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('updateContracts', this.updateContracts);
                this.emitter.on('deleteContracts', this.deleteContracts);
            },

            unsubscribeFromEvents() {
                this.emitter.off('updateContracts', this.updateContracts);
                this.emitter.off('deleteContracts', this.deleteContracts);
            },

            getContracts() {
                this.isLoaded = true;

                djinAPI.get('/finance/contracts', this.params)
                    .then((result) => {
                        const { contracts, ...qtyContracts } = result.data;
                        this.contracts = mapContractsToView(contracts);
                        this.qty = mapContractsQtyTypesToView(qtyContracts);

                        this.emitter.emit('updateShowQty');
                        this.$nextTick(() => {
                            this.calcMaxHeight();
                        });
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.isLoaded = false;
                        }, 500)
                    })
            },

            updateQueryParams(params) {
                this.params = { ...params };
                this.getContracts();
            },

            deleteContracts(ids) {
                const contractIds = {
                    contracts: ids
                }

                djinAPI.delete('finance/contracts', {
                    data: contractIds
                })
                    .then(() => {
                        this.closeModalConfirm();

                        if (ids.length > 1) {
                            this.showDeleteSeveralToast();
                        } else {
                            const contract = this.contracts.find((contract) => contract.id === ids[0]);
                            this.showDeleteOneToast(contract.name);
                        }

                        this.updateContracts();
                    })
            },

            updateContracts() {
                this.getContracts();
            },

            clearQueryParams() {
                this.emitter.emit('clearQueryParams');
            },

            updateStateFilter(id) {
                this.emitter.emit('updateStateFilter', id);
            },

            openModalContract() {
                this.openModal({
                    name: 'modalContract'
                });
            },

            closeModalConfirm() {
                this.closeModal('modalConfirm');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .finance-contracts {
        display: flex;
        flex-direction: column;
        height: 100%;

        &__content {
            flex-grow: 1;
        }

        &__content-table {
            height: 100%;
        }

        &__head {
            display: flex;
            padding: 30px;
        }

        &__card {
            width: 100%;

            &:not(:last-child) {
                margin-right: 30px;
            }
        }

        &__filters {
            display: flex;
            justify-content: space-between;
            padding: 0 30px 15px 30px;
        }
    }
</style>

<style lang="scss">
    .finance-contracts-table {
        height: 100%;
    }

    .contract {
        &__control {
            width: 4%;

            .checkmark {
                border: 1px solid var(--app-base-primary);
            }
        }

        &__panel {
            width: 96%;
            display: flex;
            align-items: center;
        }

        &__number {
            width: 18%;
            position: relative;

            display: flex;
            align-items: center;

            button:first-child {
                margin-right: 30px;
            }

            &--is-premise {
                padding-left: 40px;
            }
        }

        &__name {
            margin-left: 30px;
        }

        &__start-date {
            width: 14%;
        }

        &__state {
            width: 14%;
            display: flex;
            align-items: center;

            button {
                margin-left: 30px;
            }

            @media screen and (max-width: 1600px) {
                button {
                    margin-left: 15px;
                }
            }
        }

        &__end-date {
            width: 14%;
        }

        &__tariff {
            width: 12%;

            &--is-row {
                font-weight: 700;
            }
        }

        &__user-name {
            width: 14%;
        }

        &__user-document {
            width: 10%;
        }

        &__item {
            position: absolute;
            top: -18px;
            left: 5px;
            height: 50px;
        }

        &__selected {
            font-weight: 500;
        }

        &__send, &__delete {
            display: flex;
            align-items: center;
            margin-left: 30px;
        }

        &__actions {
            position: relative;
        }

        &__action-wrapper {
            position: absolute;
            right: 35px;
            top: 15px;
            background: var(--system-white);
            box-shadow: 0 16px 32px rgba(35, 23, 5, 0.26);
            border-radius: 13px;
            padding: 7px 0;
            z-index: 10;
        }
    }
</style>