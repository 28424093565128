<template>
    <div class="user-premise-form">
        <div class="user-premise-form__panel">
            <div class="user-premise-form__title">
                <h5>{{ title }}</h5>
            </div>
            <app-text-btn v-if="isLastPremise"
                :text="$t('users.premise.addBtn')"
                @action="addUserPremise"
            >
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.0416 10.2913H10.2916V15.0413H8.70825V10.2913H3.95825V8.70801H8.70825V3.95801H10.2916V8.70801H15.0416V10.2913Z" fill="var(--brand-main)"/>
                </svg>
            </app-text-btn>
        </div>
        <div class="user-premise-form__building">
            <app-base-select
                :label="$t('users.premise.building.label')"
                :place-holder="$t('users.premise.building.ph')"
                :is-searchable="true"
                :selected-option="selectedBuilding"
                :options="buildings"
                :type="'text'"
                @select="selectBuilding"
            />
        </div>
        <div class="user-premise-form__premise">
            <app-base-select ref="premise"
                :label="$t('users.premise.premise.label')"
                :place-holder="$t('users.premise.premise.ph')"
                :is-searchable="true"
                :is-clear="true"
                :is-disabled="isLoaded"
                :selected-option="selectedPremise"
                :options="userPremises"
                :type="'text'"
                :error-message="errors.selectedPremise"
                @select="selectPremise"
            />
        </div>
        <div class="user-premise-form__properties">
            <div class="user-premise-form__is-owner">
                <app-base-checkbox
                    v-model="properties.isOwner"
                    :value="'isOwner'"
                    :label="$t('users.user.properties.isOwner')"
                />
            </div>
            <div class="user-premise-form__is-registered">
                <app-base-checkbox
                    v-model="properties.isRegistered"
                    :value="'isRegistered'"
                    :label="$t('users.user.properties.isRegistered')"
                />
            </div>
        </div>
        <div v-if="!isOnePremise" class="user-premise-form__delete-btn">
            <app-circle-btn
                @action="deleteUserPremise"
            >
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.875 4.00625L10.9938 3.125L7.5 6.61875L4.00625 3.125L3.125 4.00625L6.61875 7.5L3.125 10.9938L4.00625 11.875L7.5 8.38125L10.9938 11.875L11.875 10.9938L8.38125 7.5L11.875 4.00625Z" fill="#2B3037"/>
                </svg>
            </app-circle-btn>
        </div>
    </div>
</template>

<script>
    import { djinAPI } from "../../config/api";
    import { mapPremisesToView } from "../../api/mappers/premises";
    import { clearDependentSelects } from "../../helpers/selects";
    import { validateUserPremise } from "../../validations/user";

    export default {
        name: "UserPremiseForm",

        props: {
            isUpdate: {
                type: Boolean,
                required: true
            },
            index: {
                type: Number,
                required: true
            },
            length: {
                type: Number,
                required: true
            },
            id: {
                type: [Number, String],
                required: true
            },
            isNew: {
                type: Boolean,
                required: true
            },
            buildings: {
                type: Array,
                required: true
            },
            buildingId: {
                type: String,
                required: true
            },
            premiseId: {
                type: String,
                required: true
            },
            isOwner: {
                type: Boolean,
                required: true
            },
            isRegistered: {
                type: Boolean,
                required: true
            }
        },

        data() {
            return {
                isLoaded: false,
                selectedBuilding: null,
                selectedPremise: null,
                properties: {
                    isOwner: true,
                    isRegistered: true
                },
                userPremises: [],
                errors: {
                    selectedPremise: ''
                }
            }
        },

        created() {
            this.checkIsUpdateUserPremise();
        },

        mounted() {
            this.subscribeToEvents();
        },

        unmounted() {
            this.unSubscribeFromEvents();
        },

        computed: {
            title() {
               return this.isOnePremise
                   ? this.$t('users.premise.title')
                   : `${this.$t('users.premise.title')} ${this.index + 1}`;
            },

            isOnePremise() {
                return this.length === 1;
            },

            isLastPremise() {
                return this.index === this.length - 1;
            }
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('validateUser', this.validateAndUpdateUserPremise);
            },

            unSubscribeFromEvents() {
                this.emitter.off('validateUser', this.validateAndUpdateUserPremise);
            },

            checkIsUpdateUserPremise() {
                this.properties.isOwner = this.isOwner;
                this.properties.isRegistered = this.isRegistered;

                if (!this.isNew) {
                    this.selectedBuilding = this.buildings.find((building) => building.id === this.buildingId);
                    this.getPremisesByBuilding(this.selectedBuilding);
                }
            },

            addUserPremise() {
                this.$emit('add');
            },

            deleteUserPremise() {
                this.$emit('delete', this.id);
            },

            selectBuilding(building) {
                this.selectedBuilding = building;
                this.clearDependentFields();

                if (this.selectedBuilding) {
                    this.getPremisesByBuilding(this.selectedBuilding);
                }
            },

            clearDependentFields() {
                const dependentSelects = {
                    premise: this.$refs.premise
                };

                clearDependentSelects(dependentSelects);

                this.$nextTick(() => {
                    this.$refs.premise.initSelect();
                })
            },

            getPremisesByBuilding(building) {
                this.isLoaded = true;

                const params = {
                    params: {
                        filter: `building:${building.id}`
                    }
                }

                djinAPI.get('/premises', params)
                    .then((result) => {
                        const premises = result.data;
                        this.userPremises = mapPremisesToView(premises);

                        if (this.isUpdate) {
                            this.selectedPremise = this.userPremises.find((premise) => premise.id === this.premiseId);
                        }
                    })
                    .finally(() => {
                        this.isLoaded = false;
                    })
            },

            selectPremise(premise) {
                this.selectedPremise = premise;
            },

            validateAndUpdateUserPremise() {
                this.clearUserErrors();
                const { isValid, errors} = validateUserPremise(this.selectedPremise);

                this.errors = errors;
                this.$emit('update', this.id, this.isNew, isValid, this.properties, this.selectedPremise);
            },

            clearUserErrors() {
                for (let key in this.errors) {
                    this.errors[key] = '';
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .user-premise-form {
        flex-basis: 50%;
        padding-top: 15px;
        position: relative;

        &__panel {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
        }

        &__title {
            margin-right: 30px;
        }

        &__delete-btn {
            position: absolute;
            top: 15px;
            right: 15px;
        }

        &:nth-child(2n) {
            padding-left: 15px;

            .user-premise-form__delete-btn {
                right: 0px;
            }
        }

        &:nth-child(2n+1) {
            padding-right: 30px;
        }

        &__building {
            margin-bottom: 15px;
        }

        &__properties {
            padding: 30px 0px;
        }

        &__is-owner {
            margin-bottom: 15px;
        }
    }
</style>