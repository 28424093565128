import { showToast } from "../../../helpers/toasts";

const contractsToastsMixin = {
    methods: {
        showDeleteOneToast(name) {
            const deleteToastTitle = this.$t('toasts.contracts.deleteOne.title');

            const deleteToastDetails = `
                    ${this.$t(`toasts.contracts.deleteOne.details1`)}
                    ${name}
                    ${this.$t(`toasts.contracts.deleteOne.details2`)}`
            ;

            showToast('success',
                deleteToastTitle,
                deleteToastDetails
            );
        },

        showDeleteSeveralToast() {
            const deleteToastTitle = this.$t('toasts.contracts.deleteSeveral.title');

            const deleteToastDetails = `
                    ${this.$t(`toasts.contracts.deleteSeveral.details1`)}
                    ${this.$t(`toasts.contracts.deleteSeveral.details2`)}`
            ;

            showToast('success',
                deleteToastTitle,
                deleteToastDetails
            );
        },

        showCreateToast() {
            showToast('success',
                this.$t('toasts.success'),
                this.$t('toasts.contracts.create.details')
            );
        }
    }
}

export {
    contractsToastsMixin
}