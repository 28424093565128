<template>
    <div class="invoice-templates" :style="{ 'maxHeight': maxHeight }">
        <div v-for="invoice in invoices" :key="invoice.id" class="invoice-templates__card">
            <invoice-template-card
                :id="invoice.id"
                :name="invoice.name"
                :price="invoice.price"
                @update="updateInvoiceTemplate"
                @delete="deleteInvoiceTemplate"
            />
        </div>
    </div>
</template>

<script>
    import InvoiceTemplateCard from "./InvoiceTemplateCard";

    export default {
        name: "InvoiceTemplatesTable",

        components: {
            InvoiceTemplateCard
        },

        props: {
            invoices: {
                type: Array,
                required: true
            },
            maxHeight: {
                type: String,
                required: true
            },
        },

        methods: {
            updateInvoiceTemplate(id) {
                const invoice = this.invoices.find((invoice) => invoice.id === id);

                this.openModal({
                    name: 'modalTemplateInvoice',
                    selectedEl: invoice
                })
            },

            deleteInvoiceTemplate(id) {
                const invoice = this.invoices.find((invoice) => invoice.id === id);

                const selectedElement = {
                    id: invoice.id,
                    name: invoice.name,
                    type: 'invoiceTemplate'
                };

                this.openModal({
                    name: 'modalConfirm',
                    selectedEl: selectedElement
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .invoice-templates {
       display: flex;
       flex-direction: column;
       overflow-y: auto;
       scrollbar-width: thin;
       margin-right: 30px;

       &__card {
           padding: 0 30px 30px 0;
       }
    }
</style>