function mapPhoneToView(phone) {
    return {
        code: phone.code,
        number: phone.number
    }
}

function mapPhoneToSend(phone) {
    return {
        code: phone.code,
        number: phone.number
    }
}

const defaultPhone = {
    code: '+380',
    number: ''
}

export {
    mapPhoneToView,
    mapPhoneToSend,
    defaultPhone
}