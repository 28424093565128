<template>
    <div class="finance-statements-table"
        :style="{
            'maxHeight': maxHeight
        }"
    >
        <div class="finance-statements-table__head finance-statements-table__head--is-sticky">
            <div class="finance-statements-table__details">
                <div class="table__f-column table__f-column--is-start statement__name statement__name--is-head">
                    <app-overflow-match-wrapper
                        :is-statement="true"
                        :text="$t('finance.statements.type.user')"
                    />
                </div>
                <div class="table__f-column statement__document-id">
                    <app-overflow-match-wrapper
                        :is-statement="true"
                        :text="$t('finance.statements.table.documentId')"
                    />
                </div>
                <div class="table__f-column statement__period statement__period--is-head">
                    <app-overflow-match-wrapper
                        :text="$t('finance.statements.table.period.start')"
                    />
                </div>
                <div class="table__f-column statement__period statement__period--is-head">
                    <app-overflow-match-wrapper
                        :text="$t('finance.statements.table.period.turnover')"
                    />
                </div>
                <div class="table__f-column statement__period statement__period--is-head">
                    <app-overflow-match-wrapper
                        :text="$t('finance.statements.table.period.end')"
                    />
                </div>
            </div>
            <div class="finance-statements-table__operations">
                <div class="table__f-column table__f-column--is-start statement__name statement__name--is-head"></div>
                <div class="table__f-column statement__document-id"></div>
                <div class="table__f-column statement__period">
                    <div class="table__f-column table__f-column--is-debit statement__debit">
                        <app-overflow-match-wrapper
                            :text="$t('finance.statements.table.debit')"
                        />
                    </div>
                    <div class="table__f-column table__f-column--is-credit statement__credit">
                        <app-overflow-match-wrapper
                            :text="$t('finance.statements.table.credit')"
                        />
                    </div>
                </div>
                <div class="table__f-column statement__period">
                    <div class="table__f-column table__f-column--is-debit statement__debit">
                        <app-overflow-match-wrapper
                            :text="$t('finance.statements.table.debit')"
                        />
                    </div>
                    <div class="table__f-column table__f-column--is-credit statement__credit">
                        <app-overflow-match-wrapper
                            :text="$t('finance.statements.table.credit')"
                        />
                    </div>
                </div>
                <div class="table__f-column statement__period">
                    <div class="table__f-column table__f-column--is-debit statement__debit">
                        <app-overflow-match-wrapper
                            :text="$t('finance.statements.table.debit')"
                        />
                    </div>
                    <div class="table__f-column table__f-column--is-credit statement__credit">
                        <app-overflow-match-wrapper
                            :text="$t('finance.statements.table.credit')"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="table__body">
            <finance-user-statement-row
                :level="0"
                :name="user.name"
                :document-id="user.documentId"
                :end-of-period="user.endOfPeriod"
                :turnover="user.turnover"
                :start-of-period="user.startOfPeriod"
                :children="user.children"
            />
        </div>
    </div>
</template>

<script>
    import FinanceUserStatementRow from "../elements/FinanceUserStatementRow";

    export default {
        name: "FinanceUserStatementsTable",

        components: {
            FinanceUserStatementRow
        },

        props: {
            user: {
                type: Object,
                required: true
            },
            maxHeight: {
                type: String,
                required: true
            }
        }
    }
</script>