import { validatePositiveNumber } from "../common";
import i18n from "../../../src/localization/i18n"
const { t } = i18n.global;

const invoiceErrors = {
    selectedUser: t('validation.invoice.selectedUser'),
}

const invoicePositionErrors = {
    name: t('validation.required'),
    selectedPosition: t('validation.invoice.selectedPosition'),
    price: t('validation.numberPositive')
}

const invoiceReviewPositionErrors = {
    amount: t('validation.numberPositive')
}

const invoiceReviewUserErrors = {
    user: t('validation.invoice.selectedUser')
}

function validateInvoice(invoice, user) {
    let isValid = false;

    const errors = {
        selectedUser: '',
    }

    const isValidUser = !!user;

    if (!isValidUser) {
        errors.selectedUser = invoiceErrors.selectedUser;
    }

    isValid = isValidUser;

    return { isValid, errors };
}

function validateInvoicePosition(position, isTemplate, template) {
    let isValid = false;

    const errors = {
        name: '',
        price: '',
        positive: ''
    }

    let isValidName = false;

    if (!isTemplate) {
        isValidName = !!position.name;
    } else {
        isValidName = !!template;
    }

    if (!isValidName) {
        if (!isTemplate) {
            errors.name = invoicePositionErrors.name;
        } else {
            errors.name = invoicePositionErrors.selectedPosition;
        }
    }

    const isValidPrice = validatePositiveNumber(position.price);

    if (!isValidPrice) {
        errors.price = 'isNotShowError';

        if (isValidName) {
            errors.positive = invoicePositionErrors.price;
        }
    }

    isValid = isValidName && isValidPrice;

    return { isValid, errors };
}

function validateReviewInvoicePosition(amount) {
    let isValid = false;

    const errors = {
        amount: '',
    }

    const isValidAmount = validatePositiveNumber(amount) || amount === '';

    if (!isValidAmount) {
        errors.amount = invoiceReviewPositionErrors.amount;
    }

    isValid = isValidAmount;

    return { isValid, errors };
}

function validateReviewUser(selectedUser) {
    let isValid = false;

    const errors = {
        user: '',
    }

    const isValidUser = !!selectedUser;

    if (!isValidUser) {
        errors.user = invoiceReviewUserErrors.user;
    }

    isValid = isValidUser;

    return { isValid, errors };
}

export {
    validateInvoice,
    validateInvoicePosition,
    validateReviewInvoicePosition,
    validateReviewUser
}