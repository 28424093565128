<template>
    <div class="finance-statements-table">
        <div class="table__body">
            <finance-empty-statement-row v-for="(row, index) in emptyRows" :key="index"
                :index="index"
            />
        </div>
    </div>
</template>

<script>
    import FinanceEmptyStatementRow from "../elements/FinanceEmptyStatementRow";

    export default {
        name: "FinanceEmptyStatementsTable",

        components: {
            FinanceEmptyStatementRow
        },

        props: {
            emptyRows: {
                type: Array,
                required: true
            }
        }
    }
</script>