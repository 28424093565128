import i18n from "../../../../src/localization/i18n"
const { t } = i18n.global;

function mapGroupsBalanceToView(groups) {
    return groups.map((group) => {
        return {
            id: group.group_id,
            name: group.group_name,
            balance: group.balance,
            buildings: mapBuildingsBalanceToView(group.buildings)
        }
    })
}

function mapBuildingsBalanceToView(buildings) {
    return buildings.map((building) => {
        return {
            id: building.building_id,
            name: building.building_name,
            balance: building.balance,
            users: mapUsersBalanceToView(building.users)
        }
    })
}

function mapUsersBalanceToView(users) {
    return users.map((user) => {
        return {
            id: user.user_id,
            documentId: user.user_document_id || '',
            name: user.user_name,
            balance: user.balance,
            isRestructing: user.is_restructing,
            currentParts: user.cur_restructing_parts,
            totalParts: user.total_restructing_parts,
            premises: mapPremisesBalanceToView(user.premises, user.services),
        }
    })
}

function mapPremisesBalanceToView(premises, services) {
    const balancePremises = premises.map((premise) => {
        return {
            id: premise.premise_id,
            name: premise.premise_name,
            balance: premise.balance
        }
    });

    if (services !== 0) {
        balancePremises.push({
            id: '0',
            name: t('finance.services'),
            balance: services
        });
    }

    return balancePremises;
}

function mapAdditionalInfoBalanceToView(info) {
    return {
        balance: info.common_balance,
        clients: {
            debt: info.clients_debt
        },
        invoices: {
            review: info.invoices_inspection
        },
        contracts: {
            signature: info.contracts_signature,
            expires: info.contracts_expires,
            overdue: info.contracts_overdue
        }
    }
}

function mapReviewInvoiceToSend(invoice) {
    return {
        check_operation_id: invoice.id,
        user_id: invoice.userId,
        invoice_id: invoice.invoiceNumber,
        payments: mapReviewInvoicePaymentsToSend(invoice.payments)
    }
}

function mapReviewInvoicePaymentsToSend(payments) {
    return payments.map((payment) => {
        const paymentToSend = {
            type: payment.id === '0' ? 'service' : 'periodic',
            amount: Number(payment.amount)
        }

        if (payment.premiseId !== '0') {
            paymentToSend.premise_id = payment.id
        }

        return paymentToSend;
    })
}

export {
    mapGroupsBalanceToView,
    mapBuildingsBalanceToView,
    mapAdditionalInfoBalanceToView,
    mapReviewInvoiceToSend
}