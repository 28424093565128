<template>
    <div class="operation-type"
         :style="{
            'backgroundColor': operationType.background
        }"
    >
        <span>{{ operationType.name }}</span>
    </div>
</template>

<script>
    import { operationTypes } from "../../../constants/finance";

    export default {
        name: "PremiseType",

        props: {
            type: {
                type: String,
                required: true,
                validator(type) {
                    return [
                        'service',
                        'periodic'
                    ].includes(type);
                }
            }
        },

        computed: {
            operationType() {
                return operationTypes.find((type) => type.id === this.type);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .operation-type {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        border-radius: 100px;
        font-size: 12px;
        line-height: 14px;
        padding: 5px 15px;
        color: var(--app-base-primary);
    }
</style>