import { showToast } from "../../../helpers/toasts";

const operationsToastsMixin = {
    methods: {
        showDeleteOperationToast() {
            const deleteToastTitle = this.$t('toasts.operation.delete.title');

            const deleteToastDetails = `
                    ${this.$t(`toasts.operation.delete.details1`)}
                    ${this.$t(`toasts.operation.delete.details2`)}`
            ;

            showToast('success',
                deleteToastTitle,
                deleteToastDetails
            );
        },

        showCreateOperationToast() {
            showToast('success',
                this.$t('toasts.success'),
                this.$t('toasts.operation.create.details')
            );
        }
    }
}

export {
    operationsToastsMixin
}