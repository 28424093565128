import { validateNumber } from "../common";
import i18n from "../../../src/localization/i18n"
const { t } = i18n.global;

const operationErrors = {
    amount: t('validation.number'),
    premise: t('validation.required'),
    basis: t('validation.required')
}

function validateOperation(amount, type, premise, basis) {
    debugger;

    let isValid = false;

    const errors = {
        amount: '',
        premise: '',
        basis: ''
    }

    const isValidAmount = validateNumber(amount);

    if (!isValidAmount) {
        errors.amount = operationErrors.amount;
    }

    let isValidPremise = true;

    if (type.id === 'periodic') {
        isValidPremise = !!premise;
    }

    if (!isValidPremise) {
        errors.premise = operationErrors.premise;
    }

    const isValidBasis = !!basis;

    if (!isValidBasis) {
        errors.basis = operationErrors.basis;
    }

    isValid = isValidAmount && isValidPremise && isValidBasis;

    return { isValid, errors };
}

export {
    validateOperation
}